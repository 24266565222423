//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, onMounted, PropType, ref,toRefs } from "vue";
import { itemdata } from "../form/dataform";
import  moment from 'moment'
import { useStore } from "vuex";
import { key } from "./../state/store";

export default defineComponent({
  name: "cfDate",
  props: {
    item: {
      type: Object as PropType<itemdata>,
      default: null,
    },
    value: String,
  },
  emits: ["input"],
  setup(props,context) {
    const store = useStore(key);
    let item = toRefs(props).item.value;
    const datavalue = ref(props.value||(props.item as any).lockvalue);
    store.commit("datePickerChange", {field:item.field,value:datavalue})
    const change = () =>{
      //console.log(moment(datavalue.value).format('YYYY-MM-DD'))
      store.commit("datePickerChange", {field:item.field,value:datavalue})
      context.emit('input',moment(datavalue.value).format('YYYY-MM-DD'))
    }
    const disabledDate = (date:Date) => {
      if (item.field == 'start_time') {
        let end_time = store.state.datePickO['end_time'];
        if (end_time) {
          return moment(end_time).diff(date) < 0
        }
      } else if (item.field == 'end_time') {
        let start_time = store.state.datePickO['start_time'];
        if (start_time) {
          return moment(start_time).diff(date) > 0
        }
      }
      return false
    }
    
    return { datavalue,change,disabledDate,store }
  },
  computed:{
    stateFieldRemark:function(){
      return this.store.state.fieldRemark
    }
  }

});
