import { JosnData } from "@/utils/JsonData";
import { StorgeUtils } from "@/utils/StorageUtils";
import Api from "@/http/Api";
import {store} from "../state/store"
export class dict {
  public async GetDict(dictName: string | undefined): Promise<dictitem[]> {
    let retdict: dictitem[] = [];
    if (dictName == "gender") {
      retdict = JSON.parse(JosnData.gender)
    } else if (dictName == "education") {
      retdict = JSON.parse(JosnData.education);
    // } else if (dictName == 'region') {
    //   retdict = JSON.parse(JosnData.area)
    } else if (dictName == 'yesno') {
      retdict = JSON.parse(JosnData.isbase)
    }else if(dictName == 'school_length'){
      retdict = JSON.parse(JosnData.schoolLength)
    }else if(dictName == 'graduation'){
      retdict = JSON.parse(JosnData.graduation)
    } else if (dictName == 'source_major') {
      const year = StorgeUtils.get('year');
      const collegeid = StorgeUtils.get('collegeid');
      const facultyid = StorgeUtils.get('facultyid');
      const education = store.state.education;
      if(year&&collegeid&&facultyid&&education){
        await Api.getSourceMajor(collegeid,year,facultyid,education).then((res) => {
          //console.log(res.data);
          if(res.status && res.data){
            for (const item of res.data) {
              item.text = item.name;
              item.code = item.source_code;
            }
            retdict = res.data;
          }else{
            retdict = [];
          }
        })
      }
    } else {
      if (dictName) {
        await Api.getDict(dictName).then((res) => {
          if (res && res.status) {
            retdict = res.data
          }
        })
      }
    }

    return new Promise(resolve => {
      resolve(retdict);
    })

  }

  public Lock(dictdata: Array<dictitem>, lockvalues: string[]): Array<dictitem> {
    return dictdata.filter((item) => lockvalues.indexOf(item.text) != -1);
  }

  public Except(dictdata: Array<dictitem>, exceptvalues: string[]): Array<dictitem> {
    return dictdata.filter((item) => exceptvalues.indexOf(item.text) == -1);
  }
}

export class dictitem {
  text!: string;
  code!: string;
  level?: number;
  parentcode?: string;
  parenttext?: string;
  relation_desc?: string;
  relation_code?: string;
  order?: number;
}

export class majoritem {
  code!: string;
  education!: string;
  education_text!: string;
  educations!: Array<education>;
  is_recommend!: boolean;
  majorid!: string;
  name!: string;
  source_code!: string;
  source_code_name?: string | null;
  student_active?: number;
  student_count?: number;
}

class education {
  code!: string;
  text!: string;
}
