//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, reactive, ref, computed } from "vue";
import { ElMessage } from "element-plus";
import Constants from "@/http/Constant";
import Api from "@/http/Api";
import { useRoute, useRouter } from "vue-router";
import { StorgeUtils } from "@/utils/StorageUtils";

export default defineComponent({
  name: "rescindSign",
  setup() {
    const router = useRouter();
    const route = useRoute();
    const reason = ref<string>("");
    const recordid = ref<string>("");
    const recordtype = ref<string>("");
    const attachments = reactive(new Array<string>());
    const fit = ref<string>('contain');
    const uploadUrl = `${Constants.ApiUrl}/signup/v3/uploadimage`;
    const rescindLetterTemplate = ref<string>("");
    const revokeFileTemplate = ref<string>("");  
    //提交解约参数
    const params = reactive({
        recordid: "",
        reason: "",
        attachments: Array<string>(),
    });
    if(route.query.recordid){
        let id:any = route.query.recordid;
        recordid.value = id
    }
    if(route.query.recordtype){
        recordtype.value = route.query.recordtype as string
    }
   

    //获取签约配置
    Api.getSignSetting().then((res) => {
        if (res.status) {
            rescindLetterTemplate.value = res.data.cancel_file_url;
            revokeFileTemplate.value = res.data.revoke_file_url
        }
    });

    //获取解约状态
    Api.getRescindSignState(recordid.value).then((res) =>{
        if(res.status){
            console.log(res)
            reason.value = res.data.reason
            res.data.attachments.forEach(v=>{
                attachments.push(v)
            })
        }
    })
    
    const rescind = () =>{
        if(!reason.value){
            ElMessage({
              message: `请填写${ recordTitle.value }原因！`,
              type: "warning",
              offset: 50,
            });
            return;
        }
        if(!attachments.length){
            ElMessage({
              message: `请上传${ recordTitle.value }函`,
              type: "warning",
              offset: 50,
            });
            return;
        }
        params.reason = reason.value;
        params.recordid = recordid.value;
        params.attachments = attachments;
        console.log(params);
        let fnKey = 'cancelSign'
        if(recordtype.value == 'revoke'){
            fnKey = 'revokeSign'
        }
        Api[fnKey](params).then((res) =>{
            console.log(res);
            if(res.status){
                ElMessage({
                    message: `${ recordTitle.value }申请已提交，请等待审核！`,
                    type: "success",
                    offset: 50,
                });
                setTimeout(() => {
                    window['wx'].miniProgram.navigateBack({delta:2})
                    window.parent.postMessage({ page: 'rescindSign' }, '*')
                }, 2000);
            }

        })
    }

    const uploadFile = (file) =>{
        if (!file.type.includes('image')) {
            ElMessage.error('请上传jpg或png图片!')
            return false
        }
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = (e) =>{
            let imageData = reader.result;
            console.log(typeof imageData);
            if(imageData){
                imageData = replaceStr(imageData);
                let data = {value:imageData};
                Api.uploadImage(data).then((res) => {
                    if(res.status){
                        attachments.push(res.data.value)
                        console.log(attachments);
                    }
                })
            }
        }
        return false;
    }

    const replaceStr = (imageData:any) =>{
        let result = imageData;
        result = result.replace(/^(data:image)\/jpeg;base64,/g, '')
        result = result.replace(/^(data:image)\/png;base64,/g, '')
        return result;
    }

    const wxjump = (url?: string) => {
      let params = {
        url: url,
      }
      console.log(params, 'wxjump')
      window['wx'].miniProgram.navigateTo({
        "url": `/pages/subpackage/activitySub/skipDownload/skipDownload?params=${encodeURIComponent(JSON.stringify(params))}`,
      })
    }

    const downloadRescindLetter = () =>{
      let userType = StorgeUtils.get("usertype")
      if (userType !== 'wx') {
        if(recordtype.value == 'revoke'){
            window.open(revokeFileTemplate.value)
        }else{
            window.open(rescindLetterTemplate.value)
        }
      } else {
        if(recordtype.value == 'revoke'){
            wxjump(revokeFileTemplate.value)
        }else{
            wxjump(rescindLetterTemplate.value)
        }
      }
    }

    const clearImg = () =>{
       let length = attachments.length;
       attachments.splice(0,length);
    }
    const recordTitle = computed(()=>{
        let recordTitle = '解约'
        if(recordtype.value == 'revoke'){
            recordTitle = '撤回'
        }
        return recordTitle
    })
    return { reason, attachments,rescind,fit,uploadUrl,uploadFile,downloadRescindLetter,clearImg,recordTitle };
  },
});
