//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, inject, onMounted, onUpdated, PropType, provide, ref, toRefs, watch } from "vue";
import { selectitemdata } from "../form/dataform";
import { dict, dictitem } from "../form/dictProvider";
import { useStore } from "vuex";
import { key } from "./../state/store";
export default defineComponent({
  name: "cfSelect",
  props: {
    item: {
      type: Object as PropType<selectitemdata>,
      default: null,
    },
    value: [String,Number],
  },
  emits: ["input", "valuechange"],
  setup(props, context) {
    const store = useStore(key);
    let list = ref<dictitem[]>([]);
    let item = toRefs(props).item.value;
    let datavalue = ref(props.value);
    //console.log(datavalue);
    //console.log(item.field,datavalue.value);
    const dictprovide = inject<dict>("dict");
    const signForm = inject("signForm");
    const LoadExtForm: any = inject("LoadExtFrom");
    const UnloadExtFormAfter: any = inject("UnloadExtFormAfter");
    const loadExtFormByHttp: any = inject("loadExtFormByHttp");
    const signModePopup: any = inject("signModePopup");
    const emptySignData: any = inject("emptySignData");
    const signMode: any = inject("signMode");
    const recordid: any = inject("recordid");

    watch(() => store.state.isTrouble, (val, oldVal) => {
      if (!val && item.field == 'filing_card_poor_family') {
        datavalue.value = '否'
        context.emit("valuechange", datavalue.value);
      }
    })
    watch(() => store.state.education, (val, oldVal) => {
      if (val && item.field == 'source_major') {
        loadDictData()
      }
    })
    const loadDictData = () => {
      if (dictprovide) {
        if (item) {
          //console.log(item);
          let source = item.dict_type;
          let listRes = dictprovide.GetDict(source);
          //console.log(`${item.field}`,listRes);
          listRes.then((res) => {
            //console.log(res);
            let listtemp = res;
            list.value = listtemp;
            //console.log(source, listtemp);
            //console.log(list);
            //先处理强制值
            if (item.lockvalues) {
              list.value = dictprovide.Lock(listtemp, item.lockvalues);
              //console.log(list.value);
              if (list.value.length) {
                datavalue.value = list.value[0].code;
              } else {
                //字典中不存在的值，但仍旧有锁定值
                list.value.push({ text: item.lockvalues[0], code: item.lockvalues[0] });
                datavalue.value = item.lockvalues[0];
              }

              //console.log(datavalue.value);
              context.emit("valuechange", datavalue.value);
            }
            //再处理排除值
            else if (item.exceptvalues) {
              list.value = dictprovide.Except(listtemp, item.exceptvalues);
            }
            //有编码字段下拉选择处理
            if (item.code_options) {
              let optionList = item.code_options;
              let x: dictitem[] = [];
              for (let optionItem of optionList) {
                //console.log(item);
                x.push({ text: optionItem.text, code: optionItem.code });
                //list.value.push({text:value,code:value})
              }
              list.value = x;
            }
            //自定义字段下拉选择处理
            if (item.options) {
              let optionList = item.options;
              let x: dictitem[] = [];
              for (let value of optionList) {
                //console.log(item);
                x.push({ text: value, code: value });
                //list.value.push({text:value,code:value})
              }
              list.value = x;
            }

            //计算是否值包含在选项内，否则清空该值
            if (datavalue.value) {
              if (list.value) {
                if (list.value.filter((item) => item.code == datavalue.value).length == 0) {
                  //console.log('字典中未找到对应的值！')
                  datavalue.value = undefined;
                  context.emit("valuechange", undefined);
                }
              }
            }
            //学历专业关联
            if (item.field == "education") {
              store.commit("educationChange", datavalue.value);
            }
            if (item.field == "training_mode") {
              if (datavalue.value == "02" || datavalue.value == "03") {
                store.commit("isDirectionalChange", true);
              } else {
                store.commit("isDirectionalChange", false);
              }
            }
            // 困难生关联
            if (item.field == "hardship_student_type") {
              if (datavalue.value == "02" || datavalue.value == "03" || datavalue.value == "06" || datavalue.value == "07") {
                store.commit("isTroubleChange", true);
              } else {
                store.commit("isTroubleChange", false);
              }
            }
            if (item.field == "employment_type" && datavalue.value&&!item.is_disable) {
              const employment_type = store.state.employment_type;
              //切换就业类别后默认签约方式为备案
              if (employment_type) {
                const unloadFromName = `sign_${employment_type}`;
                UnloadExtFormAfter(unloadFromName, signForm);
              }
              if (datavalue.value == "01"&&!recordid.value) {
                signModePopup.value = true;
              }
              const loadFormName = `sign_${datavalue.value}`;
              LoadExtForm(loadFormName, signForm);
              loadExtFormByHttp(datavalue.value);
              store.commit("employmentTypeChange", datavalue.value);
            }
          });
        }
      }
    };
    if(item.field == 'source_major'){
      if(store.state.education){
        loadDictData();
      }
    }else {
      loadDictData();
    }

    const change = () => {
      //切换就业类别后清空所有的值
      if (item.field == "employment_type") {
        emptySignData(["employment_status"]);
      }else if(item.field == "employment_status"){
        emptySignData();
      }
      context.emit("input", datavalue.value);

      //学历专业关联
      if (item.field == "education") {
        store.commit("educationChange", datavalue.value);
        //console.log(store.state.education)
      }
      // 定向委培关联
      if (item.field == "training_mode") {
        if (datavalue.value == "02" || datavalue.value == "03") {
          store.commit("isDirectionalChange", true);
          //console.log(store.state.isDirectional);
          //console.log('定向')
        } else {
          store.commit("isDirectionalChange", false);
        }
      }
      // 困难生关联
      if (item.field == "hardship_student_type") {
        if (datavalue.value == "02" || datavalue.value == "03" || datavalue.value == "06" || datavalue.value == "07") {
          store.commit("isTroubleChange", true);
        } else {
          store.commit("isTroubleChange", false);
        }
      }

      //就业类别切换
      if (item.field == "employment_type") {
        const employment_type = store.state.employment_type;
        //切换就业类别后默认签约方式为备案
        signMode.value = 0;
        store.commit("signModeChange",0);
        //console.log(employment_type);
        //debugger;
        if (employment_type) {
          const unloadFromName = `sign_${employment_type}`;
          UnloadExtFormAfter(unloadFromName, signForm);
          //console.log(signForm);
        }
        if (datavalue.value == "01") {
          signModePopup.value = true;
        }

        const loadFormName = `sign_${datavalue.value}`;
        LoadExtForm(loadFormName, signForm);
        loadExtFormByHttp(datavalue.value);
        store.commit("employmentTypeChange", datavalue.value);
      }
      //就业状况切换
      if (item.field == "employment_status") {
        console.log(123123)
        const employment_status = store.state.employment_status;
        const employment_type = store.state.employment_type;
        //切换就业类别后默认签约方式为备案
        signMode.value = 0;
        store.commit("signModeChange",0);
        //console.log(employment_type);
        //debugger;
        if (employment_status) {
          const unloadFromName = `sign_status_${employment_status}`;
          UnloadExtFormAfter(unloadFromName, signForm);
          //console.log(signForm);
        }
        if (employment_type) {
          const unloadFromName = `sign_${employment_type}`;
          UnloadExtFormAfter(unloadFromName, signForm);
          //console.log(signForm);
        }
        const loadFormName = `sign_status_${datavalue.value}`;
        LoadExtForm(loadFormName, signForm);
        store.commit("employmentStatusChange", datavalue.value);
      }
    };

    //console.log(list);

    return { list, datavalue, change, store, loadDictData };
  },
  computed: {
    seen: function () {
      let result = true;
      if (this.item.field == 'filing_card_poor_family') {
        result = this.store.state.isTrouble;
      }
      return result;
    },
    stateFieldRemark:function(){
      return this.store.state.fieldRemark
    }
  }
});
