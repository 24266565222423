//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, PropType, ref, toRefs, inject, watch } from "vue";
import { textitemdata } from "../form/dataform";
import { useStore } from 'vuex'
import { key } from './../state/store' 
import { StorgeUtils } from "@/utils/StorageUtils";


export default defineComponent({
  name: "cfText",
  props: {
    item: {
      type: Object as PropType<textitemdata>,
      default: null,
    },
    value: String,
  },
  emits: ["input"],
  setup(props,context) {
    const usertype = ref( StorgeUtils.get("usertype"))
    //console.log(props.item);
    //console.log(props.item.lockvalue)
    let item2 = toRefs(props).item.value;
    const store = useStore(key);
    let datavalue = ref<string>('');
    const signMode: any = inject("signMode");
    let tempValue:any =  props.item.lockvalue ? props.item.lockvalue : props.value;
    datavalue.value = tempValue;
    
    if(datavalue.value){
      context.emit('input',datavalue.value)
    }
    const change = () =>{
      context.emit('input',datavalue.value)
    }
    if(item2.field == 'employerment_agreement_number'&& ~~signMode.value && store.state.autoGenerateEmploymenNumber){
      item2.is_disable = true
      item2.required = false
    }
    watch(() => store.state.signMode, (val, oldVal) => {
      if (val && item2.field == 'employerment_agreement_number'&&store.state.autoGenerateEmploymenNumber) {
        item2.is_disable = true
        item2.required = false
      }
    })
    watch(() => props.value, (val, oldVal) => {
      datavalue.value = val as string;
    })
    return { usertype, datavalue, change, store,item2,signMode}
  },
  computed:{
    seen:function(){
      let result = true;
      if(this.item2.field == 'orientation_department_name'){
        result = this.store.state.isDirectional;
      }
      // if(this.item2.field == 'employerment_agreement_number'&&this.signMode){
      //   result = !!this.datavalue
      // }
      return result;
    },
    showLink:function(){
      let result = false;
      // let href = window.location.href
      // if(['#/collegesSetupSign','#/signCheck','#/collegeAmendSign'].some(v=>href.includes(v))){
        result = true
      // }
      return result;
    },
    departmentRegionShow:function(){
      return this.store.state.departmentRegionShow
    },
    stateFieldRemark:function(){
      return this.store.state.fieldRemark
    },
    autoGenerateEmploymenNumber:function(){
      return this.store.state.autoGenerateEmploymenNumber
    }
  }

});
