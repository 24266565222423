//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment'
import { defineComponent, watch, PropType, provide, reactive, ref } from "vue";
import { requestStateitem } from "../form/dataform";
export default defineComponent({
  name: "dProcess",
  components: {},
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const statebyO = {
      college: "学校",
      faculty: "院系",
      talent: "学生",
      department: "企业"
    }
    const stateA = ["已创建", "签约中", "确认签约", "审核通过", "已解约", "拒绝签约", "退回给学生", "退回给企业", "驳回给院系"]
    const requestStateList = reactive(new Array<requestStateitem>())
    const signingStatus = ref('')
    let fieldDepartmentName = ''
    props.data.request_state_list.forEach((v: requestStateitem) => {
      let stateStr = statebyO[v.stateby] + stateA[v.agreement_state]
      if(v.stateby=='talent'&&v.agreement_state==-1){
        stateStr='学生保存草稿'
      }else      if(v.stateby=='talent'&&v.agreement_state==3){
        stateStr='学生确认签约'
      }
      let state_time = moment(v.state_time).format('YYYY-MM-DD HH:mm')
      let state_accountname = v.state_accountname
      if (v.stateby == 'department' && !state_accountname) {
        if (!fieldDepartmentName) {
          let field_list = props.data.department_field_list
          fieldDepartmentName = (field_list.filter((field: { field: string; }) => field.field == 'department_name')[0] || {}).default_value
        }
        state_accountname = fieldDepartmentName
      }
      requestStateList.push({
        ...v,
        state_time,
        stateStr,
        state_accountname,
      })
    })
    if(props.data.current_state == 6){
      signingStatus.value = '撤回中'
    }else if(props.data.current_state == 7){
      signingStatus.value = '已撤回'
    } else if (props.data.agreement_state == '2') {
      if (props.data.stateby == 'talent' && props.data.audit_mode) {
        signingStatus.value = '待院系审核'
      } else {
        signingStatus.value = '待学校审核'
      }
    } else if (props.data.agreement_state == '3') {
      signingStatus.value = '已通过'
    } else if (props.data.agreement_state == '4') {
      signingStatus.value = '已解约'
    } else if (props.data.agreement_state == '5') {
      if (props.data.stateby == 'department') {
        signingStatus.value = '企业拒绝'
      } else if (props.data.stateby == 'talent') {
        signingStatus.value = '人才拒绝'
      } else {
        signingStatus.value = '未通过'
      }
    } else if (props.data.agreement_state == '8') {
      signingStatus.value = '待院系审核'
    }else if (props.data.sign_mode == '1') {// 线上签约状态
      if (props.data.agreement_state == '0') {
        if (props.data.stateby == 'talent') {
          signingStatus.value = '待企业审核'
        }
      } else if (props.data.agreement_state == '1') {
        if (props.data.stateby == 'department') {
          signingStatus.value = '待学生确认'
        }
     
      } else if (props.data.agreement_state == '6' || props.data.agreement_state == '7') {
        if (props.data.stateby == 'faculty' || props.data.stateby == 'college') {
          signingStatus.value = '签约中退回'
        } else {
          signingStatus.value = '未通过'
        }
      }
    } else if (props.data.sign_mode == '2') {// 打印协议书签约
      if (props.data.agreement_state == '0') {
        if (props.data.stateby == 'talent') {
          signingStatus.value = '学生创建未提交'
        }
      }
    } else if (props.data.sign_mode == '0') {// 已签协议书备案
      if (props.data.agreement_state == '0') {
        if (props.data.stateby == 'talent') {
          signingStatus.value = '学生创建未提交'
        }
      }
    }
    return { requestStateList, signingStatus };
  }
})
