import instance from './intercept';
import { AxiosRequest, CustomResponse } from './Response';
import Constants from './Constant';
import  {StorgeUtils } from "../utils/StorageUtils";
import CryptoJS from "crypto-js";
import { ElMessage } from 'element-plus';
import { AxiosRequestHeaders } from 'axios';



class Abstract{
    protected baseURL = Constants.ApiUrl;
    protected headers: AxiosRequestHeaders = {
        'Content-Type': 'application/json;charset=UTF-8',
        'accountid':Constants.accountid,
        'access_token':Constants.token,
    };

    private apiAxios({ baseURL = this.baseURL, headers = this.headers, method, url, data, params, responseType,isAgency,isCd,isCollege,isRecommend }: AxiosRequest): Promise<CustomResponse> {
        let client_id = Constants.clientid;
        const usertype = StorgeUtils.get('usertype');
        if(isCollege){
            client_id = Constants.clientidCollege;
        }
        if (usertype == 'college') {
            client_id = Constants.clientidCollege;
        }
        if(usertype == 'wx'){
            client_id = Constants.clientidWX;
        }
       
        // 代理请求
        if(isAgency){
            baseURL = Constants.AgentApiUrl;
            client_id = this.encrypt(client_id)
        }
        //广告请求
        if(isCd){
            baseURL = Constants.cdApi;
        }
        if(isRecommend){
            baseURL = Constants.recommendApi;
        }
        Object.assign(headers, {
            'client_id': client_id,
        });

        if(StorgeUtils.get('talentAccountid') && usertype !== 'college'){
            console.log('当前用户为人才')
            Object.assign(headers, {
                'accountid':  StorgeUtils.get('talentAccountid'),
             });
        }
        if(StorgeUtils.get('accountid') && usertype == 'college'){
            Object.assign(headers, {
                'accountid':  StorgeUtils.get('accountid'),
             });
        }

        if(StorgeUtils.get('talentToken') && usertype !== 'college'){
            console.log('当前用户为人才')
            Object.assign(headers, {
                'access_token':  StorgeUtils.get('talentToken'),
            });
        }
        if(StorgeUtils.get('token') && usertype == 'college'){
            Object.assign(headers, {
                'access_token':  StorgeUtils.get('token'),
            });
        }
        return new Promise((resolve, reject) => {
            instance({
                baseURL,
                headers,
                method,
                url,
                params,
                data,
                responseType,
            }).then((res:any) => {
                // resolve( res.data);
                /*200:服务端业务处理正常结束*/
                //console.log('res',res)
                if (res.status === 200) {
                    resolve({ status: true,data:res.data });
                } else {
                    resolve({ status: false, data: res.data});
                }
            }).catch((err) => {
                const message = err?.data?.Errmsg;
                console.log(err,'message')
                if(message != '无效的凭据'){
                  if(message) {
                    ElMessage({
                        message:message,
                        offset:50
                    });
                  }
                }
                reject({ status: false, data: null});
            });
        });
    }

    // body 参数处理
    private buildPostParams(params : any){
        const paramsArray:Array<string> = [];
        Object.keys(params).forEach((key) => {
            //console.log(params[key] instanceof Object);
            if(params[key] instanceof Array){
                params[key].forEach((val: any)=>{
                    paramsArray.push(key+'['+params[key].indexOf(val)+']'+'=' + encodeURIComponent(val));
                });
            }else if(params[key] instanceof Object){
                Object.keys(params[key]).forEach((keys) => {
                    paramsArray.push(key+'['+keys+']'+'=' + encodeURIComponent(params[key][keys]));
                })
            }else{
                paramsArray.push(key + '=' + encodeURIComponent(params[key]));
            }
        });
        params = paramsArray.join("&");
        return params;
    }


    //JS加密
    private encrypt (clientId: any) {
        const data = clientId + '--' + this.convertDate(new Date());
        const key = CryptoJS.enc.Utf8.parse('607490BE-18CA-43D7-B11A-57E2621B');
        const iv = CryptoJS.enc.Utf8.parse('2D59831C-78AC-4227-B3F3-CE656636');
        const encrypted = CryptoJS.AES.encrypt(data, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        return encrypted.ciphertext.toString(CryptoJS.enc.Base64);
    }


    //JS加密
    private convertDate = function(date: any) {
        const res = date.getFullYear() + ',' + (date.getMonth() + 1) + ',' + date.getDate() + ',' + date.getHours() + ',' + date.getMinutes() + ',' + date.getSeconds();
        return res;
    };

    /**
     * GET类型的网络请求
     */
     public get(request: AxiosRequest) {
        return this.apiAxios({  method: 'GET',...request});
    }
    /**
     * GET类型的网络请求   广告
     */
    public getCd({  headers, url, data, params, responseType,isCd = true,isCollege = false  }: AxiosRequest) {
        return this.apiAxios({  headers, method: 'GET', url, data, params, responseType,isCd,isCollege});
    }
    /**
     * POST类型的网络请求
     */
    public post({  headers, url, data, params, responseType,isCollege = false}: AxiosRequest) {
        return this.apiAxios({ headers, method: 'POST', url, data, params, responseType,isCollege });
    }
    /**
     * POST类型的网络请求  广告
     */
    public postCd({  headers, url, data, params, responseType,isCd = true,isCollege = false}: AxiosRequest) {
        return this.apiAxios({  headers, method: 'POST', url, data, params, responseType,isCd,isCollege });
    }

    /**
     * POST类型的网络请求  代理
     */
    public postAgent({  headers = {}, url, data , params, responseType,isAgency = true,isCollege = false}: AxiosRequest) {
        return this.apiAxios({  headers, method: 'POST', url, data, params, responseType,isAgency,isCollege });
    }

    /**
     * PUT类型的网络请求
     */
     public put({  headers, url, data, params, responseType,isCollege = false }: AxiosRequest) {
        return this.apiAxios({  headers, method: 'PUT', url, data, params, responseType,isCollege });
    }

    /**
     * DELETE类型的网络请求
     */
    public delete({  url, data, params, responseType,isCollege = false }: AxiosRequest) {
        return this.apiAxios({ method: 'DELETE', url, data, params, responseType,isCollege });
    }
}

export default Abstract;