//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, PropType, reactive, ref, inject } from "vue";
import { imageitemdata } from "../form/dataform";
export default defineComponent({
  name: "cfImageshow",
  props: {
    item: {
      type: Object as PropType<imageitemdata>,
      default: null,
    },
    value: String,
  },
  emits: ["input", "valuechange"],
  setup(props, context) {
    const datavalue = ref(props.value);
    const fit = ref<string>("contain");
    const image = ref<string>("");
    const signMode: any = inject("signMode");
    if (datavalue.value) {
      image.value = datavalue.value;
      context.emit("valuechange", image.value);
    }
    return {
      datavalue,
      fit,
      image,
      signMode,
    };
  },
});
