export abstract class itemdata
{
    field!:string;
    name!:string;
    control_type!:string;
    prompt_msg?:string;
    required!:boolean;
    remark?:string | null;
    dict_type?:string;
    regular?:string | null;
    is_disable?:boolean;
    is_talent_view?:boolean;
}

export class formschema

{
    name!:string;
    data!:Array<itemdata | mutilineitemdata | selectitemdata | imageitemdata | textitemdata>
}

export class mutilineitemdata extends itemdata
{
    row?:number;
}

export class selectitemdata extends itemdata
{
    datasource!:string;
    lockvalues?:Array<string> | null;
    exceptvalues?:Array<string> | null;
    options?:Array<string> | null;
    code_options?:Array<{code:string,text:string}> | null;
}

export class imageitemdata extends itemdata{
    protocol_photos?:Array<string>
}

export class textitemdata extends itemdata{
    lockvalue?:string | null;
    type?:string | null
}

export class areaitem{
    label!:string;
    value!:string;
}
export class fileditem{
    field!:string;
    value!:string;
}

export class signmodeitem{
    signMode!:number;
    signText!:string;
    signImg!:string;
    remark!:string;
    signStr!:string;
}

export enum itemtype
{
    cfNumber = "number",
    cfText = "textbox",
    cfMutiline = "textarea",
    cfSelect = "dropdown",
    cfImage = "uploadimage",
    cfDate = "datetime",
    cfAreaSelect = "areaselect",
    cfImageshow = 'singleimageupload'
}

export enum signMode{
    online = 1,
    offline = 2,
    paper = 0
}
export type FormItem = itemdata | mutilineitemdata | selectitemdata | imageitemdata | textitemdata 
export class requestStateitem{
    agreement_state!: number
    remark!: string
    state_accountid!:string
    state_accountname!:string
    state_time!:string
    stateby!:string
    stateStr!:string
}