//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, PropType, provide, ref } from "vue";
import { formschema, FormItem } from "../form/dataform";
import _textarea from "./cfMutiline.vue";
import dropdown from "./cfSelect.vue";
import number from "./cfNumber.vue";
import textbox from "./cfText.vue";
import imageupload from "./cfImage.vue";
import singleimageupload from "./cfImageshow.vue";
import datetime from "./cfDate.vue";
import areaselect from "./cfAreaSelect.vue";
import { dict } from "../form/dictProvider";

export default defineComponent({
  name: "dForm",
  components: {
    _textarea,
    dropdown,
    number,
    textbox,
    imageupload,
    datetime,
    areaselect,
    singleimageupload
  },
  props: {
    formconfig: {
      type: Object as PropType<Array<formschema>>,
      default: null,
    },
    data: {
      type: Object as PropType<Map<string, any>>,
      default: null,
    },
  },
  emits: ["input"],
  setup(props) {
    console.log("高校组件接收数据", props.formconfig, props.data);
    const data = ref(props.data);
    //console.log(data, typeof data.value);
    const forms = ref<FormItem[]>([]);
    for (let item of props.formconfig) {
      forms.value = [...item.data];
    }
    const GetControlType = (control_type:string)=>{
      let controlType = control_type
      if(controlType === 'dropdownCode'){
        controlType = 'dropdown'
      }
      return controlType
    }
    //console.log(forms.value);
    const GetValue = ( itemname: string) => {
      const v = data.value.get(itemname);
      //console.log(itemname,v);
      if (v) {
        return v;
      }

      return null;
    };
    const SetValue = ( itemname: string, itemevent: any) => {
      //console.log(itemevent);
      data.value.set(itemname, itemevent);
      //console.log(data);
    };

    //实例化dictprovider
    const dictins = new dict();
    provide<dict>("dict", dictins);

    return {GetControlType, GetValue, SetValue, forms };
  },
});
