//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

    import { defineComponent, inject, PropType, reactive, ref } from "vue";
    import { imageitemdata } from "../form/dataform";
    import { ElMessage } from "element-plus";
    import Constants from "@/http/Constant";
    import Api from "@/http/Api";
    import { useStore } from "vuex";
    import { key } from "./../state/store";
    export default defineComponent({
        name: "cfImage",
        props: {
            item: {
                type: Object as PropType<imageitemdata>,
                default: null,
            },
            value: Array as PropType<string[]>,
        },
        emits: ["input","valuechange"],
        setup(props,context) {
            const store = useStore(key);
            const datavalue = ref(props.value);
            const fit = ref<string>('contain');
            const imageList = reactive(new Array<string>());
            const imgLsit:Array<string> = [];
            const uploadUrl = `${Constants.ApiUrl}/signup/v3/uploadimage`;
            const signMode: any = inject("signMode");
            const agreementState: any = inject("agreementState");
            if(datavalue.value){
              if(typeof datavalue.value === 'string'){
                datavalue.value = [datavalue.value]
              }
              for(let item of datavalue.value){
                imageList.push(item)
                imgLsit.push(item)
              }
              console.log(imageList)
            }

            const upLoadFile = (file) =>{
              console.log('上传文件格式', file.type)
              if (!file.type.includes('image')) {
                ElMessage.error('请上传jpg或png图片!')
                return false
              } else if(file.size > 2097152){
                ElMessage.error('请上传低于2M的图片!')
                return false
              }
              let reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onloadend = () =>{
                let imageData = reader.result;
                console.log(typeof imageData);
                if(imageData){
                  imageData = replaceStr(imageData);
                  let data = {value:imageData};
                  Api.uploadImage(data).then((res) => {
                    if(res.status){
                      imageList.push(res.data.value);
                      imgLsit.push(res.data.value)
                      //datavalue.value?.push(res.data.value);
                      console.log(imgLsit);
                      context.emit('valuechange',imgLsit)
                    }
                  })
                }
              }
              
              //Api.uploadImage().
              //console.log(file)
              //console.log(1);
              return false;
            }

            const replaceStr = (imageData:any) =>{
              let result = imageData;
              result = result.replace(/^(data:image)\/jpeg;base64,/g, '')
              result = result.replace(/^(data:image)\/png;base64,/g, '')
              return result;
            }
            const delImg = (index:number) =>{
              imageList.splice(index,1);
              imgLsit.splice(index,1);
              context.emit('valuechange',imgLsit)
            }
            const viewImg = (index:number) =>{
              let width = 700
              let height = 850
              let left = (window.screen.availWidth-width)/2
              let top = (window.screen.availHeight-height)/2
              console.log(888777,left,top)
              localStorage.setItem('imageListOnce',JSON.stringify(imageList))
              localStorage.setItem('imageIndexOnce',JSON.stringify(index))
              window.open(`#/imgView?t=${~new Date()}`,'imgView',`width=${width}, height=${height}, left=${left}, top=${top}`)
            }
            const pretreatment = ref(true)
            const getAbbreviateImg = (url:string)=>{
              if(!pretreatment.value)return url
              if(url.includes('?')){
                return url+'&imageView2/0/w/100/h/100'
              }
              return url+'?imageView2/0/w/100/h/100'
            }
            return { 
              datavalue,
              fit,
              imageList, 
              upLoadFile,
              uploadUrl,
              signMode,
              store,
              delImg,
              agreementState,
              viewImg,
              getAbbreviateImg,
              pretreatment
            }
        },
        computed:{
          stateFieldRemark:function(){
            return this.store.state.fieldRemark
          }
        }
    });
