
import { defineComponent, onMounted } from "vue";
import { StorgeUtils } from "@/utils/StorageUtils";
import Constants from "@/http/Constant";

export default defineComponent({
  name: "App",
  setup() {
    const receiveMessage = () =>{
      window.addEventListener('message',(e) =>{
        console.log(e)
        if(e.origin == Constants.origin || e.origin == 'http://127.0.0.1:4200' || e.origin == Constants.originCollege || e.origin == "http://127.0.0.1:5500"){
          for(let key in e.data){
             StorgeUtils.set(key,e.data[key])
          }
        }
      })
    }
    onMounted(() =>{
      receiveMessage();
      //document.body.style.setProperty('--el-color-primary','#5d479a')
    })
    
    return { receiveMessage };
  },
});
