//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, inject, PropType, ref, toRefs } from "vue";
import { selectitemdata } from "../form/dataform";
import { dict} from "../form/dictProvider";
import { areaitem } from "../form/dataform"
import { useStore } from "vuex";
import { key } from "./../state/store";


export default defineComponent({
  name: "cfAreaSelect",
  props: {
    item: {
      type: Object as PropType<selectitemdata>,
      default: null,
    },
    value: String,
  },
  emits: ["input", "valuechange"],
  setup(props, context) { 
    const store = useStore(key);
    let item = toRefs(props).item.value;
    let datavalue = ref(props.value); 
    let allProvinceList :Array<areaitem> = [];
    let allCityList : Array<areaitem> = [];
    let allCountyList : Array<areaitem> = [];
    let provinceList = ref<areaitem[]>([])
    let cityList =  ref<areaitem[]>([]);
    let countyList = ref<areaitem[]>([])
    let provience  = ref<string>('');
    let city = ref<string>('');
    let county = ref<string>('');
    const dictprovide = inject<dict>("dict");
    if (dictprovide) {
      if (item) {
        if(item.field=='department_region'){
          store.commit("departmentRegionShowChange", true);
        }
        //console.log(item)
        let source = item?.dict_type;
        let listRes = dictprovide.GetDict(source);
        listRes.then( res => {
          //对地区数据进行分类
          res.forEach((val) =>{
              if(val.level == 1){
                  let data:areaitem = {label: val.text, value: val.code}
                  allProvinceList.push(data);
              }
              if(val.level == 2){
                  let data:areaitem = {label: val.text, value: val.code}
                  allCityList.push(data);
              }
              if(val.level == 3){
                  let data:areaitem = {label: val.text, value: val.code}
                  allCountyList.push(data);
              }
          })

          provinceList.value = allProvinceList;
          //数据初始化
          if(datavalue.value){
              //console.log(datavalue.value)
              if(datavalue.value.length == 6){
              provience.value = datavalue.value.substring(0,2);
              cityList.value = allCityList.filter((item) => item.value.substring(0,2) == provience.value)
              city.value = datavalue.value.substring(0,4);
              countyList.value = allCountyList.filter((item) => item.value.substring(0,4) == city.value);
              county.value = datavalue.value;
              } else {
                datavalue.value = ''
                context.emit("valuechange", '')
              }
          }
        })
       
      }
    }
    //省份切换
    const provienceChange = (val) =>{
        provience.value = val;
        cityList.value = allCityList.filter((item) => item.value.substring(0,2) == provience.value) 
        countyList.value = [];
        city.value = '';
        county.value = '';
        context.emit("valuechange", '')
    }
    //城市切换
    const cityChange = (val) =>{
        city.value = val;
        countyList.value = allCountyList.filter((item) => item.value.substring(0,4) == city.value)
        county.value = '';
        context.emit("valuechange", '')
    }
    //区域切换
    const countyChange = (val) =>{
        county.value = val;
        context.emit("valuechange", county.value)
    }
    return {  
        datavalue, 
        provinceList,   
        cityList,
        countyList,
        provience,
        city,
        county,
        provienceChange,
        cityChange,
        countyChange,
        store,
    };
  },
  computed:{
    stateFieldRemark:function(){
      return this.store.state.fieldRemark
    }
  }
});
