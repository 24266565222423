//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, PropType, ref } from "vue";
import { mutilineitemdata } from "../form/dataform";
import { useStore } from 'vuex'
import { key } from './../state/store' 

export default defineComponent({
  name: "cfMutiline",
  props: {
    item: {
      type: Object as PropType<mutilineitemdata>,
      default: null,
    },
    value: String,
  },
  emits: ["input"],
  setup(props,context) {
    const store = useStore(key);

    const datavalue = ref(props.value);

    const change = () =>{
      context.emit('input',datavalue.value)
    }
    return { datavalue,change,store }
  }

});
