import { formschema } from "./dataform";
function getTypeConfig(type: string) {
  switch (type) {
    case 'textbox':
      return {
        "lockvalue": "",
        "prompt_msg": "",
        "required": true,
        "remark": null,
        "regular": "",
        "is_disable": false
      }
    case 'dropdown':
      return {
        "dict_type": null,
        "lockvalues": null,
        "exceptvalues": null,
        "prompt_msg": "",
        "required": true,
        "remark": null,
        "regular": "",
        "is_disable": false,
      }
    case 'datetime':
      return {
        "prompt_msg": "",
        "required": true,
        "remark": null,
        "regular": "",
        "is_disable": false,
      }
    case 'areaselect':
      return {
        "dict_type": "",
        "lockvalues": null,
        "exceptvalues": null,
        "prompt_msg": "",
        "required": true,
        "remark": null,
        "regular": "",
        "is_disable": false
      }
    case 'imageupload':
      return {
        "protocol_photos": [],
        "prompt_msg": "",
        "required": true,
        "remark": null,
        "regular": "",
        "is_disable": false,
      }
    default:
      return {}
  }
}
function getFieldAllConfig(fieldConfig: any) {
  let config: any = {}
  switch (fieldConfig.field) {
    case 'employment_type':
      config = {
        "dict_type": "employment_type",
        "name": "就业类别",
        "control_type": "dropdown",
      }
      break;

    case 'graduation':
      config = {
        "dict_type": "graduation",
        "name": "毕业去向",
        "control_type": "dropdown",
        "is_talent_view": true
      }
      break;

    case 'employer_type':
      config = {
        "dict_type": "employer_type_gb",
        "name": "单位性质",
        "control_type": "dropdown",
        "is_talent_view": true
      }
      break;
    case 'employment_status':
      config = {
        "dict_type": "employment_status",
        "exceptvalues": [
          "不就业拟升学"
        ],
        "name": "就业状况",
        "control_type": "dropdown",
        "is_talent_view": true
      }
      break;
    case 'unemployment_type':
      config = {
        "dict_type": "unemployment_type",
        "name": "未就业类别",
        "control_type": "dropdown",
        "is_talent_view": true
      }
      break;
    case 'is_basic_level':
      config = {
        "dict_type": "yesno",
        "name": "是否属于到基层",
        "control_type": "dropdown",
        "is_talent_view": true
      }
      break;
    case 'department_name':
      config = {
        "lockvalue": null,
        "name": "扩展项5(签约单位名称)",
        "control_type": "textbox",
        "prompt_msg": "签约单位名称",
        "is_talent_view": true
      }
      break;
    case 'register_number':
      config = {
        "lockvalue": null,
        "name": "统一社会信用代码",
        "control_type": "textbox",
        "regular": "social_credit_code",
        "is_talent_view": true
      }
      break;
    case 'industry':
      config = {
        "dict_type": "industry_gb",
        "name": "单位行业",
        "control_type": "dropdown",
        "is_talent_view": true
      }
      break;
    case 'post_type':
      config = {
        "dict_type": "position_type_gb",
        "name": "工作职位类别",
        "control_type": "dropdown",
        "is_talent_view": true
      }
      break;
    case 'actual_location':
      config = {
        "dict_type": "region",
        "name": "实际所在地",
        "control_type": "areaselect",
        "is_talent_view": true
      }
      break;
    case 'department_address':
      config = {
        "lockvalue": null,
        "name": "单位地址",
        "control_type": "textbox",
        "is_talent_view": true
      }
      break;
    case 'linkman':
      config = {
        "lockvalue": null,
        "name": "单位联系人",
        "control_type": "textbox",
        "regular": "withoutNum",
        "is_talent_view": true
      }
      break;
    case 'department_phone':
      config = {
        "lockvalue": null,
        "name": "单位办公电话",
        "control_type": "textbox",
        "regular": "phone",
        "is_talent_view": true
      }
      break;
    case 'department_mobile':
      config = {
        "lockvalue": null,
        "name": "联系人手机号",
        "control_type": "textbox",
        "regular": "phone",
        "is_talent_view": true
      }
      break;
    case 'department_email':
      config = {
        "lockvalue": null,
        "name": "单位电邮",
        "control_type": "textbox",
        "regular": "email",
        "is_talent_view": true
      }
      break;
    case 'protocol_photos':
      config = {
        "name": "附件",
        "control_type": "imageupload",
        "is_talent_view": true
      }
      break;
  }
  const typeConfig = getTypeConfig(config.control_type || fieldConfig.control_type)
  return {
    ...typeConfig,
    ...config,
    ...fieldConfig
  }
}
const formList = JSON.stringify([
  {
    "name": "student_source",
    "data": [
      {
        "field": "no",
        "name": "学号",
        "control_type": "textbox"
      },
      {
        "field": "name",
        "name": "姓名",
        "control_type": "textbox"
      },
      {
        "field": "id_number",
        "name": "身份证号",
        "control_type": "textbox",
        "regular": "id_card_number"
      },
      {
        "dict_type": "gender",
        "field": "gender",
        "name": "性别",
        "control_type": "dropdown"
      },
      {
        "dict_type": "education",
        "field": "education",
        "name": "学历",
        "control_type": "dropdown"
      },
      {
        "dict_type": "source_major",
        "field": "source_major",
        "name": "专业",
        "control_type": "dropdown"
      },
      {
        "dict_type": "normal_student_type",
        "field": "normal_student_type",
        "name": "师范生类别",
        "control_type": "dropdown"
      },
      {
        "dict_type": "normal_student_sign",
        "field": "normal_student_sign",
        "name": "师范标志",
        "control_type": "dropdown"
      },
      {
        "dict_type": "hardship_student_type",
        "field": "hardship_student_type",
        "name": "困难生类别",
        "control_type": "dropdown"
      },
      {
        "dict_type": "filing_card_poor_family",
        "field": "filing_card_poor_family",
        "name": "是否属于建档立卡贫困家庭",
        "control_type": "dropdown"
      },
      {
        "dict_type": "training_mode",
        "field": "training_mode",
        "name": "培养方式",
        "control_type": "dropdown"
      },
      {
        "field": "orientation_department_name",
        "name": "定向或委培单位名称",
        "control_type": "textbox",
        "is_talent_view": false
      },
      {
        "dict_type": "school_length",
        "field": "school_length",
        "name": "学制",
        "control_type": "dropdown"
      },
      {
        "dict_type": "nation",
        "field": "nation",
        "name": "民族",
        "control_type": "dropdown"
      },
      {
        "dict_type": "political_status",
        "field": "political_status",
        "name": "政治面貌",
        "control_type": "dropdown"
      },
      {
        "field": "birthdate",
        "name": "出生日期",
        "control_type": "datetime",
        "prompt_msg": "请选择出生日期",
        "is_talent_view": true
      },
      {
        "field": "start_time",
        "name": "入学时间",
        "control_type": "datetime",
        "prompt_msg": "请选择入学时间"
      },
      {
        "field": "end_time",
        "name": "毕业时间",
        "control_type": "datetime",
        "prompt_msg": "请选择毕业时间"
      },
      {
        "dict_type": "region",
        "field": "native_place",
        "name": "生源地区",
        "control_type": "areaselect"
      },
      {
        "dict_type": "student_source",
        "field": "student_source",
        "name": "城乡生源",
        "control_type": "dropdown"
      },
      {
        "field": "examinee_number",
        "name": "考生号",
        "control_type": "textbox"
      },
      {
        "field": "mobile",
        "name": "联系方式",
        "control_type": "textbox",
        "regular": "phone"
      },
      {
        "field": "email",
        "name": "电子邮箱",
        "control_type": "textbox",
        "regular": "email"
      },
      {
        "field": "home_phone",
        "name": "家庭电话",
        "control_type": "textbox",
        "regular": "phone"
      }
    ]
  },
  {
    "name": "sign_base",
    "data": [
      {
        "field": "employment_status"
      },
    ]
  },
  {
    // 就业
    "name": "sign_status_01",
    "data": [
      {
        "exceptvalues": [
          "灵活就业",
          "求职中",
          "签约中",
          "拟参加公招考试",
          "拟创业",
          "拟应征入伍",
          "不就业拟升学",
          "暂不就业",
          "拟出国出境",
          "自主创业",
          "自由职业",
          "录取研究生",
          "录取第二学士学位",
          "专科升普通本科",
          "出国、出境"
        ],
        "field": "employment_type",
      },
    ]
  },
  {
    // 自主创业
    "name": "sign_status_02",
    "data": [
      {
        "lockvalues": [
          "自主创业"
        ],
        "field": "employment_type",
      },
    ]
  },
  {
    // 待就业
    "name": "sign_status_03",
    "data": [
      {
        "exceptvalues": [
          "签就业协议形式就业",
          "签劳动合同形式就业",
          "灵活就业",
          "科研助理、管理助理",
          "博士后入站",
          "应征义务兵",
          "特岗教师（国家基层）",
          "三支一扶",
          "西部计划",
          "特岗教师（地方基层）",
          "选调生",
          "农技特岗",
          "乡村医生",
          "乡村教师",
          "其它地方基层项目",
          "自主创业",
          "自由职业",
          "录取研究生",
          "录取第二学士学位",
          "专科升普通本科",
          "出国、出境",
        ],
        "field": "employment_type",
      },
    ]
  },
  {
    // 灵活就业
    "name": "sign_status_04",
    "data": [
      {
        "lockvalues": [
          "灵活就业"
        ],
        "field": "employment_type",
      },
    ]
  },
  {
    // 自由职业
    "name": "sign_status_05",
    "data": [
      {
        "lockvalues": [
          "自由职业"
        ],
        "field": "employment_type",
      },
    ]
  },
  {
    // 升学
    "name": "sign_status_06",
    "data": [
      {
        "exceptvalues": [
          "签就业协议形式就业",
          "签劳动合同形式就业",
          "灵活就业",
          "科研助理、管理助理",
          "博士后入站",
          "应征义务兵",
          "特岗教师（国家基层）",
          "三支一扶",
          "西部计划",
          "特岗教师（地方基层）",
          "选调生",
          "农技特岗",
          "乡村医生",
          "乡村教师",
          "其它地方基层项目",
          "求职中",
          "签约中",
          "拟参加公招考试",
          "拟创业",
          "拟应征入伍",
          "不就业拟升学",
          "暂不就业",
          "拟出国出境",
          "自主创业",
          "自由职业",
          "出国、出境"
        ],
        "field": "employment_type",
      },
    ]
  },
  {
    // 出国
    "name": "sign_status_07",
    "data": [
      {
        "lockvalues": [
          "出国、出境"
        ],
        "field": "employment_type",
      },
    ]
  },
  {
    // 签就业协议形式就业
    "name": "sign_01",
    "data": [
      {
        "lockvalues": [
          "非派遣"
        ],
        "field": "graduation"
      },
      {
        "exceptvalues": [
          "科研助理",
          "部队",
          "国家基层项目",
          "地方基层项目",
          "待就业",
          "不就业拟升学",
          "其他暂不就业",
          "自主创业",
          "自由职业",
          "升学",
          "出国、出境"
        ],
        "field": "employer_type"
      },
      // {
      //   "lockvalues": [
      //     "就业"
      //   ],
      //   "field": "employment_status"
      // },
      {
        "field": "is_basic_level"
      },
      {
        "field": "department_name"
      },
      {
        "field": "register_number"
      },
      {
        "exceptvalues": [
          "军队"
        ],
        "field": "industry"
      },
      {
        "exceptvalues": [
          "军人"
        ],
        "field": "post_type"
      },
      {
        "field": "actual_location"
      },
      {
        "field": "linkman"
      },
      {
        "field": "department_phone"
      },
      {
        "field": "department_mobile"
      },
      {
        "field": "department_email"
      },
      // {
      //   "field": "protocol_photos"
      // }
    ]
  },
  {
    // 签劳动合同形式就业
    "name": "sign_02",
    "data": [
      {
        "lockvalues": [
          "非派遣"
        ],
        "field": "graduation"
      },
      {
        "exceptvalues": [
          "科研助理",
          "部队",
          "国家基层项目",
          "地方基层项目",
          "待就业",
          "不就业拟升学",
          "其他暂不就业",
          "自主创业",
          "自由职业",
          "升学",
          "出国、出境"
        ],
        "field": "employer_type"
      },
      // {
      //   "lockvalues": [
      //     "就业"
      //   ],
      //   "field": "employment_status"
      // },
      {
        "field": "is_basic_level"
      },
      {
        "field": "department_name"
      },
      {
        "field": "register_number"
      },
      {
        "exceptvalues": [
          "军队"
        ],
        "field": "industry"
      },
      {
        "exceptvalues": [
          "军人"
        ],
        "field": "post_type"
      },
      {
        "field": "actual_location"
      },
      {
        "field": "linkman"
      },
      {
        "field": "department_phone"
      },
      {
        "field": "department_mobile"
      },
      {
        "field": "department_email"
      },
      // {
      //   "field": "protocol_photos"
      // }
    ]
  },
  {
    // 灵活就业
    "name": "sign_03",
    "data": [
      {
        "lockvalues": [
          "非派遣"
        ],
        "field": "graduation"
      },
      {
        "exceptvalues": [
          "科研助理",
          "部队",
          "国家基层项目",
          "地方基层项目",
          "待就业",
          "不就业拟升学",
          "其他暂不就业",
          "自主创业",
          "自由职业",
          "升学",
          "出国、出境"
        ],
        "field": "employer_type"
      },
      // {
      //   "lockvalues": [
      //     "灵活就业"
      //   ],
      //   "field": "employment_status"
      // },
      {
        "field": "is_basic_level"
      },
      {
        "field": "department_name"
      },
      {
        "field": "register_number"
      },
      {
        "exceptvalues": [
          "军队"
        ],
        "field": "industry"
      },
      {
        "exceptvalues": [
          "军人"
        ],
        "field": "post_type"
      },
      {
        "exceptvalues": [
          "国外"
        ],
        "field": "actual_location"
      },
      {
        "field": "linkman"
      },
      {
        "field": "department_phone"
      },
      {
        "field": "department_email"
      },
      {
        "field": "department_mobile"
      },
      // {
      //   "field": "protocol_photos"
      // }
    ]
  },
  {
    // 科研助理、管理助理
    "name": "sign_04",
    "data": [
      {
        "lockvalues": [
          "非派遣"
        ],
        "field": "graduation"
      },
      {
        "lockvalues": [
          "科研助理"
        ],
        "field": "employer_type"
      },
      // {
      //   "lockvalues": [
      //     "就业"
      //   ],
      //   "field": "employment_status"
      // },
      {
        "field": "is_basic_level"
      },
      {
        "field": "department_name"
      },
      {
        "exceptvalues": [
          "军队"
        ],
        "field": "industry"
      },
      {
        "exceptvalues": [
          "军人"
        ],
        "field": "post_type"
      },
      {
        "exceptvalues": [
          "国外"
        ],
        "field": "actual_location"
      },
      {
        "field": "linkman"
      },
      {
        "field": "department_phone"
      },
      {
        "field": "department_email"
      },
      // {
      //   "field": "protocol_photos"
      // }
    ]
  },
  {
    // 博士后入站
    "name": "sign_20",
    "data": [
      {
        "lockvalues": [
          "非派遣"
        ],
        "field": "graduation"
      },
      {
        "lockvalues": [
          "科研助理"
        ],
        "field": "employer_type"
      },
      // {
      //   "lockvalues": [
      //     "就业"
      //   ],
      //   "field": "employment_status"
      // },
      {
        "field": "is_basic_level"
      },
      {
        "field": "department_name"
      },
      {
        "exceptvalues": [
          "军队"
        ],
        "field": "industry"
      },
      {
        "exceptvalues": [
          "军人"
        ],
        "field": "post_type"
      },
      {
        "exceptvalues": [
          "国外"
        ],
        "field": "actual_location"
      },
      {
        "field": "linkman"
      },
      {
        "field": "department_phone"
      },
      {
        "field": "department_email"
      },
      // {
      //   "field": "protocol_photos"
      // }
    ]
  },
  {
    // 应征义务兵
    "name": "sign_05",
    "data": [
      {
        "lockvalues": [
          "非派遣"
        ],
        "field": "graduation"
      },
      {
        "lockvalues": [
          "部队"
        ],
        "field": "employer_type"
      },
      // {
      //   "lockvalues": [
      //     "就业"
      //   ],
      //   "field": "employment_status"
      // },
      {
        "field": "is_basic_level"
      },
      {
        "lockvalue": "应征义务兵",
        "field": "department_name",
        "prompt_msg": "",
        "is_disable": true,
      },
      {
        "lockvalues": [
          "军队"
        ],
        "field": "industry"
      },
      {
        "lockvalues": [
          "军人"
        ],
        "field": "post_type"
      },
      {
        "exceptvalues": [
          "国外"
        ],
        "field": "actual_location"
      },
      // {
      //   "field": "linkman"
      // },
      // {
      //   "field": "department_phone"
      // },
      // {
      //   "field": "department_email"
      // },
      // {
      //   "field": "protocol_photos"
      // }
    ]
  },
  {
    // 特岗教师（国家基层）
    "name": "sign_06",
    "data": [
      {
        "lockvalues": [
          "非派遣"
        ],
        "field": "graduation"
      },
      {
        "lockvalues": [
          "国家基层项目"
        ],
        "field": "employer_type"
      },
      // {
      //   "lockvalues": [
      //     "就业"
      //   ],
      //   "field": "employment_status"
      // },
      {
        "lockvalues": [
          "是"
        ],
        "field": "is_basic_level"
      },
      {
        "field": "department_name"
      },
      {
        "exceptvalues": [
          "军队"
        ],
        "field": "industry"
      },
      {
        "exceptvalues": [
          "军人"
        ],
        "field": "post_type"
      },
      {
        "exceptvalues": [
          "国外"
        ],
        "field": "actual_location"
      },
      {
        "field": "linkman"
      },
      {
        "field": "department_phone"
      },
      {
        "field": "department_email"
      },
      // {
      //   "field": "protocol_photos"
      // }
    ]
  },
  {
    // 三支一扶
    "name": "sign_21",
    "data": [
      {
        "lockvalues": [
          "非派遣"
        ],
        "field": "graduation"
      },
      {
        "lockvalues": [
          "国家基层项目",
          "地方基层项目"
        ],
        "field": "employer_type"
      },
      // {
      //   "lockvalues": [
      //     "就业"
      //   ],
      //   "field": "employment_status"
      // },
      {
        "lockvalues": [
          "是"
        ],
        "field": "is_basic_level"
      },
      {
        "field": "department_name"
      },
      {
        "exceptvalues": [
          "军队"
        ],
        "field": "industry"
      },
      {
        "exceptvalues": [
          "军人"
        ],
        "field": "post_type"
      },
      {
        "exceptvalues": [
          "国外"
        ],
        "field": "actual_location"
      },
      {
        "field": "linkman"
      },
      {
        "field": "department_phone"
      },
      {
        "field": "department_email"
      },
      // {
      //   "field": "protocol_photos"
      // }
    ]
  },
  {
    // 西部计划
    "name": "sign_22",
    "data": [
      {
        "lockvalues": [
          "非派遣"
        ],
        "field": "graduation"
      },
      {
        "lockvalues": [
          "国家基层项目",
          "地方基层项目"
        ],
        "field": "employer_type"
      },
      // {
      //   "lockvalues": [
      //     "就业"
      //   ],
      //   "field": "employment_status"
      // },
      {
        "lockvalues": [
          "是"
        ],
        "field": "is_basic_level"
      },
      {
        "field": "department_name"
      },
      {
        "exceptvalues": [
          "军队"
        ],
        "field": "industry"
      },
      {
        "exceptvalues": [
          "军人"
        ],
        "field": "post_type"
      },
      {
        "exceptvalues": [
          "国外"
        ],
        "field": "actual_location"
      },
      {
        "field": "linkman"
      },
      {
        "field": "department_phone"
      },
      {
        "field": "department_email"
      },
      // {
      //   "field": "protocol_photos"
      // }
    ]
  },
  {
    // 特岗教师（地方基层）
    "name": "sign_07",
    "data": [
      {
        "lockvalues": [
          "非派遣"
        ],
        "field": "graduation"
      },
      {
        "lockvalues": [
          "地方基层项目"
        ],
        "field": "employer_type"
      },
      // {
      //   "lockvalues": [
      //     "就业"
      //   ],
      //   "field": "employment_status"
      // },
      {
        "lockvalues": [
          "是"
        ],
        "field": "is_basic_level"
      },
      {
        "field": "department_name"
      },
      {
        "exceptvalues": [
          "军队"
        ],
        "field": "industry"
      },
      {
        "exceptvalues": [
          "军人"
        ],
        "field": "post_type"
      },
      {
        "exceptvalues": [
          "国外"
        ],
        "field": "actual_location"
      },
      {
        "field": "linkman"
      },
      {
        "field": "department_phone"
      },
      {
        "field": "department_email"
      },
      // {
      //   "field": "protocol_photos"
      // }
    ]
  },
  {
    // 选调生
    "name": "sign_23",
    "data": [
      {
        "lockvalues": [
          "非派遣"
        ],
        "field": "graduation"
      },
      {
        "lockvalues": [
          "国家基层项目",
          "地方基层项目"
        ],
        "field": "employer_type"
      },
      // {
      //   "lockvalues": [
      //     "就业"
      //   ],
      //   "field": "employment_status"
      // },
      {
        "lockvalues": [
          "是"
        ],
        "field": "is_basic_level"
      },
      {
        "field": "department_name"
      },
      {
        "exceptvalues": [
          "军队"
        ],
        "field": "industry"
      },
      {
        "exceptvalues": [
          "军人"
        ],
        "field": "post_type"
      },
      {
        "exceptvalues": [
          "国外"
        ],
        "field": "actual_location"
      },
      {
        "field": "linkman"
      },
      {
        "field": "department_phone"
      },
      {
        "field": "department_email"
      },
      // {
      //   "field": "protocol_photos"
      // }
    ]
  },
  {
    // 农技特岗
    "name": "sign_24",
    "data": [
      {
        "lockvalues": [
          "非派遣"
        ],
        "field": "graduation"
      },
      {
        "lockvalues": [
          "地方基层项目"
        ],
        "field": "employer_type"
      },
      // {
      //   "lockvalues": [
      //     "就业"
      //   ],
      //   "field": "employment_status"
      // },
      {
        "lockvalues": [
          "是"
        ],
        "field": "is_basic_level"
      },
      {
        "field": "department_name"
      },
      {
        "exceptvalues": [
          "军队"
        ],
        "field": "industry"
      },
      {
        "exceptvalues": [
          "军人"
        ],
        "field": "post_type"
      },
      {
        "exceptvalues": [
          "国外"
        ],
        "field": "actual_location"
      },
      {
        "field": "linkman"
      },
      {
        "field": "department_phone"
      },
      {
        "field": "department_email"
      },
      // {
      //   "field": "protocol_photos"
      // }
    ]
  },
  {
    // 乡村医生
    "name": "sign_25",
    "data": [
      {
        "lockvalues": [
          "非派遣"
        ],
        "field": "graduation"
      },
      {
        "lockvalues": [
          "地方基层项目"
        ],
        "field": "employer_type"
      },
      // {
      //   "lockvalues": [
      //     "就业"
      //   ],
      //   "field": "employment_status"
      // },
      {
        "lockvalues": [
          "是"
        ],
        "field": "is_basic_level"
      },
      {
        "field": "department_name"
      },
      {
        "exceptvalues": [
          "军队"
        ],
        "field": "industry"
      },
      {
        "exceptvalues": [
          "军人"
        ],
        "field": "post_type"
      },
      {
        "exceptvalues": [
          "国外"
        ],
        "field": "actual_location"
      },
      {
        "field": "linkman"
      },
      {
        "field": "department_phone"
      },
      {
        "field": "department_email"
      },
      // {
      //   "field": "protocol_photos"
      // }
    ]
  },
  {
    // 乡村教师
    "name": "sign_26",
    "data": [
      {
        "lockvalues": [
          "非派遣"
        ],
        "field": "graduation"
      },
      {
        "lockvalues": [
          "地方基层项目"
        ],
        "field": "employer_type"
      },
      // {
      //   "lockvalues": [
      //     "就业"
      //   ],
      //   "field": "employment_status"
      // },
      {
        "lockvalues": [
          "是"
        ],
        "field": "is_basic_level"
      },
      {
        "field": "department_name"
      },
      {
        "exceptvalues": [
          "军队"
        ],
        "field": "industry"
      },
      {
        "exceptvalues": [
          "军人"
        ],
        "field": "post_type"
      },
      {
        "exceptvalues": [
          "国外"
        ],
        "field": "actual_location"
      },
      {
        "field": "linkman"
      },
      {
        "field": "department_phone"
      },
      {
        "field": "department_email"
      },
      // {
      //   "field": "protocol_photos"
      // }
    ]
  },
  {
    // 其它地方基层项目
    "name": "sign_27",
    "data": [
      {
        "lockvalues": [
          "非派遣"
        ],
        "field": "graduation"
      },
      {
        "lockvalues": [
          "地方基层项目"
        ],
        "field": "employer_type"
      },
      // {
      //   "lockvalues": [
      //     "就业"
      //   ],
      //   "field": "employment_status"
      // },
      {
        "lockvalues": [
          "是"
        ],
        "field": "is_basic_level"
      },
      {
        "field": "department_name"
      },
      {
        "exceptvalues": [
          "军队"
        ],
        "field": "industry"
      },
      {
        "exceptvalues": [
          "军人"
        ],
        "field": "post_type"
      },
      {
        "exceptvalues": [
          "国外"
        ],
        "field": "actual_location"
      },
      {
        "field": "linkman"
      },
      {
        "field": "department_phone"
      },
      {
        "field": "department_email"
      },
      // {
      //   "field": "protocol_photos"
      // }
    ]
  },
  {
    // 求职中
    "name": "sign_08",
    "data": [
      {
        "lockvalues": [
          "待分"
        ],
        "field": "graduation"
      },
      {
        "lockvalues": [
          "待就业"
        ],
        "field": "employer_type"
      },
      {
        "lockvalues": [
          "求职中"
        ],
        "field": "unemployment_type"
      },
      // {
      //   "lockvalues": [
      //     "待就业"
      //   ],
      //   "field": "employment_status"
      // },
      // {
      //   "field": "protocol_photos"
      // }
    ]
  },
  {
    // 签约中
    "name": "sign_28",
    "data": [
      {
        "lockvalues": [
          "待分"
        ],
        "field": "graduation"
      },
      {
        "lockvalues": [
          "待就业"
        ],
        "field": "employer_type"
      },
      {
        "lockvalues": [
          "签约中"
        ],
        "field": "unemployment_type"
      },
      // {
      //   "lockvalues": [
      //     "待就业"
      //   ],
      //   "field": "employment_status"
      // },
      // {
      //   "field": "protocol_photos"
      // }
    ]
  },
  {
    // 拟参加公招考试
    "name": "sign_31",
    "data": [
      {
        "lockvalues": [
          "待分"
        ],
        "field": "graduation"
      },
      {
        "lockvalues": [
          "待就业"
        ],
        "field": "employer_type"
      },
      {
        "lockvalues": [
          "拟参加公招考试"
        ],
        "field": "unemployment_type"
      },
      // {
      //   "lockvalues": [
      //     "待就业"
      //   ],
      //   "field": "employment_status"
      // },
      // {
      //   "field": "protocol_photos"
      // }
    ]
  },
  {
    // 拟创业
    "name": "sign_30",
    "data": [
      {
        "lockvalues": [
          "待分"
        ],
        "field": "graduation"
      },
      {
        "lockvalues": [
          "待就业"
        ],
        "field": "employer_type"
      },
      {
        "lockvalues": [
          "拟创业"
        ],
        "field": "unemployment_type"
      },
      // {
      //   "lockvalues": [
      //     "待就业"
      //   ],
      //   "field": "employment_status"
      // },
      // {
      //   "field": "protocol_photos"
      // }
    ]
  },
  {
    // 拟应征入伍
    "name": "sign_29",
    "data": [
      {
        "lockvalues": [
          "待分"
        ],
        "field": "graduation"
      },
      {
        "lockvalues": [
          "待就业"
        ],
        "field": "employer_type"
      },
      {
        "lockvalues": [
          "拟应征入伍"
        ],
        "field": "unemployment_type"
      },
      // {
      //   "lockvalues": [
      //     "待就业"
      //   ],
      //   "field": "employment_status"
      // },
      // {
      //   "field": "protocol_photos"
      // }
    ]
  },
  {
    // 不就业拟升学
    "name": "sign_09",
    "data": [
      {
        "lockvalues": [
          "不就业拟升学"
        ],
        "field": "graduation"
      },
      {
        "lockvalues": [
          "不就业拟升学"
        ],
        "field": "employer_type"
      },
      {
        "lockvalues": [
          "拟升学"
        ],
        "field": "unemployment_type"
      },
      // {
      //   "lockvalues": [
      //     "待就业"
      //   ],
      //   "field": "employment_status"
      // },
      // {
      //   "field": "protocol_photos"
      // }
    ]
  },
  {
    // 暂不就业
    "name": "sign_35",
    "data": [
      {
        "lockvalues": [
          "待分"
        ],
        "field": "graduation"
      },
      {
        "lockvalues": [
          "其他暂不就业"
        ],
        "field": "employer_type"
      },
      {
        "lockvalues": [
          "暂不就业"
        ],
        "field": "unemployment_type"
      },
      // {
      //   "lockvalues": [
      //     "待就业"
      //   ],
      //   "field": "employment_status"
      // },
      // {
      //   "field": "protocol_photos"
      // }
    ]
  },
  {
    // 拟出国出境
    "name": "sign_36",
    "data": [
      {
        "lockvalues": [
          "待分"
        ],
        "field": "graduation"
      },
      {
        "lockvalues": [
          "其他暂不就业"
        ],
        "field": "employer_type"
      },
      {
        "lockvalues": [
          "拟出国出境"
        ],
        "field": "unemployment_type"
      },
      // {
      //   "lockvalues": [
      //     "待就业"
      //   ],
      //   "field": "employment_status"
      // },
      // {
      //   "field": "protocol_photos"
      // }
    ]
  },
  {
    // 自主创业
    "name": "sign_11",
    "data": [
      {
        "lockvalues": [
          "非派遣"
        ],
        "field": "graduation"
      },
      {
        "lockvalues": [
          "自主创业"
        ],
        "field": "employer_type"
      },
      // {
      //   "lockvalues": [
      //     "自主创业"
      //   ],
      //   "field": "employment_status"
      // },
      {
        "field": "is_basic_level"
      },
      {
        "field": "department_name",
        "prompt_msg": "创业单位名称"
      },
      {
        "exceptvalues": [
          "军队"
        ],
        "field": "industry"
      },
      {
        "exceptvalues": [
          "军人"
        ],
        "field": "post_type"
      },
      {
        "exceptvalues": [
          "国外"
        ],
        "field": "actual_location"
      },
      // {
      //   "field": "protocol_photos"
      // }
    ]
  },
  {
    // 自由职业
    "name": "sign_32",
    "data": [
      {
        "lockvalues": [
          "非派遣"
        ],
        "field": "graduation"
      },
      {
        "lockvalues": [
          "自由职业"
        ],
        "field": "employer_type"
      },
      // {
      //   "lockvalues": [
      //     "自由职业"
      //   ],
      //   "field": "employment_status"
      // },
      {
        "field": "is_basic_level"
      },
      {
        "field": "department_name"
      },
      {
        "exceptvalues": [
          "军队"
        ],
        "field": "industry"
      },
      {
        "exceptvalues": [
          "军人"
        ],
        "field": "post_type"
      },
      {
        "exceptvalues": [
          "国外"
        ],
        "field": "actual_location"
      },
      // {
      //   "field": "protocol_photos"
      // }
    ]
  },
  {
    // 录取研究生
    "name": "sign_13",
    "data": [
      {
        "lockvalues": [
          "升学"
        ],
        "field": "graduation"
      },
      {
        "lockvalues": [
          "升学"
        ],
        "field": "employer_type"
      },
      // {
      //   "lockvalues": [
      //     "升学"
      //   ],
      //   "field": "employment_status"
      // },
      {
        "field": "is_basic_level"
      },
      {
        "exceptvalues": [
          "国外"
        ],
        "field": "actual_location"
      },
      // {
      //   "field": "protocol_photos"
      // }
    ]
  },
  {
    // 录取第二学士学位
    "name": "sign_33",
    "data": [
      {
        "lockvalues": [
          "升学"
        ],
        "field": "graduation"
      },
      {
        "lockvalues": [
          "升学"
        ],
        "field": "employer_type"
      },
      // {
      //   "lockvalues": [
      //     "升学"
      //   ],
      //   "field": "employment_status"
      // },
      {
        "field": "is_basic_level"
      },
      {
        "exceptvalues": [
          "国外"
        ],
        "field": "actual_location"
      },
      // {
      //   "field": "protocol_photos"
      // }
    ]
  },
  {
    // 专科升普通本科
    "name": "sign_34",
    "data": [
      {
        "lockvalues": [
          "升学"
        ],
        "field": "graduation"
      },
      {
        "lockvalues": [
          "升学"
        ],
        "field": "employer_type"
      },
      // {
      //   "lockvalues": [
      //     "升学"
      //   ],
      //   "field": "employment_status"
      // },
      {
        "field": "is_basic_level"
      },
      {
        "exceptvalues": [
          "国外"
        ],
        "field": "actual_location"
      },
      // {
      //   "field": "protocol_photos"
      // }
    ]
  },
  {
    // 出国、出境
    "name": "sign_14",
    "data": [
      {
        "lockvalues": [
          "出国"
        ],
        "field": "graduation"
      },
      {
        "lockvalues": [
          "出国、出境"
        ],
        "field": "employer_type"
      },
      // {
      //   "lockvalues": [
      //     "出国"
      //   ],
      //   "field": "employment_status"
      // },
      {
        "field": "is_basic_level"
      },
      {
        "field": "department_name",
        "prompt_msg": "出国国家名称或留学学校名称"
      },
      {
        "lockvalues": [
          "国外"
        ],
        "field": "actual_location"
      },
      // {
      //   "field": "protocol_photos"
      // }
    ]
  }
])


function getForm(formName: string): formschema {
  const form: formschema = JSON.parse(formList).filter((item) => item.name == formName)[0];
  form.data = form.data.map(v => getFieldAllConfig(v))
  return form;
}

export {
  getForm
}