//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, PropType, ref } from "vue";
import { itemdata } from "../form/dataform";
import { useStore } from 'vuex'
import { key } from './../state/store' 

export default defineComponent({
  name: "cfNumber",
  props: {
    item: {
      type: Object as PropType<itemdata>,
      default: null,
    },
    value: Number,
  },
  emits: ["input"],
  setup(props,context) {
    const store = useStore(key);

    const datavalue = ref(props.value);

    const change = () =>{
      context.emit('input',datavalue.value)
    }
    return { datavalue,change,store }
  }
});
