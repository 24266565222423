//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, provide, reactive, ref } from "vue";
import { formschema, FormItem, fileditem, signmodeitem, requestStateitem } from "../form/dataform";
import dformVue from "../components/dform.vue";
import dProcess from "../components/dProcess.vue"
import { getForm } from "../form/Forms";
import { FormValidators } from "../form/FormValidators";
import Api from "@/http/Api";
import { JosnData } from "@/utils/JsonData";
import { useStore } from "vuex";
import { key } from "@/state/store";
import { ElMessage,ElMessageBox  } from "element-plus";
import { StorgeUtils } from "@/utils/StorageUtils";
import { useRouter, useRoute } from 'vue-router';
import Constants from "@/http/Constant";
import { getPlatform } from "@/public/GetPlatform";

export default defineComponent({
  name: "signCenter",
  components: {
    dformVue,
    dProcess
  },
  setup() {
    const store = useStore(key);
    const router = useRouter();
    const route = useRoute();
    const schema = reactive(new Array<formschema>());
    const selfTalentFieldlist = ref<string>("");
    const signForm = reactive(new Array<formschema>());
    const selfDepartmentFieldList = ref<string>("");
    const dataTalent = reactive(new Map<string, any>());
    const dataSign = reactive(new Map<string, any>());
    const showTalent = ref<boolean>(false);
    const showSign = ref<boolean>(false);
    const showXieYiBtn = ref<boolean>(false);
    const showRefuseBtn = ref<boolean>(false);
    const showDefineBtn = ref<boolean>(false);
    const allowCancelSign = ref<boolean>(false); //是否允许解约 由高校后台配置
    const step = ref<number>(0); //签约步骤控制
    const year = ref<string>("");
    const departmentUrl = ref<string>(Constants.departmentUrl);
    const signSuNumber = ref<string>("");
    const signModePopup = ref<boolean>(false);
    const signModePopupWidth = ref<string>("500px");
    const signTipsPopup = ref<boolean>(false);
    const signModeSetting = ref<signmodeitem[]>([]);
    const recordid = ref<string>("");
    const signMode = ref<number>(0); //签约方式 默认备案
    const signStartTime = ref<string>("");
    const signEndTime = ref<string>("");
    const showSignRules = ref<boolean>(true);
    const agreementState = ref<number>(-1); // 签约记录状态
    const showSignLink = ref<boolean>(false);
    const showOnlineBtn = ref<boolean>(false);
    const showRescindBtn = ref<boolean>(false);
    const showSignDownload = ref<boolean>(false);
    const stateImageUrl = ref<string>("");
    const isPc = ref<boolean>(true);
    const normalSequence: Array<string> = ['08', '28', '31', '30', '29', '09', '35', '36']
    const employment_Type = ref<string>("")
    const talentClearAuditModel = ref<boolean>(false)
    const talentRefuseSignModel = ref<boolean>(false)
    const invalidLinkModel = ref<boolean>(false)
    const talentRefuseSignCause = ref<string>('')
    const rescindSignState = ref<number>(-1) // 解约状态
    const rescindSignAuditReason = ref<string>('') // 解约审核原因
    const revokeSignState = ref<number>(-1) // 撤回状态
    const revokeSignAuditReason = ref<string>('') // 撤回审核原因
    const notosign = ref(true) // 不可签约状态
    // 签约数据
    const requestStateData = reactive({
      sign_mode: '',
      agreement_state: '',
      current_state: '',
      stateby: '',
      audit_mode: false,
      request_state_list: Array<requestStateitem>(),
      department_field_list: Array<fileditem>(),
    })
    //提交参数格式
    const signParams = reactive({
      sign_mode: 0,
      recordid: "",
      talent_info: {},
      department_info: {},
      profile_address: {},
      talent_info_self_field_list: Array<fileditem>(),
      department_info_self_field_list: Array<fileditem>(),
    });

    //签约状态
    const requestState = reactive({
      agreement_state: -1,
      remark: "",
      stateby: "",
    });
    const allowRevoke = ref(false) // 是否开启协议书撤回审核
    const canRevoke = ref(false) // 是否开启协议书撤回功能
    const needStudentArchives = ref(false) // 是否需要填写档案
    // 签约状态（新）
    const currentState = ref(-1)
    const postMessageinfo = reactive({
      page: "signCenter",
      time: '',
    });
    //接收参数
    const getParamsFromUrl = () => {
      let url = window.location.href;
      let querys = url.substring(url.indexOf("?") + 1).split("&");
      let result = [];
      for (let i = 0; i < querys.length; i++) {
        let temp = querys[i].split("=");
        console.log(temp);
        if (temp.length < 2) {
          result[temp[0]] = "";
        } else {
          result[temp[0]] = temp[1];
        }
      }
      //记录id必传 可做不判断
      recordid.value = result["recordid"];
      let tempToken = result["token"];
      let tempAccountid = result["accountid"];
      let userType = result["usertype"];
      postMessageinfo.time = result["t"]
      //token accountid usertype 小程序传参
      if (tempToken) {
        StorgeUtils.set('talentToken', tempToken);
      }
      if (tempAccountid) {
        StorgeUtils.set("talentAccountid", tempAccountid);
      }
      if (userType) {
        StorgeUtils.set("usertype", userType);
      }
    };
    getParamsFromUrl();
    let platform = getPlatform();
    if (platform != 'pc') {
      isPc.value = false;
    }
    if (!isPc.value) {
      signModePopupWidth.value = '90%'
    }
    console.log(platform);
    //获取签约配置
    Api.getSignSetting().then((res) => {
      //console.log("签约配置", res);
      if (res.status) {
        year.value = res.data.year;
        //根据配置设置可选择的签约类型
        let tempSignModeSetting = JSON.parse(JosnData.signModeSetting);
        let onlineSignMode = res.data.sign_mode;
        signModeSetting.value = tempSignModeSetting.filter((item) =>
          onlineSignMode.includes(item.signStr)
        );
        StorgeUtils.set("year", res.data.year);
        StorgeUtils.set("collegeid", res.data.collegeid);
        store.commit("autoGenerateEmploymenNumberChange",~~res.data.auto_generate_employmen_number);
        allowCancelSign.value = res.data.allow_cancel_online;
        allowRevoke.value = res.data.allow_revoke
        canRevoke.value = res.data.can_revoke
        needStudentArchives.value = res.data.need_student_archives
        //console.log(signModeSetting.value);
      }
    });
    //获取不可签约状态
    Api.getNotosign().then((res=>{
      if (res.status) {
        notosign.value = res?.data?.value
      }
    }))
    //使用表单名称载入对应表单
    const LoadExtForm = (formname: string, formschema: Array<formschema>) => {
      const form = getForm(formname);
      //console.log(form);
      if (form) {
        formschema.push(form);
      }
      //console.log(formschema);
    };

    //调用接口加载表单 根据类型就业类别加载
    const loadExtFormByHttp = (employmentType: string) => {
      Api.getSignFormByEmployment(year.value, employmentType).then((res) => {
        if (res.status) {
          store.commit("fieldRemarkChange",res.data.required_field_remark);
          employment_Type.value = res.data.employment_type
          const formName = `sign_${res.data.employment_type}`;
          const index = signForm.findIndex((item) => item.name == formName);
          let signSelfList = res.data.self_field_list;
          //debugger;
          if (index) {
            let signFormData = signForm[index].data 
            if (normalSequence.includes(employmentType)) {
              for (let item of res.data.field_list) {
                signForm[index].data.push(item);
              }
            } else {
              for (let item of res.data.field_list) {
                let location = signForm[index].data.length - 1;
                signForm[index].data.splice(location, 0, item);
              }
            }



            if (signSelfList && signSelfList.length > 0) {
              for (let item of signSelfList) {
                let location = signForm[index].data.length - 1;
                signForm[index].data.splice(location, 0, item);
              }
              selfDepartmentFieldList.value = JSON.stringify(signSelfList);
            }
            //signForm[index].data.unshift()
            console.log(7799,signForm[index].data)
            // "protocol_photos" //"business_license"
            // signForm[index].data.splice()
            signFormData.push(signFormData.splice(signFormData.findIndex(v=>v.field==='business_license'),1).pop() as any)
            signFormData.push(signFormData.splice(signFormData.findIndex(v=>v.field==='protocol_photos'),1).pop() as any)
          }
          showSign.value = true;
        }
        //console.log(res);
        //console.log(signForm);
      });
    };

    //卸载表单名称以后加载的表单
    const UnloadExtFormAfter = (formname: string, formschema: any) => {
      const index = formschema.findIndex((item) => item.name == formname);
      console.log(index);
      if (index > -1) {
        formschema.splice(index, 1);
      }
    };

    //清空签约表单值  在切换就业类别时
    const emptySignData = (except: Array<string> = []) => {
      dataSign.forEach((value, key) => {
        if (!except.includes(key)) {
          dataSign.set(key, undefined);
        }
      });
    };

    //选择就业类别
    provide("signForm", signForm);
    provide("signModePopup", signModePopup);
    provide("LoadExtFrom", LoadExtForm);
    provide("UnloadExtFormAfter", UnloadExtFormAfter);
    provide("loadExtFormByHttp", loadExtFormByHttp);
    provide("emptySignData", emptySignData);
    provide("signMode", signMode);
    provide("agreementState", agreementState)
    provide("recordid", recordid)

    //获取签约表单相关信息 如果存在recordid 则读取相关记录 不存在则为新表单
    const getNewDataForm = () => {
      Api.getSingupV3().then((res) => {
        //console.log(res)
        StorgeUtils.set("facultyid", res.data.facultyid);
        StorgeUtils.set("year", res.data.year);
        StorgeUtils.set("collegeid", res.data.collegeid);
        signStartTime.value = res.data.signup_start_time;
        signEndTime.value = res.data.signup_end_time;
        // signMode.value = res.data.sign_mode;
        let fieldData = res.data.talent_field.field_data;
        let selfFieldData = res.data.talent_field.self_field_data;
        let fieldList = res.data.talent_field.field_list;
        let selfFileList = res.data.talent_field.self_field_list;
        selfTalentFieldlist.value = JSON.stringify(
          res.data.talent_field.self_field_list
        );
        for (let key in fieldData) {
          //console.log(key,obj[key]);
          dataTalent.set(key, fieldData[key]);
        }
        for (let key in selfFieldData) {
          dataTalent.set(key, selfFieldData[key]);
        }
        showTalent.value = true;
        LoadExtForm("student_source", schema);
        LoadExtForm("sign_base", signForm);
        //year.value = dataTalent.get('year');
        //添加可控制生源字段
        if (fieldList && fieldList.length > 0) {
          for (let item of fieldList) {
            schema[0].data.push(item);
          }
        }
        //添加自定义生源字段
        if (selfFileList && selfFileList.length > 0) {
          for (let item of selfFileList) {
            schema[0].data.push(item);
          }
          selfTalentFieldlist.value = JSON.stringify(
            res.data.talent_field.self_field_list
          );
        }
        //console.log(dataTalent);
        if (res.data.department_field) {
          store.commit(
            "employmentTypeChange",
            res.data.department_field.employment_type
          );
          store.commit("fieldRemarkChange",res.data.department_field.required_field_remark);
          employment_Type.value = res.data.department_field.employment_type ? res.data.department_field.employment_type : "01"
          const employment_type = res.data.department_field.employment_type ? res.data.department_field.employment_type : "01";
          let signData = res.data.department_field.field_data;
          if(employment_type === '01'&&signData.protocol_photos){
            signData.protocol_photos = []
          }
          let signFieldData = res.data.department_field.field_list;
          let signSelfList = res.data.department_field.self_field_list;
          let signSelfListData = res.data.department_field.self_field_data;
          const formName = res.data.department_field.employment_type
            ? `sign_${res.data.department_field.employment_type}`
            : "sign_01";
          const formStatusName = `sign_status_${res.data.department_field.employment_status || res.data.department_field.field_data.employment_status || '01'}`
          store.commit(
            "employmentStatusChange",
            res.data.department_field.employment_status || res.data.department_field.field_data.employment_status
          );
          //处理表单的值 值处理完后再加载表单 否则值会未绑定
          for (let key in signData) {
            if(key == 'employerment_agreement_number'){
              signData[key] = ''
            }
            //console.log(key, signData[key]);
            dataSign.set(key, signData[key]);
          }
          for (let key in signSelfListData) {

            dataSign.set(key, signSelfListData[key]);
          }
          //签约部分表单显隐控制
          showSign.value = true;

          UnloadExtFormAfter("sign_base", signForm);
          UnloadExtFormAfter(formStatusName, signForm)
          UnloadExtFormAfter(formName, signForm);
          LoadExtForm("sign_base", signForm);
          LoadExtForm(formStatusName, signForm);
          LoadExtForm(formName, signForm);
          const index = signForm.findIndex((item) => item.name == formName);
          //debugger;
          //加载额外部分表单字段
          if (index) {
            if (normalSequence.includes(employment_type)) {
              for (let item of signFieldData) {

                signForm[index].data.push(item)
              }
            } else {
              for (let item of signFieldData) {
                let location = signForm[index].data.length - 1;
                signForm[index].data.splice(location, 0, item);
              }
            }


            if (signSelfList && signSelfList.length > 0) {
              for (let item of signSelfList) {
                let location = signForm[index].data.length - 1;
                signForm[index].data.splice(location, 0, Object.assign(item, { is_sign_self: true }));
              }
              selfDepartmentFieldList.value = JSON.stringify(signSelfList);
            }
          }
        }
        //生源部分表单信息状态处理
        schema.forEach((form) => {
          form.data.forEach((item) => {
            if(!notosign.value){
              item.is_disable = true
            }
          })
        })
        //企业部分表单信息状态处理
        signForm.forEach((form) => {
          form.data.forEach((item) => {
            if(!notosign.value){
              item.is_disable = true
            }
          })
        })
      });
    };
    const getRescindSignState = (recordid: string) => {
      Api.getRescindSignState(recordid).then((res) => {
        if (res.status) {
          rescindSignState.value = res.data.audit_status
          rescindSignAuditReason.value = res.data.audit_reason
        }
      })
    }
    const getRevokeSignState = (recordid: string) => {
      Api.getRevokeSignState(recordid).then((res) => {
        if (res.status) {
          console.log(999,res)
          revokeSignState.value = res.data.audit_status
          revokeSignAuditReason.value = res.data.audit_reason
        }
      })
    }
    //通过记录id获取签约信息
    const getDataFormByRecordid = (recordid: string) => {
      getRescindSignState(recordid)
      getRevokeSignState(recordid)
      Api.getSignupV3ById(recordid).then((res) => {
        requestStateData.sign_mode = res.data.sign_mode
        requestStateData.agreement_state = res.data.request_state?.agreement_state
        requestStateData.current_state = res.data.request_state?.current_state
        requestStateData.stateby = res.data.request_state?.stateby
        requestStateData.audit_mode = res.data.audit_mode
        requestStateData.request_state_list = res.data.request_state_list
        requestStateData.department_field_list = res.data.department_field?.field_list
        console.log(res);
        if (res.status) {
          StorgeUtils.set("facultyid", res.data.facultyid);
          StorgeUtils.set("year", res.data.year);
          StorgeUtils.set("collegeid", res.data.collegeid);
          //签约时间端赋值
          signStartTime.value = res.data.signup_start_time;
          signEndTime.value = res.data.signup_end_time;
          signMode.value = res.data.sign_mode;
          store.commit("signModeChange",~~res.data.sign_mode);
          //生源部分签约记录处理
          let fieldData = res.data.talent_field.field_data;
          let selfFieldData = res.data.talent_field.self_field_data;
          let fieldList = res.data.talent_field.field_list;
          let selfFileList = res.data.talent_field.self_field_list;
          //为表单赋值
          for (let key in fieldData) {
            //console.log(key,obj[key]);
            dataTalent.set(key, fieldData[key]);
          }
          for (let key in selfFieldData) {
            dataTalent.set(key, selfFieldData[key]);
          }
          UnloadExtFormAfter("student_source", schema);
          LoadExtForm("student_source", schema);
          //添加可控制生源字段
          if (fieldList && fieldList.length > 0) {
            for (let item of fieldList) {
              schema[0].data.push(item);
            }
          }
          //添加自定义生源字段
          if (selfFileList && selfFileList.length > 0) {
            for (let item of selfFileList) {
              schema[0].data.push(item);
            }
            selfTalentFieldlist.value = JSON.stringify(
              res.data.talent_field.self_field_list
            );
          }
          //生源部分表单显隐控制
          showTalent.value = true;

          //签约部分字段处理
          console.log(123123)
          store.commit("fieldRemarkChange",res.data?.department_field?.required_field_remark);
          if (res.data.department_field&&(res.data.request_state.agreement_state != 0 ||
                res.data.sign_mode != 1)) {
            store.commit(
              "employmentTypeChange",
              res.data.department_field.employment_type
            );
            employment_Type.value = res.data.department_field.employment_type ? res.data.department_field.employment_type : "01"
            const employment_type = res.data.department_field.employment_type ? res.data.department_field.employment_type : "01";
            let signData = res.data.department_field.field_data;
            let signFieldData = res.data.department_field.field_list;
            let signSelfList = res.data.department_field.self_field_list;
            let signSelfListData = res.data.department_field.self_field_data;
            const formName = res.data.department_field.employment_type
              ? `sign_${res.data.department_field.employment_type}`
              : "sign_01";
            const formStatusName = `sign_status_${res.data.department_field.employment_status || res.data.department_field.field_data.employment_status || '01'}`
            store.commit(
              "employmentStatusChange",
              res.data.department_field.employment_status || res.data.department_field.field_data.employment_status
            );
            //处理表单的值 值处理完后再加载表单 否则值会未绑定
            for (let key in signData) {
              //console.log(key, signData[key]);
              dataSign.set(key, signData[key]);
            }
            for (let key in signSelfListData) {
              dataSign.set(key, signSelfListData[key]);
            }
            //签约部分表单显隐控制
            showSign.value = true;

            UnloadExtFormAfter("sign_base", signForm);
            UnloadExtFormAfter(formStatusName, signForm)
            UnloadExtFormAfter(formName, signForm);
            LoadExtForm("sign_base", signForm);
            LoadExtForm(formStatusName, signForm);
            LoadExtForm(formName, signForm);
            if(res.data.additional_info&&res.data.sign_mode == 1){
              let sign_01List = signForm.filter(v=>v.name === 'sign_01')
              if(sign_01List.length){
                sign_01List[0].data.push({
                  control_type: "datetime",
                  field: "additionalInfoInternshipStartTime",
                  is_disable:true,
                  is_talent_view:true,
                  lockvalue:res.data.additional_info.internship?.start_time,
                  name:"试用期开始时间",
                  prompt_msg:"",
                  required:false,
                })
                sign_01List[0].data.push({
                  control_type: "datetime",
                  field: "additionalInfoInternshipEndTime",
                  is_disable:true,
                  is_talent_view:true,
                  lockvalue:res.data.additional_info.internship?.end_time,
                  name:"试用期结束时间",
                  prompt_msg:"",
                  required:false,
                })
                sign_01List[0].data.push({
                  control_type: "datetime",
                  field: "additionalInfoNoviciateEndTime",
                  is_disable:true,
                  is_talent_view:true,
                  lockvalue:res.data.additional_info.noviciate?.end_time,
                  name:"报到期限",
                  prompt_msg:"",
                  required:false,
                })
                sign_01List[0].data.push({
                  control_type: "textbox",
                  field: "additionalInfoProbationSalary",
                  is_disable:true,
                  is_talent_view:true,
                  lockvalue:res.data.additional_info.probation_salary,
                  name:"试用期起薪(元/月)",
                  prompt_msg:"",
                  required:false,
                })
                sign_01List[0].data.push({
                  control_type: "textbox",
                  field: "additionalInfoSalary",
                  is_disable:true,
                  is_talent_view:true,
                  lockvalue:res.data.additional_info.salary,
                  name:"转正后起薪(元/月)",
                  prompt_msg:"",
                  required:false,
                })
                sign_01List[0].data.push({
                  control_type: "textbox",
                  field: "additionalInfoResponsibility",
                  is_disable:true,
                  is_talent_view:true,
                  lockvalue:res.data.additional_info.responsibility,
                  name:"违约责任",
                  prompt_msg:"",
                  required:false,
                  type:'textarea'
                })
              }
            }
            const index = signForm.findIndex((item) => item.name == formName);
            //debugger;
            //加载额外部分表单字段
            if (index) {
              if (normalSequence.includes(employment_type)) {
                for (let item of signFieldData) {

                  signForm[index].data.push(item)
                }
              } else {
                for (let item of signFieldData) {
                  let location = signForm[index].data.length - 1;
                  signForm[index].data.splice(location, 0, item);
                }
              }


              if (signSelfList && signSelfList.length > 0) {
                for (let item of signSelfList) {
                  let location = signForm[index].data.length - 1;
                  signForm[index].data.splice(location, 0, Object.assign(item, { is_sign_self: true }));
                }
                selfDepartmentFieldList.value = JSON.stringify(signSelfList);
              }
            }
          }
          if (res.data.sign_mode == 1) {
            signSuNumber.value = res.data.su_number;
            console.log(1);
          }

          //根据签约方式 签约状态综合判断 表单的相关状态相关值
          signMode.value = res.data.sign_mode;
          store.commit("signModeChange",~~res.data.sign_mode);
          if (res.data.sign_mode == 2) {
            showXieYiBtn.value = true;
          }
          if (res.data.request_state) {
            agreementState.value = res.data.request_state.agreement_state;
            currentState.value = res.data.request_state.current_state;
            requestState.agreement_state = res.data.request_state.agreement_state;
            requestState.remark = res.data.request_state.remark;
            requestState.stateby = res.data.request_state.stateby;
            stateImageUrl.value = ''
            if (res.data.request_state.agreement_state == 3) {
              stateImageUrl.value = require("../assets/auditor-pass.png");
              // if(isPc.value){
              //   stateImageUrl.value = require("../assets/auditor-pass.png");
              // }else{
              //   stateImageUrl.value = require("../assets/adoptSucceed.png");
              // }
            }
            if (res.data.request_state.agreement_state == 2) {
              stateImageUrl.value = require("../assets/auditor-under-review.png");
              // if(isPc.value){
              //   stateImageUrl.value = require("../assets/auditor-under-review.png");
              // }else{
              //   stateImageUrl.value = require("../assets/adoptActive.png");
              // }
            }
            if (res.data.request_state.agreement_state == 6 || res.data.request_state.agreement_state == 5) {
              stateImageUrl.value = require("../assets/auditor-not-pass.png");
              // if(isPc.value){
              //   stateImageUrl.value = require("../assets/auditor-not-pass.png");
              // }else{
              //   stateImageUrl.value = require("../assets/adoptError.png");
              // }
            }
            if (
              (res.data.request_state.agreement_state == 0 &&
                res.data.sign_mode == 1) ||
              (res.data.request_state.agreement_state == 7 &&
                res.data.sign_mode == 1)
            ) {
              showSignLink.value = true;
              showOnlineBtn.value = true;
            }
          }

          if (res.data.sign_mode == 1) {
            showOnlineBtn.value = true;
          }
          //下载协议按钮 只有就业类别为01/02 时且线上/线下才有下载协议的按钮
          if (res.data.department_field) {
            if (
              res.data.department_field.employment_type == "01" ||
              res.data.department_field.employment_type == "02"
            ) {
              if (signMode.value == 1 || signMode.value == 2) {
                showSignDownload.value = true;
              }
            }
          }

          //表单状态处理
          //生源部分表单信息状态处理
          schema.forEach((form) => {
            form.data.forEach((item) => {
              if(!notosign.value){
                item.is_disable = true
              }else if (res.data.request_state) {
                if(res.data.request_state.agreement_state == -1){
                  null
                }else if (res.data.request_state.agreement_state == 3) {
                  //签约状态为已完成时 所有表单禁止修改
                  item.is_disable = true;
                } else if (
                  res.data.request_state.agreement_state == 2 &&
                  res.data.request_state.stateby!="faculty"&&
                  res.data.sign_mode == 0 &&
                  (res.data.department_field.employment_type == "01" ||
                    res.data.department_field.employment_type == "02")
                ) {
                  item.is_disable = false; // 就业类别为01 02，签约类型为备案签约，签约状态为待审核时 生源信息可以修改
                } else if (res.data.request_state.agreement_state == 6) {
                  item.is_disable = false; //签约状态为退回给学生修改时 可以修改学生信息
                } else if (res.data.request_state.agreement_state == 0) {
                  //表单状态为已创建时禁止修改
                  item.is_disable = true;
                  if(item.field == "protocol_photos"&&res.data.sign_mode == 2){
                    item.is_disable = false;
                  }
                } else {
                  item.is_disable = true;
                }
              }
            });
          });

          //签约(企业信息)部分表单状态处理
          signForm.forEach((form) => {
            form.data.forEach((item) => {
              if(!notosign.value){
                item.is_disable = true
              }else if (res.data.request_state) {
                if(res.data.request_state.agreement_state == -1){
                  null
                }else if (res.data.request_state.agreement_state == 3) {
                  //签约状态为已完成时 所有表单禁止修改
                  item.is_disable = true;
                } else if (
                  res.data.request_state.agreement_state == 2 &&
                  res.data.request_state.stateby!="faculty"&&
                  res.data.sign_mode == 0 &&
                  (res.data.department_field.employment_type == "01" ||
                    res.data.department_field.employment_type == "02")
                ) {
                  // 就业类别为01 02，签约类型为备案签约，签约状态为待审核时 除就业类别 就业状况都可修改
                  if (
                    item.field == "employment_type" ||
                    item.field == "employment_status"
                  ) {
                    item.is_disable = true;
                  } else {
                    item.is_disable = false;
                  }
                } else if (res.data.request_state.agreement_state == 0) {
                  //表单状态为已创建时禁止修改
                  if(currentState.value==6){
                    item.is_disable = true;
                  }else if(["employment_status", "employment_type", "department_name","employerment_agreement_number"].includes(item.field)&&res.data.sign_mode==2) {
                    item.is_disable = true;
                  }
                } else if (res.data.request_state.agreement_state == 1) {
                  //表单状态为签约中需要上传附件 提交
                  if (item.field == "protocol_photos") {
                    item.is_disable = false;
                  } else if (item['is_sign_self'] && res.data.sign_mode) {
                    item.is_disable = false;
                  } else if (res.data.sign_mode == 1 && ["graduation", "scale", "salary", "is_major_match", "employerment_agreement_number"].includes(item.field)) {
                    item.is_disable = false;
                  } else if (res.data.sign_mode == 2 && ["department_name", "employerment_agreement_number"].includes(item.field)) {
                    item.is_disable = false;
                  } else {
                    item.is_disable = true;
                  }
                } else {
                  item.is_disable = true;
                }
              }
            });
            console.log(998866,form)
            form.data.push(form.data.splice(form.data.findIndex(v=>v.field==='business_license'),1).pop() as any)
            form.data.push(form.data.splice(form.data.findIndex(v=>v.field==='protocol_photos'),1).pop() as any)
            console.log(998855,form)
          });
          console.log(998877,signForm)
          // signForm.
        }
      });
    };

    //根据recordid判断是新签约或者签约记录
    if (recordid.value) {
      getDataFormByRecordid(recordid.value);
    } else {
      getNewDataForm();
    }
    //获取值
    const GetData = (form: any, data: any) => {
      //过滤不在表单堆栈中的值

      let fields = new Map<string, undefined>();
      //生成表单字段表
      //console.log(form);
      form.forEach((form) => {
        form.data.forEach((item) => {
          fields.set(item.field, undefined);
        });
      });
      //console.log(fields);
      //检查data是否在表单字段中
      let retdata = new Map<string, any>();
      data.forEach((value, key) => {
        if (fields.has(key)) retdata.set(key, value);
      });

      console.log(retdata);
      return retdata;
    };
    //提交签约数据格式化
    const formatSignParams = () => {
      let talentInfo: Map<string, any> = GetData(schema, dataTalent);
      let formData: Map<string, any> = GetData(signForm, dataSign);

      signParams.sign_mode = signMode.value;
      signParams.recordid = recordid.value;
      talentInfo.forEach((value, key) => {
        signParams.talent_info[key] = value;
      });
      formData.forEach((value, key) => {
        signParams.department_info[key] = value;
      });
      if (selfTalentFieldlist.value) {
        let list = JSON.parse(selfTalentFieldlist.value);
        signParams.talent_info_self_field_list = []
        for (let item of list) {
          signParams.talent_info_self_field_list.push({
            field: item.field,
            value: talentInfo.get(item.field),
          });
        }
      }
      if (selfDepartmentFieldList.value) {
        let list = JSON.parse(selfDepartmentFieldList.value);
        signParams.department_info_self_field_list = []
        for (let item of list) {
          signParams.department_info_self_field_list.push({
            field: item.field,
            value: formData.get(item.field),
          });
        }
      }
    };
    //下一页
    const nextPage = async () => {
      let formData: Map<string, any> = GetData(schema, dataTalent);
      const result = await FormValidators.checkForm(schema[0].data, formData, ['business_license']);
      //console.log(result);
      if (result) {
        step.value = 1;
      }
    };

    //上一页
    const prevPage = () => {
      step.value = 0;
    };

    //当前时间是否在签约日期内
    const isDuringSignData = (start: string, end: string): boolean => {
      let nowDate = new Date();
      let endDate = new Date(end);
      let startDate = new Date(start);

      if (nowDate >= startDate && nowDate <= endDate) {
        return true;
      } else {
        return false;
      }
    };

    //签约方式切换
    const selectSignMode = (signModeValue: any) => {
      //console.log(signModeValue);
      signMode.value = signModeValue;
      store.commit("signModeChange",~~signModeValue);
      signModePopup.value = false;
      if (signModeValue == 2) {
        showXieYiBtn.value = true;
      } else if (signModeValue == 1) {
        createSignLink();
      }
      //console.log(signMode);
    };
    //生成并下载协议书
    const makeXieYi = async () => {
      let formData: Map<string, any> = GetData(signForm, dataSign);
      const rules: FormItem[] = [];
      signForm.forEach((form) => {
        form.data.forEach((item) => {
          if (item.field !== "protocol_photos") {
            rules.push(item);
          }
        });
      });
      const result = await FormValidators.checkForm(rules, formData, ['business_license','protocol_photos']);
      if (result) {
        formatSignParams();
        Api.makeAgreement(signParams).then((res) => {
          if (res.status) {
            recordid.value = res.data.recordid;
            getDataFormByRecordid(recordid.value);
            // postMessage(recordid.value)
            let userType = StorgeUtils.get("usertype")
            if (userType !== 'wx' && res.data.agreement_file_link) {
              window.open(`${res.data.agreement_file_link}`, "_blank");
            } else {
              wxjump(res.data.agreement_file_link);
              // ElMessage({
              //   message: "协议书生成成功，请至电脑端下载",
              //   type: "success",
              //   offset: 50,
              // });
            }
          }
          console.log(res);
        });
        console.log(1);
      }
    };
    const wxjump = (url?: string) => {
      let params = {
        fileName: recordid.value ? '就业协议书.pdf' : '就业协议书.docx',
        url: url || (recordid.value ? `${Constants.ApiUrl}/agreement_record/talent/downloadagreement/${recordid.value}` : `${Constants.ApiUrl}/signup/talent/downloadagreement`)
      }
      console.log(params, 'wxjump')
      window['wx'].miniProgram.navigateTo({
        "url": `/pages/subpackage/activitySub/skipDownload/skipDownload?params=${encodeURIComponent(JSON.stringify(params))}`,
      })
    };
    const downloadXieYi = () => {
      let userType = StorgeUtils.get("usertype")
      if (userType !== 'wx') {
        Api.downloadAgrement(recordid.value).then(res => {
          let url = window.URL.createObjectURL(res.data);
          let link = document.createElement('a');
          link.style.display = 'none';
          link.href = url;
          link.setAttribute('download', '就业协议书.pdf');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
      } else {
        wxjump()
      }
    };
    // 签约保存
    const save = async () => {
      let formData: Map<string, any> = GetData(signForm, dataSign);
      //console.log(formData);
      const rules: FormItem[] = [];
      signForm.forEach((form) => {
        form.data.forEach((item) => {
          rules.push(item);
        });
      });
     let skipFieldList = ['business_license','protocol_photos']
     const result = await FormValidators.checkForm(rules, formData, skipFieldList);
      const isDuring = isDuringSignData(signStartTime.value, signEndTime.value);
      if (!isDuring) {
        ElMessage({
          message: "当前时间不在签约时间内，请在规定的时间提交签约！",
          type: "warning",
          offset: 50,
        });
        return;
      }
      console.log(result);
      if (result) {
        //处理签约保存数据
        formatSignParams();
        if (signMode.value == 2 && agreementState.value == -1) {//协议书保存
          Api.saveOfflineSign(signParams).then((res) => {
            if (res.status) {
              ElMessage({
                message: "签约保存成功！",
                type: "success",
                offset: 50,
              });
            }
          });
          return
        }
      }
    }
    //签约提交
    const submit = async () => {
      // 有生成协议书按钮不能先点提交
      if ((agreementState.value == -1 || agreementState.value == 0 && !recordid.value) && employment_Type.value == '01' && signMode.value == 2 ) {
        ElMessage({
          message: "请先生成协议书！",
          type: "warning",
          offset: 50,
        });
        return;
      }
      //线上签约 高校退回学生修改后重新提交
      if (signMode.value == 1 && agreementState.value == 6) {
        formatSignParams();
        Api.updateOnlineSign(recordid.value, signParams).then((res) => {
          if (res.status) {
            ElMessage({
              message: "生源信息修改成功，请等待企业重新确认！",
              type: "success",
              offset: 50,
            });
            getDataFormByRecordid(recordid.value);
          }
        });
        return;
      }
      //let talentInfo: Map<string, any> = GetData(schema, dataTalent);
      let formData: Map<string, any> = GetData(signForm, dataSign);
      //console.log(formData);
      const rules: FormItem[] = [];
      signForm.forEach((form) => {
        form.data.forEach((item) => {
          rules.push(item);
        });
      });
      //console.log(formData);
      let skipFieldList = ['business_license']
      if (signMode.value == 1) {
        skipFieldList = ['business_license','protocol_photos']
      }
      const result = await FormValidators.checkForm(rules, formData, skipFieldList);
      const isDuring = isDuringSignData(signStartTime.value, signEndTime.value);
      if (!isDuring) {
        ElMessage({
          message: "当前时间不在签约时间内，请在规定的时间提交签约！",
          type: "warning",
          offset: 50,
        });
        return;
      }
      console.log(result);
      if (result) {
        //处理签约提交数据
        formatSignParams();
        if (signMode.value == 1 && agreementState.value == 1) {//线上签约，学生确认签约
          Api.submitOnlineSign(recordid.value, signParams).then((res) => {
            if (res.status) {
              ElMessage({
                message: "签约提交成功，请等待审核！",
                type: "success",
                offset: 50,
              });
              getDataFormByRecordid(recordid.value);
              checkStudentArchives()
            }
          });
          return
        }
        //新签约提交
        if (signMode.value == 0 && !recordid.value) {
          Api.submitPaperSign(signParams).then((res) => {
            console.log(res);
            if (res.status) {
              ElMessage({
                message: "签约提交成功，请等待审核！",
                type: "success",
                offset: 50,
              });
              recordid.value = res.data.value;
              getDataFormByRecordid(recordid.value);
              checkStudentArchives()
            }
          });
        } else if (signMode.value == 0 && recordid.value) {//备案签约修改
          Api.updatePaperSign(recordid.value, signParams).then((res) => {
            if (res.status) {
              ElMessage({
                message: "签约修改成功，请等待审核！",
                type: "success",
                offset: 50,
              });
              getDataFormByRecordid(recordid.value);
            }
          });
        } else if (signMode.value == 2) { //协议书打印签约提交
          Api.submitOfflineSign(recordid.value, signParams).then((res) => {
            if (res.status) {
              ElMessage({
                message: "签约提交成功，请等待审核！",
                type: "success",
                offset: 50,
              });
              getDataFormByRecordid(recordid.value);
              checkStudentArchives()
            }
          });
        }
      }
    };

    //解约
    const rescind = () => {
      const employment_type = store.state.employment_type;
      if (employment_type == "01"&&signMode.value == 2&&agreementState.value == 0){
        if(allowRevoke.value){
          router.push({ name: '/rescinSign', query: { recordid: recordid.value,recordtype:'revoke' } });
          console.log("跳转到解约页面");
        }else{
          Api.revocation(recordid.value).then((res) => {
            if (res.status) {
              ElMessage({
                message: "撤回成功",
                type: "success",
                offset: 50,
              });
              window.parent.postMessage({ page: "signCenter" }, "*")
              getDataFormByRecordid(recordid.value);
            }
            //console.log(res);
          });
        }
      } else if (employment_type == "01" || employment_type == "02") {
        //window.location.href = `${Constants.form}rescindSign?recordid=${recordid.value}`
        router.push({ name: '/rescinSign', query: { recordid: recordid.value,/*recordtype:signMode.value == 0?'revoke':undefined*/ } });
        console.log("跳转到解约页面");
      } else {
        Api.revocation(recordid.value).then((res) => {
          if (res.status) {
            ElMessage({
              message: "撤回成功",
              type: "success",
              offset: 50,
            });
            window.parent.postMessage({ page: "signCenter" }, "*")
            getDataFormByRecordid(recordid.value);
          }
          //console.log(res);
        });
      }
    };

    //线上签约第一步 提交生源信息返回签约链接
    const createSignLink = () => {
      formatSignParams();
      Api.onlineSignStepOne(signParams).then((res) => {
        console.log(res);
        if (res.status) {
          recordid.value = res.data.recordid;
          signSuNumber.value = res.data.su_number;
          showSignLink.value = true;
          //step.value = 0;
          UnloadExtFormAfter("sign_base", signForm);
          UnloadExtFormAfter("sign_status_01", signForm);
          UnloadExtFormAfter("sign_01", signForm);
          agreementState.value = 0;
          showOnlineBtn.value = true;
          //getDataFormByRecordid(recordid.value);
          // postMessage(recordid.value)
        }
      });
    };

    //线上签约拒绝签约
    const refuseSign = (cause: string) => {
      Api.refuseOnlineSign(recordid.value, { value: cause }).then((res) => {
        console.log(res);
        if (res.status) {
          ElMessage({
            message: "操作成功",
            type: "success",
            offset: 50,
          });
          getDataFormByRecordid(recordid.value);
          postMessage()
        }
      });
    };

    //复制签约链接
    const copySignLink = () => {
      let Url2 = departmentUrl.value + signSuNumber.value;
      let oInput = document.createElement("input");
      oInput.value = Url2;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      oInput.className = "oInput";
      oInput.style.display = "none";
      ElMessage({
        message: "复制成功",
        type: "success",
        offset: 50,
      });
    };

    const postMessage = (recordid?:string) => {
      let obj = JSON.parse(JSON.stringify(postMessageinfo));
      setTimeout(() => {
        if(recordid){
          window['wx'].miniProgram.redirectTo({
            "url": `/pages/subpackage/activitySub/signCenter3/signCenter?recordid=${recordid}&h5sign=1`,
          })
          obj.recordid = recordid
          window.parent.postMessage(obj, '*')
        }else{
          window['wx'].miniProgram.navigateBack()
          window.parent.postMessage(obj, '*')
        }
      }, 2000);
    }
    // 检查学生档案状态
    const checkStudentArchives = ()=>{
      if(!needStudentArchives.value)return postMessage()
      Api.getStudentArchives().then(res=>{
        console.log(666,res)
        if (res.status) {
          let archivesStatus = res?.data?.data?.audit_status
          if(archivesStatus === 'revoke'||archivesStatus==='not_passed'){
            openToArchivesModel()
          }else{
            postMessage()
          }
        }
      }).catch(err=>{
        console.log(77,err)
        if(!err.data){
          openToArchivesModel()
        }
      })
    }
    // 打开跳转档案确认弹窗
    const openToArchivesModel = ()=>{
      ElMessageBox({
        title:'温馨提示',
        message:'您是否需要申请档案转寄',
        confirmButtonText: '确定',
        showCancelButton: true,
        cancelButtonText: '取消',
      }).then(()=>{
        let obj = JSON.parse(JSON.stringify(postMessageinfo));
        obj.type = 'toArchives'
        window.parent.postMessage(obj, '*')
        window['wx'].miniProgram.redirectTo({
          "url": `/pages/subpackage/activitySub/archives/archives`,
        })
      }).catch(()=>{
        postMessage()
      })
    }
    return {
      schema,
      signForm,
      showTalent,
      showSign,
      dataTalent,
      dataSign,
      GetData,
      nextPage,
      prevPage,
      submit,
      step,
      signModePopup,
      signTipsPopup,
      signModeSetting,
      selectSignMode,
      showXieYiBtn,
      showRefuseBtn,
      showDefineBtn,
      makeXieYi,
      allowCancelSign,
      rescind,
      refuseSign,
      copySignLink,
      departmentUrl,
      signSuNumber,
      showSignRules,
      agreementState,
      signMode,
      showOnlineBtn,
      showSignLink,
      showRescindBtn,
      requestState,
      stateImageUrl,
      signModePopupWidth,
      employment_Type,
      talentClearAuditModel,
      talentRefuseSignModel,
      invalidLinkModel,
      talentRefuseSignCause,
      getRescindSignState,
      rescindSignState,
      rescindSignAuditReason,
      requestStateData,
      wxjump,
      downloadXieYi,
      recordid,
      currentState,
      revokeSignState,
      revokeSignAuditReason,
      save,
      canRevoke,
      notosign
    };
  },
  computed: {},
});
