import Abstract from "./abstract";
import Constant from "./Constant";
class Api extends Abstract {
    //获取字典
    public getDict(dictType: string) {
        return this.get({ url: `/dict/${dictType}` });
    }
    //获取字典子集
    public getDictChild(dictType: string, parentCode: any) {
        return this.get({ url: `/dict/${dictType}/${parentCode}` });
    }
    //
    public getDictContrast(sourceDictType: string, sourceCode: string, resultDictType: string) {
        return this.get({ url: `/dict/contrast/${sourceDictType}/${sourceCode}/${resultDictType}` });
    }

    //
    public getDictContrastV3(sourceDictType: string, sourceCode: string, resultDictType: string) {
        return this.get({ url: `/dict/contrast2/${sourceDictType}/${sourceCode}/${resultDictType}` });
    }

    //获取专业列表
    public getSourceMajor(collegeid: string, year: string, facultyid: string, education: string) {
        return this.get({ url: `/major/${collegeid}/${year}/list/public?facultyid=${facultyid}&education=${education}` });
    }

    //获取签约配置
    public getSignSetting() {
        return this.get({ url: `/signup/v3/talent/getfield/newest` });
    }

    //获取不可签约状态
    public getNotosign() {
        return this.get({ url: `/signup/notosign` });
    }

    //获取签约新表单
    public getSingupV3() {
        return this.get({ url: `/signup/v3.1/talent/newdataform` });
    }

    //签约新表单根据就业类别获取对应的表单
    public getSignFormByEmployment(year: string, employmentType: string) {
        return this.get({ url: `/signup/v3.1/talent/${year}/${employmentType}/form` });
    }

    //根据签约id获取已签约的表单相关信息
    public getSignupV3ById(recordid: string) {
        return this.get({ url: `/signup/v3.1/talent/record/${recordid}/dataform` });
    }

    //线上签约第一步 生成链接提交生源填写数据
    public onlineSignStepOne(data: any) {
        return this.post({ url: `/signup/v3.1/talent/online`, data: data })
    }

    //线上签约提交 (确认签约)
    public submitOnlineSign(recordid: string, data: any) {
        return this.post({ url: `/signup/v3.1/talent/online/record/${recordid}/sign`, data: data });
    }
    //线上签约高校退回修改
    public updateOnlineSign(recordid: string, data: any) {
        return this.post({ url: `/signup/v3.1/talent/online/${recordid}/update`, data: data });
    }
    //拒绝（作废）线上签约
    public refuseOnlineSign(recordid: string, data: any) {
        return this.post({ url: `/signup/v3/talent/record/online/${recordid}/unsign`, data: data });
    }
    //生成并下载协议书 线下签约第一步
    public makeAgreement(data: any) {
        return this.post({ url: `/signup/v3.1/talent/offline`, data: data })
    }
    //线下签约保存
    public saveOfflineSign(data: any) {
        return this.post({ url: `signup/v3.1/talent/offline/draft`, data: data }); 
    }
    //线下签约提交（协议书打印签约）
    public submitOfflineSign(recordid: string, data: any) {
        return this.post({ url: `/signup/v3.1/talent/offline/${recordid}/submit`, data: data });
    }
    //下载协议书
    public downloadAgrement(recordid: string) {
        return this.get({ url: `/signup/v3/talent/downloadagreement/${recordid}`,responseType: 'blob' })
    }
    //备案签约提交
    public submitPaperSign(data: any) {
        return this.post({ url: `signup/v3.1/talent/paper/submit`, data: data }); 
    }
    //备案签约更新 审核中支持修改备案签约
    public updatePaperSign(recordid: string, data: any) {
        return this.post({ url: `/signup/v3.1/talent/paper/${recordid}/update`, data: data });
    }
    //签约撤回 适用于所有非协议书打印签约，劳动合同类型的备案签约
    public revocation(recordid: string) {
        return this.post({ url: `/signup/v3/revocation/${recordid}` });
    }
    //解约 适用协议书、劳动合同签约
    public cancelSign(data: any) {
        return this.post({ url: `/sign_cancel_request/talent/create`, data: data });
    }
    //撤回 适用协议书下载后撤回
    public revokeSign(data: any) {
        return this.post({ url: `/signup/revoke/talent/create`, data: data });
    }
    //格式校验
    public formatCheck(data: any) {
        return this.post({ url: `/topsystem/formatvalidation`, data: data })
    }
    //图片上传
    public uploadImage(data: any) {
        return this.post({ url: `/signup/v3/uploadimage`, data: data })
    }
    //获取解约状态
    public getRescindSignState(recordid: string) {
        return this.get({ url: `/sign_cancel_request/talent/audit/${recordid}` });
    }
     //获取撤回状态
     public getRevokeSignState(recordid: string) {
        return this.get({ url: `/signup/revoke/talent/audit/${recordid}` });
    }

    // ----------------------------高校签约接口------------------------------

    // 高校（审核/修改）获取签约表单
    public collegeSignUp(recordid: string) {
        return this.get({ url: `/signup/v3.1/college/record/${recordid}/dataform`, isCollege: true })
    }

    // 高校审核签约
    public collegeCheckSign(sginid: string, data: any) {
        return this.post({ url: `/agreement_record/college/record/${sginid}/audit`, isCollege: true, data: data })
    }

    // 高校(新增)获取签约表单
    public collegeAddSignUp(no: string) {
        return this.get({ url: `/signup/v3.1/college/form/student/${no}`, isCollege: true });
    }

    //高校根据就业类别获取对应的表单
    public collegeSignInfoForm(year: string, employmentType: string) {
        return this.get({ url: `/signup/v3.1/college/${year}/form/employment_type/${employmentType}`, isCollege: true });
    }

    // （公共）获取专业列表
    public collegeGetMajorList(year: string, collegeid: string) {
        return this.get({ url: `/major/${collegeid}/${year}/list/public` })
    }

    // 高校新增签约提交
    public collegeAddSubmit(data: any) {
        return this.post({ url: `/signup/v3/college/paper/create`, data: data, isCollege: true })
    }

    // 高校提交修改签约
    public collegeAmendSign(arid: string, data: any) {
        return this.post({ url: `/signup/v3/college/paper/${arid}/update`, data: data, isCollege: true })
    }

    // 规则校验
    public ruleRerification(data: any) {
        return this.post({ url: `/signup/v3/college/check/error`, data: data, isCollege: true })
    }

    // 获取教师信息
    public getTeacherInfo(data: any) {
        return this.get({ url: `/teacher` })
    }

    // 退回签约
    public handBackSign(recordid: string, data: any) {
        return this.post({ url: `/signup/v3/college/online/${recordid}/sendback`, data ,isCollege: true})
    }

    // 驳回院系
    public rejectSign(recordid: string, data: any) {
        return this.post({ url: `/signup/v3/college/${recordid}/sendback/faculty`, data ,isCollege: true})
    }

    // 获取学生档案信息
    public getStudentArchives() {
        return this.get({ url: `/v2/student_archives/talent`,isRecommend: true})
    }
}
// 单列模式返回对象
let instance;
export default (() => {
    if (instance) return instance;
    instance = new Api();
    return instance;
})();