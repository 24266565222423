import {signMode} from '@/form/dataform';
const online = 'signType1.png';
const offline = 'signType2.png';
const paper = 'signType3.png';
export class JosnData {
    public static area = JSON.stringify(
        [
            {
                "code": "101304",
                "parentcode": "1013",
                "parenttext": "宿迁市",
                "text": "泗阳县",
                "level": 3,
                "order": 0,
                "relation_code": "321323",
                "relation_desc": "江苏省泗阳县"
            },
            {
                "code": "101305",
                "parentcode": "1013",
                "parenttext": "宿迁市",
                "text": "泗洪县",
                "level": 3,
                "order": 0,
                "relation_code": "321324",
                "relation_desc": "江苏省泗洪县"
            },
            {
                "code": "101303",
                "parentcode": "1013",
                "parenttext": "宿迁市",
                "text": "沭阳县",
                "level": 3,
                "order": 0,
                "relation_code": "321322",
                "relation_desc": "江苏省沭阳县"
            },
            {
                "code": "101302",
                "parentcode": "1013",
                "parenttext": "宿迁市",
                "text": "宿豫区",
                "level": 3,
                "order": 0,
                "relation_code": "321311",
                "relation_desc": "江苏省宿迁市宿豫区"
            },
            {
                "code": "086002",
                "parentcode": "0860",
                "parenttext": "省森工总局",
                "text": "牡丹江林业管理局",
                "level": 3,
                "order": 0,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "231705",
                "parentcode": "2317",
                "parenttext": "巴中市",
                "text": "恩阳区",
                "level": 3,
                "order": 0,
                "relation_code": "511903",
                "relation_desc": "四川省巴中市恩阳区"
            },
            {
                "code": "311506",
                "parentcode": "3115",
                "parenttext": "新疆生产建设兵团",
                "text": "铁门关市",
                "level": 3,
                "order": 0,
                "relation_code": "659006",
                "relation_desc": "新疆铁门关市"
            },
            {
                "code": "085001",
                "parentcode": "0850",
                "parenttext": "省农垦总局",
                "text": "宝泉岭管理局",
                "level": 3,
                "order": 0,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "085007",
                "parentcode": "0851",
                "parenttext": "农垦绥化管理局",
                "text": "齐齐哈尔管理局",
                "level": 3,
                "order": 0,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "086003",
                "parentcode": "0860",
                "parenttext": "省森工总局",
                "text": "合江林业管理局",
                "level": 3,
                "order": 0,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "171203",
                "parentcode": "1712",
                "parenttext": "随州市",
                "text": "随县",
                "level": 3,
                "order": 0,
                "relation_code": "421321",
                "relation_desc": "湖北省随县"
            },
            {
                "code": "211903",
                "parentcode": "2119",
                "parenttext": "三沙市",
                "text": "中沙群岛",
                "level": 3,
                "order": 0,
                "relation_code": "460000",
                "relation_desc": "海南省中沙群岛"
            },
            {
                "code": "030626",
                "parentcode": "0306",
                "parenttext": "保定市",
                "text": "保定高新技术产业开发区",
                "level": 3,
                "order": 0,
                "relation_code": "130601",
                "relation_desc": "河北省保定市"
            },
            {
                "code": "086004",
                "parentcode": "0860",
                "parenttext": "省森工总局",
                "text": "伊春林管局",
                "level": 3,
                "order": 0,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "211902",
                "parentcode": "2119",
                "parenttext": "三沙市",
                "text": "南沙群岛",
                "level": 3,
                "order": 0,
                "relation_code": "460000",
                "relation_desc": "海南省南沙群岛"
            },
            {
                "code": "050210",
                "parentcode": "0502",
                "parenttext": "包头市",
                "text": "白云鄂博矿区",
                "level": 3,
                "order": 0,
                "relation_code": "150201",
                "relation_desc": "内蒙古包头市白云鄂博矿区"
            },
            {
                "code": "085002",
                "parentcode": "0850",
                "parenttext": "省农垦总局",
                "text": "红兴隆管理局",
                "level": 3,
                "order": 0,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "280908",
                "parentcode": "2809",
                "parenttext": "酒泉市",
                "text": "瓜州县",
                "level": 3,
                "order": 0,
                "relation_code": "620922",
                "relation_desc": "甘肃省瓜州县"
            },
            {
                "code": "086005",
                "parentcode": "0860",
                "parenttext": "省森工总局",
                "text": "大兴安岭林管局",
                "level": 3,
                "order": 0,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "140413",
                "parentcode": "1404",
                "parenttext": "九江市",
                "text": "共青城市",
                "level": 3,
                "order": 0,
                "relation_code": "360482",
                "relation_desc": "江西省共青城市"
            },
            {
                "code": "200907",
                "parentcode": "2009",
                "parenttext": "玉林市",
                "text": "福绵区",
                "level": 3,
                "order": 0,
                "relation_code": "450903",
                "relation_desc": "广西玉林市福绵区"
            },
            {
                "code": "311211",
                "parentcode": "3112",
                "parenttext": "伊犁哈萨克自治州",
                "text": "霍尔果斯市",
                "level": 3,
                "order": 0,
                "relation_code": "654001",
                "relation_desc": "新疆伊犁哈萨克自治州"
            },
            {
                "code": "085008",
                "parentcode": "0850",
                "parenttext": "省农垦总局",
                "text": "哈尔滨管理局",
                "level": 3,
                "order": 0,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "210203",
                "parentcode": "2102",
                "parenttext": "三亚市",
                "text": "天涯区",
                "level": 3,
                "order": 0,
                "relation_code": "460204",
                "relation_desc": "海南省三亚市"
            },
            {
                "code": "210204",
                "parentcode": "2102",
                "parenttext": "三亚市",
                "text": "崖州区",
                "level": 3,
                "order": 0,
                "relation_code": "460205",
                "relation_desc": "海南省三亚市"
            },
            {
                "code": "311507",
                "parentcode": "3115",
                "parenttext": "新疆生产建设兵团",
                "text": "昆玉市",
                "level": 3,
                "order": 0,
                "relation_code": "659001",
                "relation_desc": "新疆省直辖行政单位"
            },
            {
                "code": "020119",
                "parentcode": "0201",
                "parenttext": "天津市",
                "text": "滨海新区",
                "level": 3,
                "order": 0,
                "relation_code": "120116",
                "relation_desc": "天津市滨海新区"
            },
            {
                "code": "085003",
                "parentcode": "0850",
                "parenttext": "省农垦总局",
                "text": "建三江管理局",
                "level": 3,
                "order": 0,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "085005",
                "parentcode": "0850",
                "parenttext": "省农垦总局",
                "text": "北安管理局",
                "level": 3,
                "order": 0,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "085009",
                "parentcode": "0850",
                "parenttext": "省农垦总局",
                "text": "绥化管理局",
                "level": 3,
                "order": 0,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "211901",
                "parentcode": "2119",
                "parenttext": "三沙市",
                "text": "西沙群岛",
                "level": 3,
                "order": 0,
                "relation_code": "460000",
                "relation_desc": "海南省西沙群岛"
            },
            {
                "code": "290808",
                "parentcode": "2908",
                "parenttext": "海西蒙古族藏族自治州",
                "text": "冷湖行委",
                "level": 3,
                "order": 0,
                "relation_code": "632801",
                "relation_desc": "青海省海西蒙古族藏族自治州"
            },
            {
                "code": "030125",
                "parentcode": "0301",
                "parenttext": "石家庄市",
                "text": "石家庄循环化工园区",
                "level": 3,
                "order": 0,
                "relation_code": "130101",
                "relation_desc": "河北省石家庄市"
            },
            {
                "code": "050714",
                "parentcode": "0507",
                "parenttext": "呼伦贝尔市",
                "text": "扎赉诺尔区",
                "level": 3,
                "order": 0,
                "relation_code": "150703",
                "relation_desc": "内蒙古呼伦贝尔市扎赉诺尔区"
            },
            {
                "code": "151104",
                "parentcode": "1511",
                "parenttext": "日照市",
                "text": "岚山区",
                "level": 3,
                "order": 0,
                "relation_code": "371103",
                "relation_desc": "山东省日照市岚山区"
            },
            {
                "code": "030627",
                "parentcode": "0306",
                "parenttext": "保定市",
                "text": "保定白沟新城",
                "level": 3,
                "order": 0,
                "relation_code": "130601",
                "relation_desc": "河北省保定市"
            },
            {
                "code": "085004",
                "parentcode": "0850",
                "parenttext": "省农垦总局",
                "text": "牡丹江管理局",
                "level": 3,
                "order": 0,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "210202",
                "parentcode": "2102",
                "parenttext": "三亚市",
                "text": "吉阳区",
                "level": 3,
                "order": 0,
                "relation_code": "460203",
                "relation_desc": "海南省三亚市"
            },
            {
                "code": "211904",
                "parentcode": "2119",
                "parenttext": "三沙市",
                "text": "永乐群岛",
                "level": 3,
                "order": 0,
                "relation_code": "460000",
                "relation_desc": "海南省三沙市"
            },
            {
                "code": "231406",
                "parentcode": "2314",
                "parenttext": "广安市",
                "text": "前锋区",
                "level": 3,
                "order": 0,
                "relation_code": "511603",
                "relation_desc": "四川省广安市前锋区"
            },
            {
                "code": "300305",
                "parentcode": "3003",
                "parenttext": "吴忠市",
                "text": "红寺堡区",
                "level": 3,
                "order": 0,
                "relation_code": "640303",
                "relation_desc": "宁夏吴忠市红寺堡区"
            },
            {
                "code": "2119",
                "parentcode": "21",
                "parenttext": "海南省",
                "text": "三沙市",
                "level": 2,
                "order": 0,
                "relation_code": "460300",
                "relation_desc": "海南省三沙市"
            },
            {
                "code": "311504",
                "parentcode": "3115",
                "parenttext": "新疆生产建设兵团",
                "text": "图木舒克市",
                "level": 3,
                "order": 0,
                "relation_code": "659003",
                "relation_desc": "新疆图木舒克市"
            },
            {
                "code": "085006",
                "parentcode": "0850",
                "parenttext": "省农垦总局",
                "text": "九三管理局",
                "level": 3,
                "order": 0,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "190114",
                "parentcode": "1901",
                "parenttext": "广州市",
                "text": "萝岗区",
                "level": 3,
                "order": 0,
                "relation_code": "440101",
                "relation_desc": "广东省广州市萝岗区"
            },
            {
                "code": "311505",
                "parentcode": "3115",
                "parenttext": "新疆生产建设兵团",
                "text": "北屯市",
                "level": 3,
                "order": 0,
                "relation_code": "659005",
                "relation_desc": "新疆北屯市"
            },
            {
                "code": "190113",
                "parentcode": "1901",
                "parenttext": "广州市",
                "text": "南沙区",
                "level": 3,
                "order": 0,
                "relation_code": "440115",
                "relation_desc": "广东省广州市南沙区"
            },
            {
                "code": "201105",
                "parentcode": "2011",
                "parenttext": "贺州市",
                "text": "平桂区",
                "level": 3,
                "order": 0,
                "relation_code": "451101",
                "relation_desc": "广西贺州市"
            },
            {
                "code": "290807",
                "parentcode": "2908",
                "parenttext": "海西蒙古族藏族自治州",
                "text": "茫崖行委",
                "level": 3,
                "order": 0,
                "relation_code": "632801",
                "relation_desc": "青海省海西蒙古族藏族自治州"
            },
            {
                "code": "030124",
                "parentcode": "0301",
                "parenttext": "石家庄市",
                "text": "石家庄高新技术产业开发区",
                "level": 3,
                "order": 0,
                "relation_code": "130101",
                "relation_desc": "河北省石家庄市"
            },
            {
                "code": "050609",
                "parentcode": "0506",
                "parenttext": "鄂尔多斯市",
                "text": "康巴什区",
                "level": 3,
                "order": 0,
                "relation_code": "150601",
                "relation_desc": "内蒙古鄂尔多斯市"
            },
            {
                "code": "086001",
                "parentcode": "0860",
                "parenttext": "省森工总局",
                "text": "松花江林业管理局",
                "level": 3,
                "order": 0,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "290806",
                "parentcode": "2908",
                "parenttext": "海西蒙古族藏族自治州",
                "text": "大柴旦行委",
                "level": 3,
                "order": 0,
                "relation_code": "632801",
                "relation_desc": "青海省海西蒙古族藏族自治州"
            },
            {
                "code": "310604",
                "parentcode": "3106",
                "parenttext": "博尔塔拉蒙古自治州",
                "text": "阿拉山口市",
                "level": 3,
                "order": 0,
                "relation_code": "652702",
                "relation_desc": "新疆阿拉山口市"
            },
            {
                "code": "191706",
                "parentcode": "1917",
                "parenttext": "东莞市",
                "text": "塘厦镇",
                "level": 3,
                "order": 0,
                "relation_code": "441900",
                "relation_desc": "广东省东莞市"
            },
            {
                "code": "160412",
                "parentcode": "1604",
                "parenttext": "平顶山市",
                "text": "城乡一体化示范区",
                "level": 3,
                "order": 0,
                "relation_code": "410401",
                "relation_desc": "河南省平顶山市"
            },
            {
                "code": "160411",
                "parentcode": "1604",
                "parenttext": "平顶山市",
                "text": "新城区",
                "level": 3,
                "order": 0,
                "relation_code": "410401",
                "relation_desc": "河南省平顶山市"
            },
            {
                "code": "191705",
                "parentcode": "1917",
                "parenttext": "东莞市",
                "text": "麻涌镇",
                "level": 3,
                "order": 0,
                "relation_code": "441900",
                "relation_desc": "广东省东莞市"
            },
            {
                "code": "160414",
                "parentcode": "1604",
                "parenttext": "平顶山市",
                "text": "平顶山市城乡一体化示范区",
                "level": 3,
                "order": 0,
                "relation_code": "410401",
                "relation_desc": "河南省平顶山市"
            },
            {
                "code": "210503",
                "parentcode": "2105",
                "parenttext": "儋州市",
                "text": "和庆镇",
                "level": 3,
                "order": 0,
                "relation_code": "460400",
                "relation_desc": "海南省儋州市"
            },
            {
                "code": "210513",
                "parentcode": "2105",
                "parenttext": "儋州市",
                "text": "白马井镇",
                "level": 3,
                "order": 0,
                "relation_code": "460400",
                "relation_desc": "海南省儋州市"
            },
            {
                "code": "210514",
                "parentcode": "2105",
                "parenttext": "儋州市",
                "text": "中和镇",
                "level": 3,
                "order": 0,
                "relation_code": "460400",
                "relation_desc": "海南省儋州市"
            },
            {
                "code": "290809",
                "parentcode": "2908",
                "parenttext": "海西蒙古族藏族自治州",
                "text": "茫崖市",
                "level": 3,
                "order": 0,
                "relation_code": "632801",
                "relation_desc": "青海省海西蒙古族藏族自治州"
            },
            {
                "code": "210509",
                "parentcode": "2105",
                "parenttext": "儋州市",
                "text": "木棠镇",
                "level": 3,
                "order": 0,
                "relation_code": "460400",
                "relation_desc": "海南省儋州市"
            },
            {
                "code": "210510",
                "parentcode": "2105",
                "parenttext": "儋州市",
                "text": "海头镇",
                "level": 3,
                "order": 0,
                "relation_code": "460400",
                "relation_desc": "海南省儋州市"
            },
            {
                "code": "210511",
                "parentcode": "2105",
                "parenttext": "儋州市",
                "text": "峨蔓镇",
                "level": 3,
                "order": 0,
                "relation_code": "460400",
                "relation_desc": "海南省儋州市"
            },
            {
                "code": "210515",
                "parentcode": "2105",
                "parenttext": "儋州市",
                "text": "排浦镇",
                "level": 3,
                "order": 0,
                "relation_code": "460400",
                "relation_desc": "海南省儋州市"
            },
            {
                "code": "140414",
                "parentcode": "1404",
                "parenttext": "九江市",
                "text": "柴桑区",
                "level": 3,
                "order": 0,
                "relation_code": "360404",
                "relation_desc": "江西省九江市柴桑区"
            },
            {
                "code": "101306",
                "parentcode": "1013",
                "parenttext": "宿迁市",
                "text": "宿迁经济技术开发区",
                "level": 3,
                "order": 0,
                "relation_code": "321301",
                "relation_desc": "江苏省宿迁市"
            },
            {
                "code": "210502",
                "parentcode": "2105",
                "parenttext": "儋州市",
                "text": "那大镇",
                "level": 3,
                "order": 0,
                "relation_code": "460400",
                "relation_desc": "海南省儋州市"
            },
            {
                "code": "210507",
                "parentcode": "2105",
                "parenttext": "儋州市",
                "text": "兰洋镇",
                "level": 3,
                "order": 0,
                "relation_code": "460400",
                "relation_desc": "海南省儋州市"
            },
            {
                "code": "160413",
                "parentcode": "1604",
                "parenttext": "平顶山市",
                "text": "平顶山高新技术产业开发区",
                "level": 3,
                "order": 0,
                "relation_code": "410401",
                "relation_desc": "河南省平顶山市"
            },
            {
                "code": "210504",
                "parentcode": "2105",
                "parenttext": "儋州市",
                "text": "南丰镇",
                "level": 3,
                "order": 0,
                "relation_code": "460400",
                "relation_desc": "海南省儋州市"
            },
            {
                "code": "210512",
                "parentcode": "2105",
                "parenttext": "儋州市",
                "text": "王五镇",
                "level": 3,
                "order": 0,
                "relation_code": "460400",
                "relation_desc": "海南省儋州市"
            },
            {
                "code": "210516",
                "parentcode": "2105",
                "parenttext": "儋州市",
                "text": "东成镇",
                "level": 3,
                "order": 0,
                "relation_code": "460400",
                "relation_desc": "海南省儋州市"
            },
            {
                "code": "210519",
                "parentcode": "2105",
                "parenttext": "儋州市",
                "text": "华南热作学院",
                "level": 3,
                "order": 0,
                "relation_code": "460400",
                "relation_desc": "海南省儋州市"
            },
            {
                "code": "151105",
                "parentcode": "1511",
                "parenttext": "日照市",
                "text": "日照经济技术开发区",
                "level": 3,
                "order": 0,
                "relation_code": "371101",
                "relation_desc": "山东省日照市"
            },
            {
                "code": "210518",
                "parentcode": "2105",
                "parenttext": "儋州市",
                "text": "洋浦经济开发区",
                "level": 3,
                "order": 0,
                "relation_code": "460400",
                "relation_desc": "海南省儋州市"
            },
            {
                "code": "210505",
                "parentcode": "2105",
                "parenttext": "儋州市",
                "text": "大成镇",
                "level": 3,
                "order": 0,
                "relation_code": "460400",
                "relation_desc": "海南省儋州市"
            },
            {
                "code": "210506",
                "parentcode": "2105",
                "parenttext": "儋州市",
                "text": "雅星镇",
                "level": 3,
                "order": 0,
                "relation_code": "460400",
                "relation_desc": "海南省儋州市"
            },
            {
                "code": "210508",
                "parentcode": "2105",
                "parenttext": "儋州市",
                "text": "光村镇",
                "level": 3,
                "order": 0,
                "relation_code": "460400",
                "relation_desc": "海南省儋州市"
            },
            {
                "code": "050211",
                "parentcode": "0502",
                "parenttext": "包头市",
                "text": "包头稀土高新技术产业开发区",
                "level": 3,
                "order": 0,
                "relation_code": "150201",
                "relation_desc": "内蒙古包头市"
            },
            {
                "code": "210517",
                "parentcode": "2105",
                "parenttext": "儋州市",
                "text": "新州镇",
                "level": 3,
                "order": 0,
                "relation_code": "460400",
                "relation_desc": "海南省儋州市"
            },
            {
                "code": "16",
                "parentcode": "0",
                "parenttext": "0",
                "text": "河南省",
                "level": 1,
                "order": 1,
                "relation_code": "410000",
                "relation_desc": "河南省"
            },
            {
                "code": "0851",
                "parentcode": "08",
                "parenttext": "黑龙江省",
                "text": "农垦绥化管理局",
                "level": 2,
                "order": 1,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "1601",
                "parentcode": "16",
                "parenttext": "河南省",
                "text": "郑州市",
                "level": 2,
                "order": 2,
                "relation_code": "410100",
                "relation_desc": "河南省郑州市"
            },
            {
                "code": "085101",
                "parentcode": "0851",
                "parenttext": "农垦绥化管理局",
                "text": "肇源农场",
                "level": 3,
                "order": 2,
                "relation_code": "235101",
                "relation_desc": "黑龙江省肇源农场"
            },
            {
                "code": "9999",
                "parentcode": "99",
                "parenttext": "国外",
                "text": "国外",
                "level": 2,
                "order": 2,
                "relation_code": null,
                "relation_desc": "国外"
            },
            {
                "code": "160101",
                "parentcode": "1601",
                "parenttext": "郑州市",
                "text": "中原区",
                "level": 3,
                "order": 3,
                "relation_code": "410102",
                "relation_desc": "河南省郑州市中原区"
            },
            {
                "code": "085102",
                "parentcode": "0851",
                "parenttext": "农垦绥化管理局",
                "text": "安达农牧场",
                "level": 3,
                "order": 3,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "999901",
                "parentcode": "9999",
                "parenttext": "国外",
                "text": "国外",
                "level": 3,
                "order": 3,
                "relation_code": "990000",
                "relation_desc": "国外"
            },
            {
                "code": "160102",
                "parentcode": "1601",
                "parenttext": "郑州市",
                "text": "二七区",
                "level": 3,
                "order": 4,
                "relation_code": "410103",
                "relation_desc": "河南省郑州市二七区"
            },
            {
                "code": "085103",
                "parentcode": "0851",
                "parenttext": "农垦绥化管理局",
                "text": "红光农场",
                "level": 3,
                "order": 4,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160103",
                "parentcode": "1601",
                "parenttext": "郑州市",
                "text": "管城回族区",
                "level": 3,
                "order": 5,
                "relation_code": "410104",
                "relation_desc": "河南省郑州市管城回族区"
            },
            {
                "code": "085104",
                "parentcode": "0851",
                "parenttext": "农垦绥化管理局",
                "text": "嘉荫农场",
                "level": 3,
                "order": 5,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160104",
                "parentcode": "1601",
                "parenttext": "郑州市",
                "text": "金水区",
                "level": 3,
                "order": 6,
                "relation_code": "410105",
                "relation_desc": "河南省郑州市金水区"
            },
            {
                "code": "085105",
                "parentcode": "0851",
                "parenttext": "农垦绥化管理局",
                "text": "绥棱农场",
                "level": 3,
                "order": 6,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160105",
                "parentcode": "1601",
                "parenttext": "郑州市",
                "text": "上街区",
                "level": 3,
                "order": 7,
                "relation_code": "410106",
                "relation_desc": "河南省郑州市上街区"
            },
            {
                "code": "085106",
                "parentcode": "0851",
                "parenttext": "农垦绥化管理局",
                "text": "和平种畜场",
                "level": 3,
                "order": 7,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160106",
                "parentcode": "1601",
                "parenttext": "郑州市",
                "text": "惠济区",
                "level": 3,
                "order": 8,
                "relation_code": "410108",
                "relation_desc": "河南省郑州市惠济区"
            },
            {
                "code": "085107",
                "parentcode": "0851",
                "parenttext": "农垦绥化管理局",
                "text": "海伦农场",
                "level": 3,
                "order": 8,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160107",
                "parentcode": "1601",
                "parenttext": "郑州市",
                "text": "高新区",
                "level": 3,
                "order": 9,
                "relation_code": "410102",
                "relation_desc": "河南省郑州市中原区"
            },
            {
                "code": "085108",
                "parentcode": "0851",
                "parenttext": "农垦绥化管理局",
                "text": "铁力农场",
                "level": 3,
                "order": 9,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160108",
                "parentcode": "1601",
                "parenttext": "郑州市",
                "text": "经开区",
                "level": 3,
                "order": 10,
                "relation_code": "410104",
                "relation_desc": "河南省郑州市管城回族区"
            },
            {
                "code": "0852",
                "parentcode": "08",
                "parenttext": "黑龙江省",
                "text": "农垦宝泉岭管理局",
                "level": 2,
                "order": 10,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160109",
                "parentcode": "1601",
                "parenttext": "郑州市",
                "text": "郑东新区",
                "level": 3,
                "order": 11,
                "relation_code": "410105",
                "relation_desc": "河南省郑州市金水区"
            },
            {
                "code": "085201",
                "parentcode": "0852",
                "parenttext": "农垦宝泉岭管理局",
                "text": "新华农场",
                "level": 3,
                "order": 11,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160110",
                "parentcode": "1601",
                "parenttext": "郑州市",
                "text": "中牟县",
                "level": 3,
                "order": 12,
                "relation_code": "410122",
                "relation_desc": "河南省中牟县"
            },
            {
                "code": "085202",
                "parentcode": "0852",
                "parenttext": "农垦宝泉岭管理局",
                "text": "江滨农场",
                "level": 3,
                "order": 12,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160111",
                "parentcode": "1601",
                "parenttext": "郑州市",
                "text": "巩义市",
                "level": 3,
                "order": 13,
                "relation_code": "410181",
                "relation_desc": "河南省巩义市"
            },
            {
                "code": "085203",
                "parentcode": "0852",
                "parenttext": "农垦宝泉岭管理局",
                "text": "军川农场",
                "level": 3,
                "order": 13,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160112",
                "parentcode": "1601",
                "parenttext": "郑州市",
                "text": "荥阳市",
                "level": 3,
                "order": 14,
                "relation_code": "410182",
                "relation_desc": "河南省荥阳市"
            },
            {
                "code": "085204",
                "parentcode": "0852",
                "parenttext": "农垦宝泉岭管理局",
                "text": "汤原农场",
                "level": 3,
                "order": 14,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160113",
                "parentcode": "1601",
                "parenttext": "郑州市",
                "text": "新密市",
                "level": 3,
                "order": 15,
                "relation_code": "410183",
                "relation_desc": "河南省新密市"
            },
            {
                "code": "085205",
                "parentcode": "0852",
                "parenttext": "农垦宝泉岭管理局",
                "text": "梧桐河农场",
                "level": 3,
                "order": 15,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160114",
                "parentcode": "1601",
                "parenttext": "郑州市",
                "text": "新郑市",
                "level": 3,
                "order": 16,
                "relation_code": "410184",
                "relation_desc": "河南省新郑市"
            },
            {
                "code": "085206",
                "parentcode": "0852",
                "parenttext": "农垦宝泉岭管理局",
                "text": "绥滨农场",
                "level": 3,
                "order": 16,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160115",
                "parentcode": "1601",
                "parenttext": "郑州市",
                "text": "登封市",
                "level": 3,
                "order": 17,
                "relation_code": "410185",
                "relation_desc": "河南省登封市"
            },
            {
                "code": "085207",
                "parentcode": "0852",
                "parenttext": "农垦宝泉岭管理局",
                "text": "宝泉岭农场",
                "level": 3,
                "order": 17,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160116",
                "parentcode": "1601",
                "parenttext": "郑州市",
                "text": "航空港区",
                "level": 3,
                "order": 18,
                "relation_code": "410101",
                "relation_desc": "河南省郑州市"
            },
            {
                "code": "085208",
                "parentcode": "0852",
                "parenttext": "农垦宝泉岭管理局",
                "text": "普阳农场",
                "level": 3,
                "order": 18,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "1602",
                "parentcode": "16",
                "parenttext": "河南省",
                "text": "开封市",
                "level": 2,
                "order": 19,
                "relation_code": "410200",
                "relation_desc": "河南省开封市"
            },
            {
                "code": "085209",
                "parentcode": "0852",
                "parenttext": "农垦宝泉岭管理局",
                "text": "共青农场",
                "level": 3,
                "order": 19,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160201",
                "parentcode": "1602",
                "parenttext": "开封市",
                "text": "龙亭区",
                "level": 3,
                "order": 20,
                "relation_code": "410202",
                "relation_desc": "河南省开封市龙亭区"
            },
            {
                "code": "085210",
                "parentcode": "0852",
                "parenttext": "农垦宝泉岭管理局",
                "text": "名出农场",
                "level": 3,
                "order": 20,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160202",
                "parentcode": "1602",
                "parenttext": "开封市",
                "text": "顺河回族区",
                "level": 3,
                "order": 21,
                "relation_code": "410203",
                "relation_desc": "河南省开封市顺河回族区"
            },
            {
                "code": "085211",
                "parentcode": "0852",
                "parenttext": "农垦宝泉岭管理局",
                "text": "依兰农场",
                "level": 3,
                "order": 21,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160203",
                "parentcode": "1602",
                "parenttext": "开封市",
                "text": "鼓楼区",
                "level": 3,
                "order": 22,
                "relation_code": "410204",
                "relation_desc": "河南省开封市鼓楼区"
            },
            {
                "code": "085212",
                "parentcode": "0852",
                "parenttext": "农垦宝泉岭管理局",
                "text": "延军农场",
                "level": 3,
                "order": 22,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160204",
                "parentcode": "1602",
                "parenttext": "开封市",
                "text": "祥符区",
                "level": 3,
                "order": 23,
                "relation_code": "410212",
                "relation_desc": "河南省开封市祥符区"
            },
            {
                "code": "085213",
                "parentcode": "0852",
                "parenttext": "农垦宝泉岭管理局",
                "text": "二九Ｏ农场",
                "level": 3,
                "order": 23,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160205",
                "parentcode": "1602",
                "parenttext": "开封市",
                "text": "禹王台区",
                "level": 3,
                "order": 24,
                "relation_code": "410205",
                "relation_desc": "河南省开封市禹王台区"
            },
            {
                "code": "0853",
                "parentcode": "08",
                "parenttext": "黑龙江省",
                "text": "农垦建三江管理局",
                "level": 2,
                "order": 24,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160206",
                "parentcode": "1602",
                "parenttext": "开封市",
                "text": "杞县",
                "level": 3,
                "order": 25,
                "relation_code": "410221",
                "relation_desc": "河南省杞县"
            },
            {
                "code": "085301",
                "parentcode": "0853",
                "parenttext": "农垦建三江管理局",
                "text": "大兴农场",
                "level": 3,
                "order": 25,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160207",
                "parentcode": "1602",
                "parenttext": "开封市",
                "text": "通许县",
                "level": 3,
                "order": 26,
                "relation_code": "410222",
                "relation_desc": "河南省通许县"
            },
            {
                "code": "085302",
                "parentcode": "0853",
                "parenttext": "农垦建三江管理局",
                "text": "红卫农场",
                "level": 3,
                "order": 26,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160208",
                "parentcode": "1602",
                "parenttext": "开封市",
                "text": "尉氏县",
                "level": 3,
                "order": 27,
                "relation_code": "410223",
                "relation_desc": "河南省尉氏县"
            },
            {
                "code": "085303",
                "parentcode": "0853",
                "parenttext": "农垦建三江管理局",
                "text": "八五九农场",
                "level": 3,
                "order": 27,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160209",
                "parentcode": "1602",
                "parenttext": "开封市",
                "text": "开封县",
                "level": 3,
                "order": 28,
                "relation_code": "410212",
                "relation_desc": "河南省开封市祥符区"
            },
            {
                "code": "085304",
                "parentcode": "0853",
                "parenttext": "农垦建三江管理局",
                "text": "前进农场",
                "level": 3,
                "order": 28,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160210",
                "parentcode": "1602",
                "parenttext": "开封市",
                "text": "兰考县",
                "level": 3,
                "order": 29,
                "relation_code": "410225",
                "relation_desc": "河南省兰考县"
            },
            {
                "code": "085305",
                "parentcode": "0853",
                "parenttext": "农垦建三江管理局",
                "text": "青龙山农场",
                "level": 3,
                "order": 29,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160211",
                "parentcode": "1602",
                "parenttext": "开封市",
                "text": "开封市市辖区",
                "level": 3,
                "order": 30,
                "relation_code": "410201",
                "relation_desc": "河南省开封市市辖区"
            },
            {
                "code": "085306",
                "parentcode": "0853",
                "parenttext": "农垦建三江管理局",
                "text": "前锋农场",
                "level": 3,
                "order": 30,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "1603",
                "parentcode": "16",
                "parenttext": "河南省",
                "text": "洛阳市",
                "level": 2,
                "order": 31,
                "relation_code": "410300",
                "relation_desc": "河南省洛阳市"
            },
            {
                "code": "085307",
                "parentcode": "0853",
                "parenttext": "农垦建三江管理局",
                "text": "七星农场",
                "level": 3,
                "order": 31,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160301",
                "parentcode": "1603",
                "parenttext": "洛阳市",
                "text": "老城区",
                "level": 3,
                "order": 32,
                "relation_code": "410302",
                "relation_desc": "河南省洛阳市老城区"
            },
            {
                "code": "085308",
                "parentcode": "0853",
                "parenttext": "农垦建三江管理局",
                "text": "创业农场",
                "level": 3,
                "order": 32,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160302",
                "parentcode": "1603",
                "parenttext": "洛阳市",
                "text": "西工区",
                "level": 3,
                "order": 33,
                "relation_code": "410303",
                "relation_desc": "河南省洛阳市西工区"
            },
            {
                "code": "085309",
                "parentcode": "0853",
                "parenttext": "农垦建三江管理局",
                "text": "胜利农场",
                "level": 3,
                "order": 33,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160303",
                "parentcode": "1603",
                "parenttext": "洛阳市",
                "text": "瀍河回族区",
                "level": 3,
                "order": 34,
                "relation_code": "410304",
                "relation_desc": "河南省洛阳市瀍河回族区"
            },
            {
                "code": "085310",
                "parentcode": "0853",
                "parenttext": "农垦建三江管理局",
                "text": "二道河农场",
                "level": 3,
                "order": 34,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160304",
                "parentcode": "1603",
                "parenttext": "洛阳市",
                "text": "涧西区",
                "level": 3,
                "order": 35,
                "relation_code": "410305",
                "relation_desc": "河南省洛阳市涧西区"
            },
            {
                "code": "085311",
                "parentcode": "0853",
                "parenttext": "农垦建三江管理局",
                "text": "洪河农场",
                "level": 3,
                "order": 35,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160305",
                "parentcode": "1603",
                "parenttext": "洛阳市",
                "text": "吉利区",
                "level": 3,
                "order": 36,
                "relation_code": "410306",
                "relation_desc": "河南省洛阳市吉利区"
            },
            {
                "code": "085312",
                "parentcode": "0853",
                "parenttext": "农垦建三江管理局",
                "text": "鸭绿河农场",
                "level": 3,
                "order": 36,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160306",
                "parentcode": "1603",
                "parenttext": "洛阳市",
                "text": "洛龙区",
                "level": 3,
                "order": 37,
                "relation_code": "410311",
                "relation_desc": "河南省洛阳市洛龙区"
            },
            {
                "code": "085313",
                "parentcode": "0853",
                "parenttext": "农垦建三江管理局",
                "text": "浓江农场",
                "level": 3,
                "order": 37,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160317",
                "parentcode": "1603",
                "parenttext": "洛阳市",
                "text": "伊滨区",
                "level": 3,
                "order": 37,
                "relation_code": "410301",
                "relation_desc": "河南省洛阳市伊滨区"
            },
            {
                "code": "160307",
                "parentcode": "1603",
                "parenttext": "洛阳市",
                "text": "孟津县",
                "level": 3,
                "order": 38,
                "relation_code": "410322",
                "relation_desc": "河南省孟津县"
            },
            {
                "code": "085314",
                "parentcode": "0853",
                "parenttext": "农垦建三江管理局",
                "text": "前哨农场",
                "level": 3,
                "order": 38,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160308",
                "parentcode": "1603",
                "parenttext": "洛阳市",
                "text": "新安县",
                "level": 3,
                "order": 39,
                "relation_code": "410323",
                "relation_desc": "河南省新安县"
            },
            {
                "code": "0854",
                "parentcode": "08",
                "parenttext": "黑龙江省",
                "text": "农垦红兴隆管理局",
                "level": 2,
                "order": 39,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160309",
                "parentcode": "1603",
                "parenttext": "洛阳市",
                "text": "栾川县",
                "level": 3,
                "order": 40,
                "relation_code": "410324",
                "relation_desc": "河南省栾川县"
            },
            {
                "code": "085401",
                "parentcode": "0854",
                "parenttext": "农垦红兴隆管理局",
                "text": "八五二农场",
                "level": 3,
                "order": 40,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160310",
                "parentcode": "1603",
                "parenttext": "洛阳市",
                "text": "嵩县",
                "level": 3,
                "order": 41,
                "relation_code": "410325",
                "relation_desc": "河南省嵩县"
            },
            {
                "code": "085402",
                "parentcode": "0854",
                "parenttext": "农垦红兴隆管理局",
                "text": "友谊农场",
                "level": 3,
                "order": 41,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160311",
                "parentcode": "1603",
                "parenttext": "洛阳市",
                "text": "汝阳县",
                "level": 3,
                "order": 42,
                "relation_code": "410326",
                "relation_desc": "河南省汝阳县"
            },
            {
                "code": "085403",
                "parentcode": "0854",
                "parenttext": "农垦红兴隆管理局",
                "text": "八五三农场",
                "level": 3,
                "order": 42,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160312",
                "parentcode": "1603",
                "parenttext": "洛阳市",
                "text": "宜阳县",
                "level": 3,
                "order": 43,
                "relation_code": "410327",
                "relation_desc": "河南省宜阳县"
            },
            {
                "code": "085404",
                "parentcode": "0854",
                "parenttext": "农垦红兴隆管理局",
                "text": "红旗岭农场",
                "level": 3,
                "order": 43,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160313",
                "parentcode": "1603",
                "parenttext": "洛阳市",
                "text": "洛宁县",
                "level": 3,
                "order": 44,
                "relation_code": "410328",
                "relation_desc": "河南省洛宁县"
            },
            {
                "code": "085405",
                "parentcode": "0854",
                "parenttext": "农垦红兴隆管理局",
                "text": "二九一农场",
                "level": 3,
                "order": 44,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160314",
                "parentcode": "1603",
                "parenttext": "洛阳市",
                "text": "伊川县",
                "level": 3,
                "order": 45,
                "relation_code": "410329",
                "relation_desc": "河南省伊川县"
            },
            {
                "code": "085406",
                "parentcode": "0854",
                "parenttext": "农垦红兴隆管理局",
                "text": "江川农场",
                "level": 3,
                "order": 45,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160315",
                "parentcode": "1603",
                "parenttext": "洛阳市",
                "text": "偃师市",
                "level": 3,
                "order": 46,
                "relation_code": "410381",
                "relation_desc": "河南省偃师市"
            },
            {
                "code": "085407",
                "parentcode": "0854",
                "parenttext": "农垦红兴隆管理局",
                "text": "北兴农场",
                "level": 3,
                "order": 46,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "1604",
                "parentcode": "16",
                "parenttext": "河南省",
                "text": "平顶山市",
                "level": 2,
                "order": 47,
                "relation_code": "410400",
                "relation_desc": "河南省平顶山市"
            },
            {
                "code": "085408",
                "parentcode": "0854",
                "parenttext": "农垦红兴隆管理局",
                "text": "五九七农场",
                "level": 3,
                "order": 47,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160316",
                "parentcode": "1603",
                "parenttext": "洛阳市",
                "text": "洛阳高新技术产业开发区",
                "level": 3,
                "order": 47,
                "relation_code": "410301",
                "relation_desc": "河南省洛阳市"
            },
            {
                "code": "160401",
                "parentcode": "1604",
                "parenttext": "平顶山市",
                "text": "新华区",
                "level": 3,
                "order": 48,
                "relation_code": "410402",
                "relation_desc": "河南省平顶山市新华区"
            },
            {
                "code": "085409",
                "parentcode": "0854",
                "parenttext": "农垦红兴隆管理局",
                "text": "饶河农场",
                "level": 3,
                "order": 48,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160402",
                "parentcode": "1604",
                "parenttext": "平顶山市",
                "text": "卫东区",
                "level": 3,
                "order": 49,
                "relation_code": "410403",
                "relation_desc": "河南省平顶山市卫东区"
            },
            {
                "code": "085410",
                "parentcode": "0854",
                "parenttext": "农垦红兴隆管理局",
                "text": "双鸭山农场",
                "level": 3,
                "order": 49,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160403",
                "parentcode": "1604",
                "parenttext": "平顶山市",
                "text": "石龙区",
                "level": 3,
                "order": 50,
                "relation_code": "410404",
                "relation_desc": "河南省平顶山市石龙区"
            },
            {
                "code": "085411",
                "parentcode": "0854",
                "parenttext": "农垦红兴隆管理局",
                "text": "曙光农场",
                "level": 3,
                "order": 50,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160404",
                "parentcode": "1604",
                "parenttext": "平顶山市",
                "text": "湛河区",
                "level": 3,
                "order": 51,
                "relation_code": "410411",
                "relation_desc": "河南省平顶山市湛河区"
            },
            {
                "code": "085412",
                "parentcode": "0854",
                "parenttext": "农垦红兴隆管理局",
                "text": "宝山农场",
                "level": 3,
                "order": 51,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160405",
                "parentcode": "1604",
                "parenttext": "平顶山市",
                "text": "宝丰县",
                "level": 3,
                "order": 52,
                "relation_code": "410421",
                "relation_desc": "河南省宝丰县"
            },
            {
                "code": "0855",
                "parentcode": "08",
                "parenttext": "黑龙江省",
                "text": "农垦牡丹江管理局",
                "level": 2,
                "order": 52,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160406",
                "parentcode": "1604",
                "parenttext": "平顶山市",
                "text": "叶县",
                "level": 3,
                "order": 53,
                "relation_code": "410422",
                "relation_desc": "河南省叶县"
            },
            {
                "code": "085501",
                "parentcode": "0855",
                "parenttext": "农垦牡丹江管理局",
                "text": "八五七农场",
                "level": 3,
                "order": 53,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160407",
                "parentcode": "1604",
                "parenttext": "平顶山市",
                "text": "鲁山县",
                "level": 3,
                "order": 54,
                "relation_code": "410423",
                "relation_desc": "河南省鲁山县"
            },
            {
                "code": "085502",
                "parentcode": "0855",
                "parenttext": "农垦牡丹江管理局",
                "text": "八五五农场",
                "level": 3,
                "order": 54,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160408",
                "parentcode": "1604",
                "parenttext": "平顶山市",
                "text": "郏县",
                "level": 3,
                "order": 55,
                "relation_code": "410425",
                "relation_desc": "河南省郏县"
            },
            {
                "code": "085503",
                "parentcode": "0855",
                "parenttext": "农垦牡丹江管理局",
                "text": "八五一Ｏ农场",
                "level": 3,
                "order": 55,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160409",
                "parentcode": "1604",
                "parenttext": "平顶山市",
                "text": "舞钢市",
                "level": 3,
                "order": 56,
                "relation_code": "410481",
                "relation_desc": "河南省舞钢市"
            },
            {
                "code": "085504",
                "parentcode": "0855",
                "parenttext": "农垦牡丹江管理局",
                "text": "八五Ｏ农场",
                "level": 3,
                "order": 56,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160410",
                "parentcode": "1604",
                "parenttext": "平顶山市",
                "text": "汝州市",
                "level": 3,
                "order": 57,
                "relation_code": "410482",
                "relation_desc": "河南省汝州市"
            },
            {
                "code": "085505",
                "parentcode": "0855",
                "parenttext": "农垦牡丹江管理局",
                "text": "八五八农场",
                "level": 3,
                "order": 57,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "1605",
                "parentcode": "16",
                "parenttext": "河南省",
                "text": "安阳市",
                "level": 2,
                "order": 58,
                "relation_code": "410500",
                "relation_desc": "河南省安阳市"
            },
            {
                "code": "085506",
                "parentcode": "0855",
                "parenttext": "农垦牡丹江管理局",
                "text": "庆丰农场",
                "level": 3,
                "order": 58,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160501",
                "parentcode": "1605",
                "parenttext": "安阳市",
                "text": "文峰区",
                "level": 3,
                "order": 59,
                "relation_code": "410502",
                "relation_desc": "河南省安阳市文峰区"
            },
            {
                "code": "085507",
                "parentcode": "0855",
                "parenttext": "农垦牡丹江管理局",
                "text": "海林农场",
                "level": 3,
                "order": 59,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160502",
                "parentcode": "1605",
                "parenttext": "安阳市",
                "text": "北关区",
                "level": 3,
                "order": 60,
                "relation_code": "410503",
                "relation_desc": "河南省安阳市北关区"
            },
            {
                "code": "085508",
                "parentcode": "0855",
                "parenttext": "农垦牡丹江管理局",
                "text": "兴凯湖农场",
                "level": 3,
                "order": 60,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160503",
                "parentcode": "1605",
                "parenttext": "安阳市",
                "text": "殷都区",
                "level": 3,
                "order": 61,
                "relation_code": "410505",
                "relation_desc": "河南省安阳市殷都区"
            },
            {
                "code": "085509",
                "parentcode": "0855",
                "parenttext": "农垦牡丹江管理局",
                "text": "龙头农场",
                "level": 3,
                "order": 61,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160504",
                "parentcode": "1605",
                "parenttext": "安阳市",
                "text": "龙安区",
                "level": 3,
                "order": 62,
                "relation_code": "410506",
                "relation_desc": "河南省安阳市龙安区"
            },
            {
                "code": "085510",
                "parentcode": "0855",
                "parenttext": "农垦牡丹江管理局",
                "text": "八五一一农场",
                "level": 3,
                "order": 62,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160505",
                "parentcode": "1605",
                "parenttext": "安阳市",
                "text": "安阳县",
                "level": 3,
                "order": 63,
                "relation_code": "410522",
                "relation_desc": "河南省安阳县"
            },
            {
                "code": "085511",
                "parentcode": "0855",
                "parenttext": "农垦牡丹江管理局",
                "text": "云山农场",
                "level": 3,
                "order": 63,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160506",
                "parentcode": "1605",
                "parenttext": "安阳市",
                "text": "汤阴县",
                "level": 3,
                "order": 64,
                "relation_code": "410523",
                "relation_desc": "河南省汤阴县"
            },
            {
                "code": "085512",
                "parentcode": "0855",
                "parenttext": "农垦牡丹江管理局",
                "text": "八五六农场",
                "level": 3,
                "order": 64,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160507",
                "parentcode": "1605",
                "parenttext": "安阳市",
                "text": "滑县",
                "level": 3,
                "order": 65,
                "relation_code": "410526",
                "relation_desc": "河南省滑县"
            },
            {
                "code": "085513",
                "parentcode": "0855",
                "parenttext": "农垦牡丹江管理局",
                "text": "八五四农场",
                "level": 3,
                "order": 65,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160508",
                "parentcode": "1605",
                "parenttext": "安阳市",
                "text": "内黄县",
                "level": 3,
                "order": 66,
                "relation_code": "410527",
                "relation_desc": "河南省内黄县"
            },
            {
                "code": "085514",
                "parentcode": "0855",
                "parenttext": "农垦牡丹江管理局",
                "text": "宁安农场",
                "level": 3,
                "order": 66,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160509",
                "parentcode": "1605",
                "parenttext": "安阳市",
                "text": "林州市",
                "level": 3,
                "order": 67,
                "relation_code": "410581",
                "relation_desc": "河南省林州市"
            },
            {
                "code": "0856",
                "parentcode": "08",
                "parenttext": "黑龙江省",
                "text": "农垦北安管理局",
                "level": 2,
                "order": 67,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "1606",
                "parentcode": "16",
                "parenttext": "河南省",
                "text": "鹤壁市",
                "level": 2,
                "order": 68,
                "relation_code": "410600",
                "relation_desc": "河南省鹤壁市"
            },
            {
                "code": "085601",
                "parentcode": "0856",
                "parenttext": "农垦北安管理局",
                "text": "赵光农场",
                "level": 3,
                "order": 68,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160510",
                "parentcode": "1605",
                "parenttext": "安阳市",
                "text": "安阳高新技术产业开发区",
                "level": 3,
                "order": 68,
                "relation_code": "410501",
                "relation_desc": "河南省安阳市"
            },
            {
                "code": "160601",
                "parentcode": "1606",
                "parenttext": "鹤壁市",
                "text": "鹤山区",
                "level": 3,
                "order": 69,
                "relation_code": "410602",
                "relation_desc": "河南省鹤壁市鹤山区"
            },
            {
                "code": "085602",
                "parentcode": "0856",
                "parenttext": "农垦北安管理局",
                "text": "红星农场",
                "level": 3,
                "order": 69,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160602",
                "parentcode": "1606",
                "parenttext": "鹤壁市",
                "text": "山城区",
                "level": 3,
                "order": 70,
                "relation_code": "410603",
                "relation_desc": "河南省鹤壁市山城区"
            },
            {
                "code": "085603",
                "parentcode": "0856",
                "parenttext": "农垦北安管理局",
                "text": "建设农场",
                "level": 3,
                "order": 70,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160603",
                "parentcode": "1606",
                "parenttext": "鹤壁市",
                "text": "淇滨区",
                "level": 3,
                "order": 71,
                "relation_code": "410611",
                "relation_desc": "河南省鹤壁市淇滨区"
            },
            {
                "code": "085604",
                "parentcode": "0856",
                "parenttext": "农垦北安管理局",
                "text": "龙镇农场",
                "level": 3,
                "order": 71,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160604",
                "parentcode": "1606",
                "parenttext": "鹤壁市",
                "text": "浚县",
                "level": 3,
                "order": 72,
                "relation_code": "410621",
                "relation_desc": "河南省浚县"
            },
            {
                "code": "085605",
                "parentcode": "0856",
                "parenttext": "农垦北安管理局",
                "text": "格球山农场",
                "level": 3,
                "order": 72,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160605",
                "parentcode": "1606",
                "parenttext": "鹤壁市",
                "text": "淇县",
                "level": 3,
                "order": 73,
                "relation_code": "410622",
                "relation_desc": "河南省淇县"
            },
            {
                "code": "085606",
                "parentcode": "0856",
                "parenttext": "农垦北安管理局",
                "text": "红色边疆农场",
                "level": 3,
                "order": 73,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "1607",
                "parentcode": "16",
                "parenttext": "河南省",
                "text": "新乡市",
                "level": 2,
                "order": 74,
                "relation_code": "410700",
                "relation_desc": "河南省新乡市"
            },
            {
                "code": "085607",
                "parentcode": "0856",
                "parenttext": "农垦北安管理局",
                "text": "锦河农场",
                "level": 3,
                "order": 74,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160606",
                "parentcode": "1606",
                "parenttext": "鹤壁市",
                "text": "鹤壁经济技术开发区",
                "level": 3,
                "order": 74,
                "relation_code": "410601",
                "relation_desc": "河南省鹤壁市"
            },
            {
                "code": "160701",
                "parentcode": "1607",
                "parenttext": "新乡市",
                "text": "红旗区",
                "level": 3,
                "order": 75,
                "relation_code": "410702",
                "relation_desc": "河南省新乡市红旗区"
            },
            {
                "code": "085608",
                "parentcode": "0856",
                "parenttext": "农垦北安管理局",
                "text": "长水河农场",
                "level": 3,
                "order": 75,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160702",
                "parentcode": "1607",
                "parenttext": "新乡市",
                "text": "卫滨区",
                "level": 3,
                "order": 76,
                "relation_code": "410703",
                "relation_desc": "河南省新乡市卫滨区"
            },
            {
                "code": "085609",
                "parentcode": "0856",
                "parenttext": "农垦北安管理局",
                "text": "龙门农场",
                "level": 3,
                "order": 76,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160703",
                "parentcode": "1607",
                "parenttext": "新乡市",
                "text": "凤泉区",
                "level": 3,
                "order": 77,
                "relation_code": "410704",
                "relation_desc": "河南省新乡市凤泉区"
            },
            {
                "code": "085610",
                "parentcode": "0856",
                "parenttext": "农垦北安管理局",
                "text": "引龙河农场",
                "level": 3,
                "order": 77,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160704",
                "parentcode": "1607",
                "parenttext": "新乡市",
                "text": "牧野区",
                "level": 3,
                "order": 78,
                "relation_code": "410711",
                "relation_desc": "河南省新乡市牧野区"
            },
            {
                "code": "085611",
                "parentcode": "0856",
                "parenttext": "农垦北安管理局",
                "text": "尾山农场",
                "level": 3,
                "order": 78,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160705",
                "parentcode": "1607",
                "parenttext": "新乡市",
                "text": "新乡县",
                "level": 3,
                "order": 79,
                "relation_code": "410721",
                "relation_desc": "河南省新乡县"
            },
            {
                "code": "085612",
                "parentcode": "0856",
                "parenttext": "农垦北安管理局",
                "text": "襄河农场",
                "level": 3,
                "order": 79,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160706",
                "parentcode": "1607",
                "parenttext": "新乡市",
                "text": "获嘉县",
                "level": 3,
                "order": 80,
                "relation_code": "410724",
                "relation_desc": "河南省获嘉县"
            },
            {
                "code": "085613",
                "parentcode": "0856",
                "parenttext": "农垦北安管理局",
                "text": "逊克农场",
                "level": 3,
                "order": 80,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160707",
                "parentcode": "1607",
                "parenttext": "新乡市",
                "text": "原阳县",
                "level": 3,
                "order": 81,
                "relation_code": "410725",
                "relation_desc": "河南省原阳县"
            },
            {
                "code": "0857",
                "parentcode": "08",
                "parenttext": "黑龙江省",
                "text": "农垦九三管理局",
                "level": 2,
                "order": 81,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160708",
                "parentcode": "1607",
                "parenttext": "新乡市",
                "text": "延津县",
                "level": 3,
                "order": 82,
                "relation_code": "410726",
                "relation_desc": "河南省延津县"
            },
            {
                "code": "085701",
                "parentcode": "0857",
                "parenttext": "农垦九三管理局",
                "text": "跃进农场",
                "level": 3,
                "order": 82,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160709",
                "parentcode": "1607",
                "parenttext": "新乡市",
                "text": "封丘县",
                "level": 3,
                "order": 83,
                "relation_code": "410727",
                "relation_desc": "河南省封丘县"
            },
            {
                "code": "085702",
                "parentcode": "0857",
                "parenttext": "农垦九三管理局",
                "text": "尖山农场",
                "level": 3,
                "order": 83,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160710",
                "parentcode": "1607",
                "parenttext": "新乡市",
                "text": "长垣市",
                "level": 3,
                "order": 84,
                "relation_code": "410783",
                "relation_desc": "河南省长垣市"
            },
            {
                "code": "085703",
                "parentcode": "0857",
                "parenttext": "农垦九三管理局",
                "text": "红五月农场",
                "level": 3,
                "order": 84,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160711",
                "parentcode": "1607",
                "parenttext": "新乡市",
                "text": "卫辉市",
                "level": 3,
                "order": 85,
                "relation_code": "410781",
                "relation_desc": "河南省卫辉市"
            },
            {
                "code": "085704",
                "parentcode": "0857",
                "parenttext": "农垦九三管理局",
                "text": "七星泡农场",
                "level": 3,
                "order": 85,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160712",
                "parentcode": "1607",
                "parenttext": "新乡市",
                "text": "辉县市",
                "level": 3,
                "order": 86,
                "relation_code": "410782",
                "relation_desc": "河南省辉县市"
            },
            {
                "code": "085705",
                "parentcode": "0857",
                "parenttext": "农垦九三管理局",
                "text": "建边农场",
                "level": 3,
                "order": 86,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "1608",
                "parentcode": "16",
                "parenttext": "河南省",
                "text": "焦作市",
                "level": 2,
                "order": 87,
                "relation_code": "410800",
                "relation_desc": "河南省焦作市"
            },
            {
                "code": "085706",
                "parentcode": "0857",
                "parenttext": "农垦九三管理局",
                "text": "鹤山农场",
                "level": 3,
                "order": 87,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160713",
                "parentcode": "1607",
                "parenttext": "新乡市",
                "text": "新乡高新技术产业开发区",
                "level": 3,
                "order": 87,
                "relation_code": "410701",
                "relation_desc": "河南省新乡市"
            },
            {
                "code": "160801",
                "parentcode": "1608",
                "parenttext": "焦作市",
                "text": "解放区",
                "level": 3,
                "order": 88,
                "relation_code": "410802",
                "relation_desc": "河南省焦作市解放区"
            },
            {
                "code": "085707",
                "parentcode": "0857",
                "parenttext": "农垦九三管理局",
                "text": "荣军农场",
                "level": 3,
                "order": 88,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160714",
                "parentcode": "1607",
                "parenttext": "新乡市",
                "text": "新乡经济技术开发区",
                "level": 3,
                "order": 88,
                "relation_code": "410701",
                "relation_desc": "河南省新乡市"
            },
            {
                "code": "160802",
                "parentcode": "1608",
                "parenttext": "焦作市",
                "text": "中站区",
                "level": 3,
                "order": 89,
                "relation_code": "410803",
                "relation_desc": "河南省焦作市中站区"
            },
            {
                "code": "085708",
                "parentcode": "0857",
                "parenttext": "农垦九三管理局",
                "text": "大西江农场",
                "level": 3,
                "order": 89,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160715",
                "parentcode": "1607",
                "parenttext": "新乡市",
                "text": "新乡市平原城乡一体化示范区",
                "level": 3,
                "order": 89,
                "relation_code": "410701",
                "relation_desc": "河南省新乡市"
            },
            {
                "code": "160803",
                "parentcode": "1608",
                "parenttext": "焦作市",
                "text": "马村区",
                "level": 3,
                "order": 90,
                "relation_code": "410804",
                "relation_desc": "河南省焦作市马村区"
            },
            {
                "code": "085709",
                "parentcode": "0857",
                "parenttext": "农垦九三管理局",
                "text": "嫩江农场",
                "level": 3,
                "order": 90,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160804",
                "parentcode": "1608",
                "parenttext": "焦作市",
                "text": "山阳区",
                "level": 3,
                "order": 91,
                "relation_code": "410811",
                "relation_desc": "河南省焦作市山阳区"
            },
            {
                "code": "085710",
                "parentcode": "0857",
                "parenttext": "农垦九三管理局",
                "text": "山河农场",
                "level": 3,
                "order": 91,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160805",
                "parentcode": "1608",
                "parenttext": "焦作市",
                "text": "修武县",
                "level": 3,
                "order": 92,
                "relation_code": "410821",
                "relation_desc": "河南省修武县"
            },
            {
                "code": "085711",
                "parentcode": "0857",
                "parenttext": "农垦九三管理局",
                "text": "嫩北农场",
                "level": 3,
                "order": 92,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160806",
                "parentcode": "1608",
                "parenttext": "焦作市",
                "text": "博爱县",
                "level": 3,
                "order": 93,
                "relation_code": "410822",
                "relation_desc": "河南省博爱县"
            },
            {
                "code": "0858",
                "parentcode": "08",
                "parenttext": "黑龙江省",
                "text": "农垦齐齐哈尔管理局",
                "level": 2,
                "order": 93,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160807",
                "parentcode": "1608",
                "parenttext": "焦作市",
                "text": "武陟县",
                "level": 3,
                "order": 94,
                "relation_code": "410823",
                "relation_desc": "河南省武陟县"
            },
            {
                "code": "085801",
                "parentcode": "0858",
                "parenttext": "农垦齐齐哈尔管理局",
                "text": "查哈阳农场",
                "level": 3,
                "order": 94,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160808",
                "parentcode": "1608",
                "parenttext": "焦作市",
                "text": "温县",
                "level": 3,
                "order": 95,
                "relation_code": "410825",
                "relation_desc": "河南省温县"
            },
            {
                "code": "085802",
                "parentcode": "0858",
                "parenttext": "农垦齐齐哈尔管理局",
                "text": "富裕牧场",
                "level": 3,
                "order": 95,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160809",
                "parentcode": "1608",
                "parenttext": "焦作市",
                "text": "孟州市",
                "level": 3,
                "order": 96,
                "relation_code": "410883",
                "relation_desc": "河南省孟州市"
            },
            {
                "code": "085803",
                "parentcode": "0858",
                "parenttext": "农垦齐齐哈尔管理局",
                "text": "绿色草原牧场",
                "level": 3,
                "order": 96,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160810",
                "parentcode": "1608",
                "parenttext": "焦作市",
                "text": "沁阳市",
                "level": 3,
                "order": 97,
                "relation_code": "410882",
                "relation_desc": "河南省沁阳市"
            },
            {
                "code": "160903",
                "parentcode": "1609",
                "parenttext": "济源市",
                "text": "济源市",
                "level": 3,
                "order": 97,
                "relation_code": "419001",
                "relation_desc": "河南省济源市"
            },
            {
                "code": "085804",
                "parentcode": "0858",
                "parenttext": "农垦齐齐哈尔管理局",
                "text": "长吉岗农场",
                "level": 3,
                "order": 97,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "1609",
                "parentcode": "16",
                "parenttext": "河南省",
                "text": "济源市",
                "level": 2,
                "order": 98,
                "relation_code": "419001",
                "relation_desc": "河南省济源市"
            },
            {
                "code": "085805",
                "parentcode": "0858",
                "parenttext": "农垦齐齐哈尔管理局",
                "text": "克山农场",
                "level": 3,
                "order": 98,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "160811",
                "parentcode": "1608",
                "parenttext": "焦作市",
                "text": "焦作城乡一体化示范区",
                "level": 3,
                "order": 98,
                "relation_code": "410801",
                "relation_desc": "河南省焦作市"
            },
            {
                "code": "1610",
                "parentcode": "16",
                "parenttext": "河南省",
                "text": "濮阳市",
                "level": 2,
                "order": 99,
                "relation_code": "410900",
                "relation_desc": "河南省濮阳市"
            },
            {
                "code": "085806",
                "parentcode": "0858",
                "parenttext": "农垦齐齐哈尔管理局",
                "text": "依安农场",
                "level": 3,
                "order": 99,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "161001",
                "parentcode": "1610",
                "parenttext": "濮阳市",
                "text": "华龙区",
                "level": 3,
                "order": 100,
                "relation_code": "410902",
                "relation_desc": "河南省濮阳市华龙区"
            },
            {
                "code": "085807",
                "parentcode": "0858",
                "parenttext": "农垦齐齐哈尔管理局",
                "text": "巨浪牧场",
                "level": 3,
                "order": 100,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "161002",
                "parentcode": "1610",
                "parenttext": "濮阳市",
                "text": "清丰县",
                "level": 3,
                "order": 101,
                "relation_code": "410922",
                "relation_desc": "河南省清丰县"
            },
            {
                "code": "085808",
                "parentcode": "0858",
                "parenttext": "农垦齐齐哈尔管理局",
                "text": "泰来农场",
                "level": 3,
                "order": 101,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "161003",
                "parentcode": "1610",
                "parenttext": "濮阳市",
                "text": "南乐县",
                "level": 3,
                "order": 102,
                "relation_code": "410923",
                "relation_desc": "河南省南乐县"
            },
            {
                "code": "0859",
                "parentcode": "08",
                "parenttext": "黑龙江省",
                "text": "农垦哈尔滨管理局",
                "level": 2,
                "order": 102,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "161004",
                "parentcode": "1610",
                "parenttext": "濮阳市",
                "text": "范县",
                "level": 3,
                "order": 103,
                "relation_code": "410926",
                "relation_desc": "河南省范县"
            },
            {
                "code": "085901",
                "parentcode": "0859",
                "parenttext": "农垦哈尔滨管理局",
                "text": "青年农场",
                "level": 3,
                "order": 103,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "161005",
                "parentcode": "1610",
                "parenttext": "濮阳市",
                "text": "台前县",
                "level": 3,
                "order": 104,
                "relation_code": "410927",
                "relation_desc": "河南省台前县"
            },
            {
                "code": "085902",
                "parentcode": "0859",
                "parenttext": "农垦哈尔滨管理局",
                "text": "阎家岗农场",
                "level": 3,
                "order": 104,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "161006",
                "parentcode": "1610",
                "parenttext": "濮阳市",
                "text": "濮阳县",
                "level": 3,
                "order": 105,
                "relation_code": "410928",
                "relation_desc": "河南省濮阳县"
            },
            {
                "code": "085903",
                "parentcode": "0859",
                "parenttext": "农垦哈尔滨管理局",
                "text": "庆阳农场",
                "level": 3,
                "order": 105,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "1611",
                "parentcode": "16",
                "parenttext": "河南省",
                "text": "许昌市",
                "level": 2,
                "order": 106,
                "relation_code": "411000",
                "relation_desc": "河南省许昌市"
            },
            {
                "code": "085904",
                "parentcode": "0859",
                "parenttext": "农垦哈尔滨管理局",
                "text": "红旗农场",
                "level": 3,
                "order": 106,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "161007",
                "parentcode": "1610",
                "parenttext": "濮阳市",
                "text": "河南濮阳工业园区",
                "level": 3,
                "order": 106,
                "relation_code": "410901",
                "relation_desc": "河南省濮阳市"
            },
            {
                "code": "161101",
                "parentcode": "1611",
                "parenttext": "许昌市",
                "text": "魏都区",
                "level": 3,
                "order": 107,
                "relation_code": "411002",
                "relation_desc": "河南省许昌市魏都区"
            },
            {
                "code": "085905",
                "parentcode": "0859",
                "parenttext": "农垦哈尔滨管理局",
                "text": "香坊农场",
                "level": 3,
                "order": 107,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "161008",
                "parentcode": "1610",
                "parenttext": "濮阳市",
                "text": "濮阳经济技术开发区",
                "level": 3,
                "order": 107,
                "relation_code": "410901",
                "relation_desc": "河南省濮阳市"
            },
            {
                "code": "161102",
                "parentcode": "1611",
                "parenttext": "许昌市",
                "text": "建安区",
                "level": 3,
                "order": 108,
                "relation_code": "411003",
                "relation_desc": "河南省许昌市建安区"
            },
            {
                "code": "085906",
                "parentcode": "0859",
                "parenttext": "农垦哈尔滨管理局",
                "text": "岔林河农场",
                "level": 3,
                "order": 108,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "161103",
                "parentcode": "1611",
                "parenttext": "许昌市",
                "text": "鄢陵县",
                "level": 3,
                "order": 109,
                "relation_code": "411024",
                "relation_desc": "河南省鄢陵县"
            },
            {
                "code": "085907",
                "parentcode": "0859",
                "parenttext": "农垦哈尔滨管理局",
                "text": "沙河农场",
                "level": 3,
                "order": 109,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "161104",
                "parentcode": "1611",
                "parenttext": "许昌市",
                "text": "襄城县",
                "level": 3,
                "order": 110,
                "relation_code": "411025",
                "relation_desc": "河南省襄城县"
            },
            {
                "code": "0861",
                "parentcode": "08",
                "parenttext": "黑龙江省",
                "text": "松花江林业管理局",
                "level": 2,
                "order": 110,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "161105",
                "parentcode": "1611",
                "parenttext": "许昌市",
                "text": "禹州市",
                "level": 3,
                "order": 111,
                "relation_code": "411081",
                "relation_desc": "河南省禹州市"
            },
            {
                "code": "086101",
                "parentcode": "0861",
                "parenttext": "松花江林业管理局",
                "text": "兴隆林业局",
                "level": 3,
                "order": 111,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "161106",
                "parentcode": "1611",
                "parenttext": "许昌市",
                "text": "长葛市",
                "level": 3,
                "order": 112,
                "relation_code": "411082",
                "relation_desc": "河南省长葛市"
            },
            {
                "code": "086102",
                "parentcode": "0861",
                "parenttext": "松花江林业管理局",
                "text": "山河屯林业局",
                "level": 3,
                "order": 112,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "1612",
                "parentcode": "16",
                "parenttext": "河南省",
                "text": "漯河市",
                "level": 2,
                "order": 113,
                "relation_code": "411100",
                "relation_desc": "河南省漯河市"
            },
            {
                "code": "086103",
                "parentcode": "0861",
                "parenttext": "松花江林业管理局",
                "text": "通北林业局",
                "level": 3,
                "order": 113,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "161107",
                "parentcode": "1611",
                "parenttext": "许昌市",
                "text": "许昌经济技术开发区",
                "level": 3,
                "order": 113,
                "relation_code": "411001",
                "relation_desc": "河南省许昌市"
            },
            {
                "code": "161201",
                "parentcode": "1612",
                "parenttext": "漯河市",
                "text": "源汇区",
                "level": 3,
                "order": 114,
                "relation_code": "411102",
                "relation_desc": "河南省漯河市源汇区"
            },
            {
                "code": "086104",
                "parentcode": "0861",
                "parenttext": "松花江林业管理局",
                "text": "清河林业局",
                "level": 3,
                "order": 114,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "161202",
                "parentcode": "1612",
                "parenttext": "漯河市",
                "text": "召陵区",
                "level": 3,
                "order": 115,
                "relation_code": "411104",
                "relation_desc": "河南省漯河市召陵区"
            },
            {
                "code": "086105",
                "parentcode": "0861",
                "parenttext": "松花江林业管理局",
                "text": "苇河林业局",
                "level": 3,
                "order": 115,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "161203",
                "parentcode": "1612",
                "parenttext": "漯河市",
                "text": "郾城区",
                "level": 3,
                "order": 116,
                "relation_code": "411103",
                "relation_desc": "河南省漯河市郾城区"
            },
            {
                "code": "086106",
                "parentcode": "0861",
                "parenttext": "松花江林业管理局",
                "text": "亚布力林业局",
                "level": 3,
                "order": 116,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "161204",
                "parentcode": "1612",
                "parenttext": "漯河市",
                "text": "舞阳县",
                "level": 3,
                "order": 117,
                "relation_code": "411121",
                "relation_desc": "河南省舞阳县"
            },
            {
                "code": "086107",
                "parentcode": "0861",
                "parenttext": "松花江林业管理局",
                "text": "方正林业局",
                "level": 3,
                "order": 117,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "161205",
                "parentcode": "1612",
                "parenttext": "漯河市",
                "text": "临颍县",
                "level": 3,
                "order": 118,
                "relation_code": "411122",
                "relation_desc": "河南省临颍县"
            },
            {
                "code": "086108",
                "parentcode": "0861",
                "parenttext": "松花江林业管理局",
                "text": "沾河林业局",
                "level": 3,
                "order": 118,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "1613",
                "parentcode": "16",
                "parenttext": "河南省",
                "text": "三门峡市",
                "level": 2,
                "order": 119,
                "relation_code": "411200",
                "relation_desc": "河南省三门峡市"
            },
            {
                "code": "086109",
                "parentcode": "0861",
                "parenttext": "松花江林业管理局",
                "text": "绥棱林业局",
                "level": 3,
                "order": 119,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "161206",
                "parentcode": "1612",
                "parenttext": "漯河市",
                "text": "漯河经济技术开发区",
                "level": 3,
                "order": 119,
                "relation_code": "411101",
                "relation_desc": "河南省漯河市"
            },
            {
                "code": "161301",
                "parentcode": "1613",
                "parenttext": "三门峡市",
                "text": "湖滨区",
                "level": 3,
                "order": 120,
                "relation_code": "411202",
                "relation_desc": "河南省三门峡市湖滨区"
            },
            {
                "code": "0862",
                "parentcode": "08",
                "parenttext": "黑龙江省",
                "text": "牡丹江林业管理局",
                "level": 2,
                "order": 120,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "161302",
                "parentcode": "1613",
                "parenttext": "三门峡市",
                "text": "渑池县",
                "level": 3,
                "order": 121,
                "relation_code": "411221",
                "relation_desc": "河南省渑池县"
            },
            {
                "code": "086201",
                "parentcode": "0862",
                "parenttext": "牡丹江林业管理局",
                "text": "大海林林业局",
                "level": 3,
                "order": 121,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "161303",
                "parentcode": "1613",
                "parenttext": "三门峡市",
                "text": "陕州区",
                "level": 3,
                "order": 122,
                "relation_code": "411203",
                "relation_desc": "河南省三门峡市陕州区"
            },
            {
                "code": "086202",
                "parentcode": "0862",
                "parenttext": "牡丹江林业管理局",
                "text": "海林林业局",
                "level": 3,
                "order": 122,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "161304",
                "parentcode": "1613",
                "parenttext": "三门峡市",
                "text": "卢氏县",
                "level": 3,
                "order": 123,
                "relation_code": "411224",
                "relation_desc": "河南省卢氏县"
            },
            {
                "code": "086203",
                "parentcode": "0862",
                "parenttext": "牡丹江林业管理局",
                "text": "柴河林业局",
                "level": 3,
                "order": 123,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "161305",
                "parentcode": "1613",
                "parenttext": "三门峡市",
                "text": "义马市",
                "level": 3,
                "order": 124,
                "relation_code": "411281",
                "relation_desc": "河南省义马市"
            },
            {
                "code": "086204",
                "parentcode": "0862",
                "parenttext": "牡丹江林业管理局",
                "text": "东方红林业局",
                "level": 3,
                "order": 124,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "161306",
                "parentcode": "1613",
                "parenttext": "三门峡市",
                "text": "灵宝市",
                "level": 3,
                "order": 125,
                "relation_code": "411282",
                "relation_desc": "河南省灵宝市"
            },
            {
                "code": "086205",
                "parentcode": "0862",
                "parenttext": "牡丹江林业管理局",
                "text": "东京城林业局",
                "level": 3,
                "order": 125,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "1614",
                "parentcode": "16",
                "parenttext": "河南省",
                "text": "南阳市",
                "level": 2,
                "order": 126,
                "relation_code": "411300",
                "relation_desc": "河南省南阳市"
            },
            {
                "code": "086206",
                "parentcode": "0862",
                "parenttext": "牡丹江林业管理局",
                "text": "林口林业局",
                "level": 3,
                "order": 126,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "161307",
                "parentcode": "1613",
                "parenttext": "三门峡市",
                "text": "河南三门峡经济开发区",
                "level": 3,
                "order": 126,
                "relation_code": "411301",
                "relation_desc": "河南省三门峡市"
            },
            {
                "code": "161401",
                "parentcode": "1614",
                "parenttext": "南阳市",
                "text": "宛城区",
                "level": 3,
                "order": 127,
                "relation_code": "411302",
                "relation_desc": "河南省南阳市宛城区"
            },
            {
                "code": "086207",
                "parentcode": "0862",
                "parenttext": "牡丹江林业管理局",
                "text": "绥阳林业局",
                "level": 3,
                "order": 127,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "161402",
                "parentcode": "1614",
                "parenttext": "南阳市",
                "text": "卧龙区",
                "level": 3,
                "order": 128,
                "relation_code": "411303",
                "relation_desc": "河南省南阳市卧龙区"
            },
            {
                "code": "086208",
                "parentcode": "0862",
                "parenttext": "牡丹江林业管理局",
                "text": "八面通林业局",
                "level": 3,
                "order": 128,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "161403",
                "parentcode": "1614",
                "parenttext": "南阳市",
                "text": "南召县",
                "level": 3,
                "order": 129,
                "relation_code": "411321",
                "relation_desc": "河南省南召县"
            },
            {
                "code": "086209",
                "parentcode": "0862",
                "parenttext": "牡丹江林业管理局",
                "text": "穆棱林业局",
                "level": 3,
                "order": 129,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "161404",
                "parentcode": "1614",
                "parenttext": "南阳市",
                "text": "方城县",
                "level": 3,
                "order": 130,
                "relation_code": "411322",
                "relation_desc": "河南省方城县"
            },
            {
                "code": "086210",
                "parentcode": "0862",
                "parenttext": "牡丹江林业管理局",
                "text": "迎春林业局",
                "level": 3,
                "order": 130,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "161405",
                "parentcode": "1614",
                "parenttext": "南阳市",
                "text": "西峡县",
                "level": 3,
                "order": 131,
                "relation_code": "411323",
                "relation_desc": "河南省西峡县"
            },
            {
                "code": "0863",
                "parentcode": "08",
                "parenttext": "黑龙江省",
                "text": "合江林业管理局",
                "level": 2,
                "order": 131,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "161406",
                "parentcode": "1614",
                "parenttext": "南阳市",
                "text": "镇平县",
                "level": 3,
                "order": 132,
                "relation_code": "411324",
                "relation_desc": "河南省镇平县"
            },
            {
                "code": "086301",
                "parentcode": "0863",
                "parenttext": "合江林业管理局",
                "text": "双鸭山林业局",
                "level": 3,
                "order": 132,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "161407",
                "parentcode": "1614",
                "parenttext": "南阳市",
                "text": "内乡县",
                "level": 3,
                "order": 133,
                "relation_code": "411325",
                "relation_desc": "河南省内乡县"
            },
            {
                "code": "086302",
                "parentcode": "0863",
                "parenttext": "合江林业管理局",
                "text": "鹤立林业局",
                "level": 3,
                "order": 133,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "161408",
                "parentcode": "1614",
                "parenttext": "南阳市",
                "text": "淅川县",
                "level": 3,
                "order": 134,
                "relation_code": "411326",
                "relation_desc": "河南省淅川县"
            },
            {
                "code": "086303",
                "parentcode": "0863",
                "parenttext": "合江林业管理局",
                "text": "桦南林业局",
                "level": 3,
                "order": 134,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "161409",
                "parentcode": "1614",
                "parenttext": "南阳市",
                "text": "社旗县",
                "level": 3,
                "order": 135,
                "relation_code": "411327",
                "relation_desc": "河南省社旗县"
            },
            {
                "code": "086304",
                "parentcode": "0863",
                "parenttext": "合江林业管理局",
                "text": "鹤北林业局",
                "level": 3,
                "order": 135,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "161410",
                "parentcode": "1614",
                "parenttext": "南阳市",
                "text": "唐河县",
                "level": 3,
                "order": 136,
                "relation_code": "411328",
                "relation_desc": "河南省唐河县"
            },
            {
                "code": "0864",
                "parentcode": "08",
                "parenttext": "黑龙江省",
                "text": "伊春林管局",
                "level": 2,
                "order": 136,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "161411",
                "parentcode": "1614",
                "parenttext": "南阳市",
                "text": "新野县",
                "level": 3,
                "order": 137,
                "relation_code": "411329",
                "relation_desc": "河南省新野县"
            },
            {
                "code": "086401",
                "parentcode": "0864",
                "parenttext": "伊春林管局",
                "text": "上甘岭林业局",
                "level": 3,
                "order": 137,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "161412",
                "parentcode": "1614",
                "parenttext": "南阳市",
                "text": "桐柏县",
                "level": 3,
                "order": 138,
                "relation_code": "411330",
                "relation_desc": "河南省桐柏县"
            },
            {
                "code": "086402",
                "parentcode": "0864",
                "parenttext": "伊春林管局",
                "text": "翠峦林业局",
                "level": 3,
                "order": 138,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "161413",
                "parentcode": "1614",
                "parenttext": "南阳市",
                "text": "邓州市",
                "level": 3,
                "order": 139,
                "relation_code": "411381",
                "relation_desc": "河南省邓州市"
            },
            {
                "code": "086403",
                "parentcode": "0864",
                "parenttext": "伊春林管局",
                "text": "金山屯林业局",
                "level": 3,
                "order": 139,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "1615",
                "parentcode": "16",
                "parenttext": "河南省",
                "text": "商丘市",
                "level": 2,
                "order": 140,
                "relation_code": "411400",
                "relation_desc": "河南省商丘市"
            },
            {
                "code": "086404",
                "parentcode": "0864",
                "parenttext": "伊春林管局",
                "text": "五营林业局",
                "level": 3,
                "order": 140,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "161414",
                "parentcode": "1614",
                "parenttext": "南阳市",
                "text": "南阳高新技术产业开发区",
                "level": 3,
                "order": 140,
                "relation_code": "411301",
                "relation_desc": "河南省南阳市"
            },
            {
                "code": "161501",
                "parentcode": "1615",
                "parenttext": "商丘市",
                "text": "梁园区",
                "level": 3,
                "order": 141,
                "relation_code": "411402",
                "relation_desc": "河南省商丘市梁园区"
            },
            {
                "code": "086405",
                "parentcode": "0864",
                "parenttext": "伊春林管局",
                "text": "友好林业局",
                "level": 3,
                "order": 141,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "161415",
                "parentcode": "1614",
                "parenttext": "南阳市",
                "text": "南阳市城乡一体化示范区",
                "level": 3,
                "order": 141,
                "relation_code": "411301",
                "relation_desc": "河南省南阳市"
            },
            {
                "code": "161502",
                "parentcode": "1615",
                "parenttext": "商丘市",
                "text": "睢阳区",
                "level": 3,
                "order": 142,
                "relation_code": "411403",
                "relation_desc": "河南省商丘市睢阳区"
            },
            {
                "code": "086406",
                "parentcode": "0864",
                "parenttext": "伊春林管局",
                "text": "南岔林业局",
                "level": 3,
                "order": 142,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "161503",
                "parentcode": "1615",
                "parenttext": "商丘市",
                "text": "民权县",
                "level": 3,
                "order": 143,
                "relation_code": "411421",
                "relation_desc": "河南省民权县"
            },
            {
                "code": "086407",
                "parentcode": "0864",
                "parenttext": "伊春林管局",
                "text": "汤旺河林业局",
                "level": 3,
                "order": 143,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "161504",
                "parentcode": "1615",
                "parenttext": "商丘市",
                "text": "睢县",
                "level": 3,
                "order": 144,
                "relation_code": "411422",
                "relation_desc": "河南省睢县"
            },
            {
                "code": "086408",
                "parentcode": "0864",
                "parenttext": "伊春林管局",
                "text": "红星林业局",
                "level": 3,
                "order": 144,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "161505",
                "parentcode": "1615",
                "parenttext": "商丘市",
                "text": "宁陵县",
                "level": 3,
                "order": 145,
                "relation_code": "411423",
                "relation_desc": "河南省宁陵县"
            },
            {
                "code": "086409",
                "parentcode": "0864",
                "parenttext": "伊春林管局",
                "text": "双丰林业局",
                "level": 3,
                "order": 145,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "161506",
                "parentcode": "1615",
                "parenttext": "商丘市",
                "text": "柘城县",
                "level": 3,
                "order": 146,
                "relation_code": "411424",
                "relation_desc": "河南省柘城县"
            },
            {
                "code": "086410",
                "parentcode": "0864",
                "parenttext": "伊春林管局",
                "text": "桃山林业局",
                "level": 3,
                "order": 146,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "161507",
                "parentcode": "1615",
                "parenttext": "商丘市",
                "text": "虞城县",
                "level": 3,
                "order": 147,
                "relation_code": "411425",
                "relation_desc": "河南省虞城县"
            },
            {
                "code": "086411",
                "parentcode": "0864",
                "parenttext": "伊春林管局",
                "text": "朗乡林业局",
                "level": 3,
                "order": 147,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "161508",
                "parentcode": "1615",
                "parenttext": "商丘市",
                "text": "夏邑县",
                "level": 3,
                "order": 148,
                "relation_code": "411426",
                "relation_desc": "河南省夏邑县"
            },
            {
                "code": "086412",
                "parentcode": "0864",
                "parenttext": "伊春林管局",
                "text": "铁力林业局",
                "level": 3,
                "order": 148,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "161509",
                "parentcode": "1615",
                "parenttext": "商丘市",
                "text": "永城市",
                "level": 3,
                "order": 149,
                "relation_code": "411481",
                "relation_desc": "河南省永城市"
            },
            {
                "code": "086413",
                "parentcode": "0864",
                "parenttext": "伊春林管局",
                "text": "美溪林业局",
                "level": 3,
                "order": 149,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "1616",
                "parentcode": "16",
                "parenttext": "河南省",
                "text": "信阳市",
                "level": 2,
                "order": 150,
                "relation_code": "411500",
                "relation_desc": "河南省信阳市"
            },
            {
                "code": "086414",
                "parentcode": "0864",
                "parenttext": "伊春林管局",
                "text": "新青林业局",
                "level": 3,
                "order": 150,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "161510",
                "parentcode": "1615",
                "parenttext": "商丘市",
                "text": "豫东综合物流产业聚集区",
                "level": 3,
                "order": 150,
                "relation_code": "411401",
                "relation_desc": "河南省商丘市"
            },
            {
                "code": "161601",
                "parentcode": "1616",
                "parenttext": "信阳市",
                "text": "浉河区",
                "level": 3,
                "order": 151,
                "relation_code": "411502",
                "relation_desc": "河南省信阳市浉河区"
            },
            {
                "code": "086415",
                "parentcode": "0864",
                "parenttext": "伊春林管局",
                "text": "乌马河林业局",
                "level": 3,
                "order": 151,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "161511",
                "parentcode": "1615",
                "parenttext": "商丘市",
                "text": "河南商丘经济开发区",
                "level": 3,
                "order": 151,
                "relation_code": "411401",
                "relation_desc": "河南省商丘市"
            },
            {
                "code": "161602",
                "parentcode": "1616",
                "parenttext": "信阳市",
                "text": "平桥区",
                "level": 3,
                "order": 152,
                "relation_code": "411503",
                "relation_desc": "河南省信阳市平桥区"
            },
            {
                "code": "086416",
                "parentcode": "0864",
                "parenttext": "伊春林管局",
                "text": "带岭林业实验局",
                "level": 3,
                "order": 152,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "161603",
                "parentcode": "1616",
                "parenttext": "信阳市",
                "text": "罗山县",
                "level": 3,
                "order": 153,
                "relation_code": "411521",
                "relation_desc": "河南省罗山县"
            },
            {
                "code": "086417",
                "parentcode": "0864",
                "parenttext": "伊春林管局",
                "text": "乌伊岭林业局",
                "level": 3,
                "order": 153,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "161604",
                "parentcode": "1616",
                "parenttext": "信阳市",
                "text": "光山县",
                "level": 3,
                "order": 154,
                "relation_code": "411522",
                "relation_desc": "河南省光山县"
            },
            {
                "code": "0865",
                "parentcode": "08",
                "parenttext": "黑龙江省",
                "text": "大兴安岭林管局",
                "level": 2,
                "order": 154,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "161605",
                "parentcode": "1616",
                "parenttext": "信阳市",
                "text": "新县",
                "level": 3,
                "order": 155,
                "relation_code": "411523",
                "relation_desc": "河南省新县"
            },
            {
                "code": "086501",
                "parentcode": "0865",
                "parenttext": "大兴安岭林管局",
                "text": "松岭林业局",
                "level": 3,
                "order": 155,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "161606",
                "parentcode": "1616",
                "parenttext": "信阳市",
                "text": "商城县",
                "level": 3,
                "order": 156,
                "relation_code": "411524",
                "relation_desc": "河南省商城县"
            },
            {
                "code": "086502",
                "parentcode": "0865",
                "parenttext": "大兴安岭林管局",
                "text": "新林林业局",
                "level": 3,
                "order": 156,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "161607",
                "parentcode": "1616",
                "parenttext": "信阳市",
                "text": "固始县",
                "level": 3,
                "order": 157,
                "relation_code": "411525",
                "relation_desc": "河南省固始县"
            },
            {
                "code": "086503",
                "parentcode": "0865",
                "parenttext": "大兴安岭林管局",
                "text": "呼中林业局",
                "level": 3,
                "order": 157,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "161608",
                "parentcode": "1616",
                "parenttext": "信阳市",
                "text": "潢川县",
                "level": 3,
                "order": 158,
                "relation_code": "411526",
                "relation_desc": "河南省潢川县"
            },
            {
                "code": "086504",
                "parentcode": "0865",
                "parenttext": "大兴安岭林管局",
                "text": "塔河林业局",
                "level": 3,
                "order": 158,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "161609",
                "parentcode": "1616",
                "parenttext": "信阳市",
                "text": "淮滨县",
                "level": 3,
                "order": 159,
                "relation_code": "411527",
                "relation_desc": "河南省淮滨县"
            },
            {
                "code": "086505",
                "parentcode": "0865",
                "parenttext": "大兴安岭林管局",
                "text": "十八站林业局",
                "level": 3,
                "order": 159,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "161610",
                "parentcode": "1616",
                "parenttext": "信阳市",
                "text": "息县",
                "level": 3,
                "order": 160,
                "relation_code": "411528",
                "relation_desc": "河南省息县"
            },
            {
                "code": "086506",
                "parentcode": "0865",
                "parenttext": "大兴安岭林管局",
                "text": "阿木尔林业局",
                "level": 3,
                "order": 160,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "1617",
                "parentcode": "16",
                "parenttext": "河南省",
                "text": "周口市",
                "level": 2,
                "order": 161,
                "relation_code": "411600",
                "relation_desc": "河南省周口市"
            },
            {
                "code": "086507",
                "parentcode": "0865",
                "parenttext": "大兴安岭林管局",
                "text": "图强林业局",
                "level": 3,
                "order": 161,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "161611",
                "parentcode": "1616",
                "parenttext": "信阳市",
                "text": "信阳高新技术产业开发区",
                "level": 3,
                "order": 161,
                "relation_code": "411501",
                "relation_desc": "河南省信阳市"
            },
            {
                "code": "161701",
                "parentcode": "1617",
                "parenttext": "周口市",
                "text": "川汇区",
                "level": 3,
                "order": 162,
                "relation_code": "411602",
                "relation_desc": "河南省周口市川汇区"
            },
            {
                "code": "086508",
                "parentcode": "0865",
                "parenttext": "大兴安岭林管局",
                "text": "韩家园林业局",
                "level": 3,
                "order": 162,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "161702",
                "parentcode": "1617",
                "parenttext": "周口市",
                "text": "扶沟县",
                "level": 3,
                "order": 163,
                "relation_code": "411621",
                "relation_desc": "河南省扶沟县"
            },
            {
                "code": "161703",
                "parentcode": "1617",
                "parenttext": "周口市",
                "text": "西华县",
                "level": 3,
                "order": 164,
                "relation_code": "411622",
                "relation_desc": "河南省西华县"
            },
            {
                "code": "161704",
                "parentcode": "1617",
                "parenttext": "周口市",
                "text": "商水县",
                "level": 3,
                "order": 165,
                "relation_code": "411623",
                "relation_desc": "河南省商水县"
            },
            {
                "code": "161705",
                "parentcode": "1617",
                "parenttext": "周口市",
                "text": "沈丘县",
                "level": 3,
                "order": 166,
                "relation_code": "411624",
                "relation_desc": "河南省沈丘县"
            },
            {
                "code": "161706",
                "parentcode": "1617",
                "parenttext": "周口市",
                "text": "郸城县",
                "level": 3,
                "order": 167,
                "relation_code": "411625",
                "relation_desc": "河南省郸城县"
            },
            {
                "code": "161707",
                "parentcode": "1617",
                "parenttext": "周口市",
                "text": "淮阳区",
                "level": 3,
                "order": 168,
                "relation_code": "411603",
                "relation_desc": "河南省周口市淮阳区"
            },
            {
                "code": "161708",
                "parentcode": "1617",
                "parenttext": "周口市",
                "text": "太康县",
                "level": 3,
                "order": 169,
                "relation_code": "411627",
                "relation_desc": "河南省太康县"
            },
            {
                "code": "161709",
                "parentcode": "1617",
                "parenttext": "周口市",
                "text": "鹿邑县",
                "level": 3,
                "order": 170,
                "relation_code": "411628",
                "relation_desc": "河南省鹿邑县"
            },
            {
                "code": "161710",
                "parentcode": "1617",
                "parenttext": "周口市",
                "text": "项城市",
                "level": 3,
                "order": 171,
                "relation_code": "411681",
                "relation_desc": "河南省项城市"
            },
            {
                "code": "1618",
                "parentcode": "16",
                "parenttext": "河南省",
                "text": "驻马店市",
                "level": 2,
                "order": 172,
                "relation_code": "411700",
                "relation_desc": "河南省驻马店市"
            },
            {
                "code": "161711",
                "parentcode": "1617",
                "parenttext": "周口市",
                "text": "河南周口经济开发区",
                "level": 3,
                "order": 172,
                "relation_code": "411601",
                "relation_desc": "河南省周口市"
            },
            {
                "code": "161801",
                "parentcode": "1618",
                "parenttext": "驻马店市",
                "text": "驿城区",
                "level": 3,
                "order": 173,
                "relation_code": "411702",
                "relation_desc": "河南省驻马店市驿城区"
            },
            {
                "code": "161802",
                "parentcode": "1618",
                "parenttext": "驻马店市",
                "text": "西平县",
                "level": 3,
                "order": 174,
                "relation_code": "411721",
                "relation_desc": "河南省西平县"
            },
            {
                "code": "161803",
                "parentcode": "1618",
                "parenttext": "驻马店市",
                "text": "上蔡县",
                "level": 3,
                "order": 175,
                "relation_code": "411722",
                "relation_desc": "河南省上蔡县"
            },
            {
                "code": "161804",
                "parentcode": "1618",
                "parenttext": "驻马店市",
                "text": "平舆县",
                "level": 3,
                "order": 176,
                "relation_code": "411723",
                "relation_desc": "河南省平舆县"
            },
            {
                "code": "161805",
                "parentcode": "1618",
                "parenttext": "驻马店市",
                "text": "正阳县",
                "level": 3,
                "order": 177,
                "relation_code": "411724",
                "relation_desc": "河南省正阳县"
            },
            {
                "code": "161806",
                "parentcode": "1618",
                "parenttext": "驻马店市",
                "text": "确山县",
                "level": 3,
                "order": 178,
                "relation_code": "411725",
                "relation_desc": "河南省确山县"
            },
            {
                "code": "161807",
                "parentcode": "1618",
                "parenttext": "驻马店市",
                "text": "泌阳县",
                "level": 3,
                "order": 179,
                "relation_code": "411726",
                "relation_desc": "河南省泌阳县"
            },
            {
                "code": "161808",
                "parentcode": "1618",
                "parenttext": "驻马店市",
                "text": "汝南县",
                "level": 3,
                "order": 180,
                "relation_code": "411727",
                "relation_desc": "河南省汝南县"
            },
            {
                "code": "161809",
                "parentcode": "1618",
                "parenttext": "驻马店市",
                "text": "遂平县",
                "level": 3,
                "order": 181,
                "relation_code": "411728",
                "relation_desc": "河南省遂平县"
            },
            {
                "code": "161810",
                "parentcode": "1618",
                "parenttext": "驻马店市",
                "text": "新蔡县",
                "level": 3,
                "order": 182,
                "relation_code": "411729",
                "relation_desc": "河南省新蔡县"
            },
            {
                "code": "01",
                "parentcode": "0",
                "parenttext": "0",
                "text": "北京市",
                "level": 1,
                "order": 183,
                "relation_code": "110000",
                "relation_desc": "北京市"
            },
            {
                "code": "161811",
                "parentcode": "1618",
                "parenttext": "驻马店市",
                "text": "河南驻马店经济开发区",
                "level": 3,
                "order": 183,
                "relation_code": "411701",
                "relation_desc": "河南省驻马店市"
            },
            {
                "code": "0101",
                "parentcode": "01",
                "parenttext": "北京市",
                "text": "北京市",
                "level": 2,
                "order": 184,
                "relation_code": "110100",
                "relation_desc": "北京市"
            },
            {
                "code": "010101",
                "parentcode": "0101",
                "parenttext": "北京市",
                "text": "东城区",
                "level": 3,
                "order": 185,
                "relation_code": "110101",
                "relation_desc": "北京市东城区"
            },
            {
                "code": "010102",
                "parentcode": "0101",
                "parenttext": "北京市",
                "text": "西城区",
                "level": 3,
                "order": 186,
                "relation_code": "110102",
                "relation_desc": "北京市西城区"
            },
            {
                "code": "010103",
                "parentcode": "0101",
                "parenttext": "北京市",
                "text": "崇文区",
                "level": 3,
                "order": 187,
                "relation_code": "110101",
                "relation_desc": "北京市崇文区"
            },
            {
                "code": "010104",
                "parentcode": "0101",
                "parenttext": "北京市",
                "text": "宣武区",
                "level": 3,
                "order": 188,
                "relation_code": "110101",
                "relation_desc": "北京市宣武区"
            },
            {
                "code": "010105",
                "parentcode": "0101",
                "parenttext": "北京市",
                "text": "朝阳区",
                "level": 3,
                "order": 189,
                "relation_code": "110105",
                "relation_desc": "北京市朝阳区"
            },
            {
                "code": "010106",
                "parentcode": "0101",
                "parenttext": "北京市",
                "text": "丰台区",
                "level": 3,
                "order": 190,
                "relation_code": "110106",
                "relation_desc": "北京市丰台区"
            },
            {
                "code": "010107",
                "parentcode": "0101",
                "parenttext": "北京市",
                "text": "石景山区",
                "level": 3,
                "order": 191,
                "relation_code": "110107",
                "relation_desc": "北京市石景山区"
            },
            {
                "code": "010108",
                "parentcode": "0101",
                "parenttext": "北京市",
                "text": "海淀区",
                "level": 3,
                "order": 192,
                "relation_code": "110108",
                "relation_desc": "北京市海淀区"
            },
            {
                "code": "010109",
                "parentcode": "0101",
                "parenttext": "北京市",
                "text": "门头沟区",
                "level": 3,
                "order": 193,
                "relation_code": "110109",
                "relation_desc": "北京市门头沟区"
            },
            {
                "code": "010110",
                "parentcode": "0101",
                "parenttext": "北京市",
                "text": "房山区",
                "level": 3,
                "order": 194,
                "relation_code": "110111",
                "relation_desc": "北京市房山区"
            },
            {
                "code": "010111",
                "parentcode": "0101",
                "parenttext": "北京市",
                "text": "通州区",
                "level": 3,
                "order": 195,
                "relation_code": "110112",
                "relation_desc": "北京市通州区"
            },
            {
                "code": "010112",
                "parentcode": "0101",
                "parenttext": "北京市",
                "text": "顺义区",
                "level": 3,
                "order": 196,
                "relation_code": "110113",
                "relation_desc": "北京市顺义区"
            },
            {
                "code": "010113",
                "parentcode": "0101",
                "parenttext": "北京市",
                "text": "昌平区",
                "level": 3,
                "order": 197,
                "relation_code": "110114",
                "relation_desc": "北京市昌平区"
            },
            {
                "code": "010114",
                "parentcode": "0101",
                "parenttext": "北京市",
                "text": "大兴区",
                "level": 3,
                "order": 198,
                "relation_code": "110115",
                "relation_desc": "北京市大兴区"
            },
            {
                "code": "010115",
                "parentcode": "0101",
                "parenttext": "北京市",
                "text": "怀柔区",
                "level": 3,
                "order": 199,
                "relation_code": "110116",
                "relation_desc": "北京市怀柔区"
            },
            {
                "code": "010116",
                "parentcode": "0101",
                "parenttext": "北京市",
                "text": "平谷区",
                "level": 3,
                "order": 200,
                "relation_code": "110117",
                "relation_desc": "北京市平谷区"
            },
            {
                "code": "010117",
                "parentcode": "0101",
                "parenttext": "北京市",
                "text": "密云区",
                "level": 3,
                "order": 201,
                "relation_code": "110118",
                "relation_desc": "北京市密云区"
            },
            {
                "code": "010118",
                "parentcode": "0101",
                "parenttext": "北京市",
                "text": "延庆区",
                "level": 3,
                "order": 202,
                "relation_code": "110119",
                "relation_desc": "北京市延庆区"
            },
            {
                "code": "02",
                "parentcode": "0",
                "parenttext": "0",
                "text": "天津市",
                "level": 1,
                "order": 203,
                "relation_code": "",
                "relation_desc": "天津市"
            },
            {
                "code": "0201",
                "parentcode": "02",
                "parenttext": "天津市",
                "text": "天津市",
                "level": 2,
                "order": 204,
                "relation_code": "",
                "relation_desc": "天津市"
            },
            {
                "code": "020101",
                "parentcode": "0201",
                "parenttext": "天津市",
                "text": "和平区",
                "level": 3,
                "order": 205,
                "relation_code": "120101",
                "relation_desc": "天津市和平区"
            },
            {
                "code": "020102",
                "parentcode": "0201",
                "parenttext": "天津市",
                "text": "河东区",
                "level": 3,
                "order": 206,
                "relation_code": "120102",
                "relation_desc": "天津市河东区"
            },
            {
                "code": "020103",
                "parentcode": "0201",
                "parenttext": "天津市",
                "text": "河西区",
                "level": 3,
                "order": 207,
                "relation_code": "120103",
                "relation_desc": "天津市河西区"
            },
            {
                "code": "020104",
                "parentcode": "0201",
                "parenttext": "天津市",
                "text": "南开区",
                "level": 3,
                "order": 208,
                "relation_code": "120104",
                "relation_desc": "天津市南开区"
            },
            {
                "code": "020105",
                "parentcode": "0201",
                "parenttext": "天津市",
                "text": "河北区",
                "level": 3,
                "order": 209,
                "relation_code": "120105",
                "relation_desc": "天津市河北区"
            },
            {
                "code": "020106",
                "parentcode": "0201",
                "parenttext": "天津市",
                "text": "红桥区",
                "level": 3,
                "order": 210,
                "relation_code": "120106",
                "relation_desc": "天津市红桥区"
            },
            {
                "code": "020107",
                "parentcode": "0201",
                "parenttext": "天津市",
                "text": "塘沽区",
                "level": 3,
                "order": 211,
                "relation_code": "120101",
                "relation_desc": "天津市塘沽区"
            },
            {
                "code": "020108",
                "parentcode": "0201",
                "parenttext": "天津市",
                "text": "汉沽区",
                "level": 3,
                "order": 212,
                "relation_code": "120101",
                "relation_desc": "天津市汉沽区"
            },
            {
                "code": "020109",
                "parentcode": "0201",
                "parenttext": "天津市",
                "text": "大港区",
                "level": 3,
                "order": 213,
                "relation_code": "120101",
                "relation_desc": "天津市大港区"
            },
            {
                "code": "020110",
                "parentcode": "0201",
                "parenttext": "天津市",
                "text": "东丽区",
                "level": 3,
                "order": 214,
                "relation_code": "120110",
                "relation_desc": "天津市东丽区"
            },
            {
                "code": "020111",
                "parentcode": "0201",
                "parenttext": "天津市",
                "text": "西青区",
                "level": 3,
                "order": 215,
                "relation_code": "120111",
                "relation_desc": "天津市西青区"
            },
            {
                "code": "020112",
                "parentcode": "0201",
                "parenttext": "天津市",
                "text": "津南区",
                "level": 3,
                "order": 216,
                "relation_code": "120112",
                "relation_desc": "天津市津南区"
            },
            {
                "code": "020113",
                "parentcode": "0201",
                "parenttext": "天津市",
                "text": "北辰区",
                "level": 3,
                "order": 217,
                "relation_code": "120113",
                "relation_desc": "天津市北辰区"
            },
            {
                "code": "020114",
                "parentcode": "0201",
                "parenttext": "天津市",
                "text": "武清区",
                "level": 3,
                "order": 218,
                "relation_code": "120114",
                "relation_desc": "天津市武清区"
            },
            {
                "code": "020115",
                "parentcode": "0201",
                "parenttext": "天津市",
                "text": "宝坻区",
                "level": 3,
                "order": 219,
                "relation_code": "120115",
                "relation_desc": "天津市宝坻区"
            },
            {
                "code": "020116",
                "parentcode": "0201",
                "parenttext": "天津市",
                "text": "宁河区",
                "level": 3,
                "order": 220,
                "relation_code": "120117",
                "relation_desc": "天津市宁河区"
            },
            {
                "code": "020117",
                "parentcode": "0201",
                "parenttext": "天津市",
                "text": "静海区",
                "level": 3,
                "order": 221,
                "relation_code": "120118",
                "relation_desc": "天津市静海区"
            },
            {
                "code": "020118",
                "parentcode": "0201",
                "parenttext": "天津市",
                "text": "蓟州区",
                "level": 3,
                "order": 222,
                "relation_code": "120119",
                "relation_desc": "天津市蓟州区"
            },
            {
                "code": "03",
                "parentcode": "0",
                "parenttext": "0",
                "text": "河北省",
                "level": 1,
                "order": 223,
                "relation_code": "",
                "relation_desc": "河北省"
            },
            {
                "code": "0301",
                "parentcode": "03",
                "parenttext": "河北省",
                "text": "石家庄市",
                "level": 2,
                "order": 224,
                "relation_code": "130100",
                "relation_desc": "河北省石家庄市"
            },
            {
                "code": "030101",
                "parentcode": "0301",
                "parenttext": "石家庄市",
                "text": "长安区",
                "level": 3,
                "order": 225,
                "relation_code": "130102",
                "relation_desc": "河北省石家庄市长安区"
            },
            {
                "code": "030102",
                "parentcode": "0301",
                "parenttext": "石家庄市",
                "text": "桥东区",
                "level": 3,
                "order": 226,
                "relation_code": "130101",
                "relation_desc": "河北省石家庄市桥东区"
            },
            {
                "code": "030103",
                "parentcode": "0301",
                "parenttext": "石家庄市",
                "text": "桥西区",
                "level": 3,
                "order": 227,
                "relation_code": "130104",
                "relation_desc": "河北省石家庄市桥西区"
            },
            {
                "code": "030104",
                "parentcode": "0301",
                "parenttext": "石家庄市",
                "text": "新华区",
                "level": 3,
                "order": 228,
                "relation_code": "130105",
                "relation_desc": "河北省石家庄市新华区"
            },
            {
                "code": "030105",
                "parentcode": "0301",
                "parenttext": "石家庄市",
                "text": "井陉矿区",
                "level": 3,
                "order": 229,
                "relation_code": "130107",
                "relation_desc": "河北省石家庄市井陉矿区"
            },
            {
                "code": "030106",
                "parentcode": "0301",
                "parenttext": "石家庄市",
                "text": "裕华区",
                "level": 3,
                "order": 230,
                "relation_code": "130108",
                "relation_desc": "河北省石家庄市裕华区"
            },
            {
                "code": "030120",
                "parentcode": "0301",
                "parenttext": "石家庄市",
                "text": "藁城区",
                "level": 3,
                "order": 230,
                "relation_code": "130109",
                "relation_desc": "河北省石家庄市藁城区"
            },
            {
                "code": "030109",
                "parentcode": "0301",
                "parenttext": "石家庄市",
                "text": "栾城区",
                "level": 3,
                "order": 231,
                "relation_code": "130111",
                "relation_desc": "河北省石家庄市栾城区"
            },
            {
                "code": "030107",
                "parentcode": "0301",
                "parenttext": "石家庄市",
                "text": "井陉县",
                "level": 3,
                "order": 231,
                "relation_code": "130121",
                "relation_desc": "河北省井陉县"
            },
            {
                "code": "030123",
                "parentcode": "0301",
                "parenttext": "石家庄市",
                "text": "鹿泉区",
                "level": 3,
                "order": 232,
                "relation_code": "130110",
                "relation_desc": "河北省石家庄市鹿泉区"
            },
            {
                "code": "030108",
                "parentcode": "0301",
                "parenttext": "石家庄市",
                "text": "正定县",
                "level": 3,
                "order": 232,
                "relation_code": "130123",
                "relation_desc": "河北省正定县"
            },
            {
                "code": "030110",
                "parentcode": "0301",
                "parenttext": "石家庄市",
                "text": "行唐县",
                "level": 3,
                "order": 234,
                "relation_code": "130125",
                "relation_desc": "河北省行唐县"
            },
            {
                "code": "030111",
                "parentcode": "0301",
                "parenttext": "石家庄市",
                "text": "灵寿县",
                "level": 3,
                "order": 235,
                "relation_code": "130126",
                "relation_desc": "河北省灵寿县"
            },
            {
                "code": "030112",
                "parentcode": "0301",
                "parenttext": "石家庄市",
                "text": "高邑县",
                "level": 3,
                "order": 236,
                "relation_code": "130127",
                "relation_desc": "河北省高邑县"
            },
            {
                "code": "030113",
                "parentcode": "0301",
                "parenttext": "石家庄市",
                "text": "深泽县",
                "level": 3,
                "order": 237,
                "relation_code": "130128",
                "relation_desc": "河北省深泽县"
            },
            {
                "code": "030114",
                "parentcode": "0301",
                "parenttext": "石家庄市",
                "text": "赞皇县",
                "level": 3,
                "order": 238,
                "relation_code": "130129",
                "relation_desc": "河北省赞皇县"
            },
            {
                "code": "030115",
                "parentcode": "0301",
                "parenttext": "石家庄市",
                "text": "无极县",
                "level": 3,
                "order": 239,
                "relation_code": "130130",
                "relation_desc": "河北省无极县"
            },
            {
                "code": "030116",
                "parentcode": "0301",
                "parenttext": "石家庄市",
                "text": "平山县",
                "level": 3,
                "order": 240,
                "relation_code": "130131",
                "relation_desc": "河北省平山县"
            },
            {
                "code": "030117",
                "parentcode": "0301",
                "parenttext": "石家庄市",
                "text": "元氏县",
                "level": 3,
                "order": 241,
                "relation_code": "130132",
                "relation_desc": "河北省元氏县"
            },
            {
                "code": "030118",
                "parentcode": "0301",
                "parenttext": "石家庄市",
                "text": "赵县",
                "level": 3,
                "order": 242,
                "relation_code": "130133",
                "relation_desc": "河北省赵县"
            },
            {
                "code": "030119",
                "parentcode": "0301",
                "parenttext": "石家庄市",
                "text": "辛集市",
                "level": 3,
                "order": 243,
                "relation_code": "130181",
                "relation_desc": "河北省辛集市"
            },
            {
                "code": "030121",
                "parentcode": "0301",
                "parenttext": "石家庄市",
                "text": "晋州市",
                "level": 3,
                "order": 245,
                "relation_code": "130183",
                "relation_desc": "河北省晋州市"
            },
            {
                "code": "030122",
                "parentcode": "0301",
                "parenttext": "石家庄市",
                "text": "新乐市",
                "level": 3,
                "order": 246,
                "relation_code": "130184",
                "relation_desc": "河北省新乐市"
            },
            {
                "code": "0302",
                "parentcode": "03",
                "parenttext": "河北省",
                "text": "唐山市",
                "level": 2,
                "order": 248,
                "relation_code": "130200",
                "relation_desc": "河北省唐山市"
            },
            {
                "code": "030219",
                "parentcode": "0302",
                "parenttext": "唐山市",
                "text": "滦州市",
                "level": 3,
                "order": 248,
                "relation_code": "130284",
                "relation_desc": "河北省滦州市"
            },
            {
                "code": "030201",
                "parentcode": "0302",
                "parenttext": "唐山市",
                "text": "路南区",
                "level": 3,
                "order": 249,
                "relation_code": "130202",
                "relation_desc": "河北省唐山市路南区"
            },
            {
                "code": "030202",
                "parentcode": "0302",
                "parenttext": "唐山市",
                "text": "路北区",
                "level": 3,
                "order": 250,
                "relation_code": "130203",
                "relation_desc": "河北省唐山市路北区"
            },
            {
                "code": "030203",
                "parentcode": "0302",
                "parenttext": "唐山市",
                "text": "古冶区",
                "level": 3,
                "order": 251,
                "relation_code": "130204",
                "relation_desc": "河北省唐山市古冶区"
            },
            {
                "code": "030204",
                "parentcode": "0302",
                "parenttext": "唐山市",
                "text": "开平区",
                "level": 3,
                "order": 252,
                "relation_code": "130205",
                "relation_desc": "河北省唐山市开平区"
            },
            {
                "code": "030205",
                "parentcode": "0302",
                "parenttext": "唐山市",
                "text": "丰南区",
                "level": 3,
                "order": 253,
                "relation_code": "130207",
                "relation_desc": "河北省唐山市丰南区"
            },
            {
                "code": "030206",
                "parentcode": "0302",
                "parenttext": "唐山市",
                "text": "丰润区",
                "level": 3,
                "order": 254,
                "relation_code": "130208",
                "relation_desc": "河北省唐山市丰润区"
            },
            {
                "code": "030207",
                "parentcode": "0302",
                "parenttext": "唐山市",
                "text": "滦县",
                "level": 3,
                "order": 255,
                "relation_code": "130201",
                "relation_desc": "河北省滦县"
            },
            {
                "code": "030212",
                "parentcode": "0302",
                "parenttext": "唐山市",
                "text": "曹妃甸区",
                "level": 3,
                "order": 255,
                "relation_code": "130209",
                "relation_desc": "河北省唐山市曹妃甸区"
            },
            {
                "code": "030215",
                "parentcode": "0302",
                "parenttext": "唐山市",
                "text": "河北唐山芦台经济开发区",
                "level": 3,
                "order": 255,
                "relation_code": "130271",
                "relation_desc": "河北省唐山市芦台经济技术开发区"
            },
            {
                "code": "030218",
                "parentcode": "0302",
                "parenttext": "唐山市",
                "text": "河北唐山海港经济开发区",
                "level": 3,
                "order": 255,
                "relation_code": "130274",
                "relation_desc": "河北省河北唐山海港经济开发区"
            },
            {
                "code": "030208",
                "parentcode": "0302",
                "parenttext": "唐山市",
                "text": "滦南县",
                "level": 3,
                "order": 256,
                "relation_code": "130224",
                "relation_desc": "河北省滦南县"
            },
            {
                "code": "030209",
                "parentcode": "0302",
                "parenttext": "唐山市",
                "text": "乐亭县",
                "level": 3,
                "order": 257,
                "relation_code": "130225",
                "relation_desc": "河北省乐亭县"
            },
            {
                "code": "030210",
                "parentcode": "0302",
                "parenttext": "唐山市",
                "text": "迁西县",
                "level": 3,
                "order": 258,
                "relation_code": "130227",
                "relation_desc": "河北省迁西县"
            },
            {
                "code": "030211",
                "parentcode": "0302",
                "parenttext": "唐山市",
                "text": "玉田县",
                "level": 3,
                "order": 259,
                "relation_code": "130229",
                "relation_desc": "河北省玉田县"
            },
            {
                "code": "030213",
                "parentcode": "0302",
                "parenttext": "唐山市",
                "text": "遵化市",
                "level": 3,
                "order": 261,
                "relation_code": "130281",
                "relation_desc": "河北省遵化市"
            },
            {
                "code": "030216",
                "parentcode": "0302",
                "parenttext": "唐山市",
                "text": "唐山市汉沽管理区",
                "level": 3,
                "order": 261,
                "relation_code": "130272",
                "relation_desc": "河北省唐山市汉沽管理区"
            },
            {
                "code": "030214",
                "parentcode": "0302",
                "parenttext": "唐山市",
                "text": "迁安市",
                "level": 3,
                "order": 262,
                "relation_code": "130283",
                "relation_desc": "河北省迁安市"
            },
            {
                "code": "030217",
                "parentcode": "0302",
                "parenttext": "唐山市",
                "text": "唐山高新技术产业开发区",
                "level": 3,
                "order": 262,
                "relation_code": "130273",
                "relation_desc": "河北省唐山高新技术产业开发区"
            },
            {
                "code": "0303",
                "parentcode": "03",
                "parenttext": "河北省",
                "text": "秦皇岛市",
                "level": 2,
                "order": 263,
                "relation_code": "130300",
                "relation_desc": "河北省秦皇岛市"
            },
            {
                "code": "030301",
                "parentcode": "0303",
                "parenttext": "秦皇岛市",
                "text": "海港区",
                "level": 3,
                "order": 264,
                "relation_code": "130302",
                "relation_desc": "河北省秦皇岛市海港区"
            },
            {
                "code": "030302",
                "parentcode": "0303",
                "parenttext": "秦皇岛市",
                "text": "山海关区",
                "level": 3,
                "order": 265,
                "relation_code": "130303",
                "relation_desc": "河北省秦皇岛市山海关区"
            },
            {
                "code": "030303",
                "parentcode": "0303",
                "parenttext": "秦皇岛市",
                "text": "北戴河区",
                "level": 3,
                "order": 266,
                "relation_code": "130304",
                "relation_desc": "河北省秦皇岛市北戴河区"
            },
            {
                "code": "030306",
                "parentcode": "0303",
                "parenttext": "秦皇岛市",
                "text": "抚宁区",
                "level": 3,
                "order": 267,
                "relation_code": "130306",
                "relation_desc": "河北省秦皇岛市抚宁区"
            },
            {
                "code": "030304",
                "parentcode": "0303",
                "parenttext": "秦皇岛市",
                "text": "青龙满族自治县",
                "level": 3,
                "order": 267,
                "relation_code": "130321",
                "relation_desc": "河北省青龙满族自治县"
            },
            {
                "code": "030305",
                "parentcode": "0303",
                "parenttext": "秦皇岛市",
                "text": "昌黎县",
                "level": 3,
                "order": 268,
                "relation_code": "130322",
                "relation_desc": "河北省昌黎县"
            },
            {
                "code": "030307",
                "parentcode": "0303",
                "parenttext": "秦皇岛市",
                "text": "卢龙县",
                "level": 3,
                "order": 270,
                "relation_code": "130324",
                "relation_desc": "河北省卢龙县"
            },
            {
                "code": "0304",
                "parentcode": "03",
                "parenttext": "河北省",
                "text": "邯郸市",
                "level": 2,
                "order": 271,
                "relation_code": "130400",
                "relation_desc": "河北省邯郸市"
            },
            {
                "code": "030401",
                "parentcode": "0304",
                "parenttext": "邯郸市",
                "text": "邯山区",
                "level": 3,
                "order": 272,
                "relation_code": "130402",
                "relation_desc": "河北省邯郸市邯山区"
            },
            {
                "code": "030402",
                "parentcode": "0304",
                "parenttext": "邯郸市",
                "text": "丛台区",
                "level": 3,
                "order": 273,
                "relation_code": "130403",
                "relation_desc": "河北省邯郸市丛台区"
            },
            {
                "code": "030308",
                "parentcode": "0303",
                "parenttext": "秦皇岛市",
                "text": "秦皇岛市经济技术开发区",
                "level": 3,
                "order": 273,
                "relation_code": "130301",
                "relation_desc": "河北省秦皇岛市"
            },
            {
                "code": "030403",
                "parentcode": "0304",
                "parenttext": "邯郸市",
                "text": "复兴区",
                "level": 3,
                "order": 274,
                "relation_code": "130404",
                "relation_desc": "河北省邯郸市复兴区"
            },
            {
                "code": "030404",
                "parentcode": "0304",
                "parenttext": "邯郸市",
                "text": "峰峰矿区",
                "level": 3,
                "order": 275,
                "relation_code": "130406",
                "relation_desc": "河北省邯郸市峰峰矿区"
            },
            {
                "code": "030411",
                "parentcode": "0304",
                "parenttext": "邯郸市",
                "text": "肥乡区",
                "level": 3,
                "order": 276,
                "relation_code": "130401",
                "relation_desc": "河北省肥乡县"
            },
            {
                "code": "030405",
                "parentcode": "0304",
                "parenttext": "邯郸市",
                "text": "邯郸县",
                "level": 3,
                "order": 276,
                "relation_code": "130401",
                "relation_desc": "河北省邯郸县"
            },
            {
                "code": "030309",
                "parentcode": "0303",
                "parenttext": "秦皇岛市",
                "text": "北戴河新区",
                "level": 3,
                "order": 276,
                "relation_code": "130301",
                "relation_desc": "河北省秦皇岛市北戴河区"
            },
            {
                "code": "030412",
                "parentcode": "0304",
                "parenttext": "邯郸市",
                "text": "永年区",
                "level": 3,
                "order": 277,
                "relation_code": "130401",
                "relation_desc": "河北省永年县"
            },
            {
                "code": "030406",
                "parentcode": "0304",
                "parenttext": "邯郸市",
                "text": "临漳县",
                "level": 3,
                "order": 277,
                "relation_code": "130423",
                "relation_desc": "河北省临漳县"
            },
            {
                "code": "030407",
                "parentcode": "0304",
                "parenttext": "邯郸市",
                "text": "成安县",
                "level": 3,
                "order": 278,
                "relation_code": "130424",
                "relation_desc": "河北省成安县"
            },
            {
                "code": "030408",
                "parentcode": "0304",
                "parenttext": "邯郸市",
                "text": "大名县",
                "level": 3,
                "order": 279,
                "relation_code": "130425",
                "relation_desc": "河北省大名县"
            },
            {
                "code": "030409",
                "parentcode": "0304",
                "parenttext": "邯郸市",
                "text": "涉县",
                "level": 3,
                "order": 280,
                "relation_code": "130426",
                "relation_desc": "河北省涉县"
            },
            {
                "code": "030410",
                "parentcode": "0304",
                "parenttext": "邯郸市",
                "text": "磁县",
                "level": 3,
                "order": 281,
                "relation_code": "130427",
                "relation_desc": "河北省磁县"
            },
            {
                "code": "030413",
                "parentcode": "0304",
                "parenttext": "邯郸市",
                "text": "邱县",
                "level": 3,
                "order": 284,
                "relation_code": "130430",
                "relation_desc": "河北省邱县"
            },
            {
                "code": "030414",
                "parentcode": "0304",
                "parenttext": "邯郸市",
                "text": "鸡泽县",
                "level": 3,
                "order": 285,
                "relation_code": "130431",
                "relation_desc": "河北省鸡泽县"
            },
            {
                "code": "030415",
                "parentcode": "0304",
                "parenttext": "邯郸市",
                "text": "广平县",
                "level": 3,
                "order": 286,
                "relation_code": "130432",
                "relation_desc": "河北省广平县"
            },
            {
                "code": "030416",
                "parentcode": "0304",
                "parenttext": "邯郸市",
                "text": "馆陶县",
                "level": 3,
                "order": 287,
                "relation_code": "130433",
                "relation_desc": "河北省馆陶县"
            },
            {
                "code": "030417",
                "parentcode": "0304",
                "parenttext": "邯郸市",
                "text": "魏县",
                "level": 3,
                "order": 288,
                "relation_code": "130434",
                "relation_desc": "河北省魏县"
            },
            {
                "code": "030418",
                "parentcode": "0304",
                "parenttext": "邯郸市",
                "text": "曲周县",
                "level": 3,
                "order": 289,
                "relation_code": "130435",
                "relation_desc": "河北省曲周县"
            },
            {
                "code": "030419",
                "parentcode": "0304",
                "parenttext": "邯郸市",
                "text": "武安市",
                "level": 3,
                "order": 290,
                "relation_code": "130481",
                "relation_desc": "河北省武安市"
            },
            {
                "code": "0305",
                "parentcode": "03",
                "parenttext": "河北省",
                "text": "邢台市",
                "level": 2,
                "order": 291,
                "relation_code": "130500",
                "relation_desc": "河北省邢台市"
            },
            {
                "code": "030420",
                "parentcode": "0304",
                "parenttext": "邯郸市",
                "text": "邯郸经济技术开发区",
                "level": 3,
                "order": 291,
                "relation_code": "130401",
                "relation_desc": "河北省邯郸市"
            },
            {
                "code": "030501",
                "parentcode": "0305",
                "parenttext": "邢台市",
                "text": "桥东区",
                "level": 3,
                "order": 292,
                "relation_code": "130502",
                "relation_desc": "河北省邢台市桥东区"
            },
            {
                "code": "030421",
                "parentcode": "0304",
                "parenttext": "邯郸市",
                "text": "邯郸冀南新区",
                "level": 3,
                "order": 292,
                "relation_code": "130401",
                "relation_desc": "河北省邯郸市"
            },
            {
                "code": "030502",
                "parentcode": "0305",
                "parenttext": "邢台市",
                "text": "桥西区",
                "level": 3,
                "order": 293,
                "relation_code": "130503",
                "relation_desc": "河北省邢台市桥西区"
            },
            {
                "code": "030503",
                "parentcode": "0305",
                "parenttext": "邢台市",
                "text": "邢台县",
                "level": 3,
                "order": 294,
                "relation_code": "130521",
                "relation_desc": "河北省邢台县"
            },
            {
                "code": "030504",
                "parentcode": "0305",
                "parenttext": "邢台市",
                "text": "临城县",
                "level": 3,
                "order": 295,
                "relation_code": "130522",
                "relation_desc": "河北省临城县"
            },
            {
                "code": "030505",
                "parentcode": "0305",
                "parenttext": "邢台市",
                "text": "内丘县",
                "level": 3,
                "order": 296,
                "relation_code": "130523",
                "relation_desc": "河北省内丘县"
            },
            {
                "code": "030506",
                "parentcode": "0305",
                "parenttext": "邢台市",
                "text": "柏乡县",
                "level": 3,
                "order": 297,
                "relation_code": "130524",
                "relation_desc": "河北省柏乡县"
            },
            {
                "code": "030507",
                "parentcode": "0305",
                "parenttext": "邢台市",
                "text": "隆尧县",
                "level": 3,
                "order": 298,
                "relation_code": "130525",
                "relation_desc": "河北省隆尧县"
            },
            {
                "code": "030508",
                "parentcode": "0305",
                "parenttext": "邢台市",
                "text": "任县",
                "level": 3,
                "order": 299,
                "relation_code": "130526",
                "relation_desc": "河北省任县"
            },
            {
                "code": "030509",
                "parentcode": "0305",
                "parenttext": "邢台市",
                "text": "南和县",
                "level": 3,
                "order": 300,
                "relation_code": "130527",
                "relation_desc": "河北省南和县"
            },
            {
                "code": "030510",
                "parentcode": "0305",
                "parenttext": "邢台市",
                "text": "宁晋县",
                "level": 3,
                "order": 301,
                "relation_code": "130528",
                "relation_desc": "河北省宁晋县"
            },
            {
                "code": "030511",
                "parentcode": "0305",
                "parenttext": "邢台市",
                "text": "巨鹿县",
                "level": 3,
                "order": 302,
                "relation_code": "130529",
                "relation_desc": "河北省巨鹿县"
            },
            {
                "code": "030512",
                "parentcode": "0305",
                "parenttext": "邢台市",
                "text": "新河县",
                "level": 3,
                "order": 303,
                "relation_code": "130530",
                "relation_desc": "河北省新河县"
            },
            {
                "code": "030513",
                "parentcode": "0305",
                "parenttext": "邢台市",
                "text": "广宗县",
                "level": 3,
                "order": 304,
                "relation_code": "130531",
                "relation_desc": "河北省广宗县"
            },
            {
                "code": "030514",
                "parentcode": "0305",
                "parenttext": "邢台市",
                "text": "平乡县",
                "level": 3,
                "order": 305,
                "relation_code": "130532",
                "relation_desc": "河北省平乡县"
            },
            {
                "code": "030515",
                "parentcode": "0305",
                "parenttext": "邢台市",
                "text": "威县",
                "level": 3,
                "order": 306,
                "relation_code": "130533",
                "relation_desc": "河北省威县"
            },
            {
                "code": "030516",
                "parentcode": "0305",
                "parenttext": "邢台市",
                "text": "清河县",
                "level": 3,
                "order": 307,
                "relation_code": "130534",
                "relation_desc": "河北省清河县"
            },
            {
                "code": "030517",
                "parentcode": "0305",
                "parenttext": "邢台市",
                "text": "临西县",
                "level": 3,
                "order": 308,
                "relation_code": "130535",
                "relation_desc": "河北省临西县"
            },
            {
                "code": "030518",
                "parentcode": "0305",
                "parenttext": "邢台市",
                "text": "南宫市",
                "level": 3,
                "order": 309,
                "relation_code": "130581",
                "relation_desc": "河北省南宫市"
            },
            {
                "code": "030519",
                "parentcode": "0305",
                "parenttext": "邢台市",
                "text": "沙河市",
                "level": 3,
                "order": 310,
                "relation_code": "130582",
                "relation_desc": "河北省沙河市"
            },
            {
                "code": "0306",
                "parentcode": "03",
                "parenttext": "河北省",
                "text": "保定市",
                "level": 2,
                "order": 311,
                "relation_code": "130600",
                "relation_desc": "河北省保定市"
            },
            {
                "code": "030520",
                "parentcode": "0305",
                "parenttext": "邢台市",
                "text": "河北邢台经济开发区",
                "level": 3,
                "order": 311,
                "relation_code": "130501",
                "relation_desc": "河北省邢台市"
            },
            {
                "code": "030601",
                "parentcode": "0306",
                "parenttext": "保定市",
                "text": "竞秀区",
                "level": 3,
                "order": 312,
                "relation_code": "130602",
                "relation_desc": "河北省保定市竞秀区"
            },
            {
                "code": "030602",
                "parentcode": "0306",
                "parenttext": "保定市",
                "text": "莲池区",
                "level": 3,
                "order": 313,
                "relation_code": "130606",
                "relation_desc": "河北省保定市莲池区"
            },
            {
                "code": "030604",
                "parentcode": "0306",
                "parenttext": "保定市",
                "text": "满城区",
                "level": 3,
                "order": 315,
                "relation_code": "130607",
                "relation_desc": "河北省保定市满城区"
            },
            {
                "code": "030605",
                "parentcode": "0306",
                "parenttext": "保定市",
                "text": "清苑区",
                "level": 3,
                "order": 316,
                "relation_code": "130608",
                "relation_desc": "河北省保定市清苑区"
            },
            {
                "code": "030606",
                "parentcode": "0306",
                "parenttext": "保定市",
                "text": "涞水县",
                "level": 3,
                "order": 317,
                "relation_code": "130623",
                "relation_desc": "河北省涞水县"
            },
            {
                "code": "030607",
                "parentcode": "0306",
                "parenttext": "保定市",
                "text": "阜平县",
                "level": 3,
                "order": 318,
                "relation_code": "130624",
                "relation_desc": "河北省阜平县"
            },
            {
                "code": "030608",
                "parentcode": "0306",
                "parenttext": "保定市",
                "text": "徐水区",
                "level": 3,
                "order": 319,
                "relation_code": "130609",
                "relation_desc": "河北省保定市徐水区"
            },
            {
                "code": "030609",
                "parentcode": "0306",
                "parenttext": "保定市",
                "text": "定兴县",
                "level": 3,
                "order": 320,
                "relation_code": "130626",
                "relation_desc": "河北省定兴县"
            },
            {
                "code": "030610",
                "parentcode": "0306",
                "parenttext": "保定市",
                "text": "唐县",
                "level": 3,
                "order": 321,
                "relation_code": "130627",
                "relation_desc": "河北省唐县"
            },
            {
                "code": "030611",
                "parentcode": "0306",
                "parenttext": "保定市",
                "text": "高阳县",
                "level": 3,
                "order": 322,
                "relation_code": "130628",
                "relation_desc": "河北省高阳县"
            },
            {
                "code": "030612",
                "parentcode": "0306",
                "parenttext": "保定市",
                "text": "容城县",
                "level": 3,
                "order": 323,
                "relation_code": "130629",
                "relation_desc": "河北省容城县"
            },
            {
                "code": "030613",
                "parentcode": "0306",
                "parenttext": "保定市",
                "text": "涞源县",
                "level": 3,
                "order": 324,
                "relation_code": "130630",
                "relation_desc": "河北省涞源县"
            },
            {
                "code": "030614",
                "parentcode": "0306",
                "parenttext": "保定市",
                "text": "望都县",
                "level": 3,
                "order": 325,
                "relation_code": "130631",
                "relation_desc": "河北省望都县"
            },
            {
                "code": "030615",
                "parentcode": "0306",
                "parenttext": "保定市",
                "text": "安新县",
                "level": 3,
                "order": 326,
                "relation_code": "130632",
                "relation_desc": "河北省安新县"
            },
            {
                "code": "030616",
                "parentcode": "0306",
                "parenttext": "保定市",
                "text": "易县",
                "level": 3,
                "order": 327,
                "relation_code": "130633",
                "relation_desc": "河北省易县"
            },
            {
                "code": "030617",
                "parentcode": "0306",
                "parenttext": "保定市",
                "text": "曲阳县",
                "level": 3,
                "order": 328,
                "relation_code": "130634",
                "relation_desc": "河北省曲阳县"
            },
            {
                "code": "030618",
                "parentcode": "0306",
                "parenttext": "保定市",
                "text": "蠡县",
                "level": 3,
                "order": 329,
                "relation_code": "130635",
                "relation_desc": "河北省蠡县"
            },
            {
                "code": "030619",
                "parentcode": "0306",
                "parenttext": "保定市",
                "text": "顺平县",
                "level": 3,
                "order": 330,
                "relation_code": "130636",
                "relation_desc": "河北省顺平县"
            },
            {
                "code": "030620",
                "parentcode": "0306",
                "parenttext": "保定市",
                "text": "博野县",
                "level": 3,
                "order": 331,
                "relation_code": "130637",
                "relation_desc": "河北省博野县"
            },
            {
                "code": "030621",
                "parentcode": "0306",
                "parenttext": "保定市",
                "text": "雄县",
                "level": 3,
                "order": 332,
                "relation_code": "130638",
                "relation_desc": "河北省雄县"
            },
            {
                "code": "030622",
                "parentcode": "0306",
                "parenttext": "保定市",
                "text": "涿州市",
                "level": 3,
                "order": 333,
                "relation_code": "130681",
                "relation_desc": "河北省涿州市"
            },
            {
                "code": "030623",
                "parentcode": "0306",
                "parenttext": "保定市",
                "text": "定州市",
                "level": 3,
                "order": 334,
                "relation_code": "130682",
                "relation_desc": "河北省定州市"
            },
            {
                "code": "030624",
                "parentcode": "0306",
                "parenttext": "保定市",
                "text": "安国市",
                "level": 3,
                "order": 335,
                "relation_code": "130683",
                "relation_desc": "河北省安国市"
            },
            {
                "code": "030625",
                "parentcode": "0306",
                "parenttext": "保定市",
                "text": "高碑店市",
                "level": 3,
                "order": 336,
                "relation_code": "130684",
                "relation_desc": "河北省高碑店市"
            },
            {
                "code": "0307",
                "parentcode": "03",
                "parenttext": "河北省",
                "text": "张家口市",
                "level": 2,
                "order": 337,
                "relation_code": "130700",
                "relation_desc": "河北省张家口市"
            },
            {
                "code": "030701",
                "parentcode": "0307",
                "parenttext": "张家口市",
                "text": "桥东区",
                "level": 3,
                "order": 338,
                "relation_code": "130702",
                "relation_desc": "河北省张家口市桥东区"
            },
            {
                "code": "030702",
                "parentcode": "0307",
                "parenttext": "张家口市",
                "text": "桥西区",
                "level": 3,
                "order": 339,
                "relation_code": "130703",
                "relation_desc": "河北省张家口市桥西区"
            },
            {
                "code": "030703",
                "parentcode": "0307",
                "parenttext": "张家口市",
                "text": "宣化区",
                "level": 3,
                "order": 340,
                "relation_code": "130705",
                "relation_desc": "河北省张家口市宣化区"
            },
            {
                "code": "030704",
                "parentcode": "0307",
                "parenttext": "张家口市",
                "text": "下花园区",
                "level": 3,
                "order": 341,
                "relation_code": "130706",
                "relation_desc": "河北省张家口市下花园区"
            },
            {
                "code": "030705",
                "parentcode": "0307",
                "parenttext": "张家口市",
                "text": "宣化县",
                "level": 3,
                "order": 342,
                "relation_code": "130701",
                "relation_desc": "河北省宣化县"
            },
            {
                "code": "030718",
                "parentcode": "0307",
                "parenttext": "张家口市",
                "text": "万全区",
                "level": 3,
                "order": 342,
                "relation_code": "130708",
                "relation_desc": "河北省张家口市万全区"
            },
            {
                "code": "030721",
                "parentcode": "0307",
                "parenttext": "张家口市",
                "text": "张家口市察北管理区",
                "level": 3,
                "order": 342,
                "relation_code": "130701",
                "relation_desc": "河北省张家口市"
            },
            {
                "code": "030706",
                "parentcode": "0307",
                "parenttext": "张家口市",
                "text": "张北县",
                "level": 3,
                "order": 343,
                "relation_code": "130722",
                "relation_desc": "河北省张北县"
            },
            {
                "code": "030719",
                "parentcode": "0307",
                "parenttext": "张家口市",
                "text": "崇礼区",
                "level": 3,
                "order": 343,
                "relation_code": "130709",
                "relation_desc": "河北省张家口市崇礼区"
            },
            {
                "code": "030722",
                "parentcode": "0307",
                "parenttext": "张家口市",
                "text": "张家口市塞北管理区",
                "level": 3,
                "order": 343,
                "relation_code": "130701",
                "relation_desc": "河北省张家口市"
            },
            {
                "code": "030707",
                "parentcode": "0307",
                "parenttext": "张家口市",
                "text": "康保县",
                "level": 3,
                "order": 344,
                "relation_code": "130723",
                "relation_desc": "河北省康保县"
            },
            {
                "code": "030708",
                "parentcode": "0307",
                "parenttext": "张家口市",
                "text": "沽源县",
                "level": 3,
                "order": 345,
                "relation_code": "130724",
                "relation_desc": "河北省沽源县"
            },
            {
                "code": "030709",
                "parentcode": "0307",
                "parenttext": "张家口市",
                "text": "尚义县",
                "level": 3,
                "order": 346,
                "relation_code": "130725",
                "relation_desc": "河北省尚义县"
            },
            {
                "code": "030710",
                "parentcode": "0307",
                "parenttext": "张家口市",
                "text": "蔚县",
                "level": 3,
                "order": 347,
                "relation_code": "130726",
                "relation_desc": "河北省蔚县"
            },
            {
                "code": "030711",
                "parentcode": "0307",
                "parenttext": "张家口市",
                "text": "阳原县",
                "level": 3,
                "order": 348,
                "relation_code": "130727",
                "relation_desc": "河北省阳原县"
            },
            {
                "code": "030712",
                "parentcode": "0307",
                "parenttext": "张家口市",
                "text": "怀安县",
                "level": 3,
                "order": 349,
                "relation_code": "130728",
                "relation_desc": "河北省怀安县"
            },
            {
                "code": "030713",
                "parentcode": "0307",
                "parenttext": "张家口市",
                "text": "万全县",
                "level": 3,
                "order": 350,
                "relation_code": "130701",
                "relation_desc": "河北省万全县"
            },
            {
                "code": "030714",
                "parentcode": "0307",
                "parenttext": "张家口市",
                "text": "怀来县",
                "level": 3,
                "order": 351,
                "relation_code": "130730",
                "relation_desc": "河北省怀来县"
            },
            {
                "code": "030715",
                "parentcode": "0307",
                "parenttext": "张家口市",
                "text": "涿鹿县",
                "level": 3,
                "order": 352,
                "relation_code": "130731",
                "relation_desc": "河北省涿鹿县"
            },
            {
                "code": "030716",
                "parentcode": "0307",
                "parenttext": "张家口市",
                "text": "赤城县",
                "level": 3,
                "order": 353,
                "relation_code": "130732",
                "relation_desc": "河北省赤城县"
            },
            {
                "code": "030717",
                "parentcode": "0307",
                "parenttext": "张家口市",
                "text": "崇礼县",
                "level": 3,
                "order": 354,
                "relation_code": "130701",
                "relation_desc": "河北省崇礼县"
            },
            {
                "code": "030720",
                "parentcode": "0307",
                "parenttext": "张家口市",
                "text": "张家口经济开发区",
                "level": 3,
                "order": 354,
                "relation_code": "130701",
                "relation_desc": "河北省张家口市"
            },
            {
                "code": "0308",
                "parentcode": "03",
                "parenttext": "河北省",
                "text": "承德市",
                "level": 2,
                "order": 355,
                "relation_code": "130800",
                "relation_desc": "河北省承德市"
            },
            {
                "code": "030801",
                "parentcode": "0308",
                "parenttext": "承德市",
                "text": "双桥区",
                "level": 3,
                "order": 356,
                "relation_code": "130802",
                "relation_desc": "河北省承德市双桥区"
            },
            {
                "code": "030802",
                "parentcode": "0308",
                "parenttext": "承德市",
                "text": "双滦区",
                "level": 3,
                "order": 357,
                "relation_code": "130803",
                "relation_desc": "河北省承德市双滦区"
            },
            {
                "code": "030803",
                "parentcode": "0308",
                "parenttext": "承德市",
                "text": "鹰手营子矿区",
                "level": 3,
                "order": 358,
                "relation_code": "130804",
                "relation_desc": "河北省承德市鹰手营子矿区"
            },
            {
                "code": "030804",
                "parentcode": "0308",
                "parenttext": "承德市",
                "text": "承德县",
                "level": 3,
                "order": 359,
                "relation_code": "130821",
                "relation_desc": "河北省承德县"
            },
            {
                "code": "030805",
                "parentcode": "0308",
                "parenttext": "承德市",
                "text": "兴隆县",
                "level": 3,
                "order": 360,
                "relation_code": "130822",
                "relation_desc": "河北省兴隆县"
            },
            {
                "code": "030806",
                "parentcode": "0308",
                "parenttext": "承德市",
                "text": "平泉县",
                "level": 3,
                "order": 361,
                "relation_code": "130801",
                "relation_desc": "河北省平泉县"
            },
            {
                "code": "030807",
                "parentcode": "0308",
                "parenttext": "承德市",
                "text": "滦平县",
                "level": 3,
                "order": 362,
                "relation_code": "130824",
                "relation_desc": "河北省滦平县"
            },
            {
                "code": "030808",
                "parentcode": "0308",
                "parenttext": "承德市",
                "text": "隆化县",
                "level": 3,
                "order": 363,
                "relation_code": "130825",
                "relation_desc": "河北省隆化县"
            },
            {
                "code": "030809",
                "parentcode": "0308",
                "parenttext": "承德市",
                "text": "丰宁满族自治县",
                "level": 3,
                "order": 364,
                "relation_code": "130826",
                "relation_desc": "河北省丰宁满族自治县"
            },
            {
                "code": "030810",
                "parentcode": "0308",
                "parenttext": "承德市",
                "text": "宽城满族自治县",
                "level": 3,
                "order": 365,
                "relation_code": "130827",
                "relation_desc": "河北省宽城满族自治县"
            },
            {
                "code": "030811",
                "parentcode": "0308",
                "parenttext": "承德市",
                "text": "围场满族蒙古族自治县",
                "level": 3,
                "order": 366,
                "relation_code": "130828",
                "relation_desc": "河北省围场满族蒙古族自治县"
            },
            {
                "code": "0309",
                "parentcode": "03",
                "parenttext": "河北省",
                "text": "沧州市",
                "level": 2,
                "order": 367,
                "relation_code": "130900",
                "relation_desc": "河北省沧州市"
            },
            {
                "code": "030812",
                "parentcode": "0308",
                "parenttext": "承德市",
                "text": "承德高新技术产业开发区",
                "level": 3,
                "order": 367,
                "relation_code": "130801",
                "relation_desc": "河北省承德市"
            },
            {
                "code": "030901",
                "parentcode": "0309",
                "parenttext": "沧州市",
                "text": "新华区",
                "level": 3,
                "order": 368,
                "relation_code": "130902",
                "relation_desc": "河北省沧州市新华区"
            },
            {
                "code": "030902",
                "parentcode": "0309",
                "parenttext": "沧州市",
                "text": "运河区",
                "level": 3,
                "order": 369,
                "relation_code": "130903",
                "relation_desc": "河北省沧州市运河区"
            },
            {
                "code": "030903",
                "parentcode": "0309",
                "parenttext": "沧州市",
                "text": "沧县",
                "level": 3,
                "order": 370,
                "relation_code": "130921",
                "relation_desc": "河北省沧县"
            },
            {
                "code": "030904",
                "parentcode": "0309",
                "parenttext": "沧州市",
                "text": "青县",
                "level": 3,
                "order": 371,
                "relation_code": "130922",
                "relation_desc": "河北省青县"
            },
            {
                "code": "030905",
                "parentcode": "0309",
                "parenttext": "沧州市",
                "text": "东光县",
                "level": 3,
                "order": 372,
                "relation_code": "130923",
                "relation_desc": "河北省东光县"
            },
            {
                "code": "030906",
                "parentcode": "0309",
                "parenttext": "沧州市",
                "text": "海兴县",
                "level": 3,
                "order": 373,
                "relation_code": "130924",
                "relation_desc": "河北省海兴县"
            },
            {
                "code": "030907",
                "parentcode": "0309",
                "parenttext": "沧州市",
                "text": "盐山县",
                "level": 3,
                "order": 374,
                "relation_code": "130925",
                "relation_desc": "河北省盐山县"
            },
            {
                "code": "030908",
                "parentcode": "0309",
                "parenttext": "沧州市",
                "text": "肃宁县",
                "level": 3,
                "order": 375,
                "relation_code": "130926",
                "relation_desc": "河北省肃宁县"
            },
            {
                "code": "030909",
                "parentcode": "0309",
                "parenttext": "沧州市",
                "text": "南皮县",
                "level": 3,
                "order": 376,
                "relation_code": "130927",
                "relation_desc": "河北省南皮县"
            },
            {
                "code": "030910",
                "parentcode": "0309",
                "parenttext": "沧州市",
                "text": "吴桥县",
                "level": 3,
                "order": 377,
                "relation_code": "130928",
                "relation_desc": "河北省吴桥县"
            },
            {
                "code": "030911",
                "parentcode": "0309",
                "parenttext": "沧州市",
                "text": "献县",
                "level": 3,
                "order": 378,
                "relation_code": "130929",
                "relation_desc": "河北省献县"
            },
            {
                "code": "030912",
                "parentcode": "0309",
                "parenttext": "沧州市",
                "text": "孟村回族自治县",
                "level": 3,
                "order": 379,
                "relation_code": "130930",
                "relation_desc": "河北省孟村回族自治县"
            },
            {
                "code": "030913",
                "parentcode": "0309",
                "parenttext": "沧州市",
                "text": "泊头市",
                "level": 3,
                "order": 380,
                "relation_code": "130981",
                "relation_desc": "河北省泊头市"
            },
            {
                "code": "030914",
                "parentcode": "0309",
                "parenttext": "沧州市",
                "text": "任丘市",
                "level": 3,
                "order": 381,
                "relation_code": "130982",
                "relation_desc": "河北省任丘市"
            },
            {
                "code": "030915",
                "parentcode": "0309",
                "parenttext": "沧州市",
                "text": "黄骅市",
                "level": 3,
                "order": 382,
                "relation_code": "130983",
                "relation_desc": "河北省黄骅市"
            },
            {
                "code": "030916",
                "parentcode": "0309",
                "parenttext": "沧州市",
                "text": "河间市",
                "level": 3,
                "order": 383,
                "relation_code": "130984",
                "relation_desc": "河北省河间市"
            },
            {
                "code": "0310",
                "parentcode": "03",
                "parenttext": "河北省",
                "text": "廊坊市",
                "level": 2,
                "order": 384,
                "relation_code": "131000",
                "relation_desc": "河北省廊坊市"
            },
            {
                "code": "030917",
                "parentcode": "0309",
                "parenttext": "沧州市",
                "text": "河北沧州经济开发区",
                "level": 3,
                "order": 384,
                "relation_code": "130901",
                "relation_desc": "河北省沧州市"
            },
            {
                "code": "031001",
                "parentcode": "0310",
                "parenttext": "廊坊市",
                "text": "安次区",
                "level": 3,
                "order": 385,
                "relation_code": "131002",
                "relation_desc": "河北省廊坊市安次区"
            },
            {
                "code": "030918",
                "parentcode": "0309",
                "parenttext": "沧州市",
                "text": "沧州高新技术产业开发区",
                "level": 3,
                "order": 385,
                "relation_code": "130901",
                "relation_desc": "河北省沧州市"
            },
            {
                "code": "031002",
                "parentcode": "0310",
                "parenttext": "廊坊市",
                "text": "广阳区",
                "level": 3,
                "order": 386,
                "relation_code": "131003",
                "relation_desc": "河北省廊坊市广阳区"
            },
            {
                "code": "030919",
                "parentcode": "0309",
                "parenttext": "沧州市",
                "text": "沧州渤海新区",
                "level": 3,
                "order": 386,
                "relation_code": "130901",
                "relation_desc": "河北省沧州市"
            },
            {
                "code": "031003",
                "parentcode": "0310",
                "parenttext": "廊坊市",
                "text": "固安县",
                "level": 3,
                "order": 387,
                "relation_code": "131022",
                "relation_desc": "河北省固安县"
            },
            {
                "code": "031004",
                "parentcode": "0310",
                "parenttext": "廊坊市",
                "text": "永清县",
                "level": 3,
                "order": 388,
                "relation_code": "131023",
                "relation_desc": "河北省永清县"
            },
            {
                "code": "031005",
                "parentcode": "0310",
                "parenttext": "廊坊市",
                "text": "香河县",
                "level": 3,
                "order": 389,
                "relation_code": "131024",
                "relation_desc": "河北省香河县"
            },
            {
                "code": "031006",
                "parentcode": "0310",
                "parenttext": "廊坊市",
                "text": "大城县",
                "level": 3,
                "order": 390,
                "relation_code": "131025",
                "relation_desc": "河北省大城县"
            },
            {
                "code": "031007",
                "parentcode": "0310",
                "parenttext": "廊坊市",
                "text": "文安县",
                "level": 3,
                "order": 391,
                "relation_code": "131026",
                "relation_desc": "河北省文安县"
            },
            {
                "code": "031008",
                "parentcode": "0310",
                "parenttext": "廊坊市",
                "text": "大厂回族自治县",
                "level": 3,
                "order": 392,
                "relation_code": "131028",
                "relation_desc": "河北省大厂回族自治县"
            },
            {
                "code": "031009",
                "parentcode": "0310",
                "parenttext": "廊坊市",
                "text": "霸州市",
                "level": 3,
                "order": 393,
                "relation_code": "131081",
                "relation_desc": "河北省霸州市"
            },
            {
                "code": "031010",
                "parentcode": "0310",
                "parenttext": "廊坊市",
                "text": "三河市",
                "level": 3,
                "order": 394,
                "relation_code": "131082",
                "relation_desc": "河北省三河市"
            },
            {
                "code": "0311",
                "parentcode": "03",
                "parenttext": "河北省",
                "text": "衡水市",
                "level": 2,
                "order": 395,
                "relation_code": "131100",
                "relation_desc": "河北省衡水市"
            },
            {
                "code": "031011",
                "parentcode": "0310",
                "parenttext": "廊坊市",
                "text": "廊坊经济技术开发区",
                "level": 3,
                "order": 395,
                "relation_code": "131001",
                "relation_desc": "河北省廊坊市"
            },
            {
                "code": "031101",
                "parentcode": "0311",
                "parenttext": "衡水市",
                "text": "桃城区",
                "level": 3,
                "order": 396,
                "relation_code": "131102",
                "relation_desc": "河北省衡水市桃城区"
            },
            {
                "code": "031102",
                "parentcode": "0311",
                "parenttext": "衡水市",
                "text": "枣强县",
                "level": 3,
                "order": 397,
                "relation_code": "131121",
                "relation_desc": "河北省枣强县"
            },
            {
                "code": "031110",
                "parentcode": "0311",
                "parenttext": "衡水市",
                "text": "冀州区",
                "level": 3,
                "order": 397,
                "relation_code": "131103",
                "relation_desc": "河北省衡水市冀州区"
            },
            {
                "code": "031103",
                "parentcode": "0311",
                "parenttext": "衡水市",
                "text": "武邑县",
                "level": 3,
                "order": 398,
                "relation_code": "131122",
                "relation_desc": "河北省武邑县"
            },
            {
                "code": "031104",
                "parentcode": "0311",
                "parenttext": "衡水市",
                "text": "武强县",
                "level": 3,
                "order": 399,
                "relation_code": "131123",
                "relation_desc": "河北省武强县"
            },
            {
                "code": "031105",
                "parentcode": "0311",
                "parenttext": "衡水市",
                "text": "饶阳县",
                "level": 3,
                "order": 400,
                "relation_code": "131124",
                "relation_desc": "河北省饶阳县"
            },
            {
                "code": "031106",
                "parentcode": "0311",
                "parenttext": "衡水市",
                "text": "安平县",
                "level": 3,
                "order": 401,
                "relation_code": "131125",
                "relation_desc": "河北省安平县"
            },
            {
                "code": "031107",
                "parentcode": "0311",
                "parenttext": "衡水市",
                "text": "故城县",
                "level": 3,
                "order": 402,
                "relation_code": "131126",
                "relation_desc": "河北省故城县"
            },
            {
                "code": "031108",
                "parentcode": "0311",
                "parenttext": "衡水市",
                "text": "景县",
                "level": 3,
                "order": 403,
                "relation_code": "131127",
                "relation_desc": "河北省景县"
            },
            {
                "code": "031109",
                "parentcode": "0311",
                "parenttext": "衡水市",
                "text": "阜城县",
                "level": 3,
                "order": 404,
                "relation_code": "131128",
                "relation_desc": "河北省阜城县"
            },
            {
                "code": "031111",
                "parentcode": "0311",
                "parenttext": "衡水市",
                "text": "深州市",
                "level": 3,
                "order": 406,
                "relation_code": "131182",
                "relation_desc": "河北省深州市"
            },
            {
                "code": "04",
                "parentcode": "0",
                "parenttext": "0",
                "text": "山西省",
                "level": 1,
                "order": 407,
                "relation_code": "",
                "relation_desc": "山西省"
            },
            {
                "code": "0401",
                "parentcode": "04",
                "parenttext": "山西省",
                "text": "太原市",
                "level": 2,
                "order": 408,
                "relation_code": "140100",
                "relation_desc": "山西省太原市"
            },
            {
                "code": "040101",
                "parentcode": "0401",
                "parenttext": "太原市",
                "text": "小店区",
                "level": 3,
                "order": 409,
                "relation_code": "140105",
                "relation_desc": "山西省太原市小店区"
            },
            {
                "code": "040102",
                "parentcode": "0401",
                "parenttext": "太原市",
                "text": "迎泽区",
                "level": 3,
                "order": 410,
                "relation_code": "140106",
                "relation_desc": "山西省太原市迎泽区"
            },
            {
                "code": "040103",
                "parentcode": "0401",
                "parenttext": "太原市",
                "text": "杏花岭区",
                "level": 3,
                "order": 411,
                "relation_code": "140107",
                "relation_desc": "山西省太原市杏花岭区"
            },
            {
                "code": "040104",
                "parentcode": "0401",
                "parenttext": "太原市",
                "text": "尖草坪区",
                "level": 3,
                "order": 412,
                "relation_code": "140108",
                "relation_desc": "山西省太原市尖草坪区"
            },
            {
                "code": "040105",
                "parentcode": "0401",
                "parenttext": "太原市",
                "text": "万柏林区",
                "level": 3,
                "order": 413,
                "relation_code": "140109",
                "relation_desc": "山西省太原市万柏林区"
            },
            {
                "code": "040106",
                "parentcode": "0401",
                "parenttext": "太原市",
                "text": "晋源区",
                "level": 3,
                "order": 414,
                "relation_code": "140110",
                "relation_desc": "山西省太原市晋源区"
            },
            {
                "code": "040107",
                "parentcode": "0401",
                "parenttext": "太原市",
                "text": "清徐县",
                "level": 3,
                "order": 415,
                "relation_code": "140121",
                "relation_desc": "山西省清徐县"
            },
            {
                "code": "031112",
                "parentcode": "0311",
                "parenttext": "衡水市",
                "text": "河北衡水高新技术产业开发区",
                "level": 3,
                "order": 415,
                "relation_code": "131101",
                "relation_desc": "河北省衡水市"
            },
            {
                "code": "040108",
                "parentcode": "0401",
                "parenttext": "太原市",
                "text": "阳曲县",
                "level": 3,
                "order": 416,
                "relation_code": "140122",
                "relation_desc": "山西省阳曲县"
            },
            {
                "code": "040109",
                "parentcode": "0401",
                "parenttext": "太原市",
                "text": "娄烦县",
                "level": 3,
                "order": 417,
                "relation_code": "140123",
                "relation_desc": "山西省娄烦县"
            },
            {
                "code": "040110",
                "parentcode": "0401",
                "parenttext": "太原市",
                "text": "古交市",
                "level": 3,
                "order": 418,
                "relation_code": "140181",
                "relation_desc": "山西省古交市"
            },
            {
                "code": "0402",
                "parentcode": "04",
                "parenttext": "山西省",
                "text": "大同市",
                "level": 2,
                "order": 419,
                "relation_code": "140200",
                "relation_desc": "山西省大同市"
            },
            {
                "code": "040111",
                "parentcode": "0401",
                "parenttext": "太原市",
                "text": "山西转型综合改革示范区",
                "level": 3,
                "order": 419,
                "relation_code": "140101",
                "relation_desc": "山西省太原市"
            },
            {
                "code": "040201",
                "parentcode": "0402",
                "parenttext": "大同市",
                "text": "平城区",
                "level": 3,
                "order": 420,
                "relation_code": "140213",
                "relation_desc": "山西省大同市平城区"
            },
            {
                "code": "040203",
                "parentcode": "0402",
                "parenttext": "大同市",
                "text": "云冈区",
                "level": 3,
                "order": 422,
                "relation_code": "140214",
                "relation_desc": "山西省大同市云冈区"
            },
            {
                "code": "040204",
                "parentcode": "0402",
                "parenttext": "大同市",
                "text": "新荣区",
                "level": 3,
                "order": 423,
                "relation_code": "140212",
                "relation_desc": "山西省大同市新荣区"
            },
            {
                "code": "040205",
                "parentcode": "0402",
                "parenttext": "大同市",
                "text": "阳高县",
                "level": 3,
                "order": 424,
                "relation_code": "140221",
                "relation_desc": "山西省阳高县"
            },
            {
                "code": "031113",
                "parentcode": "0311",
                "parenttext": "衡水市",
                "text": "衡水滨湖新区",
                "level": 3,
                "order": 424,
                "relation_code": "131101",
                "relation_desc": "河北省衡水市"
            },
            {
                "code": "040206",
                "parentcode": "0402",
                "parenttext": "大同市",
                "text": "天镇县",
                "level": 3,
                "order": 425,
                "relation_code": "140222",
                "relation_desc": "山西省天镇县"
            },
            {
                "code": "040207",
                "parentcode": "0402",
                "parenttext": "大同市",
                "text": "广灵县",
                "level": 3,
                "order": 426,
                "relation_code": "140223",
                "relation_desc": "山西省广灵县"
            },
            {
                "code": "040208",
                "parentcode": "0402",
                "parenttext": "大同市",
                "text": "灵丘县",
                "level": 3,
                "order": 427,
                "relation_code": "140224",
                "relation_desc": "山西省灵丘县"
            },
            {
                "code": "040209",
                "parentcode": "0402",
                "parenttext": "大同市",
                "text": "浑源县",
                "level": 3,
                "order": 428,
                "relation_code": "140225",
                "relation_desc": "山西省浑源县"
            },
            {
                "code": "040210",
                "parentcode": "0402",
                "parenttext": "大同市",
                "text": "左云县",
                "level": 3,
                "order": 429,
                "relation_code": "140226",
                "relation_desc": "山西省左云县"
            },
            {
                "code": "040211",
                "parentcode": "0402",
                "parenttext": "大同市",
                "text": "云州区",
                "level": 3,
                "order": 430,
                "relation_code": "140215",
                "relation_desc": "山西省大同市云州区"
            },
            {
                "code": "0403",
                "parentcode": "04",
                "parenttext": "山西省",
                "text": "阳泉市",
                "level": 2,
                "order": 431,
                "relation_code": "140300",
                "relation_desc": "山西省阳泉市"
            },
            {
                "code": "040212",
                "parentcode": "0402",
                "parenttext": "大同市",
                "text": "城区",
                "level": 3,
                "order": 431,
                "relation_code": "140211",
                "relation_desc": "山西省大同市城区"
            },
            {
                "code": "040301",
                "parentcode": "0403",
                "parenttext": "阳泉市",
                "text": "城区",
                "level": 3,
                "order": 432,
                "relation_code": "140302",
                "relation_desc": "山西省阳泉市城区"
            },
            {
                "code": "040213",
                "parentcode": "0402",
                "parenttext": "大同市",
                "text": "矿区",
                "level": 3,
                "order": 432,
                "relation_code": "140211",
                "relation_desc": "山西省大同市矿区"
            },
            {
                "code": "040302",
                "parentcode": "0403",
                "parenttext": "阳泉市",
                "text": "矿区",
                "level": 3,
                "order": 433,
                "relation_code": "140303",
                "relation_desc": "山西省阳泉市矿区"
            },
            {
                "code": "040214",
                "parentcode": "0402",
                "parenttext": "大同市",
                "text": "南郊区",
                "level": 3,
                "order": 433,
                "relation_code": "140211",
                "relation_desc": "山西省大同市南郊区"
            },
            {
                "code": "040303",
                "parentcode": "0403",
                "parenttext": "阳泉市",
                "text": "郊区",
                "level": 3,
                "order": 434,
                "relation_code": "140311",
                "relation_desc": "山西省阳泉市郊区"
            },
            {
                "code": "040215",
                "parentcode": "0402",
                "parenttext": "大同市",
                "text": "大同县",
                "level": 3,
                "order": 434,
                "relation_code": "140211",
                "relation_desc": "山西省大同县"
            },
            {
                "code": "040304",
                "parentcode": "0403",
                "parenttext": "阳泉市",
                "text": "平定县",
                "level": 3,
                "order": 435,
                "relation_code": "140321",
                "relation_desc": "山西省平定县"
            },
            {
                "code": "040216",
                "parentcode": "0402",
                "parenttext": "大同市",
                "text": "山西大同经济开发区",
                "level": 3,
                "order": 435,
                "relation_code": "140271",
                "relation_desc": "山西省山西大同经济开发区"
            },
            {
                "code": "040305",
                "parentcode": "0403",
                "parenttext": "阳泉市",
                "text": "盂县",
                "level": 3,
                "order": 436,
                "relation_code": "140322",
                "relation_desc": "山西省盂县"
            },
            {
                "code": "0404",
                "parentcode": "04",
                "parenttext": "山西省",
                "text": "长治市",
                "level": 2,
                "order": 437,
                "relation_code": "140400",
                "relation_desc": "山西省长治市"
            },
            {
                "code": "040401",
                "parentcode": "0404",
                "parenttext": "长治市",
                "text": "城区",
                "level": 3,
                "order": 438,
                "relation_code": "140421",
                "relation_desc": "山西省长治市城区"
            },
            {
                "code": "040402",
                "parentcode": "0404",
                "parenttext": "长治市",
                "text": "郊区",
                "level": 3,
                "order": 439,
                "relation_code": "140421",
                "relation_desc": "山西省长治市郊区"
            },
            {
                "code": "040403",
                "parentcode": "0404",
                "parenttext": "长治市",
                "text": "长治县",
                "level": 3,
                "order": 440,
                "relation_code": "140421",
                "relation_desc": "山西省长治县"
            },
            {
                "code": "040404",
                "parentcode": "0404",
                "parenttext": "长治市",
                "text": "襄垣县",
                "level": 3,
                "order": 441,
                "relation_code": "140423",
                "relation_desc": "山西省襄垣县"
            },
            {
                "code": "040405",
                "parentcode": "0404",
                "parenttext": "长治市",
                "text": "屯留县",
                "level": 3,
                "order": 442,
                "relation_code": "140421",
                "relation_desc": "山西省屯留县"
            },
            {
                "code": "040406",
                "parentcode": "0404",
                "parenttext": "长治市",
                "text": "平顺县",
                "level": 3,
                "order": 443,
                "relation_code": "140425",
                "relation_desc": "山西省平顺县"
            },
            {
                "code": "040407",
                "parentcode": "0404",
                "parenttext": "长治市",
                "text": "黎城县",
                "level": 3,
                "order": 444,
                "relation_code": "140426",
                "relation_desc": "山西省黎城县"
            },
            {
                "code": "040408",
                "parentcode": "0404",
                "parenttext": "长治市",
                "text": "壶关县",
                "level": 3,
                "order": 445,
                "relation_code": "140427",
                "relation_desc": "山西省壶关县"
            },
            {
                "code": "040409",
                "parentcode": "0404",
                "parenttext": "长治市",
                "text": "长子县",
                "level": 3,
                "order": 446,
                "relation_code": "140428",
                "relation_desc": "山西省长子县"
            },
            {
                "code": "040410",
                "parentcode": "0404",
                "parenttext": "长治市",
                "text": "武乡县",
                "level": 3,
                "order": 447,
                "relation_code": "140429",
                "relation_desc": "山西省武乡县"
            },
            {
                "code": "040411",
                "parentcode": "0404",
                "parenttext": "长治市",
                "text": "沁县",
                "level": 3,
                "order": 448,
                "relation_code": "140430",
                "relation_desc": "山西省沁县"
            },
            {
                "code": "040412",
                "parentcode": "0404",
                "parenttext": "长治市",
                "text": "沁源县",
                "level": 3,
                "order": 449,
                "relation_code": "140431",
                "relation_desc": "山西省沁源县"
            },
            {
                "code": "040413",
                "parentcode": "0404",
                "parenttext": "长治市",
                "text": "潞城区",
                "level": 3,
                "order": 450,
                "relation_code": "140406",
                "relation_desc": "山西省长治市潞城区"
            },
            {
                "code": "0405",
                "parentcode": "04",
                "parenttext": "山西省",
                "text": "晋城市",
                "level": 2,
                "order": 451,
                "relation_code": "140500",
                "relation_desc": "山西省晋城市"
            },
            {
                "code": "040414",
                "parentcode": "0404",
                "parenttext": "长治市",
                "text": "潞州区",
                "level": 3,
                "order": 451,
                "relation_code": "140403",
                "relation_desc": "山西省长治市潞州区"
            },
            {
                "code": "040501",
                "parentcode": "0405",
                "parenttext": "晋城市",
                "text": "晋城市城区",
                "level": 3,
                "order": 452,
                "relation_code": "140502",
                "relation_desc": "山西省晋城市城区"
            },
            {
                "code": "040415",
                "parentcode": "0404",
                "parenttext": "长治市",
                "text": "上党区",
                "level": 3,
                "order": 452,
                "relation_code": "140404",
                "relation_desc": "山西省长治市上党区"
            },
            {
                "code": "040502",
                "parentcode": "0405",
                "parenttext": "晋城市",
                "text": "沁水县",
                "level": 3,
                "order": 453,
                "relation_code": "140521",
                "relation_desc": "山西省沁水县"
            },
            {
                "code": "040416",
                "parentcode": "0404",
                "parenttext": "长治市",
                "text": "屯留区",
                "level": 3,
                "order": 453,
                "relation_code": "140405",
                "relation_desc": "山西省长治市屯留区"
            },
            {
                "code": "040503",
                "parentcode": "0405",
                "parenttext": "晋城市",
                "text": "阳城县",
                "level": 3,
                "order": 454,
                "relation_code": "140522",
                "relation_desc": "山西省阳城县"
            },
            {
                "code": "040504",
                "parentcode": "0405",
                "parenttext": "晋城市",
                "text": "陵川县",
                "level": 3,
                "order": 455,
                "relation_code": "140524",
                "relation_desc": "山西省陵川县"
            },
            {
                "code": "040418",
                "parentcode": "0404",
                "parenttext": "长治市",
                "text": "山西长治高新技术产业园区",
                "level": 3,
                "order": 455,
                "relation_code": "140471",
                "relation_desc": "山西省山西长治高新技术产业园区"
            },
            {
                "code": "040505",
                "parentcode": "0405",
                "parenttext": "晋城市",
                "text": "泽州县",
                "level": 3,
                "order": 456,
                "relation_code": "140525",
                "relation_desc": "山西省泽州县"
            },
            {
                "code": "040506",
                "parentcode": "0405",
                "parenttext": "晋城市",
                "text": "高平市",
                "level": 3,
                "order": 457,
                "relation_code": "140581",
                "relation_desc": "山西省高平市"
            },
            {
                "code": "0406",
                "parentcode": "04",
                "parenttext": "山西省",
                "text": "朔州市",
                "level": 2,
                "order": 458,
                "relation_code": "140600",
                "relation_desc": "山西省朔州市"
            },
            {
                "code": "040601",
                "parentcode": "0406",
                "parenttext": "朔州市",
                "text": "朔城区",
                "level": 3,
                "order": 459,
                "relation_code": "140602",
                "relation_desc": "山西省朔州市朔城区"
            },
            {
                "code": "040602",
                "parentcode": "0406",
                "parenttext": "朔州市",
                "text": "平鲁区",
                "level": 3,
                "order": 460,
                "relation_code": "140603",
                "relation_desc": "山西省朔州市平鲁区"
            },
            {
                "code": "040603",
                "parentcode": "0406",
                "parenttext": "朔州市",
                "text": "山阴县",
                "level": 3,
                "order": 461,
                "relation_code": "140621",
                "relation_desc": "山西省山阴县"
            },
            {
                "code": "040604",
                "parentcode": "0406",
                "parenttext": "朔州市",
                "text": "应县",
                "level": 3,
                "order": 462,
                "relation_code": "140622",
                "relation_desc": "山西省应县"
            },
            {
                "code": "040605",
                "parentcode": "0406",
                "parenttext": "朔州市",
                "text": "右玉县",
                "level": 3,
                "order": 463,
                "relation_code": "140623",
                "relation_desc": "山西省右玉县"
            },
            {
                "code": "040606",
                "parentcode": "0406",
                "parenttext": "朔州市",
                "text": "怀仁县",
                "level": 3,
                "order": 464,
                "relation_code": "140601",
                "relation_desc": "山西省怀仁县"
            },
            {
                "code": "0407",
                "parentcode": "04",
                "parenttext": "山西省",
                "text": "晋中市",
                "level": 2,
                "order": 465,
                "relation_code": "140700",
                "relation_desc": "山西省晋中市"
            },
            {
                "code": "040607",
                "parentcode": "0406",
                "parenttext": "朔州市",
                "text": "山西朔州经济开发区",
                "level": 3,
                "order": 465,
                "relation_code": "140671",
                "relation_desc": "山西省山西朔州经济开发区"
            },
            {
                "code": "040701",
                "parentcode": "0407",
                "parenttext": "晋中市",
                "text": "榆次区",
                "level": 3,
                "order": 466,
                "relation_code": "140702",
                "relation_desc": "山西省晋中市榆次区"
            },
            {
                "code": "040608",
                "parentcode": "0406",
                "parenttext": "朔州市",
                "text": "怀仁市",
                "level": 3,
                "order": 466,
                "relation_code": "140681",
                "relation_desc": "山西省怀仁市"
            },
            {
                "code": "040702",
                "parentcode": "0407",
                "parenttext": "晋中市",
                "text": "榆社县",
                "level": 3,
                "order": 467,
                "relation_code": "140721",
                "relation_desc": "山西省榆社县"
            },
            {
                "code": "040703",
                "parentcode": "0407",
                "parenttext": "晋中市",
                "text": "左权县",
                "level": 3,
                "order": 468,
                "relation_code": "140722",
                "relation_desc": "山西省左权县"
            },
            {
                "code": "040704",
                "parentcode": "0407",
                "parenttext": "晋中市",
                "text": "和顺县",
                "level": 3,
                "order": 469,
                "relation_code": "140723",
                "relation_desc": "山西省和顺县"
            },
            {
                "code": "040705",
                "parentcode": "0407",
                "parenttext": "晋中市",
                "text": "昔阳县",
                "level": 3,
                "order": 470,
                "relation_code": "140724",
                "relation_desc": "山西省昔阳县"
            },
            {
                "code": "040706",
                "parentcode": "0407",
                "parenttext": "晋中市",
                "text": "寿阳县",
                "level": 3,
                "order": 471,
                "relation_code": "140725",
                "relation_desc": "山西省寿阳县"
            },
            {
                "code": "040707",
                "parentcode": "0407",
                "parenttext": "晋中市",
                "text": "太谷区",
                "level": 3,
                "order": 472,
                "relation_code": "140703",
                "relation_desc": "山西省晋中市太谷区"
            },
            {
                "code": "040708",
                "parentcode": "0407",
                "parenttext": "晋中市",
                "text": "祁县",
                "level": 3,
                "order": 473,
                "relation_code": "140727",
                "relation_desc": "山西省祁县"
            },
            {
                "code": "040709",
                "parentcode": "0407",
                "parenttext": "晋中市",
                "text": "平遥县",
                "level": 3,
                "order": 474,
                "relation_code": "140728",
                "relation_desc": "山西省平遥县"
            },
            {
                "code": "040710",
                "parentcode": "0407",
                "parenttext": "晋中市",
                "text": "灵石县",
                "level": 3,
                "order": 475,
                "relation_code": "140729",
                "relation_desc": "山西省灵石县"
            },
            {
                "code": "040711",
                "parentcode": "0407",
                "parenttext": "晋中市",
                "text": "介休市",
                "level": 3,
                "order": 476,
                "relation_code": "140781",
                "relation_desc": "山西省介休市"
            },
            {
                "code": "0408",
                "parentcode": "04",
                "parenttext": "山西省",
                "text": "运城市",
                "level": 2,
                "order": 477,
                "relation_code": "140800",
                "relation_desc": "山西省运城市"
            },
            {
                "code": "040801",
                "parentcode": "0408",
                "parenttext": "运城市",
                "text": "盐湖区",
                "level": 3,
                "order": 478,
                "relation_code": "140802",
                "relation_desc": "山西省运城市盐湖区"
            },
            {
                "code": "040802",
                "parentcode": "0408",
                "parenttext": "运城市",
                "text": "临猗县",
                "level": 3,
                "order": 479,
                "relation_code": "140821",
                "relation_desc": "山西省临猗县"
            },
            {
                "code": "040803",
                "parentcode": "0408",
                "parenttext": "运城市",
                "text": "万荣县",
                "level": 3,
                "order": 480,
                "relation_code": "140822",
                "relation_desc": "山西省万荣县"
            },
            {
                "code": "040804",
                "parentcode": "0408",
                "parenttext": "运城市",
                "text": "闻喜县",
                "level": 3,
                "order": 481,
                "relation_code": "140823",
                "relation_desc": "山西省闻喜县"
            },
            {
                "code": "040805",
                "parentcode": "0408",
                "parenttext": "运城市",
                "text": "稷山县",
                "level": 3,
                "order": 482,
                "relation_code": "140824",
                "relation_desc": "山西省稷山县"
            },
            {
                "code": "040806",
                "parentcode": "0408",
                "parenttext": "运城市",
                "text": "新绛县",
                "level": 3,
                "order": 483,
                "relation_code": "140825",
                "relation_desc": "山西省新绛县"
            },
            {
                "code": "040807",
                "parentcode": "0408",
                "parenttext": "运城市",
                "text": "绛县",
                "level": 3,
                "order": 484,
                "relation_code": "140826",
                "relation_desc": "山西省绛县"
            },
            {
                "code": "040808",
                "parentcode": "0408",
                "parenttext": "运城市",
                "text": "垣曲县",
                "level": 3,
                "order": 485,
                "relation_code": "140827",
                "relation_desc": "山西省垣曲县"
            },
            {
                "code": "040809",
                "parentcode": "0408",
                "parenttext": "运城市",
                "text": "夏县",
                "level": 3,
                "order": 486,
                "relation_code": "140828",
                "relation_desc": "山西省夏县"
            },
            {
                "code": "040810",
                "parentcode": "0408",
                "parenttext": "运城市",
                "text": "平陆县",
                "level": 3,
                "order": 487,
                "relation_code": "140829",
                "relation_desc": "山西省平陆县"
            },
            {
                "code": "040811",
                "parentcode": "0408",
                "parenttext": "运城市",
                "text": "芮城县",
                "level": 3,
                "order": 488,
                "relation_code": "140830",
                "relation_desc": "山西省芮城县"
            },
            {
                "code": "040812",
                "parentcode": "0408",
                "parenttext": "运城市",
                "text": "永济市",
                "level": 3,
                "order": 489,
                "relation_code": "140881",
                "relation_desc": "山西省永济市"
            },
            {
                "code": "040813",
                "parentcode": "0408",
                "parenttext": "运城市",
                "text": "河津市",
                "level": 3,
                "order": 490,
                "relation_code": "140882",
                "relation_desc": "山西省河津市"
            },
            {
                "code": "0409",
                "parentcode": "04",
                "parenttext": "山西省",
                "text": "忻州市",
                "level": 2,
                "order": 491,
                "relation_code": "140900",
                "relation_desc": "山西省忻州市"
            },
            {
                "code": "040901",
                "parentcode": "0409",
                "parenttext": "忻州市",
                "text": "忻府区",
                "level": 3,
                "order": 492,
                "relation_code": "140902",
                "relation_desc": "山西省忻州市忻府区"
            },
            {
                "code": "040902",
                "parentcode": "0409",
                "parenttext": "忻州市",
                "text": "定襄县",
                "level": 3,
                "order": 493,
                "relation_code": "140921",
                "relation_desc": "山西省定襄县"
            },
            {
                "code": "040903",
                "parentcode": "0409",
                "parenttext": "忻州市",
                "text": "五台县",
                "level": 3,
                "order": 494,
                "relation_code": "140922",
                "relation_desc": "山西省五台县"
            },
            {
                "code": "040904",
                "parentcode": "0409",
                "parenttext": "忻州市",
                "text": "代县",
                "level": 3,
                "order": 495,
                "relation_code": "140923",
                "relation_desc": "山西省代县"
            },
            {
                "code": "040905",
                "parentcode": "0409",
                "parenttext": "忻州市",
                "text": "繁峙县",
                "level": 3,
                "order": 496,
                "relation_code": "140924",
                "relation_desc": "山西省繁峙县"
            },
            {
                "code": "040906",
                "parentcode": "0409",
                "parenttext": "忻州市",
                "text": "宁武县",
                "level": 3,
                "order": 497,
                "relation_code": "140925",
                "relation_desc": "山西省宁武县"
            },
            {
                "code": "040907",
                "parentcode": "0409",
                "parenttext": "忻州市",
                "text": "静乐县",
                "level": 3,
                "order": 498,
                "relation_code": "140926",
                "relation_desc": "山西省静乐县"
            },
            {
                "code": "040908",
                "parentcode": "0409",
                "parenttext": "忻州市",
                "text": "神池县",
                "level": 3,
                "order": 499,
                "relation_code": "140927",
                "relation_desc": "山西省神池县"
            },
            {
                "code": "040909",
                "parentcode": "0409",
                "parenttext": "忻州市",
                "text": "五寨县",
                "level": 3,
                "order": 500,
                "relation_code": "140928",
                "relation_desc": "山西省五寨县"
            },
            {
                "code": "040910",
                "parentcode": "0409",
                "parenttext": "忻州市",
                "text": "岢岚县",
                "level": 3,
                "order": 501,
                "relation_code": "140929",
                "relation_desc": "山西省岢岚县"
            },
            {
                "code": "040911",
                "parentcode": "0409",
                "parenttext": "忻州市",
                "text": "河曲县",
                "level": 3,
                "order": 502,
                "relation_code": "140930",
                "relation_desc": "山西省河曲县"
            },
            {
                "code": "040912",
                "parentcode": "0409",
                "parenttext": "忻州市",
                "text": "保德县",
                "level": 3,
                "order": 503,
                "relation_code": "140931",
                "relation_desc": "山西省保德县"
            },
            {
                "code": "040913",
                "parentcode": "0409",
                "parenttext": "忻州市",
                "text": "偏关县",
                "level": 3,
                "order": 504,
                "relation_code": "140932",
                "relation_desc": "山西省偏关县"
            },
            {
                "code": "040914",
                "parentcode": "0409",
                "parenttext": "忻州市",
                "text": "原平市",
                "level": 3,
                "order": 505,
                "relation_code": "140981",
                "relation_desc": "山西省原平市"
            },
            {
                "code": "0410",
                "parentcode": "04",
                "parenttext": "山西省",
                "text": "临汾市",
                "level": 2,
                "order": 506,
                "relation_code": "141000",
                "relation_desc": "山西省临汾市"
            },
            {
                "code": "040915",
                "parentcode": "0409",
                "parenttext": "忻州市",
                "text": "五台山风景名胜区",
                "level": 3,
                "order": 506,
                "relation_code": "140901",
                "relation_desc": "山西省忻州市"
            },
            {
                "code": "041001",
                "parentcode": "0410",
                "parenttext": "临汾市",
                "text": "尧都区",
                "level": 3,
                "order": 507,
                "relation_code": "141002",
                "relation_desc": "山西省临汾市尧都区"
            },
            {
                "code": "041002",
                "parentcode": "0410",
                "parenttext": "临汾市",
                "text": "曲沃县",
                "level": 3,
                "order": 508,
                "relation_code": "141021",
                "relation_desc": "山西省曲沃县"
            },
            {
                "code": "041003",
                "parentcode": "0410",
                "parenttext": "临汾市",
                "text": "翼城县",
                "level": 3,
                "order": 509,
                "relation_code": "141022",
                "relation_desc": "山西省翼城县"
            },
            {
                "code": "041004",
                "parentcode": "0410",
                "parenttext": "临汾市",
                "text": "襄汾县",
                "level": 3,
                "order": 510,
                "relation_code": "141023",
                "relation_desc": "山西省襄汾县"
            },
            {
                "code": "041005",
                "parentcode": "0410",
                "parenttext": "临汾市",
                "text": "洪洞县",
                "level": 3,
                "order": 511,
                "relation_code": "141024",
                "relation_desc": "山西省洪洞县"
            },
            {
                "code": "041006",
                "parentcode": "0410",
                "parenttext": "临汾市",
                "text": "古县",
                "level": 3,
                "order": 512,
                "relation_code": "141025",
                "relation_desc": "山西省古县"
            },
            {
                "code": "041007",
                "parentcode": "0410",
                "parenttext": "临汾市",
                "text": "安泽县",
                "level": 3,
                "order": 513,
                "relation_code": "141026",
                "relation_desc": "山西省安泽县"
            },
            {
                "code": "041008",
                "parentcode": "0410",
                "parenttext": "临汾市",
                "text": "浮山县",
                "level": 3,
                "order": 514,
                "relation_code": "141027",
                "relation_desc": "山西省浮山县"
            },
            {
                "code": "041009",
                "parentcode": "0410",
                "parenttext": "临汾市",
                "text": "吉县",
                "level": 3,
                "order": 515,
                "relation_code": "141028",
                "relation_desc": "山西省吉县"
            },
            {
                "code": "041010",
                "parentcode": "0410",
                "parenttext": "临汾市",
                "text": "乡宁县",
                "level": 3,
                "order": 516,
                "relation_code": "141029",
                "relation_desc": "山西省乡宁县"
            },
            {
                "code": "041011",
                "parentcode": "0410",
                "parenttext": "临汾市",
                "text": "大宁县",
                "level": 3,
                "order": 517,
                "relation_code": "141030",
                "relation_desc": "山西省大宁县"
            },
            {
                "code": "041012",
                "parentcode": "0410",
                "parenttext": "临汾市",
                "text": "隰县",
                "level": 3,
                "order": 518,
                "relation_code": "141031",
                "relation_desc": "山西省隰县"
            },
            {
                "code": "041013",
                "parentcode": "0410",
                "parenttext": "临汾市",
                "text": "永和县",
                "level": 3,
                "order": 519,
                "relation_code": "141032",
                "relation_desc": "山西省永和县"
            },
            {
                "code": "041014",
                "parentcode": "0410",
                "parenttext": "临汾市",
                "text": "蒲县",
                "level": 3,
                "order": 520,
                "relation_code": "141033",
                "relation_desc": "山西省蒲县"
            },
            {
                "code": "041015",
                "parentcode": "0410",
                "parenttext": "临汾市",
                "text": "汾西县",
                "level": 3,
                "order": 521,
                "relation_code": "141034",
                "relation_desc": "山西省汾西县"
            },
            {
                "code": "041016",
                "parentcode": "0410",
                "parenttext": "临汾市",
                "text": "侯马市",
                "level": 3,
                "order": 522,
                "relation_code": "141081",
                "relation_desc": "山西省侯马市"
            },
            {
                "code": "041017",
                "parentcode": "0410",
                "parenttext": "临汾市",
                "text": "霍州市",
                "level": 3,
                "order": 523,
                "relation_code": "141082",
                "relation_desc": "山西省霍州市"
            },
            {
                "code": "0411",
                "parentcode": "04",
                "parenttext": "山西省",
                "text": "吕梁市",
                "level": 2,
                "order": 524,
                "relation_code": "141100",
                "relation_desc": "山西省吕梁市"
            },
            {
                "code": "041101",
                "parentcode": "0411",
                "parenttext": "吕梁市",
                "text": "离石区",
                "level": 3,
                "order": 525,
                "relation_code": "141102",
                "relation_desc": "山西省吕梁市离石区"
            },
            {
                "code": "041102",
                "parentcode": "0411",
                "parenttext": "吕梁市",
                "text": "文水县",
                "level": 3,
                "order": 526,
                "relation_code": "141121",
                "relation_desc": "山西省文水县"
            },
            {
                "code": "041103",
                "parentcode": "0411",
                "parenttext": "吕梁市",
                "text": "交城县",
                "level": 3,
                "order": 527,
                "relation_code": "141122",
                "relation_desc": "山西省交城县"
            },
            {
                "code": "041104",
                "parentcode": "0411",
                "parenttext": "吕梁市",
                "text": "兴县",
                "level": 3,
                "order": 528,
                "relation_code": "141123",
                "relation_desc": "山西省兴县"
            },
            {
                "code": "041105",
                "parentcode": "0411",
                "parenttext": "吕梁市",
                "text": "临县",
                "level": 3,
                "order": 529,
                "relation_code": "141124",
                "relation_desc": "山西省临县"
            },
            {
                "code": "041106",
                "parentcode": "0411",
                "parenttext": "吕梁市",
                "text": "柳林县",
                "level": 3,
                "order": 530,
                "relation_code": "141125",
                "relation_desc": "山西省柳林县"
            },
            {
                "code": "041107",
                "parentcode": "0411",
                "parenttext": "吕梁市",
                "text": "石楼县",
                "level": 3,
                "order": 531,
                "relation_code": "141126",
                "relation_desc": "山西省石楼县"
            },
            {
                "code": "041108",
                "parentcode": "0411",
                "parenttext": "吕梁市",
                "text": "岚县",
                "level": 3,
                "order": 532,
                "relation_code": "141127",
                "relation_desc": "山西省岚县"
            },
            {
                "code": "041109",
                "parentcode": "0411",
                "parenttext": "吕梁市",
                "text": "方山县",
                "level": 3,
                "order": 533,
                "relation_code": "141128",
                "relation_desc": "山西省方山县"
            },
            {
                "code": "041110",
                "parentcode": "0411",
                "parenttext": "吕梁市",
                "text": "中阳县",
                "level": 3,
                "order": 534,
                "relation_code": "141129",
                "relation_desc": "山西省中阳县"
            },
            {
                "code": "041111",
                "parentcode": "0411",
                "parenttext": "吕梁市",
                "text": "交口县",
                "level": 3,
                "order": 535,
                "relation_code": "141130",
                "relation_desc": "山西省交口县"
            },
            {
                "code": "041112",
                "parentcode": "0411",
                "parenttext": "吕梁市",
                "text": "孝义市",
                "level": 3,
                "order": 536,
                "relation_code": "141181",
                "relation_desc": "山西省孝义市"
            },
            {
                "code": "041113",
                "parentcode": "0411",
                "parenttext": "吕梁市",
                "text": "汾阳市",
                "level": 3,
                "order": 537,
                "relation_code": "141182",
                "relation_desc": "山西省汾阳市"
            },
            {
                "code": "05",
                "parentcode": "0",
                "parenttext": "0",
                "text": "内蒙古自治区",
                "level": 1,
                "order": 538,
                "relation_code": "",
                "relation_desc": "内蒙古自治区"
            },
            {
                "code": "0501",
                "parentcode": "05",
                "parenttext": "内蒙古自治区",
                "text": "呼和浩特市",
                "level": 2,
                "order": 539,
                "relation_code": "150100",
                "relation_desc": "内蒙古呼和浩特市"
            },
            {
                "code": "050101",
                "parentcode": "0501",
                "parenttext": "呼和浩特市",
                "text": "新城区",
                "level": 3,
                "order": 540,
                "relation_code": "150102",
                "relation_desc": "内蒙古呼和浩特市新城区"
            },
            {
                "code": "050102",
                "parentcode": "0501",
                "parenttext": "呼和浩特市",
                "text": "回民区",
                "level": 3,
                "order": 541,
                "relation_code": "150103",
                "relation_desc": "内蒙古呼和浩特市回民区"
            },
            {
                "code": "050103",
                "parentcode": "0501",
                "parenttext": "呼和浩特市",
                "text": "玉泉区",
                "level": 3,
                "order": 542,
                "relation_code": "150104",
                "relation_desc": "内蒙古呼和浩特市玉泉区"
            },
            {
                "code": "050104",
                "parentcode": "0501",
                "parenttext": "呼和浩特市",
                "text": "赛罕区",
                "level": 3,
                "order": 543,
                "relation_code": "150105",
                "relation_desc": "内蒙古呼和浩特市赛罕区"
            },
            {
                "code": "050105",
                "parentcode": "0501",
                "parenttext": "呼和浩特市",
                "text": "土默特左旗",
                "level": 3,
                "order": 544,
                "relation_code": "150121",
                "relation_desc": "内蒙古土默特左旗"
            },
            {
                "code": "050106",
                "parentcode": "0501",
                "parenttext": "呼和浩特市",
                "text": "托克托县",
                "level": 3,
                "order": 545,
                "relation_code": "150122",
                "relation_desc": "内蒙古托克托县"
            },
            {
                "code": "050107",
                "parentcode": "0501",
                "parenttext": "呼和浩特市",
                "text": "和林格尔县",
                "level": 3,
                "order": 546,
                "relation_code": "150123",
                "relation_desc": "内蒙古和林格尔县"
            },
            {
                "code": "050108",
                "parentcode": "0501",
                "parenttext": "呼和浩特市",
                "text": "清水河县",
                "level": 3,
                "order": 547,
                "relation_code": "150124",
                "relation_desc": "内蒙古清水河县"
            },
            {
                "code": "050109",
                "parentcode": "0501",
                "parenttext": "呼和浩特市",
                "text": "武川县",
                "level": 3,
                "order": 548,
                "relation_code": "150125",
                "relation_desc": "内蒙古武川县"
            },
            {
                "code": "0502",
                "parentcode": "05",
                "parenttext": "内蒙古自治区",
                "text": "包头市",
                "level": 2,
                "order": 549,
                "relation_code": "150200",
                "relation_desc": "内蒙古包头市"
            },
            {
                "code": "050110",
                "parentcode": "0501",
                "parenttext": "呼和浩特市",
                "text": "呼和浩特金海工业园区",
                "level": 3,
                "order": 549,
                "relation_code": "150101",
                "relation_desc": "内蒙古呼和浩特市"
            },
            {
                "code": "050201",
                "parentcode": "0502",
                "parenttext": "包头市",
                "text": "东河区",
                "level": 3,
                "order": 550,
                "relation_code": "150202",
                "relation_desc": "内蒙古包头市东河区"
            },
            {
                "code": "050111",
                "parentcode": "0501",
                "parenttext": "呼和浩特市",
                "text": "呼和浩特经济技术开发区",
                "level": 3,
                "order": 550,
                "relation_code": "150101",
                "relation_desc": "内蒙古呼和浩特市"
            },
            {
                "code": "050202",
                "parentcode": "0502",
                "parenttext": "包头市",
                "text": "昆都仑区",
                "level": 3,
                "order": 551,
                "relation_code": "150203",
                "relation_desc": "内蒙古包头市昆都仑区"
            },
            {
                "code": "050203",
                "parentcode": "0502",
                "parenttext": "包头市",
                "text": "青山区",
                "level": 3,
                "order": 552,
                "relation_code": "150204",
                "relation_desc": "内蒙古包头市青山区"
            },
            {
                "code": "050204",
                "parentcode": "0502",
                "parenttext": "包头市",
                "text": "石拐区",
                "level": 3,
                "order": 553,
                "relation_code": "150205",
                "relation_desc": "内蒙古包头市石拐区"
            },
            {
                "code": "050205",
                "parentcode": "0502",
                "parenttext": "包头市",
                "text": "白云矿区",
                "level": 3,
                "order": 554,
                "relation_code": "150206",
                "relation_desc": "内蒙古包头市白云鄂博矿区"
            },
            {
                "code": "050206",
                "parentcode": "0502",
                "parenttext": "包头市",
                "text": "九原区",
                "level": 3,
                "order": 555,
                "relation_code": "150207",
                "relation_desc": "内蒙古包头市九原区"
            },
            {
                "code": "050207",
                "parentcode": "0502",
                "parenttext": "包头市",
                "text": "土默特右旗",
                "level": 3,
                "order": 556,
                "relation_code": "150221",
                "relation_desc": "内蒙古土默特右旗"
            },
            {
                "code": "050208",
                "parentcode": "0502",
                "parenttext": "包头市",
                "text": "固阳县",
                "level": 3,
                "order": 557,
                "relation_code": "150222",
                "relation_desc": "内蒙古固阳县"
            },
            {
                "code": "050209",
                "parentcode": "0502",
                "parenttext": "包头市",
                "text": "达尔罕茂明安联合旗",
                "level": 3,
                "order": 558,
                "relation_code": "150223",
                "relation_desc": "内蒙古达尔罕茂明安联合旗"
            },
            {
                "code": "0503",
                "parentcode": "05",
                "parenttext": "内蒙古自治区",
                "text": "乌海市",
                "level": 2,
                "order": 559,
                "relation_code": "150300",
                "relation_desc": "内蒙古乌海市"
            },
            {
                "code": "050301",
                "parentcode": "0503",
                "parenttext": "乌海市",
                "text": "海勃湾区",
                "level": 3,
                "order": 560,
                "relation_code": "150302",
                "relation_desc": "内蒙古乌海市海勃湾区"
            },
            {
                "code": "050302",
                "parentcode": "0503",
                "parenttext": "乌海市",
                "text": "海南区",
                "level": 3,
                "order": 561,
                "relation_code": "150303",
                "relation_desc": "内蒙古乌海市海南区"
            },
            {
                "code": "050303",
                "parentcode": "0503",
                "parenttext": "乌海市",
                "text": "乌达区",
                "level": 3,
                "order": 562,
                "relation_code": "150304",
                "relation_desc": "内蒙古乌海市乌达区"
            },
            {
                "code": "0504",
                "parentcode": "05",
                "parenttext": "内蒙古自治区",
                "text": "赤峰市",
                "level": 2,
                "order": 563,
                "relation_code": "150400",
                "relation_desc": "内蒙古赤峰市"
            },
            {
                "code": "050401",
                "parentcode": "0504",
                "parenttext": "赤峰市",
                "text": "红山区",
                "level": 3,
                "order": 564,
                "relation_code": "150402",
                "relation_desc": "内蒙古赤峰市红山区"
            },
            {
                "code": "050402",
                "parentcode": "0504",
                "parenttext": "赤峰市",
                "text": "元宝山区",
                "level": 3,
                "order": 565,
                "relation_code": "150403",
                "relation_desc": "内蒙古赤峰市元宝山区"
            },
            {
                "code": "050403",
                "parentcode": "0504",
                "parenttext": "赤峰市",
                "text": "松山区",
                "level": 3,
                "order": 566,
                "relation_code": "150404",
                "relation_desc": "内蒙古赤峰市松山区"
            },
            {
                "code": "050404",
                "parentcode": "0504",
                "parenttext": "赤峰市",
                "text": "阿鲁科尔沁旗",
                "level": 3,
                "order": 567,
                "relation_code": "150421",
                "relation_desc": "内蒙古阿鲁科尔沁旗"
            },
            {
                "code": "050405",
                "parentcode": "0504",
                "parenttext": "赤峰市",
                "text": "巴林左旗",
                "level": 3,
                "order": 568,
                "relation_code": "150422",
                "relation_desc": "内蒙古巴林左旗"
            },
            {
                "code": "050406",
                "parentcode": "0504",
                "parenttext": "赤峰市",
                "text": "巴林右旗",
                "level": 3,
                "order": 569,
                "relation_code": "150423",
                "relation_desc": "内蒙古巴林右旗"
            },
            {
                "code": "050407",
                "parentcode": "0504",
                "parenttext": "赤峰市",
                "text": "林西县",
                "level": 3,
                "order": 570,
                "relation_code": "150424",
                "relation_desc": "内蒙古林西县"
            },
            {
                "code": "050408",
                "parentcode": "0504",
                "parenttext": "赤峰市",
                "text": "克什克腾旗",
                "level": 3,
                "order": 571,
                "relation_code": "150425",
                "relation_desc": "内蒙古克什克腾旗"
            },
            {
                "code": "050409",
                "parentcode": "0504",
                "parenttext": "赤峰市",
                "text": "翁牛特旗",
                "level": 3,
                "order": 572,
                "relation_code": "150426",
                "relation_desc": "内蒙古翁牛特旗"
            },
            {
                "code": "050410",
                "parentcode": "0504",
                "parenttext": "赤峰市",
                "text": "喀喇沁旗",
                "level": 3,
                "order": 573,
                "relation_code": "150428",
                "relation_desc": "内蒙古喀喇沁旗"
            },
            {
                "code": "050411",
                "parentcode": "0504",
                "parenttext": "赤峰市",
                "text": "宁城县",
                "level": 3,
                "order": 574,
                "relation_code": "150429",
                "relation_desc": "内蒙古宁城县"
            },
            {
                "code": "050412",
                "parentcode": "0504",
                "parenttext": "赤峰市",
                "text": "敖汉旗",
                "level": 3,
                "order": 575,
                "relation_code": "150430",
                "relation_desc": "内蒙古敖汉旗"
            },
            {
                "code": "0505",
                "parentcode": "05",
                "parenttext": "内蒙古自治区",
                "text": "通辽市",
                "level": 2,
                "order": 576,
                "relation_code": "150500",
                "relation_desc": "内蒙古通辽市"
            },
            {
                "code": "050501",
                "parentcode": "0505",
                "parenttext": "通辽市",
                "text": "科尔沁区",
                "level": 3,
                "order": 577,
                "relation_code": "150502",
                "relation_desc": "内蒙古通辽市科尔沁区"
            },
            {
                "code": "050502",
                "parentcode": "0505",
                "parenttext": "通辽市",
                "text": "科尔沁左翼中旗",
                "level": 3,
                "order": 578,
                "relation_code": "150521",
                "relation_desc": "内蒙古科尔沁左翼中旗"
            },
            {
                "code": "050503",
                "parentcode": "0505",
                "parenttext": "通辽市",
                "text": "科尔沁左翼后旗",
                "level": 3,
                "order": 579,
                "relation_code": "150522",
                "relation_desc": "内蒙古科尔沁左翼后旗"
            },
            {
                "code": "050504",
                "parentcode": "0505",
                "parenttext": "通辽市",
                "text": "开鲁县",
                "level": 3,
                "order": 580,
                "relation_code": "150523",
                "relation_desc": "内蒙古开鲁县"
            },
            {
                "code": "050505",
                "parentcode": "0505",
                "parenttext": "通辽市",
                "text": "库伦旗",
                "level": 3,
                "order": 581,
                "relation_code": "150524",
                "relation_desc": "内蒙古库伦旗"
            },
            {
                "code": "050506",
                "parentcode": "0505",
                "parenttext": "通辽市",
                "text": "奈曼旗",
                "level": 3,
                "order": 582,
                "relation_code": "150525",
                "relation_desc": "内蒙古奈曼旗"
            },
            {
                "code": "050507",
                "parentcode": "0505",
                "parenttext": "通辽市",
                "text": "扎鲁特旗",
                "level": 3,
                "order": 583,
                "relation_code": "150526",
                "relation_desc": "内蒙古扎鲁特旗"
            },
            {
                "code": "050508",
                "parentcode": "0505",
                "parenttext": "通辽市",
                "text": "霍林郭勒市",
                "level": 3,
                "order": 584,
                "relation_code": "150581",
                "relation_desc": "内蒙古霍林郭勒市"
            },
            {
                "code": "0506",
                "parentcode": "05",
                "parenttext": "内蒙古自治区",
                "text": "鄂尔多斯市",
                "level": 2,
                "order": 585,
                "relation_code": "150600",
                "relation_desc": "内蒙古鄂尔多斯市"
            },
            {
                "code": "050509",
                "parentcode": "0505",
                "parenttext": "通辽市",
                "text": "通辽经济技术开发区",
                "level": 3,
                "order": 585,
                "relation_code": "150501",
                "relation_desc": "内蒙古通辽市"
            },
            {
                "code": "050601",
                "parentcode": "0506",
                "parenttext": "鄂尔多斯市",
                "text": "东胜区",
                "level": 3,
                "order": 586,
                "relation_code": "150602",
                "relation_desc": "内蒙古鄂尔多斯市东胜区"
            },
            {
                "code": "050602",
                "parentcode": "0506",
                "parenttext": "鄂尔多斯市",
                "text": "达拉特旗",
                "level": 3,
                "order": 587,
                "relation_code": "150621",
                "relation_desc": "内蒙古达拉特旗"
            },
            {
                "code": "050603",
                "parentcode": "0506",
                "parenttext": "鄂尔多斯市",
                "text": "准格尔旗",
                "level": 3,
                "order": 588,
                "relation_code": "150622",
                "relation_desc": "内蒙古准格尔旗"
            },
            {
                "code": "050604",
                "parentcode": "0506",
                "parenttext": "鄂尔多斯市",
                "text": "鄂托克前旗",
                "level": 3,
                "order": 589,
                "relation_code": "150623",
                "relation_desc": "内蒙古鄂托克前旗"
            },
            {
                "code": "050605",
                "parentcode": "0506",
                "parenttext": "鄂尔多斯市",
                "text": "鄂托克旗",
                "level": 3,
                "order": 590,
                "relation_code": "150624",
                "relation_desc": "内蒙古鄂托克旗"
            },
            {
                "code": "050606",
                "parentcode": "0506",
                "parenttext": "鄂尔多斯市",
                "text": "杭锦旗",
                "level": 3,
                "order": 591,
                "relation_code": "150625",
                "relation_desc": "内蒙古杭锦旗"
            },
            {
                "code": "050607",
                "parentcode": "0506",
                "parenttext": "鄂尔多斯市",
                "text": "乌审旗",
                "level": 3,
                "order": 592,
                "relation_code": "150626",
                "relation_desc": "内蒙古乌审旗"
            },
            {
                "code": "050608",
                "parentcode": "0506",
                "parenttext": "鄂尔多斯市",
                "text": "伊金霍洛旗",
                "level": 3,
                "order": 593,
                "relation_code": "150627",
                "relation_desc": "内蒙古伊金霍洛旗"
            },
            {
                "code": "0507",
                "parentcode": "05",
                "parenttext": "内蒙古自治区",
                "text": "呼伦贝尔市",
                "level": 2,
                "order": 594,
                "relation_code": "150700",
                "relation_desc": "内蒙古呼伦贝尔市"
            },
            {
                "code": "050701",
                "parentcode": "0507",
                "parenttext": "呼伦贝尔市",
                "text": "海拉尔区",
                "level": 3,
                "order": 595,
                "relation_code": "150702",
                "relation_desc": "内蒙古呼伦贝尔市海拉尔区"
            },
            {
                "code": "050702",
                "parentcode": "0507",
                "parenttext": "呼伦贝尔市",
                "text": "阿荣旗",
                "level": 3,
                "order": 596,
                "relation_code": "150721",
                "relation_desc": "内蒙古阿荣旗"
            },
            {
                "code": "050703",
                "parentcode": "0507",
                "parenttext": "呼伦贝尔市",
                "text": "莫力达瓦达斡尔族自治旗",
                "level": 3,
                "order": 597,
                "relation_code": "150722",
                "relation_desc": "内蒙古莫力达瓦达斡尔族自治旗"
            },
            {
                "code": "050704",
                "parentcode": "0507",
                "parenttext": "呼伦贝尔市",
                "text": "鄂伦春自治旗",
                "level": 3,
                "order": 598,
                "relation_code": "150723",
                "relation_desc": "内蒙古鄂伦春自治旗"
            },
            {
                "code": "050705",
                "parentcode": "0507",
                "parenttext": "呼伦贝尔市",
                "text": "鄂温克族自治旗",
                "level": 3,
                "order": 599,
                "relation_code": "150724",
                "relation_desc": "内蒙古鄂温克族自治旗"
            },
            {
                "code": "050706",
                "parentcode": "0507",
                "parenttext": "呼伦贝尔市",
                "text": "陈巴尔虎旗",
                "level": 3,
                "order": 600,
                "relation_code": "150725",
                "relation_desc": "内蒙古陈巴尔虎旗"
            },
            {
                "code": "050707",
                "parentcode": "0507",
                "parenttext": "呼伦贝尔市",
                "text": "新巴尔虎左旗",
                "level": 3,
                "order": 601,
                "relation_code": "150726",
                "relation_desc": "内蒙古新巴尔虎左旗"
            },
            {
                "code": "050708",
                "parentcode": "0507",
                "parenttext": "呼伦贝尔市",
                "text": "新巴尔虎右旗",
                "level": 3,
                "order": 602,
                "relation_code": "150727",
                "relation_desc": "内蒙古新巴尔虎右旗"
            },
            {
                "code": "050709",
                "parentcode": "0507",
                "parenttext": "呼伦贝尔市",
                "text": "满洲里市",
                "level": 3,
                "order": 603,
                "relation_code": "150781",
                "relation_desc": "内蒙古满洲里市"
            },
            {
                "code": "050710",
                "parentcode": "0507",
                "parenttext": "呼伦贝尔市",
                "text": "牙克石市",
                "level": 3,
                "order": 604,
                "relation_code": "150782",
                "relation_desc": "内蒙古牙克石市"
            },
            {
                "code": "050711",
                "parentcode": "0507",
                "parenttext": "呼伦贝尔市",
                "text": "扎兰屯市",
                "level": 3,
                "order": 605,
                "relation_code": "150783",
                "relation_desc": "内蒙古扎兰屯市"
            },
            {
                "code": "050712",
                "parentcode": "0507",
                "parenttext": "呼伦贝尔市",
                "text": "额尔古纳市",
                "level": 3,
                "order": 606,
                "relation_code": "150784",
                "relation_desc": "内蒙古额尔古纳市"
            },
            {
                "code": "050713",
                "parentcode": "0507",
                "parenttext": "呼伦贝尔市",
                "text": "根河市",
                "level": 3,
                "order": 607,
                "relation_code": "150785",
                "relation_desc": "内蒙古根河市"
            },
            {
                "code": "0508",
                "parentcode": "05",
                "parenttext": "内蒙古自治区",
                "text": "巴彦淖尔市",
                "level": 2,
                "order": 608,
                "relation_code": "150800",
                "relation_desc": "内蒙古巴彦淖尔市"
            },
            {
                "code": "050801",
                "parentcode": "0508",
                "parenttext": "巴彦淖尔市",
                "text": "临河区",
                "level": 3,
                "order": 609,
                "relation_code": "150802",
                "relation_desc": "内蒙古巴彦淖尔市临河区"
            },
            {
                "code": "050802",
                "parentcode": "0508",
                "parenttext": "巴彦淖尔市",
                "text": "五原县",
                "level": 3,
                "order": 610,
                "relation_code": "150821",
                "relation_desc": "内蒙古五原县"
            },
            {
                "code": "050803",
                "parentcode": "0508",
                "parenttext": "巴彦淖尔市",
                "text": "磴口县",
                "level": 3,
                "order": 611,
                "relation_code": "150822",
                "relation_desc": "内蒙古磴口县"
            },
            {
                "code": "050804",
                "parentcode": "0508",
                "parenttext": "巴彦淖尔市",
                "text": "乌拉特前旗",
                "level": 3,
                "order": 612,
                "relation_code": "150823",
                "relation_desc": "内蒙古乌拉特前旗"
            },
            {
                "code": "050805",
                "parentcode": "0508",
                "parenttext": "巴彦淖尔市",
                "text": "乌拉特中旗",
                "level": 3,
                "order": 613,
                "relation_code": "150824",
                "relation_desc": "内蒙古乌拉特中旗"
            },
            {
                "code": "050806",
                "parentcode": "0508",
                "parenttext": "巴彦淖尔市",
                "text": "乌拉特后旗",
                "level": 3,
                "order": 614,
                "relation_code": "150825",
                "relation_desc": "内蒙古乌拉特后旗"
            },
            {
                "code": "050807",
                "parentcode": "0508",
                "parenttext": "巴彦淖尔市",
                "text": "杭锦后旗",
                "level": 3,
                "order": 615,
                "relation_code": "150826",
                "relation_desc": "内蒙古杭锦后旗"
            },
            {
                "code": "0509",
                "parentcode": "05",
                "parenttext": "内蒙古自治区",
                "text": "乌兰察布市",
                "level": 2,
                "order": 616,
                "relation_code": "150900",
                "relation_desc": "内蒙古乌兰察布市"
            },
            {
                "code": "050901",
                "parentcode": "0509",
                "parenttext": "乌兰察布市",
                "text": "集宁区",
                "level": 3,
                "order": 617,
                "relation_code": "150902",
                "relation_desc": "内蒙古乌兰察布市集宁区"
            },
            {
                "code": "050902",
                "parentcode": "0509",
                "parenttext": "乌兰察布市",
                "text": "卓资县",
                "level": 3,
                "order": 618,
                "relation_code": "150921",
                "relation_desc": "内蒙古卓资县"
            },
            {
                "code": "050903",
                "parentcode": "0509",
                "parenttext": "乌兰察布市",
                "text": "化德县",
                "level": 3,
                "order": 619,
                "relation_code": "150922",
                "relation_desc": "内蒙古化德县"
            },
            {
                "code": "050904",
                "parentcode": "0509",
                "parenttext": "乌兰察布市",
                "text": "商都县",
                "level": 3,
                "order": 620,
                "relation_code": "150923",
                "relation_desc": "内蒙古商都县"
            },
            {
                "code": "050905",
                "parentcode": "0509",
                "parenttext": "乌兰察布市",
                "text": "兴和县",
                "level": 3,
                "order": 621,
                "relation_code": "150924",
                "relation_desc": "内蒙古兴和县"
            },
            {
                "code": "050906",
                "parentcode": "0509",
                "parenttext": "乌兰察布市",
                "text": "凉城县",
                "level": 3,
                "order": 622,
                "relation_code": "150925",
                "relation_desc": "内蒙古凉城县"
            },
            {
                "code": "050907",
                "parentcode": "0509",
                "parenttext": "乌兰察布市",
                "text": "察哈尔右翼前旗",
                "level": 3,
                "order": 623,
                "relation_code": "150926",
                "relation_desc": "内蒙古察哈尔右翼前旗"
            },
            {
                "code": "050908",
                "parentcode": "0509",
                "parenttext": "乌兰察布市",
                "text": "察哈尔右翼中旗",
                "level": 3,
                "order": 624,
                "relation_code": "150927",
                "relation_desc": "内蒙古察哈尔右翼中旗"
            },
            {
                "code": "050909",
                "parentcode": "0509",
                "parenttext": "乌兰察布市",
                "text": "察哈尔右翼后旗",
                "level": 3,
                "order": 625,
                "relation_code": "150928",
                "relation_desc": "内蒙古察哈尔右翼后旗"
            },
            {
                "code": "050910",
                "parentcode": "0509",
                "parenttext": "乌兰察布市",
                "text": "四子王旗",
                "level": 3,
                "order": 626,
                "relation_code": "150929",
                "relation_desc": "内蒙古四子王旗"
            },
            {
                "code": "050911",
                "parentcode": "0509",
                "parenttext": "乌兰察布市",
                "text": "丰镇市",
                "level": 3,
                "order": 627,
                "relation_code": "150981",
                "relation_desc": "内蒙古丰镇市"
            },
            {
                "code": "0510",
                "parentcode": "05",
                "parenttext": "内蒙古自治区",
                "text": "兴安盟",
                "level": 2,
                "order": 628,
                "relation_code": "152200",
                "relation_desc": "内蒙古兴安盟"
            },
            {
                "code": "051001",
                "parentcode": "0510",
                "parenttext": "兴安盟",
                "text": "乌兰浩特市",
                "level": 3,
                "order": 629,
                "relation_code": "152201",
                "relation_desc": "内蒙古乌兰浩特市"
            },
            {
                "code": "051002",
                "parentcode": "0510",
                "parenttext": "兴安盟",
                "text": "阿尔山市",
                "level": 3,
                "order": 630,
                "relation_code": "152202",
                "relation_desc": "内蒙古阿尔山市"
            },
            {
                "code": "051003",
                "parentcode": "0510",
                "parenttext": "兴安盟",
                "text": "科尔沁右翼前旗",
                "level": 3,
                "order": 631,
                "relation_code": "152221",
                "relation_desc": "内蒙古科尔沁右翼前旗"
            },
            {
                "code": "051004",
                "parentcode": "0510",
                "parenttext": "兴安盟",
                "text": "科尔沁右翼中旗",
                "level": 3,
                "order": 632,
                "relation_code": "152222",
                "relation_desc": "内蒙古科尔沁右翼中旗"
            },
            {
                "code": "051005",
                "parentcode": "0510",
                "parenttext": "兴安盟",
                "text": "扎赉特旗",
                "level": 3,
                "order": 633,
                "relation_code": "152223",
                "relation_desc": "内蒙古扎赉特旗"
            },
            {
                "code": "051006",
                "parentcode": "0510",
                "parenttext": "兴安盟",
                "text": "突泉县",
                "level": 3,
                "order": 634,
                "relation_code": "152224",
                "relation_desc": "内蒙古突泉县"
            },
            {
                "code": "0511",
                "parentcode": "05",
                "parenttext": "内蒙古自治区",
                "text": "锡林郭勒盟",
                "level": 2,
                "order": 635,
                "relation_code": "152500",
                "relation_desc": "内蒙古锡林郭勒盟"
            },
            {
                "code": "051101",
                "parentcode": "0511",
                "parenttext": "锡林郭勒盟",
                "text": "二连浩特市",
                "level": 3,
                "order": 636,
                "relation_code": "152501",
                "relation_desc": "内蒙古二连浩特市"
            },
            {
                "code": "051102",
                "parentcode": "0511",
                "parenttext": "锡林郭勒盟",
                "text": "锡林浩特市",
                "level": 3,
                "order": 637,
                "relation_code": "152502",
                "relation_desc": "内蒙古锡林浩特市"
            },
            {
                "code": "051103",
                "parentcode": "0511",
                "parenttext": "锡林郭勒盟",
                "text": "阿巴嘎旗",
                "level": 3,
                "order": 638,
                "relation_code": "152522",
                "relation_desc": "内蒙古阿巴嘎旗"
            },
            {
                "code": "051104",
                "parentcode": "0511",
                "parenttext": "锡林郭勒盟",
                "text": "苏尼特左旗",
                "level": 3,
                "order": 639,
                "relation_code": "152523",
                "relation_desc": "内蒙古苏尼特左旗"
            },
            {
                "code": "051105",
                "parentcode": "0511",
                "parenttext": "锡林郭勒盟",
                "text": "苏尼特右旗",
                "level": 3,
                "order": 640,
                "relation_code": "152524",
                "relation_desc": "内蒙古苏尼特右旗"
            },
            {
                "code": "051106",
                "parentcode": "0511",
                "parenttext": "锡林郭勒盟",
                "text": "东乌珠穆沁旗",
                "level": 3,
                "order": 641,
                "relation_code": "152525",
                "relation_desc": "内蒙古东乌珠穆沁旗"
            },
            {
                "code": "051107",
                "parentcode": "0511",
                "parenttext": "锡林郭勒盟",
                "text": "西乌珠穆沁旗",
                "level": 3,
                "order": 642,
                "relation_code": "152526",
                "relation_desc": "内蒙古西乌珠穆沁旗"
            },
            {
                "code": "051108",
                "parentcode": "0511",
                "parenttext": "锡林郭勒盟",
                "text": "太仆寺旗",
                "level": 3,
                "order": 643,
                "relation_code": "152527",
                "relation_desc": "内蒙古太仆寺旗"
            },
            {
                "code": "051109",
                "parentcode": "0511",
                "parenttext": "锡林郭勒盟",
                "text": "镶黄旗",
                "level": 3,
                "order": 644,
                "relation_code": "152528",
                "relation_desc": "内蒙古镶黄旗"
            },
            {
                "code": "051110",
                "parentcode": "0511",
                "parenttext": "锡林郭勒盟",
                "text": "正镶白旗",
                "level": 3,
                "order": 645,
                "relation_code": "152529",
                "relation_desc": "内蒙古正镶白旗"
            },
            {
                "code": "051111",
                "parentcode": "0511",
                "parenttext": "锡林郭勒盟",
                "text": "正蓝旗",
                "level": 3,
                "order": 646,
                "relation_code": "152530",
                "relation_desc": "内蒙古正蓝旗"
            },
            {
                "code": "051112",
                "parentcode": "0511",
                "parenttext": "锡林郭勒盟",
                "text": "多伦县",
                "level": 3,
                "order": 647,
                "relation_code": "152531",
                "relation_desc": "内蒙古多伦县"
            },
            {
                "code": "0512",
                "parentcode": "05",
                "parenttext": "内蒙古自治区",
                "text": "阿拉善盟",
                "level": 2,
                "order": 648,
                "relation_code": "152900",
                "relation_desc": "内蒙古阿拉善盟"
            },
            {
                "code": "051113",
                "parentcode": "0511",
                "parenttext": "锡林郭勒盟",
                "text": "乌拉盖管委会",
                "level": 3,
                "order": 648,
                "relation_code": "152501",
                "relation_desc": "内蒙古锡林郭勒盟"
            },
            {
                "code": "051201",
                "parentcode": "0512",
                "parenttext": "阿拉善盟",
                "text": "阿拉善左旗",
                "level": 3,
                "order": 649,
                "relation_code": "152921",
                "relation_desc": "内蒙古阿拉善左旗"
            },
            {
                "code": "051202",
                "parentcode": "0512",
                "parenttext": "阿拉善盟",
                "text": "阿拉善右旗",
                "level": 3,
                "order": 650,
                "relation_code": "152922",
                "relation_desc": "内蒙古阿拉善右旗"
            },
            {
                "code": "051203",
                "parentcode": "0512",
                "parenttext": "阿拉善盟",
                "text": "额济纳旗",
                "level": 3,
                "order": 651,
                "relation_code": "152923",
                "relation_desc": "内蒙古额济纳旗"
            },
            {
                "code": "06",
                "parentcode": "0",
                "parenttext": "0",
                "text": "辽宁省",
                "level": 1,
                "order": 652,
                "relation_code": "",
                "relation_desc": "辽宁省"
            },
            {
                "code": "051204",
                "parentcode": "0512",
                "parenttext": "阿拉善盟",
                "text": "内蒙古阿拉善经济开发区",
                "level": 3,
                "order": 652,
                "relation_code": "152921",
                "relation_desc": "内蒙古阿拉善盟"
            },
            {
                "code": "0601",
                "parentcode": "06",
                "parenttext": "辽宁省",
                "text": "沈阳市",
                "level": 2,
                "order": 653,
                "relation_code": "210100",
                "relation_desc": "辽宁省沈阳市"
            },
            {
                "code": "060101",
                "parentcode": "0601",
                "parenttext": "沈阳市",
                "text": "和平区",
                "level": 3,
                "order": 654,
                "relation_code": "210102",
                "relation_desc": "辽宁省沈阳市和平区"
            },
            {
                "code": "060102",
                "parentcode": "0601",
                "parenttext": "沈阳市",
                "text": "沈河区",
                "level": 3,
                "order": 655,
                "relation_code": "210103",
                "relation_desc": "辽宁省沈阳市沈河区"
            },
            {
                "code": "060103",
                "parentcode": "0601",
                "parenttext": "沈阳市",
                "text": "大东区",
                "level": 3,
                "order": 656,
                "relation_code": "210104",
                "relation_desc": "辽宁省沈阳市大东区"
            },
            {
                "code": "060104",
                "parentcode": "0601",
                "parenttext": "沈阳市",
                "text": "皇姑区",
                "level": 3,
                "order": 657,
                "relation_code": "210105",
                "relation_desc": "辽宁省沈阳市皇姑区"
            },
            {
                "code": "060105",
                "parentcode": "0601",
                "parenttext": "沈阳市",
                "text": "铁西区",
                "level": 3,
                "order": 658,
                "relation_code": "210106",
                "relation_desc": "辽宁省沈阳市铁西区"
            },
            {
                "code": "060106",
                "parentcode": "0601",
                "parenttext": "沈阳市",
                "text": "苏家屯区",
                "level": 3,
                "order": 659,
                "relation_code": "210111",
                "relation_desc": "辽宁省沈阳市苏家屯区"
            },
            {
                "code": "060107",
                "parentcode": "0601",
                "parenttext": "沈阳市",
                "text": "浑南区",
                "level": 3,
                "order": 660,
                "relation_code": "210101",
                "relation_desc": "辽宁省沈阳市"
            },
            {
                "code": "060108",
                "parentcode": "0601",
                "parenttext": "沈阳市",
                "text": "沈北新区",
                "level": 3,
                "order": 661,
                "relation_code": "210113",
                "relation_desc": "辽宁省沈阳市沈北新区"
            },
            {
                "code": "060109",
                "parentcode": "0601",
                "parenttext": "沈阳市",
                "text": "于洪区",
                "level": 3,
                "order": 662,
                "relation_code": "210114",
                "relation_desc": "辽宁省沈阳市于洪区"
            },
            {
                "code": "060110",
                "parentcode": "0601",
                "parenttext": "沈阳市",
                "text": "辽中区",
                "level": 3,
                "order": 663,
                "relation_code": "210115",
                "relation_desc": "辽宁省沈阳市辽中区"
            },
            {
                "code": "060111",
                "parentcode": "0601",
                "parenttext": "沈阳市",
                "text": "康平县",
                "level": 3,
                "order": 664,
                "relation_code": "210123",
                "relation_desc": "辽宁省康平县"
            },
            {
                "code": "060112",
                "parentcode": "0601",
                "parenttext": "沈阳市",
                "text": "法库县",
                "level": 3,
                "order": 665,
                "relation_code": "210124",
                "relation_desc": "辽宁省法库县"
            },
            {
                "code": "060113",
                "parentcode": "0601",
                "parenttext": "沈阳市",
                "text": "新民市",
                "level": 3,
                "order": 666,
                "relation_code": "210181",
                "relation_desc": "辽宁省新民市"
            },
            {
                "code": "0602",
                "parentcode": "06",
                "parenttext": "辽宁省",
                "text": "大连市",
                "level": 2,
                "order": 667,
                "relation_code": "210200",
                "relation_desc": "辽宁省大连市"
            },
            {
                "code": "060114",
                "parentcode": "0601",
                "parenttext": "沈阳市",
                "text": "东陵区",
                "level": 3,
                "order": 667,
                "relation_code": "210101",
                "relation_desc": "辽宁省沈阳市东陵区"
            },
            {
                "code": "060201",
                "parentcode": "0602",
                "parenttext": "大连市",
                "text": "中山区",
                "level": 3,
                "order": 668,
                "relation_code": "210202",
                "relation_desc": "辽宁省大连市中山区"
            },
            {
                "code": "060202",
                "parentcode": "0602",
                "parenttext": "大连市",
                "text": "西岗区",
                "level": 3,
                "order": 669,
                "relation_code": "210203",
                "relation_desc": "辽宁省大连市西岗区"
            },
            {
                "code": "060203",
                "parentcode": "0602",
                "parenttext": "大连市",
                "text": "沙河口区",
                "level": 3,
                "order": 670,
                "relation_code": "210204",
                "relation_desc": "辽宁省大连市沙河口区"
            },
            {
                "code": "060204",
                "parentcode": "0602",
                "parenttext": "大连市",
                "text": "甘井子区",
                "level": 3,
                "order": 671,
                "relation_code": "210211",
                "relation_desc": "辽宁省大连市甘井子区"
            },
            {
                "code": "060205",
                "parentcode": "0602",
                "parenttext": "大连市",
                "text": "旅顺口区",
                "level": 3,
                "order": 672,
                "relation_code": "210212",
                "relation_desc": "辽宁省大连市旅顺口区"
            },
            {
                "code": "060206",
                "parentcode": "0602",
                "parenttext": "大连市",
                "text": "金州区",
                "level": 3,
                "order": 673,
                "relation_code": "210213",
                "relation_desc": "辽宁省大连市金州区"
            },
            {
                "code": "060207",
                "parentcode": "0602",
                "parenttext": "大连市",
                "text": "长海县",
                "level": 3,
                "order": 674,
                "relation_code": "210224",
                "relation_desc": "辽宁省长海县"
            },
            {
                "code": "060208",
                "parentcode": "0602",
                "parenttext": "大连市",
                "text": "瓦房店市",
                "level": 3,
                "order": 675,
                "relation_code": "210281",
                "relation_desc": "辽宁省瓦房店市"
            },
            {
                "code": "060209",
                "parentcode": "0602",
                "parenttext": "大连市",
                "text": "普兰店区",
                "level": 3,
                "order": 676,
                "relation_code": "210214",
                "relation_desc": "辽宁省大连市普兰店区"
            },
            {
                "code": "060210",
                "parentcode": "0602",
                "parenttext": "大连市",
                "text": "庄河市",
                "level": 3,
                "order": 677,
                "relation_code": "210283",
                "relation_desc": "辽宁省庄河市"
            },
            {
                "code": "0603",
                "parentcode": "06",
                "parenttext": "辽宁省",
                "text": "鞍山市",
                "level": 2,
                "order": 678,
                "relation_code": "210300",
                "relation_desc": "辽宁省鞍山市"
            },
            {
                "code": "060301",
                "parentcode": "0603",
                "parenttext": "鞍山市",
                "text": "铁东区",
                "level": 3,
                "order": 679,
                "relation_code": "210302",
                "relation_desc": "辽宁省鞍山市铁东区"
            },
            {
                "code": "060302",
                "parentcode": "0603",
                "parenttext": "鞍山市",
                "text": "铁西区",
                "level": 3,
                "order": 680,
                "relation_code": "210303",
                "relation_desc": "辽宁省鞍山市铁西区"
            },
            {
                "code": "060303",
                "parentcode": "0603",
                "parenttext": "鞍山市",
                "text": "立山区",
                "level": 3,
                "order": 681,
                "relation_code": "210304",
                "relation_desc": "辽宁省鞍山市立山区"
            },
            {
                "code": "060304",
                "parentcode": "0603",
                "parenttext": "鞍山市",
                "text": "千山区",
                "level": 3,
                "order": 682,
                "relation_code": "210311",
                "relation_desc": "辽宁省鞍山市千山区"
            },
            {
                "code": "060305",
                "parentcode": "0603",
                "parenttext": "鞍山市",
                "text": "台安县",
                "level": 3,
                "order": 683,
                "relation_code": "210321",
                "relation_desc": "辽宁省台安县"
            },
            {
                "code": "060306",
                "parentcode": "0603",
                "parenttext": "鞍山市",
                "text": "岫岩满族自治县",
                "level": 3,
                "order": 684,
                "relation_code": "210323",
                "relation_desc": "辽宁省岫岩满族自治县"
            },
            {
                "code": "060307",
                "parentcode": "0603",
                "parenttext": "鞍山市",
                "text": "海城市",
                "level": 3,
                "order": 685,
                "relation_code": "210381",
                "relation_desc": "辽宁省海城市"
            },
            {
                "code": "0604",
                "parentcode": "06",
                "parenttext": "辽宁省",
                "text": "抚顺市",
                "level": 2,
                "order": 686,
                "relation_code": "210400",
                "relation_desc": "辽宁省抚顺市"
            },
            {
                "code": "060401",
                "parentcode": "0604",
                "parenttext": "抚顺市",
                "text": "新抚区",
                "level": 3,
                "order": 687,
                "relation_code": "210402",
                "relation_desc": "辽宁省抚顺市新抚区"
            },
            {
                "code": "060402",
                "parentcode": "0604",
                "parenttext": "抚顺市",
                "text": "东洲区",
                "level": 3,
                "order": 688,
                "relation_code": "210403",
                "relation_desc": "辽宁省抚顺市东洲区"
            },
            {
                "code": "060403",
                "parentcode": "0604",
                "parenttext": "抚顺市",
                "text": "望花区",
                "level": 3,
                "order": 689,
                "relation_code": "210404",
                "relation_desc": "辽宁省抚顺市望花区"
            },
            {
                "code": "060404",
                "parentcode": "0604",
                "parenttext": "抚顺市",
                "text": "顺城区",
                "level": 3,
                "order": 690,
                "relation_code": "210411",
                "relation_desc": "辽宁省抚顺市顺城区"
            },
            {
                "code": "060405",
                "parentcode": "0604",
                "parenttext": "抚顺市",
                "text": "抚顺县",
                "level": 3,
                "order": 691,
                "relation_code": "210421",
                "relation_desc": "辽宁省抚顺县"
            },
            {
                "code": "060406",
                "parentcode": "0604",
                "parenttext": "抚顺市",
                "text": "新宾满族自治县",
                "level": 3,
                "order": 692,
                "relation_code": "210422",
                "relation_desc": "辽宁省新宾满族自治县"
            },
            {
                "code": "060407",
                "parentcode": "0604",
                "parenttext": "抚顺市",
                "text": "清原满族自治县",
                "level": 3,
                "order": 693,
                "relation_code": "210423",
                "relation_desc": "辽宁省清原满族自治县"
            },
            {
                "code": "0605",
                "parentcode": "06",
                "parenttext": "辽宁省",
                "text": "本溪市",
                "level": 2,
                "order": 694,
                "relation_code": "210500",
                "relation_desc": "辽宁省本溪市"
            },
            {
                "code": "060501",
                "parentcode": "0605",
                "parenttext": "本溪市",
                "text": "平山区",
                "level": 3,
                "order": 695,
                "relation_code": "210502",
                "relation_desc": "辽宁省本溪市平山区"
            },
            {
                "code": "060502",
                "parentcode": "0605",
                "parenttext": "本溪市",
                "text": "溪湖区",
                "level": 3,
                "order": 696,
                "relation_code": "210503",
                "relation_desc": "辽宁省本溪市溪湖区"
            },
            {
                "code": "060503",
                "parentcode": "0605",
                "parenttext": "本溪市",
                "text": "明山区",
                "level": 3,
                "order": 697,
                "relation_code": "210504",
                "relation_desc": "辽宁省本溪市明山区"
            },
            {
                "code": "060504",
                "parentcode": "0605",
                "parenttext": "本溪市",
                "text": "南芬区",
                "level": 3,
                "order": 698,
                "relation_code": "210505",
                "relation_desc": "辽宁省本溪市南芬区"
            },
            {
                "code": "060505",
                "parentcode": "0605",
                "parenttext": "本溪市",
                "text": "本溪满族自治县",
                "level": 3,
                "order": 699,
                "relation_code": "210521",
                "relation_desc": "辽宁省本溪满族自治县"
            },
            {
                "code": "060506",
                "parentcode": "0605",
                "parenttext": "本溪市",
                "text": "桓仁满族自治县",
                "level": 3,
                "order": 700,
                "relation_code": "210522",
                "relation_desc": "辽宁省桓仁满族自治县"
            },
            {
                "code": "0606",
                "parentcode": "06",
                "parenttext": "辽宁省",
                "text": "丹东市",
                "level": 2,
                "order": 701,
                "relation_code": "210600",
                "relation_desc": "辽宁省丹东市"
            },
            {
                "code": "060601",
                "parentcode": "0606",
                "parenttext": "丹东市",
                "text": "元宝区",
                "level": 3,
                "order": 702,
                "relation_code": "210602",
                "relation_desc": "辽宁省丹东市元宝区"
            },
            {
                "code": "060602",
                "parentcode": "0606",
                "parenttext": "丹东市",
                "text": "振兴区",
                "level": 3,
                "order": 703,
                "relation_code": "210603",
                "relation_desc": "辽宁省丹东市振兴区"
            },
            {
                "code": "060603",
                "parentcode": "0606",
                "parenttext": "丹东市",
                "text": "振安区",
                "level": 3,
                "order": 704,
                "relation_code": "210604",
                "relation_desc": "辽宁省丹东市振安区"
            },
            {
                "code": "060604",
                "parentcode": "0606",
                "parenttext": "丹东市",
                "text": "宽甸满族自治县",
                "level": 3,
                "order": 705,
                "relation_code": "210624",
                "relation_desc": "辽宁省宽甸满族自治县"
            },
            {
                "code": "060605",
                "parentcode": "0606",
                "parenttext": "丹东市",
                "text": "东港市",
                "level": 3,
                "order": 706,
                "relation_code": "210681",
                "relation_desc": "辽宁省东港市"
            },
            {
                "code": "060606",
                "parentcode": "0606",
                "parenttext": "丹东市",
                "text": "凤城市",
                "level": 3,
                "order": 707,
                "relation_code": "210682",
                "relation_desc": "辽宁省凤城市"
            },
            {
                "code": "0607",
                "parentcode": "06",
                "parenttext": "辽宁省",
                "text": "锦州市",
                "level": 2,
                "order": 708,
                "relation_code": "210700",
                "relation_desc": "辽宁省锦州市"
            },
            {
                "code": "060701",
                "parentcode": "0607",
                "parenttext": "锦州市",
                "text": "古塔区",
                "level": 3,
                "order": 709,
                "relation_code": "210702",
                "relation_desc": "辽宁省锦州市古塔区"
            },
            {
                "code": "060702",
                "parentcode": "0607",
                "parenttext": "锦州市",
                "text": "凌河区",
                "level": 3,
                "order": 710,
                "relation_code": "210703",
                "relation_desc": "辽宁省锦州市凌河区"
            },
            {
                "code": "060703",
                "parentcode": "0607",
                "parenttext": "锦州市",
                "text": "太和区",
                "level": 3,
                "order": 711,
                "relation_code": "210711",
                "relation_desc": "辽宁省锦州市太和区"
            },
            {
                "code": "060704",
                "parentcode": "0607",
                "parenttext": "锦州市",
                "text": "黑山县",
                "level": 3,
                "order": 712,
                "relation_code": "210726",
                "relation_desc": "辽宁省黑山县"
            },
            {
                "code": "060705",
                "parentcode": "0607",
                "parenttext": "锦州市",
                "text": "义县",
                "level": 3,
                "order": 713,
                "relation_code": "210727",
                "relation_desc": "辽宁省义县"
            },
            {
                "code": "060706",
                "parentcode": "0607",
                "parenttext": "锦州市",
                "text": "凌海市",
                "level": 3,
                "order": 714,
                "relation_code": "210781",
                "relation_desc": "辽宁省凌海市"
            },
            {
                "code": "060707",
                "parentcode": "0607",
                "parenttext": "锦州市",
                "text": "北镇市",
                "level": 3,
                "order": 715,
                "relation_code": "210782",
                "relation_desc": "辽宁省北镇市"
            },
            {
                "code": "0608",
                "parentcode": "06",
                "parenttext": "辽宁省",
                "text": "营口市",
                "level": 2,
                "order": 716,
                "relation_code": "210800",
                "relation_desc": "辽宁省营口市"
            },
            {
                "code": "060801",
                "parentcode": "0608",
                "parenttext": "营口市",
                "text": "站前区",
                "level": 3,
                "order": 717,
                "relation_code": "210802",
                "relation_desc": "辽宁省营口市站前区"
            },
            {
                "code": "060802",
                "parentcode": "0608",
                "parenttext": "营口市",
                "text": "西市区",
                "level": 3,
                "order": 718,
                "relation_code": "210803",
                "relation_desc": "辽宁省营口市西市区"
            },
            {
                "code": "060803",
                "parentcode": "0608",
                "parenttext": "营口市",
                "text": "鲅鱼圈区",
                "level": 3,
                "order": 719,
                "relation_code": "210804",
                "relation_desc": "辽宁省营口市鲅鱼圈区"
            },
            {
                "code": "060804",
                "parentcode": "0608",
                "parenttext": "营口市",
                "text": "老边区",
                "level": 3,
                "order": 720,
                "relation_code": "210811",
                "relation_desc": "辽宁省营口市老边区"
            },
            {
                "code": "060805",
                "parentcode": "0608",
                "parenttext": "营口市",
                "text": "盖州市",
                "level": 3,
                "order": 721,
                "relation_code": "210881",
                "relation_desc": "辽宁省盖州市"
            },
            {
                "code": "060806",
                "parentcode": "0608",
                "parenttext": "营口市",
                "text": "大石桥市",
                "level": 3,
                "order": 722,
                "relation_code": "210882",
                "relation_desc": "辽宁省大石桥市"
            },
            {
                "code": "0609",
                "parentcode": "06",
                "parenttext": "辽宁省",
                "text": "阜新市",
                "level": 2,
                "order": 723,
                "relation_code": "210900",
                "relation_desc": "辽宁省阜新市"
            },
            {
                "code": "060901",
                "parentcode": "0609",
                "parenttext": "阜新市",
                "text": "海州区",
                "level": 3,
                "order": 724,
                "relation_code": "210902",
                "relation_desc": "辽宁省阜新市海州区"
            },
            {
                "code": "060902",
                "parentcode": "0609",
                "parenttext": "阜新市",
                "text": "新邱区",
                "level": 3,
                "order": 725,
                "relation_code": "210903",
                "relation_desc": "辽宁省阜新市新邱区"
            },
            {
                "code": "060903",
                "parentcode": "0609",
                "parenttext": "阜新市",
                "text": "太平区",
                "level": 3,
                "order": 726,
                "relation_code": "210904",
                "relation_desc": "辽宁省阜新市太平区"
            },
            {
                "code": "060904",
                "parentcode": "0609",
                "parenttext": "阜新市",
                "text": "清河门区",
                "level": 3,
                "order": 727,
                "relation_code": "210905",
                "relation_desc": "辽宁省阜新市清河门区"
            },
            {
                "code": "060905",
                "parentcode": "0609",
                "parenttext": "阜新市",
                "text": "细河区",
                "level": 3,
                "order": 728,
                "relation_code": "210911",
                "relation_desc": "辽宁省阜新市细河区"
            },
            {
                "code": "060906",
                "parentcode": "0609",
                "parenttext": "阜新市",
                "text": "阜新蒙古族自治县",
                "level": 3,
                "order": 729,
                "relation_code": "210921",
                "relation_desc": "辽宁省阜新蒙古族自治县"
            },
            {
                "code": "060907",
                "parentcode": "0609",
                "parenttext": "阜新市",
                "text": "彰武县",
                "level": 3,
                "order": 730,
                "relation_code": "210922",
                "relation_desc": "辽宁省彰武县"
            },
            {
                "code": "0610",
                "parentcode": "06",
                "parenttext": "辽宁省",
                "text": "辽阳市",
                "level": 2,
                "order": 731,
                "relation_code": "211000",
                "relation_desc": "辽宁省辽阳市"
            },
            {
                "code": "061001",
                "parentcode": "0610",
                "parenttext": "辽阳市",
                "text": "白塔区",
                "level": 3,
                "order": 732,
                "relation_code": "211002",
                "relation_desc": "辽宁省辽阳市白塔区"
            },
            {
                "code": "061002",
                "parentcode": "0610",
                "parenttext": "辽阳市",
                "text": "文圣区",
                "level": 3,
                "order": 733,
                "relation_code": "211003",
                "relation_desc": "辽宁省辽阳市文圣区"
            },
            {
                "code": "061003",
                "parentcode": "0610",
                "parenttext": "辽阳市",
                "text": "宏伟区",
                "level": 3,
                "order": 734,
                "relation_code": "211004",
                "relation_desc": "辽宁省辽阳市宏伟区"
            },
            {
                "code": "061004",
                "parentcode": "0610",
                "parenttext": "辽阳市",
                "text": "弓长岭区",
                "level": 3,
                "order": 735,
                "relation_code": "211005",
                "relation_desc": "辽宁省辽阳市弓长岭区"
            },
            {
                "code": "061005",
                "parentcode": "0610",
                "parenttext": "辽阳市",
                "text": "太子河区",
                "level": 3,
                "order": 736,
                "relation_code": "211011",
                "relation_desc": "辽宁省辽阳市太子河区"
            },
            {
                "code": "061006",
                "parentcode": "0610",
                "parenttext": "辽阳市",
                "text": "辽阳县",
                "level": 3,
                "order": 737,
                "relation_code": "211021",
                "relation_desc": "辽宁省辽阳县"
            },
            {
                "code": "061007",
                "parentcode": "0610",
                "parenttext": "辽阳市",
                "text": "灯塔市",
                "level": 3,
                "order": 738,
                "relation_code": "211081",
                "relation_desc": "辽宁省灯塔市"
            },
            {
                "code": "0611",
                "parentcode": "06",
                "parenttext": "辽宁省",
                "text": "盘锦市",
                "level": 2,
                "order": 739,
                "relation_code": "211100",
                "relation_desc": "辽宁省盘锦市"
            },
            {
                "code": "061101",
                "parentcode": "0611",
                "parenttext": "盘锦市",
                "text": "双台子区",
                "level": 3,
                "order": 740,
                "relation_code": "211102",
                "relation_desc": "辽宁省盘锦市双台子区"
            },
            {
                "code": "061102",
                "parentcode": "0611",
                "parenttext": "盘锦市",
                "text": "兴隆台区",
                "level": 3,
                "order": 741,
                "relation_code": "211103",
                "relation_desc": "辽宁省盘锦市兴隆台区"
            },
            {
                "code": "061103",
                "parentcode": "0611",
                "parenttext": "盘锦市",
                "text": "大洼区",
                "level": 3,
                "order": 742,
                "relation_code": "211104",
                "relation_desc": "辽宁省盘锦市大洼区"
            },
            {
                "code": "061104",
                "parentcode": "0611",
                "parenttext": "盘锦市",
                "text": "盘山县",
                "level": 3,
                "order": 743,
                "relation_code": "211122",
                "relation_desc": "辽宁省盘山县"
            },
            {
                "code": "0612",
                "parentcode": "06",
                "parenttext": "辽宁省",
                "text": "铁岭市",
                "level": 2,
                "order": 744,
                "relation_code": "211200",
                "relation_desc": "辽宁省铁岭市"
            },
            {
                "code": "061201",
                "parentcode": "0612",
                "parenttext": "铁岭市",
                "text": "银州区",
                "level": 3,
                "order": 745,
                "relation_code": "211202",
                "relation_desc": "辽宁省铁岭市银州区"
            },
            {
                "code": "061202",
                "parentcode": "0612",
                "parenttext": "铁岭市",
                "text": "清河区",
                "level": 3,
                "order": 746,
                "relation_code": "211204",
                "relation_desc": "辽宁省铁岭市清河区"
            },
            {
                "code": "061203",
                "parentcode": "0612",
                "parenttext": "铁岭市",
                "text": "铁岭县",
                "level": 3,
                "order": 747,
                "relation_code": "211221",
                "relation_desc": "辽宁省铁岭县"
            },
            {
                "code": "061204",
                "parentcode": "0612",
                "parenttext": "铁岭市",
                "text": "西丰县",
                "level": 3,
                "order": 748,
                "relation_code": "211223",
                "relation_desc": "辽宁省西丰县"
            },
            {
                "code": "061205",
                "parentcode": "0612",
                "parenttext": "铁岭市",
                "text": "昌图县",
                "level": 3,
                "order": 749,
                "relation_code": "211224",
                "relation_desc": "辽宁省昌图县"
            },
            {
                "code": "061206",
                "parentcode": "0612",
                "parenttext": "铁岭市",
                "text": "调兵山市",
                "level": 3,
                "order": 750,
                "relation_code": "211281",
                "relation_desc": "辽宁省调兵山市"
            },
            {
                "code": "061207",
                "parentcode": "0612",
                "parenttext": "铁岭市",
                "text": "开原市",
                "level": 3,
                "order": 751,
                "relation_code": "211282",
                "relation_desc": "辽宁省开原市"
            },
            {
                "code": "0613",
                "parentcode": "06",
                "parenttext": "辽宁省",
                "text": "朝阳市",
                "level": 2,
                "order": 752,
                "relation_code": "211300",
                "relation_desc": "辽宁省朝阳市"
            },
            {
                "code": "061301",
                "parentcode": "0613",
                "parenttext": "朝阳市",
                "text": "双塔区",
                "level": 3,
                "order": 753,
                "relation_code": "211302",
                "relation_desc": "辽宁省朝阳市双塔区"
            },
            {
                "code": "061302",
                "parentcode": "0613",
                "parenttext": "朝阳市",
                "text": "龙城区",
                "level": 3,
                "order": 754,
                "relation_code": "211303",
                "relation_desc": "辽宁省朝阳市龙城区"
            },
            {
                "code": "061303",
                "parentcode": "0613",
                "parenttext": "朝阳市",
                "text": "朝阳县",
                "level": 3,
                "order": 755,
                "relation_code": "211321",
                "relation_desc": "辽宁省朝阳县"
            },
            {
                "code": "061304",
                "parentcode": "0613",
                "parenttext": "朝阳市",
                "text": "建平县",
                "level": 3,
                "order": 756,
                "relation_code": "211322",
                "relation_desc": "辽宁省建平县"
            },
            {
                "code": "061305",
                "parentcode": "0613",
                "parenttext": "朝阳市",
                "text": "喀喇沁左翼蒙古族自治县",
                "level": 3,
                "order": 757,
                "relation_code": "211324",
                "relation_desc": "辽宁省喀喇沁左翼蒙古族自治县"
            },
            {
                "code": "061306",
                "parentcode": "0613",
                "parenttext": "朝阳市",
                "text": "北票市",
                "level": 3,
                "order": 758,
                "relation_code": "211381",
                "relation_desc": "辽宁省北票市"
            },
            {
                "code": "061307",
                "parentcode": "0613",
                "parenttext": "朝阳市",
                "text": "凌源市",
                "level": 3,
                "order": 759,
                "relation_code": "211382",
                "relation_desc": "辽宁省凌源市"
            },
            {
                "code": "0614",
                "parentcode": "06",
                "parenttext": "辽宁省",
                "text": "葫芦岛市",
                "level": 2,
                "order": 760,
                "relation_code": "211400",
                "relation_desc": "辽宁省葫芦岛市"
            },
            {
                "code": "061401",
                "parentcode": "0614",
                "parenttext": "葫芦岛市",
                "text": "连山区",
                "level": 3,
                "order": 761,
                "relation_code": "211402",
                "relation_desc": "辽宁省葫芦岛市连山区"
            },
            {
                "code": "061402",
                "parentcode": "0614",
                "parenttext": "葫芦岛市",
                "text": "龙港区",
                "level": 3,
                "order": 762,
                "relation_code": "211403",
                "relation_desc": "辽宁省葫芦岛市龙港区"
            },
            {
                "code": "061403",
                "parentcode": "0614",
                "parenttext": "葫芦岛市",
                "text": "南票区",
                "level": 3,
                "order": 763,
                "relation_code": "211404",
                "relation_desc": "辽宁省葫芦岛市南票区"
            },
            {
                "code": "061404",
                "parentcode": "0614",
                "parenttext": "葫芦岛市",
                "text": "绥中县",
                "level": 3,
                "order": 764,
                "relation_code": "211421",
                "relation_desc": "辽宁省绥中县"
            },
            {
                "code": "061405",
                "parentcode": "0614",
                "parenttext": "葫芦岛市",
                "text": "建昌县",
                "level": 3,
                "order": 765,
                "relation_code": "211422",
                "relation_desc": "辽宁省建昌县"
            },
            {
                "code": "061406",
                "parentcode": "0614",
                "parenttext": "葫芦岛市",
                "text": "兴城市",
                "level": 3,
                "order": 766,
                "relation_code": "211481",
                "relation_desc": "辽宁省兴城市"
            },
            {
                "code": "07",
                "parentcode": "0",
                "parenttext": "0",
                "text": "吉林省",
                "level": 1,
                "order": 767,
                "relation_code": "",
                "relation_desc": "吉林省"
            },
            {
                "code": "0701",
                "parentcode": "07",
                "parenttext": "吉林省",
                "text": "长春市",
                "level": 2,
                "order": 768,
                "relation_code": "220100",
                "relation_desc": "吉林省长春市"
            },
            {
                "code": "070101",
                "parentcode": "0701",
                "parenttext": "长春市",
                "text": "南关区",
                "level": 3,
                "order": 769,
                "relation_code": "220102",
                "relation_desc": "吉林省长春市南关区"
            },
            {
                "code": "070102",
                "parentcode": "0701",
                "parenttext": "长春市",
                "text": "宽城区",
                "level": 3,
                "order": 770,
                "relation_code": "220103",
                "relation_desc": "吉林省长春市宽城区"
            },
            {
                "code": "070103",
                "parentcode": "0701",
                "parenttext": "长春市",
                "text": "朝阳区",
                "level": 3,
                "order": 771,
                "relation_code": "220104",
                "relation_desc": "吉林省长春市朝阳区"
            },
            {
                "code": "070104",
                "parentcode": "0701",
                "parenttext": "长春市",
                "text": "二道区",
                "level": 3,
                "order": 772,
                "relation_code": "220105",
                "relation_desc": "吉林省长春市二道区"
            },
            {
                "code": "070105",
                "parentcode": "0701",
                "parenttext": "长春市",
                "text": "绿园区",
                "level": 3,
                "order": 773,
                "relation_code": "220106",
                "relation_desc": "吉林省长春市绿园区"
            },
            {
                "code": "070106",
                "parentcode": "0701",
                "parenttext": "长春市",
                "text": "双阳区",
                "level": 3,
                "order": 774,
                "relation_code": "220112",
                "relation_desc": "吉林省长春市双阳区"
            },
            {
                "code": "070107",
                "parentcode": "0701",
                "parenttext": "长春市",
                "text": "农安县",
                "level": 3,
                "order": 775,
                "relation_code": "220122",
                "relation_desc": "吉林省农安县"
            },
            {
                "code": "070108",
                "parentcode": "0701",
                "parenttext": "长春市",
                "text": "九台区",
                "level": 3,
                "order": 776,
                "relation_code": "220113",
                "relation_desc": "吉林省长春市九台区"
            },
            {
                "code": "070109",
                "parentcode": "0701",
                "parenttext": "长春市",
                "text": "榆树市",
                "level": 3,
                "order": 777,
                "relation_code": "220182",
                "relation_desc": "吉林省榆树市"
            },
            {
                "code": "070110",
                "parentcode": "0701",
                "parenttext": "长春市",
                "text": "德惠市",
                "level": 3,
                "order": 778,
                "relation_code": "220183",
                "relation_desc": "吉林省德惠市"
            },
            {
                "code": "0702",
                "parentcode": "07",
                "parenttext": "吉林省",
                "text": "吉林市",
                "level": 2,
                "order": 779,
                "relation_code": "220200",
                "relation_desc": "吉林省吉林市"
            },
            {
                "code": "070111",
                "parentcode": "0701",
                "parenttext": "长春市",
                "text": "长春经济技术开发区",
                "level": 3,
                "order": 779,
                "relation_code": "220101",
                "relation_desc": "吉林省长春市"
            },
            {
                "code": "070201",
                "parentcode": "0702",
                "parenttext": "吉林市",
                "text": "昌邑区",
                "level": 3,
                "order": 780,
                "relation_code": "220202",
                "relation_desc": "吉林省吉林市昌邑区"
            },
            {
                "code": "070112",
                "parentcode": "0701",
                "parenttext": "长春市",
                "text": "长春净月高新技术产业开发区",
                "level": 3,
                "order": 780,
                "relation_code": "220101",
                "relation_desc": "吉林省长春市"
            },
            {
                "code": "070202",
                "parentcode": "0702",
                "parenttext": "吉林市",
                "text": "龙潭区",
                "level": 3,
                "order": 781,
                "relation_code": "220203",
                "relation_desc": "吉林省吉林市龙潭区"
            },
            {
                "code": "070113",
                "parentcode": "0701",
                "parenttext": "长春市",
                "text": "长春高新技术产业开发区",
                "level": 3,
                "order": 781,
                "relation_code": "220101",
                "relation_desc": "吉林省长春市"
            },
            {
                "code": "070203",
                "parentcode": "0702",
                "parenttext": "吉林市",
                "text": "船营区",
                "level": 3,
                "order": 782,
                "relation_code": "220204",
                "relation_desc": "吉林省吉林市船营区"
            },
            {
                "code": "070114",
                "parentcode": "0701",
                "parenttext": "长春市",
                "text": "长春汽车经济技术开发区",
                "level": 3,
                "order": 782,
                "relation_code": "220101",
                "relation_desc": "吉林省长春市"
            },
            {
                "code": "070204",
                "parentcode": "0702",
                "parenttext": "吉林市",
                "text": "丰满区",
                "level": 3,
                "order": 783,
                "relation_code": "220211",
                "relation_desc": "吉林省吉林市丰满区"
            },
            {
                "code": "070205",
                "parentcode": "0702",
                "parenttext": "吉林市",
                "text": "永吉县",
                "level": 3,
                "order": 784,
                "relation_code": "220221",
                "relation_desc": "吉林省永吉县"
            },
            {
                "code": "070206",
                "parentcode": "0702",
                "parenttext": "吉林市",
                "text": "蛟河市",
                "level": 3,
                "order": 785,
                "relation_code": "220281",
                "relation_desc": "吉林省蛟河市"
            },
            {
                "code": "070207",
                "parentcode": "0702",
                "parenttext": "吉林市",
                "text": "桦甸市",
                "level": 3,
                "order": 786,
                "relation_code": "220282",
                "relation_desc": "吉林省桦甸市"
            },
            {
                "code": "070208",
                "parentcode": "0702",
                "parenttext": "吉林市",
                "text": "舒兰市",
                "level": 3,
                "order": 787,
                "relation_code": "220283",
                "relation_desc": "吉林省舒兰市"
            },
            {
                "code": "070209",
                "parentcode": "0702",
                "parenttext": "吉林市",
                "text": "磐石市",
                "level": 3,
                "order": 788,
                "relation_code": "220284",
                "relation_desc": "吉林省磐石市"
            },
            {
                "code": "0703",
                "parentcode": "07",
                "parenttext": "吉林省",
                "text": "四平市",
                "level": 2,
                "order": 789,
                "relation_code": "220300",
                "relation_desc": "吉林省四平市"
            },
            {
                "code": "070210",
                "parentcode": "0702",
                "parenttext": "吉林市",
                "text": "吉林经济开发区",
                "level": 3,
                "order": 789,
                "relation_code": "220201",
                "relation_desc": "吉林省吉林市"
            },
            {
                "code": "070301",
                "parentcode": "0703",
                "parenttext": "四平市",
                "text": "铁西区",
                "level": 3,
                "order": 790,
                "relation_code": "220302",
                "relation_desc": "吉林省四平市铁西区"
            },
            {
                "code": "070302",
                "parentcode": "0703",
                "parenttext": "四平市",
                "text": "铁东区",
                "level": 3,
                "order": 791,
                "relation_code": "220303",
                "relation_desc": "吉林省四平市铁东区"
            },
            {
                "code": "070211",
                "parentcode": "0702",
                "parenttext": "吉林市",
                "text": "吉林高新技术产业开发区",
                "level": 3,
                "order": 791,
                "relation_code": "220201",
                "relation_desc": "吉林省吉林市"
            },
            {
                "code": "070303",
                "parentcode": "0703",
                "parenttext": "四平市",
                "text": "梨树县",
                "level": 3,
                "order": 792,
                "relation_code": "220322",
                "relation_desc": "吉林省梨树县"
            },
            {
                "code": "070212",
                "parentcode": "0702",
                "parenttext": "吉林市",
                "text": "吉林中国新加坡食品区",
                "level": 3,
                "order": 792,
                "relation_code": "220201",
                "relation_desc": "吉林省吉林市"
            },
            {
                "code": "070304",
                "parentcode": "0703",
                "parenttext": "四平市",
                "text": "伊通满族自治县",
                "level": 3,
                "order": 793,
                "relation_code": "220323",
                "relation_desc": "吉林省伊通满族自治县"
            },
            {
                "code": "070305",
                "parentcode": "0703",
                "parenttext": "四平市",
                "text": "公主岭市",
                "level": 3,
                "order": 794,
                "relation_code": "220381",
                "relation_desc": "吉林省公主岭市"
            },
            {
                "code": "070306",
                "parentcode": "0703",
                "parenttext": "四平市",
                "text": "双辽市",
                "level": 3,
                "order": 795,
                "relation_code": "220382",
                "relation_desc": "吉林省双辽市"
            },
            {
                "code": "0704",
                "parentcode": "07",
                "parenttext": "吉林省",
                "text": "辽源市",
                "level": 2,
                "order": 796,
                "relation_code": "220400",
                "relation_desc": "吉林省辽源市"
            },
            {
                "code": "070401",
                "parentcode": "0704",
                "parenttext": "辽源市",
                "text": "龙山区",
                "level": 3,
                "order": 797,
                "relation_code": "220402",
                "relation_desc": "吉林省辽源市龙山区"
            },
            {
                "code": "070402",
                "parentcode": "0704",
                "parenttext": "辽源市",
                "text": "西安区",
                "level": 3,
                "order": 798,
                "relation_code": "220403",
                "relation_desc": "吉林省辽源市西安区"
            },
            {
                "code": "070403",
                "parentcode": "0704",
                "parenttext": "辽源市",
                "text": "东丰县",
                "level": 3,
                "order": 799,
                "relation_code": "220421",
                "relation_desc": "吉林省东丰县"
            },
            {
                "code": "070404",
                "parentcode": "0704",
                "parenttext": "辽源市",
                "text": "东辽县",
                "level": 3,
                "order": 800,
                "relation_code": "220422",
                "relation_desc": "吉林省东辽县"
            },
            {
                "code": "0705",
                "parentcode": "07",
                "parenttext": "吉林省",
                "text": "通化市",
                "level": 2,
                "order": 801,
                "relation_code": "220500",
                "relation_desc": "吉林省通化市"
            },
            {
                "code": "070501",
                "parentcode": "0705",
                "parenttext": "通化市",
                "text": "东昌区",
                "level": 3,
                "order": 802,
                "relation_code": "220502",
                "relation_desc": "吉林省通化市东昌区"
            },
            {
                "code": "070502",
                "parentcode": "0705",
                "parenttext": "通化市",
                "text": "二道江区",
                "level": 3,
                "order": 803,
                "relation_code": "220503",
                "relation_desc": "吉林省通化市二道江区"
            },
            {
                "code": "070503",
                "parentcode": "0705",
                "parenttext": "通化市",
                "text": "通化县",
                "level": 3,
                "order": 804,
                "relation_code": "220521",
                "relation_desc": "吉林省通化县"
            },
            {
                "code": "070504",
                "parentcode": "0705",
                "parenttext": "通化市",
                "text": "辉南县",
                "level": 3,
                "order": 805,
                "relation_code": "220523",
                "relation_desc": "吉林省辉南县"
            },
            {
                "code": "070505",
                "parentcode": "0705",
                "parenttext": "通化市",
                "text": "柳河县",
                "level": 3,
                "order": 806,
                "relation_code": "220524",
                "relation_desc": "吉林省柳河县"
            },
            {
                "code": "070506",
                "parentcode": "0705",
                "parenttext": "通化市",
                "text": "梅河口市",
                "level": 3,
                "order": 807,
                "relation_code": "220581",
                "relation_desc": "吉林省梅河口市"
            },
            {
                "code": "070507",
                "parentcode": "0705",
                "parenttext": "通化市",
                "text": "集安市",
                "level": 3,
                "order": 808,
                "relation_code": "220582",
                "relation_desc": "吉林省集安市"
            },
            {
                "code": "0706",
                "parentcode": "07",
                "parenttext": "吉林省",
                "text": "白山市",
                "level": 2,
                "order": 809,
                "relation_code": "220600",
                "relation_desc": "吉林省白山市"
            },
            {
                "code": "070601",
                "parentcode": "0706",
                "parenttext": "白山市",
                "text": "浑江区",
                "level": 3,
                "order": 810,
                "relation_code": "220602",
                "relation_desc": "吉林省白山市浑江区"
            },
            {
                "code": "070602",
                "parentcode": "0706",
                "parenttext": "白山市",
                "text": "抚松县",
                "level": 3,
                "order": 811,
                "relation_code": "220621",
                "relation_desc": "吉林省抚松县"
            },
            {
                "code": "070603",
                "parentcode": "0706",
                "parenttext": "白山市",
                "text": "靖宇县",
                "level": 3,
                "order": 812,
                "relation_code": "220622",
                "relation_desc": "吉林省靖宇县"
            },
            {
                "code": "070604",
                "parentcode": "0706",
                "parenttext": "白山市",
                "text": "长白朝鲜族自治县",
                "level": 3,
                "order": 813,
                "relation_code": "220623",
                "relation_desc": "吉林省长白朝鲜族自治县"
            },
            {
                "code": "070605",
                "parentcode": "0706",
                "parenttext": "白山市",
                "text": "江源区",
                "level": 3,
                "order": 814,
                "relation_code": "220605",
                "relation_desc": "吉林省白山市江源区"
            },
            {
                "code": "070606",
                "parentcode": "0706",
                "parenttext": "白山市",
                "text": "临江市",
                "level": 3,
                "order": 815,
                "relation_code": "220681",
                "relation_desc": "吉林省临江市"
            },
            {
                "code": "0707",
                "parentcode": "07",
                "parenttext": "吉林省",
                "text": "松原市",
                "level": 2,
                "order": 816,
                "relation_code": "220700",
                "relation_desc": "吉林省松原市"
            },
            {
                "code": "070701",
                "parentcode": "0707",
                "parenttext": "松原市",
                "text": "宁江区",
                "level": 3,
                "order": 817,
                "relation_code": "220702",
                "relation_desc": "吉林省松原市宁江区"
            },
            {
                "code": "070702",
                "parentcode": "0707",
                "parenttext": "松原市",
                "text": "前郭尔罗斯蒙古族自治县",
                "level": 3,
                "order": 818,
                "relation_code": "220721",
                "relation_desc": "吉林省前郭尔罗斯蒙古族自治县"
            },
            {
                "code": "070703",
                "parentcode": "0707",
                "parenttext": "松原市",
                "text": "长岭县",
                "level": 3,
                "order": 819,
                "relation_code": "220722",
                "relation_desc": "吉林省长岭县"
            },
            {
                "code": "070704",
                "parentcode": "0707",
                "parenttext": "松原市",
                "text": "乾安县",
                "level": 3,
                "order": 820,
                "relation_code": "220723",
                "relation_desc": "吉林省乾安县"
            },
            {
                "code": "070705",
                "parentcode": "0707",
                "parenttext": "松原市",
                "text": "扶余市",
                "level": 3,
                "order": 821,
                "relation_code": "220781",
                "relation_desc": "吉林省扶余市"
            },
            {
                "code": "0708",
                "parentcode": "07",
                "parenttext": "吉林省",
                "text": "白城市",
                "level": 2,
                "order": 822,
                "relation_code": "220800",
                "relation_desc": "吉林省白城市"
            },
            {
                "code": "070706",
                "parentcode": "0707",
                "parenttext": "松原市",
                "text": "吉林松原经济开发区",
                "level": 3,
                "order": 822,
                "relation_code": "220701",
                "relation_desc": "吉林省松原市"
            },
            {
                "code": "070801",
                "parentcode": "0708",
                "parenttext": "白城市",
                "text": "洮北区",
                "level": 3,
                "order": 823,
                "relation_code": "220802",
                "relation_desc": "吉林省白城市洮北区"
            },
            {
                "code": "070802",
                "parentcode": "0708",
                "parenttext": "白城市",
                "text": "镇赉县",
                "level": 3,
                "order": 824,
                "relation_code": "220821",
                "relation_desc": "吉林省镇赉县"
            },
            {
                "code": "070803",
                "parentcode": "0708",
                "parenttext": "白城市",
                "text": "通榆县",
                "level": 3,
                "order": 825,
                "relation_code": "220822",
                "relation_desc": "吉林省通榆县"
            },
            {
                "code": "070804",
                "parentcode": "0708",
                "parenttext": "白城市",
                "text": "洮南市",
                "level": 3,
                "order": 826,
                "relation_code": "220881",
                "relation_desc": "吉林省洮南市"
            },
            {
                "code": "070805",
                "parentcode": "0708",
                "parenttext": "白城市",
                "text": "大安市",
                "level": 3,
                "order": 827,
                "relation_code": "220882",
                "relation_desc": "吉林省大安市"
            },
            {
                "code": "0709",
                "parentcode": "07",
                "parenttext": "吉林省",
                "text": "延边朝鲜族自治州",
                "level": 2,
                "order": 828,
                "relation_code": "222400",
                "relation_desc": "吉林省延边朝鲜族自治州"
            },
            {
                "code": "070806",
                "parentcode": "0708",
                "parenttext": "白城市",
                "text": "吉林白城经济开发区",
                "level": 3,
                "order": 828,
                "relation_code": "220801",
                "relation_desc": "吉林省白城市"
            },
            {
                "code": "070901",
                "parentcode": "0709",
                "parenttext": "延边朝鲜族自治州",
                "text": "延吉市",
                "level": 3,
                "order": 829,
                "relation_code": "222401",
                "relation_desc": "吉林省延吉市"
            },
            {
                "code": "070902",
                "parentcode": "0709",
                "parenttext": "延边朝鲜族自治州",
                "text": "图们市",
                "level": 3,
                "order": 830,
                "relation_code": "222402",
                "relation_desc": "吉林省图们市"
            },
            {
                "code": "070903",
                "parentcode": "0709",
                "parenttext": "延边朝鲜族自治州",
                "text": "敦化市",
                "level": 3,
                "order": 831,
                "relation_code": "222403",
                "relation_desc": "吉林省敦化市"
            },
            {
                "code": "070904",
                "parentcode": "0709",
                "parenttext": "延边朝鲜族自治州",
                "text": "珲春市",
                "level": 3,
                "order": 832,
                "relation_code": "222404",
                "relation_desc": "吉林省珲春市"
            },
            {
                "code": "070905",
                "parentcode": "0709",
                "parenttext": "延边朝鲜族自治州",
                "text": "龙井市",
                "level": 3,
                "order": 833,
                "relation_code": "222405",
                "relation_desc": "吉林省龙井市"
            },
            {
                "code": "070906",
                "parentcode": "0709",
                "parenttext": "延边朝鲜族自治州",
                "text": "和龙市",
                "level": 3,
                "order": 834,
                "relation_code": "222406",
                "relation_desc": "吉林省和龙市"
            },
            {
                "code": "070907",
                "parentcode": "0709",
                "parenttext": "延边朝鲜族自治州",
                "text": "汪清县",
                "level": 3,
                "order": 835,
                "relation_code": "222424",
                "relation_desc": "吉林省汪清县"
            },
            {
                "code": "070908",
                "parentcode": "0709",
                "parenttext": "延边朝鲜族自治州",
                "text": "安图县",
                "level": 3,
                "order": 836,
                "relation_code": "222426",
                "relation_desc": "吉林省安图县"
            },
            {
                "code": "08",
                "parentcode": "0",
                "parenttext": "0",
                "text": "黑龙江省",
                "level": 1,
                "order": 837,
                "relation_code": "",
                "relation_desc": "黑龙江省"
            },
            {
                "code": "0801",
                "parentcode": "08",
                "parenttext": "黑龙江省",
                "text": "哈尔滨市",
                "level": 2,
                "order": 838,
                "relation_code": "230100",
                "relation_desc": "黑龙江省哈尔滨市"
            },
            {
                "code": "080101",
                "parentcode": "0801",
                "parenttext": "哈尔滨市",
                "text": "道里区",
                "level": 3,
                "order": 839,
                "relation_code": "230102",
                "relation_desc": "黑龙江省哈尔滨市道里区"
            },
            {
                "code": "080102",
                "parentcode": "0801",
                "parenttext": "哈尔滨市",
                "text": "南岗区",
                "level": 3,
                "order": 840,
                "relation_code": "230103",
                "relation_desc": "黑龙江省哈尔滨市南岗区"
            },
            {
                "code": "080103",
                "parentcode": "0801",
                "parenttext": "哈尔滨市",
                "text": "道外区",
                "level": 3,
                "order": 841,
                "relation_code": "230104",
                "relation_desc": "黑龙江省哈尔滨市道外区"
            },
            {
                "code": "080104",
                "parentcode": "0801",
                "parenttext": "哈尔滨市",
                "text": "香坊区",
                "level": 3,
                "order": 842,
                "relation_code": "230110",
                "relation_desc": "黑龙江省哈尔滨市香坊区"
            },
            {
                "code": "080105",
                "parentcode": "0801",
                "parenttext": "哈尔滨市",
                "text": "动力区",
                "level": 3,
                "order": 843,
                "relation_code": "230101",
                "relation_desc": "黑龙江省哈尔滨市动力区"
            },
            {
                "code": "080106",
                "parentcode": "0801",
                "parenttext": "哈尔滨市",
                "text": "平房区",
                "level": 3,
                "order": 844,
                "relation_code": "230108",
                "relation_desc": "黑龙江省哈尔滨市平房区"
            },
            {
                "code": "080107",
                "parentcode": "0801",
                "parenttext": "哈尔滨市",
                "text": "松北区",
                "level": 3,
                "order": 845,
                "relation_code": "230109",
                "relation_desc": "黑龙江省哈尔滨市松北区"
            },
            {
                "code": "080108",
                "parentcode": "0801",
                "parenttext": "哈尔滨市",
                "text": "呼兰区",
                "level": 3,
                "order": 846,
                "relation_code": "230111",
                "relation_desc": "黑龙江省哈尔滨市呼兰区"
            },
            {
                "code": "080109",
                "parentcode": "0801",
                "parenttext": "哈尔滨市",
                "text": "依兰县",
                "level": 3,
                "order": 847,
                "relation_code": "230123",
                "relation_desc": "黑龙江省依兰县"
            },
            {
                "code": "080110",
                "parentcode": "0801",
                "parenttext": "哈尔滨市",
                "text": "方正县",
                "level": 3,
                "order": 848,
                "relation_code": "230124",
                "relation_desc": "黑龙江省方正县"
            },
            {
                "code": "080111",
                "parentcode": "0801",
                "parenttext": "哈尔滨市",
                "text": "宾县",
                "level": 3,
                "order": 849,
                "relation_code": "230125",
                "relation_desc": "黑龙江省宾县"
            },
            {
                "code": "080112",
                "parentcode": "0801",
                "parenttext": "哈尔滨市",
                "text": "巴彦县",
                "level": 3,
                "order": 850,
                "relation_code": "230126",
                "relation_desc": "黑龙江省巴彦县"
            },
            {
                "code": "080113",
                "parentcode": "0801",
                "parenttext": "哈尔滨市",
                "text": "木兰县",
                "level": 3,
                "order": 851,
                "relation_code": "230127",
                "relation_desc": "黑龙江省木兰县"
            },
            {
                "code": "080114",
                "parentcode": "0801",
                "parenttext": "哈尔滨市",
                "text": "通河县",
                "level": 3,
                "order": 852,
                "relation_code": "230128",
                "relation_desc": "黑龙江省通河县"
            },
            {
                "code": "080115",
                "parentcode": "0801",
                "parenttext": "哈尔滨市",
                "text": "延寿县",
                "level": 3,
                "order": 853,
                "relation_code": "230129",
                "relation_desc": "黑龙江省延寿县"
            },
            {
                "code": "080116",
                "parentcode": "0801",
                "parenttext": "哈尔滨市",
                "text": "阿城区",
                "level": 3,
                "order": 854,
                "relation_code": "230112",
                "relation_desc": "黑龙江省哈尔滨市阿城区"
            },
            {
                "code": "080117",
                "parentcode": "0801",
                "parenttext": "哈尔滨市",
                "text": "双城区",
                "level": 3,
                "order": 855,
                "relation_code": "230113",
                "relation_desc": "黑龙江省哈尔滨市双城区"
            },
            {
                "code": "080118",
                "parentcode": "0801",
                "parenttext": "哈尔滨市",
                "text": "尚志市",
                "level": 3,
                "order": 856,
                "relation_code": "230183",
                "relation_desc": "黑龙江省尚志市"
            },
            {
                "code": "080119",
                "parentcode": "0801",
                "parenttext": "哈尔滨市",
                "text": "五常市",
                "level": 3,
                "order": 857,
                "relation_code": "230184",
                "relation_desc": "黑龙江省五常市"
            },
            {
                "code": "0802",
                "parentcode": "08",
                "parenttext": "黑龙江省",
                "text": "齐齐哈尔市",
                "level": 2,
                "order": 858,
                "relation_code": "230200",
                "relation_desc": "黑龙江省齐齐哈尔市"
            },
            {
                "code": "080201",
                "parentcode": "0802",
                "parenttext": "齐齐哈尔市",
                "text": "龙沙区",
                "level": 3,
                "order": 859,
                "relation_code": "230202",
                "relation_desc": "黑龙江省齐齐哈尔市龙沙区"
            },
            {
                "code": "080202",
                "parentcode": "0802",
                "parenttext": "齐齐哈尔市",
                "text": "建华区",
                "level": 3,
                "order": 860,
                "relation_code": "230203",
                "relation_desc": "黑龙江省齐齐哈尔市建华区"
            },
            {
                "code": "080203",
                "parentcode": "0802",
                "parenttext": "齐齐哈尔市",
                "text": "铁锋区",
                "level": 3,
                "order": 861,
                "relation_code": "230204",
                "relation_desc": "黑龙江省齐齐哈尔市铁锋区"
            },
            {
                "code": "080204",
                "parentcode": "0802",
                "parenttext": "齐齐哈尔市",
                "text": "昂昂溪区",
                "level": 3,
                "order": 862,
                "relation_code": "230205",
                "relation_desc": "黑龙江省齐齐哈尔市昂昂溪区"
            },
            {
                "code": "080205",
                "parentcode": "0802",
                "parenttext": "齐齐哈尔市",
                "text": "富拉尔基区",
                "level": 3,
                "order": 863,
                "relation_code": "230206",
                "relation_desc": "黑龙江省齐齐哈尔市富拉尔基区"
            },
            {
                "code": "080206",
                "parentcode": "0802",
                "parenttext": "齐齐哈尔市",
                "text": "碾子山区",
                "level": 3,
                "order": 864,
                "relation_code": "230207",
                "relation_desc": "黑龙江省齐齐哈尔市碾子山区"
            },
            {
                "code": "080207",
                "parentcode": "0802",
                "parenttext": "齐齐哈尔市",
                "text": "梅里斯达斡尔族区",
                "level": 3,
                "order": 865,
                "relation_code": "230208",
                "relation_desc": "黑龙江省齐齐哈尔市梅里斯达斡尔族区"
            },
            {
                "code": "080208",
                "parentcode": "0802",
                "parenttext": "齐齐哈尔市",
                "text": "龙江县",
                "level": 3,
                "order": 866,
                "relation_code": "230221",
                "relation_desc": "黑龙江省龙江县"
            },
            {
                "code": "080209",
                "parentcode": "0802",
                "parenttext": "齐齐哈尔市",
                "text": "依安县",
                "level": 3,
                "order": 867,
                "relation_code": "230223",
                "relation_desc": "黑龙江省依安县"
            },
            {
                "code": "080210",
                "parentcode": "0802",
                "parenttext": "齐齐哈尔市",
                "text": "泰来县",
                "level": 3,
                "order": 868,
                "relation_code": "230224",
                "relation_desc": "黑龙江省泰来县"
            },
            {
                "code": "080211",
                "parentcode": "0802",
                "parenttext": "齐齐哈尔市",
                "text": "甘南县",
                "level": 3,
                "order": 869,
                "relation_code": "230225",
                "relation_desc": "黑龙江省甘南县"
            },
            {
                "code": "080212",
                "parentcode": "0802",
                "parenttext": "齐齐哈尔市",
                "text": "富裕县",
                "level": 3,
                "order": 870,
                "relation_code": "230227",
                "relation_desc": "黑龙江省富裕县"
            },
            {
                "code": "080213",
                "parentcode": "0802",
                "parenttext": "齐齐哈尔市",
                "text": "克山县",
                "level": 3,
                "order": 871,
                "relation_code": "230229",
                "relation_desc": "黑龙江省克山县"
            },
            {
                "code": "080214",
                "parentcode": "0802",
                "parenttext": "齐齐哈尔市",
                "text": "克东县",
                "level": 3,
                "order": 872,
                "relation_code": "230230",
                "relation_desc": "黑龙江省克东县"
            },
            {
                "code": "080215",
                "parentcode": "0802",
                "parenttext": "齐齐哈尔市",
                "text": "拜泉县",
                "level": 3,
                "order": 873,
                "relation_code": "230231",
                "relation_desc": "黑龙江省拜泉县"
            },
            {
                "code": "080216",
                "parentcode": "0802",
                "parenttext": "齐齐哈尔市",
                "text": "讷河市",
                "level": 3,
                "order": 874,
                "relation_code": "230281",
                "relation_desc": "黑龙江省讷河市"
            },
            {
                "code": "0803",
                "parentcode": "08",
                "parenttext": "黑龙江省",
                "text": "鸡西市",
                "level": 2,
                "order": 875,
                "relation_code": "230300",
                "relation_desc": "黑龙江省鸡西市"
            },
            {
                "code": "080301",
                "parentcode": "0803",
                "parenttext": "鸡西市",
                "text": "鸡冠区",
                "level": 3,
                "order": 876,
                "relation_code": "230302",
                "relation_desc": "黑龙江省鸡西市鸡冠区"
            },
            {
                "code": "080302",
                "parentcode": "0803",
                "parenttext": "鸡西市",
                "text": "恒山区",
                "level": 3,
                "order": 877,
                "relation_code": "230303",
                "relation_desc": "黑龙江省鸡西市恒山区"
            },
            {
                "code": "080303",
                "parentcode": "0803",
                "parenttext": "鸡西市",
                "text": "滴道区",
                "level": 3,
                "order": 878,
                "relation_code": "230304",
                "relation_desc": "黑龙江省鸡西市滴道区"
            },
            {
                "code": "080304",
                "parentcode": "0803",
                "parenttext": "鸡西市",
                "text": "梨树区",
                "level": 3,
                "order": 879,
                "relation_code": "230305",
                "relation_desc": "黑龙江省鸡西市梨树区"
            },
            {
                "code": "080305",
                "parentcode": "0803",
                "parenttext": "鸡西市",
                "text": "城子河区",
                "level": 3,
                "order": 880,
                "relation_code": "230306",
                "relation_desc": "黑龙江省鸡西市城子河区"
            },
            {
                "code": "080306",
                "parentcode": "0803",
                "parenttext": "鸡西市",
                "text": "麻山区",
                "level": 3,
                "order": 881,
                "relation_code": "230307",
                "relation_desc": "黑龙江省鸡西市麻山区"
            },
            {
                "code": "080307",
                "parentcode": "0803",
                "parenttext": "鸡西市",
                "text": "鸡东县",
                "level": 3,
                "order": 882,
                "relation_code": "230321",
                "relation_desc": "黑龙江省鸡东县"
            },
            {
                "code": "080308",
                "parentcode": "0803",
                "parenttext": "鸡西市",
                "text": "虎林市",
                "level": 3,
                "order": 883,
                "relation_code": "230381",
                "relation_desc": "黑龙江省虎林市"
            },
            {
                "code": "080309",
                "parentcode": "0803",
                "parenttext": "鸡西市",
                "text": "密山市",
                "level": 3,
                "order": 884,
                "relation_code": "230382",
                "relation_desc": "黑龙江省密山市"
            },
            {
                "code": "0804",
                "parentcode": "08",
                "parenttext": "黑龙江省",
                "text": "鹤岗市",
                "level": 2,
                "order": 885,
                "relation_code": "230400",
                "relation_desc": "黑龙江省鹤岗市"
            },
            {
                "code": "080401",
                "parentcode": "0804",
                "parenttext": "鹤岗市",
                "text": "向阳区",
                "level": 3,
                "order": 886,
                "relation_code": "230402",
                "relation_desc": "黑龙江省鹤岗市向阳区"
            },
            {
                "code": "080402",
                "parentcode": "0804",
                "parenttext": "鹤岗市",
                "text": "工农区",
                "level": 3,
                "order": 887,
                "relation_code": "230403",
                "relation_desc": "黑龙江省鹤岗市工农区"
            },
            {
                "code": "080403",
                "parentcode": "0804",
                "parenttext": "鹤岗市",
                "text": "南山区",
                "level": 3,
                "order": 888,
                "relation_code": "230404",
                "relation_desc": "黑龙江省鹤岗市南山区"
            },
            {
                "code": "080404",
                "parentcode": "0804",
                "parenttext": "鹤岗市",
                "text": "兴安区",
                "level": 3,
                "order": 889,
                "relation_code": "230405",
                "relation_desc": "黑龙江省鹤岗市兴安区"
            },
            {
                "code": "080405",
                "parentcode": "0804",
                "parenttext": "鹤岗市",
                "text": "东山区",
                "level": 3,
                "order": 890,
                "relation_code": "230406",
                "relation_desc": "黑龙江省鹤岗市东山区"
            },
            {
                "code": "080406",
                "parentcode": "0804",
                "parenttext": "鹤岗市",
                "text": "兴山区",
                "level": 3,
                "order": 891,
                "relation_code": "230407",
                "relation_desc": "黑龙江省鹤岗市兴山区"
            },
            {
                "code": "080407",
                "parentcode": "0804",
                "parenttext": "鹤岗市",
                "text": "萝北县",
                "level": 3,
                "order": 892,
                "relation_code": "230421",
                "relation_desc": "黑龙江省萝北县"
            },
            {
                "code": "080408",
                "parentcode": "0804",
                "parenttext": "鹤岗市",
                "text": "绥滨县",
                "level": 3,
                "order": 893,
                "relation_code": "230422",
                "relation_desc": "黑龙江省绥滨县"
            },
            {
                "code": "0805",
                "parentcode": "08",
                "parenttext": "黑龙江省",
                "text": "双鸭山市",
                "level": 2,
                "order": 894,
                "relation_code": "230500",
                "relation_desc": "黑龙江省双鸭山市"
            },
            {
                "code": "080501",
                "parentcode": "0805",
                "parenttext": "双鸭山市",
                "text": "尖山区",
                "level": 3,
                "order": 895,
                "relation_code": "230502",
                "relation_desc": "黑龙江省双鸭山市尖山区"
            },
            {
                "code": "080502",
                "parentcode": "0805",
                "parenttext": "双鸭山市",
                "text": "岭东区",
                "level": 3,
                "order": 896,
                "relation_code": "230503",
                "relation_desc": "黑龙江省双鸭山市岭东区"
            },
            {
                "code": "080503",
                "parentcode": "0805",
                "parenttext": "双鸭山市",
                "text": "四方台区",
                "level": 3,
                "order": 897,
                "relation_code": "230505",
                "relation_desc": "黑龙江省双鸭山市四方台区"
            },
            {
                "code": "080504",
                "parentcode": "0805",
                "parenttext": "双鸭山市",
                "text": "宝山区",
                "level": 3,
                "order": 898,
                "relation_code": "230506",
                "relation_desc": "黑龙江省双鸭山市宝山区"
            },
            {
                "code": "080505",
                "parentcode": "0805",
                "parenttext": "双鸭山市",
                "text": "集贤县",
                "level": 3,
                "order": 899,
                "relation_code": "230521",
                "relation_desc": "黑龙江省集贤县"
            },
            {
                "code": "080506",
                "parentcode": "0805",
                "parenttext": "双鸭山市",
                "text": "友谊县",
                "level": 3,
                "order": 900,
                "relation_code": "230522",
                "relation_desc": "黑龙江省友谊县"
            },
            {
                "code": "080507",
                "parentcode": "0805",
                "parenttext": "双鸭山市",
                "text": "宝清县",
                "level": 3,
                "order": 901,
                "relation_code": "230523",
                "relation_desc": "黑龙江省宝清县"
            },
            {
                "code": "080508",
                "parentcode": "0805",
                "parenttext": "双鸭山市",
                "text": "饶河县",
                "level": 3,
                "order": 902,
                "relation_code": "230524",
                "relation_desc": "黑龙江省饶河县"
            },
            {
                "code": "0806",
                "parentcode": "08",
                "parenttext": "黑龙江省",
                "text": "大庆市",
                "level": 2,
                "order": 903,
                "relation_code": "230600",
                "relation_desc": "黑龙江省大庆市"
            },
            {
                "code": "080601",
                "parentcode": "0806",
                "parenttext": "大庆市",
                "text": "萨尔图区",
                "level": 3,
                "order": 904,
                "relation_code": "230602",
                "relation_desc": "黑龙江省大庆市萨尔图区"
            },
            {
                "code": "080602",
                "parentcode": "0806",
                "parenttext": "大庆市",
                "text": "龙凤区",
                "level": 3,
                "order": 905,
                "relation_code": "230603",
                "relation_desc": "黑龙江省大庆市龙凤区"
            },
            {
                "code": "080603",
                "parentcode": "0806",
                "parenttext": "大庆市",
                "text": "让胡路区",
                "level": 3,
                "order": 906,
                "relation_code": "230604",
                "relation_desc": "黑龙江省大庆市让胡路区"
            },
            {
                "code": "080604",
                "parentcode": "0806",
                "parenttext": "大庆市",
                "text": "红岗区",
                "level": 3,
                "order": 907,
                "relation_code": "230605",
                "relation_desc": "黑龙江省大庆市红岗区"
            },
            {
                "code": "080605",
                "parentcode": "0806",
                "parenttext": "大庆市",
                "text": "大同区",
                "level": 3,
                "order": 908,
                "relation_code": "230606",
                "relation_desc": "黑龙江省大庆市大同区"
            },
            {
                "code": "080606",
                "parentcode": "0806",
                "parenttext": "大庆市",
                "text": "肇州县",
                "level": 3,
                "order": 909,
                "relation_code": "230621",
                "relation_desc": "黑龙江省肇州县"
            },
            {
                "code": "080607",
                "parentcode": "0806",
                "parenttext": "大庆市",
                "text": "肇源县",
                "level": 3,
                "order": 910,
                "relation_code": "230622",
                "relation_desc": "黑龙江省肇源县"
            },
            {
                "code": "080608",
                "parentcode": "0806",
                "parenttext": "大庆市",
                "text": "林甸县",
                "level": 3,
                "order": 911,
                "relation_code": "230623",
                "relation_desc": "黑龙江省林甸县"
            },
            {
                "code": "080609",
                "parentcode": "0806",
                "parenttext": "大庆市",
                "text": "杜尔伯特蒙古族自治县",
                "level": 3,
                "order": 912,
                "relation_code": "230624",
                "relation_desc": "黑龙江省杜尔伯特蒙古族自治县"
            },
            {
                "code": "0807",
                "parentcode": "08",
                "parenttext": "黑龙江省",
                "text": "伊春市",
                "level": 2,
                "order": 913,
                "relation_code": "230700",
                "relation_desc": "黑龙江省伊春市"
            },
            {
                "code": "080610",
                "parentcode": "0806",
                "parenttext": "大庆市",
                "text": "大庆高新技术产业开发区",
                "level": 3,
                "order": 913,
                "relation_code": "230601",
                "relation_desc": "黑龙江省大庆市"
            },
            {
                "code": "080701",
                "parentcode": "0807",
                "parenttext": "伊春市",
                "text": "伊春区",
                "level": 3,
                "order": 914,
                "relation_code": "230711",
                "relation_desc": "黑龙江省伊春市伊春区"
            },
            {
                "code": "080702",
                "parentcode": "0807",
                "parenttext": "伊春市",
                "text": "南岔区",
                "level": 3,
                "order": 915,
                "relation_code": "230711",
                "relation_desc": "黑龙江省伊春市南岔区"
            },
            {
                "code": "080703",
                "parentcode": "0807",
                "parenttext": "伊春市",
                "text": "友好区",
                "level": 3,
                "order": 916,
                "relation_code": "230719",
                "relation_desc": "黑龙江省伊春市友好区"
            },
            {
                "code": "080704",
                "parentcode": "0807",
                "parenttext": "伊春市",
                "text": "西林区",
                "level": 3,
                "order": 917,
                "relation_code": "230711",
                "relation_desc": "黑龙江省伊春市西林区"
            },
            {
                "code": "080705",
                "parentcode": "0807",
                "parenttext": "伊春市",
                "text": "翠峦区",
                "level": 3,
                "order": 918,
                "relation_code": "230711",
                "relation_desc": "黑龙江省伊春市翠峦区"
            },
            {
                "code": "080706",
                "parentcode": "0807",
                "parenttext": "伊春市",
                "text": "新青区",
                "level": 3,
                "order": 919,
                "relation_code": "230711",
                "relation_desc": "黑龙江省伊春市新青区"
            },
            {
                "code": "080707",
                "parentcode": "0807",
                "parenttext": "伊春市",
                "text": "美溪区",
                "level": 3,
                "order": 920,
                "relation_code": "230711",
                "relation_desc": "黑龙江省伊春市美溪区"
            },
            {
                "code": "080708",
                "parentcode": "0807",
                "parenttext": "伊春市",
                "text": "金山屯区",
                "level": 3,
                "order": 921,
                "relation_code": "230711",
                "relation_desc": "黑龙江省伊春市金山屯区"
            },
            {
                "code": "080709",
                "parentcode": "0807",
                "parenttext": "伊春市",
                "text": "五营区",
                "level": 3,
                "order": 922,
                "relation_code": "230711",
                "relation_desc": "黑龙江省伊春市五营区"
            },
            {
                "code": "080710",
                "parentcode": "0807",
                "parenttext": "伊春市",
                "text": "乌马河区",
                "level": 3,
                "order": 923,
                "relation_code": "230711",
                "relation_desc": "黑龙江省伊春市乌马河区"
            },
            {
                "code": "080711",
                "parentcode": "0807",
                "parenttext": "伊春市",
                "text": "汤旺河区",
                "level": 3,
                "order": 924,
                "relation_code": "230711",
                "relation_desc": "黑龙江省伊春市汤旺河区"
            },
            {
                "code": "080712",
                "parentcode": "0807",
                "parenttext": "伊春市",
                "text": "带岭区",
                "level": 3,
                "order": 925,
                "relation_code": "230711",
                "relation_desc": "黑龙江省伊春市带岭区"
            },
            {
                "code": "080713",
                "parentcode": "0807",
                "parenttext": "伊春市",
                "text": "乌伊岭区",
                "level": 3,
                "order": 926,
                "relation_code": "230711",
                "relation_desc": "黑龙江省伊春市乌伊岭区"
            },
            {
                "code": "080714",
                "parentcode": "0807",
                "parenttext": "伊春市",
                "text": "红星区",
                "level": 3,
                "order": 927,
                "relation_code": "230711",
                "relation_desc": "黑龙江省伊春市红星区"
            },
            {
                "code": "080715",
                "parentcode": "0807",
                "parenttext": "伊春市",
                "text": "上甘岭区",
                "level": 3,
                "order": 928,
                "relation_code": "230711",
                "relation_desc": "黑龙江省伊春市上甘岭区"
            },
            {
                "code": "080716",
                "parentcode": "0807",
                "parenttext": "伊春市",
                "text": "嘉荫县",
                "level": 3,
                "order": 929,
                "relation_code": "230722",
                "relation_desc": "黑龙江省嘉荫县"
            },
            {
                "code": "080717",
                "parentcode": "0807",
                "parenttext": "伊春市",
                "text": "铁力市",
                "level": 3,
                "order": 930,
                "relation_code": "230781",
                "relation_desc": "黑龙江省铁力市"
            },
            {
                "code": "0808",
                "parentcode": "08",
                "parenttext": "黑龙江省",
                "text": "佳木斯市",
                "level": 2,
                "order": 931,
                "relation_code": "230800",
                "relation_desc": "黑龙江省佳木斯市"
            },
            {
                "code": "080718",
                "parentcode": "0807",
                "parenttext": "伊春市",
                "text": "伊美区",
                "level": 3,
                "order": 931,
                "relation_code": "230711",
                "relation_desc": "黑龙江省伊春市"
            },
            {
                "code": "080801",
                "parentcode": "0808",
                "parenttext": "佳木斯市",
                "text": "永红区",
                "level": 3,
                "order": 932,
                "relation_code": "230801",
                "relation_desc": "黑龙江省佳木斯市永红区"
            },
            {
                "code": "080719",
                "parentcode": "0807",
                "parenttext": "伊春市",
                "text": "乌翠区",
                "level": 3,
                "order": 932,
                "relation_code": "230711",
                "relation_desc": "黑龙江省伊春市"
            },
            {
                "code": "080802",
                "parentcode": "0808",
                "parenttext": "佳木斯市",
                "text": "向阳区",
                "level": 3,
                "order": 933,
                "relation_code": "230803",
                "relation_desc": "黑龙江省佳木斯市向阳区"
            },
            {
                "code": "080720",
                "parentcode": "0807",
                "parenttext": "伊春市",
                "text": "汤旺县",
                "level": 3,
                "order": 933,
                "relation_code": "230711",
                "relation_desc": "黑龙江省伊春市"
            },
            {
                "code": "080803",
                "parentcode": "0808",
                "parenttext": "佳木斯市",
                "text": "前进区",
                "level": 3,
                "order": 934,
                "relation_code": "230804",
                "relation_desc": "黑龙江省佳木斯市前进区"
            },
            {
                "code": "080721",
                "parentcode": "0807",
                "parenttext": "伊春市",
                "text": "丰林县",
                "level": 3,
                "order": 934,
                "relation_code": "230711",
                "relation_desc": "黑龙江省伊春市"
            },
            {
                "code": "080804",
                "parentcode": "0808",
                "parenttext": "佳木斯市",
                "text": "东风区",
                "level": 3,
                "order": 935,
                "relation_code": "230805",
                "relation_desc": "黑龙江省佳木斯市东风区"
            },
            {
                "code": "080722",
                "parentcode": "0807",
                "parenttext": "伊春市",
                "text": "大箐山县",
                "level": 3,
                "order": 935,
                "relation_code": "230711",
                "relation_desc": "黑龙江省伊春市"
            },
            {
                "code": "080805",
                "parentcode": "0808",
                "parenttext": "佳木斯市",
                "text": "郊区",
                "level": 3,
                "order": 936,
                "relation_code": "230811",
                "relation_desc": "黑龙江省佳木斯市郊区"
            },
            {
                "code": "080723",
                "parentcode": "0807",
                "parenttext": "伊春市",
                "text": "南岔县",
                "level": 3,
                "order": 936,
                "relation_code": "230711",
                "relation_desc": "黑龙江省伊春市"
            },
            {
                "code": "080806",
                "parentcode": "0808",
                "parenttext": "佳木斯市",
                "text": "桦南县",
                "level": 3,
                "order": 937,
                "relation_code": "230822",
                "relation_desc": "黑龙江省桦南县"
            },
            {
                "code": "080724",
                "parentcode": "0807",
                "parenttext": "伊春市",
                "text": "金林区",
                "level": 3,
                "order": 937,
                "relation_code": "230711",
                "relation_desc": "黑龙江省伊春市"
            },
            {
                "code": "080807",
                "parentcode": "0808",
                "parenttext": "佳木斯市",
                "text": "桦川县",
                "level": 3,
                "order": 938,
                "relation_code": "230826",
                "relation_desc": "黑龙江省桦川县"
            },
            {
                "code": "080808",
                "parentcode": "0808",
                "parenttext": "佳木斯市",
                "text": "汤原县",
                "level": 3,
                "order": 939,
                "relation_code": "230828",
                "relation_desc": "黑龙江省汤原县"
            },
            {
                "code": "080809",
                "parentcode": "0808",
                "parenttext": "佳木斯市",
                "text": "抚远市",
                "level": 3,
                "order": 940,
                "relation_code": "230801",
                "relation_desc": "黑龙江省抚远县"
            },
            {
                "code": "080810",
                "parentcode": "0808",
                "parenttext": "佳木斯市",
                "text": "同江市",
                "level": 3,
                "order": 941,
                "relation_code": "230881",
                "relation_desc": "黑龙江省同江市"
            },
            {
                "code": "080811",
                "parentcode": "0808",
                "parenttext": "佳木斯市",
                "text": "富锦市",
                "level": 3,
                "order": 942,
                "relation_code": "230882",
                "relation_desc": "黑龙江省富锦市"
            },
            {
                "code": "0809",
                "parentcode": "08",
                "parenttext": "黑龙江省",
                "text": "七台河市",
                "level": 2,
                "order": 943,
                "relation_code": "230900",
                "relation_desc": "黑龙江省七台河市"
            },
            {
                "code": "080901",
                "parentcode": "0809",
                "parenttext": "七台河市",
                "text": "新兴区",
                "level": 3,
                "order": 944,
                "relation_code": "230902",
                "relation_desc": "黑龙江省七台河市新兴区"
            },
            {
                "code": "080902",
                "parentcode": "0809",
                "parenttext": "七台河市",
                "text": "桃山区",
                "level": 3,
                "order": 945,
                "relation_code": "230903",
                "relation_desc": "黑龙江省七台河市桃山区"
            },
            {
                "code": "080903",
                "parentcode": "0809",
                "parenttext": "七台河市",
                "text": "茄子河区",
                "level": 3,
                "order": 946,
                "relation_code": "230904",
                "relation_desc": "黑龙江省七台河市茄子河区"
            },
            {
                "code": "080904",
                "parentcode": "0809",
                "parenttext": "七台河市",
                "text": "勃利县",
                "level": 3,
                "order": 947,
                "relation_code": "230921",
                "relation_desc": "黑龙江省勃利县"
            },
            {
                "code": "0810",
                "parentcode": "08",
                "parenttext": "黑龙江省",
                "text": "牡丹江市",
                "level": 2,
                "order": 948,
                "relation_code": "231000",
                "relation_desc": "黑龙江省牡丹江市"
            },
            {
                "code": "081001",
                "parentcode": "0810",
                "parenttext": "牡丹江市",
                "text": "东安区",
                "level": 3,
                "order": 949,
                "relation_code": "231002",
                "relation_desc": "黑龙江省牡丹江市东安区"
            },
            {
                "code": "081002",
                "parentcode": "0810",
                "parenttext": "牡丹江市",
                "text": "阳明区",
                "level": 3,
                "order": 950,
                "relation_code": "231003",
                "relation_desc": "黑龙江省牡丹江市阳明区"
            },
            {
                "code": "081003",
                "parentcode": "0810",
                "parenttext": "牡丹江市",
                "text": "爱民区",
                "level": 3,
                "order": 951,
                "relation_code": "231004",
                "relation_desc": "黑龙江省牡丹江市爱民区"
            },
            {
                "code": "081004",
                "parentcode": "0810",
                "parenttext": "牡丹江市",
                "text": "西安区",
                "level": 3,
                "order": 952,
                "relation_code": "231005",
                "relation_desc": "黑龙江省牡丹江市西安区"
            },
            {
                "code": "081005",
                "parentcode": "0810",
                "parenttext": "牡丹江市",
                "text": "东宁市",
                "level": 3,
                "order": 953,
                "relation_code": "231001",
                "relation_desc": "黑龙江省东宁县"
            },
            {
                "code": "081006",
                "parentcode": "0810",
                "parenttext": "牡丹江市",
                "text": "林口县",
                "level": 3,
                "order": 954,
                "relation_code": "231025",
                "relation_desc": "黑龙江省林口县"
            },
            {
                "code": "081007",
                "parentcode": "0810",
                "parenttext": "牡丹江市",
                "text": "绥芬河市",
                "level": 3,
                "order": 955,
                "relation_code": "231081",
                "relation_desc": "黑龙江省绥芬河市"
            },
            {
                "code": "081008",
                "parentcode": "0810",
                "parenttext": "牡丹江市",
                "text": "海林市",
                "level": 3,
                "order": 956,
                "relation_code": "231083",
                "relation_desc": "黑龙江省海林市"
            },
            {
                "code": "081009",
                "parentcode": "0810",
                "parenttext": "牡丹江市",
                "text": "宁安市",
                "level": 3,
                "order": 957,
                "relation_code": "231084",
                "relation_desc": "黑龙江省宁安市"
            },
            {
                "code": "081010",
                "parentcode": "0810",
                "parenttext": "牡丹江市",
                "text": "穆棱市",
                "level": 3,
                "order": 958,
                "relation_code": "231085",
                "relation_desc": "黑龙江省穆棱市"
            },
            {
                "code": "0811",
                "parentcode": "08",
                "parenttext": "黑龙江省",
                "text": "黑河市",
                "level": 2,
                "order": 959,
                "relation_code": "231100",
                "relation_desc": "黑龙江省黑河市"
            },
            {
                "code": "081011",
                "parentcode": "0810",
                "parenttext": "牡丹江市",
                "text": "牡丹江经济技术开发区",
                "level": 3,
                "order": 959,
                "relation_code": "231001",
                "relation_desc": "黑龙江省牡丹江市"
            },
            {
                "code": "081101",
                "parentcode": "0811",
                "parenttext": "黑河市",
                "text": "爱辉区",
                "level": 3,
                "order": 960,
                "relation_code": "231102",
                "relation_desc": "黑龙江省黑河市爱辉区"
            },
            {
                "code": "081102",
                "parentcode": "0811",
                "parenttext": "黑河市",
                "text": "嫩江县",
                "level": 3,
                "order": 961,
                "relation_code": "231101",
                "relation_desc": "黑龙江省嫩江县"
            },
            {
                "code": "081103",
                "parentcode": "0811",
                "parenttext": "黑河市",
                "text": "逊克县",
                "level": 3,
                "order": 962,
                "relation_code": "231123",
                "relation_desc": "黑龙江省逊克县"
            },
            {
                "code": "081104",
                "parentcode": "0811",
                "parenttext": "黑河市",
                "text": "孙吴县",
                "level": 3,
                "order": 963,
                "relation_code": "231124",
                "relation_desc": "黑龙江省孙吴县"
            },
            {
                "code": "081105",
                "parentcode": "0811",
                "parenttext": "黑河市",
                "text": "北安市",
                "level": 3,
                "order": 964,
                "relation_code": "231181",
                "relation_desc": "黑龙江省北安市"
            },
            {
                "code": "081106",
                "parentcode": "0811",
                "parenttext": "黑河市",
                "text": "五大连池市",
                "level": 3,
                "order": 965,
                "relation_code": "231182",
                "relation_desc": "黑龙江省五大连池市"
            },
            {
                "code": "0812",
                "parentcode": "08",
                "parenttext": "黑龙江省",
                "text": "绥化市",
                "level": 2,
                "order": 966,
                "relation_code": "231200",
                "relation_desc": "黑龙江省绥化市"
            },
            {
                "code": "081201",
                "parentcode": "0812",
                "parenttext": "绥化市",
                "text": "北林区",
                "level": 3,
                "order": 967,
                "relation_code": "231202",
                "relation_desc": "黑龙江省绥化市北林区"
            },
            {
                "code": "081202",
                "parentcode": "0812",
                "parenttext": "绥化市",
                "text": "望奎县",
                "level": 3,
                "order": 968,
                "relation_code": "231221",
                "relation_desc": "黑龙江省望奎县"
            },
            {
                "code": "081203",
                "parentcode": "0812",
                "parenttext": "绥化市",
                "text": "兰西县",
                "level": 3,
                "order": 969,
                "relation_code": "231222",
                "relation_desc": "黑龙江省兰西县"
            },
            {
                "code": "081204",
                "parentcode": "0812",
                "parenttext": "绥化市",
                "text": "青冈县",
                "level": 3,
                "order": 970,
                "relation_code": "231223",
                "relation_desc": "黑龙江省青冈县"
            },
            {
                "code": "081205",
                "parentcode": "0812",
                "parenttext": "绥化市",
                "text": "庆安县",
                "level": 3,
                "order": 971,
                "relation_code": "231224",
                "relation_desc": "黑龙江省庆安县"
            },
            {
                "code": "081206",
                "parentcode": "0812",
                "parenttext": "绥化市",
                "text": "明水县",
                "level": 3,
                "order": 972,
                "relation_code": "231225",
                "relation_desc": "黑龙江省明水县"
            },
            {
                "code": "081207",
                "parentcode": "0812",
                "parenttext": "绥化市",
                "text": "绥棱县",
                "level": 3,
                "order": 973,
                "relation_code": "231226",
                "relation_desc": "黑龙江省绥棱县"
            },
            {
                "code": "081208",
                "parentcode": "0812",
                "parenttext": "绥化市",
                "text": "安达市",
                "level": 3,
                "order": 974,
                "relation_code": "231281",
                "relation_desc": "黑龙江省安达市"
            },
            {
                "code": "081209",
                "parentcode": "0812",
                "parenttext": "绥化市",
                "text": "肇东市",
                "level": 3,
                "order": 975,
                "relation_code": "231282",
                "relation_desc": "黑龙江省肇东市"
            },
            {
                "code": "081210",
                "parentcode": "0812",
                "parenttext": "绥化市",
                "text": "海伦市",
                "level": 3,
                "order": 976,
                "relation_code": "231283",
                "relation_desc": "黑龙江省海伦市"
            },
            {
                "code": "0813",
                "parentcode": "08",
                "parenttext": "黑龙江省",
                "text": "大兴安岭地区",
                "level": 2,
                "order": 977,
                "relation_code": "232700",
                "relation_desc": "黑龙江省大兴安岭地区"
            },
            {
                "code": "081301",
                "parentcode": "0813",
                "parenttext": "大兴安岭地区",
                "text": "呼玛县",
                "level": 3,
                "order": 978,
                "relation_code": "232721",
                "relation_desc": "黑龙江省呼玛县"
            },
            {
                "code": "0850",
                "parentcode": "08",
                "parenttext": "黑龙江省",
                "text": "省农垦总局",
                "level": 2,
                "order": 978,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "081302",
                "parentcode": "0813",
                "parenttext": "大兴安岭地区",
                "text": "塔河县",
                "level": 3,
                "order": 979,
                "relation_code": "232722",
                "relation_desc": "黑龙江省塔河县"
            },
            {
                "code": "081303",
                "parentcode": "0813",
                "parenttext": "大兴安岭地区",
                "text": "漠河县",
                "level": 3,
                "order": 980,
                "relation_code": "232721",
                "relation_desc": "黑龙江省漠河县"
            },
            {
                "code": "09",
                "parentcode": "0",
                "parenttext": "0",
                "text": "上海市",
                "level": 1,
                "order": 981,
                "relation_code": "",
                "relation_desc": "上海市"
            },
            {
                "code": "081304",
                "parentcode": "0813",
                "parenttext": "大兴安岭地区",
                "text": "加格达奇区",
                "level": 3,
                "order": 981,
                "relation_code": "232721",
                "relation_desc": "黑龙江省大兴安岭地区"
            },
            {
                "code": "0901",
                "parentcode": "09",
                "parenttext": "上海市",
                "text": "上海市",
                "level": 2,
                "order": 982,
                "relation_code": "",
                "relation_desc": "上海市"
            },
            {
                "code": "081305",
                "parentcode": "0813",
                "parenttext": "大兴安岭地区",
                "text": "松岭区",
                "level": 3,
                "order": 982,
                "relation_code": "232721",
                "relation_desc": "黑龙江省大兴安岭地区"
            },
            {
                "code": "090101",
                "parentcode": "0901",
                "parenttext": "上海市",
                "text": "黄浦区",
                "level": 3,
                "order": 983,
                "relation_code": "310101",
                "relation_desc": "上海市黄浦区"
            },
            {
                "code": "081306",
                "parentcode": "0813",
                "parenttext": "大兴安岭地区",
                "text": "新林区",
                "level": 3,
                "order": 983,
                "relation_code": "232721",
                "relation_desc": "黑龙江省大兴安岭地区"
            },
            {
                "code": "090102",
                "parentcode": "0901",
                "parenttext": "上海市",
                "text": "卢湾区",
                "level": 3,
                "order": 984,
                "relation_code": "310101",
                "relation_desc": "上海市卢湾区"
            },
            {
                "code": "081307",
                "parentcode": "0813",
                "parenttext": "大兴安岭地区",
                "text": "呼中区",
                "level": 3,
                "order": 984,
                "relation_code": "232721",
                "relation_desc": "黑龙江省大兴安岭地区"
            },
            {
                "code": "090103",
                "parentcode": "0901",
                "parenttext": "上海市",
                "text": "徐汇区",
                "level": 3,
                "order": 985,
                "relation_code": "310104",
                "relation_desc": "上海市徐汇区"
            },
            {
                "code": "090104",
                "parentcode": "0901",
                "parenttext": "上海市",
                "text": "长宁区",
                "level": 3,
                "order": 986,
                "relation_code": "310105",
                "relation_desc": "上海市长宁区"
            },
            {
                "code": "090105",
                "parentcode": "0901",
                "parenttext": "上海市",
                "text": "静安区",
                "level": 3,
                "order": 987,
                "relation_code": "310106",
                "relation_desc": "上海市静安区"
            },
            {
                "code": "090106",
                "parentcode": "0901",
                "parenttext": "上海市",
                "text": "普陀区",
                "level": 3,
                "order": 988,
                "relation_code": "310107",
                "relation_desc": "上海市普陀区"
            },
            {
                "code": "090107",
                "parentcode": "0901",
                "parenttext": "上海市",
                "text": "闸北区",
                "level": 3,
                "order": 989,
                "relation_code": "310101",
                "relation_desc": "上海市闸北区"
            },
            {
                "code": "090108",
                "parentcode": "0901",
                "parenttext": "上海市",
                "text": "虹口区",
                "level": 3,
                "order": 990,
                "relation_code": "310109",
                "relation_desc": "上海市虹口区"
            },
            {
                "code": "090109",
                "parentcode": "0901",
                "parenttext": "上海市",
                "text": "杨浦区",
                "level": 3,
                "order": 991,
                "relation_code": "310110",
                "relation_desc": "上海市杨浦区"
            },
            {
                "code": "0860",
                "parentcode": "08",
                "parenttext": "黑龙江省",
                "text": "省森工总局",
                "level": 2,
                "order": 991,
                "relation_code": "235300",
                "relation_desc": "黑龙江省农垦建三江管理局"
            },
            {
                "code": "090110",
                "parentcode": "0901",
                "parenttext": "上海市",
                "text": "闵行区",
                "level": 3,
                "order": 992,
                "relation_code": "310112",
                "relation_desc": "上海市闵行区"
            },
            {
                "code": "090111",
                "parentcode": "0901",
                "parenttext": "上海市",
                "text": "宝山区",
                "level": 3,
                "order": 993,
                "relation_code": "310113",
                "relation_desc": "上海市宝山区"
            },
            {
                "code": "090112",
                "parentcode": "0901",
                "parenttext": "上海市",
                "text": "嘉定区",
                "level": 3,
                "order": 994,
                "relation_code": "310114",
                "relation_desc": "上海市嘉定区"
            },
            {
                "code": "090113",
                "parentcode": "0901",
                "parenttext": "上海市",
                "text": "浦东新区",
                "level": 3,
                "order": 995,
                "relation_code": "310115",
                "relation_desc": "上海市浦东新区"
            },
            {
                "code": "090114",
                "parentcode": "0901",
                "parenttext": "上海市",
                "text": "金山区",
                "level": 3,
                "order": 996,
                "relation_code": "310116",
                "relation_desc": "上海市金山区"
            },
            {
                "code": "090115",
                "parentcode": "0901",
                "parenttext": "上海市",
                "text": "松江区",
                "level": 3,
                "order": 997,
                "relation_code": "310117",
                "relation_desc": "上海市松江区"
            },
            {
                "code": "090116",
                "parentcode": "0901",
                "parenttext": "上海市",
                "text": "青浦区",
                "level": 3,
                "order": 998,
                "relation_code": "310118",
                "relation_desc": "上海市青浦区"
            },
            {
                "code": "090117",
                "parentcode": "0901",
                "parenttext": "上海市",
                "text": "南汇区",
                "level": 3,
                "order": 999,
                "relation_code": "310101",
                "relation_desc": "上海市南汇区"
            },
            {
                "code": "090118",
                "parentcode": "0901",
                "parenttext": "上海市",
                "text": "奉贤区",
                "level": 3,
                "order": 1000,
                "relation_code": "310120",
                "relation_desc": "上海市奉贤区"
            },
            {
                "code": "090119",
                "parentcode": "0901",
                "parenttext": "上海市",
                "text": "崇明区",
                "level": 3,
                "order": 1001,
                "relation_code": "310151",
                "relation_desc": "上海市崇明区"
            },
            {
                "code": "10",
                "parentcode": "0",
                "parenttext": "0",
                "text": "江苏省",
                "level": 1,
                "order": 1002,
                "relation_code": "",
                "relation_desc": "江苏省"
            },
            {
                "code": "090120",
                "parentcode": "0901",
                "parenttext": "上海市",
                "text": "大屯镇",
                "level": 3,
                "order": 1002,
                "relation_code": "310101",
                "relation_desc": "上海市大屯镇"
            },
            {
                "code": "1001",
                "parentcode": "10",
                "parenttext": "江苏省",
                "text": "南京市",
                "level": 2,
                "order": 1003,
                "relation_code": "320100",
                "relation_desc": "江苏省南京市"
            },
            {
                "code": "100101",
                "parentcode": "1001",
                "parenttext": "南京市",
                "text": "玄武区",
                "level": 3,
                "order": 1004,
                "relation_code": "320102",
                "relation_desc": "江苏省南京市玄武区"
            },
            {
                "code": "100102",
                "parentcode": "1001",
                "parenttext": "南京市",
                "text": "白下区",
                "level": 3,
                "order": 1005,
                "relation_code": "320101",
                "relation_desc": "江苏省南京市白下区"
            },
            {
                "code": "100103",
                "parentcode": "1001",
                "parenttext": "南京市",
                "text": "秦淮区",
                "level": 3,
                "order": 1006,
                "relation_code": "320104",
                "relation_desc": "江苏省南京市秦淮区"
            },
            {
                "code": "100104",
                "parentcode": "1001",
                "parenttext": "南京市",
                "text": "建邺区",
                "level": 3,
                "order": 1007,
                "relation_code": "320105",
                "relation_desc": "江苏省南京市建邺区"
            },
            {
                "code": "100105",
                "parentcode": "1001",
                "parenttext": "南京市",
                "text": "鼓楼区",
                "level": 3,
                "order": 1008,
                "relation_code": "320106",
                "relation_desc": "江苏省南京市鼓楼区"
            },
            {
                "code": "100106",
                "parentcode": "1001",
                "parenttext": "南京市",
                "text": "下关区",
                "level": 3,
                "order": 1009,
                "relation_code": "320101",
                "relation_desc": "江苏省南京市下关区"
            },
            {
                "code": "100107",
                "parentcode": "1001",
                "parenttext": "南京市",
                "text": "浦口区",
                "level": 3,
                "order": 1010,
                "relation_code": "320111",
                "relation_desc": "江苏省南京市浦口区"
            },
            {
                "code": "100108",
                "parentcode": "1001",
                "parenttext": "南京市",
                "text": "栖霞区",
                "level": 3,
                "order": 1011,
                "relation_code": "320113",
                "relation_desc": "江苏省南京市栖霞区"
            },
            {
                "code": "100109",
                "parentcode": "1001",
                "parenttext": "南京市",
                "text": "雨花台区",
                "level": 3,
                "order": 1012,
                "relation_code": "320114",
                "relation_desc": "江苏省南京市雨花台区"
            },
            {
                "code": "100110",
                "parentcode": "1001",
                "parenttext": "南京市",
                "text": "江宁区",
                "level": 3,
                "order": 1013,
                "relation_code": "320115",
                "relation_desc": "江苏省南京市江宁区"
            },
            {
                "code": "100111",
                "parentcode": "1001",
                "parenttext": "南京市",
                "text": "六合区",
                "level": 3,
                "order": 1014,
                "relation_code": "320116",
                "relation_desc": "江苏省南京市六合区"
            },
            {
                "code": "100112",
                "parentcode": "1001",
                "parenttext": "南京市",
                "text": "溧水区",
                "level": 3,
                "order": 1015,
                "relation_code": "320117",
                "relation_desc": "江苏省南京市溧水区"
            },
            {
                "code": "100113",
                "parentcode": "1001",
                "parenttext": "南京市",
                "text": "高淳区",
                "level": 3,
                "order": 1016,
                "relation_code": "320118",
                "relation_desc": "江苏省南京市高淳区"
            },
            {
                "code": "1002",
                "parentcode": "10",
                "parenttext": "江苏省",
                "text": "无锡市",
                "level": 2,
                "order": 1017,
                "relation_code": "320200",
                "relation_desc": "江苏省无锡市"
            },
            {
                "code": "100201",
                "parentcode": "1002",
                "parenttext": "无锡市",
                "text": "梁溪区",
                "level": 3,
                "order": 1018,
                "relation_code": "320213",
                "relation_desc": "江苏省无锡市梁溪区"
            },
            {
                "code": "100202",
                "parentcode": "1002",
                "parenttext": "无锡市",
                "text": "新吴区",
                "level": 3,
                "order": 1019,
                "relation_code": "320214",
                "relation_desc": "江苏省无锡市新吴区"
            },
            {
                "code": "100204",
                "parentcode": "1002",
                "parenttext": "无锡市",
                "text": "锡山区",
                "level": 3,
                "order": 1021,
                "relation_code": "320205",
                "relation_desc": "江苏省无锡市锡山区"
            },
            {
                "code": "100205",
                "parentcode": "1002",
                "parenttext": "无锡市",
                "text": "惠山区",
                "level": 3,
                "order": 1022,
                "relation_code": "320206",
                "relation_desc": "江苏省无锡市惠山区"
            },
            {
                "code": "100206",
                "parentcode": "1002",
                "parenttext": "无锡市",
                "text": "滨湖区",
                "level": 3,
                "order": 1023,
                "relation_code": "320211",
                "relation_desc": "江苏省无锡市滨湖区"
            },
            {
                "code": "100207",
                "parentcode": "1002",
                "parenttext": "无锡市",
                "text": "江阴市",
                "level": 3,
                "order": 1024,
                "relation_code": "320281",
                "relation_desc": "江苏省江阴市"
            },
            {
                "code": "100209",
                "parentcode": "1002",
                "parenttext": "无锡市",
                "text": "崇安区",
                "level": 3,
                "order": 1024,
                "relation_code": "320211",
                "relation_desc": "江苏省无锡市崇安区"
            },
            {
                "code": "100208",
                "parentcode": "1002",
                "parenttext": "无锡市",
                "text": "宜兴市",
                "level": 3,
                "order": 1025,
                "relation_code": "320282",
                "relation_desc": "江苏省宜兴市"
            },
            {
                "code": "100210",
                "parentcode": "1002",
                "parenttext": "无锡市",
                "text": "南长区",
                "level": 3,
                "order": 1025,
                "relation_code": "320211",
                "relation_desc": "江苏省无锡市南长区"
            },
            {
                "code": "1003",
                "parentcode": "10",
                "parenttext": "江苏省",
                "text": "徐州市",
                "level": 2,
                "order": 1026,
                "relation_code": "320300",
                "relation_desc": "江苏省徐州市"
            },
            {
                "code": "100211",
                "parentcode": "1002",
                "parenttext": "无锡市",
                "text": "北塘区",
                "level": 3,
                "order": 1026,
                "relation_code": "320211",
                "relation_desc": "江苏省无锡市北塘区"
            },
            {
                "code": "100301",
                "parentcode": "1003",
                "parenttext": "徐州市",
                "text": "鼓楼区",
                "level": 3,
                "order": 1027,
                "relation_code": "320302",
                "relation_desc": "江苏省徐州市鼓楼区"
            },
            {
                "code": "100302",
                "parentcode": "1003",
                "parenttext": "徐州市",
                "text": "云龙区",
                "level": 3,
                "order": 1028,
                "relation_code": "320303",
                "relation_desc": "江苏省徐州市云龙区"
            },
            {
                "code": "100303",
                "parentcode": "1003",
                "parenttext": "徐州市",
                "text": "九里区",
                "level": 3,
                "order": 1029,
                "relation_code": "320301",
                "relation_desc": "江苏省徐州市九里区"
            },
            {
                "code": "100304",
                "parentcode": "1003",
                "parenttext": "徐州市",
                "text": "贾汪区",
                "level": 3,
                "order": 1030,
                "relation_code": "320305",
                "relation_desc": "江苏省徐州市贾汪区"
            },
            {
                "code": "100305",
                "parentcode": "1003",
                "parenttext": "徐州市",
                "text": "泉山区",
                "level": 3,
                "order": 1031,
                "relation_code": "320311",
                "relation_desc": "江苏省徐州市泉山区"
            },
            {
                "code": "100306",
                "parentcode": "1003",
                "parenttext": "徐州市",
                "text": "丰县",
                "level": 3,
                "order": 1032,
                "relation_code": "320321",
                "relation_desc": "江苏省丰县"
            },
            {
                "code": "100307",
                "parentcode": "1003",
                "parenttext": "徐州市",
                "text": "沛县",
                "level": 3,
                "order": 1033,
                "relation_code": "320322",
                "relation_desc": "江苏省沛县"
            },
            {
                "code": "100308",
                "parentcode": "1003",
                "parenttext": "徐州市",
                "text": "铜山区",
                "level": 3,
                "order": 1034,
                "relation_code": "320312",
                "relation_desc": "江苏省徐州市铜山区"
            },
            {
                "code": "100309",
                "parentcode": "1003",
                "parenttext": "徐州市",
                "text": "睢宁县",
                "level": 3,
                "order": 1035,
                "relation_code": "320324",
                "relation_desc": "江苏省睢宁县"
            },
            {
                "code": "100310",
                "parentcode": "1003",
                "parenttext": "徐州市",
                "text": "新沂市",
                "level": 3,
                "order": 1036,
                "relation_code": "320381",
                "relation_desc": "江苏省新沂市"
            },
            {
                "code": "100311",
                "parentcode": "1003",
                "parenttext": "徐州市",
                "text": "邳州市",
                "level": 3,
                "order": 1037,
                "relation_code": "320382",
                "relation_desc": "江苏省邳州市"
            },
            {
                "code": "1004",
                "parentcode": "10",
                "parenttext": "江苏省",
                "text": "常州市",
                "level": 2,
                "order": 1038,
                "relation_code": "320400",
                "relation_desc": "江苏省常州市"
            },
            {
                "code": "100312",
                "parentcode": "1003",
                "parenttext": "徐州市",
                "text": "徐州经济技术开发区",
                "level": 3,
                "order": 1038,
                "relation_code": "320301",
                "relation_desc": "江苏省徐州市"
            },
            {
                "code": "100401",
                "parentcode": "1004",
                "parenttext": "常州市",
                "text": "天宁区",
                "level": 3,
                "order": 1039,
                "relation_code": "320402",
                "relation_desc": "江苏省常州市天宁区"
            },
            {
                "code": "100402",
                "parentcode": "1004",
                "parenttext": "常州市",
                "text": "钟楼区",
                "level": 3,
                "order": 1040,
                "relation_code": "320404",
                "relation_desc": "江苏省常州市钟楼区"
            },
            {
                "code": "100403",
                "parentcode": "1004",
                "parenttext": "常州市",
                "text": "戚墅堰区",
                "level": 3,
                "order": 1041,
                "relation_code": "320401",
                "relation_desc": "江苏省常州市戚墅堰区"
            },
            {
                "code": "100404",
                "parentcode": "1004",
                "parenttext": "常州市",
                "text": "新北区",
                "level": 3,
                "order": 1042,
                "relation_code": "320411",
                "relation_desc": "江苏省常州市新北区"
            },
            {
                "code": "100405",
                "parentcode": "1004",
                "parenttext": "常州市",
                "text": "武进区",
                "level": 3,
                "order": 1043,
                "relation_code": "320412",
                "relation_desc": "江苏省常州市武进区"
            },
            {
                "code": "100406",
                "parentcode": "1004",
                "parenttext": "常州市",
                "text": "溧阳市",
                "level": 3,
                "order": 1044,
                "relation_code": "320481",
                "relation_desc": "江苏省溧阳市"
            },
            {
                "code": "100407",
                "parentcode": "1004",
                "parenttext": "常州市",
                "text": "金坛区",
                "level": 3,
                "order": 1045,
                "relation_code": "320413",
                "relation_desc": "江苏省常州市金坛区"
            },
            {
                "code": "1005",
                "parentcode": "10",
                "parenttext": "江苏省",
                "text": "苏州市",
                "level": 2,
                "order": 1046,
                "relation_code": "320500",
                "relation_desc": "江苏省苏州市"
            },
            {
                "code": "100501",
                "parentcode": "1005",
                "parenttext": "苏州市",
                "text": "姑苏区",
                "level": 3,
                "order": 1047,
                "relation_code": "320508",
                "relation_desc": "江苏省苏州市姑苏区"
            },
            {
                "code": "100504",
                "parentcode": "1005",
                "parenttext": "苏州市",
                "text": "虎丘区",
                "level": 3,
                "order": 1050,
                "relation_code": "320505",
                "relation_desc": "江苏省苏州市虎丘区"
            },
            {
                "code": "100505",
                "parentcode": "1005",
                "parenttext": "苏州市",
                "text": "吴中区",
                "level": 3,
                "order": 1051,
                "relation_code": "320506",
                "relation_desc": "江苏省苏州市吴中区"
            },
            {
                "code": "100506",
                "parentcode": "1005",
                "parenttext": "苏州市",
                "text": "相城区",
                "level": 3,
                "order": 1052,
                "relation_code": "320507",
                "relation_desc": "江苏省苏州市相城区"
            },
            {
                "code": "100507",
                "parentcode": "1005",
                "parenttext": "苏州市",
                "text": "常熟市",
                "level": 3,
                "order": 1053,
                "relation_code": "320581",
                "relation_desc": "江苏省常熟市"
            },
            {
                "code": "100508",
                "parentcode": "1005",
                "parenttext": "苏州市",
                "text": "张家港市",
                "level": 3,
                "order": 1054,
                "relation_code": "320582",
                "relation_desc": "江苏省张家港市"
            },
            {
                "code": "100509",
                "parentcode": "1005",
                "parenttext": "苏州市",
                "text": "昆山市",
                "level": 3,
                "order": 1055,
                "relation_code": "320583",
                "relation_desc": "江苏省昆山市"
            },
            {
                "code": "100510",
                "parentcode": "1005",
                "parenttext": "苏州市",
                "text": "吴江区",
                "level": 3,
                "order": 1056,
                "relation_code": "320509",
                "relation_desc": "江苏省苏州市吴江区"
            },
            {
                "code": "100511",
                "parentcode": "1005",
                "parenttext": "苏州市",
                "text": "太仓市",
                "level": 3,
                "order": 1057,
                "relation_code": "320585",
                "relation_desc": "江苏省太仓市"
            },
            {
                "code": "1006",
                "parentcode": "10",
                "parenttext": "江苏省",
                "text": "南通市",
                "level": 2,
                "order": 1058,
                "relation_code": "320600",
                "relation_desc": "江苏省南通市"
            },
            {
                "code": "100512",
                "parentcode": "1005",
                "parenttext": "苏州市",
                "text": "苏州工业园区",
                "level": 3,
                "order": 1058,
                "relation_code": "320501",
                "relation_desc": "江苏省苏州市"
            },
            {
                "code": "100601",
                "parentcode": "1006",
                "parenttext": "南通市",
                "text": "崇川区",
                "level": 3,
                "order": 1059,
                "relation_code": "320602",
                "relation_desc": "江苏省南通市崇川区"
            },
            {
                "code": "100513",
                "parentcode": "1005",
                "parenttext": "苏州市",
                "text": "吴江市",
                "level": 3,
                "order": 1059,
                "relation_code": "320501",
                "relation_desc": "江苏省吴江市"
            },
            {
                "code": "100602",
                "parentcode": "1006",
                "parenttext": "南通市",
                "text": "港闸区",
                "level": 3,
                "order": 1060,
                "relation_code": "320611",
                "relation_desc": "江苏省南通市港闸区"
            },
            {
                "code": "100603",
                "parentcode": "1006",
                "parenttext": "南通市",
                "text": "海安市",
                "level": 3,
                "order": 1061,
                "relation_code": "320601",
                "relation_desc": "江苏省海安县"
            },
            {
                "code": "100604",
                "parentcode": "1006",
                "parenttext": "南通市",
                "text": "如东县",
                "level": 3,
                "order": 1062,
                "relation_code": "320623",
                "relation_desc": "江苏省如东县"
            },
            {
                "code": "100605",
                "parentcode": "1006",
                "parenttext": "南通市",
                "text": "启东市",
                "level": 3,
                "order": 1063,
                "relation_code": "320681",
                "relation_desc": "江苏省启东市"
            },
            {
                "code": "100606",
                "parentcode": "1006",
                "parenttext": "南通市",
                "text": "如皋市",
                "level": 3,
                "order": 1064,
                "relation_code": "320682",
                "relation_desc": "江苏省如皋市"
            },
            {
                "code": "100607",
                "parentcode": "1006",
                "parenttext": "南通市",
                "text": "通州市",
                "level": 3,
                "order": 1065,
                "relation_code": "320601",
                "relation_desc": "江苏省通州市"
            },
            {
                "code": "100608",
                "parentcode": "1006",
                "parenttext": "南通市",
                "text": "海门市",
                "level": 3,
                "order": 1066,
                "relation_code": "320684",
                "relation_desc": "江苏省海门市"
            },
            {
                "code": "1007",
                "parentcode": "10",
                "parenttext": "江苏省",
                "text": "连云港市",
                "level": 2,
                "order": 1067,
                "relation_code": "320700",
                "relation_desc": "江苏省连云港市"
            },
            {
                "code": "100609",
                "parentcode": "1006",
                "parenttext": "南通市",
                "text": "通州区",
                "level": 3,
                "order": 1067,
                "relation_code": "320612",
                "relation_desc": "江苏省南通市通州区"
            },
            {
                "code": "100701",
                "parentcode": "1007",
                "parenttext": "连云港市",
                "text": "连云区",
                "level": 3,
                "order": 1068,
                "relation_code": "320703",
                "relation_desc": "江苏省连云港市连云区"
            },
            {
                "code": "100610",
                "parentcode": "1006",
                "parenttext": "南通市",
                "text": "南通经济技术开发区",
                "level": 3,
                "order": 1068,
                "relation_code": "320601",
                "relation_desc": "江苏省南通市"
            },
            {
                "code": "100702",
                "parentcode": "1007",
                "parenttext": "连云港市",
                "text": "新浦区",
                "level": 3,
                "order": 1069,
                "relation_code": "320701",
                "relation_desc": "江苏省连云港市新浦区"
            },
            {
                "code": "100703",
                "parentcode": "1007",
                "parenttext": "连云港市",
                "text": "海州区",
                "level": 3,
                "order": 1070,
                "relation_code": "320706",
                "relation_desc": "江苏省连云港市海州区"
            },
            {
                "code": "100704",
                "parentcode": "1007",
                "parenttext": "连云港市",
                "text": "赣榆区",
                "level": 3,
                "order": 1071,
                "relation_code": "320707",
                "relation_desc": "江苏省连云港市赣榆区"
            },
            {
                "code": "100705",
                "parentcode": "1007",
                "parenttext": "连云港市",
                "text": "东海县",
                "level": 3,
                "order": 1072,
                "relation_code": "320722",
                "relation_desc": "江苏省东海县"
            },
            {
                "code": "100706",
                "parentcode": "1007",
                "parenttext": "连云港市",
                "text": "灌云县",
                "level": 3,
                "order": 1073,
                "relation_code": "320723",
                "relation_desc": "江苏省灌云县"
            },
            {
                "code": "100707",
                "parentcode": "1007",
                "parenttext": "连云港市",
                "text": "灌南县",
                "level": 3,
                "order": 1074,
                "relation_code": "320724",
                "relation_desc": "江苏省灌南县"
            },
            {
                "code": "1008",
                "parentcode": "10",
                "parenttext": "江苏省",
                "text": "淮安市",
                "level": 2,
                "order": 1075,
                "relation_code": "320800",
                "relation_desc": "江苏省淮安市"
            },
            {
                "code": "100708",
                "parentcode": "1007",
                "parenttext": "连云港市",
                "text": "连云港经济技术开发区",
                "level": 3,
                "order": 1075,
                "relation_code": "320701",
                "relation_desc": "江苏省连云港市"
            },
            {
                "code": "100801",
                "parentcode": "1008",
                "parenttext": "淮安市",
                "text": "清江浦区",
                "level": 3,
                "order": 1076,
                "relation_code": "320812",
                "relation_desc": "江苏省淮安市清江浦区"
            },
            {
                "code": "100709",
                "parentcode": "1007",
                "parenttext": "连云港市",
                "text": "连云港高新技术产业开发区",
                "level": 3,
                "order": 1076,
                "relation_code": "320701",
                "relation_desc": "江苏省连云港市"
            },
            {
                "code": "100802",
                "parentcode": "1008",
                "parenttext": "淮安市",
                "text": "淮安区",
                "level": 3,
                "order": 1077,
                "relation_code": "320803",
                "relation_desc": "江苏省淮安市淮安区"
            },
            {
                "code": "100803",
                "parentcode": "1008",
                "parenttext": "淮安市",
                "text": "淮阴区",
                "level": 3,
                "order": 1078,
                "relation_code": "320804",
                "relation_desc": "江苏省淮安市淮阴区"
            },
            {
                "code": "100805",
                "parentcode": "1008",
                "parenttext": "淮安市",
                "text": "涟水县",
                "level": 3,
                "order": 1080,
                "relation_code": "320826",
                "relation_desc": "江苏省涟水县"
            },
            {
                "code": "100806",
                "parentcode": "1008",
                "parenttext": "淮安市",
                "text": "洪泽区",
                "level": 3,
                "order": 1081,
                "relation_code": "320813",
                "relation_desc": "江苏省淮安市洪泽区"
            },
            {
                "code": "100807",
                "parentcode": "1008",
                "parenttext": "淮安市",
                "text": "盱眙县",
                "level": 3,
                "order": 1082,
                "relation_code": "320830",
                "relation_desc": "江苏省盱眙县"
            },
            {
                "code": "100808",
                "parentcode": "1008",
                "parenttext": "淮安市",
                "text": "金湖县",
                "level": 3,
                "order": 1083,
                "relation_code": "320831",
                "relation_desc": "江苏省金湖县"
            },
            {
                "code": "1009",
                "parentcode": "10",
                "parenttext": "江苏省",
                "text": "盐城市",
                "level": 2,
                "order": 1084,
                "relation_code": "320900",
                "relation_desc": "江苏省盐城市"
            },
            {
                "code": "100809",
                "parentcode": "1008",
                "parenttext": "淮安市",
                "text": "清河区",
                "level": 3,
                "order": 1084,
                "relation_code": "320811",
                "relation_desc": "江苏省淮安市清河区"
            },
            {
                "code": "100901",
                "parentcode": "1009",
                "parenttext": "盐城市",
                "text": "亭湖区",
                "level": 3,
                "order": 1085,
                "relation_code": "320902",
                "relation_desc": "江苏省盐城市亭湖区"
            },
            {
                "code": "100810",
                "parentcode": "1008",
                "parenttext": "淮安市",
                "text": "清浦区",
                "level": 3,
                "order": 1085,
                "relation_code": "320811",
                "relation_desc": "江苏省淮安市清浦区"
            },
            {
                "code": "100902",
                "parentcode": "1009",
                "parenttext": "盐城市",
                "text": "盐都区",
                "level": 3,
                "order": 1086,
                "relation_code": "320903",
                "relation_desc": "江苏省盐城市盐都区"
            },
            {
                "code": "100811",
                "parentcode": "1008",
                "parenttext": "淮安市",
                "text": "淮安经济技术开发区",
                "level": 3,
                "order": 1086,
                "relation_code": "320811",
                "relation_desc": "江苏省淮安市"
            },
            {
                "code": "100903",
                "parentcode": "1009",
                "parenttext": "盐城市",
                "text": "响水县",
                "level": 3,
                "order": 1087,
                "relation_code": "320921",
                "relation_desc": "江苏省响水县"
            },
            {
                "code": "100904",
                "parentcode": "1009",
                "parenttext": "盐城市",
                "text": "滨海县",
                "level": 3,
                "order": 1088,
                "relation_code": "320922",
                "relation_desc": "江苏省滨海县"
            },
            {
                "code": "100905",
                "parentcode": "1009",
                "parenttext": "盐城市",
                "text": "阜宁县",
                "level": 3,
                "order": 1089,
                "relation_code": "320923",
                "relation_desc": "江苏省阜宁县"
            },
            {
                "code": "100906",
                "parentcode": "1009",
                "parenttext": "盐城市",
                "text": "射阳县",
                "level": 3,
                "order": 1090,
                "relation_code": "320924",
                "relation_desc": "江苏省射阳县"
            },
            {
                "code": "100907",
                "parentcode": "1009",
                "parenttext": "盐城市",
                "text": "建湖县",
                "level": 3,
                "order": 1091,
                "relation_code": "320925",
                "relation_desc": "江苏省建湖县"
            },
            {
                "code": "100908",
                "parentcode": "1009",
                "parenttext": "盐城市",
                "text": "东台市",
                "level": 3,
                "order": 1092,
                "relation_code": "320981",
                "relation_desc": "江苏省东台市"
            },
            {
                "code": "100909",
                "parentcode": "1009",
                "parenttext": "盐城市",
                "text": "大丰区",
                "level": 3,
                "order": 1093,
                "relation_code": "320904",
                "relation_desc": "江苏省盐城市大丰区"
            },
            {
                "code": "1010",
                "parentcode": "10",
                "parenttext": "江苏省",
                "text": "扬州市",
                "level": 2,
                "order": 1094,
                "relation_code": "321000",
                "relation_desc": "江苏省扬州市"
            },
            {
                "code": "100910",
                "parentcode": "1009",
                "parenttext": "盐城市",
                "text": "盐城经济技术开发区",
                "level": 3,
                "order": 1094,
                "relation_code": "320901",
                "relation_desc": "江苏省盐城市"
            },
            {
                "code": "101001",
                "parentcode": "1010",
                "parenttext": "扬州市",
                "text": "广陵区",
                "level": 3,
                "order": 1095,
                "relation_code": "321002",
                "relation_desc": "江苏省扬州市广陵区"
            },
            {
                "code": "101002",
                "parentcode": "1010",
                "parenttext": "扬州市",
                "text": "邗江区",
                "level": 3,
                "order": 1096,
                "relation_code": "321003",
                "relation_desc": "江苏省扬州市邗江区"
            },
            {
                "code": "101003",
                "parentcode": "1010",
                "parenttext": "扬州市",
                "text": "郊区",
                "level": 3,
                "order": 1097,
                "relation_code": "321001",
                "relation_desc": "江苏省扬州市郊区"
            },
            {
                "code": "101004",
                "parentcode": "1010",
                "parenttext": "扬州市",
                "text": "宝应县",
                "level": 3,
                "order": 1098,
                "relation_code": "321023",
                "relation_desc": "江苏省宝应县"
            },
            {
                "code": "101005",
                "parentcode": "1010",
                "parenttext": "扬州市",
                "text": "仪征市",
                "level": 3,
                "order": 1099,
                "relation_code": "321081",
                "relation_desc": "江苏省仪征市"
            },
            {
                "code": "101006",
                "parentcode": "1010",
                "parenttext": "扬州市",
                "text": "高邮市",
                "level": 3,
                "order": 1100,
                "relation_code": "321084",
                "relation_desc": "江苏省高邮市"
            },
            {
                "code": "101007",
                "parentcode": "1010",
                "parenttext": "扬州市",
                "text": "江都区",
                "level": 3,
                "order": 1101,
                "relation_code": "321012",
                "relation_desc": "江苏省扬州市江都区"
            },
            {
                "code": "1011",
                "parentcode": "10",
                "parenttext": "江苏省",
                "text": "镇江市",
                "level": 2,
                "order": 1102,
                "relation_code": "321100",
                "relation_desc": "江苏省镇江市"
            },
            {
                "code": "101008",
                "parentcode": "1010",
                "parenttext": "扬州市",
                "text": "扬州经济技术开发区",
                "level": 3,
                "order": 1102,
                "relation_code": "321001",
                "relation_desc": "江苏省扬州市"
            },
            {
                "code": "101101",
                "parentcode": "1011",
                "parenttext": "镇江市",
                "text": "京口区",
                "level": 3,
                "order": 1103,
                "relation_code": "321102",
                "relation_desc": "江苏省镇江市京口区"
            },
            {
                "code": "101102",
                "parentcode": "1011",
                "parenttext": "镇江市",
                "text": "润州区",
                "level": 3,
                "order": 1104,
                "relation_code": "321111",
                "relation_desc": "江苏省镇江市润州区"
            },
            {
                "code": "101103",
                "parentcode": "1011",
                "parenttext": "镇江市",
                "text": "丹徒区",
                "level": 3,
                "order": 1105,
                "relation_code": "321112",
                "relation_desc": "江苏省镇江市丹徒区"
            },
            {
                "code": "101104",
                "parentcode": "1011",
                "parenttext": "镇江市",
                "text": "丹阳市",
                "level": 3,
                "order": 1106,
                "relation_code": "321181",
                "relation_desc": "江苏省丹阳市"
            },
            {
                "code": "101105",
                "parentcode": "1011",
                "parenttext": "镇江市",
                "text": "扬中市",
                "level": 3,
                "order": 1107,
                "relation_code": "321182",
                "relation_desc": "江苏省扬中市"
            },
            {
                "code": "101106",
                "parentcode": "1011",
                "parenttext": "镇江市",
                "text": "句容市",
                "level": 3,
                "order": 1108,
                "relation_code": "321183",
                "relation_desc": "江苏省句容市"
            },
            {
                "code": "1012",
                "parentcode": "10",
                "parenttext": "江苏省",
                "text": "泰州市",
                "level": 2,
                "order": 1109,
                "relation_code": "321200",
                "relation_desc": "江苏省泰州市"
            },
            {
                "code": "101107",
                "parentcode": "1011",
                "parenttext": "镇江市",
                "text": "镇江新区",
                "level": 3,
                "order": 1109,
                "relation_code": "321101",
                "relation_desc": "江苏省镇江市"
            },
            {
                "code": "101201",
                "parentcode": "1012",
                "parenttext": "泰州市",
                "text": "海陵区",
                "level": 3,
                "order": 1110,
                "relation_code": "321202",
                "relation_desc": "江苏省泰州市海陵区"
            },
            {
                "code": "101202",
                "parentcode": "1012",
                "parenttext": "泰州市",
                "text": "高港区",
                "level": 3,
                "order": 1111,
                "relation_code": "321203",
                "relation_desc": "江苏省泰州市高港区"
            },
            {
                "code": "101203",
                "parentcode": "1012",
                "parenttext": "泰州市",
                "text": "兴化市",
                "level": 3,
                "order": 1112,
                "relation_code": "321281",
                "relation_desc": "江苏省兴化市"
            },
            {
                "code": "101204",
                "parentcode": "1012",
                "parenttext": "泰州市",
                "text": "靖江市",
                "level": 3,
                "order": 1113,
                "relation_code": "321282",
                "relation_desc": "江苏省靖江市"
            },
            {
                "code": "101205",
                "parentcode": "1012",
                "parenttext": "泰州市",
                "text": "泰兴市",
                "level": 3,
                "order": 1114,
                "relation_code": "321283",
                "relation_desc": "江苏省泰兴市"
            },
            {
                "code": "101206",
                "parentcode": "1012",
                "parenttext": "泰州市",
                "text": "姜堰区",
                "level": 3,
                "order": 1115,
                "relation_code": "321204",
                "relation_desc": "江苏省泰州市姜堰区"
            },
            {
                "code": "1013",
                "parentcode": "10",
                "parenttext": "江苏省",
                "text": "宿迁市",
                "level": 2,
                "order": 1116,
                "relation_code": "321300",
                "relation_desc": "江苏省宿迁市"
            },
            {
                "code": "101207",
                "parentcode": "1012",
                "parenttext": "泰州市",
                "text": "泰州医药高新技术产业开发区",
                "level": 3,
                "order": 1116,
                "relation_code": "321201",
                "relation_desc": "江苏省泰州市"
            },
            {
                "code": "101301",
                "parentcode": "1013",
                "parenttext": "宿迁市",
                "text": "宿城区",
                "level": 3,
                "order": 1117,
                "relation_code": "321302",
                "relation_desc": "江苏省宿迁市宿城区"
            },
            {
                "code": "11",
                "parentcode": "0",
                "parenttext": "0",
                "text": "浙江省",
                "level": 1,
                "order": 1122,
                "relation_code": "",
                "relation_desc": "浙江省"
            },
            {
                "code": "1101",
                "parentcode": "11",
                "parenttext": "浙江省",
                "text": "杭州市",
                "level": 2,
                "order": 1123,
                "relation_code": "330100",
                "relation_desc": "浙江省杭州市"
            },
            {
                "code": "110101",
                "parentcode": "1101",
                "parenttext": "杭州市",
                "text": "上城区",
                "level": 3,
                "order": 1124,
                "relation_code": "330102",
                "relation_desc": "浙江省杭州市上城区"
            },
            {
                "code": "110102",
                "parentcode": "1101",
                "parenttext": "杭州市",
                "text": "下城区",
                "level": 3,
                "order": 1125,
                "relation_code": "330103",
                "relation_desc": "浙江省杭州市下城区"
            },
            {
                "code": "110103",
                "parentcode": "1101",
                "parenttext": "杭州市",
                "text": "江干区",
                "level": 3,
                "order": 1126,
                "relation_code": "330104",
                "relation_desc": "浙江省杭州市江干区"
            },
            {
                "code": "110104",
                "parentcode": "1101",
                "parenttext": "杭州市",
                "text": "拱墅区",
                "level": 3,
                "order": 1127,
                "relation_code": "330105",
                "relation_desc": "浙江省杭州市拱墅区"
            },
            {
                "code": "110105",
                "parentcode": "1101",
                "parenttext": "杭州市",
                "text": "西湖区",
                "level": 3,
                "order": 1128,
                "relation_code": "330106",
                "relation_desc": "浙江省杭州市西湖区"
            },
            {
                "code": "110106",
                "parentcode": "1101",
                "parenttext": "杭州市",
                "text": "滨江区",
                "level": 3,
                "order": 1129,
                "relation_code": "330108",
                "relation_desc": "浙江省杭州市滨江区"
            },
            {
                "code": "110107",
                "parentcode": "1101",
                "parenttext": "杭州市",
                "text": "萧山区",
                "level": 3,
                "order": 1130,
                "relation_code": "330109",
                "relation_desc": "浙江省杭州市萧山区"
            },
            {
                "code": "110108",
                "parentcode": "1101",
                "parenttext": "杭州市",
                "text": "余杭区",
                "level": 3,
                "order": 1131,
                "relation_code": "330110",
                "relation_desc": "浙江省杭州市余杭区"
            },
            {
                "code": "110109",
                "parentcode": "1101",
                "parenttext": "杭州市",
                "text": "桐庐县",
                "level": 3,
                "order": 1132,
                "relation_code": "330122",
                "relation_desc": "浙江省桐庐县"
            },
            {
                "code": "110110",
                "parentcode": "1101",
                "parenttext": "杭州市",
                "text": "淳安县",
                "level": 3,
                "order": 1133,
                "relation_code": "330127",
                "relation_desc": "浙江省淳安县"
            },
            {
                "code": "110111",
                "parentcode": "1101",
                "parenttext": "杭州市",
                "text": "建德市",
                "level": 3,
                "order": 1134,
                "relation_code": "330182",
                "relation_desc": "浙江省建德市"
            },
            {
                "code": "110112",
                "parentcode": "1101",
                "parenttext": "杭州市",
                "text": "富阳区",
                "level": 3,
                "order": 1135,
                "relation_code": "330111",
                "relation_desc": "浙江省杭州市富阳区"
            },
            {
                "code": "110113",
                "parentcode": "1101",
                "parenttext": "杭州市",
                "text": "临安区",
                "level": 3,
                "order": 1136,
                "relation_code": "330112",
                "relation_desc": "浙江省杭州市临安区"
            },
            {
                "code": "1102",
                "parentcode": "11",
                "parenttext": "浙江省",
                "text": "宁波市",
                "level": 2,
                "order": 1137,
                "relation_code": "330200",
                "relation_desc": "浙江省宁波市"
            },
            {
                "code": "110201",
                "parentcode": "1102",
                "parenttext": "宁波市",
                "text": "海曙区",
                "level": 3,
                "order": 1138,
                "relation_code": "330203",
                "relation_desc": "浙江省宁波市海曙区"
            },
            {
                "code": "110202",
                "parentcode": "1102",
                "parenttext": "宁波市",
                "text": "江东区",
                "level": 3,
                "order": 1139,
                "relation_code": "330201",
                "relation_desc": "浙江省宁波市江东区"
            },
            {
                "code": "110203",
                "parentcode": "1102",
                "parenttext": "宁波市",
                "text": "江北区",
                "level": 3,
                "order": 1140,
                "relation_code": "330205",
                "relation_desc": "浙江省宁波市江北区"
            },
            {
                "code": "110204",
                "parentcode": "1102",
                "parenttext": "宁波市",
                "text": "北仑区",
                "level": 3,
                "order": 1141,
                "relation_code": "330206",
                "relation_desc": "浙江省宁波市北仑区"
            },
            {
                "code": "110205",
                "parentcode": "1102",
                "parenttext": "宁波市",
                "text": "镇海区",
                "level": 3,
                "order": 1142,
                "relation_code": "330211",
                "relation_desc": "浙江省宁波市镇海区"
            },
            {
                "code": "110206",
                "parentcode": "1102",
                "parenttext": "宁波市",
                "text": "鄞州区",
                "level": 3,
                "order": 1143,
                "relation_code": "330212",
                "relation_desc": "浙江省宁波市鄞州区"
            },
            {
                "code": "110207",
                "parentcode": "1102",
                "parenttext": "宁波市",
                "text": "象山县",
                "level": 3,
                "order": 1144,
                "relation_code": "330225",
                "relation_desc": "浙江省象山县"
            },
            {
                "code": "110208",
                "parentcode": "1102",
                "parenttext": "宁波市",
                "text": "宁海县",
                "level": 3,
                "order": 1145,
                "relation_code": "330226",
                "relation_desc": "浙江省宁海县"
            },
            {
                "code": "110209",
                "parentcode": "1102",
                "parenttext": "宁波市",
                "text": "余姚市",
                "level": 3,
                "order": 1146,
                "relation_code": "330281",
                "relation_desc": "浙江省余姚市"
            },
            {
                "code": "110210",
                "parentcode": "1102",
                "parenttext": "宁波市",
                "text": "慈溪市",
                "level": 3,
                "order": 1147,
                "relation_code": "330282",
                "relation_desc": "浙江省慈溪市"
            },
            {
                "code": "110211",
                "parentcode": "1102",
                "parenttext": "宁波市",
                "text": "奉化区",
                "level": 3,
                "order": 1148,
                "relation_code": "330213",
                "relation_desc": "浙江省宁波市奉化区"
            },
            {
                "code": "1103",
                "parentcode": "11",
                "parenttext": "浙江省",
                "text": "温州市",
                "level": 2,
                "order": 1149,
                "relation_code": "330300",
                "relation_desc": "浙江省温州市"
            },
            {
                "code": "110301",
                "parentcode": "1103",
                "parenttext": "温州市",
                "text": "鹿城区",
                "level": 3,
                "order": 1150,
                "relation_code": "330302",
                "relation_desc": "浙江省温州市鹿城区"
            },
            {
                "code": "110302",
                "parentcode": "1103",
                "parenttext": "温州市",
                "text": "龙湾区",
                "level": 3,
                "order": 1151,
                "relation_code": "330303",
                "relation_desc": "浙江省温州市龙湾区"
            },
            {
                "code": "110303",
                "parentcode": "1103",
                "parenttext": "温州市",
                "text": "瓯海区",
                "level": 3,
                "order": 1152,
                "relation_code": "330304",
                "relation_desc": "浙江省温州市瓯海区"
            },
            {
                "code": "110304",
                "parentcode": "1103",
                "parenttext": "温州市",
                "text": "洞头区",
                "level": 3,
                "order": 1153,
                "relation_code": "330305",
                "relation_desc": "浙江省温州市洞头区"
            },
            {
                "code": "110305",
                "parentcode": "1103",
                "parenttext": "温州市",
                "text": "永嘉县",
                "level": 3,
                "order": 1154,
                "relation_code": "330324",
                "relation_desc": "浙江省永嘉县"
            },
            {
                "code": "110306",
                "parentcode": "1103",
                "parenttext": "温州市",
                "text": "平阳县",
                "level": 3,
                "order": 1155,
                "relation_code": "330326",
                "relation_desc": "浙江省平阳县"
            },
            {
                "code": "110307",
                "parentcode": "1103",
                "parenttext": "温州市",
                "text": "苍南县",
                "level": 3,
                "order": 1156,
                "relation_code": "330327",
                "relation_desc": "浙江省苍南县"
            },
            {
                "code": "110308",
                "parentcode": "1103",
                "parenttext": "温州市",
                "text": "文成县",
                "level": 3,
                "order": 1157,
                "relation_code": "330328",
                "relation_desc": "浙江省文成县"
            },
            {
                "code": "110309",
                "parentcode": "1103",
                "parenttext": "温州市",
                "text": "泰顺县",
                "level": 3,
                "order": 1158,
                "relation_code": "330329",
                "relation_desc": "浙江省泰顺县"
            },
            {
                "code": "110310",
                "parentcode": "1103",
                "parenttext": "温州市",
                "text": "瑞安市",
                "level": 3,
                "order": 1159,
                "relation_code": "330381",
                "relation_desc": "浙江省瑞安市"
            },
            {
                "code": "110311",
                "parentcode": "1103",
                "parenttext": "温州市",
                "text": "乐清市",
                "level": 3,
                "order": 1160,
                "relation_code": "330382",
                "relation_desc": "浙江省乐清市"
            },
            {
                "code": "1104",
                "parentcode": "11",
                "parenttext": "浙江省",
                "text": "嘉兴市",
                "level": 2,
                "order": 1161,
                "relation_code": "330400",
                "relation_desc": "浙江省嘉兴市"
            },
            {
                "code": "110312",
                "parentcode": "1103",
                "parenttext": "温州市",
                "text": "温州经济技术开发区",
                "level": 3,
                "order": 1161,
                "relation_code": "330301",
                "relation_desc": "浙江省温州市"
            },
            {
                "code": "110401",
                "parentcode": "1104",
                "parenttext": "嘉兴市",
                "text": "南湖区",
                "level": 3,
                "order": 1162,
                "relation_code": "330402",
                "relation_desc": "浙江省嘉兴市南湖区"
            },
            {
                "code": "110313",
                "parentcode": "1103",
                "parenttext": "温州市",
                "text": "龙港市",
                "level": 3,
                "order": 1162,
                "relation_code": "330301",
                "relation_desc": "浙江省温州市"
            },
            {
                "code": "110402",
                "parentcode": "1104",
                "parenttext": "嘉兴市",
                "text": "秀洲区",
                "level": 3,
                "order": 1163,
                "relation_code": "330411",
                "relation_desc": "浙江省嘉兴市秀洲区"
            },
            {
                "code": "110403",
                "parentcode": "1104",
                "parenttext": "嘉兴市",
                "text": "嘉善县",
                "level": 3,
                "order": 1164,
                "relation_code": "330421",
                "relation_desc": "浙江省嘉善县"
            },
            {
                "code": "110404",
                "parentcode": "1104",
                "parenttext": "嘉兴市",
                "text": "海盐县",
                "level": 3,
                "order": 1165,
                "relation_code": "330424",
                "relation_desc": "浙江省海盐县"
            },
            {
                "code": "110405",
                "parentcode": "1104",
                "parenttext": "嘉兴市",
                "text": "海宁市",
                "level": 3,
                "order": 1166,
                "relation_code": "330481",
                "relation_desc": "浙江省海宁市"
            },
            {
                "code": "110406",
                "parentcode": "1104",
                "parenttext": "嘉兴市",
                "text": "平湖市",
                "level": 3,
                "order": 1167,
                "relation_code": "330482",
                "relation_desc": "浙江省平湖市"
            },
            {
                "code": "110407",
                "parentcode": "1104",
                "parenttext": "嘉兴市",
                "text": "桐乡市",
                "level": 3,
                "order": 1168,
                "relation_code": "330483",
                "relation_desc": "浙江省桐乡市"
            },
            {
                "code": "1105",
                "parentcode": "11",
                "parenttext": "浙江省",
                "text": "湖州市",
                "level": 2,
                "order": 1169,
                "relation_code": "330500",
                "relation_desc": "浙江省湖州市"
            },
            {
                "code": "110501",
                "parentcode": "1105",
                "parenttext": "湖州市",
                "text": "吴兴区",
                "level": 3,
                "order": 1170,
                "relation_code": "330502",
                "relation_desc": "浙江省湖州市吴兴区"
            },
            {
                "code": "110502",
                "parentcode": "1105",
                "parenttext": "湖州市",
                "text": "南浔区",
                "level": 3,
                "order": 1171,
                "relation_code": "330503",
                "relation_desc": "浙江省湖州市南浔区"
            },
            {
                "code": "110503",
                "parentcode": "1105",
                "parenttext": "湖州市",
                "text": "德清县",
                "level": 3,
                "order": 1172,
                "relation_code": "330521",
                "relation_desc": "浙江省德清县"
            },
            {
                "code": "110504",
                "parentcode": "1105",
                "parenttext": "湖州市",
                "text": "长兴县",
                "level": 3,
                "order": 1173,
                "relation_code": "330522",
                "relation_desc": "浙江省长兴县"
            },
            {
                "code": "110505",
                "parentcode": "1105",
                "parenttext": "湖州市",
                "text": "安吉县",
                "level": 3,
                "order": 1174,
                "relation_code": "330523",
                "relation_desc": "浙江省安吉县"
            },
            {
                "code": "1106",
                "parentcode": "11",
                "parenttext": "浙江省",
                "text": "绍兴市",
                "level": 2,
                "order": 1175,
                "relation_code": "330600",
                "relation_desc": "浙江省绍兴市"
            },
            {
                "code": "110601",
                "parentcode": "1106",
                "parenttext": "绍兴市",
                "text": "越城区",
                "level": 3,
                "order": 1176,
                "relation_code": "330602",
                "relation_desc": "浙江省绍兴市越城区"
            },
            {
                "code": "110602",
                "parentcode": "1106",
                "parenttext": "绍兴市",
                "text": "柯桥区",
                "level": 3,
                "order": 1177,
                "relation_code": "330603",
                "relation_desc": "浙江省绍兴市柯桥区"
            },
            {
                "code": "110603",
                "parentcode": "1106",
                "parenttext": "绍兴市",
                "text": "新昌县",
                "level": 3,
                "order": 1178,
                "relation_code": "330624",
                "relation_desc": "浙江省新昌县"
            },
            {
                "code": "110604",
                "parentcode": "1106",
                "parenttext": "绍兴市",
                "text": "诸暨市",
                "level": 3,
                "order": 1179,
                "relation_code": "330681",
                "relation_desc": "浙江省诸暨市"
            },
            {
                "code": "110605",
                "parentcode": "1106",
                "parenttext": "绍兴市",
                "text": "上虞区",
                "level": 3,
                "order": 1180,
                "relation_code": "330604",
                "relation_desc": "浙江省绍兴市上虞区"
            },
            {
                "code": "110606",
                "parentcode": "1106",
                "parenttext": "绍兴市",
                "text": "嵊州市",
                "level": 3,
                "order": 1181,
                "relation_code": "330683",
                "relation_desc": "浙江省嵊州市"
            },
            {
                "code": "1107",
                "parentcode": "11",
                "parenttext": "浙江省",
                "text": "金华市",
                "level": 2,
                "order": 1182,
                "relation_code": "330700",
                "relation_desc": "浙江省金华市"
            },
            {
                "code": "110701",
                "parentcode": "1107",
                "parenttext": "金华市",
                "text": "婺城区",
                "level": 3,
                "order": 1183,
                "relation_code": "330702",
                "relation_desc": "浙江省金华市婺城区"
            },
            {
                "code": "110702",
                "parentcode": "1107",
                "parenttext": "金华市",
                "text": "金东区",
                "level": 3,
                "order": 1184,
                "relation_code": "330703",
                "relation_desc": "浙江省金华市金东区"
            },
            {
                "code": "110703",
                "parentcode": "1107",
                "parenttext": "金华市",
                "text": "武义县",
                "level": 3,
                "order": 1185,
                "relation_code": "330723",
                "relation_desc": "浙江省武义县"
            },
            {
                "code": "110704",
                "parentcode": "1107",
                "parenttext": "金华市",
                "text": "浦江县",
                "level": 3,
                "order": 1186,
                "relation_code": "330726",
                "relation_desc": "浙江省浦江县"
            },
            {
                "code": "110705",
                "parentcode": "1107",
                "parenttext": "金华市",
                "text": "磐安县",
                "level": 3,
                "order": 1187,
                "relation_code": "330727",
                "relation_desc": "浙江省磐安县"
            },
            {
                "code": "110706",
                "parentcode": "1107",
                "parenttext": "金华市",
                "text": "兰溪市",
                "level": 3,
                "order": 1188,
                "relation_code": "330781",
                "relation_desc": "浙江省兰溪市"
            },
            {
                "code": "110707",
                "parentcode": "1107",
                "parenttext": "金华市",
                "text": "义乌市",
                "level": 3,
                "order": 1189,
                "relation_code": "330782",
                "relation_desc": "浙江省义乌市"
            },
            {
                "code": "110708",
                "parentcode": "1107",
                "parenttext": "金华市",
                "text": "东阳市",
                "level": 3,
                "order": 1190,
                "relation_code": "330783",
                "relation_desc": "浙江省东阳市"
            },
            {
                "code": "110709",
                "parentcode": "1107",
                "parenttext": "金华市",
                "text": "永康市",
                "level": 3,
                "order": 1191,
                "relation_code": "330784",
                "relation_desc": "浙江省永康市"
            },
            {
                "code": "1108",
                "parentcode": "11",
                "parenttext": "浙江省",
                "text": "衢州市",
                "level": 2,
                "order": 1192,
                "relation_code": "330800",
                "relation_desc": "浙江省衢州市"
            },
            {
                "code": "110801",
                "parentcode": "1108",
                "parenttext": "衢州市",
                "text": "柯城区",
                "level": 3,
                "order": 1193,
                "relation_code": "330802",
                "relation_desc": "浙江省衢州市柯城区"
            },
            {
                "code": "110802",
                "parentcode": "1108",
                "parenttext": "衢州市",
                "text": "衢江区",
                "level": 3,
                "order": 1194,
                "relation_code": "330803",
                "relation_desc": "浙江省衢州市衢江区"
            },
            {
                "code": "110803",
                "parentcode": "1108",
                "parenttext": "衢州市",
                "text": "常山县",
                "level": 3,
                "order": 1195,
                "relation_code": "330822",
                "relation_desc": "浙江省常山县"
            },
            {
                "code": "110804",
                "parentcode": "1108",
                "parenttext": "衢州市",
                "text": "开化县",
                "level": 3,
                "order": 1196,
                "relation_code": "330824",
                "relation_desc": "浙江省开化县"
            },
            {
                "code": "110805",
                "parentcode": "1108",
                "parenttext": "衢州市",
                "text": "龙游县",
                "level": 3,
                "order": 1197,
                "relation_code": "330825",
                "relation_desc": "浙江省龙游县"
            },
            {
                "code": "110806",
                "parentcode": "1108",
                "parenttext": "衢州市",
                "text": "江山市",
                "level": 3,
                "order": 1198,
                "relation_code": "330881",
                "relation_desc": "浙江省江山市"
            },
            {
                "code": "1109",
                "parentcode": "11",
                "parenttext": "浙江省",
                "text": "舟山市",
                "level": 2,
                "order": 1199,
                "relation_code": "330900",
                "relation_desc": "浙江省舟山市"
            },
            {
                "code": "110901",
                "parentcode": "1109",
                "parenttext": "舟山市",
                "text": "定海区",
                "level": 3,
                "order": 1200,
                "relation_code": "330902",
                "relation_desc": "浙江省舟山市定海区"
            },
            {
                "code": "110902",
                "parentcode": "1109",
                "parenttext": "舟山市",
                "text": "普陀区",
                "level": 3,
                "order": 1201,
                "relation_code": "330903",
                "relation_desc": "浙江省舟山市普陀区"
            },
            {
                "code": "110903",
                "parentcode": "1109",
                "parenttext": "舟山市",
                "text": "岱山县",
                "level": 3,
                "order": 1202,
                "relation_code": "330921",
                "relation_desc": "浙江省岱山县"
            },
            {
                "code": "110904",
                "parentcode": "1109",
                "parenttext": "舟山市",
                "text": "嵊泗县",
                "level": 3,
                "order": 1203,
                "relation_code": "330922",
                "relation_desc": "浙江省嵊泗县"
            },
            {
                "code": "1110",
                "parentcode": "11",
                "parenttext": "浙江省",
                "text": "台州市",
                "level": 2,
                "order": 1204,
                "relation_code": "331000",
                "relation_desc": "浙江省台州市"
            },
            {
                "code": "111001",
                "parentcode": "1110",
                "parenttext": "台州市",
                "text": "椒江区",
                "level": 3,
                "order": 1205,
                "relation_code": "331002",
                "relation_desc": "浙江省台州市椒江区"
            },
            {
                "code": "111002",
                "parentcode": "1110",
                "parenttext": "台州市",
                "text": "黄岩区",
                "level": 3,
                "order": 1206,
                "relation_code": "331003",
                "relation_desc": "浙江省台州市黄岩区"
            },
            {
                "code": "111003",
                "parentcode": "1110",
                "parenttext": "台州市",
                "text": "路桥区",
                "level": 3,
                "order": 1207,
                "relation_code": "331004",
                "relation_desc": "浙江省台州市路桥区"
            },
            {
                "code": "111004",
                "parentcode": "1110",
                "parenttext": "台州市",
                "text": "玉环市",
                "level": 3,
                "order": 1208,
                "relation_code": "331001",
                "relation_desc": "浙江省玉环县"
            },
            {
                "code": "111005",
                "parentcode": "1110",
                "parenttext": "台州市",
                "text": "三门县",
                "level": 3,
                "order": 1209,
                "relation_code": "331022",
                "relation_desc": "浙江省三门县"
            },
            {
                "code": "111006",
                "parentcode": "1110",
                "parenttext": "台州市",
                "text": "天台县",
                "level": 3,
                "order": 1210,
                "relation_code": "331023",
                "relation_desc": "浙江省天台县"
            },
            {
                "code": "111007",
                "parentcode": "1110",
                "parenttext": "台州市",
                "text": "仙居县",
                "level": 3,
                "order": 1211,
                "relation_code": "331024",
                "relation_desc": "浙江省仙居县"
            },
            {
                "code": "111008",
                "parentcode": "1110",
                "parenttext": "台州市",
                "text": "温岭市",
                "level": 3,
                "order": 1212,
                "relation_code": "331081",
                "relation_desc": "浙江省温岭市"
            },
            {
                "code": "111009",
                "parentcode": "1110",
                "parenttext": "台州市",
                "text": "临海市",
                "level": 3,
                "order": 1213,
                "relation_code": "331082",
                "relation_desc": "浙江省临海市"
            },
            {
                "code": "1111",
                "parentcode": "11",
                "parenttext": "浙江省",
                "text": "丽水市",
                "level": 2,
                "order": 1214,
                "relation_code": "331100",
                "relation_desc": "浙江省丽水市"
            },
            {
                "code": "111101",
                "parentcode": "1111",
                "parenttext": "丽水市",
                "text": "莲都区",
                "level": 3,
                "order": 1215,
                "relation_code": "331102",
                "relation_desc": "浙江省丽水市莲都区"
            },
            {
                "code": "111102",
                "parentcode": "1111",
                "parenttext": "丽水市",
                "text": "青田县",
                "level": 3,
                "order": 1216,
                "relation_code": "331121",
                "relation_desc": "浙江省青田县"
            },
            {
                "code": "111103",
                "parentcode": "1111",
                "parenttext": "丽水市",
                "text": "缙云县",
                "level": 3,
                "order": 1217,
                "relation_code": "331122",
                "relation_desc": "浙江省缙云县"
            },
            {
                "code": "111104",
                "parentcode": "1111",
                "parenttext": "丽水市",
                "text": "遂昌县",
                "level": 3,
                "order": 1218,
                "relation_code": "331123",
                "relation_desc": "浙江省遂昌县"
            },
            {
                "code": "111105",
                "parentcode": "1111",
                "parenttext": "丽水市",
                "text": "松阳县",
                "level": 3,
                "order": 1219,
                "relation_code": "331124",
                "relation_desc": "浙江省松阳县"
            },
            {
                "code": "111106",
                "parentcode": "1111",
                "parenttext": "丽水市",
                "text": "云和县",
                "level": 3,
                "order": 1220,
                "relation_code": "331125",
                "relation_desc": "浙江省云和县"
            },
            {
                "code": "111107",
                "parentcode": "1111",
                "parenttext": "丽水市",
                "text": "庆元县",
                "level": 3,
                "order": 1221,
                "relation_code": "331126",
                "relation_desc": "浙江省庆元县"
            },
            {
                "code": "111108",
                "parentcode": "1111",
                "parenttext": "丽水市",
                "text": "景宁畲族自治县",
                "level": 3,
                "order": 1222,
                "relation_code": "331127",
                "relation_desc": "浙江省景宁畲族自治县"
            },
            {
                "code": "111109",
                "parentcode": "1111",
                "parenttext": "丽水市",
                "text": "龙泉市",
                "level": 3,
                "order": 1223,
                "relation_code": "331181",
                "relation_desc": "浙江省龙泉市"
            },
            {
                "code": "12",
                "parentcode": "0",
                "parenttext": "0",
                "text": "安徽省",
                "level": 1,
                "order": 1224,
                "relation_code": "",
                "relation_desc": "安徽省"
            },
            {
                "code": "1201",
                "parentcode": "12",
                "parenttext": "安徽省",
                "text": "合肥市",
                "level": 2,
                "order": 1225,
                "relation_code": "340100",
                "relation_desc": "安徽省合肥市"
            },
            {
                "code": "120101",
                "parentcode": "1201",
                "parenttext": "合肥市",
                "text": "瑶海区",
                "level": 3,
                "order": 1226,
                "relation_code": "340102",
                "relation_desc": "安徽省合肥市瑶海区"
            },
            {
                "code": "120102",
                "parentcode": "1201",
                "parenttext": "合肥市",
                "text": "庐阳区",
                "level": 3,
                "order": 1227,
                "relation_code": "340103",
                "relation_desc": "安徽省合肥市庐阳区"
            },
            {
                "code": "120103",
                "parentcode": "1201",
                "parenttext": "合肥市",
                "text": "蜀山区",
                "level": 3,
                "order": 1228,
                "relation_code": "340104",
                "relation_desc": "安徽省合肥市蜀山区"
            },
            {
                "code": "120104",
                "parentcode": "1201",
                "parenttext": "合肥市",
                "text": "包河区",
                "level": 3,
                "order": 1229,
                "relation_code": "340111",
                "relation_desc": "安徽省合肥市包河区"
            },
            {
                "code": "120105",
                "parentcode": "1201",
                "parenttext": "合肥市",
                "text": "长丰县",
                "level": 3,
                "order": 1230,
                "relation_code": "340121",
                "relation_desc": "安徽省长丰县"
            },
            {
                "code": "120106",
                "parentcode": "1201",
                "parenttext": "合肥市",
                "text": "肥东县",
                "level": 3,
                "order": 1231,
                "relation_code": "340122",
                "relation_desc": "安徽省肥东县"
            },
            {
                "code": "120107",
                "parentcode": "1201",
                "parenttext": "合肥市",
                "text": "肥西县",
                "level": 3,
                "order": 1232,
                "relation_code": "340123",
                "relation_desc": "安徽省肥西县"
            },
            {
                "code": "1202",
                "parentcode": "12",
                "parenttext": "安徽省",
                "text": "芜湖市",
                "level": 2,
                "order": 1233,
                "relation_code": "340200",
                "relation_desc": "安徽省芜湖市"
            },
            {
                "code": "120108",
                "parentcode": "1201",
                "parenttext": "合肥市",
                "text": "合肥高新技术产业开发区",
                "level": 3,
                "order": 1233,
                "relation_code": "340101",
                "relation_desc": "安徽省合肥市"
            },
            {
                "code": "120201",
                "parentcode": "1202",
                "parenttext": "芜湖市",
                "text": "镜湖区",
                "level": 3,
                "order": 1234,
                "relation_code": "340202",
                "relation_desc": "安徽省芜湖市镜湖区"
            },
            {
                "code": "120109",
                "parentcode": "1201",
                "parenttext": "合肥市",
                "text": "合肥经济技术开发区",
                "level": 3,
                "order": 1234,
                "relation_code": "340101",
                "relation_desc": "安徽省合肥市"
            },
            {
                "code": "120202",
                "parentcode": "1202",
                "parenttext": "芜湖市",
                "text": "弋江区",
                "level": 3,
                "order": 1235,
                "relation_code": "340203",
                "relation_desc": "安徽省芜湖市弋江区"
            },
            {
                "code": "120110",
                "parentcode": "1201",
                "parenttext": "合肥市",
                "text": "合肥新站高新技术产业开发区",
                "level": 3,
                "order": 1235,
                "relation_code": "340101",
                "relation_desc": "安徽省合肥市"
            },
            {
                "code": "120203",
                "parentcode": "1202",
                "parenttext": "芜湖市",
                "text": "新芜区",
                "level": 3,
                "order": 1236,
                "relation_code": "340201",
                "relation_desc": "安徽省芜湖市新芜区"
            },
            {
                "code": "120111",
                "parentcode": "1201",
                "parenttext": "合肥市",
                "text": "庐江县",
                "level": 3,
                "order": 1236,
                "relation_code": "340124",
                "relation_desc": "安徽省庐江县"
            },
            {
                "code": "120204",
                "parentcode": "1202",
                "parenttext": "芜湖市",
                "text": "鸠江区",
                "level": 3,
                "order": 1237,
                "relation_code": "340207",
                "relation_desc": "安徽省芜湖市鸠江区"
            },
            {
                "code": "120112",
                "parentcode": "1201",
                "parenttext": "合肥市",
                "text": "巢湖市",
                "level": 3,
                "order": 1237,
                "relation_code": "340181",
                "relation_desc": "安徽省巢湖市"
            },
            {
                "code": "120205",
                "parentcode": "1202",
                "parenttext": "芜湖市",
                "text": "芜湖县",
                "level": 3,
                "order": 1238,
                "relation_code": "340221",
                "relation_desc": "安徽省芜湖县"
            },
            {
                "code": "120206",
                "parentcode": "1202",
                "parenttext": "芜湖市",
                "text": "繁昌县",
                "level": 3,
                "order": 1239,
                "relation_code": "340222",
                "relation_desc": "安徽省繁昌县"
            },
            {
                "code": "120207",
                "parentcode": "1202",
                "parenttext": "芜湖市",
                "text": "南陵县",
                "level": 3,
                "order": 1240,
                "relation_code": "340223",
                "relation_desc": "安徽省南陵县"
            },
            {
                "code": "1203",
                "parentcode": "12",
                "parenttext": "安徽省",
                "text": "蚌埠市",
                "level": 2,
                "order": 1241,
                "relation_code": "340300",
                "relation_desc": "安徽省蚌埠市"
            },
            {
                "code": "120208",
                "parentcode": "1202",
                "parenttext": "芜湖市",
                "text": "三山区",
                "level": 3,
                "order": 1241,
                "relation_code": "340208",
                "relation_desc": "安徽省芜湖市三山区"
            },
            {
                "code": "120301",
                "parentcode": "1203",
                "parenttext": "蚌埠市",
                "text": "龙子湖区",
                "level": 3,
                "order": 1242,
                "relation_code": "340302",
                "relation_desc": "安徽省蚌埠市龙子湖区"
            },
            {
                "code": "120209",
                "parentcode": "1202",
                "parenttext": "芜湖市",
                "text": "无为县",
                "level": 3,
                "order": 1242,
                "relation_code": "340201",
                "relation_desc": "安徽省无为县"
            },
            {
                "code": "120302",
                "parentcode": "1203",
                "parenttext": "蚌埠市",
                "text": "蚌山区",
                "level": 3,
                "order": 1243,
                "relation_code": "340303",
                "relation_desc": "安徽省蚌埠市蚌山区"
            },
            {
                "code": "120210",
                "parentcode": "1202",
                "parenttext": "芜湖市",
                "text": "芜湖经济技术开发区",
                "level": 3,
                "order": 1243,
                "relation_code": "340201",
                "relation_desc": "安徽省芜湖市"
            },
            {
                "code": "120303",
                "parentcode": "1203",
                "parenttext": "蚌埠市",
                "text": "禹会区",
                "level": 3,
                "order": 1244,
                "relation_code": "340304",
                "relation_desc": "安徽省蚌埠市禹会区"
            },
            {
                "code": "120211",
                "parentcode": "1202",
                "parenttext": "芜湖市",
                "text": "安徽芜湖长江大桥经济开发区",
                "level": 3,
                "order": 1244,
                "relation_code": "340201",
                "relation_desc": "安徽省芜湖市"
            },
            {
                "code": "120304",
                "parentcode": "1203",
                "parenttext": "蚌埠市",
                "text": "淮上区",
                "level": 3,
                "order": 1245,
                "relation_code": "340311",
                "relation_desc": "安徽省蚌埠市淮上区"
            },
            {
                "code": "120305",
                "parentcode": "1203",
                "parenttext": "蚌埠市",
                "text": "怀远县",
                "level": 3,
                "order": 1246,
                "relation_code": "340321",
                "relation_desc": "安徽省怀远县"
            },
            {
                "code": "120306",
                "parentcode": "1203",
                "parenttext": "蚌埠市",
                "text": "五河县",
                "level": 3,
                "order": 1247,
                "relation_code": "340322",
                "relation_desc": "安徽省五河县"
            },
            {
                "code": "120307",
                "parentcode": "1203",
                "parenttext": "蚌埠市",
                "text": "固镇县",
                "level": 3,
                "order": 1248,
                "relation_code": "340323",
                "relation_desc": "安徽省固镇县"
            },
            {
                "code": "1204",
                "parentcode": "12",
                "parenttext": "安徽省",
                "text": "淮南市",
                "level": 2,
                "order": 1249,
                "relation_code": "340400",
                "relation_desc": "安徽省淮南市"
            },
            {
                "code": "120308",
                "parentcode": "1203",
                "parenttext": "蚌埠市",
                "text": "蚌埠市高新技术开发区",
                "level": 3,
                "order": 1249,
                "relation_code": "340301",
                "relation_desc": "安徽省蚌埠市"
            },
            {
                "code": "120401",
                "parentcode": "1204",
                "parenttext": "淮南市",
                "text": "大通区",
                "level": 3,
                "order": 1250,
                "relation_code": "340402",
                "relation_desc": "安徽省淮南市大通区"
            },
            {
                "code": "120309",
                "parentcode": "1203",
                "parenttext": "蚌埠市",
                "text": "蚌埠市经济开发区",
                "level": 3,
                "order": 1250,
                "relation_code": "340301",
                "relation_desc": "安徽省蚌埠市"
            },
            {
                "code": "120402",
                "parentcode": "1204",
                "parenttext": "淮南市",
                "text": "田家庵区",
                "level": 3,
                "order": 1251,
                "relation_code": "340403",
                "relation_desc": "安徽省淮南市田家庵区"
            },
            {
                "code": "120403",
                "parentcode": "1204",
                "parenttext": "淮南市",
                "text": "谢家集区",
                "level": 3,
                "order": 1252,
                "relation_code": "340404",
                "relation_desc": "安徽省淮南市谢家集区"
            },
            {
                "code": "120404",
                "parentcode": "1204",
                "parenttext": "淮南市",
                "text": "八公山区",
                "level": 3,
                "order": 1253,
                "relation_code": "340405",
                "relation_desc": "安徽省淮南市八公山区"
            },
            {
                "code": "120405",
                "parentcode": "1204",
                "parenttext": "淮南市",
                "text": "潘集区",
                "level": 3,
                "order": 1254,
                "relation_code": "340406",
                "relation_desc": "安徽省淮南市潘集区"
            },
            {
                "code": "120406",
                "parentcode": "1204",
                "parenttext": "淮南市",
                "text": "凤台县",
                "level": 3,
                "order": 1255,
                "relation_code": "340421",
                "relation_desc": "安徽省凤台县"
            },
            {
                "code": "1205",
                "parentcode": "12",
                "parenttext": "安徽省",
                "text": "马鞍山市",
                "level": 2,
                "order": 1256,
                "relation_code": "340500",
                "relation_desc": "安徽省马鞍山市"
            },
            {
                "code": "120407",
                "parentcode": "1204",
                "parenttext": "淮南市",
                "text": "寿县",
                "level": 3,
                "order": 1256,
                "relation_code": "340422",
                "relation_desc": "安徽省寿县"
            },
            {
                "code": "120501",
                "parentcode": "1205",
                "parenttext": "马鞍山市",
                "text": "花山区",
                "level": 3,
                "order": 1257,
                "relation_code": "340503",
                "relation_desc": "安徽省马鞍山市花山区"
            },
            {
                "code": "120502",
                "parentcode": "1205",
                "parenttext": "马鞍山市",
                "text": "雨山区",
                "level": 3,
                "order": 1258,
                "relation_code": "340504",
                "relation_desc": "安徽省马鞍山市雨山区"
            },
            {
                "code": "120503",
                "parentcode": "1205",
                "parenttext": "马鞍山市",
                "text": "博望区",
                "level": 3,
                "order": 1259,
                "relation_code": "340506",
                "relation_desc": "安徽省马鞍山市博望区"
            },
            {
                "code": "120504",
                "parentcode": "1205",
                "parenttext": "马鞍山市",
                "text": "当涂县",
                "level": 3,
                "order": 1260,
                "relation_code": "340521",
                "relation_desc": "安徽省当涂县"
            },
            {
                "code": "1206",
                "parentcode": "12",
                "parenttext": "安徽省",
                "text": "淮北市",
                "level": 2,
                "order": 1261,
                "relation_code": "340600",
                "relation_desc": "安徽省淮北市"
            },
            {
                "code": "120601",
                "parentcode": "1206",
                "parenttext": "淮北市",
                "text": "杜集区",
                "level": 3,
                "order": 1262,
                "relation_code": "340602",
                "relation_desc": "安徽省淮北市杜集区"
            },
            {
                "code": "120602",
                "parentcode": "1206",
                "parenttext": "淮北市",
                "text": "相山区",
                "level": 3,
                "order": 1263,
                "relation_code": "340603",
                "relation_desc": "安徽省淮北市相山区"
            },
            {
                "code": "120603",
                "parentcode": "1206",
                "parenttext": "淮北市",
                "text": "烈山区",
                "level": 3,
                "order": 1264,
                "relation_code": "340604",
                "relation_desc": "安徽省淮北市烈山区"
            },
            {
                "code": "120604",
                "parentcode": "1206",
                "parenttext": "淮北市",
                "text": "濉溪县",
                "level": 3,
                "order": 1265,
                "relation_code": "340621",
                "relation_desc": "安徽省濉溪县"
            },
            {
                "code": "1207",
                "parentcode": "12",
                "parenttext": "安徽省",
                "text": "铜陵市",
                "level": 2,
                "order": 1266,
                "relation_code": "340700",
                "relation_desc": "安徽省铜陵市"
            },
            {
                "code": "120701",
                "parentcode": "1207",
                "parenttext": "铜陵市",
                "text": "铜官区",
                "level": 3,
                "order": 1267,
                "relation_code": "340705",
                "relation_desc": "安徽省铜陵市铜官区"
            },
            {
                "code": "120702",
                "parentcode": "1207",
                "parenttext": "铜陵市",
                "text": "义安区",
                "level": 3,
                "order": 1268,
                "relation_code": "340706",
                "relation_desc": "安徽省铜陵市义安区"
            },
            {
                "code": "120703",
                "parentcode": "1207",
                "parenttext": "铜陵市",
                "text": "郊区",
                "level": 3,
                "order": 1269,
                "relation_code": "340711",
                "relation_desc": "安徽省铜陵市郊区"
            },
            {
                "code": "120704",
                "parentcode": "1207",
                "parenttext": "铜陵市",
                "text": "枞阳县",
                "level": 3,
                "order": 1270,
                "relation_code": "340722",
                "relation_desc": "安徽省枞阳县"
            },
            {
                "code": "1208",
                "parentcode": "12",
                "parenttext": "安徽省",
                "text": "安庆市",
                "level": 2,
                "order": 1271,
                "relation_code": "340800",
                "relation_desc": "安徽省安庆市"
            },
            {
                "code": "120801",
                "parentcode": "1208",
                "parenttext": "安庆市",
                "text": "迎江区",
                "level": 3,
                "order": 1272,
                "relation_code": "340802",
                "relation_desc": "安徽省安庆市迎江区"
            },
            {
                "code": "120802",
                "parentcode": "1208",
                "parenttext": "安庆市",
                "text": "大观区",
                "level": 3,
                "order": 1273,
                "relation_code": "340803",
                "relation_desc": "安徽省安庆市大观区"
            },
            {
                "code": "120803",
                "parentcode": "1208",
                "parenttext": "安庆市",
                "text": "郊区",
                "level": 3,
                "order": 1274,
                "relation_code": "340801",
                "relation_desc": "安徽省安庆市"
            },
            {
                "code": "120804",
                "parentcode": "1208",
                "parenttext": "安庆市",
                "text": "怀宁县",
                "level": 3,
                "order": 1275,
                "relation_code": "340822",
                "relation_desc": "安徽省怀宁县"
            },
            {
                "code": "120805",
                "parentcode": "1208",
                "parenttext": "安庆市",
                "text": "宜秀区",
                "level": 3,
                "order": 1276,
                "relation_code": "340811",
                "relation_desc": "安徽省安庆市宜秀区"
            },
            {
                "code": "120806",
                "parentcode": "1208",
                "parenttext": "安庆市",
                "text": "潜山县",
                "level": 3,
                "order": 1277,
                "relation_code": "340801",
                "relation_desc": "安徽省潜山县"
            },
            {
                "code": "120807",
                "parentcode": "1208",
                "parenttext": "安庆市",
                "text": "太湖县",
                "level": 3,
                "order": 1278,
                "relation_code": "340825",
                "relation_desc": "安徽省太湖县"
            },
            {
                "code": "120808",
                "parentcode": "1208",
                "parenttext": "安庆市",
                "text": "宿松县",
                "level": 3,
                "order": 1279,
                "relation_code": "340826",
                "relation_desc": "安徽省宿松县"
            },
            {
                "code": "120809",
                "parentcode": "1208",
                "parenttext": "安庆市",
                "text": "望江县",
                "level": 3,
                "order": 1280,
                "relation_code": "340827",
                "relation_desc": "安徽省望江县"
            },
            {
                "code": "120810",
                "parentcode": "1208",
                "parenttext": "安庆市",
                "text": "岳西县",
                "level": 3,
                "order": 1281,
                "relation_code": "340828",
                "relation_desc": "安徽省岳西县"
            },
            {
                "code": "120811",
                "parentcode": "1208",
                "parenttext": "安庆市",
                "text": "桐城市",
                "level": 3,
                "order": 1282,
                "relation_code": "340881",
                "relation_desc": "安徽省桐城市"
            },
            {
                "code": "1209",
                "parentcode": "12",
                "parenttext": "安徽省",
                "text": "黄山市",
                "level": 2,
                "order": 1283,
                "relation_code": "341000",
                "relation_desc": "安徽省黄山市"
            },
            {
                "code": "120812",
                "parentcode": "1208",
                "parenttext": "安庆市",
                "text": "安徽安庆经济开发区",
                "level": 3,
                "order": 1283,
                "relation_code": "340801",
                "relation_desc": "安徽省安庆市"
            },
            {
                "code": "120901",
                "parentcode": "1209",
                "parenttext": "黄山市",
                "text": "屯溪区",
                "level": 3,
                "order": 1284,
                "relation_code": "341002",
                "relation_desc": "安徽省黄山市屯溪区"
            },
            {
                "code": "120813",
                "parentcode": "1208",
                "parenttext": "安庆市",
                "text": "潜山市",
                "level": 3,
                "order": 1284,
                "relation_code": "340801",
                "relation_desc": "安徽省安庆市"
            },
            {
                "code": "120902",
                "parentcode": "1209",
                "parenttext": "黄山市",
                "text": "黄山区",
                "level": 3,
                "order": 1285,
                "relation_code": "341003",
                "relation_desc": "安徽省黄山市黄山区"
            },
            {
                "code": "120903",
                "parentcode": "1209",
                "parenttext": "黄山市",
                "text": "徽州区",
                "level": 3,
                "order": 1286,
                "relation_code": "341004",
                "relation_desc": "安徽省黄山市徽州区"
            },
            {
                "code": "120904",
                "parentcode": "1209",
                "parenttext": "黄山市",
                "text": "歙县",
                "level": 3,
                "order": 1287,
                "relation_code": "341021",
                "relation_desc": "安徽省歙县"
            },
            {
                "code": "120905",
                "parentcode": "1209",
                "parenttext": "黄山市",
                "text": "休宁县",
                "level": 3,
                "order": 1288,
                "relation_code": "341022",
                "relation_desc": "安徽省休宁县"
            },
            {
                "code": "120906",
                "parentcode": "1209",
                "parenttext": "黄山市",
                "text": "黟县",
                "level": 3,
                "order": 1289,
                "relation_code": "341023",
                "relation_desc": "安徽省黟县"
            },
            {
                "code": "120907",
                "parentcode": "1209",
                "parenttext": "黄山市",
                "text": "祁门县",
                "level": 3,
                "order": 1290,
                "relation_code": "341024",
                "relation_desc": "安徽省祁门县"
            },
            {
                "code": "1210",
                "parentcode": "12",
                "parenttext": "安徽省",
                "text": "滁州市",
                "level": 2,
                "order": 1291,
                "relation_code": "341100",
                "relation_desc": "安徽省滁州市"
            },
            {
                "code": "121001",
                "parentcode": "1210",
                "parenttext": "滁州市",
                "text": "琅琊区",
                "level": 3,
                "order": 1292,
                "relation_code": "341102",
                "relation_desc": "安徽省滁州市琅琊区"
            },
            {
                "code": "121002",
                "parentcode": "1210",
                "parenttext": "滁州市",
                "text": "南谯区",
                "level": 3,
                "order": 1293,
                "relation_code": "341103",
                "relation_desc": "安徽省滁州市南谯区"
            },
            {
                "code": "121003",
                "parentcode": "1210",
                "parenttext": "滁州市",
                "text": "来安县",
                "level": 3,
                "order": 1294,
                "relation_code": "341122",
                "relation_desc": "安徽省来安县"
            },
            {
                "code": "121004",
                "parentcode": "1210",
                "parenttext": "滁州市",
                "text": "全椒县",
                "level": 3,
                "order": 1295,
                "relation_code": "341124",
                "relation_desc": "安徽省全椒县"
            },
            {
                "code": "121005",
                "parentcode": "1210",
                "parenttext": "滁州市",
                "text": "定远县",
                "level": 3,
                "order": 1296,
                "relation_code": "341125",
                "relation_desc": "安徽省定远县"
            },
            {
                "code": "121006",
                "parentcode": "1210",
                "parenttext": "滁州市",
                "text": "凤阳县",
                "level": 3,
                "order": 1297,
                "relation_code": "341126",
                "relation_desc": "安徽省凤阳县"
            },
            {
                "code": "121007",
                "parentcode": "1210",
                "parenttext": "滁州市",
                "text": "天长市",
                "level": 3,
                "order": 1298,
                "relation_code": "341181",
                "relation_desc": "安徽省天长市"
            },
            {
                "code": "121008",
                "parentcode": "1210",
                "parenttext": "滁州市",
                "text": "明光市",
                "level": 3,
                "order": 1299,
                "relation_code": "341182",
                "relation_desc": "安徽省明光市"
            },
            {
                "code": "1211",
                "parentcode": "12",
                "parenttext": "安徽省",
                "text": "阜阳市",
                "level": 2,
                "order": 1300,
                "relation_code": "341200",
                "relation_desc": "安徽省阜阳市"
            },
            {
                "code": "121009",
                "parentcode": "1210",
                "parenttext": "滁州市",
                "text": "苏滁现代产业园",
                "level": 3,
                "order": 1300,
                "relation_code": "341101",
                "relation_desc": "安徽省滁州市"
            },
            {
                "code": "121101",
                "parentcode": "1211",
                "parenttext": "阜阳市",
                "text": "颍州区",
                "level": 3,
                "order": 1301,
                "relation_code": "341202",
                "relation_desc": "安徽省阜阳市颍州区"
            },
            {
                "code": "121010",
                "parentcode": "1210",
                "parenttext": "滁州市",
                "text": "滁州经济技术开发区",
                "level": 3,
                "order": 1301,
                "relation_code": "341101",
                "relation_desc": "安徽省滁州市"
            },
            {
                "code": "121102",
                "parentcode": "1211",
                "parenttext": "阜阳市",
                "text": "颍东区",
                "level": 3,
                "order": 1302,
                "relation_code": "341203",
                "relation_desc": "安徽省阜阳市颍东区"
            },
            {
                "code": "121103",
                "parentcode": "1211",
                "parenttext": "阜阳市",
                "text": "颍泉区",
                "level": 3,
                "order": 1303,
                "relation_code": "341204",
                "relation_desc": "安徽省阜阳市颍泉区"
            },
            {
                "code": "121104",
                "parentcode": "1211",
                "parenttext": "阜阳市",
                "text": "临泉县",
                "level": 3,
                "order": 1304,
                "relation_code": "341221",
                "relation_desc": "安徽省临泉县"
            },
            {
                "code": "121105",
                "parentcode": "1211",
                "parenttext": "阜阳市",
                "text": "太和县",
                "level": 3,
                "order": 1305,
                "relation_code": "341222",
                "relation_desc": "安徽省太和县"
            },
            {
                "code": "121106",
                "parentcode": "1211",
                "parenttext": "阜阳市",
                "text": "阜南县",
                "level": 3,
                "order": 1306,
                "relation_code": "341225",
                "relation_desc": "安徽省阜南县"
            },
            {
                "code": "121107",
                "parentcode": "1211",
                "parenttext": "阜阳市",
                "text": "颍上县",
                "level": 3,
                "order": 1307,
                "relation_code": "341226",
                "relation_desc": "安徽省颍上县"
            },
            {
                "code": "121108",
                "parentcode": "1211",
                "parenttext": "阜阳市",
                "text": "界首市",
                "level": 3,
                "order": 1308,
                "relation_code": "341282",
                "relation_desc": "安徽省界首市"
            },
            {
                "code": "1212",
                "parentcode": "12",
                "parenttext": "安徽省",
                "text": "宿州市",
                "level": 2,
                "order": 1309,
                "relation_code": "341300",
                "relation_desc": "安徽省宿州市"
            },
            {
                "code": "121109",
                "parentcode": "1211",
                "parenttext": "阜阳市",
                "text": "阜阳合肥现代产业园区",
                "level": 3,
                "order": 1309,
                "relation_code": "341201",
                "relation_desc": "安徽省阜阳市"
            },
            {
                "code": "121201",
                "parentcode": "1212",
                "parenttext": "宿州市",
                "text": "埇桥区",
                "level": 3,
                "order": 1310,
                "relation_code": "341302",
                "relation_desc": "安徽省宿州市埇桥区"
            },
            {
                "code": "121110",
                "parentcode": "1211",
                "parenttext": "阜阳市",
                "text": "阜阳经济技术开发区",
                "level": 3,
                "order": 1310,
                "relation_code": "341201",
                "relation_desc": "安徽省阜阳市"
            },
            {
                "code": "121202",
                "parentcode": "1212",
                "parenttext": "宿州市",
                "text": "砀山县",
                "level": 3,
                "order": 1311,
                "relation_code": "341321",
                "relation_desc": "安徽省砀山县"
            },
            {
                "code": "121203",
                "parentcode": "1212",
                "parenttext": "宿州市",
                "text": "萧县",
                "level": 3,
                "order": 1312,
                "relation_code": "341322",
                "relation_desc": "安徽省萧县"
            },
            {
                "code": "121204",
                "parentcode": "1212",
                "parenttext": "宿州市",
                "text": "灵璧县",
                "level": 3,
                "order": 1313,
                "relation_code": "341323",
                "relation_desc": "安徽省灵璧县"
            },
            {
                "code": "121205",
                "parentcode": "1212",
                "parenttext": "宿州市",
                "text": "泗县",
                "level": 3,
                "order": 1314,
                "relation_code": "341324",
                "relation_desc": "安徽省泗县"
            },
            {
                "code": "1213",
                "parentcode": "12",
                "parenttext": "安徽省",
                "text": "巢湖市",
                "level": 2,
                "order": 1315,
                "relation_code": "",
                "relation_desc": "安徽省巢湖市"
            },
            {
                "code": "121206",
                "parentcode": "1212",
                "parenttext": "宿州市",
                "text": "宿州马鞍山现代产业园区",
                "level": 3,
                "order": 1315,
                "relation_code": "341301",
                "relation_desc": "安徽省宿州市"
            },
            {
                "code": "121301",
                "parentcode": "1213",
                "parenttext": "巢湖市",
                "text": "居巢区",
                "level": 3,
                "order": 1316,
                "relation_code": "340181",
                "relation_desc": "安徽省巢湖市居巢区"
            },
            {
                "code": "121207",
                "parentcode": "1212",
                "parenttext": "宿州市",
                "text": "宿州经济技术开发区",
                "level": 3,
                "order": 1316,
                "relation_code": "341301",
                "relation_desc": "安徽省宿州市"
            },
            {
                "code": "121302",
                "parentcode": "1213",
                "parenttext": "巢湖市",
                "text": "庐江县",
                "level": 3,
                "order": 1317,
                "relation_code": "340124",
                "relation_desc": "安徽省庐江县"
            },
            {
                "code": "121303",
                "parentcode": "1213",
                "parenttext": "巢湖市",
                "text": "无为县",
                "level": 3,
                "order": 1318,
                "relation_code": "340281",
                "relation_desc": "安徽省无为县"
            },
            {
                "code": "121304",
                "parentcode": "1213",
                "parenttext": "巢湖市",
                "text": "含山县",
                "level": 3,
                "order": 1319,
                "relation_code": "340522",
                "relation_desc": "安徽省含山县"
            },
            {
                "code": "120505",
                "parentcode": "1205",
                "parenttext": "马鞍山市",
                "text": "含山县",
                "level": 3,
                "order": 1319,
                "relation_code": "340522",
                "relation_desc": "安徽省含山县"
            },
            {
                "code": "121305",
                "parentcode": "1213",
                "parenttext": "巢湖市",
                "text": "和县",
                "level": 3,
                "order": 1320,
                "relation_code": "340523",
                "relation_desc": "安徽省和县"
            },
            {
                "code": "120506",
                "parentcode": "1205",
                "parenttext": "马鞍山市",
                "text": "和县",
                "level": 3,
                "order": 1320,
                "relation_code": "340523",
                "relation_desc": "安徽省和县"
            },
            {
                "code": "1214",
                "parentcode": "12",
                "parenttext": "安徽省",
                "text": "六安市",
                "level": 2,
                "order": 1321,
                "relation_code": "341500",
                "relation_desc": "安徽省六安市"
            },
            {
                "code": "121401",
                "parentcode": "1214",
                "parenttext": "六安市",
                "text": "金安区",
                "level": 3,
                "order": 1322,
                "relation_code": "341502",
                "relation_desc": "安徽省六安市金安区"
            },
            {
                "code": "121402",
                "parentcode": "1214",
                "parenttext": "六安市",
                "text": "裕安区",
                "level": 3,
                "order": 1323,
                "relation_code": "341503",
                "relation_desc": "安徽省六安市裕安区"
            },
            {
                "code": "121403",
                "parentcode": "1214",
                "parenttext": "六安市",
                "text": "叶集区",
                "level": 3,
                "order": 1324,
                "relation_code": "341504",
                "relation_desc": "安徽省六安市叶集区"
            },
            {
                "code": "121404",
                "parentcode": "1214",
                "parenttext": "六安市",
                "text": "霍邱县",
                "level": 3,
                "order": 1325,
                "relation_code": "341522",
                "relation_desc": "安徽省霍邱县"
            },
            {
                "code": "121405",
                "parentcode": "1214",
                "parenttext": "六安市",
                "text": "舒城县",
                "level": 3,
                "order": 1326,
                "relation_code": "341523",
                "relation_desc": "安徽省舒城县"
            },
            {
                "code": "121406",
                "parentcode": "1214",
                "parenttext": "六安市",
                "text": "金寨县",
                "level": 3,
                "order": 1327,
                "relation_code": "341524",
                "relation_desc": "安徽省金寨县"
            },
            {
                "code": "121407",
                "parentcode": "1214",
                "parenttext": "六安市",
                "text": "霍山县",
                "level": 3,
                "order": 1328,
                "relation_code": "341525",
                "relation_desc": "安徽省霍山县"
            },
            {
                "code": "1215",
                "parentcode": "12",
                "parenttext": "安徽省",
                "text": "亳州市",
                "level": 2,
                "order": 1329,
                "relation_code": "341600",
                "relation_desc": "安徽省亳州市"
            },
            {
                "code": "121501",
                "parentcode": "1215",
                "parenttext": "亳州市",
                "text": "谯城区",
                "level": 3,
                "order": 1330,
                "relation_code": "341602",
                "relation_desc": "安徽省亳州市谯城区"
            },
            {
                "code": "121502",
                "parentcode": "1215",
                "parenttext": "亳州市",
                "text": "涡阳县",
                "level": 3,
                "order": 1331,
                "relation_code": "341621",
                "relation_desc": "安徽省涡阳县"
            },
            {
                "code": "121503",
                "parentcode": "1215",
                "parenttext": "亳州市",
                "text": "蒙城县",
                "level": 3,
                "order": 1332,
                "relation_code": "341622",
                "relation_desc": "安徽省蒙城县"
            },
            {
                "code": "121504",
                "parentcode": "1215",
                "parenttext": "亳州市",
                "text": "利辛县",
                "level": 3,
                "order": 1333,
                "relation_code": "341623",
                "relation_desc": "安徽省利辛县"
            },
            {
                "code": "1216",
                "parentcode": "12",
                "parenttext": "安徽省",
                "text": "池州市",
                "level": 2,
                "order": 1334,
                "relation_code": "341700",
                "relation_desc": "安徽省池州市"
            },
            {
                "code": "121601",
                "parentcode": "1216",
                "parenttext": "池州市",
                "text": "贵池区",
                "level": 3,
                "order": 1335,
                "relation_code": "341702",
                "relation_desc": "安徽省池州市贵池区"
            },
            {
                "code": "121602",
                "parentcode": "1216",
                "parenttext": "池州市",
                "text": "东至县",
                "level": 3,
                "order": 1336,
                "relation_code": "341721",
                "relation_desc": "安徽省东至县"
            },
            {
                "code": "121603",
                "parentcode": "1216",
                "parenttext": "池州市",
                "text": "石台县",
                "level": 3,
                "order": 1337,
                "relation_code": "341722",
                "relation_desc": "安徽省石台县"
            },
            {
                "code": "121604",
                "parentcode": "1216",
                "parenttext": "池州市",
                "text": "青阳县",
                "level": 3,
                "order": 1338,
                "relation_code": "341723",
                "relation_desc": "安徽省青阳县"
            },
            {
                "code": "1217",
                "parentcode": "12",
                "parenttext": "安徽省",
                "text": "宣城市",
                "level": 2,
                "order": 1339,
                "relation_code": "341800",
                "relation_desc": "安徽省宣城市"
            },
            {
                "code": "121701",
                "parentcode": "1217",
                "parenttext": "宣城市",
                "text": "宣州区",
                "level": 3,
                "order": 1340,
                "relation_code": "341802",
                "relation_desc": "安徽省宣城市宣州区"
            },
            {
                "code": "121702",
                "parentcode": "1217",
                "parenttext": "宣城市",
                "text": "郎溪县",
                "level": 3,
                "order": 1341,
                "relation_code": "341821",
                "relation_desc": "安徽省郎溪县"
            },
            {
                "code": "121703",
                "parentcode": "1217",
                "parenttext": "宣城市",
                "text": "广德县",
                "level": 3,
                "order": 1342,
                "relation_code": "341801",
                "relation_desc": "安徽省广德县"
            },
            {
                "code": "121704",
                "parentcode": "1217",
                "parenttext": "宣城市",
                "text": "泾县",
                "level": 3,
                "order": 1343,
                "relation_code": "341823",
                "relation_desc": "安徽省泾县"
            },
            {
                "code": "121705",
                "parentcode": "1217",
                "parenttext": "宣城市",
                "text": "绩溪县",
                "level": 3,
                "order": 1344,
                "relation_code": "341824",
                "relation_desc": "安徽省绩溪县"
            },
            {
                "code": "121706",
                "parentcode": "1217",
                "parenttext": "宣城市",
                "text": "旌德县",
                "level": 3,
                "order": 1345,
                "relation_code": "341825",
                "relation_desc": "安徽省旌德县"
            },
            {
                "code": "121707",
                "parentcode": "1217",
                "parenttext": "宣城市",
                "text": "宁国市",
                "level": 3,
                "order": 1346,
                "relation_code": "341881",
                "relation_desc": "安徽省宁国市"
            },
            {
                "code": "13",
                "parentcode": "0",
                "parenttext": "0",
                "text": "福建省",
                "level": 1,
                "order": 1347,
                "relation_code": "",
                "relation_desc": "福建省"
            },
            {
                "code": "121708",
                "parentcode": "1217",
                "parenttext": "宣城市",
                "text": "宣城市经济开发区",
                "level": 3,
                "order": 1347,
                "relation_code": "341801",
                "relation_desc": "安徽省宣城市"
            },
            {
                "code": "1301",
                "parentcode": "13",
                "parenttext": "福建省",
                "text": "福州市",
                "level": 2,
                "order": 1348,
                "relation_code": "350100",
                "relation_desc": "福建省福州市"
            },
            {
                "code": "121709",
                "parentcode": "1217",
                "parenttext": "宣城市",
                "text": "广德市",
                "level": 3,
                "order": 1348,
                "relation_code": "341801",
                "relation_desc": "安徽省宣城市"
            },
            {
                "code": "130101",
                "parentcode": "1301",
                "parenttext": "福州市",
                "text": "鼓楼区",
                "level": 3,
                "order": 1349,
                "relation_code": "350102",
                "relation_desc": "福建省福州市鼓楼区"
            },
            {
                "code": "130102",
                "parentcode": "1301",
                "parenttext": "福州市",
                "text": "台江区",
                "level": 3,
                "order": 1350,
                "relation_code": "350103",
                "relation_desc": "福建省福州市台江区"
            },
            {
                "code": "130103",
                "parentcode": "1301",
                "parenttext": "福州市",
                "text": "仓山区",
                "level": 3,
                "order": 1351,
                "relation_code": "350104",
                "relation_desc": "福建省福州市仓山区"
            },
            {
                "code": "130104",
                "parentcode": "1301",
                "parenttext": "福州市",
                "text": "马尾区",
                "level": 3,
                "order": 1352,
                "relation_code": "350105",
                "relation_desc": "福建省福州市马尾区"
            },
            {
                "code": "130105",
                "parentcode": "1301",
                "parenttext": "福州市",
                "text": "晋安区",
                "level": 3,
                "order": 1353,
                "relation_code": "350111",
                "relation_desc": "福建省福州市晋安区"
            },
            {
                "code": "130106",
                "parentcode": "1301",
                "parenttext": "福州市",
                "text": "闽侯县",
                "level": 3,
                "order": 1354,
                "relation_code": "350121",
                "relation_desc": "福建省闽侯县"
            },
            {
                "code": "130107",
                "parentcode": "1301",
                "parenttext": "福州市",
                "text": "连江县",
                "level": 3,
                "order": 1355,
                "relation_code": "350122",
                "relation_desc": "福建省连江县"
            },
            {
                "code": "130108",
                "parentcode": "1301",
                "parenttext": "福州市",
                "text": "罗源县",
                "level": 3,
                "order": 1356,
                "relation_code": "350123",
                "relation_desc": "福建省罗源县"
            },
            {
                "code": "130109",
                "parentcode": "1301",
                "parenttext": "福州市",
                "text": "闽清县",
                "level": 3,
                "order": 1357,
                "relation_code": "350124",
                "relation_desc": "福建省闽清县"
            },
            {
                "code": "130110",
                "parentcode": "1301",
                "parenttext": "福州市",
                "text": "永泰县",
                "level": 3,
                "order": 1358,
                "relation_code": "350125",
                "relation_desc": "福建省永泰县"
            },
            {
                "code": "130111",
                "parentcode": "1301",
                "parenttext": "福州市",
                "text": "平潭县",
                "level": 3,
                "order": 1359,
                "relation_code": "350128",
                "relation_desc": "福建省平潭县"
            },
            {
                "code": "130112",
                "parentcode": "1301",
                "parenttext": "福州市",
                "text": "福清市",
                "level": 3,
                "order": 1360,
                "relation_code": "350181",
                "relation_desc": "福建省福清市"
            },
            {
                "code": "130113",
                "parentcode": "1301",
                "parenttext": "福州市",
                "text": "长乐市",
                "level": 3,
                "order": 1361,
                "relation_code": "350101",
                "relation_desc": "福建省长乐市"
            },
            {
                "code": "1302",
                "parentcode": "13",
                "parenttext": "福建省",
                "text": "厦门市",
                "level": 2,
                "order": 1362,
                "relation_code": "350200",
                "relation_desc": "福建省厦门市"
            },
            {
                "code": "130114",
                "parentcode": "1301",
                "parenttext": "福州市",
                "text": "长乐区",
                "level": 3,
                "order": 1362,
                "relation_code": "350112",
                "relation_desc": "福建省福州市长乐区"
            },
            {
                "code": "130201",
                "parentcode": "1302",
                "parenttext": "厦门市",
                "text": "思明区",
                "level": 3,
                "order": 1363,
                "relation_code": "350203",
                "relation_desc": "福建省厦门市思明区"
            },
            {
                "code": "130202",
                "parentcode": "1302",
                "parenttext": "厦门市",
                "text": "海沧区",
                "level": 3,
                "order": 1364,
                "relation_code": "350205",
                "relation_desc": "福建省厦门市海沧区"
            },
            {
                "code": "130203",
                "parentcode": "1302",
                "parenttext": "厦门市",
                "text": "湖里区",
                "level": 3,
                "order": 1365,
                "relation_code": "350206",
                "relation_desc": "福建省厦门市湖里区"
            },
            {
                "code": "130204",
                "parentcode": "1302",
                "parenttext": "厦门市",
                "text": "集美区",
                "level": 3,
                "order": 1366,
                "relation_code": "350211",
                "relation_desc": "福建省厦门市集美区"
            },
            {
                "code": "130205",
                "parentcode": "1302",
                "parenttext": "厦门市",
                "text": "同安区",
                "level": 3,
                "order": 1367,
                "relation_code": "350212",
                "relation_desc": "福建省厦门市同安区"
            },
            {
                "code": "130206",
                "parentcode": "1302",
                "parenttext": "厦门市",
                "text": "翔安区",
                "level": 3,
                "order": 1368,
                "relation_code": "350213",
                "relation_desc": "福建省厦门市翔安区"
            },
            {
                "code": "1303",
                "parentcode": "13",
                "parenttext": "福建省",
                "text": "莆田市",
                "level": 2,
                "order": 1369,
                "relation_code": "350300",
                "relation_desc": "福建省莆田市"
            },
            {
                "code": "130301",
                "parentcode": "1303",
                "parenttext": "莆田市",
                "text": "城厢区",
                "level": 3,
                "order": 1370,
                "relation_code": "350302",
                "relation_desc": "福建省莆田市城厢区"
            },
            {
                "code": "130302",
                "parentcode": "1303",
                "parenttext": "莆田市",
                "text": "涵江区",
                "level": 3,
                "order": 1371,
                "relation_code": "350303",
                "relation_desc": "福建省莆田市涵江区"
            },
            {
                "code": "130303",
                "parentcode": "1303",
                "parenttext": "莆田市",
                "text": "荔城区",
                "level": 3,
                "order": 1372,
                "relation_code": "350304",
                "relation_desc": "福建省莆田市荔城区"
            },
            {
                "code": "130304",
                "parentcode": "1303",
                "parenttext": "莆田市",
                "text": "秀屿区",
                "level": 3,
                "order": 1373,
                "relation_code": "350305",
                "relation_desc": "福建省莆田市秀屿区"
            },
            {
                "code": "130305",
                "parentcode": "1303",
                "parenttext": "莆田市",
                "text": "仙游县",
                "level": 3,
                "order": 1374,
                "relation_code": "350322",
                "relation_desc": "福建省仙游县"
            },
            {
                "code": "1304",
                "parentcode": "13",
                "parenttext": "福建省",
                "text": "三明市",
                "level": 2,
                "order": 1375,
                "relation_code": "350400",
                "relation_desc": "福建省三明市"
            },
            {
                "code": "130401",
                "parentcode": "1304",
                "parenttext": "三明市",
                "text": "梅列区",
                "level": 3,
                "order": 1376,
                "relation_code": "350402",
                "relation_desc": "福建省三明市梅列区"
            },
            {
                "code": "130402",
                "parentcode": "1304",
                "parenttext": "三明市",
                "text": "三元区",
                "level": 3,
                "order": 1377,
                "relation_code": "350403",
                "relation_desc": "福建省三明市三元区"
            },
            {
                "code": "130403",
                "parentcode": "1304",
                "parenttext": "三明市",
                "text": "明溪县",
                "level": 3,
                "order": 1378,
                "relation_code": "350421",
                "relation_desc": "福建省明溪县"
            },
            {
                "code": "130404",
                "parentcode": "1304",
                "parenttext": "三明市",
                "text": "清流县",
                "level": 3,
                "order": 1379,
                "relation_code": "350423",
                "relation_desc": "福建省清流县"
            },
            {
                "code": "130405",
                "parentcode": "1304",
                "parenttext": "三明市",
                "text": "宁化县",
                "level": 3,
                "order": 1380,
                "relation_code": "350424",
                "relation_desc": "福建省宁化县"
            },
            {
                "code": "130406",
                "parentcode": "1304",
                "parenttext": "三明市",
                "text": "大田县",
                "level": 3,
                "order": 1381,
                "relation_code": "350425",
                "relation_desc": "福建省大田县"
            },
            {
                "code": "130407",
                "parentcode": "1304",
                "parenttext": "三明市",
                "text": "尤溪县",
                "level": 3,
                "order": 1382,
                "relation_code": "350426",
                "relation_desc": "福建省尤溪县"
            },
            {
                "code": "130408",
                "parentcode": "1304",
                "parenttext": "三明市",
                "text": "沙县",
                "level": 3,
                "order": 1383,
                "relation_code": "350427",
                "relation_desc": "福建省沙县"
            },
            {
                "code": "130409",
                "parentcode": "1304",
                "parenttext": "三明市",
                "text": "将乐县",
                "level": 3,
                "order": 1384,
                "relation_code": "350428",
                "relation_desc": "福建省将乐县"
            },
            {
                "code": "130410",
                "parentcode": "1304",
                "parenttext": "三明市",
                "text": "泰宁县",
                "level": 3,
                "order": 1385,
                "relation_code": "350429",
                "relation_desc": "福建省泰宁县"
            },
            {
                "code": "130411",
                "parentcode": "1304",
                "parenttext": "三明市",
                "text": "建宁县",
                "level": 3,
                "order": 1386,
                "relation_code": "350430",
                "relation_desc": "福建省建宁县"
            },
            {
                "code": "130412",
                "parentcode": "1304",
                "parenttext": "三明市",
                "text": "永安市",
                "level": 3,
                "order": 1387,
                "relation_code": "350481",
                "relation_desc": "福建省永安市"
            },
            {
                "code": "1305",
                "parentcode": "13",
                "parenttext": "福建省",
                "text": "泉州市",
                "level": 2,
                "order": 1388,
                "relation_code": "350500",
                "relation_desc": "福建省泉州市"
            },
            {
                "code": "130501",
                "parentcode": "1305",
                "parenttext": "泉州市",
                "text": "鲤城区",
                "level": 3,
                "order": 1389,
                "relation_code": "350502",
                "relation_desc": "福建省泉州市鲤城区"
            },
            {
                "code": "130502",
                "parentcode": "1305",
                "parenttext": "泉州市",
                "text": "丰泽区",
                "level": 3,
                "order": 1390,
                "relation_code": "350503",
                "relation_desc": "福建省泉州市丰泽区"
            },
            {
                "code": "130503",
                "parentcode": "1305",
                "parenttext": "泉州市",
                "text": "洛江区",
                "level": 3,
                "order": 1391,
                "relation_code": "350504",
                "relation_desc": "福建省泉州市洛江区"
            },
            {
                "code": "130504",
                "parentcode": "1305",
                "parenttext": "泉州市",
                "text": "泉港区",
                "level": 3,
                "order": 1392,
                "relation_code": "350505",
                "relation_desc": "福建省泉州市泉港区"
            },
            {
                "code": "130505",
                "parentcode": "1305",
                "parenttext": "泉州市",
                "text": "惠安县",
                "level": 3,
                "order": 1393,
                "relation_code": "350521",
                "relation_desc": "福建省惠安县"
            },
            {
                "code": "130506",
                "parentcode": "1305",
                "parenttext": "泉州市",
                "text": "安溪县",
                "level": 3,
                "order": 1394,
                "relation_code": "350524",
                "relation_desc": "福建省安溪县"
            },
            {
                "code": "130507",
                "parentcode": "1305",
                "parenttext": "泉州市",
                "text": "永春县",
                "level": 3,
                "order": 1395,
                "relation_code": "350525",
                "relation_desc": "福建省永春县"
            },
            {
                "code": "130508",
                "parentcode": "1305",
                "parenttext": "泉州市",
                "text": "德化县",
                "level": 3,
                "order": 1396,
                "relation_code": "350526",
                "relation_desc": "福建省德化县"
            },
            {
                "code": "130509",
                "parentcode": "1305",
                "parenttext": "泉州市",
                "text": "金门县",
                "level": 3,
                "order": 1397,
                "relation_code": "350527",
                "relation_desc": "福建省金门县"
            },
            {
                "code": "130510",
                "parentcode": "1305",
                "parenttext": "泉州市",
                "text": "石狮市",
                "level": 3,
                "order": 1398,
                "relation_code": "350581",
                "relation_desc": "福建省石狮市"
            },
            {
                "code": "130511",
                "parentcode": "1305",
                "parenttext": "泉州市",
                "text": "晋江市",
                "level": 3,
                "order": 1399,
                "relation_code": "350582",
                "relation_desc": "福建省晋江市"
            },
            {
                "code": "130512",
                "parentcode": "1305",
                "parenttext": "泉州市",
                "text": "南安市",
                "level": 3,
                "order": 1400,
                "relation_code": "350583",
                "relation_desc": "福建省南安市"
            },
            {
                "code": "1306",
                "parentcode": "13",
                "parenttext": "福建省",
                "text": "漳州市",
                "level": 2,
                "order": 1401,
                "relation_code": "350600",
                "relation_desc": "福建省漳州市"
            },
            {
                "code": "130601",
                "parentcode": "1306",
                "parenttext": "漳州市",
                "text": "芗城区",
                "level": 3,
                "order": 1402,
                "relation_code": "350602",
                "relation_desc": "福建省漳州市芗城区"
            },
            {
                "code": "130602",
                "parentcode": "1306",
                "parenttext": "漳州市",
                "text": "龙文区",
                "level": 3,
                "order": 1403,
                "relation_code": "350603",
                "relation_desc": "福建省漳州市龙文区"
            },
            {
                "code": "130603",
                "parentcode": "1306",
                "parenttext": "漳州市",
                "text": "云霄县",
                "level": 3,
                "order": 1404,
                "relation_code": "350622",
                "relation_desc": "福建省云霄县"
            },
            {
                "code": "130604",
                "parentcode": "1306",
                "parenttext": "漳州市",
                "text": "漳浦县",
                "level": 3,
                "order": 1405,
                "relation_code": "350623",
                "relation_desc": "福建省漳浦县"
            },
            {
                "code": "130605",
                "parentcode": "1306",
                "parenttext": "漳州市",
                "text": "诏安县",
                "level": 3,
                "order": 1406,
                "relation_code": "350624",
                "relation_desc": "福建省诏安县"
            },
            {
                "code": "130606",
                "parentcode": "1306",
                "parenttext": "漳州市",
                "text": "长泰县",
                "level": 3,
                "order": 1407,
                "relation_code": "350625",
                "relation_desc": "福建省长泰县"
            },
            {
                "code": "130607",
                "parentcode": "1306",
                "parenttext": "漳州市",
                "text": "东山县",
                "level": 3,
                "order": 1408,
                "relation_code": "350626",
                "relation_desc": "福建省东山县"
            },
            {
                "code": "130608",
                "parentcode": "1306",
                "parenttext": "漳州市",
                "text": "南靖县",
                "level": 3,
                "order": 1409,
                "relation_code": "350627",
                "relation_desc": "福建省南靖县"
            },
            {
                "code": "130609",
                "parentcode": "1306",
                "parenttext": "漳州市",
                "text": "平和县",
                "level": 3,
                "order": 1410,
                "relation_code": "350628",
                "relation_desc": "福建省平和县"
            },
            {
                "code": "130610",
                "parentcode": "1306",
                "parenttext": "漳州市",
                "text": "华安县",
                "level": 3,
                "order": 1411,
                "relation_code": "350629",
                "relation_desc": "福建省华安县"
            },
            {
                "code": "130611",
                "parentcode": "1306",
                "parenttext": "漳州市",
                "text": "龙海市",
                "level": 3,
                "order": 1412,
                "relation_code": "350681",
                "relation_desc": "福建省龙海市"
            },
            {
                "code": "1307",
                "parentcode": "13",
                "parenttext": "福建省",
                "text": "南平市",
                "level": 2,
                "order": 1413,
                "relation_code": "350700",
                "relation_desc": "福建省南平市"
            },
            {
                "code": "130701",
                "parentcode": "1307",
                "parenttext": "南平市",
                "text": "延平区",
                "level": 3,
                "order": 1414,
                "relation_code": "350702",
                "relation_desc": "福建省南平市延平区"
            },
            {
                "code": "130702",
                "parentcode": "1307",
                "parenttext": "南平市",
                "text": "顺昌县",
                "level": 3,
                "order": 1415,
                "relation_code": "350721",
                "relation_desc": "福建省顺昌县"
            },
            {
                "code": "130703",
                "parentcode": "1307",
                "parenttext": "南平市",
                "text": "浦城县",
                "level": 3,
                "order": 1416,
                "relation_code": "350722",
                "relation_desc": "福建省浦城县"
            },
            {
                "code": "130704",
                "parentcode": "1307",
                "parenttext": "南平市",
                "text": "光泽县",
                "level": 3,
                "order": 1417,
                "relation_code": "350723",
                "relation_desc": "福建省光泽县"
            },
            {
                "code": "130705",
                "parentcode": "1307",
                "parenttext": "南平市",
                "text": "松溪县",
                "level": 3,
                "order": 1418,
                "relation_code": "350724",
                "relation_desc": "福建省松溪县"
            },
            {
                "code": "130706",
                "parentcode": "1307",
                "parenttext": "南平市",
                "text": "政和县",
                "level": 3,
                "order": 1419,
                "relation_code": "350725",
                "relation_desc": "福建省政和县"
            },
            {
                "code": "130707",
                "parentcode": "1307",
                "parenttext": "南平市",
                "text": "邵武市",
                "level": 3,
                "order": 1420,
                "relation_code": "350781",
                "relation_desc": "福建省邵武市"
            },
            {
                "code": "130708",
                "parentcode": "1307",
                "parenttext": "南平市",
                "text": "武夷山市",
                "level": 3,
                "order": 1421,
                "relation_code": "350782",
                "relation_desc": "福建省武夷山市"
            },
            {
                "code": "130709",
                "parentcode": "1307",
                "parenttext": "南平市",
                "text": "建瓯市",
                "level": 3,
                "order": 1422,
                "relation_code": "350783",
                "relation_desc": "福建省建瓯市"
            },
            {
                "code": "130710",
                "parentcode": "1307",
                "parenttext": "南平市",
                "text": "建阳区",
                "level": 3,
                "order": 1423,
                "relation_code": "350703",
                "relation_desc": "福建省南平市建阳区"
            },
            {
                "code": "1308",
                "parentcode": "13",
                "parenttext": "福建省",
                "text": "龙岩市",
                "level": 2,
                "order": 1424,
                "relation_code": "350800",
                "relation_desc": "福建省龙岩市"
            },
            {
                "code": "130801",
                "parentcode": "1308",
                "parenttext": "龙岩市",
                "text": "新罗区",
                "level": 3,
                "order": 1425,
                "relation_code": "350802",
                "relation_desc": "福建省龙岩市新罗区"
            },
            {
                "code": "130802",
                "parentcode": "1308",
                "parenttext": "龙岩市",
                "text": "长汀县",
                "level": 3,
                "order": 1426,
                "relation_code": "350821",
                "relation_desc": "福建省长汀县"
            },
            {
                "code": "130803",
                "parentcode": "1308",
                "parenttext": "龙岩市",
                "text": "永定区",
                "level": 3,
                "order": 1427,
                "relation_code": "350803",
                "relation_desc": "福建省龙岩市永定区"
            },
            {
                "code": "130804",
                "parentcode": "1308",
                "parenttext": "龙岩市",
                "text": "上杭县",
                "level": 3,
                "order": 1428,
                "relation_code": "350823",
                "relation_desc": "福建省上杭县"
            },
            {
                "code": "130805",
                "parentcode": "1308",
                "parenttext": "龙岩市",
                "text": "武平县",
                "level": 3,
                "order": 1429,
                "relation_code": "350824",
                "relation_desc": "福建省武平县"
            },
            {
                "code": "130806",
                "parentcode": "1308",
                "parenttext": "龙岩市",
                "text": "连城县",
                "level": 3,
                "order": 1430,
                "relation_code": "350825",
                "relation_desc": "福建省连城县"
            },
            {
                "code": "130807",
                "parentcode": "1308",
                "parenttext": "龙岩市",
                "text": "漳平市",
                "level": 3,
                "order": 1431,
                "relation_code": "350881",
                "relation_desc": "福建省漳平市"
            },
            {
                "code": "1309",
                "parentcode": "13",
                "parenttext": "福建省",
                "text": "宁德市",
                "level": 2,
                "order": 1432,
                "relation_code": "350900",
                "relation_desc": "福建省宁德市"
            },
            {
                "code": "130901",
                "parentcode": "1309",
                "parenttext": "宁德市",
                "text": "蕉城区",
                "level": 3,
                "order": 1433,
                "relation_code": "350902",
                "relation_desc": "福建省宁德市蕉城区"
            },
            {
                "code": "130902",
                "parentcode": "1309",
                "parenttext": "宁德市",
                "text": "霞浦县",
                "level": 3,
                "order": 1434,
                "relation_code": "350921",
                "relation_desc": "福建省霞浦县"
            },
            {
                "code": "130903",
                "parentcode": "1309",
                "parenttext": "宁德市",
                "text": "古田县",
                "level": 3,
                "order": 1435,
                "relation_code": "350922",
                "relation_desc": "福建省古田县"
            },
            {
                "code": "130904",
                "parentcode": "1309",
                "parenttext": "宁德市",
                "text": "屏南县",
                "level": 3,
                "order": 1436,
                "relation_code": "350923",
                "relation_desc": "福建省屏南县"
            },
            {
                "code": "130905",
                "parentcode": "1309",
                "parenttext": "宁德市",
                "text": "寿宁县",
                "level": 3,
                "order": 1437,
                "relation_code": "350924",
                "relation_desc": "福建省寿宁县"
            },
            {
                "code": "130906",
                "parentcode": "1309",
                "parenttext": "宁德市",
                "text": "周宁县",
                "level": 3,
                "order": 1438,
                "relation_code": "350925",
                "relation_desc": "福建省周宁县"
            },
            {
                "code": "130907",
                "parentcode": "1309",
                "parenttext": "宁德市",
                "text": "柘荣县",
                "level": 3,
                "order": 1439,
                "relation_code": "350926",
                "relation_desc": "福建省柘荣县"
            },
            {
                "code": "130908",
                "parentcode": "1309",
                "parenttext": "宁德市",
                "text": "福安市",
                "level": 3,
                "order": 1440,
                "relation_code": "350981",
                "relation_desc": "福建省福安市"
            },
            {
                "code": "130909",
                "parentcode": "1309",
                "parenttext": "宁德市",
                "text": "福鼎市",
                "level": 3,
                "order": 1441,
                "relation_code": "350982",
                "relation_desc": "福建省福鼎市"
            },
            {
                "code": "14",
                "parentcode": "0",
                "parenttext": "0",
                "text": "江西省",
                "level": 1,
                "order": 1442,
                "relation_code": "",
                "relation_desc": "江西省"
            },
            {
                "code": "1401",
                "parentcode": "14",
                "parenttext": "江西省",
                "text": "南昌市",
                "level": 2,
                "order": 1443,
                "relation_code": "360100",
                "relation_desc": "江西省南昌市"
            },
            {
                "code": "140101",
                "parentcode": "1401",
                "parenttext": "南昌市",
                "text": "东湖区",
                "level": 3,
                "order": 1444,
                "relation_code": "360102",
                "relation_desc": "江西省南昌市东湖区"
            },
            {
                "code": "140102",
                "parentcode": "1401",
                "parenttext": "南昌市",
                "text": "西湖区",
                "level": 3,
                "order": 1445,
                "relation_code": "360103",
                "relation_desc": "江西省南昌市西湖区"
            },
            {
                "code": "140103",
                "parentcode": "1401",
                "parenttext": "南昌市",
                "text": "青云谱区",
                "level": 3,
                "order": 1446,
                "relation_code": "360104",
                "relation_desc": "江西省南昌市青云谱区"
            },
            {
                "code": "140104",
                "parentcode": "1401",
                "parenttext": "南昌市",
                "text": "湾里区",
                "level": 3,
                "order": 1447,
                "relation_code": "360105",
                "relation_desc": "江西省南昌市湾里区"
            },
            {
                "code": "140105",
                "parentcode": "1401",
                "parenttext": "南昌市",
                "text": "青山湖区",
                "level": 3,
                "order": 1448,
                "relation_code": "360111",
                "relation_desc": "江西省南昌市青山湖区"
            },
            {
                "code": "140106",
                "parentcode": "1401",
                "parenttext": "南昌市",
                "text": "南昌县",
                "level": 3,
                "order": 1449,
                "relation_code": "360121",
                "relation_desc": "江西省南昌县"
            },
            {
                "code": "140107",
                "parentcode": "1401",
                "parenttext": "南昌市",
                "text": "新建区",
                "level": 3,
                "order": 1450,
                "relation_code": "360112",
                "relation_desc": "江西省南昌市新建区"
            },
            {
                "code": "140108",
                "parentcode": "1401",
                "parenttext": "南昌市",
                "text": "安义县",
                "level": 3,
                "order": 1451,
                "relation_code": "360123",
                "relation_desc": "江西省安义县"
            },
            {
                "code": "140109",
                "parentcode": "1401",
                "parenttext": "南昌市",
                "text": "进贤县",
                "level": 3,
                "order": 1452,
                "relation_code": "360124",
                "relation_desc": "江西省进贤县"
            },
            {
                "code": "1402",
                "parentcode": "14",
                "parenttext": "江西省",
                "text": "景德镇市",
                "level": 2,
                "order": 1453,
                "relation_code": "360200",
                "relation_desc": "江西省景德镇市"
            },
            {
                "code": "140201",
                "parentcode": "1402",
                "parenttext": "景德镇市",
                "text": "昌江区",
                "level": 3,
                "order": 1454,
                "relation_code": "360202",
                "relation_desc": "江西省景德镇市昌江区"
            },
            {
                "code": "140202",
                "parentcode": "1402",
                "parenttext": "景德镇市",
                "text": "珠山区",
                "level": 3,
                "order": 1455,
                "relation_code": "360203",
                "relation_desc": "江西省景德镇市珠山区"
            },
            {
                "code": "140203",
                "parentcode": "1402",
                "parenttext": "景德镇市",
                "text": "浮梁县",
                "level": 3,
                "order": 1456,
                "relation_code": "360222",
                "relation_desc": "江西省浮梁县"
            },
            {
                "code": "140204",
                "parentcode": "1402",
                "parenttext": "景德镇市",
                "text": "乐平市",
                "level": 3,
                "order": 1457,
                "relation_code": "360281",
                "relation_desc": "江西省乐平市"
            },
            {
                "code": "1403",
                "parentcode": "14",
                "parenttext": "江西省",
                "text": "萍乡市",
                "level": 2,
                "order": 1458,
                "relation_code": "360300",
                "relation_desc": "江西省萍乡市"
            },
            {
                "code": "140301",
                "parentcode": "1403",
                "parenttext": "萍乡市",
                "text": "安源区",
                "level": 3,
                "order": 1459,
                "relation_code": "360302",
                "relation_desc": "江西省萍乡市安源区"
            },
            {
                "code": "140302",
                "parentcode": "1403",
                "parenttext": "萍乡市",
                "text": "湘东区",
                "level": 3,
                "order": 1460,
                "relation_code": "360313",
                "relation_desc": "江西省萍乡市湘东区"
            },
            {
                "code": "140303",
                "parentcode": "1403",
                "parenttext": "萍乡市",
                "text": "莲花县",
                "level": 3,
                "order": 1461,
                "relation_code": "360321",
                "relation_desc": "江西省莲花县"
            },
            {
                "code": "140304",
                "parentcode": "1403",
                "parenttext": "萍乡市",
                "text": "上栗县",
                "level": 3,
                "order": 1462,
                "relation_code": "360322",
                "relation_desc": "江西省上栗县"
            },
            {
                "code": "140305",
                "parentcode": "1403",
                "parenttext": "萍乡市",
                "text": "芦溪县",
                "level": 3,
                "order": 1463,
                "relation_code": "360323",
                "relation_desc": "江西省芦溪县"
            },
            {
                "code": "1404",
                "parentcode": "14",
                "parenttext": "江西省",
                "text": "九江市",
                "level": 2,
                "order": 1464,
                "relation_code": "360400",
                "relation_desc": "江西省九江市"
            },
            {
                "code": "140401",
                "parentcode": "1404",
                "parenttext": "九江市",
                "text": "濂溪区",
                "level": 3,
                "order": 1465,
                "relation_code": "360402",
                "relation_desc": "江西省九江市濂溪区"
            },
            {
                "code": "140402",
                "parentcode": "1404",
                "parenttext": "九江市",
                "text": "浔阳区",
                "level": 3,
                "order": 1466,
                "relation_code": "360403",
                "relation_desc": "江西省九江市浔阳区"
            },
            {
                "code": "140403",
                "parentcode": "1404",
                "parenttext": "九江市",
                "text": "九江县",
                "level": 3,
                "order": 1467,
                "relation_code": "360401",
                "relation_desc": "江西省九江县"
            },
            {
                "code": "140404",
                "parentcode": "1404",
                "parenttext": "九江市",
                "text": "武宁县",
                "level": 3,
                "order": 1468,
                "relation_code": "360423",
                "relation_desc": "江西省武宁县"
            },
            {
                "code": "140405",
                "parentcode": "1404",
                "parenttext": "九江市",
                "text": "修水县",
                "level": 3,
                "order": 1469,
                "relation_code": "360424",
                "relation_desc": "江西省修水县"
            },
            {
                "code": "140406",
                "parentcode": "1404",
                "parenttext": "九江市",
                "text": "永修县",
                "level": 3,
                "order": 1470,
                "relation_code": "360425",
                "relation_desc": "江西省永修县"
            },
            {
                "code": "140407",
                "parentcode": "1404",
                "parenttext": "九江市",
                "text": "德安县",
                "level": 3,
                "order": 1471,
                "relation_code": "360426",
                "relation_desc": "江西省德安县"
            },
            {
                "code": "140408",
                "parentcode": "1404",
                "parenttext": "九江市",
                "text": "庐山市",
                "level": 3,
                "order": 1472,
                "relation_code": "360483",
                "relation_desc": "江西省庐山市"
            },
            {
                "code": "140409",
                "parentcode": "1404",
                "parenttext": "九江市",
                "text": "都昌县",
                "level": 3,
                "order": 1473,
                "relation_code": "360428",
                "relation_desc": "江西省都昌县"
            },
            {
                "code": "140410",
                "parentcode": "1404",
                "parenttext": "九江市",
                "text": "湖口县",
                "level": 3,
                "order": 1474,
                "relation_code": "360429",
                "relation_desc": "江西省湖口县"
            },
            {
                "code": "140411",
                "parentcode": "1404",
                "parenttext": "九江市",
                "text": "彭泽县",
                "level": 3,
                "order": 1475,
                "relation_code": "360430",
                "relation_desc": "江西省彭泽县"
            },
            {
                "code": "140412",
                "parentcode": "1404",
                "parenttext": "九江市",
                "text": "瑞昌市",
                "level": 3,
                "order": 1476,
                "relation_code": "360481",
                "relation_desc": "江西省瑞昌市"
            },
            {
                "code": "1405",
                "parentcode": "14",
                "parenttext": "江西省",
                "text": "新余市",
                "level": 2,
                "order": 1477,
                "relation_code": "360500",
                "relation_desc": "江西省新余市"
            },
            {
                "code": "140501",
                "parentcode": "1405",
                "parenttext": "新余市",
                "text": "渝水区",
                "level": 3,
                "order": 1478,
                "relation_code": "360502",
                "relation_desc": "江西省新余市渝水区"
            },
            {
                "code": "140502",
                "parentcode": "1405",
                "parenttext": "新余市",
                "text": "分宜县",
                "level": 3,
                "order": 1479,
                "relation_code": "360521",
                "relation_desc": "江西省分宜县"
            },
            {
                "code": "1406",
                "parentcode": "14",
                "parenttext": "江西省",
                "text": "鹰潭市",
                "level": 2,
                "order": 1480,
                "relation_code": "360600",
                "relation_desc": "江西省鹰潭市"
            },
            {
                "code": "140601",
                "parentcode": "1406",
                "parenttext": "鹰潭市",
                "text": "月湖区",
                "level": 3,
                "order": 1481,
                "relation_code": "360602",
                "relation_desc": "江西省鹰潭市月湖区"
            },
            {
                "code": "140602",
                "parentcode": "1406",
                "parenttext": "鹰潭市",
                "text": "余江区",
                "level": 3,
                "order": 1482,
                "relation_code": "360603",
                "relation_desc": "江西省鹰潭市余江区"
            },
            {
                "code": "140603",
                "parentcode": "1406",
                "parenttext": "鹰潭市",
                "text": "贵溪市",
                "level": 3,
                "order": 1483,
                "relation_code": "360681",
                "relation_desc": "江西省贵溪市"
            },
            {
                "code": "1407",
                "parentcode": "14",
                "parenttext": "江西省",
                "text": "赣州市",
                "level": 2,
                "order": 1484,
                "relation_code": "360700",
                "relation_desc": "江西省赣州市"
            },
            {
                "code": "140701",
                "parentcode": "1407",
                "parenttext": "赣州市",
                "text": "章贡区",
                "level": 3,
                "order": 1485,
                "relation_code": "360702",
                "relation_desc": "江西省赣州市章贡区"
            },
            {
                "code": "140702",
                "parentcode": "1407",
                "parenttext": "赣州市",
                "text": "赣县",
                "level": 3,
                "order": 1486,
                "relation_code": "360701",
                "relation_desc": "江西省赣县"
            },
            {
                "code": "140703",
                "parentcode": "1407",
                "parenttext": "赣州市",
                "text": "信丰县",
                "level": 3,
                "order": 1487,
                "relation_code": "360722",
                "relation_desc": "江西省信丰县"
            },
            {
                "code": "140704",
                "parentcode": "1407",
                "parenttext": "赣州市",
                "text": "大余县",
                "level": 3,
                "order": 1488,
                "relation_code": "360723",
                "relation_desc": "江西省大余县"
            },
            {
                "code": "140705",
                "parentcode": "1407",
                "parenttext": "赣州市",
                "text": "上犹县",
                "level": 3,
                "order": 1489,
                "relation_code": "360724",
                "relation_desc": "江西省上犹县"
            },
            {
                "code": "140706",
                "parentcode": "1407",
                "parenttext": "赣州市",
                "text": "崇义县",
                "level": 3,
                "order": 1490,
                "relation_code": "360725",
                "relation_desc": "江西省崇义县"
            },
            {
                "code": "140707",
                "parentcode": "1407",
                "parenttext": "赣州市",
                "text": "安远县",
                "level": 3,
                "order": 1491,
                "relation_code": "360726",
                "relation_desc": "江西省安远县"
            },
            {
                "code": "140708",
                "parentcode": "1407",
                "parenttext": "赣州市",
                "text": "龙南县",
                "level": 3,
                "order": 1492,
                "relation_code": "360727",
                "relation_desc": "江西省龙南县"
            },
            {
                "code": "140709",
                "parentcode": "1407",
                "parenttext": "赣州市",
                "text": "定南县",
                "level": 3,
                "order": 1493,
                "relation_code": "360728",
                "relation_desc": "江西省定南县"
            },
            {
                "code": "140710",
                "parentcode": "1407",
                "parenttext": "赣州市",
                "text": "全南县",
                "level": 3,
                "order": 1494,
                "relation_code": "360729",
                "relation_desc": "江西省全南县"
            },
            {
                "code": "140711",
                "parentcode": "1407",
                "parenttext": "赣州市",
                "text": "宁都县",
                "level": 3,
                "order": 1495,
                "relation_code": "360730",
                "relation_desc": "江西省宁都县"
            },
            {
                "code": "140712",
                "parentcode": "1407",
                "parenttext": "赣州市",
                "text": "于都县",
                "level": 3,
                "order": 1496,
                "relation_code": "360731",
                "relation_desc": "江西省于都县"
            },
            {
                "code": "140713",
                "parentcode": "1407",
                "parenttext": "赣州市",
                "text": "兴国县",
                "level": 3,
                "order": 1497,
                "relation_code": "360732",
                "relation_desc": "江西省兴国县"
            },
            {
                "code": "140714",
                "parentcode": "1407",
                "parenttext": "赣州市",
                "text": "会昌县",
                "level": 3,
                "order": 1498,
                "relation_code": "360733",
                "relation_desc": "江西省会昌县"
            },
            {
                "code": "140715",
                "parentcode": "1407",
                "parenttext": "赣州市",
                "text": "寻乌县",
                "level": 3,
                "order": 1499,
                "relation_code": "360734",
                "relation_desc": "江西省寻乌县"
            },
            {
                "code": "140716",
                "parentcode": "1407",
                "parenttext": "赣州市",
                "text": "石城县",
                "level": 3,
                "order": 1500,
                "relation_code": "360735",
                "relation_desc": "江西省石城县"
            },
            {
                "code": "140717",
                "parentcode": "1407",
                "parenttext": "赣州市",
                "text": "瑞金市",
                "level": 3,
                "order": 1501,
                "relation_code": "360781",
                "relation_desc": "江西省瑞金市"
            },
            {
                "code": "140718",
                "parentcode": "1407",
                "parenttext": "赣州市",
                "text": "南康区",
                "level": 3,
                "order": 1502,
                "relation_code": "360703",
                "relation_desc": "江西省赣州市南康区"
            },
            {
                "code": "1408",
                "parentcode": "14",
                "parenttext": "江西省",
                "text": "吉安市",
                "level": 2,
                "order": 1503,
                "relation_code": "360800",
                "relation_desc": "江西省吉安市"
            },
            {
                "code": "140801",
                "parentcode": "1408",
                "parenttext": "吉安市",
                "text": "吉州区",
                "level": 3,
                "order": 1504,
                "relation_code": "360802",
                "relation_desc": "江西省吉安市吉州区"
            },
            {
                "code": "140802",
                "parentcode": "1408",
                "parenttext": "吉安市",
                "text": "青原区",
                "level": 3,
                "order": 1505,
                "relation_code": "360803",
                "relation_desc": "江西省吉安市青原区"
            },
            {
                "code": "140803",
                "parentcode": "1408",
                "parenttext": "吉安市",
                "text": "吉安县",
                "level": 3,
                "order": 1506,
                "relation_code": "360821",
                "relation_desc": "江西省吉安县"
            },
            {
                "code": "140804",
                "parentcode": "1408",
                "parenttext": "吉安市",
                "text": "吉水县",
                "level": 3,
                "order": 1507,
                "relation_code": "360822",
                "relation_desc": "江西省吉水县"
            },
            {
                "code": "140805",
                "parentcode": "1408",
                "parenttext": "吉安市",
                "text": "峡江县",
                "level": 3,
                "order": 1508,
                "relation_code": "360823",
                "relation_desc": "江西省峡江县"
            },
            {
                "code": "140806",
                "parentcode": "1408",
                "parenttext": "吉安市",
                "text": "新干县",
                "level": 3,
                "order": 1509,
                "relation_code": "360824",
                "relation_desc": "江西省新干县"
            },
            {
                "code": "140807",
                "parentcode": "1408",
                "parenttext": "吉安市",
                "text": "永丰县",
                "level": 3,
                "order": 1510,
                "relation_code": "360825",
                "relation_desc": "江西省永丰县"
            },
            {
                "code": "140808",
                "parentcode": "1408",
                "parenttext": "吉安市",
                "text": "泰和县",
                "level": 3,
                "order": 1511,
                "relation_code": "360826",
                "relation_desc": "江西省泰和县"
            },
            {
                "code": "140809",
                "parentcode": "1408",
                "parenttext": "吉安市",
                "text": "遂川县",
                "level": 3,
                "order": 1512,
                "relation_code": "360827",
                "relation_desc": "江西省遂川县"
            },
            {
                "code": "140810",
                "parentcode": "1408",
                "parenttext": "吉安市",
                "text": "万安县",
                "level": 3,
                "order": 1513,
                "relation_code": "360828",
                "relation_desc": "江西省万安县"
            },
            {
                "code": "140811",
                "parentcode": "1408",
                "parenttext": "吉安市",
                "text": "安福县",
                "level": 3,
                "order": 1514,
                "relation_code": "360829",
                "relation_desc": "江西省安福县"
            },
            {
                "code": "140812",
                "parentcode": "1408",
                "parenttext": "吉安市",
                "text": "永新县",
                "level": 3,
                "order": 1515,
                "relation_code": "360830",
                "relation_desc": "江西省永新县"
            },
            {
                "code": "140813",
                "parentcode": "1408",
                "parenttext": "吉安市",
                "text": "井冈山市",
                "level": 3,
                "order": 1516,
                "relation_code": "360881",
                "relation_desc": "江西省井冈山市"
            },
            {
                "code": "1409",
                "parentcode": "14",
                "parenttext": "江西省",
                "text": "宜春市",
                "level": 2,
                "order": 1517,
                "relation_code": "360900",
                "relation_desc": "江西省宜春市"
            },
            {
                "code": "140901",
                "parentcode": "1409",
                "parenttext": "宜春市",
                "text": "袁州区",
                "level": 3,
                "order": 1518,
                "relation_code": "360902",
                "relation_desc": "江西省宜春市袁州区"
            },
            {
                "code": "140902",
                "parentcode": "1409",
                "parenttext": "宜春市",
                "text": "奉新县",
                "level": 3,
                "order": 1519,
                "relation_code": "360921",
                "relation_desc": "江西省奉新县"
            },
            {
                "code": "140903",
                "parentcode": "1409",
                "parenttext": "宜春市",
                "text": "万载县",
                "level": 3,
                "order": 1520,
                "relation_code": "360922",
                "relation_desc": "江西省万载县"
            },
            {
                "code": "140904",
                "parentcode": "1409",
                "parenttext": "宜春市",
                "text": "上高县",
                "level": 3,
                "order": 1521,
                "relation_code": "360923",
                "relation_desc": "江西省上高县"
            },
            {
                "code": "140905",
                "parentcode": "1409",
                "parenttext": "宜春市",
                "text": "宜丰县",
                "level": 3,
                "order": 1522,
                "relation_code": "360924",
                "relation_desc": "江西省宜丰县"
            },
            {
                "code": "140906",
                "parentcode": "1409",
                "parenttext": "宜春市",
                "text": "靖安县",
                "level": 3,
                "order": 1523,
                "relation_code": "360925",
                "relation_desc": "江西省靖安县"
            },
            {
                "code": "140907",
                "parentcode": "1409",
                "parenttext": "宜春市",
                "text": "铜鼓县",
                "level": 3,
                "order": 1524,
                "relation_code": "360926",
                "relation_desc": "江西省铜鼓县"
            },
            {
                "code": "140908",
                "parentcode": "1409",
                "parenttext": "宜春市",
                "text": "丰城市",
                "level": 3,
                "order": 1525,
                "relation_code": "360981",
                "relation_desc": "江西省丰城市"
            },
            {
                "code": "140909",
                "parentcode": "1409",
                "parenttext": "宜春市",
                "text": "樟树市",
                "level": 3,
                "order": 1526,
                "relation_code": "360982",
                "relation_desc": "江西省樟树市"
            },
            {
                "code": "140910",
                "parentcode": "1409",
                "parenttext": "宜春市",
                "text": "高安市",
                "level": 3,
                "order": 1527,
                "relation_code": "360983",
                "relation_desc": "江西省高安市"
            },
            {
                "code": "1410",
                "parentcode": "14",
                "parenttext": "江西省",
                "text": "抚州市",
                "level": 2,
                "order": 1528,
                "relation_code": "361000",
                "relation_desc": "江西省抚州市"
            },
            {
                "code": "141001",
                "parentcode": "1410",
                "parenttext": "抚州市",
                "text": "临川区",
                "level": 3,
                "order": 1529,
                "relation_code": "361002",
                "relation_desc": "江西省抚州市临川区"
            },
            {
                "code": "141002",
                "parentcode": "1410",
                "parenttext": "抚州市",
                "text": "南城县",
                "level": 3,
                "order": 1530,
                "relation_code": "361021",
                "relation_desc": "江西省南城县"
            },
            {
                "code": "141003",
                "parentcode": "1410",
                "parenttext": "抚州市",
                "text": "黎川县",
                "level": 3,
                "order": 1531,
                "relation_code": "361022",
                "relation_desc": "江西省黎川县"
            },
            {
                "code": "141004",
                "parentcode": "1410",
                "parenttext": "抚州市",
                "text": "南丰县",
                "level": 3,
                "order": 1532,
                "relation_code": "361023",
                "relation_desc": "江西省南丰县"
            },
            {
                "code": "141005",
                "parentcode": "1410",
                "parenttext": "抚州市",
                "text": "崇仁县",
                "level": 3,
                "order": 1533,
                "relation_code": "361024",
                "relation_desc": "江西省崇仁县"
            },
            {
                "code": "141006",
                "parentcode": "1410",
                "parenttext": "抚州市",
                "text": "乐安县",
                "level": 3,
                "order": 1534,
                "relation_code": "361025",
                "relation_desc": "江西省乐安县"
            },
            {
                "code": "141007",
                "parentcode": "1410",
                "parenttext": "抚州市",
                "text": "宜黄县",
                "level": 3,
                "order": 1535,
                "relation_code": "361026",
                "relation_desc": "江西省宜黄县"
            },
            {
                "code": "141008",
                "parentcode": "1410",
                "parenttext": "抚州市",
                "text": "金溪县",
                "level": 3,
                "order": 1536,
                "relation_code": "361027",
                "relation_desc": "江西省金溪县"
            },
            {
                "code": "141009",
                "parentcode": "1410",
                "parenttext": "抚州市",
                "text": "资溪县",
                "level": 3,
                "order": 1537,
                "relation_code": "361028",
                "relation_desc": "江西省资溪县"
            },
            {
                "code": "141010",
                "parentcode": "1410",
                "parenttext": "抚州市",
                "text": "东乡县",
                "level": 3,
                "order": 1538,
                "relation_code": "361001",
                "relation_desc": "江西省东乡县"
            },
            {
                "code": "141011",
                "parentcode": "1410",
                "parenttext": "抚州市",
                "text": "广昌县",
                "level": 3,
                "order": 1539,
                "relation_code": "361030",
                "relation_desc": "江西省广昌县"
            },
            {
                "code": "1411",
                "parentcode": "14",
                "parenttext": "江西省",
                "text": "上饶市",
                "level": 2,
                "order": 1540,
                "relation_code": "361100",
                "relation_desc": "江西省上饶市"
            },
            {
                "code": "141101",
                "parentcode": "1411",
                "parenttext": "上饶市",
                "text": "信州区",
                "level": 3,
                "order": 1541,
                "relation_code": "361102",
                "relation_desc": "江西省上饶市信州区"
            },
            {
                "code": "141102",
                "parentcode": "1411",
                "parenttext": "上饶市",
                "text": "广丰区",
                "level": 3,
                "order": 1542,
                "relation_code": "361103",
                "relation_desc": "江西省上饶市广丰区"
            },
            {
                "code": "141103",
                "parentcode": "1411",
                "parenttext": "上饶市",
                "text": "上饶县",
                "level": 3,
                "order": 1543,
                "relation_code": "361101",
                "relation_desc": "江西省上饶县"
            },
            {
                "code": "141104",
                "parentcode": "1411",
                "parenttext": "上饶市",
                "text": "玉山县",
                "level": 3,
                "order": 1544,
                "relation_code": "361123",
                "relation_desc": "江西省玉山县"
            },
            {
                "code": "141105",
                "parentcode": "1411",
                "parenttext": "上饶市",
                "text": "铅山县",
                "level": 3,
                "order": 1545,
                "relation_code": "361124",
                "relation_desc": "江西省铅山县"
            },
            {
                "code": "141106",
                "parentcode": "1411",
                "parenttext": "上饶市",
                "text": "横峰县",
                "level": 3,
                "order": 1546,
                "relation_code": "361125",
                "relation_desc": "江西省横峰县"
            },
            {
                "code": "141107",
                "parentcode": "1411",
                "parenttext": "上饶市",
                "text": "弋阳县",
                "level": 3,
                "order": 1547,
                "relation_code": "361126",
                "relation_desc": "江西省弋阳县"
            },
            {
                "code": "141108",
                "parentcode": "1411",
                "parenttext": "上饶市",
                "text": "余干县",
                "level": 3,
                "order": 1548,
                "relation_code": "361127",
                "relation_desc": "江西省余干县"
            },
            {
                "code": "141109",
                "parentcode": "1411",
                "parenttext": "上饶市",
                "text": "鄱阳县",
                "level": 3,
                "order": 1549,
                "relation_code": "361128",
                "relation_desc": "江西省鄱阳县"
            },
            {
                "code": "141110",
                "parentcode": "1411",
                "parenttext": "上饶市",
                "text": "万年县",
                "level": 3,
                "order": 1550,
                "relation_code": "361129",
                "relation_desc": "江西省万年县"
            },
            {
                "code": "141111",
                "parentcode": "1411",
                "parenttext": "上饶市",
                "text": "婺源县",
                "level": 3,
                "order": 1551,
                "relation_code": "361130",
                "relation_desc": "江西省婺源县"
            },
            {
                "code": "141112",
                "parentcode": "1411",
                "parenttext": "上饶市",
                "text": "德兴市",
                "level": 3,
                "order": 1552,
                "relation_code": "361181",
                "relation_desc": "江西省德兴市"
            },
            {
                "code": "15",
                "parentcode": "0",
                "parenttext": "0",
                "text": "山东省",
                "level": 1,
                "order": 1553,
                "relation_code": "",
                "relation_desc": "山东省"
            },
            {
                "code": "141113",
                "parentcode": "1411",
                "parenttext": "上饶市",
                "text": "广信区",
                "level": 3,
                "order": 1553,
                "relation_code": "361101",
                "relation_desc": "江西省上饶市"
            },
            {
                "code": "1501",
                "parentcode": "15",
                "parenttext": "山东省",
                "text": "济南市",
                "level": 2,
                "order": 1554,
                "relation_code": "370100",
                "relation_desc": "山东省济南市"
            },
            {
                "code": "150101",
                "parentcode": "1501",
                "parenttext": "济南市",
                "text": "历下区",
                "level": 3,
                "order": 1555,
                "relation_code": "370102",
                "relation_desc": "山东省济南市历下区"
            },
            {
                "code": "150102",
                "parentcode": "1501",
                "parenttext": "济南市",
                "text": "市中区",
                "level": 3,
                "order": 1556,
                "relation_code": "370103",
                "relation_desc": "山东省济南市市中区"
            },
            {
                "code": "150103",
                "parentcode": "1501",
                "parenttext": "济南市",
                "text": "槐荫区",
                "level": 3,
                "order": 1557,
                "relation_code": "370104",
                "relation_desc": "山东省济南市槐荫区"
            },
            {
                "code": "150104",
                "parentcode": "1501",
                "parenttext": "济南市",
                "text": "天桥区",
                "level": 3,
                "order": 1558,
                "relation_code": "370105",
                "relation_desc": "山东省济南市天桥区"
            },
            {
                "code": "150105",
                "parentcode": "1501",
                "parenttext": "济南市",
                "text": "历城区",
                "level": 3,
                "order": 1559,
                "relation_code": "370112",
                "relation_desc": "山东省济南市历城区"
            },
            {
                "code": "150106",
                "parentcode": "1501",
                "parenttext": "济南市",
                "text": "长清区",
                "level": 3,
                "order": 1560,
                "relation_code": "370113",
                "relation_desc": "山东省济南市长清区"
            },
            {
                "code": "150107",
                "parentcode": "1501",
                "parenttext": "济南市",
                "text": "平阴县",
                "level": 3,
                "order": 1561,
                "relation_code": "370124",
                "relation_desc": "山东省平阴县"
            },
            {
                "code": "150108",
                "parentcode": "1501",
                "parenttext": "济南市",
                "text": "济阳县",
                "level": 3,
                "order": 1562,
                "relation_code": "370101",
                "relation_desc": "山东省济阳县"
            },
            {
                "code": "150109",
                "parentcode": "1501",
                "parenttext": "济南市",
                "text": "商河县",
                "level": 3,
                "order": 1563,
                "relation_code": "370126",
                "relation_desc": "山东省商河县"
            },
            {
                "code": "150110",
                "parentcode": "1501",
                "parenttext": "济南市",
                "text": "章丘市",
                "level": 3,
                "order": 1564,
                "relation_code": "370101",
                "relation_desc": "山东省章丘市"
            },
            {
                "code": "1502",
                "parentcode": "15",
                "parenttext": "山东省",
                "text": "青岛市",
                "level": 2,
                "order": 1565,
                "relation_code": "370200",
                "relation_desc": "山东省青岛市"
            },
            {
                "code": "150111",
                "parentcode": "1501",
                "parenttext": "济南市",
                "text": "莱芜区",
                "level": 3,
                "order": 1565,
                "relation_code": "370116",
                "relation_desc": "山东省济南市莱芜区"
            },
            {
                "code": "150201",
                "parentcode": "1502",
                "parenttext": "青岛市",
                "text": "市南区",
                "level": 3,
                "order": 1566,
                "relation_code": "370202",
                "relation_desc": "山东省青岛市市南区"
            },
            {
                "code": "150112",
                "parentcode": "1501",
                "parenttext": "济南市",
                "text": "章丘区",
                "level": 3,
                "order": 1566,
                "relation_code": "370114",
                "relation_desc": "山东省济南市章丘区"
            },
            {
                "code": "150202",
                "parentcode": "1502",
                "parenttext": "青岛市",
                "text": "市北区",
                "level": 3,
                "order": 1567,
                "relation_code": "370203",
                "relation_desc": "山东省青岛市市北区"
            },
            {
                "code": "150113",
                "parentcode": "1501",
                "parenttext": "济南市",
                "text": "济阳区",
                "level": 3,
                "order": 1567,
                "relation_code": "370115",
                "relation_desc": "山东省济南市济阳区"
            },
            {
                "code": "150203",
                "parentcode": "1502",
                "parenttext": "青岛市",
                "text": "四方区",
                "level": 3,
                "order": 1568,
                "relation_code": "370201",
                "relation_desc": "山东省青岛市四方区"
            },
            {
                "code": "150114",
                "parentcode": "1501",
                "parenttext": "济南市",
                "text": "济南高新技术产业开发区",
                "level": 3,
                "order": 1568,
                "relation_code": "370101",
                "relation_desc": "山东省济南市"
            },
            {
                "code": "150204",
                "parentcode": "1502",
                "parenttext": "青岛市",
                "text": "黄岛区",
                "level": 3,
                "order": 1569,
                "relation_code": "370211",
                "relation_desc": "山东省青岛市黄岛区"
            },
            {
                "code": "150115",
                "parentcode": "1501",
                "parenttext": "济南市",
                "text": "钢城区",
                "level": 3,
                "order": 1569,
                "relation_code": "370117",
                "relation_desc": "山东省济南市钢城区"
            },
            {
                "code": "150205",
                "parentcode": "1502",
                "parenttext": "青岛市",
                "text": "崂山区",
                "level": 3,
                "order": 1570,
                "relation_code": "370212",
                "relation_desc": "山东省青岛市崂山区"
            },
            {
                "code": "150206",
                "parentcode": "1502",
                "parenttext": "青岛市",
                "text": "李沧区",
                "level": 3,
                "order": 1571,
                "relation_code": "370213",
                "relation_desc": "山东省青岛市李沧区"
            },
            {
                "code": "150207",
                "parentcode": "1502",
                "parenttext": "青岛市",
                "text": "城阳区",
                "level": 3,
                "order": 1572,
                "relation_code": "370214",
                "relation_desc": "山东省青岛市城阳区"
            },
            {
                "code": "150208",
                "parentcode": "1502",
                "parenttext": "青岛市",
                "text": "胶州市",
                "level": 3,
                "order": 1573,
                "relation_code": "370281",
                "relation_desc": "山东省胶州市"
            },
            {
                "code": "150209",
                "parentcode": "1502",
                "parenttext": "青岛市",
                "text": "即墨区",
                "level": 3,
                "order": 1574,
                "relation_code": "370215",
                "relation_desc": "山东省青岛市即墨区"
            },
            {
                "code": "150210",
                "parentcode": "1502",
                "parenttext": "青岛市",
                "text": "平度市",
                "level": 3,
                "order": 1575,
                "relation_code": "370283",
                "relation_desc": "山东省平度市"
            },
            {
                "code": "150211",
                "parentcode": "1502",
                "parenttext": "青岛市",
                "text": "胶南市",
                "level": 3,
                "order": 1576,
                "relation_code": "370201",
                "relation_desc": "山东省胶南市"
            },
            {
                "code": "150212",
                "parentcode": "1502",
                "parenttext": "青岛市",
                "text": "莱西市",
                "level": 3,
                "order": 1577,
                "relation_code": "370285",
                "relation_desc": "山东省莱西市"
            },
            {
                "code": "1503",
                "parentcode": "15",
                "parenttext": "山东省",
                "text": "淄博市",
                "level": 2,
                "order": 1578,
                "relation_code": "370300",
                "relation_desc": "山东省淄博市"
            },
            {
                "code": "150213",
                "parentcode": "1502",
                "parenttext": "青岛市",
                "text": "青岛高新技术产业开发区",
                "level": 3,
                "order": 1578,
                "relation_code": "370201",
                "relation_desc": "山东省青岛市"
            },
            {
                "code": "150301",
                "parentcode": "1503",
                "parenttext": "淄博市",
                "text": "淄川区",
                "level": 3,
                "order": 1579,
                "relation_code": "370302",
                "relation_desc": "山东省淄博市淄川区"
            },
            {
                "code": "150302",
                "parentcode": "1503",
                "parenttext": "淄博市",
                "text": "张店区",
                "level": 3,
                "order": 1580,
                "relation_code": "370303",
                "relation_desc": "山东省淄博市张店区"
            },
            {
                "code": "150303",
                "parentcode": "1503",
                "parenttext": "淄博市",
                "text": "博山区",
                "level": 3,
                "order": 1581,
                "relation_code": "370304",
                "relation_desc": "山东省淄博市博山区"
            },
            {
                "code": "150304",
                "parentcode": "1503",
                "parenttext": "淄博市",
                "text": "临淄区",
                "level": 3,
                "order": 1582,
                "relation_code": "370305",
                "relation_desc": "山东省淄博市临淄区"
            },
            {
                "code": "150305",
                "parentcode": "1503",
                "parenttext": "淄博市",
                "text": "周村区",
                "level": 3,
                "order": 1583,
                "relation_code": "370306",
                "relation_desc": "山东省淄博市周村区"
            },
            {
                "code": "150306",
                "parentcode": "1503",
                "parenttext": "淄博市",
                "text": "桓台县",
                "level": 3,
                "order": 1584,
                "relation_code": "370321",
                "relation_desc": "山东省桓台县"
            },
            {
                "code": "150307",
                "parentcode": "1503",
                "parenttext": "淄博市",
                "text": "高青县",
                "level": 3,
                "order": 1585,
                "relation_code": "370322",
                "relation_desc": "山东省高青县"
            },
            {
                "code": "150308",
                "parentcode": "1503",
                "parenttext": "淄博市",
                "text": "沂源县",
                "level": 3,
                "order": 1586,
                "relation_code": "370323",
                "relation_desc": "山东省沂源县"
            },
            {
                "code": "1504",
                "parentcode": "15",
                "parenttext": "山东省",
                "text": "枣庄市",
                "level": 2,
                "order": 1587,
                "relation_code": "370400",
                "relation_desc": "山东省枣庄市"
            },
            {
                "code": "150401",
                "parentcode": "1504",
                "parenttext": "枣庄市",
                "text": "市中区",
                "level": 3,
                "order": 1588,
                "relation_code": "370402",
                "relation_desc": "山东省枣庄市市中区"
            },
            {
                "code": "150402",
                "parentcode": "1504",
                "parenttext": "枣庄市",
                "text": "薛城区",
                "level": 3,
                "order": 1589,
                "relation_code": "370403",
                "relation_desc": "山东省枣庄市薛城区"
            },
            {
                "code": "150403",
                "parentcode": "1504",
                "parenttext": "枣庄市",
                "text": "峄城区",
                "level": 3,
                "order": 1590,
                "relation_code": "370404",
                "relation_desc": "山东省枣庄市峄城区"
            },
            {
                "code": "150404",
                "parentcode": "1504",
                "parenttext": "枣庄市",
                "text": "台儿庄区",
                "level": 3,
                "order": 1591,
                "relation_code": "370405",
                "relation_desc": "山东省枣庄市台儿庄区"
            },
            {
                "code": "150405",
                "parentcode": "1504",
                "parenttext": "枣庄市",
                "text": "山亭区",
                "level": 3,
                "order": 1592,
                "relation_code": "370406",
                "relation_desc": "山东省枣庄市山亭区"
            },
            {
                "code": "150406",
                "parentcode": "1504",
                "parenttext": "枣庄市",
                "text": "滕州市",
                "level": 3,
                "order": 1593,
                "relation_code": "370481",
                "relation_desc": "山东省滕州市"
            },
            {
                "code": "1505",
                "parentcode": "15",
                "parenttext": "山东省",
                "text": "东营市",
                "level": 2,
                "order": 1594,
                "relation_code": "370500",
                "relation_desc": "山东省东营市"
            },
            {
                "code": "150501",
                "parentcode": "1505",
                "parenttext": "东营市",
                "text": "东营区",
                "level": 3,
                "order": 1595,
                "relation_code": "370502",
                "relation_desc": "山东省东营市东营区"
            },
            {
                "code": "150502",
                "parentcode": "1505",
                "parenttext": "东营市",
                "text": "河口区",
                "level": 3,
                "order": 1596,
                "relation_code": "370503",
                "relation_desc": "山东省东营市河口区"
            },
            {
                "code": "150503",
                "parentcode": "1505",
                "parenttext": "东营市",
                "text": "垦利区",
                "level": 3,
                "order": 1597,
                "relation_code": "370505",
                "relation_desc": "山东省东营市垦利区"
            },
            {
                "code": "150504",
                "parentcode": "1505",
                "parenttext": "东营市",
                "text": "利津县",
                "level": 3,
                "order": 1598,
                "relation_code": "370522",
                "relation_desc": "山东省利津县"
            },
            {
                "code": "150505",
                "parentcode": "1505",
                "parenttext": "东营市",
                "text": "广饶县",
                "level": 3,
                "order": 1599,
                "relation_code": "370523",
                "relation_desc": "山东省广饶县"
            },
            {
                "code": "1506",
                "parentcode": "15",
                "parenttext": "山东省",
                "text": "烟台市",
                "level": 2,
                "order": 1600,
                "relation_code": "370600",
                "relation_desc": "山东省烟台市"
            },
            {
                "code": "150506",
                "parentcode": "1505",
                "parenttext": "东营市",
                "text": "东营经济技术开发区",
                "level": 3,
                "order": 1600,
                "relation_code": "370501",
                "relation_desc": "山东省东营市"
            },
            {
                "code": "150601",
                "parentcode": "1506",
                "parenttext": "烟台市",
                "text": "芝罘区",
                "level": 3,
                "order": 1601,
                "relation_code": "370602",
                "relation_desc": "山东省烟台市芝罘区"
            },
            {
                "code": "150507",
                "parentcode": "1505",
                "parenttext": "东营市",
                "text": "东营港经济开发区",
                "level": 3,
                "order": 1601,
                "relation_code": "370501",
                "relation_desc": "山东省东营市"
            },
            {
                "code": "150602",
                "parentcode": "1506",
                "parenttext": "烟台市",
                "text": "福山区",
                "level": 3,
                "order": 1602,
                "relation_code": "370611",
                "relation_desc": "山东省烟台市福山区"
            },
            {
                "code": "150603",
                "parentcode": "1506",
                "parenttext": "烟台市",
                "text": "牟平区",
                "level": 3,
                "order": 1603,
                "relation_code": "370612",
                "relation_desc": "山东省烟台市牟平区"
            },
            {
                "code": "150604",
                "parentcode": "1506",
                "parenttext": "烟台市",
                "text": "莱山区",
                "level": 3,
                "order": 1604,
                "relation_code": "370613",
                "relation_desc": "山东省烟台市莱山区"
            },
            {
                "code": "150605",
                "parentcode": "1506",
                "parenttext": "烟台市",
                "text": "长岛县",
                "level": 3,
                "order": 1605,
                "relation_code": "370634",
                "relation_desc": "山东省长岛县"
            },
            {
                "code": "150606",
                "parentcode": "1506",
                "parenttext": "烟台市",
                "text": "龙口市",
                "level": 3,
                "order": 1606,
                "relation_code": "370681",
                "relation_desc": "山东省龙口市"
            },
            {
                "code": "150607",
                "parentcode": "1506",
                "parenttext": "烟台市",
                "text": "莱阳市",
                "level": 3,
                "order": 1607,
                "relation_code": "370682",
                "relation_desc": "山东省莱阳市"
            },
            {
                "code": "150608",
                "parentcode": "1506",
                "parenttext": "烟台市",
                "text": "莱州市",
                "level": 3,
                "order": 1608,
                "relation_code": "370683",
                "relation_desc": "山东省莱州市"
            },
            {
                "code": "150609",
                "parentcode": "1506",
                "parenttext": "烟台市",
                "text": "蓬莱市",
                "level": 3,
                "order": 1609,
                "relation_code": "370684",
                "relation_desc": "山东省蓬莱市"
            },
            {
                "code": "150610",
                "parentcode": "1506",
                "parenttext": "烟台市",
                "text": "招远市",
                "level": 3,
                "order": 1610,
                "relation_code": "370685",
                "relation_desc": "山东省招远市"
            },
            {
                "code": "150611",
                "parentcode": "1506",
                "parenttext": "烟台市",
                "text": "栖霞市",
                "level": 3,
                "order": 1611,
                "relation_code": "370686",
                "relation_desc": "山东省栖霞市"
            },
            {
                "code": "150612",
                "parentcode": "1506",
                "parenttext": "烟台市",
                "text": "海阳市",
                "level": 3,
                "order": 1612,
                "relation_code": "370687",
                "relation_desc": "山东省海阳市"
            },
            {
                "code": "1507",
                "parentcode": "15",
                "parenttext": "山东省",
                "text": "潍坊市",
                "level": 2,
                "order": 1613,
                "relation_code": "370700",
                "relation_desc": "山东省潍坊市"
            },
            {
                "code": "150613",
                "parentcode": "1506",
                "parenttext": "烟台市",
                "text": "烟台高新技术产业开发区",
                "level": 3,
                "order": 1613,
                "relation_code": "370601",
                "relation_desc": "山东省烟台市"
            },
            {
                "code": "150701",
                "parentcode": "1507",
                "parenttext": "潍坊市",
                "text": "潍城区",
                "level": 3,
                "order": 1614,
                "relation_code": "370702",
                "relation_desc": "山东省潍坊市潍城区"
            },
            {
                "code": "150614",
                "parentcode": "1506",
                "parenttext": "烟台市",
                "text": "烟台经济技术开发区",
                "level": 3,
                "order": 1614,
                "relation_code": "370601",
                "relation_desc": "山东省烟台市"
            },
            {
                "code": "150702",
                "parentcode": "1507",
                "parenttext": "潍坊市",
                "text": "寒亭区",
                "level": 3,
                "order": 1615,
                "relation_code": "370703",
                "relation_desc": "山东省潍坊市寒亭区"
            },
            {
                "code": "150703",
                "parentcode": "1507",
                "parenttext": "潍坊市",
                "text": "坊子区",
                "level": 3,
                "order": 1616,
                "relation_code": "370704",
                "relation_desc": "山东省潍坊市坊子区"
            },
            {
                "code": "150704",
                "parentcode": "1507",
                "parenttext": "潍坊市",
                "text": "奎文区",
                "level": 3,
                "order": 1617,
                "relation_code": "370705",
                "relation_desc": "山东省潍坊市奎文区"
            },
            {
                "code": "150705",
                "parentcode": "1507",
                "parenttext": "潍坊市",
                "text": "临朐县",
                "level": 3,
                "order": 1618,
                "relation_code": "370724",
                "relation_desc": "山东省临朐县"
            },
            {
                "code": "150706",
                "parentcode": "1507",
                "parenttext": "潍坊市",
                "text": "昌乐县",
                "level": 3,
                "order": 1619,
                "relation_code": "370725",
                "relation_desc": "山东省昌乐县"
            },
            {
                "code": "150707",
                "parentcode": "1507",
                "parenttext": "潍坊市",
                "text": "青州市",
                "level": 3,
                "order": 1620,
                "relation_code": "370781",
                "relation_desc": "山东省青州市"
            },
            {
                "code": "150708",
                "parentcode": "1507",
                "parenttext": "潍坊市",
                "text": "诸城市",
                "level": 3,
                "order": 1621,
                "relation_code": "370782",
                "relation_desc": "山东省诸城市"
            },
            {
                "code": "150709",
                "parentcode": "1507",
                "parenttext": "潍坊市",
                "text": "寿光市",
                "level": 3,
                "order": 1622,
                "relation_code": "370783",
                "relation_desc": "山东省寿光市"
            },
            {
                "code": "150710",
                "parentcode": "1507",
                "parenttext": "潍坊市",
                "text": "安丘市",
                "level": 3,
                "order": 1623,
                "relation_code": "370784",
                "relation_desc": "山东省安丘市"
            },
            {
                "code": "150711",
                "parentcode": "1507",
                "parenttext": "潍坊市",
                "text": "高密市",
                "level": 3,
                "order": 1624,
                "relation_code": "370785",
                "relation_desc": "山东省高密市"
            },
            {
                "code": "150712",
                "parentcode": "1507",
                "parenttext": "潍坊市",
                "text": "昌邑市",
                "level": 3,
                "order": 1625,
                "relation_code": "370786",
                "relation_desc": "山东省昌邑市"
            },
            {
                "code": "1508",
                "parentcode": "15",
                "parenttext": "山东省",
                "text": "济宁市",
                "level": 2,
                "order": 1626,
                "relation_code": "370800",
                "relation_desc": "山东省济宁市"
            },
            {
                "code": "150713",
                "parentcode": "1507",
                "parenttext": "潍坊市",
                "text": "潍坊滨海经济技术开发区",
                "level": 3,
                "order": 1626,
                "relation_code": "370701",
                "relation_desc": "山东省潍坊市"
            },
            {
                "code": "150801",
                "parentcode": "1508",
                "parenttext": "济宁市",
                "text": "任城区",
                "level": 3,
                "order": 1627,
                "relation_code": "370811",
                "relation_desc": "山东省济宁市任城区"
            },
            {
                "code": "150802",
                "parentcode": "1508",
                "parenttext": "济宁市",
                "text": "兖州区",
                "level": 3,
                "order": 1628,
                "relation_code": "370812",
                "relation_desc": "山东省济宁市兖州区"
            },
            {
                "code": "150803",
                "parentcode": "1508",
                "parenttext": "济宁市",
                "text": "微山县",
                "level": 3,
                "order": 1629,
                "relation_code": "370826",
                "relation_desc": "山东省微山县"
            },
            {
                "code": "150804",
                "parentcode": "1508",
                "parenttext": "济宁市",
                "text": "鱼台县",
                "level": 3,
                "order": 1630,
                "relation_code": "370827",
                "relation_desc": "山东省鱼台县"
            },
            {
                "code": "150805",
                "parentcode": "1508",
                "parenttext": "济宁市",
                "text": "金乡县",
                "level": 3,
                "order": 1631,
                "relation_code": "370828",
                "relation_desc": "山东省金乡县"
            },
            {
                "code": "150806",
                "parentcode": "1508",
                "parenttext": "济宁市",
                "text": "嘉祥县",
                "level": 3,
                "order": 1632,
                "relation_code": "370829",
                "relation_desc": "山东省嘉祥县"
            },
            {
                "code": "150807",
                "parentcode": "1508",
                "parenttext": "济宁市",
                "text": "汶上县",
                "level": 3,
                "order": 1633,
                "relation_code": "370830",
                "relation_desc": "山东省汶上县"
            },
            {
                "code": "150808",
                "parentcode": "1508",
                "parenttext": "济宁市",
                "text": "泗水县",
                "level": 3,
                "order": 1634,
                "relation_code": "370831",
                "relation_desc": "山东省泗水县"
            },
            {
                "code": "150809",
                "parentcode": "1508",
                "parenttext": "济宁市",
                "text": "梁山县",
                "level": 3,
                "order": 1635,
                "relation_code": "370832",
                "relation_desc": "山东省梁山县"
            },
            {
                "code": "150810",
                "parentcode": "1508",
                "parenttext": "济宁市",
                "text": "曲阜市",
                "level": 3,
                "order": 1636,
                "relation_code": "370881",
                "relation_desc": "山东省曲阜市"
            },
            {
                "code": "150811",
                "parentcode": "1508",
                "parenttext": "济宁市",
                "text": "市中区",
                "level": 3,
                "order": 1637,
                "relation_code": "370811",
                "relation_desc": "山东省济宁市市中区"
            },
            {
                "code": "150812",
                "parentcode": "1508",
                "parenttext": "济宁市",
                "text": "邹城市",
                "level": 3,
                "order": 1638,
                "relation_code": "370883",
                "relation_desc": "山东省邹城市"
            },
            {
                "code": "1509",
                "parentcode": "15",
                "parenttext": "山东省",
                "text": "泰安市",
                "level": 2,
                "order": 1639,
                "relation_code": "370900",
                "relation_desc": "山东省泰安市"
            },
            {
                "code": "150813",
                "parentcode": "1508",
                "parenttext": "济宁市",
                "text": "济宁高新技术产业开发区",
                "level": 3,
                "order": 1639,
                "relation_code": "370811",
                "relation_desc": "山东省济宁市"
            },
            {
                "code": "150901",
                "parentcode": "1509",
                "parenttext": "泰安市",
                "text": "泰山区",
                "level": 3,
                "order": 1640,
                "relation_code": "370902",
                "relation_desc": "山东省泰安市泰山区"
            },
            {
                "code": "150902",
                "parentcode": "1509",
                "parenttext": "泰安市",
                "text": "岱岳区",
                "level": 3,
                "order": 1641,
                "relation_code": "370911",
                "relation_desc": "山东省泰安市岱岳区"
            },
            {
                "code": "150903",
                "parentcode": "1509",
                "parenttext": "泰安市",
                "text": "宁阳县",
                "level": 3,
                "order": 1642,
                "relation_code": "370921",
                "relation_desc": "山东省宁阳县"
            },
            {
                "code": "150904",
                "parentcode": "1509",
                "parenttext": "泰安市",
                "text": "东平县",
                "level": 3,
                "order": 1643,
                "relation_code": "370923",
                "relation_desc": "山东省东平县"
            },
            {
                "code": "150905",
                "parentcode": "1509",
                "parenttext": "泰安市",
                "text": "新泰市",
                "level": 3,
                "order": 1644,
                "relation_code": "370982",
                "relation_desc": "山东省新泰市"
            },
            {
                "code": "150906",
                "parentcode": "1509",
                "parenttext": "泰安市",
                "text": "肥城市",
                "level": 3,
                "order": 1645,
                "relation_code": "370983",
                "relation_desc": "山东省肥城市"
            },
            {
                "code": "1510",
                "parentcode": "15",
                "parenttext": "山东省",
                "text": "威海市",
                "level": 2,
                "order": 1646,
                "relation_code": "371000",
                "relation_desc": "山东省威海市"
            },
            {
                "code": "151001",
                "parentcode": "1510",
                "parenttext": "威海市",
                "text": "环翠区",
                "level": 3,
                "order": 1647,
                "relation_code": "371002",
                "relation_desc": "山东省威海市环翠区"
            },
            {
                "code": "151002",
                "parentcode": "1510",
                "parenttext": "威海市",
                "text": "文登区",
                "level": 3,
                "order": 1648,
                "relation_code": "371003",
                "relation_desc": "山东省威海市文登区"
            },
            {
                "code": "151003",
                "parentcode": "1510",
                "parenttext": "威海市",
                "text": "荣成市",
                "level": 3,
                "order": 1649,
                "relation_code": "371082",
                "relation_desc": "山东省荣成市"
            },
            {
                "code": "151004",
                "parentcode": "1510",
                "parenttext": "威海市",
                "text": "乳山市",
                "level": 3,
                "order": 1650,
                "relation_code": "371083",
                "relation_desc": "山东省乳山市"
            },
            {
                "code": "1511",
                "parentcode": "15",
                "parenttext": "山东省",
                "text": "日照市",
                "level": 2,
                "order": 1651,
                "relation_code": "371100",
                "relation_desc": "山东省日照市"
            },
            {
                "code": "151005",
                "parentcode": "1510",
                "parenttext": "威海市",
                "text": "威海火炬高技术产业开发区",
                "level": 3,
                "order": 1651,
                "relation_code": "371001",
                "relation_desc": "山东省威海市"
            },
            {
                "code": "151101",
                "parentcode": "1511",
                "parenttext": "日照市",
                "text": "东港区",
                "level": 3,
                "order": 1652,
                "relation_code": "371102",
                "relation_desc": "山东省日照市东港区"
            },
            {
                "code": "151006",
                "parentcode": "1510",
                "parenttext": "威海市",
                "text": "威海经济技术开发区",
                "level": 3,
                "order": 1652,
                "relation_code": "371001",
                "relation_desc": "山东省威海市"
            },
            {
                "code": "151102",
                "parentcode": "1511",
                "parenttext": "日照市",
                "text": "五莲县",
                "level": 3,
                "order": 1653,
                "relation_code": "371121",
                "relation_desc": "山东省五莲县"
            },
            {
                "code": "151007",
                "parentcode": "1510",
                "parenttext": "威海市",
                "text": "威海临港经济技术开发区",
                "level": 3,
                "order": 1653,
                "relation_code": "371001",
                "relation_desc": "山东省威海市"
            },
            {
                "code": "151103",
                "parentcode": "1511",
                "parenttext": "日照市",
                "text": "莒县",
                "level": 3,
                "order": 1654,
                "relation_code": "371122",
                "relation_desc": "山东省莒县"
            },
            {
                "code": "1512",
                "parentcode": "15",
                "parenttext": "山东省",
                "text": "莱芜市",
                "level": 2,
                "order": 1655,
                "relation_code": "",
                "relation_desc": "山东省莱芜市"
            },
            {
                "code": "151202",
                "parentcode": "1512",
                "parenttext": "莱芜市",
                "text": "莱城区",
                "level": 3,
                "order": 1657,
                "relation_code": "370116",
                "relation_desc": "山东省莱芜市莱城区"
            },
            {
                "code": "151203",
                "parentcode": "1512",
                "parenttext": "莱芜市",
                "text": "钢城区",
                "level": 3,
                "order": 1658,
                "relation_code": "370117",
                "relation_desc": "山东省莱芜市钢城区"
            },
            {
                "code": "1513",
                "parentcode": "15",
                "parenttext": "山东省",
                "text": "临沂市",
                "level": 2,
                "order": 1659,
                "relation_code": "371300",
                "relation_desc": "山东省临沂市"
            },
            {
                "code": "151301",
                "parentcode": "1513",
                "parenttext": "临沂市",
                "text": "兰山区",
                "level": 3,
                "order": 1660,
                "relation_code": "371302",
                "relation_desc": "山东省临沂市兰山区"
            },
            {
                "code": "151302",
                "parentcode": "1513",
                "parenttext": "临沂市",
                "text": "罗庄区",
                "level": 3,
                "order": 1661,
                "relation_code": "371311",
                "relation_desc": "山东省临沂市罗庄区"
            },
            {
                "code": "151303",
                "parentcode": "1513",
                "parenttext": "临沂市",
                "text": "河东区",
                "level": 3,
                "order": 1662,
                "relation_code": "371312",
                "relation_desc": "山东省临沂市河东区"
            },
            {
                "code": "151304",
                "parentcode": "1513",
                "parenttext": "临沂市",
                "text": "沂南县",
                "level": 3,
                "order": 1663,
                "relation_code": "371321",
                "relation_desc": "山东省沂南县"
            },
            {
                "code": "151305",
                "parentcode": "1513",
                "parenttext": "临沂市",
                "text": "郯城县",
                "level": 3,
                "order": 1664,
                "relation_code": "371322",
                "relation_desc": "山东省郯城县"
            },
            {
                "code": "151306",
                "parentcode": "1513",
                "parenttext": "临沂市",
                "text": "沂水县",
                "level": 3,
                "order": 1665,
                "relation_code": "371323",
                "relation_desc": "山东省沂水县"
            },
            {
                "code": "151307",
                "parentcode": "1513",
                "parenttext": "临沂市",
                "text": "兰陵县",
                "level": 3,
                "order": 1666,
                "relation_code": "371301",
                "relation_desc": "山东省临沂市"
            },
            {
                "code": "151308",
                "parentcode": "1513",
                "parenttext": "临沂市",
                "text": "费县",
                "level": 3,
                "order": 1667,
                "relation_code": "371325",
                "relation_desc": "山东省费县"
            },
            {
                "code": "151309",
                "parentcode": "1513",
                "parenttext": "临沂市",
                "text": "平邑县",
                "level": 3,
                "order": 1668,
                "relation_code": "371326",
                "relation_desc": "山东省平邑县"
            },
            {
                "code": "151310",
                "parentcode": "1513",
                "parenttext": "临沂市",
                "text": "莒南县",
                "level": 3,
                "order": 1669,
                "relation_code": "371327",
                "relation_desc": "山东省莒南县"
            },
            {
                "code": "151311",
                "parentcode": "1513",
                "parenttext": "临沂市",
                "text": "蒙阴县",
                "level": 3,
                "order": 1670,
                "relation_code": "371328",
                "relation_desc": "山东省蒙阴县"
            },
            {
                "code": "151312",
                "parentcode": "1513",
                "parenttext": "临沂市",
                "text": "临沭县",
                "level": 3,
                "order": 1671,
                "relation_code": "371329",
                "relation_desc": "山东省临沭县"
            },
            {
                "code": "1514",
                "parentcode": "15",
                "parenttext": "山东省",
                "text": "德州市",
                "level": 2,
                "order": 1672,
                "relation_code": "371400",
                "relation_desc": "山东省德州市"
            },
            {
                "code": "151313",
                "parentcode": "1513",
                "parenttext": "临沂市",
                "text": "临沂高新技术产业开发区",
                "level": 3,
                "order": 1672,
                "relation_code": "371301",
                "relation_desc": "山东省临沂市"
            },
            {
                "code": "151401",
                "parentcode": "1514",
                "parenttext": "德州市",
                "text": "德城区",
                "level": 3,
                "order": 1673,
                "relation_code": "371402",
                "relation_desc": "山东省德州市德城区"
            },
            {
                "code": "151314",
                "parentcode": "1513",
                "parenttext": "临沂市",
                "text": "临沂经济技术开发区",
                "level": 3,
                "order": 1673,
                "relation_code": "371301",
                "relation_desc": "山东省临沂市"
            },
            {
                "code": "151402",
                "parentcode": "1514",
                "parenttext": "德州市",
                "text": "陵城区",
                "level": 3,
                "order": 1674,
                "relation_code": "371403",
                "relation_desc": "山东省德州市陵城区"
            },
            {
                "code": "151315",
                "parentcode": "1513",
                "parenttext": "临沂市",
                "text": "临沂临港经济开发区",
                "level": 3,
                "order": 1674,
                "relation_code": "371301",
                "relation_desc": "山东省临沂市"
            },
            {
                "code": "151403",
                "parentcode": "1514",
                "parenttext": "德州市",
                "text": "宁津县",
                "level": 3,
                "order": 1675,
                "relation_code": "371422",
                "relation_desc": "山东省宁津县"
            },
            {
                "code": "151316",
                "parentcode": "1513",
                "parenttext": "临沂市",
                "text": "苍山县",
                "level": 3,
                "order": 1675,
                "relation_code": "371301",
                "relation_desc": "山东省苍山县"
            },
            {
                "code": "151404",
                "parentcode": "1514",
                "parenttext": "德州市",
                "text": "庆云县",
                "level": 3,
                "order": 1676,
                "relation_code": "371423",
                "relation_desc": "山东省庆云县"
            },
            {
                "code": "151405",
                "parentcode": "1514",
                "parenttext": "德州市",
                "text": "临邑县",
                "level": 3,
                "order": 1677,
                "relation_code": "371424",
                "relation_desc": "山东省临邑县"
            },
            {
                "code": "151406",
                "parentcode": "1514",
                "parenttext": "德州市",
                "text": "齐河县",
                "level": 3,
                "order": 1678,
                "relation_code": "371425",
                "relation_desc": "山东省齐河县"
            },
            {
                "code": "151407",
                "parentcode": "1514",
                "parenttext": "德州市",
                "text": "平原县",
                "level": 3,
                "order": 1679,
                "relation_code": "371426",
                "relation_desc": "山东省平原县"
            },
            {
                "code": "151408",
                "parentcode": "1514",
                "parenttext": "德州市",
                "text": "夏津县",
                "level": 3,
                "order": 1680,
                "relation_code": "371427",
                "relation_desc": "山东省夏津县"
            },
            {
                "code": "151409",
                "parentcode": "1514",
                "parenttext": "德州市",
                "text": "武城县",
                "level": 3,
                "order": 1681,
                "relation_code": "371428",
                "relation_desc": "山东省武城县"
            },
            {
                "code": "151410",
                "parentcode": "1514",
                "parenttext": "德州市",
                "text": "乐陵市",
                "level": 3,
                "order": 1682,
                "relation_code": "371481",
                "relation_desc": "山东省乐陵市"
            },
            {
                "code": "151411",
                "parentcode": "1514",
                "parenttext": "德州市",
                "text": "禹城市",
                "level": 3,
                "order": 1683,
                "relation_code": "371482",
                "relation_desc": "山东省禹城市"
            },
            {
                "code": "1515",
                "parentcode": "15",
                "parenttext": "山东省",
                "text": "聊城市",
                "level": 2,
                "order": 1684,
                "relation_code": "371500",
                "relation_desc": "山东省聊城市"
            },
            {
                "code": "151412",
                "parentcode": "1514",
                "parenttext": "德州市",
                "text": "德州经济技术开发区",
                "level": 3,
                "order": 1684,
                "relation_code": "371401",
                "relation_desc": "山东省德州市"
            },
            {
                "code": "151501",
                "parentcode": "1515",
                "parenttext": "聊城市",
                "text": "东昌府区",
                "level": 3,
                "order": 1685,
                "relation_code": "371502",
                "relation_desc": "山东省聊城市东昌府区"
            },
            {
                "code": "151413",
                "parentcode": "1514",
                "parenttext": "德州市",
                "text": "德州运河经济开发区",
                "level": 3,
                "order": 1685,
                "relation_code": "371401",
                "relation_desc": "山东省德州市"
            },
            {
                "code": "151502",
                "parentcode": "1515",
                "parenttext": "聊城市",
                "text": "阳谷县",
                "level": 3,
                "order": 1686,
                "relation_code": "371521",
                "relation_desc": "山东省阳谷县"
            },
            {
                "code": "151503",
                "parentcode": "1515",
                "parenttext": "聊城市",
                "text": "莘县",
                "level": 3,
                "order": 1687,
                "relation_code": "371522",
                "relation_desc": "山东省莘县"
            },
            {
                "code": "151504",
                "parentcode": "1515",
                "parenttext": "聊城市",
                "text": "茌平县",
                "level": 3,
                "order": 1688,
                "relation_code": "371501",
                "relation_desc": "山东省茌平县"
            },
            {
                "code": "151505",
                "parentcode": "1515",
                "parenttext": "聊城市",
                "text": "东阿县",
                "level": 3,
                "order": 1689,
                "relation_code": "371524",
                "relation_desc": "山东省东阿县"
            },
            {
                "code": "151506",
                "parentcode": "1515",
                "parenttext": "聊城市",
                "text": "冠县",
                "level": 3,
                "order": 1690,
                "relation_code": "371525",
                "relation_desc": "山东省冠县"
            },
            {
                "code": "151507",
                "parentcode": "1515",
                "parenttext": "聊城市",
                "text": "高唐县",
                "level": 3,
                "order": 1691,
                "relation_code": "371526",
                "relation_desc": "山东省高唐县"
            },
            {
                "code": "151508",
                "parentcode": "1515",
                "parenttext": "聊城市",
                "text": "临清市",
                "level": 3,
                "order": 1692,
                "relation_code": "371581",
                "relation_desc": "山东省临清市"
            },
            {
                "code": "1516",
                "parentcode": "15",
                "parenttext": "山东省",
                "text": "滨州市",
                "level": 2,
                "order": 1693,
                "relation_code": "371600",
                "relation_desc": "山东省滨州市"
            },
            {
                "code": "151601",
                "parentcode": "1516",
                "parenttext": "滨州市",
                "text": "滨城区",
                "level": 3,
                "order": 1694,
                "relation_code": "371602",
                "relation_desc": "山东省滨州市滨城区"
            },
            {
                "code": "151602",
                "parentcode": "1516",
                "parenttext": "滨州市",
                "text": "惠民县",
                "level": 3,
                "order": 1695,
                "relation_code": "371621",
                "relation_desc": "山东省惠民县"
            },
            {
                "code": "151603",
                "parentcode": "1516",
                "parenttext": "滨州市",
                "text": "阳信县",
                "level": 3,
                "order": 1696,
                "relation_code": "371622",
                "relation_desc": "山东省阳信县"
            },
            {
                "code": "151604",
                "parentcode": "1516",
                "parenttext": "滨州市",
                "text": "无棣县",
                "level": 3,
                "order": 1697,
                "relation_code": "371623",
                "relation_desc": "山东省无棣县"
            },
            {
                "code": "151605",
                "parentcode": "1516",
                "parenttext": "滨州市",
                "text": "沾化区",
                "level": 3,
                "order": 1698,
                "relation_code": "371603",
                "relation_desc": "山东省滨州市沾化区"
            },
            {
                "code": "151606",
                "parentcode": "1516",
                "parenttext": "滨州市",
                "text": "博兴县",
                "level": 3,
                "order": 1699,
                "relation_code": "371625",
                "relation_desc": "山东省博兴县"
            },
            {
                "code": "151607",
                "parentcode": "1516",
                "parenttext": "滨州市",
                "text": "邹平市",
                "level": 3,
                "order": 1700,
                "relation_code": "371681",
                "relation_desc": "山东省邹平市"
            },
            {
                "code": "1517",
                "parentcode": "15",
                "parenttext": "山东省",
                "text": "菏泽市",
                "level": 2,
                "order": 1701,
                "relation_code": "371700",
                "relation_desc": "山东省菏泽市"
            },
            {
                "code": "151701",
                "parentcode": "1517",
                "parenttext": "菏泽市",
                "text": "牡丹区",
                "level": 3,
                "order": 1702,
                "relation_code": "371702",
                "relation_desc": "山东省菏泽市牡丹区"
            },
            {
                "code": "151702",
                "parentcode": "1517",
                "parenttext": "菏泽市",
                "text": "曹县",
                "level": 3,
                "order": 1703,
                "relation_code": "371721",
                "relation_desc": "山东省曹县"
            },
            {
                "code": "151703",
                "parentcode": "1517",
                "parenttext": "菏泽市",
                "text": "单县",
                "level": 3,
                "order": 1704,
                "relation_code": "371722",
                "relation_desc": "山东省单县"
            },
            {
                "code": "151704",
                "parentcode": "1517",
                "parenttext": "菏泽市",
                "text": "成武县",
                "level": 3,
                "order": 1705,
                "relation_code": "371723",
                "relation_desc": "山东省成武县"
            },
            {
                "code": "151705",
                "parentcode": "1517",
                "parenttext": "菏泽市",
                "text": "巨野县",
                "level": 3,
                "order": 1706,
                "relation_code": "371724",
                "relation_desc": "山东省巨野县"
            },
            {
                "code": "151706",
                "parentcode": "1517",
                "parenttext": "菏泽市",
                "text": "郓城县",
                "level": 3,
                "order": 1707,
                "relation_code": "371725",
                "relation_desc": "山东省郓城县"
            },
            {
                "code": "151707",
                "parentcode": "1517",
                "parenttext": "菏泽市",
                "text": "鄄城县",
                "level": 3,
                "order": 1708,
                "relation_code": "371726",
                "relation_desc": "山东省鄄城县"
            },
            {
                "code": "151708",
                "parentcode": "1517",
                "parenttext": "菏泽市",
                "text": "定陶区",
                "level": 3,
                "order": 1709,
                "relation_code": "371703",
                "relation_desc": "山东省菏泽市定陶区"
            },
            {
                "code": "151709",
                "parentcode": "1517",
                "parenttext": "菏泽市",
                "text": "东明县",
                "level": 3,
                "order": 1710,
                "relation_code": "371728",
                "relation_desc": "山东省东明县"
            },
            {
                "code": "17",
                "parentcode": "0",
                "parenttext": "0",
                "text": "湖北省",
                "level": 1,
                "order": 1711,
                "relation_code": "",
                "relation_desc": "湖北省"
            },
            {
                "code": "151710",
                "parentcode": "1517",
                "parenttext": "菏泽市",
                "text": "菏泽经济技术开发区",
                "level": 3,
                "order": 1711,
                "relation_code": "371701",
                "relation_desc": "山东省菏泽市"
            },
            {
                "code": "1701",
                "parentcode": "17",
                "parenttext": "湖北省",
                "text": "武汉市",
                "level": 2,
                "order": 1712,
                "relation_code": "420100",
                "relation_desc": "湖北省武汉市"
            },
            {
                "code": "151711",
                "parentcode": "1517",
                "parenttext": "菏泽市",
                "text": "菏泽高新技术开发区",
                "level": 3,
                "order": 1712,
                "relation_code": "371701",
                "relation_desc": "山东省菏泽市"
            },
            {
                "code": "170101",
                "parentcode": "1701",
                "parenttext": "武汉市",
                "text": "江岸区",
                "level": 3,
                "order": 1713,
                "relation_code": "420102",
                "relation_desc": "湖北省武汉市江岸区"
            },
            {
                "code": "170102",
                "parentcode": "1701",
                "parenttext": "武汉市",
                "text": "江汉区",
                "level": 3,
                "order": 1714,
                "relation_code": "420103",
                "relation_desc": "湖北省武汉市江汉区"
            },
            {
                "code": "170103",
                "parentcode": "1701",
                "parenttext": "武汉市",
                "text": "硚口区",
                "level": 3,
                "order": 1715,
                "relation_code": "420104",
                "relation_desc": "湖北省武汉市硚口区"
            },
            {
                "code": "170104",
                "parentcode": "1701",
                "parenttext": "武汉市",
                "text": "汉阳区",
                "level": 3,
                "order": 1716,
                "relation_code": "420105",
                "relation_desc": "湖北省武汉市汉阳区"
            },
            {
                "code": "170105",
                "parentcode": "1701",
                "parenttext": "武汉市",
                "text": "武昌区",
                "level": 3,
                "order": 1717,
                "relation_code": "420106",
                "relation_desc": "湖北省武汉市武昌区"
            },
            {
                "code": "170106",
                "parentcode": "1701",
                "parenttext": "武汉市",
                "text": "青山区",
                "level": 3,
                "order": 1718,
                "relation_code": "420107",
                "relation_desc": "湖北省武汉市青山区"
            },
            {
                "code": "170107",
                "parentcode": "1701",
                "parenttext": "武汉市",
                "text": "洪山区",
                "level": 3,
                "order": 1719,
                "relation_code": "420111",
                "relation_desc": "湖北省武汉市洪山区"
            },
            {
                "code": "170108",
                "parentcode": "1701",
                "parenttext": "武汉市",
                "text": "东西湖区",
                "level": 3,
                "order": 1720,
                "relation_code": "420112",
                "relation_desc": "湖北省武汉市东西湖区"
            },
            {
                "code": "170109",
                "parentcode": "1701",
                "parenttext": "武汉市",
                "text": "汉南区",
                "level": 3,
                "order": 1721,
                "relation_code": "420113",
                "relation_desc": "湖北省武汉市汉南区"
            },
            {
                "code": "170110",
                "parentcode": "1701",
                "parenttext": "武汉市",
                "text": "蔡甸区",
                "level": 3,
                "order": 1722,
                "relation_code": "420114",
                "relation_desc": "湖北省武汉市蔡甸区"
            },
            {
                "code": "170111",
                "parentcode": "1701",
                "parenttext": "武汉市",
                "text": "江夏区",
                "level": 3,
                "order": 1723,
                "relation_code": "420115",
                "relation_desc": "湖北省武汉市江夏区"
            },
            {
                "code": "170112",
                "parentcode": "1701",
                "parenttext": "武汉市",
                "text": "黄陂区",
                "level": 3,
                "order": 1724,
                "relation_code": "420116",
                "relation_desc": "湖北省武汉市黄陂区"
            },
            {
                "code": "170113",
                "parentcode": "1701",
                "parenttext": "武汉市",
                "text": "新洲区",
                "level": 3,
                "order": 1725,
                "relation_code": "420117",
                "relation_desc": "湖北省武汉市新洲区"
            },
            {
                "code": "1702",
                "parentcode": "17",
                "parenttext": "湖北省",
                "text": "黄石市",
                "level": 2,
                "order": 1726,
                "relation_code": "420200",
                "relation_desc": "湖北省黄石市"
            },
            {
                "code": "170201",
                "parentcode": "1702",
                "parenttext": "黄石市",
                "text": "黄石港区",
                "level": 3,
                "order": 1727,
                "relation_code": "420202",
                "relation_desc": "湖北省黄石市黄石港区"
            },
            {
                "code": "170202",
                "parentcode": "1702",
                "parenttext": "黄石市",
                "text": "西塞山区",
                "level": 3,
                "order": 1728,
                "relation_code": "420203",
                "relation_desc": "湖北省黄石市西塞山区"
            },
            {
                "code": "170203",
                "parentcode": "1702",
                "parenttext": "黄石市",
                "text": "下陆区",
                "level": 3,
                "order": 1729,
                "relation_code": "420204",
                "relation_desc": "湖北省黄石市下陆区"
            },
            {
                "code": "170204",
                "parentcode": "1702",
                "parenttext": "黄石市",
                "text": "铁山区",
                "level": 3,
                "order": 1730,
                "relation_code": "420205",
                "relation_desc": "湖北省黄石市铁山区"
            },
            {
                "code": "170205",
                "parentcode": "1702",
                "parenttext": "黄石市",
                "text": "阳新县",
                "level": 3,
                "order": 1731,
                "relation_code": "420222",
                "relation_desc": "湖北省阳新县"
            },
            {
                "code": "170206",
                "parentcode": "1702",
                "parenttext": "黄石市",
                "text": "大冶市",
                "level": 3,
                "order": 1732,
                "relation_code": "420281",
                "relation_desc": "湖北省大冶市"
            },
            {
                "code": "1703",
                "parentcode": "17",
                "parenttext": "湖北省",
                "text": "十堰市",
                "level": 2,
                "order": 1733,
                "relation_code": "420300",
                "relation_desc": "湖北省十堰市"
            },
            {
                "code": "170301",
                "parentcode": "1703",
                "parenttext": "十堰市",
                "text": "茅箭区",
                "level": 3,
                "order": 1734,
                "relation_code": "420302",
                "relation_desc": "湖北省十堰市茅箭区"
            },
            {
                "code": "170302",
                "parentcode": "1703",
                "parenttext": "十堰市",
                "text": "张湾区",
                "level": 3,
                "order": 1735,
                "relation_code": "420303",
                "relation_desc": "湖北省十堰市张湾区"
            },
            {
                "code": "170303",
                "parentcode": "1703",
                "parenttext": "十堰市",
                "text": "郧阳区",
                "level": 3,
                "order": 1736,
                "relation_code": "420304",
                "relation_desc": "湖北省十堰市郧阳区"
            },
            {
                "code": "170304",
                "parentcode": "1703",
                "parenttext": "十堰市",
                "text": "郧西县",
                "level": 3,
                "order": 1737,
                "relation_code": "420322",
                "relation_desc": "湖北省郧西县"
            },
            {
                "code": "170305",
                "parentcode": "1703",
                "parenttext": "十堰市",
                "text": "竹山县",
                "level": 3,
                "order": 1738,
                "relation_code": "420323",
                "relation_desc": "湖北省竹山县"
            },
            {
                "code": "170306",
                "parentcode": "1703",
                "parenttext": "十堰市",
                "text": "竹溪县",
                "level": 3,
                "order": 1739,
                "relation_code": "420324",
                "relation_desc": "湖北省竹溪县"
            },
            {
                "code": "170307",
                "parentcode": "1703",
                "parenttext": "十堰市",
                "text": "房县",
                "level": 3,
                "order": 1740,
                "relation_code": "420325",
                "relation_desc": "湖北省房县"
            },
            {
                "code": "170308",
                "parentcode": "1703",
                "parenttext": "十堰市",
                "text": "丹江口市",
                "level": 3,
                "order": 1741,
                "relation_code": "420381",
                "relation_desc": "湖北省丹江口市"
            },
            {
                "code": "1704",
                "parentcode": "17",
                "parenttext": "湖北省",
                "text": "宜昌市",
                "level": 2,
                "order": 1742,
                "relation_code": "420500",
                "relation_desc": "湖北省宜昌市"
            },
            {
                "code": "170401",
                "parentcode": "1704",
                "parenttext": "宜昌市",
                "text": "西陵区",
                "level": 3,
                "order": 1743,
                "relation_code": "420502",
                "relation_desc": "湖北省宜昌市西陵区"
            },
            {
                "code": "170402",
                "parentcode": "1704",
                "parenttext": "宜昌市",
                "text": "伍家岗区",
                "level": 3,
                "order": 1744,
                "relation_code": "420503",
                "relation_desc": "湖北省宜昌市伍家岗区"
            },
            {
                "code": "170403",
                "parentcode": "1704",
                "parenttext": "宜昌市",
                "text": "点军区",
                "level": 3,
                "order": 1745,
                "relation_code": "420504",
                "relation_desc": "湖北省宜昌市点军区"
            },
            {
                "code": "170404",
                "parentcode": "1704",
                "parenttext": "宜昌市",
                "text": "猇亭区",
                "level": 3,
                "order": 1746,
                "relation_code": "420505",
                "relation_desc": "湖北省宜昌市猇亭区"
            },
            {
                "code": "170405",
                "parentcode": "1704",
                "parenttext": "宜昌市",
                "text": "夷陵区",
                "level": 3,
                "order": 1747,
                "relation_code": "420506",
                "relation_desc": "湖北省宜昌市夷陵区"
            },
            {
                "code": "170406",
                "parentcode": "1704",
                "parenttext": "宜昌市",
                "text": "远安县",
                "level": 3,
                "order": 1748,
                "relation_code": "420525",
                "relation_desc": "湖北省远安县"
            },
            {
                "code": "170407",
                "parentcode": "1704",
                "parenttext": "宜昌市",
                "text": "兴山县",
                "level": 3,
                "order": 1749,
                "relation_code": "420526",
                "relation_desc": "湖北省兴山县"
            },
            {
                "code": "170408",
                "parentcode": "1704",
                "parenttext": "宜昌市",
                "text": "秭归县",
                "level": 3,
                "order": 1750,
                "relation_code": "420527",
                "relation_desc": "湖北省秭归县"
            },
            {
                "code": "170409",
                "parentcode": "1704",
                "parenttext": "宜昌市",
                "text": "长阳土家族自治县",
                "level": 3,
                "order": 1751,
                "relation_code": "420528",
                "relation_desc": "湖北省长阳土家族自治县"
            },
            {
                "code": "170410",
                "parentcode": "1704",
                "parenttext": "宜昌市",
                "text": "五峰土家族自治县",
                "level": 3,
                "order": 1752,
                "relation_code": "420529",
                "relation_desc": "湖北省五峰土家族自治县"
            },
            {
                "code": "170411",
                "parentcode": "1704",
                "parenttext": "宜昌市",
                "text": "宜都市",
                "level": 3,
                "order": 1753,
                "relation_code": "420581",
                "relation_desc": "湖北省宜都市"
            },
            {
                "code": "170412",
                "parentcode": "1704",
                "parenttext": "宜昌市",
                "text": "当阳市",
                "level": 3,
                "order": 1754,
                "relation_code": "420582",
                "relation_desc": "湖北省当阳市"
            },
            {
                "code": "170413",
                "parentcode": "1704",
                "parenttext": "宜昌市",
                "text": "枝江市",
                "level": 3,
                "order": 1755,
                "relation_code": "420583",
                "relation_desc": "湖北省枝江市"
            },
            {
                "code": "1705",
                "parentcode": "17",
                "parenttext": "湖北省",
                "text": "襄阳市",
                "level": 2,
                "order": 1756,
                "relation_code": "420600",
                "relation_desc": "湖北省襄阳市"
            },
            {
                "code": "170501",
                "parentcode": "1705",
                "parenttext": "襄阳市",
                "text": "襄城区",
                "level": 3,
                "order": 1757,
                "relation_code": "420602",
                "relation_desc": "湖北省襄阳市襄城区"
            },
            {
                "code": "170502",
                "parentcode": "1705",
                "parenttext": "襄阳市",
                "text": "樊城区",
                "level": 3,
                "order": 1758,
                "relation_code": "420606",
                "relation_desc": "湖北省襄阳市樊城区"
            },
            {
                "code": "170503",
                "parentcode": "1705",
                "parenttext": "襄阳市",
                "text": "襄州区",
                "level": 3,
                "order": 1759,
                "relation_code": "420607",
                "relation_desc": "湖北省襄阳市襄州区"
            },
            {
                "code": "170504",
                "parentcode": "1705",
                "parenttext": "襄阳市",
                "text": "南漳县",
                "level": 3,
                "order": 1760,
                "relation_code": "420624",
                "relation_desc": "湖北省南漳县"
            },
            {
                "code": "170505",
                "parentcode": "1705",
                "parenttext": "襄阳市",
                "text": "谷城县",
                "level": 3,
                "order": 1761,
                "relation_code": "420625",
                "relation_desc": "湖北省谷城县"
            },
            {
                "code": "170506",
                "parentcode": "1705",
                "parenttext": "襄阳市",
                "text": "保康县",
                "level": 3,
                "order": 1762,
                "relation_code": "420626",
                "relation_desc": "湖北省保康县"
            },
            {
                "code": "170507",
                "parentcode": "1705",
                "parenttext": "襄阳市",
                "text": "老河口市",
                "level": 3,
                "order": 1763,
                "relation_code": "420682",
                "relation_desc": "湖北省老河口市"
            },
            {
                "code": "170508",
                "parentcode": "1705",
                "parenttext": "襄阳市",
                "text": "枣阳市",
                "level": 3,
                "order": 1764,
                "relation_code": "420683",
                "relation_desc": "湖北省枣阳市"
            },
            {
                "code": "170509",
                "parentcode": "1705",
                "parenttext": "襄阳市",
                "text": "宜城市",
                "level": 3,
                "order": 1765,
                "relation_code": "420684",
                "relation_desc": "湖北省宜城市"
            },
            {
                "code": "1706",
                "parentcode": "17",
                "parenttext": "湖北省",
                "text": "鄂州市",
                "level": 2,
                "order": 1766,
                "relation_code": "420700",
                "relation_desc": "湖北省鄂州市"
            },
            {
                "code": "170601",
                "parentcode": "1706",
                "parenttext": "鄂州市",
                "text": "梁子湖区",
                "level": 3,
                "order": 1767,
                "relation_code": "420702",
                "relation_desc": "湖北省鄂州市梁子湖区"
            },
            {
                "code": "170602",
                "parentcode": "1706",
                "parenttext": "鄂州市",
                "text": "华容区",
                "level": 3,
                "order": 1768,
                "relation_code": "420703",
                "relation_desc": "湖北省鄂州市华容区"
            },
            {
                "code": "170603",
                "parentcode": "1706",
                "parenttext": "鄂州市",
                "text": "鄂城区",
                "level": 3,
                "order": 1769,
                "relation_code": "420704",
                "relation_desc": "湖北省鄂州市鄂城区"
            },
            {
                "code": "1707",
                "parentcode": "17",
                "parenttext": "湖北省",
                "text": "荆门市",
                "level": 2,
                "order": 1770,
                "relation_code": "420800",
                "relation_desc": "湖北省荆门市"
            },
            {
                "code": "170701",
                "parentcode": "1707",
                "parenttext": "荆门市",
                "text": "东宝区",
                "level": 3,
                "order": 1771,
                "relation_code": "420802",
                "relation_desc": "湖北省荆门市东宝区"
            },
            {
                "code": "170702",
                "parentcode": "1707",
                "parenttext": "荆门市",
                "text": "掇刀区",
                "level": 3,
                "order": 1772,
                "relation_code": "420804",
                "relation_desc": "湖北省荆门市掇刀区"
            },
            {
                "code": "170703",
                "parentcode": "1707",
                "parenttext": "荆门市",
                "text": "京山县",
                "level": 3,
                "order": 1773,
                "relation_code": "420801",
                "relation_desc": "湖北省京山县"
            },
            {
                "code": "170704",
                "parentcode": "1707",
                "parenttext": "荆门市",
                "text": "沙洋县",
                "level": 3,
                "order": 1774,
                "relation_code": "420822",
                "relation_desc": "湖北省沙洋县"
            },
            {
                "code": "170705",
                "parentcode": "1707",
                "parenttext": "荆门市",
                "text": "钟祥市",
                "level": 3,
                "order": 1775,
                "relation_code": "420881",
                "relation_desc": "湖北省钟祥市"
            },
            {
                "code": "1708",
                "parentcode": "17",
                "parenttext": "湖北省",
                "text": "孝感市",
                "level": 2,
                "order": 1776,
                "relation_code": "420900",
                "relation_desc": "湖北省孝感市"
            },
            {
                "code": "170801",
                "parentcode": "1708",
                "parenttext": "孝感市",
                "text": "孝南区",
                "level": 3,
                "order": 1777,
                "relation_code": "420902",
                "relation_desc": "湖北省孝感市孝南区"
            },
            {
                "code": "170802",
                "parentcode": "1708",
                "parenttext": "孝感市",
                "text": "孝昌县",
                "level": 3,
                "order": 1778,
                "relation_code": "420921",
                "relation_desc": "湖北省孝昌县"
            },
            {
                "code": "170803",
                "parentcode": "1708",
                "parenttext": "孝感市",
                "text": "大悟县",
                "level": 3,
                "order": 1779,
                "relation_code": "420922",
                "relation_desc": "湖北省大悟县"
            },
            {
                "code": "170804",
                "parentcode": "1708",
                "parenttext": "孝感市",
                "text": "云梦县",
                "level": 3,
                "order": 1780,
                "relation_code": "420923",
                "relation_desc": "湖北省云梦县"
            },
            {
                "code": "170805",
                "parentcode": "1708",
                "parenttext": "孝感市",
                "text": "应城市",
                "level": 3,
                "order": 1781,
                "relation_code": "420981",
                "relation_desc": "湖北省应城市"
            },
            {
                "code": "170806",
                "parentcode": "1708",
                "parenttext": "孝感市",
                "text": "安陆市",
                "level": 3,
                "order": 1782,
                "relation_code": "420982",
                "relation_desc": "湖北省安陆市"
            },
            {
                "code": "170807",
                "parentcode": "1708",
                "parenttext": "孝感市",
                "text": "汉川市",
                "level": 3,
                "order": 1783,
                "relation_code": "420984",
                "relation_desc": "湖北省汉川市"
            },
            {
                "code": "1709",
                "parentcode": "17",
                "parenttext": "湖北省",
                "text": "荆州市",
                "level": 2,
                "order": 1784,
                "relation_code": "421000",
                "relation_desc": "湖北省荆州市"
            },
            {
                "code": "170901",
                "parentcode": "1709",
                "parenttext": "荆州市",
                "text": "沙市区",
                "level": 3,
                "order": 1785,
                "relation_code": "421002",
                "relation_desc": "湖北省荆州市沙市区"
            },
            {
                "code": "170902",
                "parentcode": "1709",
                "parenttext": "荆州市",
                "text": "荆州区",
                "level": 3,
                "order": 1786,
                "relation_code": "421003",
                "relation_desc": "湖北省荆州市荆州区"
            },
            {
                "code": "170903",
                "parentcode": "1709",
                "parenttext": "荆州市",
                "text": "公安县",
                "level": 3,
                "order": 1787,
                "relation_code": "421022",
                "relation_desc": "湖北省公安县"
            },
            {
                "code": "170904",
                "parentcode": "1709",
                "parenttext": "荆州市",
                "text": "监利县",
                "level": 3,
                "order": 1788,
                "relation_code": "421023",
                "relation_desc": "湖北省监利县"
            },
            {
                "code": "170905",
                "parentcode": "1709",
                "parenttext": "荆州市",
                "text": "江陵县",
                "level": 3,
                "order": 1789,
                "relation_code": "421024",
                "relation_desc": "湖北省江陵县"
            },
            {
                "code": "170906",
                "parentcode": "1709",
                "parenttext": "荆州市",
                "text": "石首市",
                "level": 3,
                "order": 1790,
                "relation_code": "421081",
                "relation_desc": "湖北省石首市"
            },
            {
                "code": "170907",
                "parentcode": "1709",
                "parenttext": "荆州市",
                "text": "洪湖市",
                "level": 3,
                "order": 1791,
                "relation_code": "421083",
                "relation_desc": "湖北省洪湖市"
            },
            {
                "code": "170908",
                "parentcode": "1709",
                "parenttext": "荆州市",
                "text": "松滋市",
                "level": 3,
                "order": 1792,
                "relation_code": "421087",
                "relation_desc": "湖北省松滋市"
            },
            {
                "code": "1710",
                "parentcode": "17",
                "parenttext": "湖北省",
                "text": "黄冈市",
                "level": 2,
                "order": 1793,
                "relation_code": "421100",
                "relation_desc": "湖北省黄冈市"
            },
            {
                "code": "170909",
                "parentcode": "1709",
                "parenttext": "荆州市",
                "text": "荆州经济技术开发区",
                "level": 3,
                "order": 1793,
                "relation_code": "421001",
                "relation_desc": "湖北省荆州市"
            },
            {
                "code": "171001",
                "parentcode": "1710",
                "parenttext": "黄冈市",
                "text": "黄州区",
                "level": 3,
                "order": 1794,
                "relation_code": "421102",
                "relation_desc": "湖北省黄冈市黄州区"
            },
            {
                "code": "171002",
                "parentcode": "1710",
                "parenttext": "黄冈市",
                "text": "团风县",
                "level": 3,
                "order": 1795,
                "relation_code": "421121",
                "relation_desc": "湖北省团风县"
            },
            {
                "code": "171003",
                "parentcode": "1710",
                "parenttext": "黄冈市",
                "text": "红安县",
                "level": 3,
                "order": 1796,
                "relation_code": "421122",
                "relation_desc": "湖北省红安县"
            },
            {
                "code": "171004",
                "parentcode": "1710",
                "parenttext": "黄冈市",
                "text": "罗田县",
                "level": 3,
                "order": 1797,
                "relation_code": "421123",
                "relation_desc": "湖北省罗田县"
            },
            {
                "code": "171005",
                "parentcode": "1710",
                "parenttext": "黄冈市",
                "text": "英山县",
                "level": 3,
                "order": 1798,
                "relation_code": "421124",
                "relation_desc": "湖北省英山县"
            },
            {
                "code": "171006",
                "parentcode": "1710",
                "parenttext": "黄冈市",
                "text": "浠水县",
                "level": 3,
                "order": 1799,
                "relation_code": "421125",
                "relation_desc": "湖北省浠水县"
            },
            {
                "code": "171007",
                "parentcode": "1710",
                "parenttext": "黄冈市",
                "text": "蕲春县",
                "level": 3,
                "order": 1800,
                "relation_code": "421126",
                "relation_desc": "湖北省蕲春县"
            },
            {
                "code": "171008",
                "parentcode": "1710",
                "parenttext": "黄冈市",
                "text": "黄梅县",
                "level": 3,
                "order": 1801,
                "relation_code": "421127",
                "relation_desc": "湖北省黄梅县"
            },
            {
                "code": "171009",
                "parentcode": "1710",
                "parenttext": "黄冈市",
                "text": "麻城市",
                "level": 3,
                "order": 1802,
                "relation_code": "421181",
                "relation_desc": "湖北省麻城市"
            },
            {
                "code": "171010",
                "parentcode": "1710",
                "parenttext": "黄冈市",
                "text": "武穴市",
                "level": 3,
                "order": 1803,
                "relation_code": "421182",
                "relation_desc": "湖北省武穴市"
            },
            {
                "code": "1711",
                "parentcode": "17",
                "parenttext": "湖北省",
                "text": "咸宁市",
                "level": 2,
                "order": 1804,
                "relation_code": "421200",
                "relation_desc": "湖北省咸宁市"
            },
            {
                "code": "171011",
                "parentcode": "1710",
                "parenttext": "黄冈市",
                "text": "龙感湖管理区",
                "level": 3,
                "order": 1804,
                "relation_code": "421101",
                "relation_desc": "湖北省黄冈市"
            },
            {
                "code": "171101",
                "parentcode": "1711",
                "parenttext": "咸宁市",
                "text": "咸安区",
                "level": 3,
                "order": 1805,
                "relation_code": "421202",
                "relation_desc": "湖北省咸宁市咸安区"
            },
            {
                "code": "171102",
                "parentcode": "1711",
                "parenttext": "咸宁市",
                "text": "嘉鱼县",
                "level": 3,
                "order": 1806,
                "relation_code": "421221",
                "relation_desc": "湖北省嘉鱼县"
            },
            {
                "code": "171103",
                "parentcode": "1711",
                "parenttext": "咸宁市",
                "text": "通城县",
                "level": 3,
                "order": 1807,
                "relation_code": "421222",
                "relation_desc": "湖北省通城县"
            },
            {
                "code": "171104",
                "parentcode": "1711",
                "parenttext": "咸宁市",
                "text": "崇阳县",
                "level": 3,
                "order": 1808,
                "relation_code": "421223",
                "relation_desc": "湖北省崇阳县"
            },
            {
                "code": "171105",
                "parentcode": "1711",
                "parenttext": "咸宁市",
                "text": "通山县",
                "level": 3,
                "order": 1809,
                "relation_code": "421224",
                "relation_desc": "湖北省通山县"
            },
            {
                "code": "171106",
                "parentcode": "1711",
                "parenttext": "咸宁市",
                "text": "赤壁市",
                "level": 3,
                "order": 1810,
                "relation_code": "421281",
                "relation_desc": "湖北省赤壁市"
            },
            {
                "code": "1712",
                "parentcode": "17",
                "parenttext": "湖北省",
                "text": "随州市",
                "level": 2,
                "order": 1811,
                "relation_code": "421300",
                "relation_desc": "湖北省随州市"
            },
            {
                "code": "171201",
                "parentcode": "1712",
                "parenttext": "随州市",
                "text": "曾都区",
                "level": 3,
                "order": 1812,
                "relation_code": "421303",
                "relation_desc": "湖北省随州市曾都区"
            },
            {
                "code": "171202",
                "parentcode": "1712",
                "parenttext": "随州市",
                "text": "广水市",
                "level": 3,
                "order": 1813,
                "relation_code": "421381",
                "relation_desc": "湖北省广水市"
            },
            {
                "code": "1713",
                "parentcode": "17",
                "parenttext": "湖北省",
                "text": "恩施土家族苗族自治州",
                "level": 2,
                "order": 1814,
                "relation_code": "422800",
                "relation_desc": "湖北省恩施土家族苗族自治州"
            },
            {
                "code": "171301",
                "parentcode": "1713",
                "parenttext": "恩施土家族苗族自治州",
                "text": "恩施市",
                "level": 3,
                "order": 1815,
                "relation_code": "422801",
                "relation_desc": "湖北省恩施市"
            },
            {
                "code": "171302",
                "parentcode": "1713",
                "parenttext": "恩施土家族苗族自治州",
                "text": "利川市",
                "level": 3,
                "order": 1816,
                "relation_code": "422802",
                "relation_desc": "湖北省利川市"
            },
            {
                "code": "171303",
                "parentcode": "1713",
                "parenttext": "恩施土家族苗族自治州",
                "text": "建始县",
                "level": 3,
                "order": 1817,
                "relation_code": "422822",
                "relation_desc": "湖北省建始县"
            },
            {
                "code": "171304",
                "parentcode": "1713",
                "parenttext": "恩施土家族苗族自治州",
                "text": "巴东县",
                "level": 3,
                "order": 1818,
                "relation_code": "422823",
                "relation_desc": "湖北省巴东县"
            },
            {
                "code": "171305",
                "parentcode": "1713",
                "parenttext": "恩施土家族苗族自治州",
                "text": "宣恩县",
                "level": 3,
                "order": 1819,
                "relation_code": "422825",
                "relation_desc": "湖北省宣恩县"
            },
            {
                "code": "171306",
                "parentcode": "1713",
                "parenttext": "恩施土家族苗族自治州",
                "text": "咸丰县",
                "level": 3,
                "order": 1820,
                "relation_code": "422826",
                "relation_desc": "湖北省咸丰县"
            },
            {
                "code": "171307",
                "parentcode": "1713",
                "parenttext": "恩施土家族苗族自治州",
                "text": "来凤县",
                "level": 3,
                "order": 1821,
                "relation_code": "422827",
                "relation_desc": "湖北省来凤县"
            },
            {
                "code": "171308",
                "parentcode": "1713",
                "parenttext": "恩施土家族苗族自治州",
                "text": "鹤峰县",
                "level": 3,
                "order": 1822,
                "relation_code": "422828",
                "relation_desc": "湖北省鹤峰县"
            },
            {
                "code": "1714",
                "parentcode": "17",
                "parenttext": "湖北省",
                "text": "仙桃市",
                "level": 2,
                "order": 1823,
                "relation_code": "429004",
                "relation_desc": "湖北省仙桃市"
            },
            {
                "code": "171401",
                "parentcode": "1714",
                "parenttext": "仙桃市",
                "text": "仙桃市",
                "level": 3,
                "order": 1824,
                "relation_code": "429004",
                "relation_desc": "湖北省仙桃市"
            },
            {
                "code": "1715",
                "parentcode": "17",
                "parenttext": "湖北省",
                "text": "潜江市",
                "level": 2,
                "order": 1825,
                "relation_code": "429005",
                "relation_desc": "湖北省潜江市"
            },
            {
                "code": "171501",
                "parentcode": "1715",
                "parenttext": "潜江市",
                "text": "潜江市",
                "level": 3,
                "order": 1826,
                "relation_code": "429005",
                "relation_desc": "湖北省潜江市"
            },
            {
                "code": "1716",
                "parentcode": "17",
                "parenttext": "湖北省",
                "text": "天门市",
                "level": 2,
                "order": 1827,
                "relation_code": "429006",
                "relation_desc": "湖北省天门市"
            },
            {
                "code": "171601",
                "parentcode": "1716",
                "parenttext": "天门市",
                "text": "天门市",
                "level": 3,
                "order": 1828,
                "relation_code": "429006",
                "relation_desc": "湖北省天门市"
            },
            {
                "code": "1717",
                "parentcode": "17",
                "parenttext": "湖北省",
                "text": "神农架林区",
                "level": 2,
                "order": 1829,
                "relation_code": "429021",
                "relation_desc": "湖北省神农架林区"
            },
            {
                "code": "171701",
                "parentcode": "1717",
                "parenttext": "神农架林区",
                "text": "神农架林区",
                "level": 3,
                "order": 1830,
                "relation_code": "429021",
                "relation_desc": "湖北省神农架林区"
            },
            {
                "code": "18",
                "parentcode": "0",
                "parenttext": "0",
                "text": "湖南省",
                "level": 1,
                "order": 1831,
                "relation_code": "",
                "relation_desc": "湖南省"
            },
            {
                "code": "1801",
                "parentcode": "18",
                "parenttext": "湖南省",
                "text": "长沙市",
                "level": 2,
                "order": 1832,
                "relation_code": "430100",
                "relation_desc": "湖南省长沙市"
            },
            {
                "code": "180101",
                "parentcode": "1801",
                "parenttext": "长沙市",
                "text": "芙蓉区",
                "level": 3,
                "order": 1833,
                "relation_code": "430102",
                "relation_desc": "湖南省长沙市芙蓉区"
            },
            {
                "code": "180102",
                "parentcode": "1801",
                "parenttext": "长沙市",
                "text": "天心区",
                "level": 3,
                "order": 1834,
                "relation_code": "430103",
                "relation_desc": "湖南省长沙市天心区"
            },
            {
                "code": "180103",
                "parentcode": "1801",
                "parenttext": "长沙市",
                "text": "岳麓区",
                "level": 3,
                "order": 1835,
                "relation_code": "430104",
                "relation_desc": "湖南省长沙市岳麓区"
            },
            {
                "code": "180104",
                "parentcode": "1801",
                "parenttext": "长沙市",
                "text": "开福区",
                "level": 3,
                "order": 1836,
                "relation_code": "430105",
                "relation_desc": "湖南省长沙市开福区"
            },
            {
                "code": "180105",
                "parentcode": "1801",
                "parenttext": "长沙市",
                "text": "雨花区",
                "level": 3,
                "order": 1837,
                "relation_code": "430111",
                "relation_desc": "湖南省长沙市雨花区"
            },
            {
                "code": "180106",
                "parentcode": "1801",
                "parenttext": "长沙市",
                "text": "长沙县",
                "level": 3,
                "order": 1838,
                "relation_code": "430121",
                "relation_desc": "湖南省长沙县"
            },
            {
                "code": "180107",
                "parentcode": "1801",
                "parenttext": "长沙市",
                "text": "望城区",
                "level": 3,
                "order": 1839,
                "relation_code": "430112",
                "relation_desc": "湖南省长沙市望城区"
            },
            {
                "code": "180108",
                "parentcode": "1801",
                "parenttext": "长沙市",
                "text": "宁乡市",
                "level": 3,
                "order": 1840,
                "relation_code": "430182",
                "relation_desc": "湖南省宁乡市"
            },
            {
                "code": "180109",
                "parentcode": "1801",
                "parenttext": "长沙市",
                "text": "浏阳市",
                "level": 3,
                "order": 1841,
                "relation_code": "430181",
                "relation_desc": "湖南省浏阳市"
            },
            {
                "code": "1802",
                "parentcode": "18",
                "parenttext": "湖南省",
                "text": "株洲市",
                "level": 2,
                "order": 1842,
                "relation_code": "430200",
                "relation_desc": "湖南省株洲市"
            },
            {
                "code": "180201",
                "parentcode": "1802",
                "parenttext": "株洲市",
                "text": "荷塘区",
                "level": 3,
                "order": 1843,
                "relation_code": "430202",
                "relation_desc": "湖南省株洲市荷塘区"
            },
            {
                "code": "180202",
                "parentcode": "1802",
                "parenttext": "株洲市",
                "text": "芦淞区",
                "level": 3,
                "order": 1844,
                "relation_code": "430203",
                "relation_desc": "湖南省株洲市芦淞区"
            },
            {
                "code": "180203",
                "parentcode": "1802",
                "parenttext": "株洲市",
                "text": "石峰区",
                "level": 3,
                "order": 1845,
                "relation_code": "430204",
                "relation_desc": "湖南省株洲市石峰区"
            },
            {
                "code": "180204",
                "parentcode": "1802",
                "parenttext": "株洲市",
                "text": "天元区",
                "level": 3,
                "order": 1846,
                "relation_code": "430211",
                "relation_desc": "湖南省株洲市天元区"
            },
            {
                "code": "180205",
                "parentcode": "1802",
                "parenttext": "株洲市",
                "text": "株洲县",
                "level": 3,
                "order": 1847,
                "relation_code": "430201",
                "relation_desc": "湖南省株洲县"
            },
            {
                "code": "180206",
                "parentcode": "1802",
                "parenttext": "株洲市",
                "text": "攸县",
                "level": 3,
                "order": 1848,
                "relation_code": "430223",
                "relation_desc": "湖南省攸县"
            },
            {
                "code": "180207",
                "parentcode": "1802",
                "parenttext": "株洲市",
                "text": "茶陵县",
                "level": 3,
                "order": 1849,
                "relation_code": "430224",
                "relation_desc": "湖南省茶陵县"
            },
            {
                "code": "180208",
                "parentcode": "1802",
                "parenttext": "株洲市",
                "text": "炎陵县",
                "level": 3,
                "order": 1850,
                "relation_code": "430225",
                "relation_desc": "湖南省炎陵县"
            },
            {
                "code": "180209",
                "parentcode": "1802",
                "parenttext": "株洲市",
                "text": "醴陵市",
                "level": 3,
                "order": 1851,
                "relation_code": "430281",
                "relation_desc": "湖南省醴陵市"
            },
            {
                "code": "1803",
                "parentcode": "18",
                "parenttext": "湖南省",
                "text": "湘潭市",
                "level": 2,
                "order": 1852,
                "relation_code": "430300",
                "relation_desc": "湖南省湘潭市"
            },
            {
                "code": "180210",
                "parentcode": "1802",
                "parenttext": "株洲市",
                "text": "渌口区",
                "level": 3,
                "order": 1852,
                "relation_code": "430212",
                "relation_desc": "湖南省株洲市渌口区"
            },
            {
                "code": "180301",
                "parentcode": "1803",
                "parenttext": "湘潭市",
                "text": "雨湖区",
                "level": 3,
                "order": 1853,
                "relation_code": "430302",
                "relation_desc": "湖南省湘潭市雨湖区"
            },
            {
                "code": "180211",
                "parentcode": "1802",
                "parenttext": "株洲市",
                "text": "云龙示范区",
                "level": 3,
                "order": 1853,
                "relation_code": "430201",
                "relation_desc": "湖南省株洲市"
            },
            {
                "code": "180302",
                "parentcode": "1803",
                "parenttext": "湘潭市",
                "text": "岳塘区",
                "level": 3,
                "order": 1854,
                "relation_code": "430304",
                "relation_desc": "湖南省湘潭市岳塘区"
            },
            {
                "code": "180303",
                "parentcode": "1803",
                "parenttext": "湘潭市",
                "text": "湘潭县",
                "level": 3,
                "order": 1855,
                "relation_code": "430321",
                "relation_desc": "湖南省湘潭县"
            },
            {
                "code": "180304",
                "parentcode": "1803",
                "parenttext": "湘潭市",
                "text": "湘乡市",
                "level": 3,
                "order": 1856,
                "relation_code": "430381",
                "relation_desc": "湖南省湘乡市"
            },
            {
                "code": "180305",
                "parentcode": "1803",
                "parenttext": "湘潭市",
                "text": "韶山市",
                "level": 3,
                "order": 1857,
                "relation_code": "430382",
                "relation_desc": "湖南省韶山市"
            },
            {
                "code": "1804",
                "parentcode": "18",
                "parenttext": "湖南省",
                "text": "衡阳市",
                "level": 2,
                "order": 1858,
                "relation_code": "430400",
                "relation_desc": "湖南省衡阳市"
            },
            {
                "code": "180306",
                "parentcode": "1803",
                "parenttext": "湘潭市",
                "text": "湖南湘潭高新技术产业园区",
                "level": 3,
                "order": 1858,
                "relation_code": "430301",
                "relation_desc": "湖南省湘潭市"
            },
            {
                "code": "180401",
                "parentcode": "1804",
                "parenttext": "衡阳市",
                "text": "珠晖区",
                "level": 3,
                "order": 1859,
                "relation_code": "430405",
                "relation_desc": "湖南省衡阳市珠晖区"
            },
            {
                "code": "180307",
                "parentcode": "1803",
                "parenttext": "湘潭市",
                "text": "湘潭昭山示范区",
                "level": 3,
                "order": 1859,
                "relation_code": "430301",
                "relation_desc": "湖南省湘潭市"
            },
            {
                "code": "180402",
                "parentcode": "1804",
                "parenttext": "衡阳市",
                "text": "雁峰区",
                "level": 3,
                "order": 1860,
                "relation_code": "430406",
                "relation_desc": "湖南省衡阳市雁峰区"
            },
            {
                "code": "180308",
                "parentcode": "1803",
                "parenttext": "湘潭市",
                "text": "湘潭九华示范区",
                "level": 3,
                "order": 1860,
                "relation_code": "430301",
                "relation_desc": "湖南省湘潭市"
            },
            {
                "code": "180403",
                "parentcode": "1804",
                "parenttext": "衡阳市",
                "text": "石鼓区",
                "level": 3,
                "order": 1861,
                "relation_code": "430407",
                "relation_desc": "湖南省衡阳市石鼓区"
            },
            {
                "code": "180404",
                "parentcode": "1804",
                "parenttext": "衡阳市",
                "text": "蒸湘区",
                "level": 3,
                "order": 1862,
                "relation_code": "430408",
                "relation_desc": "湖南省衡阳市蒸湘区"
            },
            {
                "code": "180405",
                "parentcode": "1804",
                "parenttext": "衡阳市",
                "text": "南岳区",
                "level": 3,
                "order": 1863,
                "relation_code": "430412",
                "relation_desc": "湖南省衡阳市南岳区"
            },
            {
                "code": "180406",
                "parentcode": "1804",
                "parenttext": "衡阳市",
                "text": "衡阳县",
                "level": 3,
                "order": 1864,
                "relation_code": "430421",
                "relation_desc": "湖南省衡阳县"
            },
            {
                "code": "180407",
                "parentcode": "1804",
                "parenttext": "衡阳市",
                "text": "衡南县",
                "level": 3,
                "order": 1865,
                "relation_code": "430422",
                "relation_desc": "湖南省衡南县"
            },
            {
                "code": "180408",
                "parentcode": "1804",
                "parenttext": "衡阳市",
                "text": "衡山县",
                "level": 3,
                "order": 1866,
                "relation_code": "430423",
                "relation_desc": "湖南省衡山县"
            },
            {
                "code": "180409",
                "parentcode": "1804",
                "parenttext": "衡阳市",
                "text": "衡东县",
                "level": 3,
                "order": 1867,
                "relation_code": "430424",
                "relation_desc": "湖南省衡东县"
            },
            {
                "code": "180410",
                "parentcode": "1804",
                "parenttext": "衡阳市",
                "text": "祁东县",
                "level": 3,
                "order": 1868,
                "relation_code": "430426",
                "relation_desc": "湖南省祁东县"
            },
            {
                "code": "180411",
                "parentcode": "1804",
                "parenttext": "衡阳市",
                "text": "耒阳市",
                "level": 3,
                "order": 1869,
                "relation_code": "430481",
                "relation_desc": "湖南省耒阳市"
            },
            {
                "code": "180412",
                "parentcode": "1804",
                "parenttext": "衡阳市",
                "text": "常宁市",
                "level": 3,
                "order": 1870,
                "relation_code": "430482",
                "relation_desc": "湖南省常宁市"
            },
            {
                "code": "1805",
                "parentcode": "18",
                "parenttext": "湖南省",
                "text": "邵阳市",
                "level": 2,
                "order": 1871,
                "relation_code": "430500",
                "relation_desc": "湖南省邵阳市"
            },
            {
                "code": "180413",
                "parentcode": "1804",
                "parenttext": "衡阳市",
                "text": "衡阳综合保税区",
                "level": 3,
                "order": 1871,
                "relation_code": "430401",
                "relation_desc": "湖南省衡阳市"
            },
            {
                "code": "180501",
                "parentcode": "1805",
                "parenttext": "邵阳市",
                "text": "双清区",
                "level": 3,
                "order": 1872,
                "relation_code": "430502",
                "relation_desc": "湖南省邵阳市双清区"
            },
            {
                "code": "180414",
                "parentcode": "1804",
                "parenttext": "衡阳市",
                "text": "湖南衡阳高新技术产业园区",
                "level": 3,
                "order": 1872,
                "relation_code": "430401",
                "relation_desc": "湖南省衡阳市"
            },
            {
                "code": "180502",
                "parentcode": "1805",
                "parenttext": "邵阳市",
                "text": "大祥区",
                "level": 3,
                "order": 1873,
                "relation_code": "430503",
                "relation_desc": "湖南省邵阳市大祥区"
            },
            {
                "code": "180415",
                "parentcode": "1804",
                "parenttext": "衡阳市",
                "text": "湖南衡阳松木经济开发区",
                "level": 3,
                "order": 1873,
                "relation_code": "430401",
                "relation_desc": "湖南省衡阳市"
            },
            {
                "code": "180503",
                "parentcode": "1805",
                "parenttext": "邵阳市",
                "text": "北塔区",
                "level": 3,
                "order": 1874,
                "relation_code": "430511",
                "relation_desc": "湖南省邵阳市北塔区"
            },
            {
                "code": "180504",
                "parentcode": "1805",
                "parenttext": "邵阳市",
                "text": "邵东县",
                "level": 3,
                "order": 1875,
                "relation_code": "430501",
                "relation_desc": "湖南省邵东县"
            },
            {
                "code": "180505",
                "parentcode": "1805",
                "parenttext": "邵阳市",
                "text": "新邵县",
                "level": 3,
                "order": 1876,
                "relation_code": "430522",
                "relation_desc": "湖南省新邵县"
            },
            {
                "code": "180506",
                "parentcode": "1805",
                "parenttext": "邵阳市",
                "text": "邵阳县",
                "level": 3,
                "order": 1877,
                "relation_code": "430523",
                "relation_desc": "湖南省邵阳县"
            },
            {
                "code": "180507",
                "parentcode": "1805",
                "parenttext": "邵阳市",
                "text": "隆回县",
                "level": 3,
                "order": 1878,
                "relation_code": "430524",
                "relation_desc": "湖南省隆回县"
            },
            {
                "code": "180508",
                "parentcode": "1805",
                "parenttext": "邵阳市",
                "text": "洞口县",
                "level": 3,
                "order": 1879,
                "relation_code": "430525",
                "relation_desc": "湖南省洞口县"
            },
            {
                "code": "180509",
                "parentcode": "1805",
                "parenttext": "邵阳市",
                "text": "绥宁县",
                "level": 3,
                "order": 1880,
                "relation_code": "430527",
                "relation_desc": "湖南省绥宁县"
            },
            {
                "code": "180510",
                "parentcode": "1805",
                "parenttext": "邵阳市",
                "text": "新宁县",
                "level": 3,
                "order": 1881,
                "relation_code": "430528",
                "relation_desc": "湖南省新宁县"
            },
            {
                "code": "180511",
                "parentcode": "1805",
                "parenttext": "邵阳市",
                "text": "城步苗族自治县",
                "level": 3,
                "order": 1882,
                "relation_code": "430529",
                "relation_desc": "湖南省城步苗族自治县"
            },
            {
                "code": "180512",
                "parentcode": "1805",
                "parenttext": "邵阳市",
                "text": "武冈市",
                "level": 3,
                "order": 1883,
                "relation_code": "430581",
                "relation_desc": "湖南省武冈市"
            },
            {
                "code": "1806",
                "parentcode": "18",
                "parenttext": "湖南省",
                "text": "岳阳市",
                "level": 2,
                "order": 1884,
                "relation_code": "430600",
                "relation_desc": "湖南省岳阳市"
            },
            {
                "code": "180513",
                "parentcode": "1805",
                "parenttext": "邵阳市",
                "text": "邵东市",
                "level": 3,
                "order": 1884,
                "relation_code": "430501",
                "relation_desc": "湖南省邵阳市"
            },
            {
                "code": "180601",
                "parentcode": "1806",
                "parenttext": "岳阳市",
                "text": "岳阳楼区",
                "level": 3,
                "order": 1885,
                "relation_code": "430602",
                "relation_desc": "湖南省岳阳市岳阳楼区"
            },
            {
                "code": "180602",
                "parentcode": "1806",
                "parenttext": "岳阳市",
                "text": "云溪区",
                "level": 3,
                "order": 1886,
                "relation_code": "430603",
                "relation_desc": "湖南省岳阳市云溪区"
            },
            {
                "code": "180603",
                "parentcode": "1806",
                "parenttext": "岳阳市",
                "text": "君山区",
                "level": 3,
                "order": 1887,
                "relation_code": "430611",
                "relation_desc": "湖南省岳阳市君山区"
            },
            {
                "code": "180604",
                "parentcode": "1806",
                "parenttext": "岳阳市",
                "text": "岳阳县",
                "level": 3,
                "order": 1888,
                "relation_code": "430621",
                "relation_desc": "湖南省岳阳县"
            },
            {
                "code": "180605",
                "parentcode": "1806",
                "parenttext": "岳阳市",
                "text": "华容县",
                "level": 3,
                "order": 1889,
                "relation_code": "430623",
                "relation_desc": "湖南省华容县"
            },
            {
                "code": "180606",
                "parentcode": "1806",
                "parenttext": "岳阳市",
                "text": "湘阴县",
                "level": 3,
                "order": 1890,
                "relation_code": "430624",
                "relation_desc": "湖南省湘阴县"
            },
            {
                "code": "180607",
                "parentcode": "1806",
                "parenttext": "岳阳市",
                "text": "平江县",
                "level": 3,
                "order": 1891,
                "relation_code": "430626",
                "relation_desc": "湖南省平江县"
            },
            {
                "code": "180608",
                "parentcode": "1806",
                "parenttext": "岳阳市",
                "text": "汨罗市",
                "level": 3,
                "order": 1892,
                "relation_code": "430681",
                "relation_desc": "湖南省汨罗市"
            },
            {
                "code": "180609",
                "parentcode": "1806",
                "parenttext": "岳阳市",
                "text": "临湘市",
                "level": 3,
                "order": 1893,
                "relation_code": "430682",
                "relation_desc": "湖南省临湘市"
            },
            {
                "code": "1807",
                "parentcode": "18",
                "parenttext": "湖南省",
                "text": "常德市",
                "level": 2,
                "order": 1894,
                "relation_code": "430700",
                "relation_desc": "湖南省常德市"
            },
            {
                "code": "180610",
                "parentcode": "1806",
                "parenttext": "岳阳市",
                "text": "岳阳市屈原管理区",
                "level": 3,
                "order": 1894,
                "relation_code": "430601",
                "relation_desc": "湖南省岳阳市"
            },
            {
                "code": "180701",
                "parentcode": "1807",
                "parenttext": "常德市",
                "text": "武陵区",
                "level": 3,
                "order": 1895,
                "relation_code": "430702",
                "relation_desc": "湖南省常德市武陵区"
            },
            {
                "code": "180702",
                "parentcode": "1807",
                "parenttext": "常德市",
                "text": "鼎城区",
                "level": 3,
                "order": 1896,
                "relation_code": "430703",
                "relation_desc": "湖南省常德市鼎城区"
            },
            {
                "code": "180703",
                "parentcode": "1807",
                "parenttext": "常德市",
                "text": "安乡县",
                "level": 3,
                "order": 1897,
                "relation_code": "430721",
                "relation_desc": "湖南省安乡县"
            },
            {
                "code": "180704",
                "parentcode": "1807",
                "parenttext": "常德市",
                "text": "汉寿县",
                "level": 3,
                "order": 1898,
                "relation_code": "430722",
                "relation_desc": "湖南省汉寿县"
            },
            {
                "code": "180705",
                "parentcode": "1807",
                "parenttext": "常德市",
                "text": "澧县",
                "level": 3,
                "order": 1899,
                "relation_code": "430723",
                "relation_desc": "湖南省澧县"
            },
            {
                "code": "180706",
                "parentcode": "1807",
                "parenttext": "常德市",
                "text": "临澧县",
                "level": 3,
                "order": 1900,
                "relation_code": "430724",
                "relation_desc": "湖南省临澧县"
            },
            {
                "code": "180707",
                "parentcode": "1807",
                "parenttext": "常德市",
                "text": "桃源县",
                "level": 3,
                "order": 1901,
                "relation_code": "430725",
                "relation_desc": "湖南省桃源县"
            },
            {
                "code": "180708",
                "parentcode": "1807",
                "parenttext": "常德市",
                "text": "石门县",
                "level": 3,
                "order": 1902,
                "relation_code": "430726",
                "relation_desc": "湖南省石门县"
            },
            {
                "code": "180709",
                "parentcode": "1807",
                "parenttext": "常德市",
                "text": "津市市",
                "level": 3,
                "order": 1903,
                "relation_code": "430781",
                "relation_desc": "湖南省津市市"
            },
            {
                "code": "1808",
                "parentcode": "18",
                "parenttext": "湖南省",
                "text": "张家界市",
                "level": 2,
                "order": 1904,
                "relation_code": "430800",
                "relation_desc": "湖南省张家界市"
            },
            {
                "code": "180710",
                "parentcode": "1807",
                "parenttext": "常德市",
                "text": "常德市西洞庭管理区",
                "level": 3,
                "order": 1904,
                "relation_code": "430701",
                "relation_desc": "湖南省常德市"
            },
            {
                "code": "180801",
                "parentcode": "1808",
                "parenttext": "张家界市",
                "text": "永定区",
                "level": 3,
                "order": 1905,
                "relation_code": "430802",
                "relation_desc": "湖南省张家界市永定区"
            },
            {
                "code": "180802",
                "parentcode": "1808",
                "parenttext": "张家界市",
                "text": "武陵源区",
                "level": 3,
                "order": 1906,
                "relation_code": "430811",
                "relation_desc": "湖南省张家界市武陵源区"
            },
            {
                "code": "180803",
                "parentcode": "1808",
                "parenttext": "张家界市",
                "text": "慈利县",
                "level": 3,
                "order": 1907,
                "relation_code": "430821",
                "relation_desc": "湖南省慈利县"
            },
            {
                "code": "180804",
                "parentcode": "1808",
                "parenttext": "张家界市",
                "text": "桑植县",
                "level": 3,
                "order": 1908,
                "relation_code": "430822",
                "relation_desc": "湖南省桑植县"
            },
            {
                "code": "1809",
                "parentcode": "18",
                "parenttext": "湖南省",
                "text": "益阳市",
                "level": 2,
                "order": 1909,
                "relation_code": "430900",
                "relation_desc": "湖南省益阳市"
            },
            {
                "code": "180901",
                "parentcode": "1809",
                "parenttext": "益阳市",
                "text": "资阳区",
                "level": 3,
                "order": 1910,
                "relation_code": "430902",
                "relation_desc": "湖南省益阳市资阳区"
            },
            {
                "code": "180902",
                "parentcode": "1809",
                "parenttext": "益阳市",
                "text": "赫山区",
                "level": 3,
                "order": 1911,
                "relation_code": "430903",
                "relation_desc": "湖南省益阳市赫山区"
            },
            {
                "code": "180903",
                "parentcode": "1809",
                "parenttext": "益阳市",
                "text": "南县",
                "level": 3,
                "order": 1912,
                "relation_code": "430921",
                "relation_desc": "湖南省南县"
            },
            {
                "code": "180904",
                "parentcode": "1809",
                "parenttext": "益阳市",
                "text": "桃江县",
                "level": 3,
                "order": 1913,
                "relation_code": "430922",
                "relation_desc": "湖南省桃江县"
            },
            {
                "code": "180905",
                "parentcode": "1809",
                "parenttext": "益阳市",
                "text": "安化县",
                "level": 3,
                "order": 1914,
                "relation_code": "430923",
                "relation_desc": "湖南省安化县"
            },
            {
                "code": "180906",
                "parentcode": "1809",
                "parenttext": "益阳市",
                "text": "沅江市",
                "level": 3,
                "order": 1915,
                "relation_code": "430981",
                "relation_desc": "湖南省沅江市"
            },
            {
                "code": "1810",
                "parentcode": "18",
                "parenttext": "湖南省",
                "text": "郴州市",
                "level": 2,
                "order": 1916,
                "relation_code": "431000",
                "relation_desc": "湖南省郴州市"
            },
            {
                "code": "180907",
                "parentcode": "1809",
                "parenttext": "益阳市",
                "text": "益阳市大通湖管理区",
                "level": 3,
                "order": 1916,
                "relation_code": "430901",
                "relation_desc": "湖南省益阳市"
            },
            {
                "code": "181001",
                "parentcode": "1810",
                "parenttext": "郴州市",
                "text": "北湖区",
                "level": 3,
                "order": 1917,
                "relation_code": "431002",
                "relation_desc": "湖南省郴州市北湖区"
            },
            {
                "code": "180908",
                "parentcode": "1809",
                "parenttext": "益阳市",
                "text": "湖南益阳高新技术产业园区",
                "level": 3,
                "order": 1917,
                "relation_code": "430901",
                "relation_desc": "湖南省益阳市"
            },
            {
                "code": "181002",
                "parentcode": "1810",
                "parenttext": "郴州市",
                "text": "苏仙区",
                "level": 3,
                "order": 1918,
                "relation_code": "431003",
                "relation_desc": "湖南省郴州市苏仙区"
            },
            {
                "code": "181003",
                "parentcode": "1810",
                "parenttext": "郴州市",
                "text": "桂阳县",
                "level": 3,
                "order": 1919,
                "relation_code": "431021",
                "relation_desc": "湖南省桂阳县"
            },
            {
                "code": "181004",
                "parentcode": "1810",
                "parenttext": "郴州市",
                "text": "宜章县",
                "level": 3,
                "order": 1920,
                "relation_code": "431022",
                "relation_desc": "湖南省宜章县"
            },
            {
                "code": "181005",
                "parentcode": "1810",
                "parenttext": "郴州市",
                "text": "永兴县",
                "level": 3,
                "order": 1921,
                "relation_code": "431023",
                "relation_desc": "湖南省永兴县"
            },
            {
                "code": "181006",
                "parentcode": "1810",
                "parenttext": "郴州市",
                "text": "嘉禾县",
                "level": 3,
                "order": 1922,
                "relation_code": "431024",
                "relation_desc": "湖南省嘉禾县"
            },
            {
                "code": "181007",
                "parentcode": "1810",
                "parenttext": "郴州市",
                "text": "临武县",
                "level": 3,
                "order": 1923,
                "relation_code": "431025",
                "relation_desc": "湖南省临武县"
            },
            {
                "code": "181008",
                "parentcode": "1810",
                "parenttext": "郴州市",
                "text": "汝城县",
                "level": 3,
                "order": 1924,
                "relation_code": "431026",
                "relation_desc": "湖南省汝城县"
            },
            {
                "code": "181009",
                "parentcode": "1810",
                "parenttext": "郴州市",
                "text": "桂东县",
                "level": 3,
                "order": 1925,
                "relation_code": "431027",
                "relation_desc": "湖南省桂东县"
            },
            {
                "code": "181010",
                "parentcode": "1810",
                "parenttext": "郴州市",
                "text": "安仁县",
                "level": 3,
                "order": 1926,
                "relation_code": "431028",
                "relation_desc": "湖南省安仁县"
            },
            {
                "code": "181011",
                "parentcode": "1810",
                "parenttext": "郴州市",
                "text": "资兴市",
                "level": 3,
                "order": 1927,
                "relation_code": "431081",
                "relation_desc": "湖南省资兴市"
            },
            {
                "code": "1811",
                "parentcode": "18",
                "parenttext": "湖南省",
                "text": "永州市",
                "level": 2,
                "order": 1928,
                "relation_code": "431100",
                "relation_desc": "湖南省永州市"
            },
            {
                "code": "181101",
                "parentcode": "1811",
                "parenttext": "永州市",
                "text": "零陵区",
                "level": 3,
                "order": 1929,
                "relation_code": "431102",
                "relation_desc": "湖南省永州市零陵区"
            },
            {
                "code": "181102",
                "parentcode": "1811",
                "parenttext": "永州市",
                "text": "冷水滩区",
                "level": 3,
                "order": 1930,
                "relation_code": "431103",
                "relation_desc": "湖南省永州市冷水滩区"
            },
            {
                "code": "181103",
                "parentcode": "1811",
                "parenttext": "永州市",
                "text": "祁阳县",
                "level": 3,
                "order": 1931,
                "relation_code": "431121",
                "relation_desc": "湖南省祁阳县"
            },
            {
                "code": "181104",
                "parentcode": "1811",
                "parenttext": "永州市",
                "text": "东安县",
                "level": 3,
                "order": 1932,
                "relation_code": "431122",
                "relation_desc": "湖南省东安县"
            },
            {
                "code": "181105",
                "parentcode": "1811",
                "parenttext": "永州市",
                "text": "双牌县",
                "level": 3,
                "order": 1933,
                "relation_code": "431123",
                "relation_desc": "湖南省双牌县"
            },
            {
                "code": "181106",
                "parentcode": "1811",
                "parenttext": "永州市",
                "text": "道县",
                "level": 3,
                "order": 1934,
                "relation_code": "431124",
                "relation_desc": "湖南省道县"
            },
            {
                "code": "181107",
                "parentcode": "1811",
                "parenttext": "永州市",
                "text": "江永县",
                "level": 3,
                "order": 1935,
                "relation_code": "431125",
                "relation_desc": "湖南省江永县"
            },
            {
                "code": "181108",
                "parentcode": "1811",
                "parenttext": "永州市",
                "text": "宁远县",
                "level": 3,
                "order": 1936,
                "relation_code": "431126",
                "relation_desc": "湖南省宁远县"
            },
            {
                "code": "181109",
                "parentcode": "1811",
                "parenttext": "永州市",
                "text": "蓝山县",
                "level": 3,
                "order": 1937,
                "relation_code": "431127",
                "relation_desc": "湖南省蓝山县"
            },
            {
                "code": "181110",
                "parentcode": "1811",
                "parenttext": "永州市",
                "text": "新田县",
                "level": 3,
                "order": 1938,
                "relation_code": "431128",
                "relation_desc": "湖南省新田县"
            },
            {
                "code": "181111",
                "parentcode": "1811",
                "parenttext": "永州市",
                "text": "江华瑶族自治县",
                "level": 3,
                "order": 1939,
                "relation_code": "431129",
                "relation_desc": "湖南省江华瑶族自治县"
            },
            {
                "code": "1812",
                "parentcode": "18",
                "parenttext": "湖南省",
                "text": "怀化市",
                "level": 2,
                "order": 1940,
                "relation_code": "431200",
                "relation_desc": "湖南省怀化市"
            },
            {
                "code": "181112",
                "parentcode": "1811",
                "parenttext": "永州市",
                "text": "永州经济技术开发区",
                "level": 3,
                "order": 1940,
                "relation_code": "431101",
                "relation_desc": "湖南省永州市"
            },
            {
                "code": "181201",
                "parentcode": "1812",
                "parenttext": "怀化市",
                "text": "鹤城区",
                "level": 3,
                "order": 1941,
                "relation_code": "431202",
                "relation_desc": "湖南省怀化市鹤城区"
            },
            {
                "code": "181113",
                "parentcode": "1811",
                "parenttext": "永州市",
                "text": "永州市金洞管理区",
                "level": 3,
                "order": 1941,
                "relation_code": "431101",
                "relation_desc": "湖南省永州市"
            },
            {
                "code": "181202",
                "parentcode": "1812",
                "parenttext": "怀化市",
                "text": "中方县",
                "level": 3,
                "order": 1942,
                "relation_code": "431221",
                "relation_desc": "湖南省中方县"
            },
            {
                "code": "181114",
                "parentcode": "1811",
                "parenttext": "永州市",
                "text": "永州市回龙圩管理区",
                "level": 3,
                "order": 1942,
                "relation_code": "431101",
                "relation_desc": "湖南省永州市"
            },
            {
                "code": "181203",
                "parentcode": "1812",
                "parenttext": "怀化市",
                "text": "沅陵县",
                "level": 3,
                "order": 1943,
                "relation_code": "431222",
                "relation_desc": "湖南省沅陵县"
            },
            {
                "code": "181204",
                "parentcode": "1812",
                "parenttext": "怀化市",
                "text": "辰溪县",
                "level": 3,
                "order": 1944,
                "relation_code": "431223",
                "relation_desc": "湖南省辰溪县"
            },
            {
                "code": "181205",
                "parentcode": "1812",
                "parenttext": "怀化市",
                "text": "溆浦县",
                "level": 3,
                "order": 1945,
                "relation_code": "431224",
                "relation_desc": "湖南省溆浦县"
            },
            {
                "code": "181206",
                "parentcode": "1812",
                "parenttext": "怀化市",
                "text": "会同县",
                "level": 3,
                "order": 1946,
                "relation_code": "431225",
                "relation_desc": "湖南省会同县"
            },
            {
                "code": "181207",
                "parentcode": "1812",
                "parenttext": "怀化市",
                "text": "麻阳苗族自治县",
                "level": 3,
                "order": 1947,
                "relation_code": "431226",
                "relation_desc": "湖南省麻阳苗族自治县"
            },
            {
                "code": "181208",
                "parentcode": "1812",
                "parenttext": "怀化市",
                "text": "新晃侗族自治县",
                "level": 3,
                "order": 1948,
                "relation_code": "431227",
                "relation_desc": "湖南省新晃侗族自治县"
            },
            {
                "code": "181209",
                "parentcode": "1812",
                "parenttext": "怀化市",
                "text": "芷江侗族自治县",
                "level": 3,
                "order": 1949,
                "relation_code": "431228",
                "relation_desc": "湖南省芷江侗族自治县"
            },
            {
                "code": "181210",
                "parentcode": "1812",
                "parenttext": "怀化市",
                "text": "靖州苗族侗族自治县",
                "level": 3,
                "order": 1950,
                "relation_code": "431229",
                "relation_desc": "湖南省靖州苗族侗族自治县"
            },
            {
                "code": "181211",
                "parentcode": "1812",
                "parenttext": "怀化市",
                "text": "通道侗族自治县",
                "level": 3,
                "order": 1951,
                "relation_code": "431230",
                "relation_desc": "湖南省通道侗族自治县"
            },
            {
                "code": "181212",
                "parentcode": "1812",
                "parenttext": "怀化市",
                "text": "洪江市",
                "level": 3,
                "order": 1952,
                "relation_code": "431281",
                "relation_desc": "湖南省洪江市"
            },
            {
                "code": "1813",
                "parentcode": "18",
                "parenttext": "湖南省",
                "text": "娄底市",
                "level": 2,
                "order": 1953,
                "relation_code": "431300",
                "relation_desc": "湖南省娄底市"
            },
            {
                "code": "181213",
                "parentcode": "1812",
                "parenttext": "怀化市",
                "text": "怀化市洪江管理区",
                "level": 3,
                "order": 1953,
                "relation_code": "431201",
                "relation_desc": "湖南省怀化市"
            },
            {
                "code": "181301",
                "parentcode": "1813",
                "parenttext": "娄底市",
                "text": "娄星区",
                "level": 3,
                "order": 1954,
                "relation_code": "431302",
                "relation_desc": "湖南省娄底市娄星区"
            },
            {
                "code": "181302",
                "parentcode": "1813",
                "parenttext": "娄底市",
                "text": "双峰县",
                "level": 3,
                "order": 1955,
                "relation_code": "431321",
                "relation_desc": "湖南省双峰县"
            },
            {
                "code": "181303",
                "parentcode": "1813",
                "parenttext": "娄底市",
                "text": "新化县",
                "level": 3,
                "order": 1956,
                "relation_code": "431322",
                "relation_desc": "湖南省新化县"
            },
            {
                "code": "181304",
                "parentcode": "1813",
                "parenttext": "娄底市",
                "text": "冷水江市",
                "level": 3,
                "order": 1957,
                "relation_code": "431381",
                "relation_desc": "湖南省冷水江市"
            },
            {
                "code": "181305",
                "parentcode": "1813",
                "parenttext": "娄底市",
                "text": "涟源市",
                "level": 3,
                "order": 1958,
                "relation_code": "431382",
                "relation_desc": "湖南省涟源市"
            },
            {
                "code": "1814",
                "parentcode": "18",
                "parenttext": "湖南省",
                "text": "湘西土家族苗族自治州",
                "level": 2,
                "order": 1959,
                "relation_code": "433100",
                "relation_desc": "湖南省湘西土家族苗族自治州"
            },
            {
                "code": "181401",
                "parentcode": "1814",
                "parenttext": "湘西土家族苗族自治州",
                "text": "吉首市",
                "level": 3,
                "order": 1960,
                "relation_code": "433101",
                "relation_desc": "湖南省吉首市"
            },
            {
                "code": "181402",
                "parentcode": "1814",
                "parenttext": "湘西土家族苗族自治州",
                "text": "泸溪县",
                "level": 3,
                "order": 1961,
                "relation_code": "433122",
                "relation_desc": "湖南省泸溪县"
            },
            {
                "code": "181403",
                "parentcode": "1814",
                "parenttext": "湘西土家族苗族自治州",
                "text": "凤凰县",
                "level": 3,
                "order": 1962,
                "relation_code": "433123",
                "relation_desc": "湖南省凤凰县"
            },
            {
                "code": "181404",
                "parentcode": "1814",
                "parenttext": "湘西土家族苗族自治州",
                "text": "花垣县",
                "level": 3,
                "order": 1963,
                "relation_code": "433124",
                "relation_desc": "湖南省花垣县"
            },
            {
                "code": "181405",
                "parentcode": "1814",
                "parenttext": "湘西土家族苗族自治州",
                "text": "保靖县",
                "level": 3,
                "order": 1964,
                "relation_code": "433125",
                "relation_desc": "湖南省保靖县"
            },
            {
                "code": "181406",
                "parentcode": "1814",
                "parenttext": "湘西土家族苗族自治州",
                "text": "古丈县",
                "level": 3,
                "order": 1965,
                "relation_code": "433126",
                "relation_desc": "湖南省古丈县"
            },
            {
                "code": "181407",
                "parentcode": "1814",
                "parenttext": "湘西土家族苗族自治州",
                "text": "永顺县",
                "level": 3,
                "order": 1966,
                "relation_code": "433127",
                "relation_desc": "湖南省永顺县"
            },
            {
                "code": "181408",
                "parentcode": "1814",
                "parenttext": "湘西土家族苗族自治州",
                "text": "龙山县",
                "level": 3,
                "order": 1967,
                "relation_code": "433130",
                "relation_desc": "湖南省龙山县"
            },
            {
                "code": "19",
                "parentcode": "0",
                "parenttext": "0",
                "text": "广东省",
                "level": 1,
                "order": 1968,
                "relation_code": "",
                "relation_desc": "广东省"
            },
            {
                "code": "181409",
                "parentcode": "1814",
                "parenttext": "湘西土家族苗族自治州",
                "text": "湖南永顺经济开发区",
                "level": 3,
                "order": 1968,
                "relation_code": "433101",
                "relation_desc": "湖南省湘西土家族苗族自治州"
            },
            {
                "code": "1901",
                "parentcode": "19",
                "parenttext": "广东省",
                "text": "广州市",
                "level": 2,
                "order": 1969,
                "relation_code": "440100",
                "relation_desc": "广东省广州市"
            },
            {
                "code": "190101",
                "parentcode": "1901",
                "parenttext": "广州市",
                "text": "东山区",
                "level": 3,
                "order": 1970,
                "relation_code": "440101",
                "relation_desc": "广东省广州市东山区"
            },
            {
                "code": "190102",
                "parentcode": "1901",
                "parenttext": "广州市",
                "text": "荔湾区",
                "level": 3,
                "order": 1971,
                "relation_code": "440103",
                "relation_desc": "广东省广州市荔湾区"
            },
            {
                "code": "190103",
                "parentcode": "1901",
                "parenttext": "广州市",
                "text": "越秀区",
                "level": 3,
                "order": 1972,
                "relation_code": "440104",
                "relation_desc": "广东省广州市越秀区"
            },
            {
                "code": "190104",
                "parentcode": "1901",
                "parenttext": "广州市",
                "text": "海珠区",
                "level": 3,
                "order": 1973,
                "relation_code": "440105",
                "relation_desc": "广东省广州市海珠区"
            },
            {
                "code": "190105",
                "parentcode": "1901",
                "parenttext": "广州市",
                "text": "天河区",
                "level": 3,
                "order": 1974,
                "relation_code": "440106",
                "relation_desc": "广东省广州市天河区"
            },
            {
                "code": "190106",
                "parentcode": "1901",
                "parenttext": "广州市",
                "text": "芳村区",
                "level": 3,
                "order": 1975,
                "relation_code": "440101",
                "relation_desc": "广东省广州市芳村区"
            },
            {
                "code": "190107",
                "parentcode": "1901",
                "parenttext": "广州市",
                "text": "白云区",
                "level": 3,
                "order": 1976,
                "relation_code": "440111",
                "relation_desc": "广东省广州市白云区"
            },
            {
                "code": "190108",
                "parentcode": "1901",
                "parenttext": "广州市",
                "text": "黄埔区",
                "level": 3,
                "order": 1977,
                "relation_code": "440112",
                "relation_desc": "广东省广州市黄埔区"
            },
            {
                "code": "190109",
                "parentcode": "1901",
                "parenttext": "广州市",
                "text": "番禺区",
                "level": 3,
                "order": 1978,
                "relation_code": "440113",
                "relation_desc": "广东省广州市番禺区"
            },
            {
                "code": "190110",
                "parentcode": "1901",
                "parenttext": "广州市",
                "text": "花都区",
                "level": 3,
                "order": 1979,
                "relation_code": "440114",
                "relation_desc": "广东省广州市花都区"
            },
            {
                "code": "190111",
                "parentcode": "1901",
                "parenttext": "广州市",
                "text": "增城区",
                "level": 3,
                "order": 1980,
                "relation_code": "440118",
                "relation_desc": "广东省广州市增城区"
            },
            {
                "code": "190112",
                "parentcode": "1901",
                "parenttext": "广州市",
                "text": "从化区",
                "level": 3,
                "order": 1981,
                "relation_code": "440117",
                "relation_desc": "广东省广州市从化区"
            },
            {
                "code": "1902",
                "parentcode": "19",
                "parenttext": "广东省",
                "text": "韶关市",
                "level": 2,
                "order": 1982,
                "relation_code": "440200",
                "relation_desc": "广东省韶关市"
            },
            {
                "code": "190201",
                "parentcode": "1902",
                "parenttext": "韶关市",
                "text": "北江区",
                "level": 3,
                "order": 1983,
                "relation_code": "440201",
                "relation_desc": "广东省韶关市北江区"
            },
            {
                "code": "190202",
                "parentcode": "1902",
                "parenttext": "韶关市",
                "text": "武江区",
                "level": 3,
                "order": 1984,
                "relation_code": "440203",
                "relation_desc": "广东省韶关市武江区"
            },
            {
                "code": "190203",
                "parentcode": "1902",
                "parenttext": "韶关市",
                "text": "浈江区",
                "level": 3,
                "order": 1985,
                "relation_code": "440204",
                "relation_desc": "广东省韶关市浈江区"
            },
            {
                "code": "190204",
                "parentcode": "1902",
                "parenttext": "韶关市",
                "text": "曲江区",
                "level": 3,
                "order": 1986,
                "relation_code": "440205",
                "relation_desc": "广东省韶关市曲江区"
            },
            {
                "code": "190205",
                "parentcode": "1902",
                "parenttext": "韶关市",
                "text": "始兴县",
                "level": 3,
                "order": 1987,
                "relation_code": "440222",
                "relation_desc": "广东省始兴县"
            },
            {
                "code": "190206",
                "parentcode": "1902",
                "parenttext": "韶关市",
                "text": "仁化县",
                "level": 3,
                "order": 1988,
                "relation_code": "440224",
                "relation_desc": "广东省仁化县"
            },
            {
                "code": "190207",
                "parentcode": "1902",
                "parenttext": "韶关市",
                "text": "翁源县",
                "level": 3,
                "order": 1989,
                "relation_code": "440229",
                "relation_desc": "广东省翁源县"
            },
            {
                "code": "190208",
                "parentcode": "1902",
                "parenttext": "韶关市",
                "text": "乳源瑶族自治县",
                "level": 3,
                "order": 1990,
                "relation_code": "440232",
                "relation_desc": "广东省乳源瑶族自治县"
            },
            {
                "code": "190209",
                "parentcode": "1902",
                "parenttext": "韶关市",
                "text": "新丰县",
                "level": 3,
                "order": 1991,
                "relation_code": "440233",
                "relation_desc": "广东省新丰县"
            },
            {
                "code": "190210",
                "parentcode": "1902",
                "parenttext": "韶关市",
                "text": "乐昌市",
                "level": 3,
                "order": 1992,
                "relation_code": "440281",
                "relation_desc": "广东省乐昌市"
            },
            {
                "code": "190211",
                "parentcode": "1902",
                "parenttext": "韶关市",
                "text": "南雄市",
                "level": 3,
                "order": 1993,
                "relation_code": "440282",
                "relation_desc": "广东省南雄市"
            },
            {
                "code": "1903",
                "parentcode": "19",
                "parenttext": "广东省",
                "text": "深圳市",
                "level": 2,
                "order": 1994,
                "relation_code": "440300",
                "relation_desc": "广东省深圳市"
            },
            {
                "code": "190301",
                "parentcode": "1903",
                "parenttext": "深圳市",
                "text": "罗湖区",
                "level": 3,
                "order": 1995,
                "relation_code": "440303",
                "relation_desc": "广东省深圳市罗湖区"
            },
            {
                "code": "190302",
                "parentcode": "1903",
                "parenttext": "深圳市",
                "text": "福田区",
                "level": 3,
                "order": 1996,
                "relation_code": "440304",
                "relation_desc": "广东省深圳市福田区"
            },
            {
                "code": "190303",
                "parentcode": "1903",
                "parenttext": "深圳市",
                "text": "南山区",
                "level": 3,
                "order": 1997,
                "relation_code": "440305",
                "relation_desc": "广东省深圳市南山区"
            },
            {
                "code": "190304",
                "parentcode": "1903",
                "parenttext": "深圳市",
                "text": "宝安区",
                "level": 3,
                "order": 1998,
                "relation_code": "440306",
                "relation_desc": "广东省深圳市宝安区"
            },
            {
                "code": "190305",
                "parentcode": "1903",
                "parenttext": "深圳市",
                "text": "龙岗区",
                "level": 3,
                "order": 1999,
                "relation_code": "440307",
                "relation_desc": "广东省深圳市龙岗区"
            },
            {
                "code": "190306",
                "parentcode": "1903",
                "parenttext": "深圳市",
                "text": "盐田区",
                "level": 3,
                "order": 2000,
                "relation_code": "440308",
                "relation_desc": "广东省深圳市盐田区"
            },
            {
                "code": "1904",
                "parentcode": "19",
                "parenttext": "广东省",
                "text": "珠海市",
                "level": 2,
                "order": 2001,
                "relation_code": "440400",
                "relation_desc": "广东省珠海市"
            },
            {
                "code": "190307",
                "parentcode": "1903",
                "parenttext": "深圳市",
                "text": "龙华区",
                "level": 3,
                "order": 2001,
                "relation_code": "440309",
                "relation_desc": "广东省深圳市龙华区"
            },
            {
                "code": "190401",
                "parentcode": "1904",
                "parenttext": "珠海市",
                "text": "香洲区",
                "level": 3,
                "order": 2002,
                "relation_code": "440402",
                "relation_desc": "广东省珠海市香洲区"
            },
            {
                "code": "190308",
                "parentcode": "1903",
                "parenttext": "深圳市",
                "text": "坪山区",
                "level": 3,
                "order": 2002,
                "relation_code": "440310",
                "relation_desc": "广东省深圳市坪山区"
            },
            {
                "code": "190402",
                "parentcode": "1904",
                "parenttext": "珠海市",
                "text": "斗门区",
                "level": 3,
                "order": 2003,
                "relation_code": "440403",
                "relation_desc": "广东省珠海市斗门区"
            },
            {
                "code": "190309",
                "parentcode": "1903",
                "parenttext": "深圳市",
                "text": "光明区",
                "level": 3,
                "order": 2003,
                "relation_code": "440311",
                "relation_desc": "广东省深圳市光明区"
            },
            {
                "code": "190403",
                "parentcode": "1904",
                "parenttext": "珠海市",
                "text": "金湾区",
                "level": 3,
                "order": 2004,
                "relation_code": "440404",
                "relation_desc": "广东省珠海市金湾区"
            },
            {
                "code": "1905",
                "parentcode": "19",
                "parenttext": "广东省",
                "text": "汕头市",
                "level": 2,
                "order": 2005,
                "relation_code": "440500",
                "relation_desc": "广东省汕头市"
            },
            {
                "code": "190501",
                "parentcode": "1905",
                "parenttext": "汕头市",
                "text": "龙湖区",
                "level": 3,
                "order": 2006,
                "relation_code": "440507",
                "relation_desc": "广东省汕头市龙湖区"
            },
            {
                "code": "190502",
                "parentcode": "1905",
                "parenttext": "汕头市",
                "text": "金平区",
                "level": 3,
                "order": 2007,
                "relation_code": "440511",
                "relation_desc": "广东省汕头市金平区"
            },
            {
                "code": "190503",
                "parentcode": "1905",
                "parenttext": "汕头市",
                "text": "濠江区",
                "level": 3,
                "order": 2008,
                "relation_code": "440512",
                "relation_desc": "广东省汕头市濠江区"
            },
            {
                "code": "190504",
                "parentcode": "1905",
                "parenttext": "汕头市",
                "text": "潮阳区",
                "level": 3,
                "order": 2009,
                "relation_code": "440513",
                "relation_desc": "广东省汕头市潮阳区"
            },
            {
                "code": "190505",
                "parentcode": "1905",
                "parenttext": "汕头市",
                "text": "潮南区",
                "level": 3,
                "order": 2010,
                "relation_code": "440514",
                "relation_desc": "广东省汕头市潮南区"
            },
            {
                "code": "190506",
                "parentcode": "1905",
                "parenttext": "汕头市",
                "text": "澄海区",
                "level": 3,
                "order": 2011,
                "relation_code": "440515",
                "relation_desc": "广东省汕头市澄海区"
            },
            {
                "code": "190507",
                "parentcode": "1905",
                "parenttext": "汕头市",
                "text": "南澳县",
                "level": 3,
                "order": 2012,
                "relation_code": "440523",
                "relation_desc": "广东省南澳县"
            },
            {
                "code": "1906",
                "parentcode": "19",
                "parenttext": "广东省",
                "text": "佛山市",
                "level": 2,
                "order": 2013,
                "relation_code": "440600",
                "relation_desc": "广东省佛山市"
            },
            {
                "code": "190601",
                "parentcode": "1906",
                "parenttext": "佛山市",
                "text": "禅城区",
                "level": 3,
                "order": 2014,
                "relation_code": "440604",
                "relation_desc": "广东省佛山市禅城区"
            },
            {
                "code": "190602",
                "parentcode": "1906",
                "parenttext": "佛山市",
                "text": "南海区",
                "level": 3,
                "order": 2015,
                "relation_code": "440605",
                "relation_desc": "广东省佛山市南海区"
            },
            {
                "code": "190603",
                "parentcode": "1906",
                "parenttext": "佛山市",
                "text": "顺德区",
                "level": 3,
                "order": 2016,
                "relation_code": "440606",
                "relation_desc": "广东省佛山市顺德区"
            },
            {
                "code": "190604",
                "parentcode": "1906",
                "parenttext": "佛山市",
                "text": "三水区",
                "level": 3,
                "order": 2017,
                "relation_code": "440607",
                "relation_desc": "广东省佛山市三水区"
            },
            {
                "code": "190605",
                "parentcode": "1906",
                "parenttext": "佛山市",
                "text": "高明区",
                "level": 3,
                "order": 2018,
                "relation_code": "440608",
                "relation_desc": "广东省佛山市高明区"
            },
            {
                "code": "1907",
                "parentcode": "19",
                "parenttext": "广东省",
                "text": "江门市",
                "level": 2,
                "order": 2019,
                "relation_code": "440700",
                "relation_desc": "广东省江门市"
            },
            {
                "code": "190701",
                "parentcode": "1907",
                "parenttext": "江门市",
                "text": "蓬江区",
                "level": 3,
                "order": 2020,
                "relation_code": "440703",
                "relation_desc": "广东省江门市蓬江区"
            },
            {
                "code": "190702",
                "parentcode": "1907",
                "parenttext": "江门市",
                "text": "江海区",
                "level": 3,
                "order": 2021,
                "relation_code": "440704",
                "relation_desc": "广东省江门市江海区"
            },
            {
                "code": "190703",
                "parentcode": "1907",
                "parenttext": "江门市",
                "text": "新会区",
                "level": 3,
                "order": 2022,
                "relation_code": "440705",
                "relation_desc": "广东省江门市新会区"
            },
            {
                "code": "190704",
                "parentcode": "1907",
                "parenttext": "江门市",
                "text": "台山市",
                "level": 3,
                "order": 2023,
                "relation_code": "440781",
                "relation_desc": "广东省台山市"
            },
            {
                "code": "190705",
                "parentcode": "1907",
                "parenttext": "江门市",
                "text": "开平市",
                "level": 3,
                "order": 2024,
                "relation_code": "440783",
                "relation_desc": "广东省开平市"
            },
            {
                "code": "190706",
                "parentcode": "1907",
                "parenttext": "江门市",
                "text": "鹤山市",
                "level": 3,
                "order": 2025,
                "relation_code": "440784",
                "relation_desc": "广东省鹤山市"
            },
            {
                "code": "190707",
                "parentcode": "1907",
                "parenttext": "江门市",
                "text": "恩平市",
                "level": 3,
                "order": 2026,
                "relation_code": "440785",
                "relation_desc": "广东省恩平市"
            },
            {
                "code": "1908",
                "parentcode": "19",
                "parenttext": "广东省",
                "text": "湛江市",
                "level": 2,
                "order": 2027,
                "relation_code": "440800",
                "relation_desc": "广东省湛江市"
            },
            {
                "code": "190801",
                "parentcode": "1908",
                "parenttext": "湛江市",
                "text": "赤坎区",
                "level": 3,
                "order": 2028,
                "relation_code": "440802",
                "relation_desc": "广东省湛江市赤坎区"
            },
            {
                "code": "190802",
                "parentcode": "1908",
                "parenttext": "湛江市",
                "text": "霞山区",
                "level": 3,
                "order": 2029,
                "relation_code": "440803",
                "relation_desc": "广东省湛江市霞山区"
            },
            {
                "code": "190803",
                "parentcode": "1908",
                "parenttext": "湛江市",
                "text": "坡头区",
                "level": 3,
                "order": 2030,
                "relation_code": "440804",
                "relation_desc": "广东省湛江市坡头区"
            },
            {
                "code": "190804",
                "parentcode": "1908",
                "parenttext": "湛江市",
                "text": "麻章区",
                "level": 3,
                "order": 2031,
                "relation_code": "440811",
                "relation_desc": "广东省湛江市麻章区"
            },
            {
                "code": "190805",
                "parentcode": "1908",
                "parenttext": "湛江市",
                "text": "遂溪县",
                "level": 3,
                "order": 2032,
                "relation_code": "440823",
                "relation_desc": "广东省遂溪县"
            },
            {
                "code": "190806",
                "parentcode": "1908",
                "parenttext": "湛江市",
                "text": "徐闻县",
                "level": 3,
                "order": 2033,
                "relation_code": "440825",
                "relation_desc": "广东省徐闻县"
            },
            {
                "code": "190807",
                "parentcode": "1908",
                "parenttext": "湛江市",
                "text": "廉江市",
                "level": 3,
                "order": 2034,
                "relation_code": "440881",
                "relation_desc": "广东省廉江市"
            },
            {
                "code": "190808",
                "parentcode": "1908",
                "parenttext": "湛江市",
                "text": "雷州市",
                "level": 3,
                "order": 2035,
                "relation_code": "440882",
                "relation_desc": "广东省雷州市"
            },
            {
                "code": "190809",
                "parentcode": "1908",
                "parenttext": "湛江市",
                "text": "吴川市",
                "level": 3,
                "order": 2036,
                "relation_code": "440883",
                "relation_desc": "广东省吴川市"
            },
            {
                "code": "1909",
                "parentcode": "19",
                "parenttext": "广东省",
                "text": "茂名市",
                "level": 2,
                "order": 2037,
                "relation_code": "440900",
                "relation_desc": "广东省茂名市"
            },
            {
                "code": "190901",
                "parentcode": "1909",
                "parenttext": "茂名市",
                "text": "茂南区",
                "level": 3,
                "order": 2038,
                "relation_code": "440902",
                "relation_desc": "广东省茂名市茂南区"
            },
            {
                "code": "190902",
                "parentcode": "1909",
                "parenttext": "茂名市",
                "text": "茂港区",
                "level": 3,
                "order": 2039,
                "relation_code": "440901",
                "relation_desc": "广东省茂名市茂港区"
            },
            {
                "code": "190903",
                "parentcode": "1909",
                "parenttext": "茂名市",
                "text": "电白区",
                "level": 3,
                "order": 2040,
                "relation_code": "440904",
                "relation_desc": "广东省茂名市电白区"
            },
            {
                "code": "190904",
                "parentcode": "1909",
                "parenttext": "茂名市",
                "text": "高州市",
                "level": 3,
                "order": 2041,
                "relation_code": "440981",
                "relation_desc": "广东省高州市"
            },
            {
                "code": "190905",
                "parentcode": "1909",
                "parenttext": "茂名市",
                "text": "化州市",
                "level": 3,
                "order": 2042,
                "relation_code": "440982",
                "relation_desc": "广东省化州市"
            },
            {
                "code": "190906",
                "parentcode": "1909",
                "parenttext": "茂名市",
                "text": "信宜市",
                "level": 3,
                "order": 2043,
                "relation_code": "440983",
                "relation_desc": "广东省信宜市"
            },
            {
                "code": "1910",
                "parentcode": "19",
                "parenttext": "广东省",
                "text": "肇庆市",
                "level": 2,
                "order": 2044,
                "relation_code": "441200",
                "relation_desc": "广东省肇庆市"
            },
            {
                "code": "191001",
                "parentcode": "1910",
                "parenttext": "肇庆市",
                "text": "端州区",
                "level": 3,
                "order": 2045,
                "relation_code": "441202",
                "relation_desc": "广东省肇庆市端州区"
            },
            {
                "code": "191002",
                "parentcode": "1910",
                "parenttext": "肇庆市",
                "text": "鼎湖区",
                "level": 3,
                "order": 2046,
                "relation_code": "441203",
                "relation_desc": "广东省肇庆市鼎湖区"
            },
            {
                "code": "191003",
                "parentcode": "1910",
                "parenttext": "肇庆市",
                "text": "广宁县",
                "level": 3,
                "order": 2047,
                "relation_code": "441223",
                "relation_desc": "广东省广宁县"
            },
            {
                "code": "191004",
                "parentcode": "1910",
                "parenttext": "肇庆市",
                "text": "怀集县",
                "level": 3,
                "order": 2048,
                "relation_code": "441224",
                "relation_desc": "广东省怀集县"
            },
            {
                "code": "191005",
                "parentcode": "1910",
                "parenttext": "肇庆市",
                "text": "封开县",
                "level": 3,
                "order": 2049,
                "relation_code": "441225",
                "relation_desc": "广东省封开县"
            },
            {
                "code": "191006",
                "parentcode": "1910",
                "parenttext": "肇庆市",
                "text": "德庆县",
                "level": 3,
                "order": 2050,
                "relation_code": "441226",
                "relation_desc": "广东省德庆县"
            },
            {
                "code": "191007",
                "parentcode": "1910",
                "parenttext": "肇庆市",
                "text": "高要区",
                "level": 3,
                "order": 2051,
                "relation_code": "441204",
                "relation_desc": "广东省肇庆市高要区"
            },
            {
                "code": "191008",
                "parentcode": "1910",
                "parenttext": "肇庆市",
                "text": "四会市",
                "level": 3,
                "order": 2052,
                "relation_code": "441284",
                "relation_desc": "广东省四会市"
            },
            {
                "code": "1911",
                "parentcode": "19",
                "parenttext": "广东省",
                "text": "惠州市",
                "level": 2,
                "order": 2053,
                "relation_code": "441300",
                "relation_desc": "广东省惠州市"
            },
            {
                "code": "191101",
                "parentcode": "1911",
                "parenttext": "惠州市",
                "text": "惠城区",
                "level": 3,
                "order": 2054,
                "relation_code": "441302",
                "relation_desc": "广东省惠州市惠城区"
            },
            {
                "code": "191102",
                "parentcode": "1911",
                "parenttext": "惠州市",
                "text": "惠阳区",
                "level": 3,
                "order": 2055,
                "relation_code": "441303",
                "relation_desc": "广东省惠州市惠阳区"
            },
            {
                "code": "191103",
                "parentcode": "1911",
                "parenttext": "惠州市",
                "text": "博罗县",
                "level": 3,
                "order": 2056,
                "relation_code": "441322",
                "relation_desc": "广东省博罗县"
            },
            {
                "code": "191104",
                "parentcode": "1911",
                "parenttext": "惠州市",
                "text": "惠东县",
                "level": 3,
                "order": 2057,
                "relation_code": "441323",
                "relation_desc": "广东省惠东县"
            },
            {
                "code": "191105",
                "parentcode": "1911",
                "parenttext": "惠州市",
                "text": "龙门县",
                "level": 3,
                "order": 2058,
                "relation_code": "441324",
                "relation_desc": "广东省龙门县"
            },
            {
                "code": "1912",
                "parentcode": "19",
                "parenttext": "广东省",
                "text": "梅州市",
                "level": 2,
                "order": 2059,
                "relation_code": "441400",
                "relation_desc": "广东省梅州市"
            },
            {
                "code": "191201",
                "parentcode": "1912",
                "parenttext": "梅州市",
                "text": "梅江区",
                "level": 3,
                "order": 2060,
                "relation_code": "441402",
                "relation_desc": "广东省梅州市梅江区"
            },
            {
                "code": "191202",
                "parentcode": "1912",
                "parenttext": "梅州市",
                "text": "梅县区",
                "level": 3,
                "order": 2061,
                "relation_code": "441403",
                "relation_desc": "广东省梅州市梅县区"
            },
            {
                "code": "191203",
                "parentcode": "1912",
                "parenttext": "梅州市",
                "text": "大埔县",
                "level": 3,
                "order": 2062,
                "relation_code": "441422",
                "relation_desc": "广东省大埔县"
            },
            {
                "code": "191204",
                "parentcode": "1912",
                "parenttext": "梅州市",
                "text": "丰顺县",
                "level": 3,
                "order": 2063,
                "relation_code": "441423",
                "relation_desc": "广东省丰顺县"
            },
            {
                "code": "191205",
                "parentcode": "1912",
                "parenttext": "梅州市",
                "text": "五华县",
                "level": 3,
                "order": 2064,
                "relation_code": "441424",
                "relation_desc": "广东省五华县"
            },
            {
                "code": "191206",
                "parentcode": "1912",
                "parenttext": "梅州市",
                "text": "平远县",
                "level": 3,
                "order": 2065,
                "relation_code": "441426",
                "relation_desc": "广东省平远县"
            },
            {
                "code": "191207",
                "parentcode": "1912",
                "parenttext": "梅州市",
                "text": "蕉岭县",
                "level": 3,
                "order": 2066,
                "relation_code": "441427",
                "relation_desc": "广东省蕉岭县"
            },
            {
                "code": "191208",
                "parentcode": "1912",
                "parenttext": "梅州市",
                "text": "兴宁市",
                "level": 3,
                "order": 2067,
                "relation_code": "441481",
                "relation_desc": "广东省兴宁市"
            },
            {
                "code": "1913",
                "parentcode": "19",
                "parenttext": "广东省",
                "text": "汕尾市",
                "level": 2,
                "order": 2068,
                "relation_code": "441500",
                "relation_desc": "广东省汕尾市"
            },
            {
                "code": "191301",
                "parentcode": "1913",
                "parenttext": "汕尾市",
                "text": "市城区",
                "level": 3,
                "order": 2069,
                "relation_code": "441502",
                "relation_desc": "广东省汕尾市城区"
            },
            {
                "code": "191302",
                "parentcode": "1913",
                "parenttext": "汕尾市",
                "text": "海丰县",
                "level": 3,
                "order": 2070,
                "relation_code": "441521",
                "relation_desc": "广东省海丰县"
            },
            {
                "code": "191303",
                "parentcode": "1913",
                "parenttext": "汕尾市",
                "text": "陆河县",
                "level": 3,
                "order": 2071,
                "relation_code": "441523",
                "relation_desc": "广东省陆河县"
            },
            {
                "code": "191304",
                "parentcode": "1913",
                "parenttext": "汕尾市",
                "text": "陆丰市",
                "level": 3,
                "order": 2072,
                "relation_code": "441581",
                "relation_desc": "广东省陆丰市"
            },
            {
                "code": "1914",
                "parentcode": "19",
                "parenttext": "广东省",
                "text": "河源市",
                "level": 2,
                "order": 2073,
                "relation_code": "441600",
                "relation_desc": "广东省河源市"
            },
            {
                "code": "191401",
                "parentcode": "1914",
                "parenttext": "河源市",
                "text": "源城区",
                "level": 3,
                "order": 2074,
                "relation_code": "441602",
                "relation_desc": "广东省河源市源城区"
            },
            {
                "code": "191402",
                "parentcode": "1914",
                "parenttext": "河源市",
                "text": "紫金县",
                "level": 3,
                "order": 2075,
                "relation_code": "441621",
                "relation_desc": "广东省紫金县"
            },
            {
                "code": "191403",
                "parentcode": "1914",
                "parenttext": "河源市",
                "text": "龙川县",
                "level": 3,
                "order": 2076,
                "relation_code": "441622",
                "relation_desc": "广东省龙川县"
            },
            {
                "code": "191404",
                "parentcode": "1914",
                "parenttext": "河源市",
                "text": "连平县",
                "level": 3,
                "order": 2077,
                "relation_code": "441623",
                "relation_desc": "广东省连平县"
            },
            {
                "code": "191405",
                "parentcode": "1914",
                "parenttext": "河源市",
                "text": "和平县",
                "level": 3,
                "order": 2078,
                "relation_code": "441624",
                "relation_desc": "广东省和平县"
            },
            {
                "code": "191406",
                "parentcode": "1914",
                "parenttext": "河源市",
                "text": "东源县",
                "level": 3,
                "order": 2079,
                "relation_code": "441625",
                "relation_desc": "广东省东源县"
            },
            {
                "code": "1915",
                "parentcode": "19",
                "parenttext": "广东省",
                "text": "阳江市",
                "level": 2,
                "order": 2080,
                "relation_code": "441700",
                "relation_desc": "广东省阳江市"
            },
            {
                "code": "191501",
                "parentcode": "1915",
                "parenttext": "阳江市",
                "text": "江城区",
                "level": 3,
                "order": 2081,
                "relation_code": "441702",
                "relation_desc": "广东省阳江市江城区"
            },
            {
                "code": "191502",
                "parentcode": "1915",
                "parenttext": "阳江市",
                "text": "阳西县",
                "level": 3,
                "order": 2082,
                "relation_code": "441721",
                "relation_desc": "广东省阳西县"
            },
            {
                "code": "191503",
                "parentcode": "1915",
                "parenttext": "阳江市",
                "text": "阳东区",
                "level": 3,
                "order": 2083,
                "relation_code": "441701",
                "relation_desc": "广东省阳江市"
            },
            {
                "code": "191504",
                "parentcode": "1915",
                "parenttext": "阳江市",
                "text": "阳春市",
                "level": 3,
                "order": 2084,
                "relation_code": "441781",
                "relation_desc": "广东省阳春市"
            },
            {
                "code": "1916",
                "parentcode": "19",
                "parenttext": "广东省",
                "text": "清远市",
                "level": 2,
                "order": 2085,
                "relation_code": "441800",
                "relation_desc": "广东省清远市"
            },
            {
                "code": "191601",
                "parentcode": "1916",
                "parenttext": "清远市",
                "text": "清城区",
                "level": 3,
                "order": 2086,
                "relation_code": "441802",
                "relation_desc": "广东省清远市清城区"
            },
            {
                "code": "191602",
                "parentcode": "1916",
                "parenttext": "清远市",
                "text": "佛冈县",
                "level": 3,
                "order": 2087,
                "relation_code": "441821",
                "relation_desc": "广东省佛冈县"
            },
            {
                "code": "191603",
                "parentcode": "1916",
                "parenttext": "清远市",
                "text": "阳山县",
                "level": 3,
                "order": 2088,
                "relation_code": "441823",
                "relation_desc": "广东省阳山县"
            },
            {
                "code": "191604",
                "parentcode": "1916",
                "parenttext": "清远市",
                "text": "连山壮族瑶族自治县",
                "level": 3,
                "order": 2089,
                "relation_code": "441825",
                "relation_desc": "广东省连山壮族瑶族自治县"
            },
            {
                "code": "191605",
                "parentcode": "1916",
                "parenttext": "清远市",
                "text": "连南瑶族自治县",
                "level": 3,
                "order": 2090,
                "relation_code": "441826",
                "relation_desc": "广东省连南瑶族自治县"
            },
            {
                "code": "191606",
                "parentcode": "1916",
                "parenttext": "清远市",
                "text": "清新区",
                "level": 3,
                "order": 2091,
                "relation_code": "441803",
                "relation_desc": "广东省清远市清新区"
            },
            {
                "code": "191607",
                "parentcode": "1916",
                "parenttext": "清远市",
                "text": "英德市",
                "level": 3,
                "order": 2092,
                "relation_code": "441881",
                "relation_desc": "广东省英德市"
            },
            {
                "code": "191608",
                "parentcode": "1916",
                "parenttext": "清远市",
                "text": "连州市",
                "level": 3,
                "order": 2093,
                "relation_code": "441882",
                "relation_desc": "广东省连州市"
            },
            {
                "code": "1917",
                "parentcode": "19",
                "parenttext": "广东省",
                "text": "东莞市",
                "level": 2,
                "order": 2094,
                "relation_code": "441900",
                "relation_desc": "广东省东莞市"
            },
            {
                "code": "191701",
                "parentcode": "1917",
                "parenttext": "东莞市",
                "text": "莞城区",
                "level": 3,
                "order": 2095,
                "relation_code": "441900",
                "relation_desc": "广东省东莞市"
            },
            {
                "code": "191702",
                "parentcode": "1917",
                "parenttext": "东莞市",
                "text": "南城区",
                "level": 3,
                "order": 2096,
                "relation_code": "441900",
                "relation_desc": "广东省东莞市"
            },
            {
                "code": "191703",
                "parentcode": "1917",
                "parenttext": "东莞市",
                "text": "东城区",
                "level": 3,
                "order": 2097,
                "relation_code": "441900",
                "relation_desc": "广东省东莞市"
            },
            {
                "code": "191704",
                "parentcode": "1917",
                "parenttext": "东莞市",
                "text": "万江区",
                "level": 3,
                "order": 2098,
                "relation_code": "441900",
                "relation_desc": "广东省东莞市"
            },
            {
                "code": "1918",
                "parentcode": "19",
                "parenttext": "广东省",
                "text": "中山市",
                "level": 2,
                "order": 2099,
                "relation_code": "442000",
                "relation_desc": "广东省中山市"
            },
            {
                "code": "191707",
                "parentcode": "1917",
                "parenttext": "东莞市",
                "text": "厚街镇",
                "level": 3,
                "order": 2099,
                "relation_code": "441900",
                "relation_desc": "广东省厚街镇"
            },
            {
                "code": "191801",
                "parentcode": "1918",
                "parenttext": "中山市",
                "text": "中山市",
                "level": 3,
                "order": 2100,
                "relation_code": "442000",
                "relation_desc": "广东省中山市"
            },
            {
                "code": "191708",
                "parentcode": "1917",
                "parenttext": "东莞市",
                "text": "长安镇",
                "level": 3,
                "order": 2100,
                "relation_code": "441900",
                "relation_desc": "广东省长安镇"
            },
            {
                "code": "191802",
                "parentcode": "1918",
                "parenttext": "中山市",
                "text": "石岐街道",
                "level": 3,
                "order": 2100,
                "relation_code": "442000",
                "relation_desc": "广东省中山市"
            },
            {
                "code": "1919",
                "parentcode": "19",
                "parenttext": "广东省",
                "text": "潮州市",
                "level": 2,
                "order": 2101,
                "relation_code": "445100",
                "relation_desc": "广东省潮州市"
            },
            {
                "code": "191709",
                "parentcode": "1917",
                "parenttext": "东莞市",
                "text": "樟木头镇",
                "level": 3,
                "order": 2101,
                "relation_code": "441900",
                "relation_desc": "广东省樟木头镇"
            },
            {
                "code": "191803",
                "parentcode": "1918",
                "parenttext": "中山市",
                "text": "东区街道",
                "level": 3,
                "order": 2101,
                "relation_code": "442000",
                "relation_desc": "广东省中山市"
            },
            {
                "code": "191901",
                "parentcode": "1919",
                "parenttext": "潮州市",
                "text": "湘桥区",
                "level": 3,
                "order": 2102,
                "relation_code": "445102",
                "relation_desc": "广东省潮州市湘桥区"
            },
            {
                "code": "191804",
                "parentcode": "1918",
                "parenttext": "中山市",
                "text": "中山港街道",
                "level": 3,
                "order": 2102,
                "relation_code": "442000",
                "relation_desc": "广东省中山市"
            },
            {
                "code": "191710",
                "parentcode": "1917",
                "parenttext": "东莞市",
                "text": "东城街道",
                "level": 3,
                "order": 2102,
                "relation_code": "441900",
                "relation_desc": "广东省东莞市"
            },
            {
                "code": "191902",
                "parentcode": "1919",
                "parenttext": "潮州市",
                "text": "潮安区",
                "level": 3,
                "order": 2103,
                "relation_code": "445103",
                "relation_desc": "广东省潮州市潮安区"
            },
            {
                "code": "191711",
                "parentcode": "1917",
                "parenttext": "东莞市",
                "text": "南城街道",
                "level": 3,
                "order": 2103,
                "relation_code": "441900",
                "relation_desc": "广东省东莞市"
            },
            {
                "code": "191805",
                "parentcode": "1918",
                "parenttext": "中山市",
                "text": "西区街道",
                "level": 3,
                "order": 2103,
                "relation_code": "442000",
                "relation_desc": "广东省中山市"
            },
            {
                "code": "191903",
                "parentcode": "1919",
                "parenttext": "潮州市",
                "text": "饶平县",
                "level": 3,
                "order": 2104,
                "relation_code": "445122",
                "relation_desc": "广东省饶平县"
            },
            {
                "code": "191806",
                "parentcode": "1918",
                "parenttext": "中山市",
                "text": "南区街道",
                "level": 3,
                "order": 2104,
                "relation_code": "442000",
                "relation_desc": "广东省中山市"
            },
            {
                "code": "191712",
                "parentcode": "1917",
                "parenttext": "东莞市",
                "text": "万江街道",
                "level": 3,
                "order": 2104,
                "relation_code": "441900",
                "relation_desc": "广东省东莞市"
            },
            {
                "code": "1920",
                "parentcode": "19",
                "parenttext": "广东省",
                "text": "揭阳市",
                "level": 2,
                "order": 2105,
                "relation_code": "445200",
                "relation_desc": "广东省揭阳市"
            },
            {
                "code": "191713",
                "parentcode": "1917",
                "parenttext": "东莞市",
                "text": "莞城街道",
                "level": 3,
                "order": 2105,
                "relation_code": "441900",
                "relation_desc": "广东省东莞市"
            },
            {
                "code": "191807",
                "parentcode": "1918",
                "parenttext": "中山市",
                "text": "五桂山街道",
                "level": 3,
                "order": 2105,
                "relation_code": "442000",
                "relation_desc": "广东省中山市"
            },
            {
                "code": "192001",
                "parentcode": "1920",
                "parenttext": "揭阳市",
                "text": "榕城区",
                "level": 3,
                "order": 2106,
                "relation_code": "445202",
                "relation_desc": "广东省揭阳市榕城区"
            },
            {
                "code": "191808",
                "parentcode": "1918",
                "parenttext": "中山市",
                "text": "小榄镇",
                "level": 3,
                "order": 2106,
                "relation_code": "442000",
                "relation_desc": "广东省中山市"
            },
            {
                "code": "191714",
                "parentcode": "1917",
                "parenttext": "东莞市",
                "text": "石碣镇",
                "level": 3,
                "order": 2106,
                "relation_code": "441900",
                "relation_desc": "广东省东莞市"
            },
            {
                "code": "192002",
                "parentcode": "1920",
                "parenttext": "揭阳市",
                "text": "揭东区",
                "level": 3,
                "order": 2107,
                "relation_code": "445203",
                "relation_desc": "广东省揭阳市揭东区"
            },
            {
                "code": "191809",
                "parentcode": "1918",
                "parenttext": "中山市",
                "text": "黄圃镇",
                "level": 3,
                "order": 2107,
                "relation_code": "442000",
                "relation_desc": "广东省中山市"
            },
            {
                "code": "191715",
                "parentcode": "1917",
                "parenttext": "东莞市",
                "text": "石龙镇",
                "level": 3,
                "order": 2107,
                "relation_code": "441900",
                "relation_desc": "广东省东莞市"
            },
            {
                "code": "192003",
                "parentcode": "1920",
                "parenttext": "揭阳市",
                "text": "揭西县",
                "level": 3,
                "order": 2108,
                "relation_code": "445222",
                "relation_desc": "广东省揭西县"
            },
            {
                "code": "191810",
                "parentcode": "1918",
                "parenttext": "中山市",
                "text": "民众镇",
                "level": 3,
                "order": 2108,
                "relation_code": "442000",
                "relation_desc": "广东省中山市"
            },
            {
                "code": "191716",
                "parentcode": "1917",
                "parenttext": "东莞市",
                "text": "茶山镇",
                "level": 3,
                "order": 2108,
                "relation_code": "441900",
                "relation_desc": "广东省东莞市"
            },
            {
                "code": "192004",
                "parentcode": "1920",
                "parenttext": "揭阳市",
                "text": "惠来县",
                "level": 3,
                "order": 2109,
                "relation_code": "445224",
                "relation_desc": "广东省惠来县"
            },
            {
                "code": "191811",
                "parentcode": "1918",
                "parenttext": "中山市",
                "text": "东凤镇",
                "level": 3,
                "order": 2109,
                "relation_code": "442000",
                "relation_desc": "广东省中山市"
            },
            {
                "code": "191717",
                "parentcode": "1917",
                "parenttext": "东莞市",
                "text": "石排镇",
                "level": 3,
                "order": 2109,
                "relation_code": "441900",
                "relation_desc": "广东省东莞市"
            },
            {
                "code": "192005",
                "parentcode": "1920",
                "parenttext": "揭阳市",
                "text": "普宁市",
                "level": 3,
                "order": 2110,
                "relation_code": "445281",
                "relation_desc": "广东省普宁市"
            },
            {
                "code": "191718",
                "parentcode": "1917",
                "parenttext": "东莞市",
                "text": "企石镇",
                "level": 3,
                "order": 2110,
                "relation_code": "441900",
                "relation_desc": "广东省东莞市"
            },
            {
                "code": "191812",
                "parentcode": "1918",
                "parenttext": "中山市",
                "text": "东升镇",
                "level": 3,
                "order": 2110,
                "relation_code": "442000",
                "relation_desc": "广东省中山市"
            },
            {
                "code": "1921",
                "parentcode": "19",
                "parenttext": "广东省",
                "text": "云浮市",
                "level": 2,
                "order": 2111,
                "relation_code": "445300",
                "relation_desc": "广东省云浮市"
            },
            {
                "code": "191719",
                "parentcode": "1917",
                "parenttext": "东莞市",
                "text": "横沥镇",
                "level": 3,
                "order": 2111,
                "relation_code": "441900",
                "relation_desc": "广东省东莞市"
            },
            {
                "code": "191813",
                "parentcode": "1918",
                "parenttext": "中山市",
                "text": "古镇镇",
                "level": 3,
                "order": 2111,
                "relation_code": "442000",
                "relation_desc": "广东省中山市"
            },
            {
                "code": "192101",
                "parentcode": "1921",
                "parenttext": "云浮市",
                "text": "云城区",
                "level": 3,
                "order": 2112,
                "relation_code": "445302",
                "relation_desc": "广东省云浮市云城区"
            },
            {
                "code": "191720",
                "parentcode": "1917",
                "parenttext": "东莞市",
                "text": "桥头镇",
                "level": 3,
                "order": 2112,
                "relation_code": "441900",
                "relation_desc": "广东省东莞市"
            },
            {
                "code": "191814",
                "parentcode": "1918",
                "parenttext": "中山市",
                "text": "沙溪镇",
                "level": 3,
                "order": 2112,
                "relation_code": "442000",
                "relation_desc": "广东省中山市"
            },
            {
                "code": "192102",
                "parentcode": "1921",
                "parenttext": "云浮市",
                "text": "新兴县",
                "level": 3,
                "order": 2113,
                "relation_code": "445321",
                "relation_desc": "广东省新兴县"
            },
            {
                "code": "191815",
                "parentcode": "1918",
                "parenttext": "中山市",
                "text": "坦洲镇",
                "level": 3,
                "order": 2113,
                "relation_code": "442000",
                "relation_desc": "广东省中山市"
            },
            {
                "code": "191721",
                "parentcode": "1917",
                "parenttext": "东莞市",
                "text": "谢岗镇",
                "level": 3,
                "order": 2113,
                "relation_code": "441900",
                "relation_desc": "广东省东莞市"
            },
            {
                "code": "192103",
                "parentcode": "1921",
                "parenttext": "云浮市",
                "text": "郁南县",
                "level": 3,
                "order": 2114,
                "relation_code": "445322",
                "relation_desc": "广东省郁南县"
            },
            {
                "code": "191722",
                "parentcode": "1917",
                "parenttext": "东莞市",
                "text": "东坑镇",
                "level": 3,
                "order": 2114,
                "relation_code": "441900",
                "relation_desc": "广东省东莞市"
            },
            {
                "code": "191816",
                "parentcode": "1918",
                "parenttext": "中山市",
                "text": "港口镇",
                "level": 3,
                "order": 2114,
                "relation_code": "442000",
                "relation_desc": "广东省中山市"
            },
            {
                "code": "192104",
                "parentcode": "1921",
                "parenttext": "云浮市",
                "text": "云安区",
                "level": 3,
                "order": 2115,
                "relation_code": "445301",
                "relation_desc": "广东省云安区"
            },
            {
                "code": "191723",
                "parentcode": "1917",
                "parenttext": "东莞市",
                "text": "常平镇",
                "level": 3,
                "order": 2115,
                "relation_code": "441900",
                "relation_desc": "广东省东莞市"
            },
            {
                "code": "191817",
                "parentcode": "1918",
                "parenttext": "中山市",
                "text": "三角镇",
                "level": 3,
                "order": 2115,
                "relation_code": "442000",
                "relation_desc": "广东省中山市"
            },
            {
                "code": "192105",
                "parentcode": "1921",
                "parenttext": "云浮市",
                "text": "罗定市",
                "level": 3,
                "order": 2116,
                "relation_code": "445381",
                "relation_desc": "广东省罗定市"
            },
            {
                "code": "191724",
                "parentcode": "1917",
                "parenttext": "东莞市",
                "text": "寮步镇",
                "level": 3,
                "order": 2116,
                "relation_code": "441900",
                "relation_desc": "广东省东莞市"
            },
            {
                "code": "191818",
                "parentcode": "1918",
                "parenttext": "中山市",
                "text": "横栏镇",
                "level": 3,
                "order": 2116,
                "relation_code": "442000",
                "relation_desc": "广东省中山市"
            },
            {
                "code": "20",
                "parentcode": "0",
                "parenttext": "0",
                "text": "广西壮族自治区",
                "level": 1,
                "order": 2117,
                "relation_code": "",
                "relation_desc": "广西壮族自治区"
            },
            {
                "code": "191819",
                "parentcode": "1918",
                "parenttext": "中山市",
                "text": "南头镇",
                "level": 3,
                "order": 2117,
                "relation_code": "442000",
                "relation_desc": "广东省中山市"
            },
            {
                "code": "191725",
                "parentcode": "1917",
                "parenttext": "东莞市",
                "text": "大朗镇",
                "level": 3,
                "order": 2117,
                "relation_code": "441900",
                "relation_desc": "广东省东莞市"
            },
            {
                "code": "2001",
                "parentcode": "20",
                "parenttext": "广西壮族自治区",
                "text": "南宁市",
                "level": 2,
                "order": 2118,
                "relation_code": "450100",
                "relation_desc": "广西南宁市"
            },
            {
                "code": "191820",
                "parentcode": "1918",
                "parenttext": "中山市",
                "text": "阜沙镇",
                "level": 3,
                "order": 2118,
                "relation_code": "442000",
                "relation_desc": "广东省中山市"
            },
            {
                "code": "191726",
                "parentcode": "1917",
                "parenttext": "东莞市",
                "text": "黄江镇",
                "level": 3,
                "order": 2118,
                "relation_code": "441900",
                "relation_desc": "广东省东莞市"
            },
            {
                "code": "200101",
                "parentcode": "2001",
                "parenttext": "南宁市",
                "text": "兴宁区",
                "level": 3,
                "order": 2119,
                "relation_code": "450102",
                "relation_desc": "广西南宁市兴宁区"
            },
            {
                "code": "191727",
                "parentcode": "1917",
                "parenttext": "东莞市",
                "text": "清溪镇",
                "level": 3,
                "order": 2119,
                "relation_code": "441900",
                "relation_desc": "广东省东莞市"
            },
            {
                "code": "191821",
                "parentcode": "1918",
                "parenttext": "中山市",
                "text": "南朗镇",
                "level": 3,
                "order": 2119,
                "relation_code": "442000",
                "relation_desc": "广东省中山市"
            },
            {
                "code": "200102",
                "parentcode": "2001",
                "parenttext": "南宁市",
                "text": "青秀区",
                "level": 3,
                "order": 2120,
                "relation_code": "450103",
                "relation_desc": "广西南宁市青秀区"
            },
            {
                "code": "191728",
                "parentcode": "1917",
                "parenttext": "东莞市",
                "text": "凤岗镇",
                "level": 3,
                "order": 2120,
                "relation_code": "441900",
                "relation_desc": "广东省东莞市"
            },
            {
                "code": "191822",
                "parentcode": "1918",
                "parenttext": "中山市",
                "text": "三乡镇",
                "level": 3,
                "order": 2120,
                "relation_code": "442000",
                "relation_desc": "广东省中山市"
            },
            {
                "code": "200103",
                "parentcode": "2001",
                "parenttext": "南宁市",
                "text": "西乡塘区",
                "level": 3,
                "order": 2121,
                "relation_code": "450107",
                "relation_desc": "广西南宁市西乡塘区"
            },
            {
                "code": "191823",
                "parentcode": "1918",
                "parenttext": "中山市",
                "text": "板芙镇",
                "level": 3,
                "order": 2121,
                "relation_code": "442000",
                "relation_desc": "广东省中山市"
            },
            {
                "code": "191729",
                "parentcode": "1917",
                "parenttext": "东莞市",
                "text": "大岭山镇",
                "level": 3,
                "order": 2121,
                "relation_code": "441900",
                "relation_desc": "广东省东莞市"
            },
            {
                "code": "200104",
                "parentcode": "2001",
                "parenttext": "南宁市",
                "text": "江南区",
                "level": 3,
                "order": 2122,
                "relation_code": "450105",
                "relation_desc": "广西南宁市江南区"
            },
            {
                "code": "191730",
                "parentcode": "1917",
                "parenttext": "东莞市",
                "text": "虎门镇",
                "level": 3,
                "order": 2122,
                "relation_code": "441900",
                "relation_desc": "广东省东莞市"
            },
            {
                "code": "191824",
                "parentcode": "1918",
                "parenttext": "中山市",
                "text": "大涌镇",
                "level": 3,
                "order": 2122,
                "relation_code": "442000",
                "relation_desc": "广东省中山市"
            },
            {
                "code": "200105",
                "parentcode": "2001",
                "parenttext": "南宁市",
                "text": "良庆区",
                "level": 3,
                "order": 2123,
                "relation_code": "450108",
                "relation_desc": "广西南宁市良庆区"
            },
            {
                "code": "191731",
                "parentcode": "1917",
                "parenttext": "东莞市",
                "text": "沙田镇",
                "level": 3,
                "order": 2123,
                "relation_code": "441900",
                "relation_desc": "广东省东莞市"
            },
            {
                "code": "191825",
                "parentcode": "1918",
                "parenttext": "中山市",
                "text": "神湾镇",
                "level": 3,
                "order": 2123,
                "relation_code": "442000",
                "relation_desc": "广东省中山市"
            },
            {
                "code": "200106",
                "parentcode": "2001",
                "parenttext": "南宁市",
                "text": "邕宁区",
                "level": 3,
                "order": 2124,
                "relation_code": "450109",
                "relation_desc": "广西南宁市邕宁区"
            },
            {
                "code": "191732",
                "parentcode": "1917",
                "parenttext": "东莞市",
                "text": "道滘镇",
                "level": 3,
                "order": 2124,
                "relation_code": "441900",
                "relation_desc": "广东省东莞市"
            },
            {
                "code": "200107",
                "parentcode": "2001",
                "parenttext": "南宁市",
                "text": "武鸣区",
                "level": 3,
                "order": 2125,
                "relation_code": "450110",
                "relation_desc": "广西南宁市武鸣区"
            },
            {
                "code": "191733",
                "parentcode": "1917",
                "parenttext": "东莞市",
                "text": "洪梅镇",
                "level": 3,
                "order": 2125,
                "relation_code": "441900",
                "relation_desc": "广东省东莞市"
            },
            {
                "code": "200108",
                "parentcode": "2001",
                "parenttext": "南宁市",
                "text": "隆安县",
                "level": 3,
                "order": 2126,
                "relation_code": "450123",
                "relation_desc": "广西隆安县"
            },
            {
                "code": "191734",
                "parentcode": "1917",
                "parenttext": "东莞市",
                "text": "望牛墩镇",
                "level": 3,
                "order": 2126,
                "relation_code": "441900",
                "relation_desc": "广东省东莞市"
            },
            {
                "code": "200109",
                "parentcode": "2001",
                "parenttext": "南宁市",
                "text": "马山县",
                "level": 3,
                "order": 2127,
                "relation_code": "450124",
                "relation_desc": "广西马山县"
            },
            {
                "code": "191735",
                "parentcode": "1917",
                "parenttext": "东莞市",
                "text": "中堂镇",
                "level": 3,
                "order": 2127,
                "relation_code": "441900",
                "relation_desc": "广东省东莞市"
            },
            {
                "code": "200110",
                "parentcode": "2001",
                "parenttext": "南宁市",
                "text": "上林县",
                "level": 3,
                "order": 2128,
                "relation_code": "450125",
                "relation_desc": "广西上林县"
            },
            {
                "code": "191736",
                "parentcode": "1917",
                "parenttext": "东莞市",
                "text": "高埗镇",
                "level": 3,
                "order": 2128,
                "relation_code": "441900",
                "relation_desc": "广东省东莞市"
            },
            {
                "code": "200111",
                "parentcode": "2001",
                "parenttext": "南宁市",
                "text": "宾阳县",
                "level": 3,
                "order": 2129,
                "relation_code": "450126",
                "relation_desc": "广西宾阳县"
            },
            {
                "code": "191737",
                "parentcode": "1917",
                "parenttext": "东莞市",
                "text": "松山湖",
                "level": 3,
                "order": 2129,
                "relation_code": "441900",
                "relation_desc": "广东省东莞市"
            },
            {
                "code": "200112",
                "parentcode": "2001",
                "parenttext": "南宁市",
                "text": "横县",
                "level": 3,
                "order": 2130,
                "relation_code": "450127",
                "relation_desc": "广西横县"
            },
            {
                "code": "191738",
                "parentcode": "1917",
                "parenttext": "东莞市",
                "text": "东莞港",
                "level": 3,
                "order": 2130,
                "relation_code": "441900",
                "relation_desc": "广东省东莞市"
            },
            {
                "code": "2002",
                "parentcode": "20",
                "parenttext": "广西壮族自治区",
                "text": "柳州市",
                "level": 2,
                "order": 2131,
                "relation_code": "450200",
                "relation_desc": "广西柳州市"
            },
            {
                "code": "191739",
                "parentcode": "1917",
                "parenttext": "东莞市",
                "text": "东莞生态园",
                "level": 3,
                "order": 2131,
                "relation_code": "441900",
                "relation_desc": "广东省东莞市"
            },
            {
                "code": "200201",
                "parentcode": "2002",
                "parenttext": "柳州市",
                "text": "城中区",
                "level": 3,
                "order": 2132,
                "relation_code": "450202",
                "relation_desc": "广西柳州市城中区"
            },
            {
                "code": "200202",
                "parentcode": "2002",
                "parenttext": "柳州市",
                "text": "鱼峰区",
                "level": 3,
                "order": 2133,
                "relation_code": "450203",
                "relation_desc": "广西柳州市鱼峰区"
            },
            {
                "code": "200203",
                "parentcode": "2002",
                "parenttext": "柳州市",
                "text": "柳南区",
                "level": 3,
                "order": 2134,
                "relation_code": "450204",
                "relation_desc": "广西柳州市柳南区"
            },
            {
                "code": "200204",
                "parentcode": "2002",
                "parenttext": "柳州市",
                "text": "柳北区",
                "level": 3,
                "order": 2135,
                "relation_code": "450205",
                "relation_desc": "广西柳州市柳北区"
            },
            {
                "code": "200205",
                "parentcode": "2002",
                "parenttext": "柳州市",
                "text": "柳江区",
                "level": 3,
                "order": 2136,
                "relation_code": "450206",
                "relation_desc": "广西柳州市柳江区"
            },
            {
                "code": "200206",
                "parentcode": "2002",
                "parenttext": "柳州市",
                "text": "柳城县",
                "level": 3,
                "order": 2137,
                "relation_code": "450222",
                "relation_desc": "广西柳城县"
            },
            {
                "code": "200207",
                "parentcode": "2002",
                "parenttext": "柳州市",
                "text": "鹿寨县",
                "level": 3,
                "order": 2138,
                "relation_code": "450223",
                "relation_desc": "广西鹿寨县"
            },
            {
                "code": "200208",
                "parentcode": "2002",
                "parenttext": "柳州市",
                "text": "融安县",
                "level": 3,
                "order": 2139,
                "relation_code": "450224",
                "relation_desc": "广西融安县"
            },
            {
                "code": "200209",
                "parentcode": "2002",
                "parenttext": "柳州市",
                "text": "融水苗族自治县",
                "level": 3,
                "order": 2140,
                "relation_code": "450225",
                "relation_desc": "广西融水苗族自治县"
            },
            {
                "code": "200210",
                "parentcode": "2002",
                "parenttext": "柳州市",
                "text": "三江侗族自治县",
                "level": 3,
                "order": 2141,
                "relation_code": "450226",
                "relation_desc": "广西三江侗族自治县"
            },
            {
                "code": "2003",
                "parentcode": "20",
                "parenttext": "广西壮族自治区",
                "text": "桂林市",
                "level": 2,
                "order": 2142,
                "relation_code": "450300",
                "relation_desc": "广西桂林市"
            },
            {
                "code": "200301",
                "parentcode": "2003",
                "parenttext": "桂林市",
                "text": "秀峰区",
                "level": 3,
                "order": 2143,
                "relation_code": "450302",
                "relation_desc": "广西桂林市秀峰区"
            },
            {
                "code": "200302",
                "parentcode": "2003",
                "parenttext": "桂林市",
                "text": "叠彩区",
                "level": 3,
                "order": 2144,
                "relation_code": "450303",
                "relation_desc": "广西桂林市叠彩区"
            },
            {
                "code": "200303",
                "parentcode": "2003",
                "parenttext": "桂林市",
                "text": "象山区",
                "level": 3,
                "order": 2145,
                "relation_code": "450304",
                "relation_desc": "广西桂林市象山区"
            },
            {
                "code": "200304",
                "parentcode": "2003",
                "parenttext": "桂林市",
                "text": "七星区",
                "level": 3,
                "order": 2146,
                "relation_code": "450305",
                "relation_desc": "广西桂林市七星区"
            },
            {
                "code": "200305",
                "parentcode": "2003",
                "parenttext": "桂林市",
                "text": "雁山区",
                "level": 3,
                "order": 2147,
                "relation_code": "450311",
                "relation_desc": "广西桂林市雁山区"
            },
            {
                "code": "200306",
                "parentcode": "2003",
                "parenttext": "桂林市",
                "text": "阳朔县",
                "level": 3,
                "order": 2148,
                "relation_code": "450321",
                "relation_desc": "广西阳朔县"
            },
            {
                "code": "200307",
                "parentcode": "2003",
                "parenttext": "桂林市",
                "text": "临桂区",
                "level": 3,
                "order": 2149,
                "relation_code": "450312",
                "relation_desc": "广西桂林市临桂区"
            },
            {
                "code": "200308",
                "parentcode": "2003",
                "parenttext": "桂林市",
                "text": "灵川县",
                "level": 3,
                "order": 2150,
                "relation_code": "450323",
                "relation_desc": "广西灵川县"
            },
            {
                "code": "200309",
                "parentcode": "2003",
                "parenttext": "桂林市",
                "text": "全州县",
                "level": 3,
                "order": 2151,
                "relation_code": "450324",
                "relation_desc": "广西全州县"
            },
            {
                "code": "200310",
                "parentcode": "2003",
                "parenttext": "桂林市",
                "text": "兴安县",
                "level": 3,
                "order": 2152,
                "relation_code": "450325",
                "relation_desc": "广西兴安县"
            },
            {
                "code": "200311",
                "parentcode": "2003",
                "parenttext": "桂林市",
                "text": "永福县",
                "level": 3,
                "order": 2153,
                "relation_code": "450326",
                "relation_desc": "广西永福县"
            },
            {
                "code": "200312",
                "parentcode": "2003",
                "parenttext": "桂林市",
                "text": "灌阳县",
                "level": 3,
                "order": 2154,
                "relation_code": "450327",
                "relation_desc": "广西灌阳县"
            },
            {
                "code": "200313",
                "parentcode": "2003",
                "parenttext": "桂林市",
                "text": "龙胜各族自治县",
                "level": 3,
                "order": 2155,
                "relation_code": "450328",
                "relation_desc": "广西龙胜各族自治县"
            },
            {
                "code": "200314",
                "parentcode": "2003",
                "parenttext": "桂林市",
                "text": "资源县",
                "level": 3,
                "order": 2156,
                "relation_code": "450329",
                "relation_desc": "广西资源县"
            },
            {
                "code": "200315",
                "parentcode": "2003",
                "parenttext": "桂林市",
                "text": "平乐县",
                "level": 3,
                "order": 2157,
                "relation_code": "450330",
                "relation_desc": "广西平乐县"
            },
            {
                "code": "200316",
                "parentcode": "2003",
                "parenttext": "桂林市",
                "text": "荔浦县",
                "level": 3,
                "order": 2158,
                "relation_code": "450301",
                "relation_desc": "广西荔蒲县"
            },
            {
                "code": "200317",
                "parentcode": "2003",
                "parenttext": "桂林市",
                "text": "恭城瑶族自治县",
                "level": 3,
                "order": 2159,
                "relation_code": "450332",
                "relation_desc": "广西恭城瑶族自治县"
            },
            {
                "code": "2004",
                "parentcode": "20",
                "parenttext": "广西壮族自治区",
                "text": "梧州市",
                "level": 2,
                "order": 2160,
                "relation_code": "450400",
                "relation_desc": "广西梧州市"
            },
            {
                "code": "200401",
                "parentcode": "2004",
                "parenttext": "梧州市",
                "text": "万秀区",
                "level": 3,
                "order": 2161,
                "relation_code": "450403",
                "relation_desc": "广西梧州市万秀区"
            },
            {
                "code": "200402",
                "parentcode": "2004",
                "parenttext": "梧州市",
                "text": "龙圩区",
                "level": 3,
                "order": 2162,
                "relation_code": "450406",
                "relation_desc": "广西梧州市龙圩区"
            },
            {
                "code": "200403",
                "parentcode": "2004",
                "parenttext": "梧州市",
                "text": "长洲区",
                "level": 3,
                "order": 2163,
                "relation_code": "450405",
                "relation_desc": "广西梧州市长洲区"
            },
            {
                "code": "200404",
                "parentcode": "2004",
                "parenttext": "梧州市",
                "text": "苍梧县",
                "level": 3,
                "order": 2164,
                "relation_code": "450421",
                "relation_desc": "广西苍梧县"
            },
            {
                "code": "200405",
                "parentcode": "2004",
                "parenttext": "梧州市",
                "text": "藤县",
                "level": 3,
                "order": 2165,
                "relation_code": "450422",
                "relation_desc": "广西藤县"
            },
            {
                "code": "200406",
                "parentcode": "2004",
                "parenttext": "梧州市",
                "text": "蒙山县",
                "level": 3,
                "order": 2166,
                "relation_code": "450423",
                "relation_desc": "广西蒙山县"
            },
            {
                "code": "200407",
                "parentcode": "2004",
                "parenttext": "梧州市",
                "text": "岑溪市",
                "level": 3,
                "order": 2167,
                "relation_code": "450481",
                "relation_desc": "广西岑溪市"
            },
            {
                "code": "2005",
                "parentcode": "20",
                "parenttext": "广西壮族自治区",
                "text": "北海市",
                "level": 2,
                "order": 2168,
                "relation_code": "450500",
                "relation_desc": "广西北海市"
            },
            {
                "code": "200501",
                "parentcode": "2005",
                "parenttext": "北海市",
                "text": "海城区",
                "level": 3,
                "order": 2169,
                "relation_code": "450502",
                "relation_desc": "广西北海市海城区"
            },
            {
                "code": "200502",
                "parentcode": "2005",
                "parenttext": "北海市",
                "text": "银海区",
                "level": 3,
                "order": 2170,
                "relation_code": "450503",
                "relation_desc": "广西北海市银海区"
            },
            {
                "code": "200503",
                "parentcode": "2005",
                "parenttext": "北海市",
                "text": "铁山港区",
                "level": 3,
                "order": 2171,
                "relation_code": "450512",
                "relation_desc": "广西北海市铁山港区"
            },
            {
                "code": "200504",
                "parentcode": "2005",
                "parenttext": "北海市",
                "text": "合浦县",
                "level": 3,
                "order": 2172,
                "relation_code": "450521",
                "relation_desc": "广西合浦县"
            },
            {
                "code": "2006",
                "parentcode": "20",
                "parenttext": "广西壮族自治区",
                "text": "防城港市",
                "level": 2,
                "order": 2173,
                "relation_code": "450600",
                "relation_desc": "广西防城港市"
            },
            {
                "code": "200601",
                "parentcode": "2006",
                "parenttext": "防城港市",
                "text": "港口区",
                "level": 3,
                "order": 2174,
                "relation_code": "450602",
                "relation_desc": "广西防城港市港口区"
            },
            {
                "code": "200602",
                "parentcode": "2006",
                "parenttext": "防城港市",
                "text": "防城区",
                "level": 3,
                "order": 2175,
                "relation_code": "450603",
                "relation_desc": "广西防城港市防城区"
            },
            {
                "code": "200603",
                "parentcode": "2006",
                "parenttext": "防城港市",
                "text": "上思县",
                "level": 3,
                "order": 2176,
                "relation_code": "450621",
                "relation_desc": "广西上思县"
            },
            {
                "code": "200604",
                "parentcode": "2006",
                "parenttext": "防城港市",
                "text": "东兴市",
                "level": 3,
                "order": 2177,
                "relation_code": "450681",
                "relation_desc": "广西东兴市"
            },
            {
                "code": "2007",
                "parentcode": "20",
                "parenttext": "广西壮族自治区",
                "text": "钦州市",
                "level": 2,
                "order": 2178,
                "relation_code": "450700",
                "relation_desc": "广西钦州市"
            },
            {
                "code": "200701",
                "parentcode": "2007",
                "parenttext": "钦州市",
                "text": "钦南区",
                "level": 3,
                "order": 2179,
                "relation_code": "450702",
                "relation_desc": "广西钦州市钦南区"
            },
            {
                "code": "200702",
                "parentcode": "2007",
                "parenttext": "钦州市",
                "text": "钦北区",
                "level": 3,
                "order": 2180,
                "relation_code": "450703",
                "relation_desc": "广西钦州市钦北区"
            },
            {
                "code": "200703",
                "parentcode": "2007",
                "parenttext": "钦州市",
                "text": "灵山县",
                "level": 3,
                "order": 2181,
                "relation_code": "450721",
                "relation_desc": "广西灵山县"
            },
            {
                "code": "200704",
                "parentcode": "2007",
                "parenttext": "钦州市",
                "text": "浦北县",
                "level": 3,
                "order": 2182,
                "relation_code": "450722",
                "relation_desc": "广西浦北县"
            },
            {
                "code": "2008",
                "parentcode": "20",
                "parenttext": "广西壮族自治区",
                "text": "贵港市",
                "level": 2,
                "order": 2183,
                "relation_code": "450800",
                "relation_desc": "广西贵港市"
            },
            {
                "code": "200801",
                "parentcode": "2008",
                "parenttext": "贵港市",
                "text": "港北区",
                "level": 3,
                "order": 2184,
                "relation_code": "450802",
                "relation_desc": "广西贵港市港北区"
            },
            {
                "code": "200802",
                "parentcode": "2008",
                "parenttext": "贵港市",
                "text": "港南区",
                "level": 3,
                "order": 2185,
                "relation_code": "450803",
                "relation_desc": "广西贵港市港南区"
            },
            {
                "code": "200803",
                "parentcode": "2008",
                "parenttext": "贵港市",
                "text": "覃塘区",
                "level": 3,
                "order": 2186,
                "relation_code": "450804",
                "relation_desc": "广西贵港市覃塘区"
            },
            {
                "code": "200804",
                "parentcode": "2008",
                "parenttext": "贵港市",
                "text": "平南县",
                "level": 3,
                "order": 2187,
                "relation_code": "450821",
                "relation_desc": "广西平南县"
            },
            {
                "code": "200805",
                "parentcode": "2008",
                "parenttext": "贵港市",
                "text": "桂平市",
                "level": 3,
                "order": 2188,
                "relation_code": "450881",
                "relation_desc": "广西桂平市"
            },
            {
                "code": "2009",
                "parentcode": "20",
                "parenttext": "广西壮族自治区",
                "text": "玉林市",
                "level": 2,
                "order": 2189,
                "relation_code": "450900",
                "relation_desc": "广西玉林市"
            },
            {
                "code": "200901",
                "parentcode": "2009",
                "parenttext": "玉林市",
                "text": "玉州区",
                "level": 3,
                "order": 2190,
                "relation_code": "450902",
                "relation_desc": "广西玉林市玉州区"
            },
            {
                "code": "200902",
                "parentcode": "2009",
                "parenttext": "玉林市",
                "text": "容县",
                "level": 3,
                "order": 2191,
                "relation_code": "450921",
                "relation_desc": "广西容县"
            },
            {
                "code": "200903",
                "parentcode": "2009",
                "parenttext": "玉林市",
                "text": "陆川县",
                "level": 3,
                "order": 2192,
                "relation_code": "450922",
                "relation_desc": "广西陆川县"
            },
            {
                "code": "200904",
                "parentcode": "2009",
                "parenttext": "玉林市",
                "text": "博白县",
                "level": 3,
                "order": 2193,
                "relation_code": "450923",
                "relation_desc": "广西博白县"
            },
            {
                "code": "200905",
                "parentcode": "2009",
                "parenttext": "玉林市",
                "text": "兴业县",
                "level": 3,
                "order": 2194,
                "relation_code": "450924",
                "relation_desc": "广西兴业县"
            },
            {
                "code": "200906",
                "parentcode": "2009",
                "parenttext": "玉林市",
                "text": "北流市",
                "level": 3,
                "order": 2195,
                "relation_code": "450981",
                "relation_desc": "广西北流市"
            },
            {
                "code": "2010",
                "parentcode": "20",
                "parenttext": "广西壮族自治区",
                "text": "百色市",
                "level": 2,
                "order": 2196,
                "relation_code": "451000",
                "relation_desc": "广西百色市"
            },
            {
                "code": "201001",
                "parentcode": "2010",
                "parenttext": "百色市",
                "text": "右江区",
                "level": 3,
                "order": 2197,
                "relation_code": "451002",
                "relation_desc": "广西百色市右江区"
            },
            {
                "code": "201002",
                "parentcode": "2010",
                "parenttext": "百色市",
                "text": "田阳县",
                "level": 3,
                "order": 2198,
                "relation_code": "451001",
                "relation_desc": "广西田阳县"
            },
            {
                "code": "201003",
                "parentcode": "2010",
                "parenttext": "百色市",
                "text": "田东县",
                "level": 3,
                "order": 2199,
                "relation_code": "451022",
                "relation_desc": "广西田东县"
            },
            {
                "code": "201004",
                "parentcode": "2010",
                "parenttext": "百色市",
                "text": "平果县",
                "level": 3,
                "order": 2200,
                "relation_code": "451001",
                "relation_desc": "广西平果县"
            },
            {
                "code": "201005",
                "parentcode": "2010",
                "parenttext": "百色市",
                "text": "德保县",
                "level": 3,
                "order": 2201,
                "relation_code": "451024",
                "relation_desc": "广西德保县"
            },
            {
                "code": "201006",
                "parentcode": "2010",
                "parenttext": "百色市",
                "text": "靖西市",
                "level": 3,
                "order": 2202,
                "relation_code": "451081",
                "relation_desc": "广西靖西市"
            },
            {
                "code": "201007",
                "parentcode": "2010",
                "parenttext": "百色市",
                "text": "那坡县",
                "level": 3,
                "order": 2203,
                "relation_code": "451026",
                "relation_desc": "广西那坡县"
            },
            {
                "code": "201008",
                "parentcode": "2010",
                "parenttext": "百色市",
                "text": "凌云县",
                "level": 3,
                "order": 2204,
                "relation_code": "451027",
                "relation_desc": "广西凌云县"
            },
            {
                "code": "201009",
                "parentcode": "2010",
                "parenttext": "百色市",
                "text": "乐业县",
                "level": 3,
                "order": 2205,
                "relation_code": "451028",
                "relation_desc": "广西乐业县"
            },
            {
                "code": "201010",
                "parentcode": "2010",
                "parenttext": "百色市",
                "text": "田林县",
                "level": 3,
                "order": 2206,
                "relation_code": "451029",
                "relation_desc": "广西田林县"
            },
            {
                "code": "201011",
                "parentcode": "2010",
                "parenttext": "百色市",
                "text": "西林县",
                "level": 3,
                "order": 2207,
                "relation_code": "451030",
                "relation_desc": "广西西林县"
            },
            {
                "code": "201012",
                "parentcode": "2010",
                "parenttext": "百色市",
                "text": "隆林各族自治县",
                "level": 3,
                "order": 2208,
                "relation_code": "451031",
                "relation_desc": "广西隆林各族自治县"
            },
            {
                "code": "2011",
                "parentcode": "20",
                "parenttext": "广西壮族自治区",
                "text": "贺州市",
                "level": 2,
                "order": 2209,
                "relation_code": "451100",
                "relation_desc": "广西贺州市"
            },
            {
                "code": "201101",
                "parentcode": "2011",
                "parenttext": "贺州市",
                "text": "八步区",
                "level": 3,
                "order": 2210,
                "relation_code": "451102",
                "relation_desc": "广西贺州市八步区"
            },
            {
                "code": "201102",
                "parentcode": "2011",
                "parenttext": "贺州市",
                "text": "昭平县",
                "level": 3,
                "order": 2211,
                "relation_code": "451121",
                "relation_desc": "广西昭平县"
            },
            {
                "code": "201103",
                "parentcode": "2011",
                "parenttext": "贺州市",
                "text": "钟山县",
                "level": 3,
                "order": 2212,
                "relation_code": "451122",
                "relation_desc": "广西钟山县"
            },
            {
                "code": "201104",
                "parentcode": "2011",
                "parenttext": "贺州市",
                "text": "富川瑶族自治县",
                "level": 3,
                "order": 2213,
                "relation_code": "451123",
                "relation_desc": "广西富川瑶族自治县"
            },
            {
                "code": "2012",
                "parentcode": "20",
                "parenttext": "广西壮族自治区",
                "text": "河池市",
                "level": 2,
                "order": 2214,
                "relation_code": "451200",
                "relation_desc": "广西河池市"
            },
            {
                "code": "201201",
                "parentcode": "2012",
                "parenttext": "河池市",
                "text": "金城江区",
                "level": 3,
                "order": 2215,
                "relation_code": "451202",
                "relation_desc": "广西河池市金城江区"
            },
            {
                "code": "201202",
                "parentcode": "2012",
                "parenttext": "河池市",
                "text": "南丹县",
                "level": 3,
                "order": 2216,
                "relation_code": "451221",
                "relation_desc": "广西南丹县"
            },
            {
                "code": "201203",
                "parentcode": "2012",
                "parenttext": "河池市",
                "text": "天峨县",
                "level": 3,
                "order": 2217,
                "relation_code": "451222",
                "relation_desc": "广西天峨县"
            },
            {
                "code": "201204",
                "parentcode": "2012",
                "parenttext": "河池市",
                "text": "凤山县",
                "level": 3,
                "order": 2218,
                "relation_code": "451223",
                "relation_desc": "广西凤山县"
            },
            {
                "code": "201205",
                "parentcode": "2012",
                "parenttext": "河池市",
                "text": "东兰县",
                "level": 3,
                "order": 2219,
                "relation_code": "451224",
                "relation_desc": "广西东兰县"
            },
            {
                "code": "201206",
                "parentcode": "2012",
                "parenttext": "河池市",
                "text": "罗城仫佬族自治县",
                "level": 3,
                "order": 2220,
                "relation_code": "451225",
                "relation_desc": "广西罗城仫佬族自治县"
            },
            {
                "code": "201207",
                "parentcode": "2012",
                "parenttext": "河池市",
                "text": "环江毛南族自治县",
                "level": 3,
                "order": 2221,
                "relation_code": "451226",
                "relation_desc": "广西环江毛南族自治县"
            },
            {
                "code": "201208",
                "parentcode": "2012",
                "parenttext": "河池市",
                "text": "巴马瑶族自治县",
                "level": 3,
                "order": 2222,
                "relation_code": "451227",
                "relation_desc": "广西巴马瑶族自治县"
            },
            {
                "code": "201209",
                "parentcode": "2012",
                "parenttext": "河池市",
                "text": "都安瑶族自治县",
                "level": 3,
                "order": 2223,
                "relation_code": "451228",
                "relation_desc": "广西都安瑶族自治县"
            },
            {
                "code": "201210",
                "parentcode": "2012",
                "parenttext": "河池市",
                "text": "大化瑶族自治县",
                "level": 3,
                "order": 2224,
                "relation_code": "451229",
                "relation_desc": "广西大化瑶族自治县"
            },
            {
                "code": "201211",
                "parentcode": "2012",
                "parenttext": "河池市",
                "text": "宜州市",
                "level": 3,
                "order": 2225,
                "relation_code": "451201",
                "relation_desc": "广西宜州市"
            },
            {
                "code": "2013",
                "parentcode": "20",
                "parenttext": "广西壮族自治区",
                "text": "来宾市",
                "level": 2,
                "order": 2226,
                "relation_code": "451300",
                "relation_desc": "广西来宾市"
            },
            {
                "code": "201301",
                "parentcode": "2013",
                "parenttext": "来宾市",
                "text": "兴宾区",
                "level": 3,
                "order": 2227,
                "relation_code": "451302",
                "relation_desc": "广西来宾市兴宾区"
            },
            {
                "code": "201302",
                "parentcode": "2013",
                "parenttext": "来宾市",
                "text": "忻城县",
                "level": 3,
                "order": 2228,
                "relation_code": "451321",
                "relation_desc": "广西忻城县"
            },
            {
                "code": "201303",
                "parentcode": "2013",
                "parenttext": "来宾市",
                "text": "象州县",
                "level": 3,
                "order": 2229,
                "relation_code": "451322",
                "relation_desc": "广西象州县"
            },
            {
                "code": "201304",
                "parentcode": "2013",
                "parenttext": "来宾市",
                "text": "武宣县",
                "level": 3,
                "order": 2230,
                "relation_code": "451323",
                "relation_desc": "广西武宣县"
            },
            {
                "code": "201305",
                "parentcode": "2013",
                "parenttext": "来宾市",
                "text": "金秀瑶族自治县",
                "level": 3,
                "order": 2231,
                "relation_code": "451324",
                "relation_desc": "广西金秀瑶族自治县"
            },
            {
                "code": "201306",
                "parentcode": "2013",
                "parenttext": "来宾市",
                "text": "合山市",
                "level": 3,
                "order": 2232,
                "relation_code": "451381",
                "relation_desc": "广西合山市"
            },
            {
                "code": "2014",
                "parentcode": "20",
                "parenttext": "广西壮族自治区",
                "text": "崇左市",
                "level": 2,
                "order": 2233,
                "relation_code": "451400",
                "relation_desc": "广西崇左市"
            },
            {
                "code": "201401",
                "parentcode": "2014",
                "parenttext": "崇左市",
                "text": "江洲区",
                "level": 3,
                "order": 2234,
                "relation_code": "451421",
                "relation_desc": "广西崇左市江洲区"
            },
            {
                "code": "201402",
                "parentcode": "2014",
                "parenttext": "崇左市",
                "text": "扶绥县",
                "level": 3,
                "order": 2235,
                "relation_code": "451421",
                "relation_desc": "广西扶绥县"
            },
            {
                "code": "201403",
                "parentcode": "2014",
                "parenttext": "崇左市",
                "text": "宁明县",
                "level": 3,
                "order": 2236,
                "relation_code": "451422",
                "relation_desc": "广西宁明县"
            },
            {
                "code": "201404",
                "parentcode": "2014",
                "parenttext": "崇左市",
                "text": "龙州县",
                "level": 3,
                "order": 2237,
                "relation_code": "451423",
                "relation_desc": "广西龙州县"
            },
            {
                "code": "201405",
                "parentcode": "2014",
                "parenttext": "崇左市",
                "text": "大新县",
                "level": 3,
                "order": 2238,
                "relation_code": "451424",
                "relation_desc": "广西大新县"
            },
            {
                "code": "201406",
                "parentcode": "2014",
                "parenttext": "崇左市",
                "text": "天等县",
                "level": 3,
                "order": 2239,
                "relation_code": "451425",
                "relation_desc": "广西天等县"
            },
            {
                "code": "201407",
                "parentcode": "2014",
                "parenttext": "崇左市",
                "text": "凭祥市",
                "level": 3,
                "order": 2240,
                "relation_code": "451481",
                "relation_desc": "广西凭祥市"
            },
            {
                "code": "21",
                "parentcode": "0",
                "parenttext": "0",
                "text": "海南省",
                "level": 1,
                "order": 2241,
                "relation_code": "",
                "relation_desc": "海南省"
            },
            {
                "code": "2101",
                "parentcode": "21",
                "parenttext": "海南省",
                "text": "海口市",
                "level": 2,
                "order": 2242,
                "relation_code": "460100",
                "relation_desc": "海南省海口市"
            },
            {
                "code": "210101",
                "parentcode": "2101",
                "parenttext": "海口市",
                "text": "秀英区",
                "level": 3,
                "order": 2243,
                "relation_code": "460105",
                "relation_desc": "海南省海口市秀英区"
            },
            {
                "code": "210102",
                "parentcode": "2101",
                "parenttext": "海口市",
                "text": "龙华区",
                "level": 3,
                "order": 2244,
                "relation_code": "460106",
                "relation_desc": "海南省海口市龙华区"
            },
            {
                "code": "210103",
                "parentcode": "2101",
                "parenttext": "海口市",
                "text": "琼山区",
                "level": 3,
                "order": 2245,
                "relation_code": "460107",
                "relation_desc": "海南省海口市琼山区"
            },
            {
                "code": "210104",
                "parentcode": "2101",
                "parenttext": "海口市",
                "text": "美兰区",
                "level": 3,
                "order": 2246,
                "relation_code": "460108",
                "relation_desc": "海南省海口市美兰区"
            },
            {
                "code": "2102",
                "parentcode": "21",
                "parenttext": "海南省",
                "text": "三亚市",
                "level": 2,
                "order": 2247,
                "relation_code": "460200",
                "relation_desc": "海南省三亚市"
            },
            {
                "code": "210201",
                "parentcode": "2102",
                "parenttext": "三亚市",
                "text": "海棠区",
                "level": 3,
                "order": 2248,
                "relation_code": "460202",
                "relation_desc": "海南省三亚市"
            },
            {
                "code": "2103",
                "parentcode": "21",
                "parenttext": "海南省",
                "text": "五指山市",
                "level": 2,
                "order": 2249,
                "relation_code": "469001",
                "relation_desc": "海南省五指山市"
            },
            {
                "code": "210301",
                "parentcode": "2103",
                "parenttext": "五指山市",
                "text": "五指山市",
                "level": 3,
                "order": 2250,
                "relation_code": "469001",
                "relation_desc": "海南省五指山市"
            },
            {
                "code": "2104",
                "parentcode": "21",
                "parenttext": "海南省",
                "text": "琼海市",
                "level": 2,
                "order": 2251,
                "relation_code": "469002",
                "relation_desc": "海南省琼海市"
            },
            {
                "code": "210401",
                "parentcode": "2104",
                "parenttext": "琼海市",
                "text": "琼海市",
                "level": 3,
                "order": 2252,
                "relation_code": "469002",
                "relation_desc": "海南省琼海市"
            },
            {
                "code": "2105",
                "parentcode": "21",
                "parenttext": "海南省",
                "text": "儋州市",
                "level": 2,
                "order": 2253,
                "relation_code": "460400",
                "relation_desc": "海南省儋州市"
            },
            {
                "code": "210501",
                "parentcode": "2105",
                "parenttext": "儋州市",
                "text": "儋州市",
                "level": 3,
                "order": 2254,
                "relation_code": "460400",
                "relation_desc": "海南省儋州市"
            },
            {
                "code": "2106",
                "parentcode": "21",
                "parenttext": "海南省",
                "text": "文昌市",
                "level": 2,
                "order": 2255,
                "relation_code": "469005",
                "relation_desc": "海南省文昌市"
            },
            {
                "code": "210601",
                "parentcode": "2106",
                "parenttext": "文昌市",
                "text": "文昌市",
                "level": 3,
                "order": 2256,
                "relation_code": "469005",
                "relation_desc": "海南省文昌市"
            },
            {
                "code": "2107",
                "parentcode": "21",
                "parenttext": "海南省",
                "text": "万宁市",
                "level": 2,
                "order": 2257,
                "relation_code": "469006",
                "relation_desc": "海南省万宁市"
            },
            {
                "code": "210701",
                "parentcode": "2107",
                "parenttext": "万宁市",
                "text": "万宁市",
                "level": 3,
                "order": 2258,
                "relation_code": "469006",
                "relation_desc": "海南省万宁市"
            },
            {
                "code": "2108",
                "parentcode": "21",
                "parenttext": "海南省",
                "text": "东方市",
                "level": 2,
                "order": 2259,
                "relation_code": "469007",
                "relation_desc": "海南省东方市"
            },
            {
                "code": "210801",
                "parentcode": "2108",
                "parenttext": "东方市",
                "text": "东方市",
                "level": 3,
                "order": 2260,
                "relation_code": "469007",
                "relation_desc": "海南省东方市"
            },
            {
                "code": "2109",
                "parentcode": "21",
                "parenttext": "海南省",
                "text": "定安县",
                "level": 2,
                "order": 2261,
                "relation_code": "469021",
                "relation_desc": "海南省定安县"
            },
            {
                "code": "210901",
                "parentcode": "2109",
                "parenttext": "定安县",
                "text": "定安县",
                "level": 3,
                "order": 2262,
                "relation_code": "469021",
                "relation_desc": "海南省定安县"
            },
            {
                "code": "2110",
                "parentcode": "21",
                "parenttext": "海南省",
                "text": "屯昌县",
                "level": 2,
                "order": 2263,
                "relation_code": "469022",
                "relation_desc": "海南省屯昌县"
            },
            {
                "code": "211001",
                "parentcode": "2110",
                "parenttext": "屯昌县",
                "text": "屯昌县",
                "level": 3,
                "order": 2264,
                "relation_code": "469022",
                "relation_desc": "海南省屯昌县"
            },
            {
                "code": "2111",
                "parentcode": "21",
                "parenttext": "海南省",
                "text": "澄迈县",
                "level": 2,
                "order": 2265,
                "relation_code": "469023",
                "relation_desc": "海南省澄迈县"
            },
            {
                "code": "211101",
                "parentcode": "2111",
                "parenttext": "澄迈县",
                "text": "澄迈县",
                "level": 3,
                "order": 2266,
                "relation_code": "469023",
                "relation_desc": "海南省澄迈县"
            },
            {
                "code": "2112",
                "parentcode": "21",
                "parenttext": "海南省",
                "text": "临高县",
                "level": 2,
                "order": 2267,
                "relation_code": "469024",
                "relation_desc": "海南省临高县"
            },
            {
                "code": "211201",
                "parentcode": "2112",
                "parenttext": "临高县",
                "text": "临高县",
                "level": 3,
                "order": 2268,
                "relation_code": "469024",
                "relation_desc": "海南省临高县"
            },
            {
                "code": "2113",
                "parentcode": "21",
                "parenttext": "海南省",
                "text": "白沙黎族自治县",
                "level": 2,
                "order": 2269,
                "relation_code": "469025",
                "relation_desc": "海南省白沙黎族自治县"
            },
            {
                "code": "211301",
                "parentcode": "2113",
                "parenttext": "白沙黎族自治县",
                "text": "白沙黎族自治县",
                "level": 3,
                "order": 2270,
                "relation_code": "469025",
                "relation_desc": "海南省白沙黎族自治县"
            },
            {
                "code": "2114",
                "parentcode": "21",
                "parenttext": "海南省",
                "text": "昌江黎族自治县",
                "level": 2,
                "order": 2271,
                "relation_code": "469026",
                "relation_desc": "海南省昌江黎族自治县"
            },
            {
                "code": "211401",
                "parentcode": "2114",
                "parenttext": "昌江黎族自治县",
                "text": "昌江黎族自治县",
                "level": 3,
                "order": 2272,
                "relation_code": "469026",
                "relation_desc": "海南省昌江黎族自治县"
            },
            {
                "code": "2115",
                "parentcode": "21",
                "parenttext": "海南省",
                "text": "乐东黎族自治县",
                "level": 2,
                "order": 2273,
                "relation_code": "469027",
                "relation_desc": "海南省乐东黎族自治县"
            },
            {
                "code": "211501",
                "parentcode": "2115",
                "parenttext": "乐东黎族自治县",
                "text": "乐东黎族自治县",
                "level": 3,
                "order": 2274,
                "relation_code": "469027",
                "relation_desc": "海南省乐东黎族自治县"
            },
            {
                "code": "2116",
                "parentcode": "21",
                "parenttext": "海南省",
                "text": "陵水黎族自治县",
                "level": 2,
                "order": 2275,
                "relation_code": "469028",
                "relation_desc": "海南省陵水黎族自治县"
            },
            {
                "code": "211601",
                "parentcode": "2116",
                "parenttext": "陵水黎族自治县",
                "text": "陵水黎族自治县",
                "level": 3,
                "order": 2276,
                "relation_code": "469028",
                "relation_desc": "海南省陵水黎族自治县"
            },
            {
                "code": "2117",
                "parentcode": "21",
                "parenttext": "海南省",
                "text": "保亭黎族苗族自治县",
                "level": 2,
                "order": 2277,
                "relation_code": "469029",
                "relation_desc": "海南省保亭黎族苗族自治县"
            },
            {
                "code": "211701",
                "parentcode": "2117",
                "parenttext": "保亭黎族苗族自治县",
                "text": "保亭黎族苗族自治县",
                "level": 3,
                "order": 2278,
                "relation_code": "469029",
                "relation_desc": "海南省保亭黎族苗族自治县"
            },
            {
                "code": "2118",
                "parentcode": "21",
                "parenttext": "海南省",
                "text": "琼中黎族苗族自治县",
                "level": 2,
                "order": 2279,
                "relation_code": "469030",
                "relation_desc": "海南省琼中黎族苗族自治县"
            },
            {
                "code": "211801",
                "parentcode": "2118",
                "parenttext": "琼中黎族苗族自治县",
                "text": "琼中黎族苗族自治县",
                "level": 3,
                "order": 2280,
                "relation_code": "469030",
                "relation_desc": "海南省琼中黎族苗族自治县"
            },
            {
                "code": "22",
                "parentcode": "0",
                "parenttext": "0",
                "text": "重庆市",
                "level": 1,
                "order": 2281,
                "relation_code": "",
                "relation_desc": "重庆市"
            },
            {
                "code": "2201",
                "parentcode": "22",
                "parenttext": "重庆市",
                "text": "重庆市",
                "level": 2,
                "order": 2282,
                "relation_code": "",
                "relation_desc": "重庆市"
            },
            {
                "code": "220101",
                "parentcode": "2201",
                "parenttext": "重庆市",
                "text": "万州区",
                "level": 3,
                "order": 2283,
                "relation_code": "500101",
                "relation_desc": "重庆市万州区"
            },
            {
                "code": "220102",
                "parentcode": "2201",
                "parenttext": "重庆市",
                "text": "涪陵区",
                "level": 3,
                "order": 2284,
                "relation_code": "500102",
                "relation_desc": "重庆市涪陵区"
            },
            {
                "code": "220103",
                "parentcode": "2201",
                "parenttext": "重庆市",
                "text": "渝中区",
                "level": 3,
                "order": 2285,
                "relation_code": "500103",
                "relation_desc": "重庆市渝中区"
            },
            {
                "code": "220104",
                "parentcode": "2201",
                "parenttext": "重庆市",
                "text": "大渡口区",
                "level": 3,
                "order": 2286,
                "relation_code": "500104",
                "relation_desc": "重庆市大渡口区"
            },
            {
                "code": "220105",
                "parentcode": "2201",
                "parenttext": "重庆市",
                "text": "江北区",
                "level": 3,
                "order": 2287,
                "relation_code": "500105",
                "relation_desc": "重庆市江北区"
            },
            {
                "code": "220106",
                "parentcode": "2201",
                "parenttext": "重庆市",
                "text": "沙坪坝区",
                "level": 3,
                "order": 2288,
                "relation_code": "500106",
                "relation_desc": "重庆市沙坪坝区"
            },
            {
                "code": "220107",
                "parentcode": "2201",
                "parenttext": "重庆市",
                "text": "九龙坡区",
                "level": 3,
                "order": 2289,
                "relation_code": "500107",
                "relation_desc": "重庆市九龙坡区"
            },
            {
                "code": "220108",
                "parentcode": "2201",
                "parenttext": "重庆市",
                "text": "南岸区",
                "level": 3,
                "order": 2290,
                "relation_code": "500108",
                "relation_desc": "重庆市南岸区"
            },
            {
                "code": "220109",
                "parentcode": "2201",
                "parenttext": "重庆市",
                "text": "北碚区",
                "level": 3,
                "order": 2291,
                "relation_code": "500109",
                "relation_desc": "重庆市北碚区"
            },
            {
                "code": "220110",
                "parentcode": "2201",
                "parenttext": "重庆市",
                "text": "万盛区",
                "level": 3,
                "order": 2292,
                "relation_code": "500101",
                "relation_desc": "重庆市"
            },
            {
                "code": "220111",
                "parentcode": "2201",
                "parenttext": "重庆市",
                "text": "双桥区",
                "level": 3,
                "order": 2293,
                "relation_code": "500101",
                "relation_desc": "重庆市"
            },
            {
                "code": "220112",
                "parentcode": "2201",
                "parenttext": "重庆市",
                "text": "渝北区",
                "level": 3,
                "order": 2294,
                "relation_code": "500112",
                "relation_desc": "重庆市渝北区"
            },
            {
                "code": "220113",
                "parentcode": "2201",
                "parenttext": "重庆市",
                "text": "巴南区",
                "level": 3,
                "order": 2295,
                "relation_code": "500113",
                "relation_desc": "重庆市巴南区"
            },
            {
                "code": "220114",
                "parentcode": "2201",
                "parenttext": "重庆市",
                "text": "黔江区",
                "level": 3,
                "order": 2296,
                "relation_code": "500114",
                "relation_desc": "重庆市黔江区"
            },
            {
                "code": "220115",
                "parentcode": "2201",
                "parenttext": "重庆市",
                "text": "长寿区",
                "level": 3,
                "order": 2297,
                "relation_code": "500115",
                "relation_desc": "重庆市长寿区"
            },
            {
                "code": "220116",
                "parentcode": "2201",
                "parenttext": "重庆市",
                "text": "綦江区",
                "level": 3,
                "order": 2298,
                "relation_code": "500110",
                "relation_desc": "重庆市綦江区"
            },
            {
                "code": "220117",
                "parentcode": "2201",
                "parenttext": "重庆市",
                "text": "潼南区",
                "level": 3,
                "order": 2299,
                "relation_code": "500152",
                "relation_desc": "重庆市潼南区"
            },
            {
                "code": "220118",
                "parentcode": "2201",
                "parenttext": "重庆市",
                "text": "铜梁区",
                "level": 3,
                "order": 2300,
                "relation_code": "500151",
                "relation_desc": "重庆市铜梁区"
            },
            {
                "code": "220119",
                "parentcode": "2201",
                "parenttext": "重庆市",
                "text": "大足区",
                "level": 3,
                "order": 2301,
                "relation_code": "500111",
                "relation_desc": "重庆市大足区"
            },
            {
                "code": "220120",
                "parentcode": "2201",
                "parenttext": "重庆市",
                "text": "荣昌区",
                "level": 3,
                "order": 2302,
                "relation_code": "500153",
                "relation_desc": "重庆市荣昌区"
            },
            {
                "code": "220121",
                "parentcode": "2201",
                "parenttext": "重庆市",
                "text": "璧山区",
                "level": 3,
                "order": 2303,
                "relation_code": "500120",
                "relation_desc": "重庆市璧山区"
            },
            {
                "code": "220122",
                "parentcode": "2201",
                "parenttext": "重庆市",
                "text": "梁平区",
                "level": 3,
                "order": 2304,
                "relation_code": "500155",
                "relation_desc": "重庆市梁平区"
            },
            {
                "code": "220123",
                "parentcode": "2201",
                "parenttext": "重庆市",
                "text": "城口县",
                "level": 3,
                "order": 2305,
                "relation_code": "500229",
                "relation_desc": "重庆市城口县"
            },
            {
                "code": "220124",
                "parentcode": "2201",
                "parenttext": "重庆市",
                "text": "丰都县",
                "level": 3,
                "order": 2306,
                "relation_code": "500230",
                "relation_desc": "重庆市丰都县"
            },
            {
                "code": "220125",
                "parentcode": "2201",
                "parenttext": "重庆市",
                "text": "垫江县",
                "level": 3,
                "order": 2307,
                "relation_code": "500231",
                "relation_desc": "重庆市垫江县"
            },
            {
                "code": "220126",
                "parentcode": "2201",
                "parenttext": "重庆市",
                "text": "武隆区",
                "level": 3,
                "order": 2308,
                "relation_code": "500156",
                "relation_desc": "重庆市武隆区"
            },
            {
                "code": "220127",
                "parentcode": "2201",
                "parenttext": "重庆市",
                "text": "忠县",
                "level": 3,
                "order": 2309,
                "relation_code": "500233",
                "relation_desc": "重庆市忠县"
            },
            {
                "code": "220128",
                "parentcode": "2201",
                "parenttext": "重庆市",
                "text": "开州区",
                "level": 3,
                "order": 2310,
                "relation_code": "500154",
                "relation_desc": "重庆市开州区"
            },
            {
                "code": "220129",
                "parentcode": "2201",
                "parenttext": "重庆市",
                "text": "云阳县",
                "level": 3,
                "order": 2311,
                "relation_code": "500235",
                "relation_desc": "重庆市云阳县"
            },
            {
                "code": "220130",
                "parentcode": "2201",
                "parenttext": "重庆市",
                "text": "奉节县",
                "level": 3,
                "order": 2312,
                "relation_code": "500236",
                "relation_desc": "重庆市奉节县"
            },
            {
                "code": "220131",
                "parentcode": "2201",
                "parenttext": "重庆市",
                "text": "巫山县",
                "level": 3,
                "order": 2313,
                "relation_code": "500237",
                "relation_desc": "重庆市巫山县"
            },
            {
                "code": "220132",
                "parentcode": "2201",
                "parenttext": "重庆市",
                "text": "巫溪县",
                "level": 3,
                "order": 2314,
                "relation_code": "500238",
                "relation_desc": "重庆市巫溪县"
            },
            {
                "code": "220133",
                "parentcode": "2201",
                "parenttext": "重庆市",
                "text": "石柱土家族自治县",
                "level": 3,
                "order": 2315,
                "relation_code": "500240",
                "relation_desc": "重庆市石柱土家族自治县"
            },
            {
                "code": "220134",
                "parentcode": "2201",
                "parenttext": "重庆市",
                "text": "秀山土家族苗族自治县",
                "level": 3,
                "order": 2316,
                "relation_code": "500241",
                "relation_desc": "重庆市秀山土家族苗族自治县"
            },
            {
                "code": "220135",
                "parentcode": "2201",
                "parenttext": "重庆市",
                "text": "酉阳土家族苗族自治县",
                "level": 3,
                "order": 2317,
                "relation_code": "500242",
                "relation_desc": "重庆市酉阳土家族苗族自治县"
            },
            {
                "code": "220136",
                "parentcode": "2201",
                "parenttext": "重庆市",
                "text": "彭水苗族土家族自治县",
                "level": 3,
                "order": 2318,
                "relation_code": "500243",
                "relation_desc": "重庆市彭水苗族土家族自治县"
            },
            {
                "code": "220137",
                "parentcode": "2201",
                "parenttext": "重庆市",
                "text": "江津区",
                "level": 3,
                "order": 2319,
                "relation_code": "500116",
                "relation_desc": "重庆市江津区"
            },
            {
                "code": "220138",
                "parentcode": "2201",
                "parenttext": "重庆市",
                "text": "合川区",
                "level": 3,
                "order": 2320,
                "relation_code": "500117",
                "relation_desc": "重庆市合川区"
            },
            {
                "code": "220139",
                "parentcode": "2201",
                "parenttext": "重庆市",
                "text": "永川区",
                "level": 3,
                "order": 2321,
                "relation_code": "500118",
                "relation_desc": "重庆市永川区"
            },
            {
                "code": "220140",
                "parentcode": "2201",
                "parenttext": "重庆市",
                "text": "南川区",
                "level": 3,
                "order": 2322,
                "relation_code": "500119",
                "relation_desc": "重庆市南川区"
            },
            {
                "code": "23",
                "parentcode": "0",
                "parenttext": "0",
                "text": "四川省",
                "level": 1,
                "order": 2323,
                "relation_code": "",
                "relation_desc": "四川省"
            },
            {
                "code": "2301",
                "parentcode": "23",
                "parenttext": "四川省",
                "text": "成都市",
                "level": 2,
                "order": 2324,
                "relation_code": "510100",
                "relation_desc": "四川省成都市"
            },
            {
                "code": "230101",
                "parentcode": "2301",
                "parenttext": "成都市",
                "text": "锦江区",
                "level": 3,
                "order": 2325,
                "relation_code": "510104",
                "relation_desc": "四川省成都市锦江区"
            },
            {
                "code": "230102",
                "parentcode": "2301",
                "parenttext": "成都市",
                "text": "青羊区",
                "level": 3,
                "order": 2326,
                "relation_code": "510105",
                "relation_desc": "四川省成都市青羊区"
            },
            {
                "code": "230103",
                "parentcode": "2301",
                "parenttext": "成都市",
                "text": "金牛区",
                "level": 3,
                "order": 2327,
                "relation_code": "510106",
                "relation_desc": "四川省成都市金牛区"
            },
            {
                "code": "230104",
                "parentcode": "2301",
                "parenttext": "成都市",
                "text": "武侯区",
                "level": 3,
                "order": 2328,
                "relation_code": "510107",
                "relation_desc": "四川省成都市武侯区"
            },
            {
                "code": "230105",
                "parentcode": "2301",
                "parenttext": "成都市",
                "text": "成华区",
                "level": 3,
                "order": 2329,
                "relation_code": "510108",
                "relation_desc": "四川省成都市成华区"
            },
            {
                "code": "230106",
                "parentcode": "2301",
                "parenttext": "成都市",
                "text": "龙泉驿区",
                "level": 3,
                "order": 2330,
                "relation_code": "510112",
                "relation_desc": "四川省成都市龙泉驿区"
            },
            {
                "code": "230107",
                "parentcode": "2301",
                "parenttext": "成都市",
                "text": "青白江区",
                "level": 3,
                "order": 2331,
                "relation_code": "510113",
                "relation_desc": "四川省成都市青白江区"
            },
            {
                "code": "230108",
                "parentcode": "2301",
                "parenttext": "成都市",
                "text": "新都区",
                "level": 3,
                "order": 2332,
                "relation_code": "510114",
                "relation_desc": "四川省成都市新都区"
            },
            {
                "code": "230109",
                "parentcode": "2301",
                "parenttext": "成都市",
                "text": "温江区",
                "level": 3,
                "order": 2333,
                "relation_code": "510115",
                "relation_desc": "四川省成都市温江区"
            },
            {
                "code": "230110",
                "parentcode": "2301",
                "parenttext": "成都市",
                "text": "金堂县",
                "level": 3,
                "order": 2334,
                "relation_code": "510121",
                "relation_desc": "四川省金堂县"
            },
            {
                "code": "230111",
                "parentcode": "2301",
                "parenttext": "成都市",
                "text": "双流区",
                "level": 3,
                "order": 2335,
                "relation_code": "510116",
                "relation_desc": "四川省成都市双流区"
            },
            {
                "code": "230112",
                "parentcode": "2301",
                "parenttext": "成都市",
                "text": "郫都区",
                "level": 3,
                "order": 2336,
                "relation_code": "510117",
                "relation_desc": "四川省成都市郫都区"
            },
            {
                "code": "230113",
                "parentcode": "2301",
                "parenttext": "成都市",
                "text": "大邑县",
                "level": 3,
                "order": 2337,
                "relation_code": "510129",
                "relation_desc": "四川省大邑县"
            },
            {
                "code": "230114",
                "parentcode": "2301",
                "parenttext": "成都市",
                "text": "蒲江县",
                "level": 3,
                "order": 2338,
                "relation_code": "510131",
                "relation_desc": "四川省蒲江县"
            },
            {
                "code": "230115",
                "parentcode": "2301",
                "parenttext": "成都市",
                "text": "新津县",
                "level": 3,
                "order": 2339,
                "relation_code": "510132",
                "relation_desc": "四川省新津县"
            },
            {
                "code": "230116",
                "parentcode": "2301",
                "parenttext": "成都市",
                "text": "都江堰市",
                "level": 3,
                "order": 2340,
                "relation_code": "510181",
                "relation_desc": "四川省都江堰市"
            },
            {
                "code": "230117",
                "parentcode": "2301",
                "parenttext": "成都市",
                "text": "彭州市",
                "level": 3,
                "order": 2341,
                "relation_code": "510182",
                "relation_desc": "四川省彭州市"
            },
            {
                "code": "230118",
                "parentcode": "2301",
                "parenttext": "成都市",
                "text": "邛崃市",
                "level": 3,
                "order": 2342,
                "relation_code": "510183",
                "relation_desc": "四川省邛崃市"
            },
            {
                "code": "230119",
                "parentcode": "2301",
                "parenttext": "成都市",
                "text": "崇州市",
                "level": 3,
                "order": 2343,
                "relation_code": "510184",
                "relation_desc": "四川省崇州市"
            },
            {
                "code": "230120",
                "parentcode": "2301",
                "parenttext": "成都市",
                "text": "简阳市",
                "level": 3,
                "order": 2343,
                "relation_code": "510100",
                "relation_desc": "四川省简阳市"
            },
            {
                "code": "2302",
                "parentcode": "23",
                "parenttext": "四川省",
                "text": "自贡市",
                "level": 2,
                "order": 2344,
                "relation_code": "510300",
                "relation_desc": "四川省自贡市"
            },
            {
                "code": "230201",
                "parentcode": "2302",
                "parenttext": "自贡市",
                "text": "自流井区",
                "level": 3,
                "order": 2345,
                "relation_code": "510302",
                "relation_desc": "四川省自贡市自流井区"
            },
            {
                "code": "230202",
                "parentcode": "2302",
                "parenttext": "自贡市",
                "text": "贡井区",
                "level": 3,
                "order": 2346,
                "relation_code": "510303",
                "relation_desc": "四川省自贡市贡井区"
            },
            {
                "code": "230203",
                "parentcode": "2302",
                "parenttext": "自贡市",
                "text": "大安区",
                "level": 3,
                "order": 2347,
                "relation_code": "510304",
                "relation_desc": "四川省自贡市大安区"
            },
            {
                "code": "230204",
                "parentcode": "2302",
                "parenttext": "自贡市",
                "text": "沿滩区",
                "level": 3,
                "order": 2348,
                "relation_code": "510311",
                "relation_desc": "四川省自贡市沿滩区"
            },
            {
                "code": "230205",
                "parentcode": "2302",
                "parenttext": "自贡市",
                "text": "荣县",
                "level": 3,
                "order": 2349,
                "relation_code": "510321",
                "relation_desc": "四川省荣县"
            },
            {
                "code": "230206",
                "parentcode": "2302",
                "parenttext": "自贡市",
                "text": "富顺县",
                "level": 3,
                "order": 2350,
                "relation_code": "510322",
                "relation_desc": "四川省富顺县"
            },
            {
                "code": "2303",
                "parentcode": "23",
                "parenttext": "四川省",
                "text": "攀枝花市",
                "level": 2,
                "order": 2351,
                "relation_code": "510400",
                "relation_desc": "四川省攀枝花市"
            },
            {
                "code": "230301",
                "parentcode": "2303",
                "parenttext": "攀枝花市",
                "text": "东区",
                "level": 3,
                "order": 2352,
                "relation_code": "510402",
                "relation_desc": "四川省攀枝花市东区"
            },
            {
                "code": "230302",
                "parentcode": "2303",
                "parenttext": "攀枝花市",
                "text": "西区",
                "level": 3,
                "order": 2353,
                "relation_code": "510403",
                "relation_desc": "四川省攀枝花市西区"
            },
            {
                "code": "230303",
                "parentcode": "2303",
                "parenttext": "攀枝花市",
                "text": "仁和区",
                "level": 3,
                "order": 2354,
                "relation_code": "510411",
                "relation_desc": "四川省攀枝花市仁和区"
            },
            {
                "code": "230304",
                "parentcode": "2303",
                "parenttext": "攀枝花市",
                "text": "米易县",
                "level": 3,
                "order": 2355,
                "relation_code": "510421",
                "relation_desc": "四川省米易县"
            },
            {
                "code": "230305",
                "parentcode": "2303",
                "parenttext": "攀枝花市",
                "text": "盐边县",
                "level": 3,
                "order": 2356,
                "relation_code": "510422",
                "relation_desc": "四川省盐边县"
            },
            {
                "code": "2304",
                "parentcode": "23",
                "parenttext": "四川省",
                "text": "泸州市",
                "level": 2,
                "order": 2357,
                "relation_code": "510500",
                "relation_desc": "四川省泸州市"
            },
            {
                "code": "230401",
                "parentcode": "2304",
                "parenttext": "泸州市",
                "text": "江阳区",
                "level": 3,
                "order": 2358,
                "relation_code": "510502",
                "relation_desc": "四川省泸州市江阳区"
            },
            {
                "code": "230402",
                "parentcode": "2304",
                "parenttext": "泸州市",
                "text": "纳溪区",
                "level": 3,
                "order": 2359,
                "relation_code": "510503",
                "relation_desc": "四川省泸州市纳溪区"
            },
            {
                "code": "230403",
                "parentcode": "2304",
                "parenttext": "泸州市",
                "text": "龙马潭区",
                "level": 3,
                "order": 2360,
                "relation_code": "510504",
                "relation_desc": "四川省泸州市龙马潭区"
            },
            {
                "code": "230404",
                "parentcode": "2304",
                "parenttext": "泸州市",
                "text": "泸县",
                "level": 3,
                "order": 2361,
                "relation_code": "510521",
                "relation_desc": "四川省泸县"
            },
            {
                "code": "230405",
                "parentcode": "2304",
                "parenttext": "泸州市",
                "text": "合江县",
                "level": 3,
                "order": 2362,
                "relation_code": "510522",
                "relation_desc": "四川省合江县"
            },
            {
                "code": "230406",
                "parentcode": "2304",
                "parenttext": "泸州市",
                "text": "叙永县",
                "level": 3,
                "order": 2363,
                "relation_code": "510524",
                "relation_desc": "四川省叙永县"
            },
            {
                "code": "230407",
                "parentcode": "2304",
                "parenttext": "泸州市",
                "text": "古蔺县",
                "level": 3,
                "order": 2364,
                "relation_code": "510525",
                "relation_desc": "四川省古蔺县"
            },
            {
                "code": "2305",
                "parentcode": "23",
                "parenttext": "四川省",
                "text": "德阳市",
                "level": 2,
                "order": 2365,
                "relation_code": "510600",
                "relation_desc": "四川省德阳市"
            },
            {
                "code": "230501",
                "parentcode": "2305",
                "parenttext": "德阳市",
                "text": "旌阳区",
                "level": 3,
                "order": 2366,
                "relation_code": "510603",
                "relation_desc": "四川省德阳市旌阳区"
            },
            {
                "code": "230502",
                "parentcode": "2305",
                "parenttext": "德阳市",
                "text": "中江县",
                "level": 3,
                "order": 2367,
                "relation_code": "510623",
                "relation_desc": "四川省中江县"
            },
            {
                "code": "230503",
                "parentcode": "2305",
                "parenttext": "德阳市",
                "text": "罗江县",
                "level": 3,
                "order": 2368,
                "relation_code": "510601",
                "relation_desc": "四川省罗江县"
            },
            {
                "code": "230504",
                "parentcode": "2305",
                "parenttext": "德阳市",
                "text": "广汉市",
                "level": 3,
                "order": 2369,
                "relation_code": "510681",
                "relation_desc": "四川省广汉市"
            },
            {
                "code": "230505",
                "parentcode": "2305",
                "parenttext": "德阳市",
                "text": "什邡市",
                "level": 3,
                "order": 2370,
                "relation_code": "510682",
                "relation_desc": "四川省什邡市"
            },
            {
                "code": "230506",
                "parentcode": "2305",
                "parenttext": "德阳市",
                "text": "绵竹市",
                "level": 3,
                "order": 2371,
                "relation_code": "510683",
                "relation_desc": "四川省绵竹市"
            },
            {
                "code": "2306",
                "parentcode": "23",
                "parenttext": "四川省",
                "text": "绵阳市",
                "level": 2,
                "order": 2372,
                "relation_code": "510700",
                "relation_desc": "四川省绵阳市"
            },
            {
                "code": "230601",
                "parentcode": "2306",
                "parenttext": "绵阳市",
                "text": "涪城区",
                "level": 3,
                "order": 2373,
                "relation_code": "510703",
                "relation_desc": "四川省绵阳市涪城区"
            },
            {
                "code": "230602",
                "parentcode": "2306",
                "parenttext": "绵阳市",
                "text": "游仙区",
                "level": 3,
                "order": 2374,
                "relation_code": "510704",
                "relation_desc": "四川省绵阳市游仙区"
            },
            {
                "code": "230603",
                "parentcode": "2306",
                "parenttext": "绵阳市",
                "text": "三台县",
                "level": 3,
                "order": 2375,
                "relation_code": "510722",
                "relation_desc": "四川省三台县"
            },
            {
                "code": "230604",
                "parentcode": "2306",
                "parenttext": "绵阳市",
                "text": "盐亭县",
                "level": 3,
                "order": 2376,
                "relation_code": "510723",
                "relation_desc": "四川省盐亭县"
            },
            {
                "code": "230605",
                "parentcode": "2306",
                "parenttext": "绵阳市",
                "text": "安州区",
                "level": 3,
                "order": 2377,
                "relation_code": "510705",
                "relation_desc": "四川省绵阳市安州区"
            },
            {
                "code": "230606",
                "parentcode": "2306",
                "parenttext": "绵阳市",
                "text": "梓潼县",
                "level": 3,
                "order": 2378,
                "relation_code": "510725",
                "relation_desc": "四川省梓潼县"
            },
            {
                "code": "230607",
                "parentcode": "2306",
                "parenttext": "绵阳市",
                "text": "北川羌族自治县",
                "level": 3,
                "order": 2379,
                "relation_code": "510726",
                "relation_desc": "四川省北川羌族自治县"
            },
            {
                "code": "230608",
                "parentcode": "2306",
                "parenttext": "绵阳市",
                "text": "平武县",
                "level": 3,
                "order": 2380,
                "relation_code": "510727",
                "relation_desc": "四川省平武县"
            },
            {
                "code": "230609",
                "parentcode": "2306",
                "parenttext": "绵阳市",
                "text": "江油市",
                "level": 3,
                "order": 2381,
                "relation_code": "510781",
                "relation_desc": "四川省江油市"
            },
            {
                "code": "2307",
                "parentcode": "23",
                "parenttext": "四川省",
                "text": "广元市",
                "level": 2,
                "order": 2382,
                "relation_code": "510800",
                "relation_desc": "四川省广元市"
            },
            {
                "code": "230701",
                "parentcode": "2307",
                "parenttext": "广元市",
                "text": "利州区",
                "level": 3,
                "order": 2383,
                "relation_code": "510802",
                "relation_desc": "四川省广元市利州区"
            },
            {
                "code": "230702",
                "parentcode": "2307",
                "parenttext": "广元市",
                "text": "昭化区",
                "level": 3,
                "order": 2384,
                "relation_code": "510801",
                "relation_desc": "四川省广元市"
            },
            {
                "code": "230703",
                "parentcode": "2307",
                "parenttext": "广元市",
                "text": "朝天区",
                "level": 3,
                "order": 2385,
                "relation_code": "510812",
                "relation_desc": "四川省广元市朝天区"
            },
            {
                "code": "230704",
                "parentcode": "2307",
                "parenttext": "广元市",
                "text": "旺苍县",
                "level": 3,
                "order": 2386,
                "relation_code": "510821",
                "relation_desc": "四川省旺苍县"
            },
            {
                "code": "230705",
                "parentcode": "2307",
                "parenttext": "广元市",
                "text": "青川县",
                "level": 3,
                "order": 2387,
                "relation_code": "510822",
                "relation_desc": "四川省青川县"
            },
            {
                "code": "230706",
                "parentcode": "2307",
                "parenttext": "广元市",
                "text": "剑阁县",
                "level": 3,
                "order": 2388,
                "relation_code": "510823",
                "relation_desc": "四川省剑阁县"
            },
            {
                "code": "230707",
                "parentcode": "2307",
                "parenttext": "广元市",
                "text": "苍溪县",
                "level": 3,
                "order": 2389,
                "relation_code": "510824",
                "relation_desc": "四川省苍溪县"
            },
            {
                "code": "2308",
                "parentcode": "23",
                "parenttext": "四川省",
                "text": "遂宁市",
                "level": 2,
                "order": 2390,
                "relation_code": "510900",
                "relation_desc": "四川省遂宁市"
            },
            {
                "code": "230708",
                "parentcode": "2307",
                "parenttext": "广元市",
                "text": "元坝区",
                "level": 3,
                "order": 2390,
                "relation_code": "510801",
                "relation_desc": "四川省广元市元坝区"
            },
            {
                "code": "230801",
                "parentcode": "2308",
                "parenttext": "遂宁市",
                "text": "船山区",
                "level": 3,
                "order": 2391,
                "relation_code": "510903",
                "relation_desc": "四川省遂宁市船山区"
            },
            {
                "code": "230802",
                "parentcode": "2308",
                "parenttext": "遂宁市",
                "text": "安居区",
                "level": 3,
                "order": 2392,
                "relation_code": "510904",
                "relation_desc": "四川省遂宁市安居区"
            },
            {
                "code": "230803",
                "parentcode": "2308",
                "parenttext": "遂宁市",
                "text": "蓬溪县",
                "level": 3,
                "order": 2393,
                "relation_code": "510921",
                "relation_desc": "四川省蓬溪县"
            },
            {
                "code": "230804",
                "parentcode": "2308",
                "parenttext": "遂宁市",
                "text": "射洪县",
                "level": 3,
                "order": 2394,
                "relation_code": "510901",
                "relation_desc": "四川省射洪县"
            },
            {
                "code": "230805",
                "parentcode": "2308",
                "parenttext": "遂宁市",
                "text": "大英县",
                "level": 3,
                "order": 2395,
                "relation_code": "510923",
                "relation_desc": "四川省大英县"
            },
            {
                "code": "2309",
                "parentcode": "23",
                "parenttext": "四川省",
                "text": "内江市",
                "level": 2,
                "order": 2396,
                "relation_code": "511000",
                "relation_desc": "四川省内江市"
            },
            {
                "code": "230901",
                "parentcode": "2309",
                "parenttext": "内江市",
                "text": "市中区",
                "level": 3,
                "order": 2397,
                "relation_code": "511002",
                "relation_desc": "四川省内江市市中区"
            },
            {
                "code": "230902",
                "parentcode": "2309",
                "parenttext": "内江市",
                "text": "东兴区",
                "level": 3,
                "order": 2398,
                "relation_code": "511011",
                "relation_desc": "四川省内江市东兴区"
            },
            {
                "code": "230903",
                "parentcode": "2309",
                "parenttext": "内江市",
                "text": "威远县",
                "level": 3,
                "order": 2399,
                "relation_code": "511024",
                "relation_desc": "四川省威远县"
            },
            {
                "code": "230904",
                "parentcode": "2309",
                "parenttext": "内江市",
                "text": "资中县",
                "level": 3,
                "order": 2400,
                "relation_code": "511025",
                "relation_desc": "四川省资中县"
            },
            {
                "code": "230905",
                "parentcode": "2309",
                "parenttext": "内江市",
                "text": "隆昌县",
                "level": 3,
                "order": 2401,
                "relation_code": "511001",
                "relation_desc": "四川省隆昌县"
            },
            {
                "code": "2310",
                "parentcode": "23",
                "parenttext": "四川省",
                "text": "乐山市",
                "level": 2,
                "order": 2402,
                "relation_code": "511100",
                "relation_desc": "四川省乐山市"
            },
            {
                "code": "230906",
                "parentcode": "2309",
                "parenttext": "内江市",
                "text": "内江经济开发区",
                "level": 3,
                "order": 2402,
                "relation_code": "511001",
                "relation_desc": "四川省内江市"
            },
            {
                "code": "231001",
                "parentcode": "2310",
                "parenttext": "乐山市",
                "text": "市中区",
                "level": 3,
                "order": 2403,
                "relation_code": "511102",
                "relation_desc": "四川省乐山市市中区"
            },
            {
                "code": "231002",
                "parentcode": "2310",
                "parenttext": "乐山市",
                "text": "沙湾区",
                "level": 3,
                "order": 2404,
                "relation_code": "511111",
                "relation_desc": "四川省乐山市沙湾区"
            },
            {
                "code": "231003",
                "parentcode": "2310",
                "parenttext": "乐山市",
                "text": "五通桥区",
                "level": 3,
                "order": 2405,
                "relation_code": "511112",
                "relation_desc": "四川省乐山市五通桥区"
            },
            {
                "code": "231004",
                "parentcode": "2310",
                "parenttext": "乐山市",
                "text": "金口河区",
                "level": 3,
                "order": 2406,
                "relation_code": "511113",
                "relation_desc": "四川省乐山市金口河区"
            },
            {
                "code": "231005",
                "parentcode": "2310",
                "parenttext": "乐山市",
                "text": "犍为县",
                "level": 3,
                "order": 2407,
                "relation_code": "511123",
                "relation_desc": "四川省犍为县"
            },
            {
                "code": "231006",
                "parentcode": "2310",
                "parenttext": "乐山市",
                "text": "井研县",
                "level": 3,
                "order": 2408,
                "relation_code": "511124",
                "relation_desc": "四川省井研县"
            },
            {
                "code": "231007",
                "parentcode": "2310",
                "parenttext": "乐山市",
                "text": "夹江县",
                "level": 3,
                "order": 2409,
                "relation_code": "511126",
                "relation_desc": "四川省夹江县"
            },
            {
                "code": "231008",
                "parentcode": "2310",
                "parenttext": "乐山市",
                "text": "沐川县",
                "level": 3,
                "order": 2410,
                "relation_code": "511129",
                "relation_desc": "四川省沐川县"
            },
            {
                "code": "231009",
                "parentcode": "2310",
                "parenttext": "乐山市",
                "text": "峨边彝族自治县",
                "level": 3,
                "order": 2411,
                "relation_code": "511132",
                "relation_desc": "四川省峨边彝族自治县"
            },
            {
                "code": "231010",
                "parentcode": "2310",
                "parenttext": "乐山市",
                "text": "马边彝族自治县",
                "level": 3,
                "order": 2412,
                "relation_code": "511133",
                "relation_desc": "四川省马边彝族自治县"
            },
            {
                "code": "231011",
                "parentcode": "2310",
                "parenttext": "乐山市",
                "text": "峨眉山市",
                "level": 3,
                "order": 2413,
                "relation_code": "511181",
                "relation_desc": "四川省峨眉山市"
            },
            {
                "code": "2311",
                "parentcode": "23",
                "parenttext": "四川省",
                "text": "南充市",
                "level": 2,
                "order": 2414,
                "relation_code": "511300",
                "relation_desc": "四川省南充市"
            },
            {
                "code": "231101",
                "parentcode": "2311",
                "parenttext": "南充市",
                "text": "顺庆区",
                "level": 3,
                "order": 2415,
                "relation_code": "511302",
                "relation_desc": "四川省南充市顺庆区"
            },
            {
                "code": "231102",
                "parentcode": "2311",
                "parenttext": "南充市",
                "text": "高坪区",
                "level": 3,
                "order": 2416,
                "relation_code": "511303",
                "relation_desc": "四川省南充市高坪区"
            },
            {
                "code": "231103",
                "parentcode": "2311",
                "parenttext": "南充市",
                "text": "嘉陵区",
                "level": 3,
                "order": 2417,
                "relation_code": "511304",
                "relation_desc": "四川省南充市嘉陵区"
            },
            {
                "code": "231104",
                "parentcode": "2311",
                "parenttext": "南充市",
                "text": "南部县",
                "level": 3,
                "order": 2418,
                "relation_code": "511321",
                "relation_desc": "四川省南部县"
            },
            {
                "code": "231105",
                "parentcode": "2311",
                "parenttext": "南充市",
                "text": "营山县",
                "level": 3,
                "order": 2419,
                "relation_code": "511322",
                "relation_desc": "四川省营山县"
            },
            {
                "code": "231106",
                "parentcode": "2311",
                "parenttext": "南充市",
                "text": "蓬安县",
                "level": 3,
                "order": 2420,
                "relation_code": "511323",
                "relation_desc": "四川省蓬安县"
            },
            {
                "code": "231107",
                "parentcode": "2311",
                "parenttext": "南充市",
                "text": "仪陇县",
                "level": 3,
                "order": 2421,
                "relation_code": "511324",
                "relation_desc": "四川省仪陇县"
            },
            {
                "code": "231108",
                "parentcode": "2311",
                "parenttext": "南充市",
                "text": "西充县",
                "level": 3,
                "order": 2422,
                "relation_code": "511325",
                "relation_desc": "四川省西充县"
            },
            {
                "code": "231109",
                "parentcode": "2311",
                "parenttext": "南充市",
                "text": "阆中市",
                "level": 3,
                "order": 2423,
                "relation_code": "511381",
                "relation_desc": "四川省阆中市"
            },
            {
                "code": "2312",
                "parentcode": "23",
                "parenttext": "四川省",
                "text": "眉山市",
                "level": 2,
                "order": 2424,
                "relation_code": "511400",
                "relation_desc": "四川省眉山市"
            },
            {
                "code": "231201",
                "parentcode": "2312",
                "parenttext": "眉山市",
                "text": "东坡区",
                "level": 3,
                "order": 2425,
                "relation_code": "511402",
                "relation_desc": "四川省眉山市东坡区"
            },
            {
                "code": "231202",
                "parentcode": "2312",
                "parenttext": "眉山市",
                "text": "仁寿县",
                "level": 3,
                "order": 2426,
                "relation_code": "511421",
                "relation_desc": "四川省仁寿县"
            },
            {
                "code": "231203",
                "parentcode": "2312",
                "parenttext": "眉山市",
                "text": "彭山区",
                "level": 3,
                "order": 2427,
                "relation_code": "511401",
                "relation_desc": "四川省眉山市"
            },
            {
                "code": "231204",
                "parentcode": "2312",
                "parenttext": "眉山市",
                "text": "洪雅县",
                "level": 3,
                "order": 2428,
                "relation_code": "511423",
                "relation_desc": "四川省洪雅县"
            },
            {
                "code": "231205",
                "parentcode": "2312",
                "parenttext": "眉山市",
                "text": "丹棱县",
                "level": 3,
                "order": 2429,
                "relation_code": "511424",
                "relation_desc": "四川省丹棱县"
            },
            {
                "code": "231206",
                "parentcode": "2312",
                "parenttext": "眉山市",
                "text": "青神县",
                "level": 3,
                "order": 2430,
                "relation_code": "511425",
                "relation_desc": "四川省青神县"
            },
            {
                "code": "2313",
                "parentcode": "23",
                "parenttext": "四川省",
                "text": "宜宾市",
                "level": 2,
                "order": 2431,
                "relation_code": "511500",
                "relation_desc": "四川省宜宾市"
            },
            {
                "code": "231301",
                "parentcode": "2313",
                "parenttext": "宜宾市",
                "text": "翠屏区",
                "level": 3,
                "order": 2432,
                "relation_code": "511502",
                "relation_desc": "四川省宜宾市翠屏区"
            },
            {
                "code": "231302",
                "parentcode": "2313",
                "parenttext": "宜宾市",
                "text": "宜宾县",
                "level": 3,
                "order": 2433,
                "relation_code": "511501",
                "relation_desc": "四川省宜宾县"
            },
            {
                "code": "231303",
                "parentcode": "2313",
                "parenttext": "宜宾市",
                "text": "南溪区",
                "level": 3,
                "order": 2434,
                "relation_code": "511503",
                "relation_desc": "四川省宜宾市南溪区"
            },
            {
                "code": "231304",
                "parentcode": "2313",
                "parenttext": "宜宾市",
                "text": "江安县",
                "level": 3,
                "order": 2435,
                "relation_code": "511523",
                "relation_desc": "四川省江安县"
            },
            {
                "code": "231305",
                "parentcode": "2313",
                "parenttext": "宜宾市",
                "text": "长宁县",
                "level": 3,
                "order": 2436,
                "relation_code": "511524",
                "relation_desc": "四川省长宁县"
            },
            {
                "code": "231306",
                "parentcode": "2313",
                "parenttext": "宜宾市",
                "text": "高县",
                "level": 3,
                "order": 2437,
                "relation_code": "511525",
                "relation_desc": "四川省高县"
            },
            {
                "code": "231307",
                "parentcode": "2313",
                "parenttext": "宜宾市",
                "text": "珙县",
                "level": 3,
                "order": 2438,
                "relation_code": "511526",
                "relation_desc": "四川省珙县"
            },
            {
                "code": "231308",
                "parentcode": "2313",
                "parenttext": "宜宾市",
                "text": "筠连县",
                "level": 3,
                "order": 2439,
                "relation_code": "511527",
                "relation_desc": "四川省筠连县"
            },
            {
                "code": "231309",
                "parentcode": "2313",
                "parenttext": "宜宾市",
                "text": "兴文县",
                "level": 3,
                "order": 2440,
                "relation_code": "511528",
                "relation_desc": "四川省兴文县"
            },
            {
                "code": "231310",
                "parentcode": "2313",
                "parenttext": "宜宾市",
                "text": "屏山县",
                "level": 3,
                "order": 2441,
                "relation_code": "511529",
                "relation_desc": "四川省屏山县"
            },
            {
                "code": "2314",
                "parentcode": "23",
                "parenttext": "四川省",
                "text": "广安市",
                "level": 2,
                "order": 2442,
                "relation_code": "511600",
                "relation_desc": "四川省广安市"
            },
            {
                "code": "231311",
                "parentcode": "2313",
                "parenttext": "宜宾市",
                "text": "叙州区",
                "level": 3,
                "order": 2442,
                "relation_code": "511501",
                "relation_desc": "四川省宜宾市"
            },
            {
                "code": "231401",
                "parentcode": "2314",
                "parenttext": "广安市",
                "text": "广安区",
                "level": 3,
                "order": 2443,
                "relation_code": "511602",
                "relation_desc": "四川省广安市广安区"
            },
            {
                "code": "231402",
                "parentcode": "2314",
                "parenttext": "广安市",
                "text": "岳池县",
                "level": 3,
                "order": 2444,
                "relation_code": "511621",
                "relation_desc": "四川省岳池县"
            },
            {
                "code": "231403",
                "parentcode": "2314",
                "parenttext": "广安市",
                "text": "武胜县",
                "level": 3,
                "order": 2445,
                "relation_code": "511622",
                "relation_desc": "四川省武胜县"
            },
            {
                "code": "231404",
                "parentcode": "2314",
                "parenttext": "广安市",
                "text": "邻水县",
                "level": 3,
                "order": 2446,
                "relation_code": "511623",
                "relation_desc": "四川省邻水县"
            },
            {
                "code": "231405",
                "parentcode": "2314",
                "parenttext": "广安市",
                "text": "华蓥市",
                "level": 3,
                "order": 2447,
                "relation_code": "511681",
                "relation_desc": "四川省华蓥市"
            },
            {
                "code": "2315",
                "parentcode": "23",
                "parenttext": "四川省",
                "text": "达州市",
                "level": 2,
                "order": 2448,
                "relation_code": "511700",
                "relation_desc": "四川省达州市"
            },
            {
                "code": "231501",
                "parentcode": "2315",
                "parenttext": "达州市",
                "text": "通川区",
                "level": 3,
                "order": 2449,
                "relation_code": "511702",
                "relation_desc": "四川省达州市通川区"
            },
            {
                "code": "231502",
                "parentcode": "2315",
                "parenttext": "达州市",
                "text": "达川区",
                "level": 3,
                "order": 2450,
                "relation_code": "511703",
                "relation_desc": "四川省达州市达川区"
            },
            {
                "code": "231503",
                "parentcode": "2315",
                "parenttext": "达州市",
                "text": "宣汉县",
                "level": 3,
                "order": 2451,
                "relation_code": "511722",
                "relation_desc": "四川省宣汉县"
            },
            {
                "code": "231504",
                "parentcode": "2315",
                "parenttext": "达州市",
                "text": "开江县",
                "level": 3,
                "order": 2452,
                "relation_code": "511723",
                "relation_desc": "四川省开江县"
            },
            {
                "code": "231505",
                "parentcode": "2315",
                "parenttext": "达州市",
                "text": "大竹县",
                "level": 3,
                "order": 2453,
                "relation_code": "511724",
                "relation_desc": "四川省大竹县"
            },
            {
                "code": "231506",
                "parentcode": "2315",
                "parenttext": "达州市",
                "text": "渠县",
                "level": 3,
                "order": 2454,
                "relation_code": "511725",
                "relation_desc": "四川省渠县"
            },
            {
                "code": "231507",
                "parentcode": "2315",
                "parenttext": "达州市",
                "text": "万源市",
                "level": 3,
                "order": 2455,
                "relation_code": "511781",
                "relation_desc": "四川省万源市"
            },
            {
                "code": "2316",
                "parentcode": "23",
                "parenttext": "四川省",
                "text": "雅安市",
                "level": 2,
                "order": 2456,
                "relation_code": "511800",
                "relation_desc": "四川省雅安市"
            },
            {
                "code": "231508",
                "parentcode": "2315",
                "parenttext": "达州市",
                "text": "达州经济开发区",
                "level": 3,
                "order": 2456,
                "relation_code": "511701",
                "relation_desc": "四川省达州市"
            },
            {
                "code": "231601",
                "parentcode": "2316",
                "parenttext": "雅安市",
                "text": "雨城区",
                "level": 3,
                "order": 2457,
                "relation_code": "511802",
                "relation_desc": "四川省雅安市雨城区"
            },
            {
                "code": "231602",
                "parentcode": "2316",
                "parenttext": "雅安市",
                "text": "名山区",
                "level": 3,
                "order": 2458,
                "relation_code": "511803",
                "relation_desc": "四川省雅安市名山区"
            },
            {
                "code": "231603",
                "parentcode": "2316",
                "parenttext": "雅安市",
                "text": "荥经县",
                "level": 3,
                "order": 2459,
                "relation_code": "511822",
                "relation_desc": "四川省荥经县"
            },
            {
                "code": "231604",
                "parentcode": "2316",
                "parenttext": "雅安市",
                "text": "汉源县",
                "level": 3,
                "order": 2460,
                "relation_code": "511823",
                "relation_desc": "四川省汉源县"
            },
            {
                "code": "231605",
                "parentcode": "2316",
                "parenttext": "雅安市",
                "text": "石棉县",
                "level": 3,
                "order": 2461,
                "relation_code": "511824",
                "relation_desc": "四川省石棉县"
            },
            {
                "code": "231606",
                "parentcode": "2316",
                "parenttext": "雅安市",
                "text": "天全县",
                "level": 3,
                "order": 2462,
                "relation_code": "511825",
                "relation_desc": "四川省天全县"
            },
            {
                "code": "231607",
                "parentcode": "2316",
                "parenttext": "雅安市",
                "text": "芦山县",
                "level": 3,
                "order": 2463,
                "relation_code": "511826",
                "relation_desc": "四川省芦山县"
            },
            {
                "code": "231608",
                "parentcode": "2316",
                "parenttext": "雅安市",
                "text": "宝兴县",
                "level": 3,
                "order": 2464,
                "relation_code": "511827",
                "relation_desc": "四川省宝兴县"
            },
            {
                "code": "2317",
                "parentcode": "23",
                "parenttext": "四川省",
                "text": "巴中市",
                "level": 2,
                "order": 2465,
                "relation_code": "511900",
                "relation_desc": "四川省巴中市"
            },
            {
                "code": "231701",
                "parentcode": "2317",
                "parenttext": "巴中市",
                "text": "巴州区",
                "level": 3,
                "order": 2466,
                "relation_code": "511902",
                "relation_desc": "四川省巴中市巴州区"
            },
            {
                "code": "231702",
                "parentcode": "2317",
                "parenttext": "巴中市",
                "text": "通江县",
                "level": 3,
                "order": 2467,
                "relation_code": "511921",
                "relation_desc": "四川省通江县"
            },
            {
                "code": "231703",
                "parentcode": "2317",
                "parenttext": "巴中市",
                "text": "南江县",
                "level": 3,
                "order": 2468,
                "relation_code": "511922",
                "relation_desc": "四川省南江县"
            },
            {
                "code": "231704",
                "parentcode": "2317",
                "parenttext": "巴中市",
                "text": "平昌县",
                "level": 3,
                "order": 2469,
                "relation_code": "511923",
                "relation_desc": "四川省平昌县"
            },
            {
                "code": "231706",
                "parentcode": "2317",
                "parenttext": "巴中市",
                "text": "巴中经济开发区",
                "level": 3,
                "order": 2469,
                "relation_code": "511901",
                "relation_desc": "四川省巴中市"
            },
            {
                "code": "2318",
                "parentcode": "23",
                "parenttext": "四川省",
                "text": "资阳市",
                "level": 2,
                "order": 2470,
                "relation_code": "512000",
                "relation_desc": "四川省资阳市"
            },
            {
                "code": "231801",
                "parentcode": "2318",
                "parenttext": "资阳市",
                "text": "雁江区",
                "level": 3,
                "order": 2471,
                "relation_code": "512002",
                "relation_desc": "四川省资阳市雁江区"
            },
            {
                "code": "231802",
                "parentcode": "2318",
                "parenttext": "资阳市",
                "text": "安岳县",
                "level": 3,
                "order": 2472,
                "relation_code": "512021",
                "relation_desc": "四川省安岳县"
            },
            {
                "code": "231803",
                "parentcode": "2318",
                "parenttext": "资阳市",
                "text": "乐至县",
                "level": 3,
                "order": 2473,
                "relation_code": "512022",
                "relation_desc": "四川省乐至县"
            },
            {
                "code": "231804",
                "parentcode": "2318",
                "parenttext": "资阳市",
                "text": "简阳市",
                "level": 3,
                "order": 2474,
                "relation_code": "510185",
                "relation_desc": "四川省简阳市"
            },
            {
                "code": "2319",
                "parentcode": "23",
                "parenttext": "四川省",
                "text": "阿坝藏族羌族自治州",
                "level": 2,
                "order": 2475,
                "relation_code": "513200",
                "relation_desc": "四川省阿坝藏族羌族自治州"
            },
            {
                "code": "231901",
                "parentcode": "2319",
                "parenttext": "阿坝藏族羌族自治州",
                "text": "汶川县",
                "level": 3,
                "order": 2476,
                "relation_code": "513221",
                "relation_desc": "四川省汶川县"
            },
            {
                "code": "231902",
                "parentcode": "2319",
                "parenttext": "阿坝藏族羌族自治州",
                "text": "理县",
                "level": 3,
                "order": 2477,
                "relation_code": "513222",
                "relation_desc": "四川省理县"
            },
            {
                "code": "231903",
                "parentcode": "2319",
                "parenttext": "阿坝藏族羌族自治州",
                "text": "茂县",
                "level": 3,
                "order": 2478,
                "relation_code": "513223",
                "relation_desc": "四川省茂县"
            },
            {
                "code": "231904",
                "parentcode": "2319",
                "parenttext": "阿坝藏族羌族自治州",
                "text": "松潘县",
                "level": 3,
                "order": 2479,
                "relation_code": "513224",
                "relation_desc": "四川省松潘县"
            },
            {
                "code": "231905",
                "parentcode": "2319",
                "parenttext": "阿坝藏族羌族自治州",
                "text": "九寨沟县",
                "level": 3,
                "order": 2480,
                "relation_code": "513225",
                "relation_desc": "四川省九寨沟县"
            },
            {
                "code": "231906",
                "parentcode": "2319",
                "parenttext": "阿坝藏族羌族自治州",
                "text": "金川县",
                "level": 3,
                "order": 2481,
                "relation_code": "513226",
                "relation_desc": "四川省金川县"
            },
            {
                "code": "231907",
                "parentcode": "2319",
                "parenttext": "阿坝藏族羌族自治州",
                "text": "小金县",
                "level": 3,
                "order": 2482,
                "relation_code": "513227",
                "relation_desc": "四川省小金县"
            },
            {
                "code": "231908",
                "parentcode": "2319",
                "parenttext": "阿坝藏族羌族自治州",
                "text": "黑水县",
                "level": 3,
                "order": 2483,
                "relation_code": "513228",
                "relation_desc": "四川省黑水县"
            },
            {
                "code": "231909",
                "parentcode": "2319",
                "parenttext": "阿坝藏族羌族自治州",
                "text": "马尔康市",
                "level": 3,
                "order": 2484,
                "relation_code": "513201",
                "relation_desc": "四川省马尔康市"
            },
            {
                "code": "231910",
                "parentcode": "2319",
                "parenttext": "阿坝藏族羌族自治州",
                "text": "壤塘县",
                "level": 3,
                "order": 2485,
                "relation_code": "513230",
                "relation_desc": "四川省壤塘县"
            },
            {
                "code": "231911",
                "parentcode": "2319",
                "parenttext": "阿坝藏族羌族自治州",
                "text": "阿坝县",
                "level": 3,
                "order": 2486,
                "relation_code": "513231",
                "relation_desc": "四川省阿坝县"
            },
            {
                "code": "231912",
                "parentcode": "2319",
                "parenttext": "阿坝藏族羌族自治州",
                "text": "若尔盖县",
                "level": 3,
                "order": 2487,
                "relation_code": "513232",
                "relation_desc": "四川省若尔盖县"
            },
            {
                "code": "231913",
                "parentcode": "2319",
                "parenttext": "阿坝藏族羌族自治州",
                "text": "红原县",
                "level": 3,
                "order": 2488,
                "relation_code": "513233",
                "relation_desc": "四川省红原县"
            },
            {
                "code": "2320",
                "parentcode": "23",
                "parenttext": "四川省",
                "text": "甘孜藏族自治州",
                "level": 2,
                "order": 2489,
                "relation_code": "513300",
                "relation_desc": "四川省甘孜藏族自治州"
            },
            {
                "code": "232001",
                "parentcode": "2320",
                "parenttext": "甘孜藏族自治州",
                "text": "康定市",
                "level": 3,
                "order": 2490,
                "relation_code": "513321",
                "relation_desc": "四川省甘孜藏族自治州"
            },
            {
                "code": "232002",
                "parentcode": "2320",
                "parenttext": "甘孜藏族自治州",
                "text": "泸定县",
                "level": 3,
                "order": 2491,
                "relation_code": "513322",
                "relation_desc": "四川省泸定县"
            },
            {
                "code": "232003",
                "parentcode": "2320",
                "parenttext": "甘孜藏族自治州",
                "text": "丹巴县",
                "level": 3,
                "order": 2492,
                "relation_code": "513323",
                "relation_desc": "四川省丹巴县"
            },
            {
                "code": "232004",
                "parentcode": "2320",
                "parenttext": "甘孜藏族自治州",
                "text": "九龙县",
                "level": 3,
                "order": 2493,
                "relation_code": "513324",
                "relation_desc": "四川省九龙县"
            },
            {
                "code": "232005",
                "parentcode": "2320",
                "parenttext": "甘孜藏族自治州",
                "text": "雅江县",
                "level": 3,
                "order": 2494,
                "relation_code": "513325",
                "relation_desc": "四川省雅江县"
            },
            {
                "code": "232006",
                "parentcode": "2320",
                "parenttext": "甘孜藏族自治州",
                "text": "道孚县",
                "level": 3,
                "order": 2495,
                "relation_code": "513326",
                "relation_desc": "四川省道孚县"
            },
            {
                "code": "232007",
                "parentcode": "2320",
                "parenttext": "甘孜藏族自治州",
                "text": "炉霍县",
                "level": 3,
                "order": 2496,
                "relation_code": "513327",
                "relation_desc": "四川省炉霍县"
            },
            {
                "code": "232008",
                "parentcode": "2320",
                "parenttext": "甘孜藏族自治州",
                "text": "甘孜县",
                "level": 3,
                "order": 2497,
                "relation_code": "513328",
                "relation_desc": "四川省甘孜县"
            },
            {
                "code": "232009",
                "parentcode": "2320",
                "parenttext": "甘孜藏族自治州",
                "text": "新龙县",
                "level": 3,
                "order": 2498,
                "relation_code": "513329",
                "relation_desc": "四川省新龙县"
            },
            {
                "code": "232010",
                "parentcode": "2320",
                "parenttext": "甘孜藏族自治州",
                "text": "德格县",
                "level": 3,
                "order": 2499,
                "relation_code": "513330",
                "relation_desc": "四川省德格县"
            },
            {
                "code": "232011",
                "parentcode": "2320",
                "parenttext": "甘孜藏族自治州",
                "text": "白玉县",
                "level": 3,
                "order": 2500,
                "relation_code": "513331",
                "relation_desc": "四川省白玉县"
            },
            {
                "code": "232012",
                "parentcode": "2320",
                "parenttext": "甘孜藏族自治州",
                "text": "石渠县",
                "level": 3,
                "order": 2501,
                "relation_code": "513332",
                "relation_desc": "四川省石渠县"
            },
            {
                "code": "232013",
                "parentcode": "2320",
                "parenttext": "甘孜藏族自治州",
                "text": "色达县",
                "level": 3,
                "order": 2502,
                "relation_code": "513333",
                "relation_desc": "四川省色达县"
            },
            {
                "code": "232014",
                "parentcode": "2320",
                "parenttext": "甘孜藏族自治州",
                "text": "理塘县",
                "level": 3,
                "order": 2503,
                "relation_code": "513334",
                "relation_desc": "四川省理塘县"
            },
            {
                "code": "232015",
                "parentcode": "2320",
                "parenttext": "甘孜藏族自治州",
                "text": "巴塘县",
                "level": 3,
                "order": 2504,
                "relation_code": "513335",
                "relation_desc": "四川省巴塘县"
            },
            {
                "code": "232016",
                "parentcode": "2320",
                "parenttext": "甘孜藏族自治州",
                "text": "乡城县",
                "level": 3,
                "order": 2505,
                "relation_code": "513336",
                "relation_desc": "四川省乡城县"
            },
            {
                "code": "232017",
                "parentcode": "2320",
                "parenttext": "甘孜藏族自治州",
                "text": "稻城县",
                "level": 3,
                "order": 2506,
                "relation_code": "513337",
                "relation_desc": "四川省稻城县"
            },
            {
                "code": "232018",
                "parentcode": "2320",
                "parenttext": "甘孜藏族自治州",
                "text": "得荣县",
                "level": 3,
                "order": 2507,
                "relation_code": "513338",
                "relation_desc": "四川省得荣县"
            },
            {
                "code": "2321",
                "parentcode": "23",
                "parenttext": "四川省",
                "text": "凉山彝族自治州",
                "level": 2,
                "order": 2508,
                "relation_code": "513400",
                "relation_desc": "四川省凉山彝族自治州"
            },
            {
                "code": "232101",
                "parentcode": "2321",
                "parenttext": "凉山彝族自治州",
                "text": "西昌市",
                "level": 3,
                "order": 2509,
                "relation_code": "513401",
                "relation_desc": "四川省西昌市"
            },
            {
                "code": "232102",
                "parentcode": "2321",
                "parenttext": "凉山彝族自治州",
                "text": "木里藏族自治县",
                "level": 3,
                "order": 2510,
                "relation_code": "513422",
                "relation_desc": "四川省木里藏族自治县"
            },
            {
                "code": "232103",
                "parentcode": "2321",
                "parenttext": "凉山彝族自治州",
                "text": "盐源县",
                "level": 3,
                "order": 2511,
                "relation_code": "513423",
                "relation_desc": "四川省盐源县"
            },
            {
                "code": "232104",
                "parentcode": "2321",
                "parenttext": "凉山彝族自治州",
                "text": "德昌县",
                "level": 3,
                "order": 2512,
                "relation_code": "513424",
                "relation_desc": "四川省德昌县"
            },
            {
                "code": "232105",
                "parentcode": "2321",
                "parenttext": "凉山彝族自治州",
                "text": "会理县",
                "level": 3,
                "order": 2513,
                "relation_code": "513425",
                "relation_desc": "四川省会理县"
            },
            {
                "code": "232106",
                "parentcode": "2321",
                "parenttext": "凉山彝族自治州",
                "text": "会东县",
                "level": 3,
                "order": 2514,
                "relation_code": "513426",
                "relation_desc": "四川省会东县"
            },
            {
                "code": "232107",
                "parentcode": "2321",
                "parenttext": "凉山彝族自治州",
                "text": "宁南县",
                "level": 3,
                "order": 2515,
                "relation_code": "513427",
                "relation_desc": "四川省宁南县"
            },
            {
                "code": "232108",
                "parentcode": "2321",
                "parenttext": "凉山彝族自治州",
                "text": "普格县",
                "level": 3,
                "order": 2516,
                "relation_code": "513428",
                "relation_desc": "四川省普格县"
            },
            {
                "code": "232109",
                "parentcode": "2321",
                "parenttext": "凉山彝族自治州",
                "text": "布拖县",
                "level": 3,
                "order": 2517,
                "relation_code": "513429",
                "relation_desc": "四川省布拖县"
            },
            {
                "code": "232110",
                "parentcode": "2321",
                "parenttext": "凉山彝族自治州",
                "text": "金阳县",
                "level": 3,
                "order": 2518,
                "relation_code": "513430",
                "relation_desc": "四川省金阳县"
            },
            {
                "code": "232111",
                "parentcode": "2321",
                "parenttext": "凉山彝族自治州",
                "text": "昭觉县",
                "level": 3,
                "order": 2519,
                "relation_code": "513431",
                "relation_desc": "四川省昭觉县"
            },
            {
                "code": "232112",
                "parentcode": "2321",
                "parenttext": "凉山彝族自治州",
                "text": "喜德县",
                "level": 3,
                "order": 2520,
                "relation_code": "513432",
                "relation_desc": "四川省喜德县"
            },
            {
                "code": "232113",
                "parentcode": "2321",
                "parenttext": "凉山彝族自治州",
                "text": "冕宁县",
                "level": 3,
                "order": 2521,
                "relation_code": "513433",
                "relation_desc": "四川省冕宁县"
            },
            {
                "code": "232114",
                "parentcode": "2321",
                "parenttext": "凉山彝族自治州",
                "text": "越西县",
                "level": 3,
                "order": 2522,
                "relation_code": "513434",
                "relation_desc": "四川省越西县"
            },
            {
                "code": "232115",
                "parentcode": "2321",
                "parenttext": "凉山彝族自治州",
                "text": "甘洛县",
                "level": 3,
                "order": 2523,
                "relation_code": "513435",
                "relation_desc": "四川省甘洛县"
            },
            {
                "code": "232116",
                "parentcode": "2321",
                "parenttext": "凉山彝族自治州",
                "text": "美姑县",
                "level": 3,
                "order": 2524,
                "relation_code": "513436",
                "relation_desc": "四川省美姑县"
            },
            {
                "code": "232117",
                "parentcode": "2321",
                "parenttext": "凉山彝族自治州",
                "text": "雷波县",
                "level": 3,
                "order": 2525,
                "relation_code": "513437",
                "relation_desc": "四川省雷波县"
            },
            {
                "code": "24",
                "parentcode": "0",
                "parenttext": "0",
                "text": "贵州省",
                "level": 1,
                "order": 2526,
                "relation_code": "",
                "relation_desc": "贵州省"
            },
            {
                "code": "2401",
                "parentcode": "24",
                "parenttext": "贵州省",
                "text": "贵阳市",
                "level": 2,
                "order": 2527,
                "relation_code": "520100",
                "relation_desc": "贵州省贵阳市"
            },
            {
                "code": "240101",
                "parentcode": "2401",
                "parenttext": "贵阳市",
                "text": "南明区",
                "level": 3,
                "order": 2528,
                "relation_code": "520102",
                "relation_desc": "贵州省贵阳市南明区"
            },
            {
                "code": "240102",
                "parentcode": "2401",
                "parenttext": "贵阳市",
                "text": "云岩区",
                "level": 3,
                "order": 2529,
                "relation_code": "520103",
                "relation_desc": "贵州省贵阳市云岩区"
            },
            {
                "code": "240103",
                "parentcode": "2401",
                "parenttext": "贵阳市",
                "text": "花溪区",
                "level": 3,
                "order": 2530,
                "relation_code": "520111",
                "relation_desc": "贵州省贵阳市花溪区"
            },
            {
                "code": "240104",
                "parentcode": "2401",
                "parenttext": "贵阳市",
                "text": "乌当区",
                "level": 3,
                "order": 2531,
                "relation_code": "520112",
                "relation_desc": "贵州省贵阳市乌当区"
            },
            {
                "code": "240105",
                "parentcode": "2401",
                "parenttext": "贵阳市",
                "text": "白云区",
                "level": 3,
                "order": 2532,
                "relation_code": "520113",
                "relation_desc": "贵州省贵阳市白云区"
            },
            {
                "code": "240106",
                "parentcode": "2401",
                "parenttext": "贵阳市",
                "text": "观山湖区",
                "level": 3,
                "order": 2533,
                "relation_code": "520115",
                "relation_desc": "贵州省贵阳市观山湖区"
            },
            {
                "code": "240107",
                "parentcode": "2401",
                "parenttext": "贵阳市",
                "text": "开阳县",
                "level": 3,
                "order": 2534,
                "relation_code": "520121",
                "relation_desc": "贵州省开阳县"
            },
            {
                "code": "240108",
                "parentcode": "2401",
                "parenttext": "贵阳市",
                "text": "息烽县",
                "level": 3,
                "order": 2535,
                "relation_code": "520122",
                "relation_desc": "贵州省息烽县"
            },
            {
                "code": "240109",
                "parentcode": "2401",
                "parenttext": "贵阳市",
                "text": "修文县",
                "level": 3,
                "order": 2536,
                "relation_code": "520123",
                "relation_desc": "贵州省修文县"
            },
            {
                "code": "240110",
                "parentcode": "2401",
                "parenttext": "贵阳市",
                "text": "清镇市",
                "level": 3,
                "order": 2537,
                "relation_code": "520181",
                "relation_desc": "贵州省清镇市"
            },
            {
                "code": "2402",
                "parentcode": "24",
                "parenttext": "贵州省",
                "text": "六盘水市",
                "level": 2,
                "order": 2538,
                "relation_code": "520200",
                "relation_desc": "贵州省六盘水市"
            },
            {
                "code": "240201",
                "parentcode": "2402",
                "parenttext": "六盘水市",
                "text": "钟山区",
                "level": 3,
                "order": 2539,
                "relation_code": "520201",
                "relation_desc": "贵州省六盘水市钟山区"
            },
            {
                "code": "240202",
                "parentcode": "2402",
                "parenttext": "六盘水市",
                "text": "六枝特区",
                "level": 3,
                "order": 2540,
                "relation_code": "520203",
                "relation_desc": "贵州省六盘水市六枝特区"
            },
            {
                "code": "240203",
                "parentcode": "2402",
                "parenttext": "六盘水市",
                "text": "水城县",
                "level": 3,
                "order": 2541,
                "relation_code": "520221",
                "relation_desc": "贵州省水城县"
            },
            {
                "code": "240204",
                "parentcode": "2402",
                "parenttext": "六盘水市",
                "text": "盘州市",
                "level": 3,
                "order": 2542,
                "relation_code": "520281",
                "relation_desc": "贵州省盘州市"
            },
            {
                "code": "2403",
                "parentcode": "24",
                "parenttext": "贵州省",
                "text": "遵义市",
                "level": 2,
                "order": 2543,
                "relation_code": "520300",
                "relation_desc": "贵州省遵义市"
            },
            {
                "code": "240301",
                "parentcode": "2403",
                "parenttext": "遵义市",
                "text": "红花岗区",
                "level": 3,
                "order": 2544,
                "relation_code": "520302",
                "relation_desc": "贵州省遵义市红花岗区"
            },
            {
                "code": "240302",
                "parentcode": "2403",
                "parenttext": "遵义市",
                "text": "汇川区",
                "level": 3,
                "order": 2545,
                "relation_code": "520303",
                "relation_desc": "贵州省遵义市汇川区"
            },
            {
                "code": "240303",
                "parentcode": "2403",
                "parenttext": "遵义市",
                "text": "播州区",
                "level": 3,
                "order": 2546,
                "relation_code": "520304",
                "relation_desc": "贵州省遵义市播州区"
            },
            {
                "code": "240304",
                "parentcode": "2403",
                "parenttext": "遵义市",
                "text": "桐梓县",
                "level": 3,
                "order": 2547,
                "relation_code": "520322",
                "relation_desc": "贵州省桐梓县"
            },
            {
                "code": "240305",
                "parentcode": "2403",
                "parenttext": "遵义市",
                "text": "绥阳县",
                "level": 3,
                "order": 2548,
                "relation_code": "520323",
                "relation_desc": "贵州省绥阳县"
            },
            {
                "code": "240306",
                "parentcode": "2403",
                "parenttext": "遵义市",
                "text": "正安县",
                "level": 3,
                "order": 2549,
                "relation_code": "520324",
                "relation_desc": "贵州省正安县"
            },
            {
                "code": "240307",
                "parentcode": "2403",
                "parenttext": "遵义市",
                "text": "道真仡佬族苗族自治县",
                "level": 3,
                "order": 2550,
                "relation_code": "520325",
                "relation_desc": "贵州省道真仡佬族苗族自治县"
            },
            {
                "code": "240308",
                "parentcode": "2403",
                "parenttext": "遵义市",
                "text": "务川仡佬族苗族自治县",
                "level": 3,
                "order": 2551,
                "relation_code": "520326",
                "relation_desc": "贵州省务川仡佬族苗族自治县"
            },
            {
                "code": "240309",
                "parentcode": "2403",
                "parenttext": "遵义市",
                "text": "凤冈县",
                "level": 3,
                "order": 2552,
                "relation_code": "520327",
                "relation_desc": "贵州省凤冈县"
            },
            {
                "code": "240310",
                "parentcode": "2403",
                "parenttext": "遵义市",
                "text": "湄潭县",
                "level": 3,
                "order": 2553,
                "relation_code": "520328",
                "relation_desc": "贵州省湄潭县"
            },
            {
                "code": "240311",
                "parentcode": "2403",
                "parenttext": "遵义市",
                "text": "余庆县",
                "level": 3,
                "order": 2554,
                "relation_code": "520329",
                "relation_desc": "贵州省余庆县"
            },
            {
                "code": "240312",
                "parentcode": "2403",
                "parenttext": "遵义市",
                "text": "习水县",
                "level": 3,
                "order": 2555,
                "relation_code": "520330",
                "relation_desc": "贵州省习水县"
            },
            {
                "code": "240313",
                "parentcode": "2403",
                "parenttext": "遵义市",
                "text": "赤水市",
                "level": 3,
                "order": 2556,
                "relation_code": "520381",
                "relation_desc": "贵州省赤水市"
            },
            {
                "code": "240314",
                "parentcode": "2403",
                "parenttext": "遵义市",
                "text": "仁怀市",
                "level": 3,
                "order": 2557,
                "relation_code": "520382",
                "relation_desc": "贵州省仁怀市"
            },
            {
                "code": "2404",
                "parentcode": "24",
                "parenttext": "贵州省",
                "text": "安顺市",
                "level": 2,
                "order": 2558,
                "relation_code": "520400",
                "relation_desc": "贵州省安顺市"
            },
            {
                "code": "240401",
                "parentcode": "2404",
                "parenttext": "安顺市",
                "text": "西秀区",
                "level": 3,
                "order": 2559,
                "relation_code": "520402",
                "relation_desc": "贵州省安顺市西秀区"
            },
            {
                "code": "240402",
                "parentcode": "2404",
                "parenttext": "安顺市",
                "text": "平坝区",
                "level": 3,
                "order": 2560,
                "relation_code": "520403",
                "relation_desc": "贵州省安顺市平坝区"
            },
            {
                "code": "240403",
                "parentcode": "2404",
                "parenttext": "安顺市",
                "text": "普定县",
                "level": 3,
                "order": 2561,
                "relation_code": "520422",
                "relation_desc": "贵州省普定县"
            },
            {
                "code": "240404",
                "parentcode": "2404",
                "parenttext": "安顺市",
                "text": "镇宁布依族苗族自治县",
                "level": 3,
                "order": 2562,
                "relation_code": "520423",
                "relation_desc": "贵州省镇宁布依族苗族自治县"
            },
            {
                "code": "240405",
                "parentcode": "2404",
                "parenttext": "安顺市",
                "text": "关岭布依族苗族自治县",
                "level": 3,
                "order": 2563,
                "relation_code": "520424",
                "relation_desc": "贵州省关岭布依族苗族自治县"
            },
            {
                "code": "240406",
                "parentcode": "2404",
                "parenttext": "安顺市",
                "text": "紫云苗族布依族自治县",
                "level": 3,
                "order": 2564,
                "relation_code": "520425",
                "relation_desc": "贵州省紫云苗族布依族自治县"
            },
            {
                "code": "2405",
                "parentcode": "24",
                "parenttext": "贵州省",
                "text": "铜仁市",
                "level": 2,
                "order": 2565,
                "relation_code": "520600",
                "relation_desc": "贵州省铜仁市"
            },
            {
                "code": "240501",
                "parentcode": "2405",
                "parenttext": "铜仁市",
                "text": "碧江区",
                "level": 3,
                "order": 2566,
                "relation_code": "520602",
                "relation_desc": "贵州省铜仁市碧江区"
            },
            {
                "code": "240502",
                "parentcode": "2405",
                "parenttext": "铜仁市",
                "text": "江口县",
                "level": 3,
                "order": 2567,
                "relation_code": "520621",
                "relation_desc": "贵州省江口县"
            },
            {
                "code": "240503",
                "parentcode": "2405",
                "parenttext": "铜仁市",
                "text": "玉屏侗族自治县",
                "level": 3,
                "order": 2568,
                "relation_code": "520622",
                "relation_desc": "贵州省玉屏侗族自治县"
            },
            {
                "code": "240504",
                "parentcode": "2405",
                "parenttext": "铜仁市",
                "text": "石阡县",
                "level": 3,
                "order": 2569,
                "relation_code": "520623",
                "relation_desc": "贵州省石阡县"
            },
            {
                "code": "240505",
                "parentcode": "2405",
                "parenttext": "铜仁市",
                "text": "思南县",
                "level": 3,
                "order": 2570,
                "relation_code": "520624",
                "relation_desc": "贵州省思南县"
            },
            {
                "code": "240506",
                "parentcode": "2405",
                "parenttext": "铜仁市",
                "text": "印江土家族苗族自治县",
                "level": 3,
                "order": 2571,
                "relation_code": "520625",
                "relation_desc": "贵州省印江土家族苗族自治县"
            },
            {
                "code": "240507",
                "parentcode": "2405",
                "parenttext": "铜仁市",
                "text": "德江县",
                "level": 3,
                "order": 2572,
                "relation_code": "520626",
                "relation_desc": "贵州省德江县"
            },
            {
                "code": "240508",
                "parentcode": "2405",
                "parenttext": "铜仁市",
                "text": "沿河土家族自治县",
                "level": 3,
                "order": 2573,
                "relation_code": "520627",
                "relation_desc": "贵州省沿河土家族自治县"
            },
            {
                "code": "240509",
                "parentcode": "2405",
                "parenttext": "铜仁市",
                "text": "松桃苗族自治县",
                "level": 3,
                "order": 2574,
                "relation_code": "520628",
                "relation_desc": "贵州省松桃苗族自治县"
            },
            {
                "code": "240510",
                "parentcode": "2405",
                "parenttext": "铜仁市",
                "text": "万山区",
                "level": 3,
                "order": 2575,
                "relation_code": "520603",
                "relation_desc": "贵州省铜仁市万山区"
            },
            {
                "code": "2406",
                "parentcode": "24",
                "parenttext": "贵州省",
                "text": "黔西南布依族苗族自治州",
                "level": 2,
                "order": 2576,
                "relation_code": "522300",
                "relation_desc": "贵州省黔西南布依族苗族自治州"
            },
            {
                "code": "240601",
                "parentcode": "2406",
                "parenttext": "黔西南布依族苗族自治州",
                "text": "兴义市",
                "level": 3,
                "order": 2577,
                "relation_code": "522301",
                "relation_desc": "贵州省兴义市"
            },
            {
                "code": "240602",
                "parentcode": "2406",
                "parenttext": "黔西南布依族苗族自治州",
                "text": "兴仁市",
                "level": 3,
                "order": 2578,
                "relation_code": "522302",
                "relation_desc": "贵州省兴仁市"
            },
            {
                "code": "240603",
                "parentcode": "2406",
                "parenttext": "黔西南布依族苗族自治州",
                "text": "普安县",
                "level": 3,
                "order": 2579,
                "relation_code": "522323",
                "relation_desc": "贵州省普安县"
            },
            {
                "code": "240604",
                "parentcode": "2406",
                "parenttext": "黔西南布依族苗族自治州",
                "text": "晴隆县",
                "level": 3,
                "order": 2580,
                "relation_code": "522324",
                "relation_desc": "贵州省晴隆县"
            },
            {
                "code": "240605",
                "parentcode": "2406",
                "parenttext": "黔西南布依族苗族自治州",
                "text": "贞丰县",
                "level": 3,
                "order": 2581,
                "relation_code": "522325",
                "relation_desc": "贵州省贞丰县"
            },
            {
                "code": "240606",
                "parentcode": "2406",
                "parenttext": "黔西南布依族苗族自治州",
                "text": "望谟县",
                "level": 3,
                "order": 2582,
                "relation_code": "522326",
                "relation_desc": "贵州省望谟县"
            },
            {
                "code": "240607",
                "parentcode": "2406",
                "parenttext": "黔西南布依族苗族自治州",
                "text": "册亨县",
                "level": 3,
                "order": 2583,
                "relation_code": "522327",
                "relation_desc": "贵州省册亨县"
            },
            {
                "code": "240608",
                "parentcode": "2406",
                "parenttext": "黔西南布依族苗族自治州",
                "text": "安龙县",
                "level": 3,
                "order": 2584,
                "relation_code": "522328",
                "relation_desc": "贵州省安龙县"
            },
            {
                "code": "2407",
                "parentcode": "24",
                "parenttext": "贵州省",
                "text": "毕节市",
                "level": 2,
                "order": 2585,
                "relation_code": "520500",
                "relation_desc": "贵州省毕节市"
            },
            {
                "code": "240701",
                "parentcode": "2407",
                "parenttext": "毕节市",
                "text": "七星关区",
                "level": 3,
                "order": 2586,
                "relation_code": "520502",
                "relation_desc": "贵州省毕节市七星关区"
            },
            {
                "code": "240702",
                "parentcode": "2407",
                "parenttext": "毕节市",
                "text": "大方县",
                "level": 3,
                "order": 2587,
                "relation_code": "520521",
                "relation_desc": "贵州省大方县"
            },
            {
                "code": "240703",
                "parentcode": "2407",
                "parenttext": "毕节市",
                "text": "黔西县",
                "level": 3,
                "order": 2588,
                "relation_code": "520522",
                "relation_desc": "贵州省黔西县"
            },
            {
                "code": "240704",
                "parentcode": "2407",
                "parenttext": "毕节市",
                "text": "金沙县",
                "level": 3,
                "order": 2589,
                "relation_code": "520523",
                "relation_desc": "贵州省金沙县"
            },
            {
                "code": "240705",
                "parentcode": "2407",
                "parenttext": "毕节市",
                "text": "织金县",
                "level": 3,
                "order": 2590,
                "relation_code": "520524",
                "relation_desc": "贵州省织金县"
            },
            {
                "code": "240706",
                "parentcode": "2407",
                "parenttext": "毕节市",
                "text": "纳雍县",
                "level": 3,
                "order": 2591,
                "relation_code": "520525",
                "relation_desc": "贵州省纳雍县"
            },
            {
                "code": "240707",
                "parentcode": "2407",
                "parenttext": "毕节市",
                "text": "威宁彝族回族苗族自治县",
                "level": 3,
                "order": 2592,
                "relation_code": "520526",
                "relation_desc": "贵州省威宁彝族回族苗族自治县"
            },
            {
                "code": "240708",
                "parentcode": "2407",
                "parenttext": "毕节市",
                "text": "赫章县",
                "level": 3,
                "order": 2593,
                "relation_code": "520527",
                "relation_desc": "贵州省赫章县"
            },
            {
                "code": "2408",
                "parentcode": "24",
                "parenttext": "贵州省",
                "text": "黔东南苗族侗族自治州",
                "level": 2,
                "order": 2594,
                "relation_code": "522600",
                "relation_desc": "贵州省黔东南苗族侗族自治州"
            },
            {
                "code": "240801",
                "parentcode": "2408",
                "parenttext": "黔东南苗族侗族自治州",
                "text": "凯里市",
                "level": 3,
                "order": 2595,
                "relation_code": "522601",
                "relation_desc": "贵州省凯里市"
            },
            {
                "code": "240802",
                "parentcode": "2408",
                "parenttext": "黔东南苗族侗族自治州",
                "text": "黄平县",
                "level": 3,
                "order": 2596,
                "relation_code": "522622",
                "relation_desc": "贵州省黄平县"
            },
            {
                "code": "240803",
                "parentcode": "2408",
                "parenttext": "黔东南苗族侗族自治州",
                "text": "施秉县",
                "level": 3,
                "order": 2597,
                "relation_code": "522623",
                "relation_desc": "贵州省施秉县"
            },
            {
                "code": "240804",
                "parentcode": "2408",
                "parenttext": "黔东南苗族侗族自治州",
                "text": "三穗县",
                "level": 3,
                "order": 2598,
                "relation_code": "522624",
                "relation_desc": "贵州省三穗县"
            },
            {
                "code": "240805",
                "parentcode": "2408",
                "parenttext": "黔东南苗族侗族自治州",
                "text": "镇远县",
                "level": 3,
                "order": 2599,
                "relation_code": "522625",
                "relation_desc": "贵州省镇远县"
            },
            {
                "code": "240806",
                "parentcode": "2408",
                "parenttext": "黔东南苗族侗族自治州",
                "text": "岑巩县",
                "level": 3,
                "order": 2600,
                "relation_code": "522626",
                "relation_desc": "贵州省岑巩县"
            },
            {
                "code": "240807",
                "parentcode": "2408",
                "parenttext": "黔东南苗族侗族自治州",
                "text": "天柱县",
                "level": 3,
                "order": 2601,
                "relation_code": "522627",
                "relation_desc": "贵州省天柱县"
            },
            {
                "code": "240808",
                "parentcode": "2408",
                "parenttext": "黔东南苗族侗族自治州",
                "text": "锦屏县",
                "level": 3,
                "order": 2602,
                "relation_code": "522628",
                "relation_desc": "贵州省锦屏县"
            },
            {
                "code": "240809",
                "parentcode": "2408",
                "parenttext": "黔东南苗族侗族自治州",
                "text": "剑河县",
                "level": 3,
                "order": 2603,
                "relation_code": "522629",
                "relation_desc": "贵州省剑河县"
            },
            {
                "code": "240810",
                "parentcode": "2408",
                "parenttext": "黔东南苗族侗族自治州",
                "text": "台江县",
                "level": 3,
                "order": 2604,
                "relation_code": "522630",
                "relation_desc": "贵州省台江县"
            },
            {
                "code": "240811",
                "parentcode": "2408",
                "parenttext": "黔东南苗族侗族自治州",
                "text": "黎平县",
                "level": 3,
                "order": 2605,
                "relation_code": "522631",
                "relation_desc": "贵州省黎平县"
            },
            {
                "code": "240812",
                "parentcode": "2408",
                "parenttext": "黔东南苗族侗族自治州",
                "text": "榕江县",
                "level": 3,
                "order": 2606,
                "relation_code": "522632",
                "relation_desc": "贵州省榕江县"
            },
            {
                "code": "240813",
                "parentcode": "2408",
                "parenttext": "黔东南苗族侗族自治州",
                "text": "从江县",
                "level": 3,
                "order": 2607,
                "relation_code": "522633",
                "relation_desc": "贵州省从江县"
            },
            {
                "code": "240814",
                "parentcode": "2408",
                "parenttext": "黔东南苗族侗族自治州",
                "text": "雷山县",
                "level": 3,
                "order": 2608,
                "relation_code": "522634",
                "relation_desc": "贵州省雷山县"
            },
            {
                "code": "240815",
                "parentcode": "2408",
                "parenttext": "黔东南苗族侗族自治州",
                "text": "麻江县",
                "level": 3,
                "order": 2609,
                "relation_code": "522635",
                "relation_desc": "贵州省麻江县"
            },
            {
                "code": "240816",
                "parentcode": "2408",
                "parenttext": "黔东南苗族侗族自治州",
                "text": "丹寨县",
                "level": 3,
                "order": 2610,
                "relation_code": "522636",
                "relation_desc": "贵州省丹寨县"
            },
            {
                "code": "2409",
                "parentcode": "24",
                "parenttext": "贵州省",
                "text": "黔南布依族苗族自治州",
                "level": 2,
                "order": 2611,
                "relation_code": "522700",
                "relation_desc": "贵州省黔南布依族苗族自治州"
            },
            {
                "code": "240901",
                "parentcode": "2409",
                "parenttext": "黔南布依族苗族自治州",
                "text": "都匀市",
                "level": 3,
                "order": 2612,
                "relation_code": "522701",
                "relation_desc": "贵州省都匀市"
            },
            {
                "code": "240902",
                "parentcode": "2409",
                "parenttext": "黔南布依族苗族自治州",
                "text": "福泉市",
                "level": 3,
                "order": 2613,
                "relation_code": "522702",
                "relation_desc": "贵州省福泉市"
            },
            {
                "code": "240903",
                "parentcode": "2409",
                "parenttext": "黔南布依族苗族自治州",
                "text": "荔波县",
                "level": 3,
                "order": 2614,
                "relation_code": "522722",
                "relation_desc": "贵州省荔波县"
            },
            {
                "code": "240904",
                "parentcode": "2409",
                "parenttext": "黔南布依族苗族自治州",
                "text": "贵定县",
                "level": 3,
                "order": 2615,
                "relation_code": "522723",
                "relation_desc": "贵州省贵定县"
            },
            {
                "code": "240905",
                "parentcode": "2409",
                "parenttext": "黔南布依族苗族自治州",
                "text": "瓮安县",
                "level": 3,
                "order": 2616,
                "relation_code": "522725",
                "relation_desc": "贵州省瓮安县"
            },
            {
                "code": "240906",
                "parentcode": "2409",
                "parenttext": "黔南布依族苗族自治州",
                "text": "独山县",
                "level": 3,
                "order": 2617,
                "relation_code": "522726",
                "relation_desc": "贵州省独山县"
            },
            {
                "code": "240907",
                "parentcode": "2409",
                "parenttext": "黔南布依族苗族自治州",
                "text": "平塘县",
                "level": 3,
                "order": 2618,
                "relation_code": "522727",
                "relation_desc": "贵州省平塘县"
            },
            {
                "code": "240908",
                "parentcode": "2409",
                "parenttext": "黔南布依族苗族自治州",
                "text": "罗甸县",
                "level": 3,
                "order": 2619,
                "relation_code": "522728",
                "relation_desc": "贵州省罗甸县"
            },
            {
                "code": "240909",
                "parentcode": "2409",
                "parenttext": "黔南布依族苗族自治州",
                "text": "长顺县",
                "level": 3,
                "order": 2620,
                "relation_code": "522729",
                "relation_desc": "贵州省长顺县"
            },
            {
                "code": "240910",
                "parentcode": "2409",
                "parenttext": "黔南布依族苗族自治州",
                "text": "龙里县",
                "level": 3,
                "order": 2621,
                "relation_code": "522730",
                "relation_desc": "贵州省龙里县"
            },
            {
                "code": "240911",
                "parentcode": "2409",
                "parenttext": "黔南布依族苗族自治州",
                "text": "惠水县",
                "level": 3,
                "order": 2622,
                "relation_code": "522731",
                "relation_desc": "贵州省惠水县"
            },
            {
                "code": "240912",
                "parentcode": "2409",
                "parenttext": "黔南布依族苗族自治州",
                "text": "三都水族自治县",
                "level": 3,
                "order": 2623,
                "relation_code": "522732",
                "relation_desc": "贵州省三都水族自治县"
            },
            {
                "code": "25",
                "parentcode": "0",
                "parenttext": "0",
                "text": "云南省",
                "level": 1,
                "order": 2624,
                "relation_code": "",
                "relation_desc": "云南省"
            },
            {
                "code": "2501",
                "parentcode": "25",
                "parenttext": "云南省",
                "text": "昆明市",
                "level": 2,
                "order": 2625,
                "relation_code": "530100",
                "relation_desc": "云南省昆明市"
            },
            {
                "code": "250101",
                "parentcode": "2501",
                "parenttext": "昆明市",
                "text": "五华区",
                "level": 3,
                "order": 2626,
                "relation_code": "530102",
                "relation_desc": "云南省昆明市五华区"
            },
            {
                "code": "250102",
                "parentcode": "2501",
                "parenttext": "昆明市",
                "text": "盘龙区",
                "level": 3,
                "order": 2627,
                "relation_code": "530103",
                "relation_desc": "云南省昆明市盘龙区"
            },
            {
                "code": "250103",
                "parentcode": "2501",
                "parenttext": "昆明市",
                "text": "官渡区",
                "level": 3,
                "order": 2628,
                "relation_code": "530111",
                "relation_desc": "云南省昆明市官渡区"
            },
            {
                "code": "250104",
                "parentcode": "2501",
                "parenttext": "昆明市",
                "text": "西山区",
                "level": 3,
                "order": 2629,
                "relation_code": "530112",
                "relation_desc": "云南省昆明市西山区"
            },
            {
                "code": "250105",
                "parentcode": "2501",
                "parenttext": "昆明市",
                "text": "东川区",
                "level": 3,
                "order": 2630,
                "relation_code": "530113",
                "relation_desc": "云南省昆明市东川区"
            },
            {
                "code": "250106",
                "parentcode": "2501",
                "parenttext": "昆明市",
                "text": "呈贡区",
                "level": 3,
                "order": 2631,
                "relation_code": "530114",
                "relation_desc": "云南省昆明市呈贡区"
            },
            {
                "code": "250107",
                "parentcode": "2501",
                "parenttext": "昆明市",
                "text": "晋宁县",
                "level": 3,
                "order": 2632,
                "relation_code": "530101",
                "relation_desc": "云南省晋宁县"
            },
            {
                "code": "250108",
                "parentcode": "2501",
                "parenttext": "昆明市",
                "text": "富民县",
                "level": 3,
                "order": 2633,
                "relation_code": "530124",
                "relation_desc": "云南省富民县"
            },
            {
                "code": "250109",
                "parentcode": "2501",
                "parenttext": "昆明市",
                "text": "宜良县",
                "level": 3,
                "order": 2634,
                "relation_code": "530125",
                "relation_desc": "云南省宜良县"
            },
            {
                "code": "250110",
                "parentcode": "2501",
                "parenttext": "昆明市",
                "text": "石林彝族自治县",
                "level": 3,
                "order": 2635,
                "relation_code": "530126",
                "relation_desc": "云南省石林彝族自治县"
            },
            {
                "code": "250111",
                "parentcode": "2501",
                "parenttext": "昆明市",
                "text": "嵩明县",
                "level": 3,
                "order": 2636,
                "relation_code": "530127",
                "relation_desc": "云南省嵩明县"
            },
            {
                "code": "250112",
                "parentcode": "2501",
                "parenttext": "昆明市",
                "text": "禄劝彝族苗族自治县",
                "level": 3,
                "order": 2637,
                "relation_code": "530128",
                "relation_desc": "云南省禄劝彝族苗族自治县"
            },
            {
                "code": "250113",
                "parentcode": "2501",
                "parenttext": "昆明市",
                "text": "寻甸回族彝族自治县",
                "level": 3,
                "order": 2638,
                "relation_code": "530129",
                "relation_desc": "云南省寻甸回族彝族自治县"
            },
            {
                "code": "250114",
                "parentcode": "2501",
                "parenttext": "昆明市",
                "text": "安宁市",
                "level": 3,
                "order": 2639,
                "relation_code": "530181",
                "relation_desc": "云南省安宁市"
            },
            {
                "code": "2502",
                "parentcode": "25",
                "parenttext": "云南省",
                "text": "曲靖市",
                "level": 2,
                "order": 2640,
                "relation_code": "530300",
                "relation_desc": "云南省曲靖市"
            },
            {
                "code": "250201",
                "parentcode": "2502",
                "parenttext": "曲靖市",
                "text": "麒麟区",
                "level": 3,
                "order": 2641,
                "relation_code": "530302",
                "relation_desc": "云南省曲靖市麒麟区"
            },
            {
                "code": "250202",
                "parentcode": "2502",
                "parenttext": "曲靖市",
                "text": "马龙县",
                "level": 3,
                "order": 2642,
                "relation_code": "530301",
                "relation_desc": "云南省马龙县"
            },
            {
                "code": "250203",
                "parentcode": "2502",
                "parenttext": "曲靖市",
                "text": "陆良县",
                "level": 3,
                "order": 2643,
                "relation_code": "530322",
                "relation_desc": "云南省陆良县"
            },
            {
                "code": "250204",
                "parentcode": "2502",
                "parenttext": "曲靖市",
                "text": "师宗县",
                "level": 3,
                "order": 2644,
                "relation_code": "530323",
                "relation_desc": "云南省师宗县"
            },
            {
                "code": "250205",
                "parentcode": "2502",
                "parenttext": "曲靖市",
                "text": "罗平县",
                "level": 3,
                "order": 2645,
                "relation_code": "530324",
                "relation_desc": "云南省罗平县"
            },
            {
                "code": "250206",
                "parentcode": "2502",
                "parenttext": "曲靖市",
                "text": "富源县",
                "level": 3,
                "order": 2646,
                "relation_code": "530325",
                "relation_desc": "云南省富源县"
            },
            {
                "code": "250207",
                "parentcode": "2502",
                "parenttext": "曲靖市",
                "text": "会泽县",
                "level": 3,
                "order": 2647,
                "relation_code": "530326",
                "relation_desc": "云南省会泽县"
            },
            {
                "code": "250208",
                "parentcode": "2502",
                "parenttext": "曲靖市",
                "text": "沾益区",
                "level": 3,
                "order": 2648,
                "relation_code": "530303",
                "relation_desc": "云南省曲靖市沾益区"
            },
            {
                "code": "250209",
                "parentcode": "2502",
                "parenttext": "曲靖市",
                "text": "宣威市",
                "level": 3,
                "order": 2649,
                "relation_code": "530381",
                "relation_desc": "云南省宣威市"
            },
            {
                "code": "2503",
                "parentcode": "25",
                "parenttext": "云南省",
                "text": "玉溪市",
                "level": 2,
                "order": 2650,
                "relation_code": "530400",
                "relation_desc": "云南省玉溪市"
            },
            {
                "code": "250301",
                "parentcode": "2503",
                "parenttext": "玉溪市",
                "text": "红塔区",
                "level": 3,
                "order": 2651,
                "relation_code": "530402",
                "relation_desc": "云南省玉溪市红塔区"
            },
            {
                "code": "250302",
                "parentcode": "2503",
                "parenttext": "玉溪市",
                "text": "江川区",
                "level": 3,
                "order": 2652,
                "relation_code": "530403",
                "relation_desc": "云南省玉溪市江川区"
            },
            {
                "code": "250303",
                "parentcode": "2503",
                "parenttext": "玉溪市",
                "text": "澄江市",
                "level": 3,
                "order": 2653,
                "relation_code": "530481",
                "relation_desc": "云南省澄江市"
            },
            {
                "code": "250304",
                "parentcode": "2503",
                "parenttext": "玉溪市",
                "text": "通海县",
                "level": 3,
                "order": 2654,
                "relation_code": "530423",
                "relation_desc": "云南省通海县"
            },
            {
                "code": "250305",
                "parentcode": "2503",
                "parenttext": "玉溪市",
                "text": "华宁县",
                "level": 3,
                "order": 2655,
                "relation_code": "530424",
                "relation_desc": "云南省华宁县"
            },
            {
                "code": "250306",
                "parentcode": "2503",
                "parenttext": "玉溪市",
                "text": "易门县",
                "level": 3,
                "order": 2656,
                "relation_code": "530425",
                "relation_desc": "云南省易门县"
            },
            {
                "code": "250307",
                "parentcode": "2503",
                "parenttext": "玉溪市",
                "text": "峨山彝族自治县",
                "level": 3,
                "order": 2657,
                "relation_code": "530426",
                "relation_desc": "云南省峨山彝族自治县"
            },
            {
                "code": "250308",
                "parentcode": "2503",
                "parenttext": "玉溪市",
                "text": "新平彝族傣族自治县",
                "level": 3,
                "order": 2658,
                "relation_code": "530427",
                "relation_desc": "云南省新平彝族傣族自治县"
            },
            {
                "code": "250309",
                "parentcode": "2503",
                "parenttext": "玉溪市",
                "text": "元江哈尼族彝族傣族自治县",
                "level": 3,
                "order": 2659,
                "relation_code": "530428",
                "relation_desc": "云南省元江哈尼族彝族傣族自治县"
            },
            {
                "code": "2504",
                "parentcode": "25",
                "parenttext": "云南省",
                "text": "保山市",
                "level": 2,
                "order": 2660,
                "relation_code": "530500",
                "relation_desc": "云南省保山市"
            },
            {
                "code": "250401",
                "parentcode": "2504",
                "parenttext": "保山市",
                "text": "隆阳区",
                "level": 3,
                "order": 2661,
                "relation_code": "530502",
                "relation_desc": "云南省保山市隆阳区"
            },
            {
                "code": "250402",
                "parentcode": "2504",
                "parenttext": "保山市",
                "text": "施甸县",
                "level": 3,
                "order": 2662,
                "relation_code": "530521",
                "relation_desc": "云南省施甸县"
            },
            {
                "code": "250403",
                "parentcode": "2504",
                "parenttext": "保山市",
                "text": "腾冲市",
                "level": 3,
                "order": 2663,
                "relation_code": "530581",
                "relation_desc": "云南省腾冲市"
            },
            {
                "code": "250404",
                "parentcode": "2504",
                "parenttext": "保山市",
                "text": "龙陵县",
                "level": 3,
                "order": 2664,
                "relation_code": "530523",
                "relation_desc": "云南省龙陵县"
            },
            {
                "code": "250405",
                "parentcode": "2504",
                "parenttext": "保山市",
                "text": "昌宁县",
                "level": 3,
                "order": 2665,
                "relation_code": "530524",
                "relation_desc": "云南省昌宁县"
            },
            {
                "code": "2505",
                "parentcode": "25",
                "parenttext": "云南省",
                "text": "昭通市",
                "level": 2,
                "order": 2666,
                "relation_code": "530600",
                "relation_desc": "云南省昭通市"
            },
            {
                "code": "250501",
                "parentcode": "2505",
                "parenttext": "昭通市",
                "text": "昭阳区",
                "level": 3,
                "order": 2667,
                "relation_code": "530602",
                "relation_desc": "云南省昭通市昭阳区"
            },
            {
                "code": "250502",
                "parentcode": "2505",
                "parenttext": "昭通市",
                "text": "鲁甸县",
                "level": 3,
                "order": 2668,
                "relation_code": "530621",
                "relation_desc": "云南省鲁甸县"
            },
            {
                "code": "250503",
                "parentcode": "2505",
                "parenttext": "昭通市",
                "text": "巧家县",
                "level": 3,
                "order": 2669,
                "relation_code": "530622",
                "relation_desc": "云南省巧家县"
            },
            {
                "code": "250504",
                "parentcode": "2505",
                "parenttext": "昭通市",
                "text": "盐津县",
                "level": 3,
                "order": 2670,
                "relation_code": "530623",
                "relation_desc": "云南省盐津县"
            },
            {
                "code": "250505",
                "parentcode": "2505",
                "parenttext": "昭通市",
                "text": "大关县",
                "level": 3,
                "order": 2671,
                "relation_code": "530624",
                "relation_desc": "云南省大关县"
            },
            {
                "code": "250506",
                "parentcode": "2505",
                "parenttext": "昭通市",
                "text": "永善县",
                "level": 3,
                "order": 2672,
                "relation_code": "530625",
                "relation_desc": "云南省永善县"
            },
            {
                "code": "250507",
                "parentcode": "2505",
                "parenttext": "昭通市",
                "text": "绥江县",
                "level": 3,
                "order": 2673,
                "relation_code": "530626",
                "relation_desc": "云南省绥江县"
            },
            {
                "code": "250508",
                "parentcode": "2505",
                "parenttext": "昭通市",
                "text": "镇雄县",
                "level": 3,
                "order": 2674,
                "relation_code": "530627",
                "relation_desc": "云南省镇雄县"
            },
            {
                "code": "250509",
                "parentcode": "2505",
                "parenttext": "昭通市",
                "text": "彝良县",
                "level": 3,
                "order": 2675,
                "relation_code": "530628",
                "relation_desc": "云南省彝良县"
            },
            {
                "code": "250510",
                "parentcode": "2505",
                "parenttext": "昭通市",
                "text": "威信县",
                "level": 3,
                "order": 2676,
                "relation_code": "530629",
                "relation_desc": "云南省威信县"
            },
            {
                "code": "250511",
                "parentcode": "2505",
                "parenttext": "昭通市",
                "text": "水富市",
                "level": 3,
                "order": 2677,
                "relation_code": "530681",
                "relation_desc": "云南省水富市"
            },
            {
                "code": "2506",
                "parentcode": "25",
                "parenttext": "云南省",
                "text": "丽江市",
                "level": 2,
                "order": 2678,
                "relation_code": "530700",
                "relation_desc": "云南省丽江市"
            },
            {
                "code": "250601",
                "parentcode": "2506",
                "parenttext": "丽江市",
                "text": "古城区",
                "level": 3,
                "order": 2679,
                "relation_code": "530702",
                "relation_desc": "云南省丽江市古城区"
            },
            {
                "code": "250602",
                "parentcode": "2506",
                "parenttext": "丽江市",
                "text": "玉龙纳西族自治县",
                "level": 3,
                "order": 2680,
                "relation_code": "530721",
                "relation_desc": "云南省玉龙纳西族自治县"
            },
            {
                "code": "250603",
                "parentcode": "2506",
                "parenttext": "丽江市",
                "text": "永胜县",
                "level": 3,
                "order": 2681,
                "relation_code": "530722",
                "relation_desc": "云南省永胜县"
            },
            {
                "code": "250604",
                "parentcode": "2506",
                "parenttext": "丽江市",
                "text": "华坪县",
                "level": 3,
                "order": 2682,
                "relation_code": "530723",
                "relation_desc": "云南省华坪县"
            },
            {
                "code": "250605",
                "parentcode": "2506",
                "parenttext": "丽江市",
                "text": "宁蒗彝族自治县",
                "level": 3,
                "order": 2683,
                "relation_code": "530724",
                "relation_desc": "云南省宁蒗彝族自治县"
            },
            {
                "code": "2507",
                "parentcode": "25",
                "parenttext": "云南省",
                "text": "普洱市",
                "level": 2,
                "order": 2684,
                "relation_code": "530800",
                "relation_desc": "云南省普洱市"
            },
            {
                "code": "250701",
                "parentcode": "2507",
                "parenttext": "普洱市",
                "text": "思茅区",
                "level": 3,
                "order": 2685,
                "relation_code": "530802",
                "relation_desc": "云南省普洱市思茅区"
            },
            {
                "code": "250702",
                "parentcode": "2507",
                "parenttext": "普洱市",
                "text": "宁洱哈尼族彝族自治县",
                "level": 3,
                "order": 2686,
                "relation_code": "530821",
                "relation_desc": "云南省宁洱哈尼族彝族自治县"
            },
            {
                "code": "250703",
                "parentcode": "2507",
                "parenttext": "普洱市",
                "text": "墨江哈尼族自治县",
                "level": 3,
                "order": 2687,
                "relation_code": "530822",
                "relation_desc": "云南省墨江哈尼族自治县"
            },
            {
                "code": "250704",
                "parentcode": "2507",
                "parenttext": "普洱市",
                "text": "景东彝族自治县",
                "level": 3,
                "order": 2688,
                "relation_code": "530823",
                "relation_desc": "云南省景东彝族自治县"
            },
            {
                "code": "250705",
                "parentcode": "2507",
                "parenttext": "普洱市",
                "text": "景谷傣族彝族自治县",
                "level": 3,
                "order": 2689,
                "relation_code": "530824",
                "relation_desc": "云南省景谷傣族彝族自治县"
            },
            {
                "code": "250706",
                "parentcode": "2507",
                "parenttext": "普洱市",
                "text": "镇沅彝族哈尼族拉祜族自治县",
                "level": 3,
                "order": 2690,
                "relation_code": "530825",
                "relation_desc": "云南省镇沅彝族哈尼族拉祜族自治县"
            },
            {
                "code": "250707",
                "parentcode": "2507",
                "parenttext": "普洱市",
                "text": "江城哈尼族彝族自治县",
                "level": 3,
                "order": 2691,
                "relation_code": "530826",
                "relation_desc": "云南省江城哈尼族彝族自治县"
            },
            {
                "code": "250708",
                "parentcode": "2507",
                "parenttext": "普洱市",
                "text": "孟连傣族拉祜族佤族自治县",
                "level": 3,
                "order": 2692,
                "relation_code": "530827",
                "relation_desc": "云南省孟连傣族拉祜族佤族自治县"
            },
            {
                "code": "250709",
                "parentcode": "2507",
                "parenttext": "普洱市",
                "text": "澜沧拉祜族自治县",
                "level": 3,
                "order": 2693,
                "relation_code": "530828",
                "relation_desc": "云南省澜沧拉祜族自治县"
            },
            {
                "code": "250710",
                "parentcode": "2507",
                "parenttext": "普洱市",
                "text": "西盟佤族自治县",
                "level": 3,
                "order": 2694,
                "relation_code": "530829",
                "relation_desc": "云南省西盟佤族自治县"
            },
            {
                "code": "2508",
                "parentcode": "25",
                "parenttext": "云南省",
                "text": "临沧市",
                "level": 2,
                "order": 2695,
                "relation_code": "530900",
                "relation_desc": "云南省临沧市"
            },
            {
                "code": "250801",
                "parentcode": "2508",
                "parenttext": "临沧市",
                "text": "临翔区",
                "level": 3,
                "order": 2696,
                "relation_code": "530902",
                "relation_desc": "云南省临沧市临翔区"
            },
            {
                "code": "250802",
                "parentcode": "2508",
                "parenttext": "临沧市",
                "text": "凤庆县",
                "level": 3,
                "order": 2697,
                "relation_code": "530921",
                "relation_desc": "云南省凤庆县"
            },
            {
                "code": "250803",
                "parentcode": "2508",
                "parenttext": "临沧市",
                "text": "云县",
                "level": 3,
                "order": 2698,
                "relation_code": "530922",
                "relation_desc": "云南省云县"
            },
            {
                "code": "250804",
                "parentcode": "2508",
                "parenttext": "临沧市",
                "text": "永德县",
                "level": 3,
                "order": 2699,
                "relation_code": "530923",
                "relation_desc": "云南省永德县"
            },
            {
                "code": "250805",
                "parentcode": "2508",
                "parenttext": "临沧市",
                "text": "镇康县",
                "level": 3,
                "order": 2700,
                "relation_code": "530924",
                "relation_desc": "云南省镇康县"
            },
            {
                "code": "250806",
                "parentcode": "2508",
                "parenttext": "临沧市",
                "text": "双江拉祜族佤族布朗族傣族自治县",
                "level": 3,
                "order": 2701,
                "relation_code": "530925",
                "relation_desc": "云南省双江拉祜族佤族布朗族傣族自治县"
            },
            {
                "code": "250807",
                "parentcode": "2508",
                "parenttext": "临沧市",
                "text": "耿马傣族佤族自治县",
                "level": 3,
                "order": 2702,
                "relation_code": "530926",
                "relation_desc": "云南省耿马傣族佤族自治县"
            },
            {
                "code": "250808",
                "parentcode": "2508",
                "parenttext": "临沧市",
                "text": "沧源佤族自治县",
                "level": 3,
                "order": 2703,
                "relation_code": "530927",
                "relation_desc": "云南省沧源佤族自治县"
            },
            {
                "code": "2509",
                "parentcode": "25",
                "parenttext": "云南省",
                "text": "楚雄彝族自治州",
                "level": 2,
                "order": 2704,
                "relation_code": "532300",
                "relation_desc": "云南省楚雄彝族自治州"
            },
            {
                "code": "250901",
                "parentcode": "2509",
                "parenttext": "楚雄彝族自治州",
                "text": "楚雄市",
                "level": 3,
                "order": 2705,
                "relation_code": "532301",
                "relation_desc": "云南省楚雄市"
            },
            {
                "code": "250902",
                "parentcode": "2509",
                "parenttext": "楚雄彝族自治州",
                "text": "双柏县",
                "level": 3,
                "order": 2706,
                "relation_code": "532322",
                "relation_desc": "云南省双柏县"
            },
            {
                "code": "250903",
                "parentcode": "2509",
                "parenttext": "楚雄彝族自治州",
                "text": "牟定县",
                "level": 3,
                "order": 2707,
                "relation_code": "532323",
                "relation_desc": "云南省牟定县"
            },
            {
                "code": "250904",
                "parentcode": "2509",
                "parenttext": "楚雄彝族自治州",
                "text": "南华县",
                "level": 3,
                "order": 2708,
                "relation_code": "532324",
                "relation_desc": "云南省南华县"
            },
            {
                "code": "250905",
                "parentcode": "2509",
                "parenttext": "楚雄彝族自治州",
                "text": "姚安县",
                "level": 3,
                "order": 2709,
                "relation_code": "532325",
                "relation_desc": "云南省姚安县"
            },
            {
                "code": "250906",
                "parentcode": "2509",
                "parenttext": "楚雄彝族自治州",
                "text": "大姚县",
                "level": 3,
                "order": 2710,
                "relation_code": "532326",
                "relation_desc": "云南省大姚县"
            },
            {
                "code": "250907",
                "parentcode": "2509",
                "parenttext": "楚雄彝族自治州",
                "text": "永仁县",
                "level": 3,
                "order": 2711,
                "relation_code": "532327",
                "relation_desc": "云南省永仁县"
            },
            {
                "code": "250908",
                "parentcode": "2509",
                "parenttext": "楚雄彝族自治州",
                "text": "元谋县",
                "level": 3,
                "order": 2712,
                "relation_code": "532328",
                "relation_desc": "云南省元谋县"
            },
            {
                "code": "250909",
                "parentcode": "2509",
                "parenttext": "楚雄彝族自治州",
                "text": "武定县",
                "level": 3,
                "order": 2713,
                "relation_code": "532329",
                "relation_desc": "云南省武定县"
            },
            {
                "code": "250910",
                "parentcode": "2509",
                "parenttext": "楚雄彝族自治州",
                "text": "禄丰县",
                "level": 3,
                "order": 2714,
                "relation_code": "532331",
                "relation_desc": "云南省禄丰县"
            },
            {
                "code": "2510",
                "parentcode": "25",
                "parenttext": "云南省",
                "text": "红河哈尼族彝族自治州",
                "level": 2,
                "order": 2715,
                "relation_code": "532500",
                "relation_desc": "云南省红河哈尼族彝族自治州"
            },
            {
                "code": "251001",
                "parentcode": "2510",
                "parenttext": "红河哈尼族彝族自治州",
                "text": "个旧市",
                "level": 3,
                "order": 2716,
                "relation_code": "532501",
                "relation_desc": "云南省个旧市"
            },
            {
                "code": "251002",
                "parentcode": "2510",
                "parenttext": "红河哈尼族彝族自治州",
                "text": "开远市",
                "level": 3,
                "order": 2717,
                "relation_code": "532502",
                "relation_desc": "云南省开远市"
            },
            {
                "code": "251003",
                "parentcode": "2510",
                "parenttext": "红河哈尼族彝族自治州",
                "text": "蒙自市",
                "level": 3,
                "order": 2718,
                "relation_code": "532503",
                "relation_desc": "云南省蒙自市"
            },
            {
                "code": "251004",
                "parentcode": "2510",
                "parenttext": "红河哈尼族彝族自治州",
                "text": "屏边苗族自治县",
                "level": 3,
                "order": 2719,
                "relation_code": "532523",
                "relation_desc": "云南省屏边苗族自治县"
            },
            {
                "code": "251005",
                "parentcode": "2510",
                "parenttext": "红河哈尼族彝族自治州",
                "text": "建水县",
                "level": 3,
                "order": 2720,
                "relation_code": "532524",
                "relation_desc": "云南省建水县"
            },
            {
                "code": "251006",
                "parentcode": "2510",
                "parenttext": "红河哈尼族彝族自治州",
                "text": "石屏县",
                "level": 3,
                "order": 2721,
                "relation_code": "532525",
                "relation_desc": "云南省石屏县"
            },
            {
                "code": "251007",
                "parentcode": "2510",
                "parenttext": "红河哈尼族彝族自治州",
                "text": "弥勒市",
                "level": 3,
                "order": 2722,
                "relation_code": "532504",
                "relation_desc": "云南省弥勒市"
            },
            {
                "code": "251008",
                "parentcode": "2510",
                "parenttext": "红河哈尼族彝族自治州",
                "text": "泸西县",
                "level": 3,
                "order": 2723,
                "relation_code": "532527",
                "relation_desc": "云南省泸西县"
            },
            {
                "code": "251009",
                "parentcode": "2510",
                "parenttext": "红河哈尼族彝族自治州",
                "text": "元阳县",
                "level": 3,
                "order": 2724,
                "relation_code": "532528",
                "relation_desc": "云南省元阳县"
            },
            {
                "code": "251010",
                "parentcode": "2510",
                "parenttext": "红河哈尼族彝族自治州",
                "text": "红河县",
                "level": 3,
                "order": 2725,
                "relation_code": "532529",
                "relation_desc": "云南省红河县"
            },
            {
                "code": "251011",
                "parentcode": "2510",
                "parenttext": "红河哈尼族彝族自治州",
                "text": "金平苗族瑶族傣族自治县",
                "level": 3,
                "order": 2726,
                "relation_code": "532530",
                "relation_desc": "云南省金平苗族瑶族傣族自治县"
            },
            {
                "code": "251012",
                "parentcode": "2510",
                "parenttext": "红河哈尼族彝族自治州",
                "text": "绿春县",
                "level": 3,
                "order": 2727,
                "relation_code": "532531",
                "relation_desc": "云南省绿春县"
            },
            {
                "code": "251013",
                "parentcode": "2510",
                "parenttext": "红河哈尼族彝族自治州",
                "text": "河口瑶族自治县",
                "level": 3,
                "order": 2728,
                "relation_code": "532532",
                "relation_desc": "云南省河口瑶族自治县"
            },
            {
                "code": "2511",
                "parentcode": "25",
                "parenttext": "云南省",
                "text": "文山壮族苗族自治州",
                "level": 2,
                "order": 2729,
                "relation_code": "532600",
                "relation_desc": "云南省文山壮族苗族自治州"
            },
            {
                "code": "251101",
                "parentcode": "2511",
                "parenttext": "文山壮族苗族自治州",
                "text": "文山市",
                "level": 3,
                "order": 2730,
                "relation_code": "532601",
                "relation_desc": "云南省文山市"
            },
            {
                "code": "251102",
                "parentcode": "2511",
                "parenttext": "文山壮族苗族自治州",
                "text": "砚山县",
                "level": 3,
                "order": 2731,
                "relation_code": "532622",
                "relation_desc": "云南省砚山县"
            },
            {
                "code": "251103",
                "parentcode": "2511",
                "parenttext": "文山壮族苗族自治州",
                "text": "西畴县",
                "level": 3,
                "order": 2732,
                "relation_code": "532623",
                "relation_desc": "云南省西畴县"
            },
            {
                "code": "251104",
                "parentcode": "2511",
                "parenttext": "文山壮族苗族自治州",
                "text": "麻栗坡县",
                "level": 3,
                "order": 2733,
                "relation_code": "532624",
                "relation_desc": "云南省麻栗坡县"
            },
            {
                "code": "251105",
                "parentcode": "2511",
                "parenttext": "文山壮族苗族自治州",
                "text": "马关县",
                "level": 3,
                "order": 2734,
                "relation_code": "532625",
                "relation_desc": "云南省马关县"
            },
            {
                "code": "251106",
                "parentcode": "2511",
                "parenttext": "文山壮族苗族自治州",
                "text": "丘北县",
                "level": 3,
                "order": 2735,
                "relation_code": "532626",
                "relation_desc": "云南省丘北县"
            },
            {
                "code": "251107",
                "parentcode": "2511",
                "parenttext": "文山壮族苗族自治州",
                "text": "广南县",
                "level": 3,
                "order": 2736,
                "relation_code": "532627",
                "relation_desc": "云南省广南县"
            },
            {
                "code": "251108",
                "parentcode": "2511",
                "parenttext": "文山壮族苗族自治州",
                "text": "富宁县",
                "level": 3,
                "order": 2737,
                "relation_code": "532628",
                "relation_desc": "云南省富宁县"
            },
            {
                "code": "2512",
                "parentcode": "25",
                "parenttext": "云南省",
                "text": "西双版纳傣族自治州",
                "level": 2,
                "order": 2738,
                "relation_code": "532800",
                "relation_desc": "云南省西双版纳傣族自治州"
            },
            {
                "code": "251201",
                "parentcode": "2512",
                "parenttext": "西双版纳傣族自治州",
                "text": "景洪市",
                "level": 3,
                "order": 2739,
                "relation_code": "532801",
                "relation_desc": "云南省景洪市"
            },
            {
                "code": "251202",
                "parentcode": "2512",
                "parenttext": "西双版纳傣族自治州",
                "text": "勐海县",
                "level": 3,
                "order": 2740,
                "relation_code": "532822",
                "relation_desc": "云南省勐海县"
            },
            {
                "code": "251203",
                "parentcode": "2512",
                "parenttext": "西双版纳傣族自治州",
                "text": "勐腊县",
                "level": 3,
                "order": 2741,
                "relation_code": "532823",
                "relation_desc": "云南省勐腊县"
            },
            {
                "code": "2513",
                "parentcode": "25",
                "parenttext": "云南省",
                "text": "大理白族自治州",
                "level": 2,
                "order": 2742,
                "relation_code": "532900",
                "relation_desc": "云南省大理白族自治州"
            },
            {
                "code": "251301",
                "parentcode": "2513",
                "parenttext": "大理白族自治州",
                "text": "大理市",
                "level": 3,
                "order": 2743,
                "relation_code": "532901",
                "relation_desc": "云南省大理市"
            },
            {
                "code": "251302",
                "parentcode": "2513",
                "parenttext": "大理白族自治州",
                "text": "漾濞彝族自治县",
                "level": 3,
                "order": 2744,
                "relation_code": "532922",
                "relation_desc": "云南省漾濞彝族自治县"
            },
            {
                "code": "251303",
                "parentcode": "2513",
                "parenttext": "大理白族自治州",
                "text": "祥云县",
                "level": 3,
                "order": 2745,
                "relation_code": "532923",
                "relation_desc": "云南省祥云县"
            },
            {
                "code": "251304",
                "parentcode": "2513",
                "parenttext": "大理白族自治州",
                "text": "宾川县",
                "level": 3,
                "order": 2746,
                "relation_code": "532924",
                "relation_desc": "云南省宾川县"
            },
            {
                "code": "251305",
                "parentcode": "2513",
                "parenttext": "大理白族自治州",
                "text": "弥渡县",
                "level": 3,
                "order": 2747,
                "relation_code": "532925",
                "relation_desc": "云南省弥渡县"
            },
            {
                "code": "251306",
                "parentcode": "2513",
                "parenttext": "大理白族自治州",
                "text": "南涧彝族自治县",
                "level": 3,
                "order": 2748,
                "relation_code": "532926",
                "relation_desc": "云南省南涧彝族自治县"
            },
            {
                "code": "251307",
                "parentcode": "2513",
                "parenttext": "大理白族自治州",
                "text": "巍山彝族回族自治县",
                "level": 3,
                "order": 2749,
                "relation_code": "532927",
                "relation_desc": "云南省巍山彝族回族自治县"
            },
            {
                "code": "251308",
                "parentcode": "2513",
                "parenttext": "大理白族自治州",
                "text": "永平县",
                "level": 3,
                "order": 2750,
                "relation_code": "532928",
                "relation_desc": "云南省永平县"
            },
            {
                "code": "251309",
                "parentcode": "2513",
                "parenttext": "大理白族自治州",
                "text": "云龙县",
                "level": 3,
                "order": 2751,
                "relation_code": "532929",
                "relation_desc": "云南省云龙县"
            },
            {
                "code": "251310",
                "parentcode": "2513",
                "parenttext": "大理白族自治州",
                "text": "洱源县",
                "level": 3,
                "order": 2752,
                "relation_code": "532930",
                "relation_desc": "云南省洱源县"
            },
            {
                "code": "251311",
                "parentcode": "2513",
                "parenttext": "大理白族自治州",
                "text": "剑川县",
                "level": 3,
                "order": 2753,
                "relation_code": "532931",
                "relation_desc": "云南省剑川县"
            },
            {
                "code": "251312",
                "parentcode": "2513",
                "parenttext": "大理白族自治州",
                "text": "鹤庆县",
                "level": 3,
                "order": 2754,
                "relation_code": "532932",
                "relation_desc": "云南省鹤庆县"
            },
            {
                "code": "2514",
                "parentcode": "25",
                "parenttext": "云南省",
                "text": "德宏傣族景颇族自治州",
                "level": 2,
                "order": 2755,
                "relation_code": "533100",
                "relation_desc": "云南省德宏傣族景颇族自治州"
            },
            {
                "code": "251401",
                "parentcode": "2514",
                "parenttext": "德宏傣族景颇族自治州",
                "text": "瑞丽市",
                "level": 3,
                "order": 2756,
                "relation_code": "533102",
                "relation_desc": "云南省瑞丽市"
            },
            {
                "code": "251402",
                "parentcode": "2514",
                "parenttext": "德宏傣族景颇族自治州",
                "text": "芒市",
                "level": 3,
                "order": 2757,
                "relation_code": "533103",
                "relation_desc": "云南省芒市"
            },
            {
                "code": "251403",
                "parentcode": "2514",
                "parenttext": "德宏傣族景颇族自治州",
                "text": "梁河县",
                "level": 3,
                "order": 2758,
                "relation_code": "533122",
                "relation_desc": "云南省梁河县"
            },
            {
                "code": "251404",
                "parentcode": "2514",
                "parenttext": "德宏傣族景颇族自治州",
                "text": "盈江县",
                "level": 3,
                "order": 2759,
                "relation_code": "533123",
                "relation_desc": "云南省盈江县"
            },
            {
                "code": "251405",
                "parentcode": "2514",
                "parenttext": "德宏傣族景颇族自治州",
                "text": "陇川县",
                "level": 3,
                "order": 2760,
                "relation_code": "533124",
                "relation_desc": "云南省陇川县"
            },
            {
                "code": "2515",
                "parentcode": "25",
                "parenttext": "云南省",
                "text": "怒江傈僳族自治州",
                "level": 2,
                "order": 2761,
                "relation_code": "533300",
                "relation_desc": "云南省怒江傈僳族自治州"
            },
            {
                "code": "251501",
                "parentcode": "2515",
                "parenttext": "怒江傈僳族自治州",
                "text": "泸水市",
                "level": 3,
                "order": 2762,
                "relation_code": "533321",
                "relation_desc": "云南省泸水县"
            },
            {
                "code": "251502",
                "parentcode": "2515",
                "parenttext": "怒江傈僳族自治州",
                "text": "福贡县",
                "level": 3,
                "order": 2763,
                "relation_code": "533323",
                "relation_desc": "云南省福贡县"
            },
            {
                "code": "251503",
                "parentcode": "2515",
                "parenttext": "怒江傈僳族自治州",
                "text": "贡山独龙族怒族自治县",
                "level": 3,
                "order": 2764,
                "relation_code": "533324",
                "relation_desc": "云南省贡山独龙族怒族自治县"
            },
            {
                "code": "251504",
                "parentcode": "2515",
                "parenttext": "怒江傈僳族自治州",
                "text": "兰坪白族普米族自治县",
                "level": 3,
                "order": 2765,
                "relation_code": "533325",
                "relation_desc": "云南省兰坪白族普米族自治县"
            },
            {
                "code": "2516",
                "parentcode": "25",
                "parenttext": "云南省",
                "text": "迪庆藏族自治州",
                "level": 2,
                "order": 2766,
                "relation_code": "533400",
                "relation_desc": "云南省迪庆藏族自治州"
            },
            {
                "code": "251601",
                "parentcode": "2516",
                "parenttext": "迪庆藏族自治州",
                "text": "香格里拉市",
                "level": 3,
                "order": 2767,
                "relation_code": "533401",
                "relation_desc": "云南省香格里拉市"
            },
            {
                "code": "251602",
                "parentcode": "2516",
                "parenttext": "迪庆藏族自治州",
                "text": "德钦县",
                "level": 3,
                "order": 2768,
                "relation_code": "533422",
                "relation_desc": "云南省德钦县"
            },
            {
                "code": "251603",
                "parentcode": "2516",
                "parenttext": "迪庆藏族自治州",
                "text": "维西傈僳族自治县",
                "level": 3,
                "order": 2769,
                "relation_code": "533423",
                "relation_desc": "云南省维西傈僳族自治县"
            },
            {
                "code": "26",
                "parentcode": "0",
                "parenttext": "0",
                "text": "西藏自治区",
                "level": 1,
                "order": 2770,
                "relation_code": "",
                "relation_desc": "西藏自治区"
            },
            {
                "code": "2601",
                "parentcode": "26",
                "parenttext": "西藏自治区",
                "text": "拉萨市",
                "level": 2,
                "order": 2771,
                "relation_code": "540100",
                "relation_desc": "西藏拉萨市"
            },
            {
                "code": "260101",
                "parentcode": "2601",
                "parenttext": "拉萨市",
                "text": "城关区",
                "level": 3,
                "order": 2772,
                "relation_code": "540102",
                "relation_desc": "西藏拉萨市城关区"
            },
            {
                "code": "260102",
                "parentcode": "2601",
                "parenttext": "拉萨市",
                "text": "林周县",
                "level": 3,
                "order": 2773,
                "relation_code": "540121",
                "relation_desc": "西藏林周县"
            },
            {
                "code": "260103",
                "parentcode": "2601",
                "parenttext": "拉萨市",
                "text": "当雄县",
                "level": 3,
                "order": 2774,
                "relation_code": "540122",
                "relation_desc": "西藏当雄县"
            },
            {
                "code": "260104",
                "parentcode": "2601",
                "parenttext": "拉萨市",
                "text": "尼木县",
                "level": 3,
                "order": 2775,
                "relation_code": "540123",
                "relation_desc": "西藏尼木县"
            },
            {
                "code": "260105",
                "parentcode": "2601",
                "parenttext": "拉萨市",
                "text": "曲水县",
                "level": 3,
                "order": 2776,
                "relation_code": "540124",
                "relation_desc": "西藏曲水县"
            },
            {
                "code": "260106",
                "parentcode": "2601",
                "parenttext": "拉萨市",
                "text": "堆龙德庆区",
                "level": 3,
                "order": 2777,
                "relation_code": "540103",
                "relation_desc": "西藏拉萨市堆龙德庆区"
            },
            {
                "code": "260107",
                "parentcode": "2601",
                "parenttext": "拉萨市",
                "text": "达孜区",
                "level": 3,
                "order": 2778,
                "relation_code": "540104",
                "relation_desc": "西藏拉萨市达孜区"
            },
            {
                "code": "260108",
                "parentcode": "2601",
                "parenttext": "拉萨市",
                "text": "墨竹工卡县",
                "level": 3,
                "order": 2779,
                "relation_code": "540127",
                "relation_desc": "西藏墨竹工卡县"
            },
            {
                "code": "2602",
                "parentcode": "26",
                "parenttext": "西藏自治区",
                "text": "昌都市",
                "level": 2,
                "order": 2780,
                "relation_code": "540300",
                "relation_desc": "西藏昌都市"
            },
            {
                "code": "260109",
                "parentcode": "2601",
                "parenttext": "拉萨市",
                "text": "格尔木藏青工业园区",
                "level": 3,
                "order": 2780,
                "relation_code": "540101",
                "relation_desc": "西藏拉萨市"
            },
            {
                "code": "260201",
                "parentcode": "2602",
                "parenttext": "昌都市",
                "text": "卡若区",
                "level": 3,
                "order": 2781,
                "relation_code": "540302",
                "relation_desc": "西藏昌都市卡若区"
            },
            {
                "code": "260110",
                "parentcode": "2601",
                "parenttext": "拉萨市",
                "text": "拉萨经济技术开发区",
                "level": 3,
                "order": 2781,
                "relation_code": "540101",
                "relation_desc": "西藏拉萨市"
            },
            {
                "code": "260202",
                "parentcode": "2602",
                "parenttext": "昌都市",
                "text": "江达县",
                "level": 3,
                "order": 2782,
                "relation_code": "540321",
                "relation_desc": "西藏江达县"
            },
            {
                "code": "260111",
                "parentcode": "2601",
                "parenttext": "拉萨市",
                "text": "西藏文化旅游创意园区",
                "level": 3,
                "order": 2782,
                "relation_code": "540101",
                "relation_desc": "西藏拉萨市"
            },
            {
                "code": "260203",
                "parentcode": "2602",
                "parenttext": "昌都市",
                "text": "贡觉县",
                "level": 3,
                "order": 2783,
                "relation_code": "540322",
                "relation_desc": "西藏贡觉县"
            },
            {
                "code": "260112",
                "parentcode": "2601",
                "parenttext": "拉萨市",
                "text": "达孜工业园区",
                "level": 3,
                "order": 2783,
                "relation_code": "540101",
                "relation_desc": "西藏拉萨市"
            },
            {
                "code": "260204",
                "parentcode": "2602",
                "parenttext": "昌都市",
                "text": "类乌齐县",
                "level": 3,
                "order": 2784,
                "relation_code": "540323",
                "relation_desc": "西藏类乌齐县"
            },
            {
                "code": "260205",
                "parentcode": "2602",
                "parenttext": "昌都市",
                "text": "丁青县",
                "level": 3,
                "order": 2785,
                "relation_code": "540324",
                "relation_desc": "西藏丁青县"
            },
            {
                "code": "260206",
                "parentcode": "2602",
                "parenttext": "昌都市",
                "text": "察雅县",
                "level": 3,
                "order": 2786,
                "relation_code": "540325",
                "relation_desc": "西藏察雅县"
            },
            {
                "code": "260207",
                "parentcode": "2602",
                "parenttext": "昌都市",
                "text": "八宿县",
                "level": 3,
                "order": 2787,
                "relation_code": "540326",
                "relation_desc": "西藏八宿县"
            },
            {
                "code": "260208",
                "parentcode": "2602",
                "parenttext": "昌都市",
                "text": "左贡县",
                "level": 3,
                "order": 2788,
                "relation_code": "540327",
                "relation_desc": "西藏左贡县"
            },
            {
                "code": "260209",
                "parentcode": "2602",
                "parenttext": "昌都市",
                "text": "芒康县",
                "level": 3,
                "order": 2789,
                "relation_code": "540328",
                "relation_desc": "西藏芒康县"
            },
            {
                "code": "260210",
                "parentcode": "2602",
                "parenttext": "昌都市",
                "text": "洛隆县",
                "level": 3,
                "order": 2790,
                "relation_code": "540329",
                "relation_desc": "西藏洛隆县"
            },
            {
                "code": "260211",
                "parentcode": "2602",
                "parenttext": "昌都市",
                "text": "边坝县",
                "level": 3,
                "order": 2791,
                "relation_code": "540330",
                "relation_desc": "西藏边坝县"
            },
            {
                "code": "2603",
                "parentcode": "26",
                "parenttext": "西藏自治区",
                "text": "山南市",
                "level": 2,
                "order": 2792,
                "relation_code": "540500",
                "relation_desc": "西藏山南市"
            },
            {
                "code": "260301",
                "parentcode": "2603",
                "parenttext": "山南市",
                "text": "乃东区",
                "level": 3,
                "order": 2793,
                "relation_code": "540502",
                "relation_desc": "西藏山南市乃东区"
            },
            {
                "code": "260302",
                "parentcode": "2603",
                "parenttext": "山南市",
                "text": "扎囊县",
                "level": 3,
                "order": 2794,
                "relation_code": "540521",
                "relation_desc": "西藏扎囊县"
            },
            {
                "code": "260303",
                "parentcode": "2603",
                "parenttext": "山南市",
                "text": "贡嘎县",
                "level": 3,
                "order": 2795,
                "relation_code": "540522",
                "relation_desc": "西藏贡嘎县"
            },
            {
                "code": "260304",
                "parentcode": "2603",
                "parenttext": "山南市",
                "text": "桑日县",
                "level": 3,
                "order": 2796,
                "relation_code": "540523",
                "relation_desc": "西藏桑日县"
            },
            {
                "code": "260305",
                "parentcode": "2603",
                "parenttext": "山南市",
                "text": "琼结县",
                "level": 3,
                "order": 2797,
                "relation_code": "540524",
                "relation_desc": "西藏琼结县"
            },
            {
                "code": "260306",
                "parentcode": "2603",
                "parenttext": "山南市",
                "text": "曲松县",
                "level": 3,
                "order": 2798,
                "relation_code": "540525",
                "relation_desc": "西藏曲松县"
            },
            {
                "code": "260307",
                "parentcode": "2603",
                "parenttext": "山南市",
                "text": "措美县",
                "level": 3,
                "order": 2799,
                "relation_code": "540526",
                "relation_desc": "西藏措美县"
            },
            {
                "code": "260308",
                "parentcode": "2603",
                "parenttext": "山南市",
                "text": "洛扎县",
                "level": 3,
                "order": 2800,
                "relation_code": "540527",
                "relation_desc": "西藏洛扎县"
            },
            {
                "code": "260309",
                "parentcode": "2603",
                "parenttext": "山南市",
                "text": "加查县",
                "level": 3,
                "order": 2801,
                "relation_code": "540528",
                "relation_desc": "西藏加查县"
            },
            {
                "code": "260310",
                "parentcode": "2603",
                "parenttext": "山南市",
                "text": "隆子县",
                "level": 3,
                "order": 2802,
                "relation_code": "540529",
                "relation_desc": "西藏隆子县"
            },
            {
                "code": "260311",
                "parentcode": "2603",
                "parenttext": "山南市",
                "text": "错那县",
                "level": 3,
                "order": 2803,
                "relation_code": "540530",
                "relation_desc": "西藏错那县"
            },
            {
                "code": "260312",
                "parentcode": "2603",
                "parenttext": "山南市",
                "text": "浪卡子县",
                "level": 3,
                "order": 2804,
                "relation_code": "540531",
                "relation_desc": "西藏浪卡子县"
            },
            {
                "code": "2604",
                "parentcode": "26",
                "parenttext": "西藏自治区",
                "text": "日喀则市",
                "level": 2,
                "order": 2805,
                "relation_code": "540200",
                "relation_desc": "西藏日喀则市"
            },
            {
                "code": "260401",
                "parentcode": "2604",
                "parenttext": "日喀则市",
                "text": "桑珠孜区",
                "level": 3,
                "order": 2806,
                "relation_code": "540202",
                "relation_desc": "西藏日喀则市桑珠孜区"
            },
            {
                "code": "260402",
                "parentcode": "2604",
                "parenttext": "日喀则市",
                "text": "南木林县",
                "level": 3,
                "order": 2807,
                "relation_code": "540221",
                "relation_desc": "西藏南木林县"
            },
            {
                "code": "260403",
                "parentcode": "2604",
                "parenttext": "日喀则市",
                "text": "江孜县",
                "level": 3,
                "order": 2808,
                "relation_code": "540222",
                "relation_desc": "西藏江孜县"
            },
            {
                "code": "260404",
                "parentcode": "2604",
                "parenttext": "日喀则市",
                "text": "定日县",
                "level": 3,
                "order": 2809,
                "relation_code": "540223",
                "relation_desc": "西藏定日县"
            },
            {
                "code": "260405",
                "parentcode": "2604",
                "parenttext": "日喀则市",
                "text": "萨迦县",
                "level": 3,
                "order": 2810,
                "relation_code": "540224",
                "relation_desc": "西藏萨迦县"
            },
            {
                "code": "260406",
                "parentcode": "2604",
                "parenttext": "日喀则市",
                "text": "拉孜县",
                "level": 3,
                "order": 2811,
                "relation_code": "540225",
                "relation_desc": "西藏拉孜县"
            },
            {
                "code": "260407",
                "parentcode": "2604",
                "parenttext": "日喀则市",
                "text": "昂仁县",
                "level": 3,
                "order": 2812,
                "relation_code": "540226",
                "relation_desc": "西藏昂仁县"
            },
            {
                "code": "260408",
                "parentcode": "2604",
                "parenttext": "日喀则市",
                "text": "谢通门县",
                "level": 3,
                "order": 2813,
                "relation_code": "540227",
                "relation_desc": "西藏谢通门县"
            },
            {
                "code": "260409",
                "parentcode": "2604",
                "parenttext": "日喀则市",
                "text": "白朗县",
                "level": 3,
                "order": 2814,
                "relation_code": "540228",
                "relation_desc": "西藏白朗县"
            },
            {
                "code": "260410",
                "parentcode": "2604",
                "parenttext": "日喀则市",
                "text": "仁布县",
                "level": 3,
                "order": 2815,
                "relation_code": "540229",
                "relation_desc": "西藏仁布县"
            },
            {
                "code": "260411",
                "parentcode": "2604",
                "parenttext": "日喀则市",
                "text": "康马县",
                "level": 3,
                "order": 2816,
                "relation_code": "540230",
                "relation_desc": "西藏康马县"
            },
            {
                "code": "260412",
                "parentcode": "2604",
                "parenttext": "日喀则市",
                "text": "定结县",
                "level": 3,
                "order": 2817,
                "relation_code": "540231",
                "relation_desc": "西藏定结县"
            },
            {
                "code": "260413",
                "parentcode": "2604",
                "parenttext": "日喀则市",
                "text": "仲巴县",
                "level": 3,
                "order": 2818,
                "relation_code": "540232",
                "relation_desc": "西藏仲巴县"
            },
            {
                "code": "260414",
                "parentcode": "2604",
                "parenttext": "日喀则市",
                "text": "亚东县",
                "level": 3,
                "order": 2819,
                "relation_code": "540233",
                "relation_desc": "西藏亚东县"
            },
            {
                "code": "260415",
                "parentcode": "2604",
                "parenttext": "日喀则市",
                "text": "吉隆县",
                "level": 3,
                "order": 2820,
                "relation_code": "540234",
                "relation_desc": "西藏吉隆县"
            },
            {
                "code": "260416",
                "parentcode": "2604",
                "parenttext": "日喀则市",
                "text": "聂拉木县",
                "level": 3,
                "order": 2821,
                "relation_code": "540235",
                "relation_desc": "西藏聂拉木县"
            },
            {
                "code": "260417",
                "parentcode": "2604",
                "parenttext": "日喀则市",
                "text": "萨嘎县",
                "level": 3,
                "order": 2822,
                "relation_code": "540236",
                "relation_desc": "西藏萨嘎县"
            },
            {
                "code": "260418",
                "parentcode": "2604",
                "parenttext": "日喀则市",
                "text": "岗巴县",
                "level": 3,
                "order": 2823,
                "relation_code": "540237",
                "relation_desc": "西藏岗巴县"
            },
            {
                "code": "2605",
                "parentcode": "26",
                "parenttext": "西藏自治区",
                "text": "那曲市",
                "level": 2,
                "order": 2824,
                "relation_code": "540600",
                "relation_desc": "西藏那曲市"
            },
            {
                "code": "260501",
                "parentcode": "2605",
                "parenttext": "那曲市",
                "text": "那曲县",
                "level": 3,
                "order": 2825,
                "relation_code": "540621",
                "relation_desc": "西藏那曲县"
            },
            {
                "code": "260502",
                "parentcode": "2605",
                "parenttext": "那曲市",
                "text": "嘉黎县",
                "level": 3,
                "order": 2826,
                "relation_code": "540621",
                "relation_desc": "西藏嘉黎县"
            },
            {
                "code": "260503",
                "parentcode": "2605",
                "parenttext": "那曲市",
                "text": "比如县",
                "level": 3,
                "order": 2827,
                "relation_code": "540622",
                "relation_desc": "西藏比如县"
            },
            {
                "code": "260504",
                "parentcode": "2605",
                "parenttext": "那曲市",
                "text": "聂荣县",
                "level": 3,
                "order": 2828,
                "relation_code": "540623",
                "relation_desc": "西藏聂荣县"
            },
            {
                "code": "260505",
                "parentcode": "2605",
                "parenttext": "那曲市",
                "text": "安多县",
                "level": 3,
                "order": 2829,
                "relation_code": "540624",
                "relation_desc": "西藏安多县"
            },
            {
                "code": "260506",
                "parentcode": "2605",
                "parenttext": "那曲市",
                "text": "申扎县",
                "level": 3,
                "order": 2830,
                "relation_code": "540625",
                "relation_desc": "西藏申扎县"
            },
            {
                "code": "260507",
                "parentcode": "2605",
                "parenttext": "那曲市",
                "text": "索县",
                "level": 3,
                "order": 2831,
                "relation_code": "540626",
                "relation_desc": "西藏索县"
            },
            {
                "code": "260508",
                "parentcode": "2605",
                "parenttext": "那曲市",
                "text": "班戈县",
                "level": 3,
                "order": 2832,
                "relation_code": "540627",
                "relation_desc": "西藏班戈县"
            },
            {
                "code": "260509",
                "parentcode": "2605",
                "parenttext": "那曲市",
                "text": "巴青县",
                "level": 3,
                "order": 2833,
                "relation_code": "540628",
                "relation_desc": "西藏巴青县"
            },
            {
                "code": "260510",
                "parentcode": "2605",
                "parenttext": "那曲市",
                "text": "尼玛县",
                "level": 3,
                "order": 2834,
                "relation_code": "540629",
                "relation_desc": "西藏尼玛县"
            },
            {
                "code": "2606",
                "parentcode": "26",
                "parenttext": "西藏自治区",
                "text": "阿里地区",
                "level": 2,
                "order": 2835,
                "relation_code": "542500",
                "relation_desc": "西藏阿里地区"
            },
            {
                "code": "260511",
                "parentcode": "2605",
                "parenttext": "那曲市",
                "text": "双湖县",
                "level": 3,
                "order": 2835,
                "relation_code": "540630",
                "relation_desc": "西藏双湖县"
            },
            {
                "code": "260601",
                "parentcode": "2606",
                "parenttext": "阿里地区",
                "text": "普兰县",
                "level": 3,
                "order": 2836,
                "relation_code": "542521",
                "relation_desc": "西藏普兰县"
            },
            {
                "code": "260512",
                "parentcode": "2605",
                "parenttext": "那曲市",
                "text": "色尼区",
                "level": 3,
                "order": 2836,
                "relation_code": "540602",
                "relation_desc": "西藏那曲市色尼区"
            },
            {
                "code": "260602",
                "parentcode": "2606",
                "parenttext": "阿里地区",
                "text": "札达县",
                "level": 3,
                "order": 2837,
                "relation_code": "542522",
                "relation_desc": "西藏札达县"
            },
            {
                "code": "260603",
                "parentcode": "2606",
                "parenttext": "阿里地区",
                "text": "噶尔县",
                "level": 3,
                "order": 2838,
                "relation_code": "542523",
                "relation_desc": "西藏噶尔县"
            },
            {
                "code": "260604",
                "parentcode": "2606",
                "parenttext": "阿里地区",
                "text": "日土县",
                "level": 3,
                "order": 2839,
                "relation_code": "542524",
                "relation_desc": "西藏日土县"
            },
            {
                "code": "260605",
                "parentcode": "2606",
                "parenttext": "阿里地区",
                "text": "革吉县",
                "level": 3,
                "order": 2840,
                "relation_code": "542525",
                "relation_desc": "西藏革吉县"
            },
            {
                "code": "260606",
                "parentcode": "2606",
                "parenttext": "阿里地区",
                "text": "改则县",
                "level": 3,
                "order": 2841,
                "relation_code": "542526",
                "relation_desc": "西藏改则县"
            },
            {
                "code": "260607",
                "parentcode": "2606",
                "parenttext": "阿里地区",
                "text": "措勤县",
                "level": 3,
                "order": 2842,
                "relation_code": "542527",
                "relation_desc": "西藏措勤县"
            },
            {
                "code": "2607",
                "parentcode": "26",
                "parenttext": "西藏自治区",
                "text": "林芝市",
                "level": 2,
                "order": 2843,
                "relation_code": "540400",
                "relation_desc": "西藏林芝市"
            },
            {
                "code": "260701",
                "parentcode": "2607",
                "parenttext": "林芝市",
                "text": "巴宜区",
                "level": 3,
                "order": 2844,
                "relation_code": "540402",
                "relation_desc": "西藏林芝市巴宜区"
            },
            {
                "code": "260702",
                "parentcode": "2607",
                "parenttext": "林芝市",
                "text": "工布江达县",
                "level": 3,
                "order": 2845,
                "relation_code": "540421",
                "relation_desc": "西藏工布江达县"
            },
            {
                "code": "260703",
                "parentcode": "2607",
                "parenttext": "林芝市",
                "text": "米林县",
                "level": 3,
                "order": 2846,
                "relation_code": "540422",
                "relation_desc": "西藏米林县"
            },
            {
                "code": "260704",
                "parentcode": "2607",
                "parenttext": "林芝市",
                "text": "墨脱县",
                "level": 3,
                "order": 2847,
                "relation_code": "540423",
                "relation_desc": "西藏墨脱县"
            },
            {
                "code": "260705",
                "parentcode": "2607",
                "parenttext": "林芝市",
                "text": "波密县",
                "level": 3,
                "order": 2848,
                "relation_code": "540424",
                "relation_desc": "西藏波密县"
            },
            {
                "code": "260706",
                "parentcode": "2607",
                "parenttext": "林芝市",
                "text": "察隅县",
                "level": 3,
                "order": 2849,
                "relation_code": "540425",
                "relation_desc": "西藏察隅县"
            },
            {
                "code": "260707",
                "parentcode": "2607",
                "parenttext": "林芝市",
                "text": "朗县",
                "level": 3,
                "order": 2850,
                "relation_code": "540426",
                "relation_desc": "西藏朗县"
            },
            {
                "code": "27",
                "parentcode": "0",
                "parenttext": "0",
                "text": "陕西省",
                "level": 1,
                "order": 2851,
                "relation_code": "",
                "relation_desc": "陕西省"
            },
            {
                "code": "2701",
                "parentcode": "27",
                "parenttext": "陕西省",
                "text": "西安市",
                "level": 2,
                "order": 2852,
                "relation_code": "610100",
                "relation_desc": "陕西省西安市"
            },
            {
                "code": "270101",
                "parentcode": "2701",
                "parenttext": "西安市",
                "text": "新城区",
                "level": 3,
                "order": 2853,
                "relation_code": "610102",
                "relation_desc": "陕西省西安市新城区"
            },
            {
                "code": "270102",
                "parentcode": "2701",
                "parenttext": "西安市",
                "text": "碑林区",
                "level": 3,
                "order": 2854,
                "relation_code": "610103",
                "relation_desc": "陕西省西安市碑林区"
            },
            {
                "code": "270103",
                "parentcode": "2701",
                "parenttext": "西安市",
                "text": "莲湖区",
                "level": 3,
                "order": 2855,
                "relation_code": "610104",
                "relation_desc": "陕西省西安市莲湖区"
            },
            {
                "code": "270104",
                "parentcode": "2701",
                "parenttext": "西安市",
                "text": "灞桥区",
                "level": 3,
                "order": 2856,
                "relation_code": "610111",
                "relation_desc": "陕西省西安市灞桥区"
            },
            {
                "code": "270105",
                "parentcode": "2701",
                "parenttext": "西安市",
                "text": "未央区",
                "level": 3,
                "order": 2857,
                "relation_code": "610112",
                "relation_desc": "陕西省西安市未央区"
            },
            {
                "code": "270106",
                "parentcode": "2701",
                "parenttext": "西安市",
                "text": "雁塔区",
                "level": 3,
                "order": 2858,
                "relation_code": "610113",
                "relation_desc": "陕西省西安市雁塔区"
            },
            {
                "code": "270107",
                "parentcode": "2701",
                "parenttext": "西安市",
                "text": "阎良区",
                "level": 3,
                "order": 2859,
                "relation_code": "610114",
                "relation_desc": "陕西省西安市阎良区"
            },
            {
                "code": "270108",
                "parentcode": "2701",
                "parenttext": "西安市",
                "text": "临潼区",
                "level": 3,
                "order": 2860,
                "relation_code": "610115",
                "relation_desc": "陕西省西安市临潼区"
            },
            {
                "code": "270109",
                "parentcode": "2701",
                "parenttext": "西安市",
                "text": "长安区",
                "level": 3,
                "order": 2861,
                "relation_code": "610116",
                "relation_desc": "陕西省西安市长安区"
            },
            {
                "code": "270110",
                "parentcode": "2701",
                "parenttext": "西安市",
                "text": "蓝田县",
                "level": 3,
                "order": 2862,
                "relation_code": "610122",
                "relation_desc": "陕西省蓝田县"
            },
            {
                "code": "270111",
                "parentcode": "2701",
                "parenttext": "西安市",
                "text": "周至县",
                "level": 3,
                "order": 2863,
                "relation_code": "610124",
                "relation_desc": "陕西省周至县"
            },
            {
                "code": "270112",
                "parentcode": "2701",
                "parenttext": "西安市",
                "text": "鄠邑区",
                "level": 3,
                "order": 2864,
                "relation_code": "610118",
                "relation_desc": "陕西省西安市鄠邑区"
            },
            {
                "code": "270113",
                "parentcode": "2701",
                "parenttext": "西安市",
                "text": "高陵区",
                "level": 3,
                "order": 2865,
                "relation_code": "610117",
                "relation_desc": "陕西省西安市高陵区"
            },
            {
                "code": "2702",
                "parentcode": "27",
                "parenttext": "陕西省",
                "text": "铜川市",
                "level": 2,
                "order": 2866,
                "relation_code": "610200",
                "relation_desc": "陕西省铜川市"
            },
            {
                "code": "270114",
                "parentcode": "2701",
                "parenttext": "西安市",
                "text": "户县",
                "level": 3,
                "order": 2866,
                "relation_code": "610101",
                "relation_desc": "陕西省户县"
            },
            {
                "code": "270201",
                "parentcode": "2702",
                "parenttext": "铜川市",
                "text": "王益区",
                "level": 3,
                "order": 2867,
                "relation_code": "610202",
                "relation_desc": "陕西省铜川市王益区"
            },
            {
                "code": "270202",
                "parentcode": "2702",
                "parenttext": "铜川市",
                "text": "印台区",
                "level": 3,
                "order": 2868,
                "relation_code": "610203",
                "relation_desc": "陕西省铜川市印台区"
            },
            {
                "code": "270203",
                "parentcode": "2702",
                "parenttext": "铜川市",
                "text": "耀州区",
                "level": 3,
                "order": 2869,
                "relation_code": "610204",
                "relation_desc": "陕西省铜川市耀州区"
            },
            {
                "code": "270204",
                "parentcode": "2702",
                "parenttext": "铜川市",
                "text": "宜君县",
                "level": 3,
                "order": 2870,
                "relation_code": "610222",
                "relation_desc": "陕西省宜君县"
            },
            {
                "code": "2703",
                "parentcode": "27",
                "parenttext": "陕西省",
                "text": "宝鸡市",
                "level": 2,
                "order": 2871,
                "relation_code": "610300",
                "relation_desc": "陕西省宝鸡市"
            },
            {
                "code": "270301",
                "parentcode": "2703",
                "parenttext": "宝鸡市",
                "text": "渭滨区",
                "level": 3,
                "order": 2872,
                "relation_code": "610302",
                "relation_desc": "陕西省宝鸡市渭滨区"
            },
            {
                "code": "270302",
                "parentcode": "2703",
                "parenttext": "宝鸡市",
                "text": "金台区",
                "level": 3,
                "order": 2873,
                "relation_code": "610303",
                "relation_desc": "陕西省宝鸡市金台区"
            },
            {
                "code": "270303",
                "parentcode": "2703",
                "parenttext": "宝鸡市",
                "text": "陈仓区",
                "level": 3,
                "order": 2874,
                "relation_code": "610304",
                "relation_desc": "陕西省宝鸡市陈仓区"
            },
            {
                "code": "270304",
                "parentcode": "2703",
                "parenttext": "宝鸡市",
                "text": "凤翔县",
                "level": 3,
                "order": 2875,
                "relation_code": "610322",
                "relation_desc": "陕西省凤翔县"
            },
            {
                "code": "270305",
                "parentcode": "2703",
                "parenttext": "宝鸡市",
                "text": "岐山县",
                "level": 3,
                "order": 2876,
                "relation_code": "610323",
                "relation_desc": "陕西省岐山县"
            },
            {
                "code": "270306",
                "parentcode": "2703",
                "parenttext": "宝鸡市",
                "text": "扶风县",
                "level": 3,
                "order": 2877,
                "relation_code": "610324",
                "relation_desc": "陕西省扶风县"
            },
            {
                "code": "270307",
                "parentcode": "2703",
                "parenttext": "宝鸡市",
                "text": "眉县",
                "level": 3,
                "order": 2878,
                "relation_code": "610326",
                "relation_desc": "陕西省眉县"
            },
            {
                "code": "270308",
                "parentcode": "2703",
                "parenttext": "宝鸡市",
                "text": "陇县",
                "level": 3,
                "order": 2879,
                "relation_code": "610327",
                "relation_desc": "陕西省陇县"
            },
            {
                "code": "270309",
                "parentcode": "2703",
                "parenttext": "宝鸡市",
                "text": "千阳县",
                "level": 3,
                "order": 2880,
                "relation_code": "610328",
                "relation_desc": "陕西省千阳县"
            },
            {
                "code": "270310",
                "parentcode": "2703",
                "parenttext": "宝鸡市",
                "text": "麟游县",
                "level": 3,
                "order": 2881,
                "relation_code": "610329",
                "relation_desc": "陕西省麟游县"
            },
            {
                "code": "270311",
                "parentcode": "2703",
                "parenttext": "宝鸡市",
                "text": "凤县",
                "level": 3,
                "order": 2882,
                "relation_code": "610330",
                "relation_desc": "陕西省凤县"
            },
            {
                "code": "270312",
                "parentcode": "2703",
                "parenttext": "宝鸡市",
                "text": "太白县",
                "level": 3,
                "order": 2883,
                "relation_code": "610331",
                "relation_desc": "陕西省太白县"
            },
            {
                "code": "2704",
                "parentcode": "27",
                "parenttext": "陕西省",
                "text": "咸阳市",
                "level": 2,
                "order": 2884,
                "relation_code": "610400",
                "relation_desc": "陕西省咸阳市"
            },
            {
                "code": "270401",
                "parentcode": "2704",
                "parenttext": "咸阳市",
                "text": "秦都区",
                "level": 3,
                "order": 2885,
                "relation_code": "610402",
                "relation_desc": "陕西省咸阳市秦都区"
            },
            {
                "code": "270402",
                "parentcode": "2704",
                "parenttext": "咸阳市",
                "text": "杨陵区",
                "level": 3,
                "order": 2886,
                "relation_code": "610403",
                "relation_desc": "陕西省咸阳市杨陵区"
            },
            {
                "code": "270403",
                "parentcode": "2704",
                "parenttext": "咸阳市",
                "text": "渭城区",
                "level": 3,
                "order": 2887,
                "relation_code": "610404",
                "relation_desc": "陕西省咸阳市渭城区"
            },
            {
                "code": "270404",
                "parentcode": "2704",
                "parenttext": "咸阳市",
                "text": "三原县",
                "level": 3,
                "order": 2888,
                "relation_code": "610422",
                "relation_desc": "陕西省三原县"
            },
            {
                "code": "270405",
                "parentcode": "2704",
                "parenttext": "咸阳市",
                "text": "泾阳县",
                "level": 3,
                "order": 2889,
                "relation_code": "610423",
                "relation_desc": "陕西省泾阳县"
            },
            {
                "code": "270406",
                "parentcode": "2704",
                "parenttext": "咸阳市",
                "text": "乾县",
                "level": 3,
                "order": 2890,
                "relation_code": "610424",
                "relation_desc": "陕西省乾县"
            },
            {
                "code": "270407",
                "parentcode": "2704",
                "parenttext": "咸阳市",
                "text": "礼泉县",
                "level": 3,
                "order": 2891,
                "relation_code": "610425",
                "relation_desc": "陕西省礼泉县"
            },
            {
                "code": "270408",
                "parentcode": "2704",
                "parenttext": "咸阳市",
                "text": "永寿县",
                "level": 3,
                "order": 2892,
                "relation_code": "610426",
                "relation_desc": "陕西省永寿县"
            },
            {
                "code": "270409",
                "parentcode": "2704",
                "parenttext": "咸阳市",
                "text": "彬县",
                "level": 3,
                "order": 2893,
                "relation_code": "610401",
                "relation_desc": "陕西省彬县"
            },
            {
                "code": "270410",
                "parentcode": "2704",
                "parenttext": "咸阳市",
                "text": "长武县",
                "level": 3,
                "order": 2894,
                "relation_code": "610428",
                "relation_desc": "陕西省长武县"
            },
            {
                "code": "270411",
                "parentcode": "2704",
                "parenttext": "咸阳市",
                "text": "旬邑县",
                "level": 3,
                "order": 2895,
                "relation_code": "610429",
                "relation_desc": "陕西省旬邑县"
            },
            {
                "code": "270412",
                "parentcode": "2704",
                "parenttext": "咸阳市",
                "text": "淳化县",
                "level": 3,
                "order": 2896,
                "relation_code": "610430",
                "relation_desc": "陕西省淳化县"
            },
            {
                "code": "270413",
                "parentcode": "2704",
                "parenttext": "咸阳市",
                "text": "武功县",
                "level": 3,
                "order": 2897,
                "relation_code": "610431",
                "relation_desc": "陕西省武功县"
            },
            {
                "code": "270414",
                "parentcode": "2704",
                "parenttext": "咸阳市",
                "text": "兴平市",
                "level": 3,
                "order": 2898,
                "relation_code": "610481",
                "relation_desc": "陕西省兴平市"
            },
            {
                "code": "2705",
                "parentcode": "27",
                "parenttext": "陕西省",
                "text": "渭南市",
                "level": 2,
                "order": 2899,
                "relation_code": "610500",
                "relation_desc": "陕西省渭南市"
            },
            {
                "code": "270501",
                "parentcode": "2705",
                "parenttext": "渭南市",
                "text": "临渭区",
                "level": 3,
                "order": 2900,
                "relation_code": "610502",
                "relation_desc": "陕西省渭南市临渭区"
            },
            {
                "code": "270502",
                "parentcode": "2705",
                "parenttext": "渭南市",
                "text": "华州区",
                "level": 3,
                "order": 2901,
                "relation_code": "610503",
                "relation_desc": "陕西省渭南市华州区"
            },
            {
                "code": "270503",
                "parentcode": "2705",
                "parenttext": "渭南市",
                "text": "潼关县",
                "level": 3,
                "order": 2902,
                "relation_code": "610522",
                "relation_desc": "陕西省潼关县"
            },
            {
                "code": "270504",
                "parentcode": "2705",
                "parenttext": "渭南市",
                "text": "大荔县",
                "level": 3,
                "order": 2903,
                "relation_code": "610523",
                "relation_desc": "陕西省大荔县"
            },
            {
                "code": "270505",
                "parentcode": "2705",
                "parenttext": "渭南市",
                "text": "合阳县",
                "level": 3,
                "order": 2904,
                "relation_code": "610524",
                "relation_desc": "陕西省合阳县"
            },
            {
                "code": "270506",
                "parentcode": "2705",
                "parenttext": "渭南市",
                "text": "澄城县",
                "level": 3,
                "order": 2905,
                "relation_code": "610525",
                "relation_desc": "陕西省澄城县"
            },
            {
                "code": "270507",
                "parentcode": "2705",
                "parenttext": "渭南市",
                "text": "蒲城县",
                "level": 3,
                "order": 2906,
                "relation_code": "610526",
                "relation_desc": "陕西省蒲城县"
            },
            {
                "code": "270508",
                "parentcode": "2705",
                "parenttext": "渭南市",
                "text": "白水县",
                "level": 3,
                "order": 2907,
                "relation_code": "610527",
                "relation_desc": "陕西省白水县"
            },
            {
                "code": "270509",
                "parentcode": "2705",
                "parenttext": "渭南市",
                "text": "富平县",
                "level": 3,
                "order": 2908,
                "relation_code": "610528",
                "relation_desc": "陕西省富平县"
            },
            {
                "code": "270510",
                "parentcode": "2705",
                "parenttext": "渭南市",
                "text": "韩城市",
                "level": 3,
                "order": 2909,
                "relation_code": "610581",
                "relation_desc": "陕西省韩城市"
            },
            {
                "code": "270511",
                "parentcode": "2705",
                "parenttext": "渭南市",
                "text": "华阴市",
                "level": 3,
                "order": 2910,
                "relation_code": "610582",
                "relation_desc": "陕西省华阴市"
            },
            {
                "code": "2706",
                "parentcode": "27",
                "parenttext": "陕西省",
                "text": "延安市",
                "level": 2,
                "order": 2911,
                "relation_code": "610600",
                "relation_desc": "陕西省延安市"
            },
            {
                "code": "270602",
                "parentcode": "2706",
                "parenttext": "延安市",
                "text": "宝塔区",
                "level": 3,
                "order": 2913,
                "relation_code": "610602",
                "relation_desc": "陕西省延安市宝塔区"
            },
            {
                "code": "270603",
                "parentcode": "2706",
                "parenttext": "延安市",
                "text": "延长县",
                "level": 3,
                "order": 2914,
                "relation_code": "610621",
                "relation_desc": "陕西省延长县"
            },
            {
                "code": "270604",
                "parentcode": "2706",
                "parenttext": "延安市",
                "text": "延川县",
                "level": 3,
                "order": 2915,
                "relation_code": "610622",
                "relation_desc": "陕西省延川县"
            },
            {
                "code": "270605",
                "parentcode": "2706",
                "parenttext": "延安市",
                "text": "子长县",
                "level": 3,
                "order": 2916,
                "relation_code": "610601",
                "relation_desc": "陕西省子长县"
            },
            {
                "code": "270606",
                "parentcode": "2706",
                "parenttext": "延安市",
                "text": "安塞区",
                "level": 3,
                "order": 2917,
                "relation_code": "610601",
                "relation_desc": "陕西省延安市"
            },
            {
                "code": "270607",
                "parentcode": "2706",
                "parenttext": "延安市",
                "text": "志丹县",
                "level": 3,
                "order": 2918,
                "relation_code": "610625",
                "relation_desc": "陕西省志丹县"
            },
            {
                "code": "270608",
                "parentcode": "2706",
                "parenttext": "延安市",
                "text": "吴起县",
                "level": 3,
                "order": 2919,
                "relation_code": "610626",
                "relation_desc": "陕西省吴起县"
            },
            {
                "code": "270609",
                "parentcode": "2706",
                "parenttext": "延安市",
                "text": "甘泉县",
                "level": 3,
                "order": 2920,
                "relation_code": "610627",
                "relation_desc": "陕西省甘泉县"
            },
            {
                "code": "270610",
                "parentcode": "2706",
                "parenttext": "延安市",
                "text": "富县",
                "level": 3,
                "order": 2921,
                "relation_code": "610628",
                "relation_desc": "陕西省富县"
            },
            {
                "code": "270611",
                "parentcode": "2706",
                "parenttext": "延安市",
                "text": "洛川县",
                "level": 3,
                "order": 2922,
                "relation_code": "610629",
                "relation_desc": "陕西省洛川县"
            },
            {
                "code": "270612",
                "parentcode": "2706",
                "parenttext": "延安市",
                "text": "宜川县",
                "level": 3,
                "order": 2923,
                "relation_code": "610630",
                "relation_desc": "陕西省宜川县"
            },
            {
                "code": "270613",
                "parentcode": "2706",
                "parenttext": "延安市",
                "text": "黄龙县",
                "level": 3,
                "order": 2924,
                "relation_code": "610631",
                "relation_desc": "陕西省黄龙县"
            },
            {
                "code": "270614",
                "parentcode": "2706",
                "parenttext": "延安市",
                "text": "黄陵县",
                "level": 3,
                "order": 2925,
                "relation_code": "610632",
                "relation_desc": "陕西省黄陵县"
            },
            {
                "code": "2707",
                "parentcode": "27",
                "parenttext": "陕西省",
                "text": "汉中市",
                "level": 2,
                "order": 2926,
                "relation_code": "610700",
                "relation_desc": "陕西省汉中市"
            },
            {
                "code": "270701",
                "parentcode": "2707",
                "parenttext": "汉中市",
                "text": "汉台区",
                "level": 3,
                "order": 2927,
                "relation_code": "610702",
                "relation_desc": "陕西省汉中市汉台区"
            },
            {
                "code": "270702",
                "parentcode": "2707",
                "parenttext": "汉中市",
                "text": "南郑县",
                "level": 3,
                "order": 2928,
                "relation_code": "610701",
                "relation_desc": "陕西省南郑县"
            },
            {
                "code": "270703",
                "parentcode": "2707",
                "parenttext": "汉中市",
                "text": "城固县",
                "level": 3,
                "order": 2929,
                "relation_code": "610722",
                "relation_desc": "陕西省城固县"
            },
            {
                "code": "270704",
                "parentcode": "2707",
                "parenttext": "汉中市",
                "text": "洋县",
                "level": 3,
                "order": 2930,
                "relation_code": "610723",
                "relation_desc": "陕西省洋县"
            },
            {
                "code": "270705",
                "parentcode": "2707",
                "parenttext": "汉中市",
                "text": "西乡县",
                "level": 3,
                "order": 2931,
                "relation_code": "610724",
                "relation_desc": "陕西省西乡县"
            },
            {
                "code": "270706",
                "parentcode": "2707",
                "parenttext": "汉中市",
                "text": "勉县",
                "level": 3,
                "order": 2932,
                "relation_code": "610725",
                "relation_desc": "陕西省勉县"
            },
            {
                "code": "270707",
                "parentcode": "2707",
                "parenttext": "汉中市",
                "text": "宁强县",
                "level": 3,
                "order": 2933,
                "relation_code": "610726",
                "relation_desc": "陕西省宁强县"
            },
            {
                "code": "270708",
                "parentcode": "2707",
                "parenttext": "汉中市",
                "text": "略阳县",
                "level": 3,
                "order": 2934,
                "relation_code": "610727",
                "relation_desc": "陕西省略阳县"
            },
            {
                "code": "270709",
                "parentcode": "2707",
                "parenttext": "汉中市",
                "text": "镇巴县",
                "level": 3,
                "order": 2935,
                "relation_code": "610728",
                "relation_desc": "陕西省镇巴县"
            },
            {
                "code": "270710",
                "parentcode": "2707",
                "parenttext": "汉中市",
                "text": "留坝县",
                "level": 3,
                "order": 2936,
                "relation_code": "610729",
                "relation_desc": "陕西省留坝县"
            },
            {
                "code": "270711",
                "parentcode": "2707",
                "parenttext": "汉中市",
                "text": "佛坪县",
                "level": 3,
                "order": 2937,
                "relation_code": "610730",
                "relation_desc": "陕西省佛坪县"
            },
            {
                "code": "2708",
                "parentcode": "27",
                "parenttext": "陕西省",
                "text": "榆林市",
                "level": 2,
                "order": 2938,
                "relation_code": "610800",
                "relation_desc": "陕西省榆林市"
            },
            {
                "code": "270801",
                "parentcode": "2708",
                "parenttext": "榆林市",
                "text": "榆阳区",
                "level": 3,
                "order": 2939,
                "relation_code": "610802",
                "relation_desc": "陕西省榆林市榆阳区"
            },
            {
                "code": "270802",
                "parentcode": "2708",
                "parenttext": "榆林市",
                "text": "神木市",
                "level": 3,
                "order": 2940,
                "relation_code": "610881",
                "relation_desc": "陕西省神木市"
            },
            {
                "code": "270803",
                "parentcode": "2708",
                "parenttext": "榆林市",
                "text": "府谷县",
                "level": 3,
                "order": 2941,
                "relation_code": "610822",
                "relation_desc": "陕西省府谷县"
            },
            {
                "code": "270804",
                "parentcode": "2708",
                "parenttext": "榆林市",
                "text": "横山区",
                "level": 3,
                "order": 2942,
                "relation_code": "610803",
                "relation_desc": "陕西省榆林市横山区"
            },
            {
                "code": "270805",
                "parentcode": "2708",
                "parenttext": "榆林市",
                "text": "靖边县",
                "level": 3,
                "order": 2943,
                "relation_code": "610824",
                "relation_desc": "陕西省靖边县"
            },
            {
                "code": "270806",
                "parentcode": "2708",
                "parenttext": "榆林市",
                "text": "定边县",
                "level": 3,
                "order": 2944,
                "relation_code": "610825",
                "relation_desc": "陕西省定边县"
            },
            {
                "code": "270807",
                "parentcode": "2708",
                "parenttext": "榆林市",
                "text": "绥德县",
                "level": 3,
                "order": 2945,
                "relation_code": "610826",
                "relation_desc": "陕西省绥德县"
            },
            {
                "code": "270808",
                "parentcode": "2708",
                "parenttext": "榆林市",
                "text": "米脂县",
                "level": 3,
                "order": 2946,
                "relation_code": "610827",
                "relation_desc": "陕西省米脂县"
            },
            {
                "code": "270809",
                "parentcode": "2708",
                "parenttext": "榆林市",
                "text": "佳县",
                "level": 3,
                "order": 2947,
                "relation_code": "610828",
                "relation_desc": "陕西省佳县"
            },
            {
                "code": "270810",
                "parentcode": "2708",
                "parenttext": "榆林市",
                "text": "吴堡县",
                "level": 3,
                "order": 2948,
                "relation_code": "610829",
                "relation_desc": "陕西省吴堡县"
            },
            {
                "code": "270811",
                "parentcode": "2708",
                "parenttext": "榆林市",
                "text": "清涧县",
                "level": 3,
                "order": 2949,
                "relation_code": "610830",
                "relation_desc": "陕西省清涧县"
            },
            {
                "code": "270812",
                "parentcode": "2708",
                "parenttext": "榆林市",
                "text": "子洲县",
                "level": 3,
                "order": 2950,
                "relation_code": "610831",
                "relation_desc": "陕西省子洲县"
            },
            {
                "code": "2709",
                "parentcode": "27",
                "parenttext": "陕西省",
                "text": "安康市",
                "level": 2,
                "order": 2951,
                "relation_code": "610900",
                "relation_desc": "陕西省安康市"
            },
            {
                "code": "270901",
                "parentcode": "2709",
                "parenttext": "安康市",
                "text": "汉滨区",
                "level": 3,
                "order": 2952,
                "relation_code": "610902",
                "relation_desc": "陕西省安康市汉滨区"
            },
            {
                "code": "270902",
                "parentcode": "2709",
                "parenttext": "安康市",
                "text": "汉阴县",
                "level": 3,
                "order": 2953,
                "relation_code": "610921",
                "relation_desc": "陕西省汉阴县"
            },
            {
                "code": "270903",
                "parentcode": "2709",
                "parenttext": "安康市",
                "text": "石泉县",
                "level": 3,
                "order": 2954,
                "relation_code": "610922",
                "relation_desc": "陕西省石泉县"
            },
            {
                "code": "270904",
                "parentcode": "2709",
                "parenttext": "安康市",
                "text": "宁陕县",
                "level": 3,
                "order": 2955,
                "relation_code": "610923",
                "relation_desc": "陕西省宁陕县"
            },
            {
                "code": "270905",
                "parentcode": "2709",
                "parenttext": "安康市",
                "text": "紫阳县",
                "level": 3,
                "order": 2956,
                "relation_code": "610924",
                "relation_desc": "陕西省紫阳县"
            },
            {
                "code": "270906",
                "parentcode": "2709",
                "parenttext": "安康市",
                "text": "岚皋县",
                "level": 3,
                "order": 2957,
                "relation_code": "610925",
                "relation_desc": "陕西省岚皋县"
            },
            {
                "code": "270907",
                "parentcode": "2709",
                "parenttext": "安康市",
                "text": "平利县",
                "level": 3,
                "order": 2958,
                "relation_code": "610926",
                "relation_desc": "陕西省平利县"
            },
            {
                "code": "270908",
                "parentcode": "2709",
                "parenttext": "安康市",
                "text": "镇坪县",
                "level": 3,
                "order": 2959,
                "relation_code": "610927",
                "relation_desc": "陕西省镇坪县"
            },
            {
                "code": "270909",
                "parentcode": "2709",
                "parenttext": "安康市",
                "text": "旬阳县",
                "level": 3,
                "order": 2960,
                "relation_code": "610928",
                "relation_desc": "陕西省旬阳县"
            },
            {
                "code": "270910",
                "parentcode": "2709",
                "parenttext": "安康市",
                "text": "白河县",
                "level": 3,
                "order": 2961,
                "relation_code": "610929",
                "relation_desc": "陕西省白河县"
            },
            {
                "code": "2710",
                "parentcode": "27",
                "parenttext": "陕西省",
                "text": "商洛市",
                "level": 2,
                "order": 2962,
                "relation_code": "611000",
                "relation_desc": "陕西省商洛市"
            },
            {
                "code": "271001",
                "parentcode": "2710",
                "parenttext": "商洛市",
                "text": "商州区",
                "level": 3,
                "order": 2963,
                "relation_code": "611002",
                "relation_desc": "陕西省商洛市商州区"
            },
            {
                "code": "271002",
                "parentcode": "2710",
                "parenttext": "商洛市",
                "text": "洛南县",
                "level": 3,
                "order": 2964,
                "relation_code": "611021",
                "relation_desc": "陕西省洛南县"
            },
            {
                "code": "271003",
                "parentcode": "2710",
                "parenttext": "商洛市",
                "text": "丹凤县",
                "level": 3,
                "order": 2965,
                "relation_code": "611022",
                "relation_desc": "陕西省丹凤县"
            },
            {
                "code": "271004",
                "parentcode": "2710",
                "parenttext": "商洛市",
                "text": "商南县",
                "level": 3,
                "order": 2966,
                "relation_code": "611023",
                "relation_desc": "陕西省商南县"
            },
            {
                "code": "271005",
                "parentcode": "2710",
                "parenttext": "商洛市",
                "text": "山阳县",
                "level": 3,
                "order": 2967,
                "relation_code": "611024",
                "relation_desc": "陕西省山阳县"
            },
            {
                "code": "271006",
                "parentcode": "2710",
                "parenttext": "商洛市",
                "text": "镇安县",
                "level": 3,
                "order": 2968,
                "relation_code": "611025",
                "relation_desc": "陕西省镇安县"
            },
            {
                "code": "271007",
                "parentcode": "2710",
                "parenttext": "商洛市",
                "text": "柞水县",
                "level": 3,
                "order": 2969,
                "relation_code": "611026",
                "relation_desc": "陕西省柞水县"
            },
            {
                "code": "28",
                "parentcode": "0",
                "parenttext": "0",
                "text": "甘肃省",
                "level": 1,
                "order": 2970,
                "relation_code": "",
                "relation_desc": "甘肃省"
            },
            {
                "code": "2801",
                "parentcode": "28",
                "parenttext": "甘肃省",
                "text": "兰州市",
                "level": 2,
                "order": 2971,
                "relation_code": "620100",
                "relation_desc": "甘肃省兰州市"
            },
            {
                "code": "280101",
                "parentcode": "2801",
                "parenttext": "兰州市",
                "text": "城关区",
                "level": 3,
                "order": 2972,
                "relation_code": "620102",
                "relation_desc": "甘肃省兰州市城关区"
            },
            {
                "code": "280102",
                "parentcode": "2801",
                "parenttext": "兰州市",
                "text": "七里河区",
                "level": 3,
                "order": 2973,
                "relation_code": "620103",
                "relation_desc": "甘肃省兰州市七里河区"
            },
            {
                "code": "280103",
                "parentcode": "2801",
                "parenttext": "兰州市",
                "text": "西固区",
                "level": 3,
                "order": 2974,
                "relation_code": "620104",
                "relation_desc": "甘肃省兰州市西固区"
            },
            {
                "code": "280104",
                "parentcode": "2801",
                "parenttext": "兰州市",
                "text": "安宁区",
                "level": 3,
                "order": 2975,
                "relation_code": "620105",
                "relation_desc": "甘肃省兰州市安宁区"
            },
            {
                "code": "280105",
                "parentcode": "2801",
                "parenttext": "兰州市",
                "text": "红古区",
                "level": 3,
                "order": 2976,
                "relation_code": "620111",
                "relation_desc": "甘肃省兰州市红古区"
            },
            {
                "code": "280106",
                "parentcode": "2801",
                "parenttext": "兰州市",
                "text": "永登县",
                "level": 3,
                "order": 2977,
                "relation_code": "620121",
                "relation_desc": "甘肃省永登县"
            },
            {
                "code": "280107",
                "parentcode": "2801",
                "parenttext": "兰州市",
                "text": "皋兰县",
                "level": 3,
                "order": 2978,
                "relation_code": "620122",
                "relation_desc": "甘肃省皋兰县"
            },
            {
                "code": "280108",
                "parentcode": "2801",
                "parenttext": "兰州市",
                "text": "榆中县",
                "level": 3,
                "order": 2979,
                "relation_code": "620123",
                "relation_desc": "甘肃省榆中县"
            },
            {
                "code": "2802",
                "parentcode": "28",
                "parenttext": "甘肃省",
                "text": "嘉峪关市",
                "level": 2,
                "order": 2980,
                "relation_code": "620200",
                "relation_desc": "甘肃省嘉峪关市"
            },
            {
                "code": "280109",
                "parentcode": "2801",
                "parenttext": "兰州市",
                "text": "兰州新区",
                "level": 3,
                "order": 2980,
                "relation_code": "620101",
                "relation_desc": "甘肃省兰州市"
            },
            {
                "code": "280201",
                "parentcode": "2802",
                "parenttext": "嘉峪关市",
                "text": "雄关区",
                "level": 3,
                "order": 2981,
                "relation_code": "620201",
                "relation_desc": "甘肃省嘉峪关市"
            },
            {
                "code": "280202",
                "parentcode": "2802",
                "parenttext": "嘉峪关市",
                "text": "长城区",
                "level": 3,
                "order": 2982,
                "relation_code": "620201",
                "relation_desc": "甘肃省嘉峪关市"
            },
            {
                "code": "280203",
                "parentcode": "2802",
                "parenttext": "嘉峪关市",
                "text": "镜铁区",
                "level": 3,
                "order": 2983,
                "relation_code": "620201",
                "relation_desc": "甘肃省嘉峪关市"
            },
            {
                "code": "2803",
                "parentcode": "28",
                "parenttext": "甘肃省",
                "text": "金昌市",
                "level": 2,
                "order": 2984,
                "relation_code": "620300",
                "relation_desc": "甘肃省金昌市"
            },
            {
                "code": "280204",
                "parentcode": "2802",
                "parenttext": "嘉峪关市",
                "text": "新城镇",
                "level": 3,
                "order": 2984,
                "relation_code": "620201",
                "relation_desc": "甘肃省嘉峪关市"
            },
            {
                "code": "280301",
                "parentcode": "2803",
                "parenttext": "金昌市",
                "text": "金川区",
                "level": 3,
                "order": 2985,
                "relation_code": "620302",
                "relation_desc": "甘肃省金昌市金川区"
            },
            {
                "code": "280205",
                "parentcode": "2802",
                "parenttext": "嘉峪关市",
                "text": "峪泉镇",
                "level": 3,
                "order": 2985,
                "relation_code": "620201",
                "relation_desc": "甘肃省嘉峪关市"
            },
            {
                "code": "280302",
                "parentcode": "2803",
                "parenttext": "金昌市",
                "text": "永昌县",
                "level": 3,
                "order": 2986,
                "relation_code": "620321",
                "relation_desc": "甘肃省永昌县"
            },
            {
                "code": "280206",
                "parentcode": "2802",
                "parenttext": "嘉峪关市",
                "text": "文殊镇",
                "level": 3,
                "order": 2986,
                "relation_code": "620201",
                "relation_desc": "甘肃省嘉峪关市"
            },
            {
                "code": "2804",
                "parentcode": "28",
                "parenttext": "甘肃省",
                "text": "白银市",
                "level": 2,
                "order": 2987,
                "relation_code": "620400",
                "relation_desc": "甘肃省白银市"
            },
            {
                "code": "280401",
                "parentcode": "2804",
                "parenttext": "白银市",
                "text": "白银区",
                "level": 3,
                "order": 2988,
                "relation_code": "620402",
                "relation_desc": "甘肃省白银市白银区"
            },
            {
                "code": "280402",
                "parentcode": "2804",
                "parenttext": "白银市",
                "text": "平川区",
                "level": 3,
                "order": 2989,
                "relation_code": "620403",
                "relation_desc": "甘肃省白银市平川区"
            },
            {
                "code": "280403",
                "parentcode": "2804",
                "parenttext": "白银市",
                "text": "靖远县",
                "level": 3,
                "order": 2990,
                "relation_code": "620421",
                "relation_desc": "甘肃省靖远县"
            },
            {
                "code": "280404",
                "parentcode": "2804",
                "parenttext": "白银市",
                "text": "会宁县",
                "level": 3,
                "order": 2991,
                "relation_code": "620422",
                "relation_desc": "甘肃省会宁县"
            },
            {
                "code": "280405",
                "parentcode": "2804",
                "parenttext": "白银市",
                "text": "景泰县",
                "level": 3,
                "order": 2992,
                "relation_code": "620423",
                "relation_desc": "甘肃省景泰县"
            },
            {
                "code": "2805",
                "parentcode": "28",
                "parenttext": "甘肃省",
                "text": "天水市",
                "level": 2,
                "order": 2993,
                "relation_code": "620500",
                "relation_desc": "甘肃省天水市"
            },
            {
                "code": "280501",
                "parentcode": "2805",
                "parenttext": "天水市",
                "text": "秦城区",
                "level": 3,
                "order": 2994,
                "relation_code": "620521",
                "relation_desc": "甘肃省天水市"
            },
            {
                "code": "280502",
                "parentcode": "2805",
                "parenttext": "天水市",
                "text": "北道区",
                "level": 3,
                "order": 2995,
                "relation_code": "620521",
                "relation_desc": "甘肃省天水市"
            },
            {
                "code": "280503",
                "parentcode": "2805",
                "parenttext": "天水市",
                "text": "清水县",
                "level": 3,
                "order": 2996,
                "relation_code": "620521",
                "relation_desc": "甘肃省清水县"
            },
            {
                "code": "280504",
                "parentcode": "2805",
                "parenttext": "天水市",
                "text": "秦安县",
                "level": 3,
                "order": 2997,
                "relation_code": "620522",
                "relation_desc": "甘肃省秦安县"
            },
            {
                "code": "280505",
                "parentcode": "2805",
                "parenttext": "天水市",
                "text": "甘谷县",
                "level": 3,
                "order": 2998,
                "relation_code": "620523",
                "relation_desc": "甘肃省甘谷县"
            },
            {
                "code": "280506",
                "parentcode": "2805",
                "parenttext": "天水市",
                "text": "武山县",
                "level": 3,
                "order": 2999,
                "relation_code": "620524",
                "relation_desc": "甘肃省武山县"
            },
            {
                "code": "280507",
                "parentcode": "2805",
                "parenttext": "天水市",
                "text": "张家川回族自治县",
                "level": 3,
                "order": 3000,
                "relation_code": "620525",
                "relation_desc": "甘肃省张家川回族自治县"
            },
            {
                "code": "2806",
                "parentcode": "28",
                "parenttext": "甘肃省",
                "text": "武威市",
                "level": 2,
                "order": 3001,
                "relation_code": "620600",
                "relation_desc": "甘肃省武威市"
            },
            {
                "code": "280508",
                "parentcode": "2805",
                "parenttext": "天水市",
                "text": "秦州区",
                "level": 3,
                "order": 3001,
                "relation_code": "620502",
                "relation_desc": "甘肃省天水市秦州区"
            },
            {
                "code": "280601",
                "parentcode": "2806",
                "parenttext": "武威市",
                "text": "凉州区",
                "level": 3,
                "order": 3002,
                "relation_code": "620602",
                "relation_desc": "甘肃省武威市凉州区"
            },
            {
                "code": "280509",
                "parentcode": "2805",
                "parenttext": "天水市",
                "text": "麦积区",
                "level": 3,
                "order": 3002,
                "relation_code": "620503",
                "relation_desc": "甘肃省天水市麦积区"
            },
            {
                "code": "280602",
                "parentcode": "2806",
                "parenttext": "武威市",
                "text": "民勤县",
                "level": 3,
                "order": 3003,
                "relation_code": "620621",
                "relation_desc": "甘肃省民勤县"
            },
            {
                "code": "280603",
                "parentcode": "2806",
                "parenttext": "武威市",
                "text": "古浪县",
                "level": 3,
                "order": 3004,
                "relation_code": "620622",
                "relation_desc": "甘肃省古浪县"
            },
            {
                "code": "280604",
                "parentcode": "2806",
                "parenttext": "武威市",
                "text": "天祝藏族自治县",
                "level": 3,
                "order": 3005,
                "relation_code": "620623",
                "relation_desc": "甘肃省天祝藏族自治县"
            },
            {
                "code": "2807",
                "parentcode": "28",
                "parenttext": "甘肃省",
                "text": "张掖市",
                "level": 2,
                "order": 3006,
                "relation_code": "620700",
                "relation_desc": "甘肃省张掖市"
            },
            {
                "code": "280701",
                "parentcode": "2807",
                "parenttext": "张掖市",
                "text": "甘州区",
                "level": 3,
                "order": 3007,
                "relation_code": "620702",
                "relation_desc": "甘肃省张掖市甘州区"
            },
            {
                "code": "280702",
                "parentcode": "2807",
                "parenttext": "张掖市",
                "text": "肃南裕固族自治县",
                "level": 3,
                "order": 3008,
                "relation_code": "620721",
                "relation_desc": "甘肃省肃南裕固族自治县"
            },
            {
                "code": "280703",
                "parentcode": "2807",
                "parenttext": "张掖市",
                "text": "民乐县",
                "level": 3,
                "order": 3009,
                "relation_code": "620722",
                "relation_desc": "甘肃省民乐县"
            },
            {
                "code": "280704",
                "parentcode": "2807",
                "parenttext": "张掖市",
                "text": "临泽县",
                "level": 3,
                "order": 3010,
                "relation_code": "620723",
                "relation_desc": "甘肃省临泽县"
            },
            {
                "code": "280705",
                "parentcode": "2807",
                "parenttext": "张掖市",
                "text": "高台县",
                "level": 3,
                "order": 3011,
                "relation_code": "620724",
                "relation_desc": "甘肃省高台县"
            },
            {
                "code": "280706",
                "parentcode": "2807",
                "parenttext": "张掖市",
                "text": "山丹县",
                "level": 3,
                "order": 3012,
                "relation_code": "620725",
                "relation_desc": "甘肃省山丹县"
            },
            {
                "code": "2808",
                "parentcode": "28",
                "parenttext": "甘肃省",
                "text": "平凉市",
                "level": 2,
                "order": 3013,
                "relation_code": "620800",
                "relation_desc": "甘肃省平凉市"
            },
            {
                "code": "280801",
                "parentcode": "2808",
                "parenttext": "平凉市",
                "text": "崆峒区",
                "level": 3,
                "order": 3014,
                "relation_code": "620802",
                "relation_desc": "甘肃省平凉市崆峒区"
            },
            {
                "code": "280802",
                "parentcode": "2808",
                "parenttext": "平凉市",
                "text": "泾川县",
                "level": 3,
                "order": 3015,
                "relation_code": "620821",
                "relation_desc": "甘肃省泾川县"
            },
            {
                "code": "280803",
                "parentcode": "2808",
                "parenttext": "平凉市",
                "text": "灵台县",
                "level": 3,
                "order": 3016,
                "relation_code": "620822",
                "relation_desc": "甘肃省灵台县"
            },
            {
                "code": "280804",
                "parentcode": "2808",
                "parenttext": "平凉市",
                "text": "崇信县",
                "level": 3,
                "order": 3017,
                "relation_code": "620823",
                "relation_desc": "甘肃省崇信县"
            },
            {
                "code": "280805",
                "parentcode": "2808",
                "parenttext": "平凉市",
                "text": "华亭县",
                "level": 3,
                "order": 3018,
                "relation_code": "620801",
                "relation_desc": "甘肃省华亭县"
            },
            {
                "code": "280806",
                "parentcode": "2808",
                "parenttext": "平凉市",
                "text": "庄浪县",
                "level": 3,
                "order": 3019,
                "relation_code": "620825",
                "relation_desc": "甘肃省庄浪县"
            },
            {
                "code": "280807",
                "parentcode": "2808",
                "parenttext": "平凉市",
                "text": "静宁县",
                "level": 3,
                "order": 3020,
                "relation_code": "620826",
                "relation_desc": "甘肃省静宁县"
            },
            {
                "code": "2809",
                "parentcode": "28",
                "parenttext": "甘肃省",
                "text": "酒泉市",
                "level": 2,
                "order": 3021,
                "relation_code": "620900",
                "relation_desc": "甘肃省酒泉市"
            },
            {
                "code": "280901",
                "parentcode": "2809",
                "parenttext": "酒泉市",
                "text": "肃州区",
                "level": 3,
                "order": 3022,
                "relation_code": "620902",
                "relation_desc": "甘肃省酒泉市肃州区"
            },
            {
                "code": "280902",
                "parentcode": "2809",
                "parenttext": "酒泉市",
                "text": "金塔县",
                "level": 3,
                "order": 3023,
                "relation_code": "620921",
                "relation_desc": "甘肃省金塔县"
            },
            {
                "code": "280903",
                "parentcode": "2809",
                "parenttext": "酒泉市",
                "text": "安西县",
                "level": 3,
                "order": 3024,
                "relation_code": "620901",
                "relation_desc": "甘肃省酒泉市"
            },
            {
                "code": "280904",
                "parentcode": "2809",
                "parenttext": "酒泉市",
                "text": "肃北蒙古族自治县",
                "level": 3,
                "order": 3025,
                "relation_code": "620923",
                "relation_desc": "甘肃省肃北蒙古族自治县"
            },
            {
                "code": "280905",
                "parentcode": "2809",
                "parenttext": "酒泉市",
                "text": "阿克塞哈萨克族自治县",
                "level": 3,
                "order": 3026,
                "relation_code": "620924",
                "relation_desc": "甘肃省阿克塞哈萨克族自治县"
            },
            {
                "code": "280906",
                "parentcode": "2809",
                "parenttext": "酒泉市",
                "text": "玉门市",
                "level": 3,
                "order": 3027,
                "relation_code": "620981",
                "relation_desc": "甘肃省玉门市"
            },
            {
                "code": "280907",
                "parentcode": "2809",
                "parenttext": "酒泉市",
                "text": "敦煌市",
                "level": 3,
                "order": 3028,
                "relation_code": "620982",
                "relation_desc": "甘肃省敦煌市"
            },
            {
                "code": "2810",
                "parentcode": "28",
                "parenttext": "甘肃省",
                "text": "庆阳市",
                "level": 2,
                "order": 3029,
                "relation_code": "621000",
                "relation_desc": "甘肃省庆阳市"
            },
            {
                "code": "281001",
                "parentcode": "2810",
                "parenttext": "庆阳市",
                "text": "西峰区",
                "level": 3,
                "order": 3030,
                "relation_code": "621002",
                "relation_desc": "甘肃省庆阳市西峰区"
            },
            {
                "code": "281002",
                "parentcode": "2810",
                "parenttext": "庆阳市",
                "text": "庆城县",
                "level": 3,
                "order": 3031,
                "relation_code": "621021",
                "relation_desc": "甘肃省庆城县"
            },
            {
                "code": "281003",
                "parentcode": "2810",
                "parenttext": "庆阳市",
                "text": "环县",
                "level": 3,
                "order": 3032,
                "relation_code": "621022",
                "relation_desc": "甘肃省环县"
            },
            {
                "code": "281004",
                "parentcode": "2810",
                "parenttext": "庆阳市",
                "text": "华池县",
                "level": 3,
                "order": 3033,
                "relation_code": "621023",
                "relation_desc": "甘肃省华池县"
            },
            {
                "code": "281005",
                "parentcode": "2810",
                "parenttext": "庆阳市",
                "text": "合水县",
                "level": 3,
                "order": 3034,
                "relation_code": "621024",
                "relation_desc": "甘肃省合水县"
            },
            {
                "code": "281006",
                "parentcode": "2810",
                "parenttext": "庆阳市",
                "text": "正宁县",
                "level": 3,
                "order": 3035,
                "relation_code": "621025",
                "relation_desc": "甘肃省正宁县"
            },
            {
                "code": "281007",
                "parentcode": "2810",
                "parenttext": "庆阳市",
                "text": "宁县",
                "level": 3,
                "order": 3036,
                "relation_code": "621026",
                "relation_desc": "甘肃省宁县"
            },
            {
                "code": "281008",
                "parentcode": "2810",
                "parenttext": "庆阳市",
                "text": "镇原县",
                "level": 3,
                "order": 3037,
                "relation_code": "621027",
                "relation_desc": "甘肃省镇原县"
            },
            {
                "code": "2811",
                "parentcode": "28",
                "parenttext": "甘肃省",
                "text": "定西市",
                "level": 2,
                "order": 3038,
                "relation_code": "621100",
                "relation_desc": "甘肃省定西市"
            },
            {
                "code": "281101",
                "parentcode": "2811",
                "parenttext": "定西市",
                "text": "安定区",
                "level": 3,
                "order": 3039,
                "relation_code": "621102",
                "relation_desc": "甘肃省定西市安定区"
            },
            {
                "code": "281102",
                "parentcode": "2811",
                "parenttext": "定西市",
                "text": "通渭县",
                "level": 3,
                "order": 3040,
                "relation_code": "621121",
                "relation_desc": "甘肃省通渭县"
            },
            {
                "code": "281103",
                "parentcode": "2811",
                "parenttext": "定西市",
                "text": "陇西县",
                "level": 3,
                "order": 3041,
                "relation_code": "621122",
                "relation_desc": "甘肃省陇西县"
            },
            {
                "code": "281104",
                "parentcode": "2811",
                "parenttext": "定西市",
                "text": "渭源县",
                "level": 3,
                "order": 3042,
                "relation_code": "621123",
                "relation_desc": "甘肃省渭源县"
            },
            {
                "code": "281105",
                "parentcode": "2811",
                "parenttext": "定西市",
                "text": "临洮县",
                "level": 3,
                "order": 3043,
                "relation_code": "621124",
                "relation_desc": "甘肃省临洮县"
            },
            {
                "code": "281106",
                "parentcode": "2811",
                "parenttext": "定西市",
                "text": "漳县",
                "level": 3,
                "order": 3044,
                "relation_code": "621125",
                "relation_desc": "甘肃省漳县"
            },
            {
                "code": "281107",
                "parentcode": "2811",
                "parenttext": "定西市",
                "text": "岷县",
                "level": 3,
                "order": 3045,
                "relation_code": "621126",
                "relation_desc": "甘肃省岷县"
            },
            {
                "code": "2812",
                "parentcode": "28",
                "parenttext": "甘肃省",
                "text": "陇南市",
                "level": 2,
                "order": 3046,
                "relation_code": "621200",
                "relation_desc": "甘肃省陇南市"
            },
            {
                "code": "281201",
                "parentcode": "2812",
                "parenttext": "陇南市",
                "text": "武都区",
                "level": 3,
                "order": 3047,
                "relation_code": "621202",
                "relation_desc": "甘肃省陇南市武都区"
            },
            {
                "code": "281202",
                "parentcode": "2812",
                "parenttext": "陇南市",
                "text": "成县",
                "level": 3,
                "order": 3048,
                "relation_code": "621221",
                "relation_desc": "甘肃省成县"
            },
            {
                "code": "281203",
                "parentcode": "2812",
                "parenttext": "陇南市",
                "text": "文县",
                "level": 3,
                "order": 3049,
                "relation_code": "621222",
                "relation_desc": "甘肃省文县"
            },
            {
                "code": "281204",
                "parentcode": "2812",
                "parenttext": "陇南市",
                "text": "宕昌县",
                "level": 3,
                "order": 3050,
                "relation_code": "621223",
                "relation_desc": "甘肃省宕昌县"
            },
            {
                "code": "281205",
                "parentcode": "2812",
                "parenttext": "陇南市",
                "text": "康县",
                "level": 3,
                "order": 3051,
                "relation_code": "621224",
                "relation_desc": "甘肃省康县"
            },
            {
                "code": "281206",
                "parentcode": "2812",
                "parenttext": "陇南市",
                "text": "西和县",
                "level": 3,
                "order": 3052,
                "relation_code": "621225",
                "relation_desc": "甘肃省西和县"
            },
            {
                "code": "281207",
                "parentcode": "2812",
                "parenttext": "陇南市",
                "text": "礼县",
                "level": 3,
                "order": 3053,
                "relation_code": "621226",
                "relation_desc": "甘肃省礼县"
            },
            {
                "code": "281208",
                "parentcode": "2812",
                "parenttext": "陇南市",
                "text": "徽县",
                "level": 3,
                "order": 3054,
                "relation_code": "621227",
                "relation_desc": "甘肃省徽县"
            },
            {
                "code": "281209",
                "parentcode": "2812",
                "parenttext": "陇南市",
                "text": "两当县",
                "level": 3,
                "order": 3055,
                "relation_code": "621228",
                "relation_desc": "甘肃省两当县"
            },
            {
                "code": "2813",
                "parentcode": "28",
                "parenttext": "甘肃省",
                "text": "临夏回族自治州",
                "level": 2,
                "order": 3056,
                "relation_code": "622900",
                "relation_desc": "甘肃省临夏回族自治州"
            },
            {
                "code": "281301",
                "parentcode": "2813",
                "parenttext": "临夏回族自治州",
                "text": "临夏市",
                "level": 3,
                "order": 3057,
                "relation_code": "622901",
                "relation_desc": "甘肃省临夏市"
            },
            {
                "code": "281302",
                "parentcode": "2813",
                "parenttext": "临夏回族自治州",
                "text": "临夏县",
                "level": 3,
                "order": 3058,
                "relation_code": "622921",
                "relation_desc": "甘肃省临夏县"
            },
            {
                "code": "281303",
                "parentcode": "2813",
                "parenttext": "临夏回族自治州",
                "text": "康乐县",
                "level": 3,
                "order": 3059,
                "relation_code": "622922",
                "relation_desc": "甘肃省康乐县"
            },
            {
                "code": "281304",
                "parentcode": "2813",
                "parenttext": "临夏回族自治州",
                "text": "永靖县",
                "level": 3,
                "order": 3060,
                "relation_code": "622923",
                "relation_desc": "甘肃省永靖县"
            },
            {
                "code": "281305",
                "parentcode": "2813",
                "parenttext": "临夏回族自治州",
                "text": "广河县",
                "level": 3,
                "order": 3061,
                "relation_code": "622924",
                "relation_desc": "甘肃省广河县"
            },
            {
                "code": "281306",
                "parentcode": "2813",
                "parenttext": "临夏回族自治州",
                "text": "和政县",
                "level": 3,
                "order": 3062,
                "relation_code": "622925",
                "relation_desc": "甘肃省和政县"
            },
            {
                "code": "281307",
                "parentcode": "2813",
                "parenttext": "临夏回族自治州",
                "text": "东乡族自治县",
                "level": 3,
                "order": 3063,
                "relation_code": "622926",
                "relation_desc": "甘肃省东乡族自治县"
            },
            {
                "code": "281308",
                "parentcode": "2813",
                "parenttext": "临夏回族自治州",
                "text": "积石山保安族东乡族撒拉族自治县",
                "level": 3,
                "order": 3064,
                "relation_code": "622927",
                "relation_desc": "甘肃省积石山保安族东乡族撒拉族自治县"
            },
            {
                "code": "2814",
                "parentcode": "28",
                "parenttext": "甘肃省",
                "text": "甘南藏族自治州",
                "level": 2,
                "order": 3065,
                "relation_code": "623000",
                "relation_desc": "甘肃省甘南藏族自治州"
            },
            {
                "code": "281401",
                "parentcode": "2814",
                "parenttext": "甘南藏族自治州",
                "text": "合作市",
                "level": 3,
                "order": 3066,
                "relation_code": "623001",
                "relation_desc": "甘肃省合作市"
            },
            {
                "code": "281402",
                "parentcode": "2814",
                "parenttext": "甘南藏族自治州",
                "text": "临潭县",
                "level": 3,
                "order": 3067,
                "relation_code": "623021",
                "relation_desc": "甘肃省临潭县"
            },
            {
                "code": "281403",
                "parentcode": "2814",
                "parenttext": "甘南藏族自治州",
                "text": "卓尼县",
                "level": 3,
                "order": 3068,
                "relation_code": "623022",
                "relation_desc": "甘肃省卓尼县"
            },
            {
                "code": "281404",
                "parentcode": "2814",
                "parenttext": "甘南藏族自治州",
                "text": "舟曲县",
                "level": 3,
                "order": 3069,
                "relation_code": "623023",
                "relation_desc": "甘肃省舟曲县"
            },
            {
                "code": "281405",
                "parentcode": "2814",
                "parenttext": "甘南藏族自治州",
                "text": "迭部县",
                "level": 3,
                "order": 3070,
                "relation_code": "623024",
                "relation_desc": "甘肃省迭部县"
            },
            {
                "code": "281406",
                "parentcode": "2814",
                "parenttext": "甘南藏族自治州",
                "text": "玛曲县",
                "level": 3,
                "order": 3071,
                "relation_code": "623025",
                "relation_desc": "甘肃省玛曲县"
            },
            {
                "code": "281407",
                "parentcode": "2814",
                "parenttext": "甘南藏族自治州",
                "text": "碌曲县",
                "level": 3,
                "order": 3072,
                "relation_code": "623026",
                "relation_desc": "甘肃省碌曲县"
            },
            {
                "code": "281408",
                "parentcode": "2814",
                "parenttext": "甘南藏族自治州",
                "text": "夏河县",
                "level": 3,
                "order": 3073,
                "relation_code": "623027",
                "relation_desc": "甘肃省夏河县"
            },
            {
                "code": "29",
                "parentcode": "0",
                "parenttext": "0",
                "text": "青海省",
                "level": 1,
                "order": 3074,
                "relation_code": "",
                "relation_desc": "青海省"
            },
            {
                "code": "2901",
                "parentcode": "29",
                "parenttext": "青海省",
                "text": "西宁市",
                "level": 2,
                "order": 3075,
                "relation_code": "630100",
                "relation_desc": "青海省西宁市"
            },
            {
                "code": "290101",
                "parentcode": "2901",
                "parenttext": "西宁市",
                "text": "城东区",
                "level": 3,
                "order": 3076,
                "relation_code": "630102",
                "relation_desc": "青海省西宁市城东区"
            },
            {
                "code": "290102",
                "parentcode": "2901",
                "parenttext": "西宁市",
                "text": "城中区",
                "level": 3,
                "order": 3077,
                "relation_code": "630103",
                "relation_desc": "青海省西宁市城中区"
            },
            {
                "code": "290103",
                "parentcode": "2901",
                "parenttext": "西宁市",
                "text": "城西区",
                "level": 3,
                "order": 3078,
                "relation_code": "630104",
                "relation_desc": "青海省西宁市城西区"
            },
            {
                "code": "290104",
                "parentcode": "2901",
                "parenttext": "西宁市",
                "text": "城北区",
                "level": 3,
                "order": 3079,
                "relation_code": "630105",
                "relation_desc": "青海省西宁市城北区"
            },
            {
                "code": "290105",
                "parentcode": "2901",
                "parenttext": "西宁市",
                "text": "大通回族土族自治县",
                "level": 3,
                "order": 3080,
                "relation_code": "630121",
                "relation_desc": "青海省大通回族土族自治县"
            },
            {
                "code": "290106",
                "parentcode": "2901",
                "parenttext": "西宁市",
                "text": "湟中县",
                "level": 3,
                "order": 3081,
                "relation_code": "630101",
                "relation_desc": "青海省湟中县"
            },
            {
                "code": "290107",
                "parentcode": "2901",
                "parenttext": "西宁市",
                "text": "湟源县",
                "level": 3,
                "order": 3082,
                "relation_code": "630123",
                "relation_desc": "青海省湟源县"
            },
            {
                "code": "2902",
                "parentcode": "29",
                "parenttext": "青海省",
                "text": "海东市",
                "level": 2,
                "order": 3083,
                "relation_code": "630200",
                "relation_desc": "青海省海东市"
            },
            {
                "code": "290201",
                "parentcode": "2902",
                "parenttext": "海东市",
                "text": "平安区",
                "level": 3,
                "order": 3084,
                "relation_code": "630203",
                "relation_desc": "青海省海东市平安区"
            },
            {
                "code": "290202",
                "parentcode": "2902",
                "parenttext": "海东市",
                "text": "民和回族土族自治县",
                "level": 3,
                "order": 3085,
                "relation_code": "630222",
                "relation_desc": "青海省民和回族土族自治县"
            },
            {
                "code": "290203",
                "parentcode": "2902",
                "parenttext": "海东市",
                "text": "乐都区",
                "level": 3,
                "order": 3086,
                "relation_code": "630202",
                "relation_desc": "青海省海东市乐都区"
            },
            {
                "code": "290204",
                "parentcode": "2902",
                "parenttext": "海东市",
                "text": "互助土族自治县",
                "level": 3,
                "order": 3087,
                "relation_code": "630223",
                "relation_desc": "青海省互助土族自治县"
            },
            {
                "code": "290205",
                "parentcode": "2902",
                "parenttext": "海东市",
                "text": "化隆回族自治县",
                "level": 3,
                "order": 3088,
                "relation_code": "630224",
                "relation_desc": "青海省化隆回族自治县"
            },
            {
                "code": "290206",
                "parentcode": "2902",
                "parenttext": "海东市",
                "text": "循化撒拉族自治县",
                "level": 3,
                "order": 3089,
                "relation_code": "630225",
                "relation_desc": "青海省循化撒拉族自治县"
            },
            {
                "code": "2903",
                "parentcode": "29",
                "parenttext": "青海省",
                "text": "海北藏族自治州",
                "level": 2,
                "order": 3090,
                "relation_code": "632200",
                "relation_desc": "青海省海北藏族自治州"
            },
            {
                "code": "290301",
                "parentcode": "2903",
                "parenttext": "海北藏族自治州",
                "text": "门源回族自治县",
                "level": 3,
                "order": 3091,
                "relation_code": "632221",
                "relation_desc": "青海省门源回族自治县"
            },
            {
                "code": "290302",
                "parentcode": "2903",
                "parenttext": "海北藏族自治州",
                "text": "祁连县",
                "level": 3,
                "order": 3092,
                "relation_code": "632222",
                "relation_desc": "青海省祁连县"
            },
            {
                "code": "290303",
                "parentcode": "2903",
                "parenttext": "海北藏族自治州",
                "text": "海晏县",
                "level": 3,
                "order": 3093,
                "relation_code": "632223",
                "relation_desc": "青海省海晏县"
            },
            {
                "code": "290304",
                "parentcode": "2903",
                "parenttext": "海北藏族自治州",
                "text": "刚察县",
                "level": 3,
                "order": 3094,
                "relation_code": "632224",
                "relation_desc": "青海省刚察县"
            },
            {
                "code": "2904",
                "parentcode": "29",
                "parenttext": "青海省",
                "text": "黄南藏族自治州",
                "level": 2,
                "order": 3095,
                "relation_code": "632300",
                "relation_desc": "青海省黄南藏族自治州"
            },
            {
                "code": "290401",
                "parentcode": "2904",
                "parenttext": "黄南藏族自治州",
                "text": "同仁县",
                "level": 3,
                "order": 3096,
                "relation_code": "632321",
                "relation_desc": "青海省同仁县"
            },
            {
                "code": "290402",
                "parentcode": "2904",
                "parenttext": "黄南藏族自治州",
                "text": "尖扎县",
                "level": 3,
                "order": 3097,
                "relation_code": "632322",
                "relation_desc": "青海省尖扎县"
            },
            {
                "code": "290403",
                "parentcode": "2904",
                "parenttext": "黄南藏族自治州",
                "text": "泽库县",
                "level": 3,
                "order": 3098,
                "relation_code": "632323",
                "relation_desc": "青海省泽库县"
            },
            {
                "code": "290404",
                "parentcode": "2904",
                "parenttext": "黄南藏族自治州",
                "text": "河南蒙古族自治县",
                "level": 3,
                "order": 3099,
                "relation_code": "632324",
                "relation_desc": "青海省河南蒙古族自治县"
            },
            {
                "code": "2905",
                "parentcode": "29",
                "parenttext": "青海省",
                "text": "海南藏族自治州",
                "level": 2,
                "order": 3100,
                "relation_code": "632500",
                "relation_desc": "青海省海南藏族自治州"
            },
            {
                "code": "290501",
                "parentcode": "2905",
                "parenttext": "海南藏族自治州",
                "text": "共和县",
                "level": 3,
                "order": 3101,
                "relation_code": "632521",
                "relation_desc": "青海省共和县"
            },
            {
                "code": "290502",
                "parentcode": "2905",
                "parenttext": "海南藏族自治州",
                "text": "同德县",
                "level": 3,
                "order": 3102,
                "relation_code": "632522",
                "relation_desc": "青海省同德县"
            },
            {
                "code": "290503",
                "parentcode": "2905",
                "parenttext": "海南藏族自治州",
                "text": "贵德县",
                "level": 3,
                "order": 3103,
                "relation_code": "632523",
                "relation_desc": "青海省贵德县"
            },
            {
                "code": "290504",
                "parentcode": "2905",
                "parenttext": "海南藏族自治州",
                "text": "兴海县",
                "level": 3,
                "order": 3104,
                "relation_code": "632524",
                "relation_desc": "青海省兴海县"
            },
            {
                "code": "290505",
                "parentcode": "2905",
                "parenttext": "海南藏族自治州",
                "text": "贵南县",
                "level": 3,
                "order": 3105,
                "relation_code": "632525",
                "relation_desc": "青海省贵南县"
            },
            {
                "code": "2906",
                "parentcode": "29",
                "parenttext": "青海省",
                "text": "果洛藏族自治州",
                "level": 2,
                "order": 3106,
                "relation_code": "632600",
                "relation_desc": "青海省果洛藏族自治州"
            },
            {
                "code": "290601",
                "parentcode": "2906",
                "parenttext": "果洛藏族自治州",
                "text": "玛沁县",
                "level": 3,
                "order": 3107,
                "relation_code": "632621",
                "relation_desc": "青海省玛沁县"
            },
            {
                "code": "290602",
                "parentcode": "2906",
                "parenttext": "果洛藏族自治州",
                "text": "班玛县",
                "level": 3,
                "order": 3108,
                "relation_code": "632622",
                "relation_desc": "青海省班玛县"
            },
            {
                "code": "290603",
                "parentcode": "2906",
                "parenttext": "果洛藏族自治州",
                "text": "甘德县",
                "level": 3,
                "order": 3109,
                "relation_code": "632623",
                "relation_desc": "青海省甘德县"
            },
            {
                "code": "290604",
                "parentcode": "2906",
                "parenttext": "果洛藏族自治州",
                "text": "达日县",
                "level": 3,
                "order": 3110,
                "relation_code": "632624",
                "relation_desc": "青海省达日县"
            },
            {
                "code": "290605",
                "parentcode": "2906",
                "parenttext": "果洛藏族自治州",
                "text": "久治县",
                "level": 3,
                "order": 3111,
                "relation_code": "632625",
                "relation_desc": "青海省久治县"
            },
            {
                "code": "290606",
                "parentcode": "2906",
                "parenttext": "果洛藏族自治州",
                "text": "玛多县",
                "level": 3,
                "order": 3112,
                "relation_code": "632626",
                "relation_desc": "青海省玛多县"
            },
            {
                "code": "2907",
                "parentcode": "29",
                "parenttext": "青海省",
                "text": "玉树藏族自治州",
                "level": 2,
                "order": 3113,
                "relation_code": "632700",
                "relation_desc": "青海省玉树藏族自治州"
            },
            {
                "code": "290701",
                "parentcode": "2907",
                "parenttext": "玉树藏族自治州",
                "text": "玉树市",
                "level": 3,
                "order": 3114,
                "relation_code": "632701",
                "relation_desc": "青海省玉树市"
            },
            {
                "code": "290702",
                "parentcode": "2907",
                "parenttext": "玉树藏族自治州",
                "text": "杂多县",
                "level": 3,
                "order": 3115,
                "relation_code": "632722",
                "relation_desc": "青海省杂多县"
            },
            {
                "code": "290703",
                "parentcode": "2907",
                "parenttext": "玉树藏族自治州",
                "text": "称多县",
                "level": 3,
                "order": 3116,
                "relation_code": "632723",
                "relation_desc": "青海省称多县"
            },
            {
                "code": "290704",
                "parentcode": "2907",
                "parenttext": "玉树藏族自治州",
                "text": "治多县",
                "level": 3,
                "order": 3117,
                "relation_code": "632724",
                "relation_desc": "青海省治多县"
            },
            {
                "code": "290705",
                "parentcode": "2907",
                "parenttext": "玉树藏族自治州",
                "text": "囊谦县",
                "level": 3,
                "order": 3118,
                "relation_code": "632725",
                "relation_desc": "青海省囊谦县"
            },
            {
                "code": "290706",
                "parentcode": "2907",
                "parenttext": "玉树藏族自治州",
                "text": "曲麻莱县",
                "level": 3,
                "order": 3119,
                "relation_code": "632726",
                "relation_desc": "青海省曲麻莱县"
            },
            {
                "code": "2908",
                "parentcode": "29",
                "parenttext": "青海省",
                "text": "海西蒙古族藏族自治州",
                "level": 2,
                "order": 3120,
                "relation_code": "632800",
                "relation_desc": "青海省海西蒙古族藏族自治州"
            },
            {
                "code": "290801",
                "parentcode": "2908",
                "parenttext": "海西蒙古族藏族自治州",
                "text": "格尔木市",
                "level": 3,
                "order": 3121,
                "relation_code": "632801",
                "relation_desc": "青海省格尔木市"
            },
            {
                "code": "290802",
                "parentcode": "2908",
                "parenttext": "海西蒙古族藏族自治州",
                "text": "德令哈市",
                "level": 3,
                "order": 3122,
                "relation_code": "632802",
                "relation_desc": "青海省德令哈市"
            },
            {
                "code": "290803",
                "parentcode": "2908",
                "parenttext": "海西蒙古族藏族自治州",
                "text": "乌兰县",
                "level": 3,
                "order": 3123,
                "relation_code": "632821",
                "relation_desc": "青海省乌兰县"
            },
            {
                "code": "290804",
                "parentcode": "2908",
                "parenttext": "海西蒙古族藏族自治州",
                "text": "都兰县",
                "level": 3,
                "order": 3124,
                "relation_code": "632822",
                "relation_desc": "青海省都兰县"
            },
            {
                "code": "290805",
                "parentcode": "2908",
                "parenttext": "海西蒙古族藏族自治州",
                "text": "天峻县",
                "level": 3,
                "order": 3125,
                "relation_code": "632823",
                "relation_desc": "青海省天峻县"
            },
            {
                "code": "30",
                "parentcode": "0",
                "parenttext": "0",
                "text": "宁夏回族自治区",
                "level": 1,
                "order": 3126,
                "relation_code": "",
                "relation_desc": "宁夏回族自治区"
            },
            {
                "code": "3001",
                "parentcode": "30",
                "parenttext": "宁夏回族自治区",
                "text": "银川市",
                "level": 2,
                "order": 3127,
                "relation_code": "640100",
                "relation_desc": "宁夏银川市"
            },
            {
                "code": "300101",
                "parentcode": "3001",
                "parenttext": "银川市",
                "text": "兴庆区",
                "level": 3,
                "order": 3128,
                "relation_code": "640104",
                "relation_desc": "宁夏银川市兴庆区"
            },
            {
                "code": "300102",
                "parentcode": "3001",
                "parenttext": "银川市",
                "text": "西夏区",
                "level": 3,
                "order": 3129,
                "relation_code": "640105",
                "relation_desc": "宁夏银川市西夏区"
            },
            {
                "code": "300103",
                "parentcode": "3001",
                "parenttext": "银川市",
                "text": "金凤区",
                "level": 3,
                "order": 3130,
                "relation_code": "640106",
                "relation_desc": "宁夏银川市金凤区"
            },
            {
                "code": "300104",
                "parentcode": "3001",
                "parenttext": "银川市",
                "text": "永宁县",
                "level": 3,
                "order": 3131,
                "relation_code": "640121",
                "relation_desc": "宁夏永宁县"
            },
            {
                "code": "300105",
                "parentcode": "3001",
                "parenttext": "银川市",
                "text": "贺兰县",
                "level": 3,
                "order": 3132,
                "relation_code": "640122",
                "relation_desc": "宁夏贺兰县"
            },
            {
                "code": "300106",
                "parentcode": "3001",
                "parenttext": "银川市",
                "text": "灵武市",
                "level": 3,
                "order": 3133,
                "relation_code": "640181",
                "relation_desc": "宁夏灵武市"
            },
            {
                "code": "3002",
                "parentcode": "30",
                "parenttext": "宁夏回族自治区",
                "text": "石嘴山市",
                "level": 2,
                "order": 3134,
                "relation_code": "640200",
                "relation_desc": "宁夏石嘴山市"
            },
            {
                "code": "300201",
                "parentcode": "3002",
                "parenttext": "石嘴山市",
                "text": "大武口区",
                "level": 3,
                "order": 3135,
                "relation_code": "640202",
                "relation_desc": "宁夏石嘴山市大武口区"
            },
            {
                "code": "300202",
                "parentcode": "3002",
                "parenttext": "石嘴山市",
                "text": "惠农区",
                "level": 3,
                "order": 3136,
                "relation_code": "640205",
                "relation_desc": "宁夏石嘴山市惠农区"
            },
            {
                "code": "300203",
                "parentcode": "3002",
                "parenttext": "石嘴山市",
                "text": "平罗县",
                "level": 3,
                "order": 3137,
                "relation_code": "640221",
                "relation_desc": "宁夏平罗县"
            },
            {
                "code": "3003",
                "parentcode": "30",
                "parenttext": "宁夏回族自治区",
                "text": "吴忠市",
                "level": 2,
                "order": 3138,
                "relation_code": "640300",
                "relation_desc": "宁夏吴忠市"
            },
            {
                "code": "300301",
                "parentcode": "3003",
                "parenttext": "吴忠市",
                "text": "利通区",
                "level": 3,
                "order": 3139,
                "relation_code": "640302",
                "relation_desc": "宁夏吴忠市利通区"
            },
            {
                "code": "300302",
                "parentcode": "3003",
                "parenttext": "吴忠市",
                "text": "盐池县",
                "level": 3,
                "order": 3140,
                "relation_code": "640323",
                "relation_desc": "宁夏盐池县"
            },
            {
                "code": "300303",
                "parentcode": "3003",
                "parenttext": "吴忠市",
                "text": "同心县",
                "level": 3,
                "order": 3141,
                "relation_code": "640324",
                "relation_desc": "宁夏同心县"
            },
            {
                "code": "300304",
                "parentcode": "3003",
                "parenttext": "吴忠市",
                "text": "青铜峡市",
                "level": 3,
                "order": 3142,
                "relation_code": "640381",
                "relation_desc": "宁夏青铜峡市"
            },
            {
                "code": "3004",
                "parentcode": "30",
                "parenttext": "宁夏回族自治区",
                "text": "固原市",
                "level": 2,
                "order": 3143,
                "relation_code": "640400",
                "relation_desc": "宁夏固原市"
            },
            {
                "code": "300401",
                "parentcode": "3004",
                "parenttext": "固原市",
                "text": "原州区",
                "level": 3,
                "order": 3144,
                "relation_code": "640402",
                "relation_desc": "宁夏固原市原州区"
            },
            {
                "code": "300402",
                "parentcode": "3004",
                "parenttext": "固原市",
                "text": "西吉县",
                "level": 3,
                "order": 3145,
                "relation_code": "640422",
                "relation_desc": "宁夏西吉县"
            },
            {
                "code": "300403",
                "parentcode": "3004",
                "parenttext": "固原市",
                "text": "隆德县",
                "level": 3,
                "order": 3146,
                "relation_code": "640423",
                "relation_desc": "宁夏隆德县"
            },
            {
                "code": "300404",
                "parentcode": "3004",
                "parenttext": "固原市",
                "text": "泾源县",
                "level": 3,
                "order": 3147,
                "relation_code": "640424",
                "relation_desc": "宁夏泾源县"
            },
            {
                "code": "300405",
                "parentcode": "3004",
                "parenttext": "固原市",
                "text": "彭阳县",
                "level": 3,
                "order": 3148,
                "relation_code": "640425",
                "relation_desc": "宁夏彭阳县"
            },
            {
                "code": "3005",
                "parentcode": "30",
                "parenttext": "宁夏回族自治区",
                "text": "中卫市",
                "level": 2,
                "order": 3149,
                "relation_code": "640500",
                "relation_desc": "宁夏中卫市"
            },
            {
                "code": "300501",
                "parentcode": "3005",
                "parenttext": "中卫市",
                "text": "沙坡头区",
                "level": 3,
                "order": 3150,
                "relation_code": "640502",
                "relation_desc": "宁夏中卫市沙坡头区"
            },
            {
                "code": "300502",
                "parentcode": "3005",
                "parenttext": "中卫市",
                "text": "中宁县",
                "level": 3,
                "order": 3151,
                "relation_code": "640521",
                "relation_desc": "宁夏中宁县"
            },
            {
                "code": "300503",
                "parentcode": "3005",
                "parenttext": "中卫市",
                "text": "海原县",
                "level": 3,
                "order": 3152,
                "relation_code": "640522",
                "relation_desc": "宁夏海原县"
            },
            {
                "code": "31",
                "parentcode": "0",
                "parenttext": "0",
                "text": "新疆维吾尔自治区",
                "level": 1,
                "order": 3153,
                "relation_code": "",
                "relation_desc": "新疆维吾尔自治区"
            },
            {
                "code": "3101",
                "parentcode": "31",
                "parenttext": "新疆维吾尔自治区",
                "text": "乌鲁木齐市",
                "level": 2,
                "order": 3154,
                "relation_code": "650100",
                "relation_desc": "新疆乌鲁木齐市"
            },
            {
                "code": "310101",
                "parentcode": "3101",
                "parenttext": "乌鲁木齐市",
                "text": "天山区",
                "level": 3,
                "order": 3155,
                "relation_code": "650102",
                "relation_desc": "新疆乌鲁木齐市天山区"
            },
            {
                "code": "310102",
                "parentcode": "3101",
                "parenttext": "乌鲁木齐市",
                "text": "沙依巴克区",
                "level": 3,
                "order": 3156,
                "relation_code": "650103",
                "relation_desc": "新疆乌鲁木齐市沙依巴克区"
            },
            {
                "code": "310103",
                "parentcode": "3101",
                "parenttext": "乌鲁木齐市",
                "text": "新市区",
                "level": 3,
                "order": 3157,
                "relation_code": "650104",
                "relation_desc": "新疆乌鲁木齐市新市区"
            },
            {
                "code": "310104",
                "parentcode": "3101",
                "parenttext": "乌鲁木齐市",
                "text": "水磨沟区",
                "level": 3,
                "order": 3158,
                "relation_code": "650105",
                "relation_desc": "新疆乌鲁木齐市水磨沟区"
            },
            {
                "code": "310105",
                "parentcode": "3101",
                "parenttext": "乌鲁木齐市",
                "text": "头屯河区",
                "level": 3,
                "order": 3159,
                "relation_code": "650106",
                "relation_desc": "新疆乌鲁木齐市头屯河区"
            },
            {
                "code": "310106",
                "parentcode": "3101",
                "parenttext": "乌鲁木齐市",
                "text": "达坂城区",
                "level": 3,
                "order": 3160,
                "relation_code": "650107",
                "relation_desc": "新疆乌鲁木齐市达坂城区"
            },
            {
                "code": "310107",
                "parentcode": "3101",
                "parenttext": "乌鲁木齐市",
                "text": "米东区",
                "level": 3,
                "order": 3161,
                "relation_code": "650109",
                "relation_desc": "新疆乌鲁木齐市米东区"
            },
            {
                "code": "310108",
                "parentcode": "3101",
                "parenttext": "乌鲁木齐市",
                "text": "乌鲁木齐县",
                "level": 3,
                "order": 3162,
                "relation_code": "650121",
                "relation_desc": "新疆乌鲁木齐县"
            },
            {
                "code": "3102",
                "parentcode": "31",
                "parenttext": "新疆维吾尔自治区",
                "text": "克拉玛依市",
                "level": 2,
                "order": 3163,
                "relation_code": "650200",
                "relation_desc": "新疆克拉玛依市"
            },
            {
                "code": "310201",
                "parentcode": "3102",
                "parenttext": "克拉玛依市",
                "text": "独山子区",
                "level": 3,
                "order": 3164,
                "relation_code": "650202",
                "relation_desc": "新疆克拉玛依市独山子区"
            },
            {
                "code": "310202",
                "parentcode": "3102",
                "parenttext": "克拉玛依市",
                "text": "克拉玛依区",
                "level": 3,
                "order": 3165,
                "relation_code": "650203",
                "relation_desc": "新疆克拉玛依市克拉玛依区"
            },
            {
                "code": "310203",
                "parentcode": "3102",
                "parenttext": "克拉玛依市",
                "text": "白碱滩区",
                "level": 3,
                "order": 3166,
                "relation_code": "650204",
                "relation_desc": "新疆克拉玛依市白碱滩区"
            },
            {
                "code": "310204",
                "parentcode": "3102",
                "parenttext": "克拉玛依市",
                "text": "乌尔禾区",
                "level": 3,
                "order": 3167,
                "relation_code": "650205",
                "relation_desc": "新疆克拉玛依市乌尔禾区"
            },
            {
                "code": "3103",
                "parentcode": "31",
                "parenttext": "新疆维吾尔自治区",
                "text": "吐鲁番市",
                "level": 2,
                "order": 3168,
                "relation_code": "",
                "relation_desc": "新疆吐鲁番地区"
            },
            {
                "code": "310301",
                "parentcode": "3103",
                "parenttext": "吐鲁番市",
                "text": "高昌区",
                "level": 3,
                "order": 3169,
                "relation_code": "650400",
                "relation_desc": "新疆吐鲁番市"
            },
            {
                "code": "310302",
                "parentcode": "3103",
                "parenttext": "吐鲁番市",
                "text": "鄯善县",
                "level": 3,
                "order": 3170,
                "relation_code": "650421",
                "relation_desc": "新疆鄯善县"
            },
            {
                "code": "310303",
                "parentcode": "3103",
                "parenttext": "吐鲁番市",
                "text": "托克逊县",
                "level": 3,
                "order": 3171,
                "relation_code": "650422",
                "relation_desc": "新疆托克逊县"
            },
            {
                "code": "3104",
                "parentcode": "31",
                "parenttext": "新疆维吾尔自治区",
                "text": "哈密市",
                "level": 2,
                "order": 3172,
                "relation_code": "",
                "relation_desc": "新疆哈密地区"
            },
            {
                "code": "310401",
                "parentcode": "3104",
                "parenttext": "哈密市",
                "text": "伊州区",
                "level": 3,
                "order": 3173,
                "relation_code": "650500",
                "relation_desc": "新疆哈密市"
            },
            {
                "code": "310402",
                "parentcode": "3104",
                "parenttext": "哈密市",
                "text": "巴里坤哈萨克自治县",
                "level": 3,
                "order": 3174,
                "relation_code": "650521",
                "relation_desc": "新疆巴里坤哈萨克自治县"
            },
            {
                "code": "310403",
                "parentcode": "3104",
                "parenttext": "哈密市",
                "text": "伊吾县",
                "level": 3,
                "order": 3175,
                "relation_code": "650522",
                "relation_desc": "新疆伊吾县"
            },
            {
                "code": "3105",
                "parentcode": "31",
                "parenttext": "新疆维吾尔自治区",
                "text": "昌吉回族自治州",
                "level": 2,
                "order": 3176,
                "relation_code": "652300",
                "relation_desc": "新疆昌吉回族自治州"
            },
            {
                "code": "310501",
                "parentcode": "3105",
                "parenttext": "昌吉回族自治州",
                "text": "昌吉市",
                "level": 3,
                "order": 3177,
                "relation_code": "652301",
                "relation_desc": "新疆昌吉市"
            },
            {
                "code": "310502",
                "parentcode": "3105",
                "parenttext": "昌吉回族自治州",
                "text": "阜康市",
                "level": 3,
                "order": 3178,
                "relation_code": "652302",
                "relation_desc": "新疆阜康市"
            },
            {
                "code": "310503",
                "parentcode": "3105",
                "parenttext": "昌吉回族自治州",
                "text": "米泉市",
                "level": 3,
                "order": 3179,
                "relation_code": "652301",
                "relation_desc": "新疆米泉市"
            },
            {
                "code": "310504",
                "parentcode": "3105",
                "parenttext": "昌吉回族自治州",
                "text": "呼图壁县",
                "level": 3,
                "order": 3180,
                "relation_code": "652323",
                "relation_desc": "新疆呼图壁县"
            },
            {
                "code": "310505",
                "parentcode": "3105",
                "parenttext": "昌吉回族自治州",
                "text": "玛纳斯县",
                "level": 3,
                "order": 3181,
                "relation_code": "652324",
                "relation_desc": "新疆玛纳斯县"
            },
            {
                "code": "310506",
                "parentcode": "3105",
                "parenttext": "昌吉回族自治州",
                "text": "奇台县",
                "level": 3,
                "order": 3182,
                "relation_code": "652325",
                "relation_desc": "新疆奇台县"
            },
            {
                "code": "310507",
                "parentcode": "3105",
                "parenttext": "昌吉回族自治州",
                "text": "吉木萨尔县",
                "level": 3,
                "order": 3183,
                "relation_code": "652327",
                "relation_desc": "新疆吉木萨尔县"
            },
            {
                "code": "310508",
                "parentcode": "3105",
                "parenttext": "昌吉回族自治州",
                "text": "木垒哈萨克自治县",
                "level": 3,
                "order": 3184,
                "relation_code": "652328",
                "relation_desc": "新疆木垒哈萨克自治县"
            },
            {
                "code": "3106",
                "parentcode": "31",
                "parenttext": "新疆维吾尔自治区",
                "text": "博尔塔拉蒙古自治州",
                "level": 2,
                "order": 3185,
                "relation_code": "652700",
                "relation_desc": "新疆博尔塔拉蒙古自治州"
            },
            {
                "code": "310601",
                "parentcode": "3106",
                "parenttext": "博尔塔拉蒙古自治州",
                "text": "博乐市",
                "level": 3,
                "order": 3186,
                "relation_code": "652701",
                "relation_desc": "新疆博乐市"
            },
            {
                "code": "310602",
                "parentcode": "3106",
                "parenttext": "博尔塔拉蒙古自治州",
                "text": "精河县",
                "level": 3,
                "order": 3187,
                "relation_code": "652722",
                "relation_desc": "新疆精河县"
            },
            {
                "code": "310603",
                "parentcode": "3106",
                "parenttext": "博尔塔拉蒙古自治州",
                "text": "温泉县",
                "level": 3,
                "order": 3188,
                "relation_code": "652723",
                "relation_desc": "新疆温泉县"
            },
            {
                "code": "3107",
                "parentcode": "31",
                "parenttext": "新疆维吾尔自治区",
                "text": "巴音郭楞蒙古自治州",
                "level": 2,
                "order": 3189,
                "relation_code": "652800",
                "relation_desc": "新疆巴音郭楞蒙古自治州"
            },
            {
                "code": "310701",
                "parentcode": "3107",
                "parenttext": "巴音郭楞蒙古自治州",
                "text": "库尔勒市",
                "level": 3,
                "order": 3190,
                "relation_code": "652801",
                "relation_desc": "新疆库尔勒市"
            },
            {
                "code": "310702",
                "parentcode": "3107",
                "parenttext": "巴音郭楞蒙古自治州",
                "text": "轮台县",
                "level": 3,
                "order": 3191,
                "relation_code": "652822",
                "relation_desc": "新疆轮台县"
            },
            {
                "code": "310703",
                "parentcode": "3107",
                "parenttext": "巴音郭楞蒙古自治州",
                "text": "尉犁县",
                "level": 3,
                "order": 3192,
                "relation_code": "652823",
                "relation_desc": "新疆尉犁县"
            },
            {
                "code": "310704",
                "parentcode": "3107",
                "parenttext": "巴音郭楞蒙古自治州",
                "text": "若羌县",
                "level": 3,
                "order": 3193,
                "relation_code": "652824",
                "relation_desc": "新疆若羌县"
            },
            {
                "code": "310705",
                "parentcode": "3107",
                "parenttext": "巴音郭楞蒙古自治州",
                "text": "且末县",
                "level": 3,
                "order": 3194,
                "relation_code": "652825",
                "relation_desc": "新疆且末县"
            },
            {
                "code": "310706",
                "parentcode": "3107",
                "parenttext": "巴音郭楞蒙古自治州",
                "text": "焉耆回族自治县",
                "level": 3,
                "order": 3195,
                "relation_code": "652826",
                "relation_desc": "新疆焉耆回族自治县"
            },
            {
                "code": "310707",
                "parentcode": "3107",
                "parenttext": "巴音郭楞蒙古自治州",
                "text": "和静县",
                "level": 3,
                "order": 3196,
                "relation_code": "652827",
                "relation_desc": "新疆和静县"
            },
            {
                "code": "310708",
                "parentcode": "3107",
                "parenttext": "巴音郭楞蒙古自治州",
                "text": "和硕县",
                "level": 3,
                "order": 3197,
                "relation_code": "652828",
                "relation_desc": "新疆和硕县"
            },
            {
                "code": "310709",
                "parentcode": "3107",
                "parenttext": "巴音郭楞蒙古自治州",
                "text": "博湖县",
                "level": 3,
                "order": 3198,
                "relation_code": "652829",
                "relation_desc": "新疆博湖县"
            },
            {
                "code": "3108",
                "parentcode": "31",
                "parenttext": "新疆维吾尔自治区",
                "text": "阿克苏地区",
                "level": 2,
                "order": 3199,
                "relation_code": "652900",
                "relation_desc": "新疆阿克苏地区"
            },
            {
                "code": "310710",
                "parentcode": "3107",
                "parenttext": "巴音郭楞蒙古自治州",
                "text": "库尔勒经济技术开发区",
                "level": 3,
                "order": 3199,
                "relation_code": "652801",
                "relation_desc": "新疆巴音郭楞蒙古自治州"
            },
            {
                "code": "310801",
                "parentcode": "3108",
                "parenttext": "阿克苏地区",
                "text": "阿克苏市",
                "level": 3,
                "order": 3200,
                "relation_code": "652901",
                "relation_desc": "新疆阿克苏市"
            },
            {
                "code": "310802",
                "parentcode": "3108",
                "parenttext": "阿克苏地区",
                "text": "温宿县",
                "level": 3,
                "order": 3201,
                "relation_code": "652922",
                "relation_desc": "新疆温宿县"
            },
            {
                "code": "310803",
                "parentcode": "3108",
                "parenttext": "阿克苏地区",
                "text": "库车市",
                "level": 3,
                "order": 3202,
                "relation_code": "652902",
                "relation_desc": "新疆库车市"
            },
            {
                "code": "3201",
                "parentcode": "32",
                "parenttext": "台湾省",
                "text": "台湾",
                "level": 2,
                "order": 3202,
                "relation_code": "",
                "relation_desc": ""
            },
            {
                "code": "310804",
                "parentcode": "3108",
                "parenttext": "阿克苏地区",
                "text": "沙雅县",
                "level": 3,
                "order": 3203,
                "relation_code": "652924",
                "relation_desc": "新疆沙雅县"
            },
            {
                "code": "320101",
                "parentcode": "3201",
                "parenttext": "台湾",
                "text": "台北市",
                "level": 3,
                "order": 3203,
                "relation_code": "710000",
                "relation_desc": "台湾省"
            },
            {
                "code": "310805",
                "parentcode": "3108",
                "parenttext": "阿克苏地区",
                "text": "新和县",
                "level": 3,
                "order": 3204,
                "relation_code": "652925",
                "relation_desc": "新疆新和县"
            },
            {
                "code": "320102",
                "parentcode": "3201",
                "parenttext": "台湾",
                "text": "新北市",
                "level": 3,
                "order": 3204,
                "relation_code": "710000",
                "relation_desc": "台湾省"
            },
            {
                "code": "310806",
                "parentcode": "3108",
                "parenttext": "阿克苏地区",
                "text": "拜城县",
                "level": 3,
                "order": 3205,
                "relation_code": "652926",
                "relation_desc": "新疆拜城县"
            },
            {
                "code": "320103",
                "parentcode": "3201",
                "parenttext": "台湾",
                "text": "桃园市",
                "level": 3,
                "order": 3205,
                "relation_code": "710000",
                "relation_desc": "台湾省"
            },
            {
                "code": "310807",
                "parentcode": "3108",
                "parenttext": "阿克苏地区",
                "text": "乌什县",
                "level": 3,
                "order": 3206,
                "relation_code": "652927",
                "relation_desc": "新疆乌什县"
            },
            {
                "code": "320104",
                "parentcode": "3201",
                "parenttext": "台湾",
                "text": "台中市",
                "level": 3,
                "order": 3206,
                "relation_code": "710000",
                "relation_desc": "台湾省"
            },
            {
                "code": "310808",
                "parentcode": "3108",
                "parenttext": "阿克苏地区",
                "text": "阿瓦提县",
                "level": 3,
                "order": 3207,
                "relation_code": "652928",
                "relation_desc": "新疆阿瓦提县"
            },
            {
                "code": "320105",
                "parentcode": "3201",
                "parenttext": "台湾",
                "text": "台南市",
                "level": 3,
                "order": 3207,
                "relation_code": "710000",
                "relation_desc": "台湾省"
            },
            {
                "code": "310809",
                "parentcode": "3108",
                "parenttext": "阿克苏地区",
                "text": "柯坪县",
                "level": 3,
                "order": 3208,
                "relation_code": "652929",
                "relation_desc": "新疆柯坪县"
            },
            {
                "code": "320106",
                "parentcode": "3201",
                "parenttext": "台湾",
                "text": "高雄市",
                "level": 3,
                "order": 3208,
                "relation_code": "710000",
                "relation_desc": "台湾省"
            },
            {
                "code": "3109",
                "parentcode": "31",
                "parenttext": "新疆维吾尔自治区",
                "text": "克孜勒苏柯尔克孜自治州",
                "level": 2,
                "order": 3209,
                "relation_code": "653000",
                "relation_desc": "新疆克孜勒苏柯尔克孜自治州"
            },
            {
                "code": "320107",
                "parentcode": "3201",
                "parenttext": "台湾",
                "text": "基隆市",
                "level": 3,
                "order": 3209,
                "relation_code": "710000",
                "relation_desc": "台湾省"
            },
            {
                "code": "310901",
                "parentcode": "3109",
                "parenttext": "克孜勒苏柯尔克孜自治州",
                "text": "阿图什市",
                "level": 3,
                "order": 3210,
                "relation_code": "653001",
                "relation_desc": "新疆阿图什市"
            },
            {
                "code": "320108",
                "parentcode": "3201",
                "parenttext": "台湾",
                "text": "新竹市",
                "level": 3,
                "order": 3210,
                "relation_code": "710000",
                "relation_desc": "台湾省"
            },
            {
                "code": "310902",
                "parentcode": "3109",
                "parenttext": "克孜勒苏柯尔克孜自治州",
                "text": "阿克陶县",
                "level": 3,
                "order": 3211,
                "relation_code": "653022",
                "relation_desc": "新疆阿克陶县"
            },
            {
                "code": "320109",
                "parentcode": "3201",
                "parenttext": "台湾",
                "text": "嘉义市",
                "level": 3,
                "order": 3211,
                "relation_code": "710000",
                "relation_desc": "台湾省"
            },
            {
                "code": "310903",
                "parentcode": "3109",
                "parenttext": "克孜勒苏柯尔克孜自治州",
                "text": "阿合奇县",
                "level": 3,
                "order": 3212,
                "relation_code": "653023",
                "relation_desc": "新疆阿合奇县"
            },
            {
                "code": "320110",
                "parentcode": "3201",
                "parenttext": "台湾",
                "text": "新竹县",
                "level": 3,
                "order": 3212,
                "relation_code": "710000",
                "relation_desc": "台湾省"
            },
            {
                "code": "310904",
                "parentcode": "3109",
                "parenttext": "克孜勒苏柯尔克孜自治州",
                "text": "乌恰县",
                "level": 3,
                "order": 3213,
                "relation_code": "653024",
                "relation_desc": "新疆乌恰县"
            },
            {
                "code": "320111",
                "parentcode": "3201",
                "parenttext": "台湾",
                "text": "苗栗县",
                "level": 3,
                "order": 3213,
                "relation_code": "710000",
                "relation_desc": "台湾省"
            },
            {
                "code": "3110",
                "parentcode": "31",
                "parenttext": "新疆维吾尔自治区",
                "text": "喀什地区",
                "level": 2,
                "order": 3214,
                "relation_code": "653100",
                "relation_desc": "新疆喀什地区"
            },
            {
                "code": "320112",
                "parentcode": "3201",
                "parenttext": "台湾",
                "text": "彰化县",
                "level": 3,
                "order": 3214,
                "relation_code": "710000",
                "relation_desc": "台湾省"
            },
            {
                "code": "311001",
                "parentcode": "3110",
                "parenttext": "喀什地区",
                "text": "喀什市",
                "level": 3,
                "order": 3215,
                "relation_code": "653101",
                "relation_desc": "新疆喀什市"
            },
            {
                "code": "320113",
                "parentcode": "3201",
                "parenttext": "台湾",
                "text": "南投县",
                "level": 3,
                "order": 3215,
                "relation_code": "710000",
                "relation_desc": "台湾省"
            },
            {
                "code": "311002",
                "parentcode": "3110",
                "parenttext": "喀什地区",
                "text": "疏附县",
                "level": 3,
                "order": 3216,
                "relation_code": "653121",
                "relation_desc": "新疆疏附县"
            },
            {
                "code": "320114",
                "parentcode": "3201",
                "parenttext": "台湾",
                "text": "云林县",
                "level": 3,
                "order": 3216,
                "relation_code": "710000",
                "relation_desc": "台湾省"
            },
            {
                "code": "311003",
                "parentcode": "3110",
                "parenttext": "喀什地区",
                "text": "疏勒县",
                "level": 3,
                "order": 3217,
                "relation_code": "653122",
                "relation_desc": "新疆疏勒县"
            },
            {
                "code": "320115",
                "parentcode": "3201",
                "parenttext": "台湾",
                "text": "嘉义县",
                "level": 3,
                "order": 3217,
                "relation_code": "710000",
                "relation_desc": "台湾省"
            },
            {
                "code": "311004",
                "parentcode": "3110",
                "parenttext": "喀什地区",
                "text": "英吉沙县",
                "level": 3,
                "order": 3218,
                "relation_code": "653123",
                "relation_desc": "新疆英吉沙县"
            },
            {
                "code": "320116",
                "parentcode": "3201",
                "parenttext": "台湾",
                "text": "屏东县",
                "level": 3,
                "order": 3218,
                "relation_code": "710000",
                "relation_desc": "台湾省"
            },
            {
                "code": "311005",
                "parentcode": "3110",
                "parenttext": "喀什地区",
                "text": "泽普县",
                "level": 3,
                "order": 3219,
                "relation_code": "653124",
                "relation_desc": "新疆泽普县"
            },
            {
                "code": "320117",
                "parentcode": "3201",
                "parenttext": "台湾",
                "text": "宜兰县",
                "level": 3,
                "order": 3219,
                "relation_code": "710000",
                "relation_desc": "台湾省"
            },
            {
                "code": "311006",
                "parentcode": "3110",
                "parenttext": "喀什地区",
                "text": "莎车县",
                "level": 3,
                "order": 3220,
                "relation_code": "653125",
                "relation_desc": "新疆莎车县"
            },
            {
                "code": "320118",
                "parentcode": "3201",
                "parenttext": "台湾",
                "text": "花莲县",
                "level": 3,
                "order": 3220,
                "relation_code": "710000",
                "relation_desc": "台湾省"
            },
            {
                "code": "311007",
                "parentcode": "3110",
                "parenttext": "喀什地区",
                "text": "叶城县",
                "level": 3,
                "order": 3221,
                "relation_code": "653126",
                "relation_desc": "新疆叶城县"
            },
            {
                "code": "320119",
                "parentcode": "3201",
                "parenttext": "台湾",
                "text": "台东县",
                "level": 3,
                "order": 3221,
                "relation_code": "710000",
                "relation_desc": "台湾省"
            },
            {
                "code": "311008",
                "parentcode": "3110",
                "parenttext": "喀什地区",
                "text": "麦盖提县",
                "level": 3,
                "order": 3222,
                "relation_code": "653127",
                "relation_desc": "新疆麦盖提县"
            },
            {
                "code": "320120",
                "parentcode": "3201",
                "parenttext": "台湾",
                "text": "澎湖县",
                "level": 3,
                "order": 3222,
                "relation_code": "710000",
                "relation_desc": "台湾省"
            },
            {
                "code": "311009",
                "parentcode": "3110",
                "parenttext": "喀什地区",
                "text": "岳普湖县",
                "level": 3,
                "order": 3223,
                "relation_code": "653128",
                "relation_desc": "新疆岳普湖县"
            },
            {
                "code": "320121",
                "parentcode": "3201",
                "parenttext": "台湾",
                "text": "金门县",
                "level": 3,
                "order": 3223,
                "relation_code": "710000",
                "relation_desc": "台湾省"
            },
            {
                "code": "311010",
                "parentcode": "3110",
                "parenttext": "喀什地区",
                "text": "伽师县",
                "level": 3,
                "order": 3224,
                "relation_code": "653129",
                "relation_desc": "新疆伽师县"
            },
            {
                "code": "320122",
                "parentcode": "3201",
                "parenttext": "台湾",
                "text": "连江县",
                "level": 3,
                "order": 3224,
                "relation_code": "710000",
                "relation_desc": "台湾省"
            },
            {
                "code": "311011",
                "parentcode": "3110",
                "parenttext": "喀什地区",
                "text": "巴楚县",
                "level": 3,
                "order": 3225,
                "relation_code": "653130",
                "relation_desc": "新疆巴楚县"
            },
            {
                "code": "311012",
                "parentcode": "3110",
                "parenttext": "喀什地区",
                "text": "塔什库尔干塔吉克自治县",
                "level": 3,
                "order": 3226,
                "relation_code": "653131",
                "relation_desc": "新疆塔什库尔干塔吉克自治县"
            },
            {
                "code": "3111",
                "parentcode": "31",
                "parenttext": "新疆维吾尔自治区",
                "text": "和田地区",
                "level": 2,
                "order": 3227,
                "relation_code": "653200",
                "relation_desc": "新疆和田地区"
            },
            {
                "code": "311101",
                "parentcode": "3111",
                "parenttext": "和田地区",
                "text": "和田市",
                "level": 3,
                "order": 3228,
                "relation_code": "653201",
                "relation_desc": "新疆和田市"
            },
            {
                "code": "311102",
                "parentcode": "3111",
                "parenttext": "和田地区",
                "text": "和田县",
                "level": 3,
                "order": 3229,
                "relation_code": "653221",
                "relation_desc": "新疆和田县"
            },
            {
                "code": "311103",
                "parentcode": "3111",
                "parenttext": "和田地区",
                "text": "墨玉县",
                "level": 3,
                "order": 3230,
                "relation_code": "653222",
                "relation_desc": "新疆墨玉县"
            },
            {
                "code": "311104",
                "parentcode": "3111",
                "parenttext": "和田地区",
                "text": "皮山县",
                "level": 3,
                "order": 3231,
                "relation_code": "653223",
                "relation_desc": "新疆皮山县"
            },
            {
                "code": "311105",
                "parentcode": "3111",
                "parenttext": "和田地区",
                "text": "洛浦县",
                "level": 3,
                "order": 3232,
                "relation_code": "653224",
                "relation_desc": "新疆洛浦县"
            },
            {
                "code": "311106",
                "parentcode": "3111",
                "parenttext": "和田地区",
                "text": "策勒县",
                "level": 3,
                "order": 3233,
                "relation_code": "653225",
                "relation_desc": "新疆策勒县"
            },
            {
                "code": "311107",
                "parentcode": "3111",
                "parenttext": "和田地区",
                "text": "于田县",
                "level": 3,
                "order": 3234,
                "relation_code": "653226",
                "relation_desc": "新疆于田县"
            },
            {
                "code": "311108",
                "parentcode": "3111",
                "parenttext": "和田地区",
                "text": "民丰县",
                "level": 3,
                "order": 3235,
                "relation_code": "653227",
                "relation_desc": "新疆民丰县"
            },
            {
                "code": "3112",
                "parentcode": "31",
                "parenttext": "新疆维吾尔自治区",
                "text": "伊犁哈萨克自治州",
                "level": 2,
                "order": 3236,
                "relation_code": "654000",
                "relation_desc": "新疆伊犁哈萨克自治州"
            },
            {
                "code": "311201",
                "parentcode": "3112",
                "parenttext": "伊犁哈萨克自治州",
                "text": "伊宁市",
                "level": 3,
                "order": 3237,
                "relation_code": "654002",
                "relation_desc": "新疆伊宁市"
            },
            {
                "code": "311202",
                "parentcode": "3112",
                "parenttext": "伊犁哈萨克自治州",
                "text": "奎屯市",
                "level": 3,
                "order": 3238,
                "relation_code": "654003",
                "relation_desc": "新疆奎屯市"
            },
            {
                "code": "311203",
                "parentcode": "3112",
                "parenttext": "伊犁哈萨克自治州",
                "text": "伊宁县",
                "level": 3,
                "order": 3239,
                "relation_code": "654021",
                "relation_desc": "新疆伊宁县"
            },
            {
                "code": "311204",
                "parentcode": "3112",
                "parenttext": "伊犁哈萨克自治州",
                "text": "察布查尔锡伯自治县",
                "level": 3,
                "order": 3240,
                "relation_code": "654022",
                "relation_desc": "新疆察布查尔锡伯自治县"
            },
            {
                "code": "311205",
                "parentcode": "3112",
                "parenttext": "伊犁哈萨克自治州",
                "text": "霍城县",
                "level": 3,
                "order": 3241,
                "relation_code": "654023",
                "relation_desc": "新疆霍城县"
            },
            {
                "code": "311206",
                "parentcode": "3112",
                "parenttext": "伊犁哈萨克自治州",
                "text": "巩留县",
                "level": 3,
                "order": 3242,
                "relation_code": "654024",
                "relation_desc": "新疆巩留县"
            },
            {
                "code": "311207",
                "parentcode": "3112",
                "parenttext": "伊犁哈萨克自治州",
                "text": "新源县",
                "level": 3,
                "order": 3243,
                "relation_code": "654025",
                "relation_desc": "新疆新源县"
            },
            {
                "code": "311208",
                "parentcode": "3112",
                "parenttext": "伊犁哈萨克自治州",
                "text": "昭苏县",
                "level": 3,
                "order": 3244,
                "relation_code": "654026",
                "relation_desc": "新疆昭苏县"
            },
            {
                "code": "311209",
                "parentcode": "3112",
                "parenttext": "伊犁哈萨克自治州",
                "text": "特克斯县",
                "level": 3,
                "order": 3245,
                "relation_code": "654027",
                "relation_desc": "新疆特克斯县"
            },
            {
                "code": "311210",
                "parentcode": "3112",
                "parenttext": "伊犁哈萨克自治州",
                "text": "尼勒克县",
                "level": 3,
                "order": 3246,
                "relation_code": "654028",
                "relation_desc": "新疆尼勒克县"
            },
            {
                "code": "3113",
                "parentcode": "31",
                "parenttext": "新疆维吾尔自治区",
                "text": "塔城地区",
                "level": 2,
                "order": 3247,
                "relation_code": "654200",
                "relation_desc": "新疆塔城地区"
            },
            {
                "code": "311301",
                "parentcode": "3113",
                "parenttext": "塔城地区",
                "text": "塔城市",
                "level": 3,
                "order": 3248,
                "relation_code": "654201",
                "relation_desc": "新疆塔城市"
            },
            {
                "code": "311302",
                "parentcode": "3113",
                "parenttext": "塔城地区",
                "text": "乌苏市",
                "level": 3,
                "order": 3249,
                "relation_code": "654202",
                "relation_desc": "新疆乌苏市"
            },
            {
                "code": "311303",
                "parentcode": "3113",
                "parenttext": "塔城地区",
                "text": "额敏县",
                "level": 3,
                "order": 3250,
                "relation_code": "654221",
                "relation_desc": "新疆额敏县"
            },
            {
                "code": "311304",
                "parentcode": "3113",
                "parenttext": "塔城地区",
                "text": "沙湾县",
                "level": 3,
                "order": 3251,
                "relation_code": "654223",
                "relation_desc": "新疆沙湾县"
            },
            {
                "code": "311305",
                "parentcode": "3113",
                "parenttext": "塔城地区",
                "text": "托里县",
                "level": 3,
                "order": 3252,
                "relation_code": "654224",
                "relation_desc": "新疆托里县"
            },
            {
                "code": "311306",
                "parentcode": "3113",
                "parenttext": "塔城地区",
                "text": "裕民县",
                "level": 3,
                "order": 3253,
                "relation_code": "654225",
                "relation_desc": "新疆裕民县"
            },
            {
                "code": "311307",
                "parentcode": "3113",
                "parenttext": "塔城地区",
                "text": "和布克赛尔蒙古自治县",
                "level": 3,
                "order": 3254,
                "relation_code": "654226",
                "relation_desc": "新疆和布克赛尔蒙古自治县"
            },
            {
                "code": "3114",
                "parentcode": "31",
                "parenttext": "新疆维吾尔自治区",
                "text": "阿勒泰地区",
                "level": 2,
                "order": 3255,
                "relation_code": "654300",
                "relation_desc": "新疆阿勒泰地区"
            },
            {
                "code": "311401",
                "parentcode": "3114",
                "parenttext": "阿勒泰地区",
                "text": "阿勒泰市",
                "level": 3,
                "order": 3256,
                "relation_code": "654301",
                "relation_desc": "新疆阿勒泰市"
            },
            {
                "code": "311402",
                "parentcode": "3114",
                "parenttext": "阿勒泰地区",
                "text": "布尔津县",
                "level": 3,
                "order": 3257,
                "relation_code": "654321",
                "relation_desc": "新疆布尔津县"
            },
            {
                "code": "311403",
                "parentcode": "3114",
                "parenttext": "阿勒泰地区",
                "text": "富蕴县",
                "level": 3,
                "order": 3258,
                "relation_code": "654322",
                "relation_desc": "新疆富蕴县"
            },
            {
                "code": "311404",
                "parentcode": "3114",
                "parenttext": "阿勒泰地区",
                "text": "福海县",
                "level": 3,
                "order": 3259,
                "relation_code": "654323",
                "relation_desc": "新疆福海县"
            },
            {
                "code": "311405",
                "parentcode": "3114",
                "parenttext": "阿勒泰地区",
                "text": "哈巴河县",
                "level": 3,
                "order": 3260,
                "relation_code": "654324",
                "relation_desc": "新疆哈巴河县"
            },
            {
                "code": "311406",
                "parentcode": "3114",
                "parenttext": "阿勒泰地区",
                "text": "青河县",
                "level": 3,
                "order": 3261,
                "relation_code": "654325",
                "relation_desc": "新疆青河县"
            },
            {
                "code": "311407",
                "parentcode": "3114",
                "parenttext": "阿勒泰地区",
                "text": "吉木乃县",
                "level": 3,
                "order": 3262,
                "relation_code": "654326",
                "relation_desc": "新疆吉木乃县"
            },
            {
                "code": "3115",
                "parentcode": "31",
                "parenttext": "新疆维吾尔自治区",
                "text": "新疆生产建设兵团",
                "level": 2,
                "order": 3263,
                "relation_code": "",
                "relation_desc": "新疆省直辖行政单位"
            },
            {
                "code": "311501",
                "parentcode": "3115",
                "parenttext": "新疆生产建设兵团",
                "text": "石河子市",
                "level": 3,
                "order": 3264,
                "relation_code": "659001",
                "relation_desc": "新疆石河子市"
            },
            {
                "code": "3116",
                "parentcode": "31",
                "parenttext": "新疆维吾尔自治区",
                "text": "阿拉尔市",
                "level": 2,
                "order": 3265,
                "relation_code": "",
                "relation_desc": "新疆阿拉尔市"
            },
            {
                "code": "311502",
                "parentcode": "3115",
                "parenttext": "新疆生产建设兵团",
                "text": "阿拉尔市",
                "level": 3,
                "order": 3265,
                "relation_code": "659002",
                "relation_desc": "新疆阿拉尔市"
            },
            {
                "code": "3117",
                "parentcode": "31",
                "parenttext": "新疆维吾尔自治区",
                "text": "五家渠市",
                "level": 2,
                "order": 3266,
                "relation_code": "",
                "relation_desc": "新疆五家渠市"
            },
            {
                "code": "311503",
                "parentcode": "3115",
                "parenttext": "新疆生产建设兵团",
                "text": "五家渠市",
                "level": 3,
                "order": 3266,
                "relation_code": "659004",
                "relation_desc": "新疆五家渠市"
            },
            {
                "code": "32",
                "parentcode": "0",
                "parenttext": "0",
                "text": "台湾省",
                "level": 1,
                "order": 3267,
                "relation_code": "710000",
                "relation_desc": "台湾省"
            },
            {
                "code": "33",
                "parentcode": "0",
                "parenttext": "0",
                "text": "香港特别行政区",
                "level": 1,
                "order": 3270,
                "relation_code": "810000",
                "relation_desc": "香港特别行政区"
            },
            {
                "code": "3301",
                "parentcode": "33",
                "parenttext": "香港特别行政区",
                "text": "香港",
                "level": 2,
                "order": 3271,
                "relation_code": "",
                "relation_desc": "香港特别行政区"
            },
            {
                "code": "34",
                "parentcode": "0",
                "parenttext": "0",
                "text": "澳门特别行政区",
                "level": 1,
                "order": 3273,
                "relation_code": "820000",
                "relation_desc": "澳门特别行政区"
            },
            {
                "code": "3401",
                "parentcode": "34",
                "parenttext": "澳门特别行政区",
                "text": "澳门",
                "level": 2,
                "order": 3274,
                "relation_code": "",
                "relation_desc": "澳门特别行政区"
            },
            {
                "code": "330101",
                "parentcode": "3301",
                "parenttext": "香港",
                "text": "深水埗区",
                "level": 3,
                "order": 3301,
                "relation_code": "810000",
                "relation_desc": "香港特别行政区"
            },
            {
                "code": "330102",
                "parentcode": "3301",
                "parenttext": "香港",
                "text": "油尖旺区",
                "level": 3,
                "order": 3302,
                "relation_code": "810000",
                "relation_desc": "香港特别行政区"
            },
            {
                "code": "330103",
                "parentcode": "3301",
                "parenttext": "香港",
                "text": "观塘区",
                "level": 3,
                "order": 3303,
                "relation_code": "810000",
                "relation_desc": "香港特别行政区"
            },
            {
                "code": "330104",
                "parentcode": "3301",
                "parenttext": "香港",
                "text": "屯门区",
                "level": 3,
                "order": 3304,
                "relation_code": "810000",
                "relation_desc": "香港特别行政区"
            },
            {
                "code": "330105",
                "parentcode": "3301",
                "parenttext": "香港",
                "text": "北区",
                "level": 3,
                "order": 3305,
                "relation_code": "810000",
                "relation_desc": "香港特别行政区"
            },
            {
                "code": "330106",
                "parentcode": "3301",
                "parenttext": "香港",
                "text": "西贡区",
                "level": 3,
                "order": 3306,
                "relation_code": "810000",
                "relation_desc": "香港特别行政区"
            },
            {
                "code": "330107",
                "parentcode": "3301",
                "parenttext": "香港",
                "text": "离岛区",
                "level": 3,
                "order": 3307,
                "relation_code": "810000",
                "relation_desc": "香港特别行政区"
            },
            {
                "code": "330108",
                "parentcode": "3301",
                "parenttext": "香港",
                "text": "沙田区",
                "level": 3,
                "order": 3308,
                "relation_code": "810000",
                "relation_desc": "香港特别行政区"
            },
            {
                "code": "330109",
                "parentcode": "3301",
                "parenttext": "香港",
                "text": "黄大仙区",
                "level": 3,
                "order": 3309,
                "relation_code": "810000",
                "relation_desc": "香港特别行政区"
            },
            {
                "code": "330110",
                "parentcode": "3301",
                "parenttext": "香港",
                "text": "葵青区",
                "level": 3,
                "order": 3310,
                "relation_code": "810000",
                "relation_desc": "香港特别行政区"
            },
            {
                "code": "330111",
                "parentcode": "3301",
                "parenttext": "香港",
                "text": "大埔区",
                "level": 3,
                "order": 3311,
                "relation_code": "810000",
                "relation_desc": "香港特别行政区"
            },
            {
                "code": "330112",
                "parentcode": "3301",
                "parenttext": "香港",
                "text": "九龙半岛",
                "level": 3,
                "order": 3312,
                "relation_code": "810000",
                "relation_desc": "香港特别行政区"
            },
            {
                "code": "330113",
                "parentcode": "3301",
                "parenttext": "香港",
                "text": "湾仔区",
                "level": 3,
                "order": 3313,
                "relation_code": "810000",
                "relation_desc": "香港特别行政区"
            },
            {
                "code": "330114",
                "parentcode": "3301",
                "parenttext": "香港",
                "text": "新界",
                "level": 3,
                "order": 3314,
                "relation_code": "810000",
                "relation_desc": "香港特别行政区"
            },
            {
                "code": "330115",
                "parentcode": "3301",
                "parenttext": "香港",
                "text": "元朗区",
                "level": 3,
                "order": 3315,
                "relation_code": "810000",
                "relation_desc": "香港特别行政区"
            },
            {
                "code": "330116",
                "parentcode": "3301",
                "parenttext": "香港",
                "text": "南区",
                "level": 3,
                "order": 3316,
                "relation_code": "810000",
                "relation_desc": "香港特别行政区"
            },
            {
                "code": "330117",
                "parentcode": "3301",
                "parenttext": "香港",
                "text": "荃湾区",
                "level": 3,
                "order": 3317,
                "relation_code": "810000",
                "relation_desc": "香港特别行政区"
            },
            {
                "code": "330118",
                "parentcode": "3301",
                "parenttext": "香港",
                "text": "香港岛",
                "level": 3,
                "order": 3318,
                "relation_code": "810000",
                "relation_desc": "香港特别行政区"
            },
            {
                "code": "330119",
                "parentcode": "3301",
                "parenttext": "香港",
                "text": "中西区",
                "level": 3,
                "order": 3319,
                "relation_code": "810000",
                "relation_desc": "香港特别行政区"
            },
            {
                "code": "330120",
                "parentcode": "3301",
                "parenttext": "香港",
                "text": "九龙区",
                "level": 3,
                "order": 3320,
                "relation_code": "810000",
                "relation_desc": "香港特别行政区"
            },
            {
                "code": "330121",
                "parentcode": "3301",
                "parenttext": "香港",
                "text": "东区",
                "level": 3,
                "order": 3321,
                "relation_code": "810000",
                "relation_desc": "香港特别行政区"
            },
            {
                "code": "340101",
                "parentcode": "3401",
                "parenttext": "澳门",
                "text": "圣方济各堂区",
                "level": 3,
                "order": 3401,
                "relation_code": "820000",
                "relation_desc": "澳门特别行政区"
            },
            {
                "code": "340102",
                "parentcode": "3401",
                "parenttext": "澳门",
                "text": "望德堂区",
                "level": 3,
                "order": 3402,
                "relation_code": "820000",
                "relation_desc": "澳门特别行政区"
            },
            {
                "code": "340103",
                "parentcode": "3401",
                "parenttext": "澳门",
                "text": "风顺堂区",
                "level": 3,
                "order": 3403,
                "relation_code": "820000",
                "relation_desc": "澳门特别行政区"
            },
            {
                "code": "340104",
                "parentcode": "3401",
                "parenttext": "澳门",
                "text": "圣安多尼堂区",
                "level": 3,
                "order": 3404,
                "relation_code": "820000",
                "relation_desc": "澳门特别行政区"
            },
            {
                "code": "340105",
                "parentcode": "3401",
                "parenttext": "澳门",
                "text": "大堂区",
                "level": 3,
                "order": 3405,
                "relation_code": "820000",
                "relation_desc": "澳门特别行政区"
            },
            {
                "code": "340106",
                "parentcode": "3401",
                "parenttext": "澳门",
                "text": "花地玛堂区",
                "level": 3,
                "order": 3406,
                "relation_code": "820000",
                "relation_desc": "澳门特别行政区"
            },
            {
                "code": "340107",
                "parentcode": "3401",
                "parenttext": "澳门",
                "text": "路氹城",
                "level": 3,
                "order": 3407,
                "relation_code": "820000",
                "relation_desc": "澳门特别行政区"
            },
            {
                "code": "340108",
                "parentcode": "3401",
                "parenttext": "澳门",
                "text": "嘉模堂区",
                "level": 3,
                "order": 3408,
                "relation_code": "820000",
                "relation_desc": "澳门特别行政区"
            },
            {
                "code": "99",
                "parentcode": "0",
                "parenttext": "0",
                "text": "国外",
                "level": 1,
                "order": 3550,
                "relation_code": "990000",
                "relation_desc": "国外"
            }
        ]
    )
    //性别
    public static gender = JSON.stringify(
        [
            {text:'男',code:'01'},
            {text:'女',code:'02'}
        ]
    )
    //是否属于到基层
    public static isbase = JSON.stringify(
        [
            {text:'是',code:'01'},
            {text:'否',code:'02'}
        ]
    )
    //学制
    public static schoolLength = JSON.stringify(
        [
            {text:'0.5',code:'0.5'},
            {text:'1',code:'1'},
            {text:'1.5',code:'1.5'},
            {text:'2',code:'2'},
            {text:'2.5',code:'2.5'},
            {text:'3',code:'3'},
            {text:'3.5',code:'3.5'},
            {text:'4',code:'4'},
            {text:'4.5',code:'4.5'},
            {text:'5',code:'5'},
            {text:'5.5',code:'5.5'},
            {text:'6',code:'6'},
            {text:'6.5',code:'6.5'},
            {text:'7',code:'7'},
            {text:'7.5',code:'7.5'},
            {text:'8',code:'8'},
        ]
    ) 
    //学历
    public static education = JSON.stringify(
        [
            {
                "code": "01",
                "parentcode": "0",
                "text": "中专及以下",
                "level": 1
            }, 
            {
                "code": "02", 
                "parentcode": "0", 
                "text": "大专", 
                "level": 1
            }, 
            {
                "code": "03",
                "parentcode": "0",
                "text": "本科",
                "level": 1
            },
            {
                "code": "04", 
                "parentcode": "0",
                "text": "硕士", 
                "level": 1
            }, 
            {
                "code": "05",
                "parentcode": "0",
                "text": "MBA",
                "level": 1
            }, 
            {
                "code": "06",
                "parentcode": "0", 
                "text": "EMBA", 
                "level": 1
            }, 
            {
                "code": "07",
                "parentcode": "0",
                "text": "博士",
                "level": 1
            }
        ]
    )

    //签约配置
    public static signModeSetting = JSON.stringify([
        {
            signMode:signMode.online,
            signText:'线上签约',
            signImg:require(`@/assets/${online}`),
            remark:'用人单位参与线上签约',
            signStr:'online',
        },
        {
            signMode:signMode.offline,
            signText:'协议书打印签约',
            signImg:require(`@/assets/${offline}`),
            remark:'*本人独立完成，线上生成就业协议书并上传',
            signStr:'offline',
        },
        {
            signMode:signMode.paper,
            signText:'已签协议书备案',
            signImg:require(`@/assets/${paper}`),
            remark:'*本人独立完成，需上传纸质协议书照片',
            signStr:'paper',
        },
    ])

    //毕业去向
    public static graduation = JSON.stringify(
        [
            {text:'回原籍',code:'01'},
            {text:'派遣',code:'02'},
            {text:'非派遣',code:'03'},
            {text:'升学',code:'04'},
            {text:'出国',code:'05'},
            {text:'待分',code:'06'},
            {text:'不就业拟升学',code:'07'},
            {text:'河南省人才交流中心',code:'10'},
            {text:'郑州市人力资源和社会保障局',code:'11'},
            
        ]
    ) 
}